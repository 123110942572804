import './bootstrap.js';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */
/*
    /Users/glblllc/Web_Applications/DOCKER_DEVELOPMENT/DOCKER_BUILD_AWS_UBUNTU_PHP8-1_SSL_SYMFONY6-2/application/node_modules/spectrum-colorpicker2/dist
    spectrum.js
    
    /Users/glblllc/Web_Applications/DOCKER_DEVELOPMENT/DOCKER_BUILD_AWS_UBUNTU_PHP8-1_SSL_SYMFONY6-2/application/node_modules/jquery-slidePanel/dist
    jquery-slidePanel.es.js
	
	https://stackoverflow.com/questions/77684268/bootstrap-cannot-find-symfony-stimulus-bundle/77720816#77720816

*/
//https://symfony.com/doc/current/frontend/encore/legacy-applications.html
const $ = require('jquery');
global.$ = global.jQuery = $;
//Window.prototype.$ = $; //HERE IS MY SOLUTION (Without this line it doesn't work!)
//import $ from 'jquery';
//window.$ = $;
import * as jQuery from 'jquery';
import * as jquery from 'jquery';
window.jQuery = jQuery;
/*******************//*******************//*******************/
/*******************//*******************//*******************/
/*

#	https://symfony.com/doc/current/frontend/asset_mapper.html
#	HERE IS THE ASSET PRODUCTION CODE: php bin/console asset-map:compile


*/


/*******************//*******************//*******************/
/*
	DESKTOP
*/
import './styles/jquery.datetimepicker.css';
//import './styles/jquery.datetimepicker.min.css';
import './styles/main_unique.css';
import './styles/main_universal.css';
//import './styles/bootstrap-material-datetimepicker.css';
import './styles/standalone.css';
import './styles/clockpicker.css';
import './styles/fullcalendar.css';
import './styles/slidePanel.css';
import './styles/MGMT_Cropper.css';
import './styles/spectrum.css';
//import './styles/pannellum.css';
import './styles/main_feed.css';
import './styles/main_profile.css';
import './styles/main_event.css';
import './styles/main_system_form.css';
import './styles/main_system_frame.css';
import './styles/main_metrics.css';
/*******************//*******************//*******************/
/*
	MOBILE & TABLET
*/


import './styles/mobile_system_form.css';
import './styles/mobile_system_frame.css';
import './styles/tablet_system_form.css';

import './styles/main_mobilsystem_form.css';
import './styles/main_mobilview_system_frame.css';
import './styles/main_mobilview_universal.css';
import './styles/main_mobilview_unique.css';
import './styles/main_mobilview_profile.css';
import './styles/main_mobilview_metrics.css';
import './styles/main_mobilview_feed.css';
import './styles/main_mobilview_event.css';

import './styles/main_tabletsystem_form.css';
import './styles/main_tabletview_system_frame.css';
import './styles/main_tabletview_universal.css';
import './styles/main_tabletview_unique.css';


/*******************//*******************//*******************/
/*******************/
/*******************/
import './controllers/mgmt_system.js';
import './controllers/mgmt_admin.js';
import './controllers/mgmt_control.js';
import './controllers/mgmt_profile.js';
import './controllers/mgmt_access.js';
import './controllers/mgmt_business.js';
import './controllers/mgmt_business_cohost.js';
import './controllers/mgmt_items.js';
import './controllers/mgmt_event.js';
import './controllers/mgmt_mapping.js';
import './controllers/mgmt_metrics.js';
import './controllers/mgmt_feed.js';
import './controllers/mgmt_image.js';
import './controllers/MGMT_Cropper.js';
import './bootstrap';
import './controllers/mgmt_chart.min.js';
//import '.controllers/mgmt_4_infinite-scroll.pkgd.min.js';
//import './controllers/dropzone.js';
//import './controllers/pannellum.js';
/*******************/
/*******************/
/*

#	https://symfony.com/doc/current/frontend/asset_mapper.html
#	HERE IS THE ASSET PRODUCTION CODE: php bin/console asset-map:compile


*/
/*******************//*******************//*******************/
/*******************//*******************//*******************/
var GOOGLE_MAPS_API_KEY ='AIzaSyCOZFABEYCgmhlnGGM5ARkuM98f1iTkuhI';
/********************/
import * as moment from 'moment';
const m = moment(); 
var momenttz = require('moment-timezone');
/********************/
/*******************/
import 'jquery-circliful/css/jquery.circliful.css';
import 'jquery-circliful/js/jquery.circliful.js';
/********************/
//import Chart from 'chart.js';
import Chart from 'chart.js/auto';
//const Chart = require('chart.js');
//import { getRelativePosition } from 'chart.js/helpers';


import "jquery-slidePanel";
/********************/
import Cropper from 'cropperjs';
//import "jquery-ui/ui/widgets/datepicker";
import "jquery-datetimepicker";
/********************/
import 'ol/ol.css';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Map from 'ol/Map';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import {Cluster} from 'ol/source';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import {Group as LayerGroup} from 'ol/layer';
import {fromLonLat} from 'ol/proj';
//import Stamen from 'ol/source';///Stamen
import KML from 'ol/format/KML';
import Polygon from 'ol/geom/Polygon';
import {Icon} from 'ol/style';
import {addProjection, addCoordinateTransforms, transform} from 'ol/proj';
import {defaults as defaultControls, Attribution} from 'ol/control';
import * as olExtent from 'ol/extent';
/*******************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
import {StadiaMaps} from 'ol/source';///StadiaMaps.js'
/*******************/
//https://tom-select.js.org/plugins/
import TomSelect from 'tom-select/dist/js/tom-select.complete.js';
//var googleMapsClient = require('@google/maps').createClient({
//  key: 'AIzaSyCOZFABEYCgmhlnGGM5ARkuM98f1iTkuhI'
//});
import { Loader } from "@googlemaps/js-api-loader"
//import googleMapsClient from "@googlemaps/google-maps-services-js";



/*******************/
/*******************/
/******************
console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');

$(document).ready(() => {
    console.log('page is ready!');
})
console.log('script is done!');
*/


