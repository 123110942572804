/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 

/*  BUSINESS_registration_basics
/*	BUSINESS_basics
/*	BUSINESS_recommendation_select_basics
/*	BUSINESS_recommendation_management_basics
/*	BUSINESS_reporting_basics
/*	BUSINESS_settings_basics
/*	BUSINESS_account_basics
/*	BUSINESS_COHOST_SELECT_basics
/*  BUSINESS_TYPE_SELECT_basics BUSINESS_TYPE_SELECT_basics
/*	BUSINESS_Catalog
/*	business_instance_function
/*	
/*		href_Function		'account_schedule_options2'    
/*
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { changeMetaContent as changeMetaContent } from "./mgmt_system.js";
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { META_TAGS as META_TAGS } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { business_type_select as business_type_select } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";

import { settings_request_function as settings_request_function } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { business_title_validation_MGMT as business_title_validation_MGMT } from "./mgmt_control.js";
import { ENTRYdelay as ENTRYdelay } from "./mgmt_control.js";
import { UPDATE_BTITLE_CLASS as UPDATE_BTITLE_CLASS } from "./mgmt_control.js";

import { title_ajax as title_ajax } from "./mgmt_control.js";
import { email_ajax as email_ajax } from "./mgmt_control.js";
import { gender_ajax as gender_ajax } from "./mgmt_control.js";

import { business_header_background_activate as business_header_background_activate } from "./mgmt_control.js";
import { BUSINESS_PHOTO_basics as BUSINESS_PHOTO_basics } from "./mgmt_control.js";
import { header_background_activate as header_background_activate } from "./mgmt_control.js";

import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_LISTED as ACTIVATE_UPLOAD_FORM_LISTED } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";


import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { business_recommendations_map as business_recommendations_map } from "./mgmt_mapping.js";

import { PROFILESYSTEM_LOAD_MGMT as PROFILESYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";

import { PHOTOBUCKET_PROFILE_LOAD_MGMT as PHOTOBUCKET_PROFILE_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_PROFILE_UPDATE as PHOTOBUCKET_PROFILE_UPDATE } from "./mgmt_control.js";

import { PHOTOSYSTEM_PROFILE_LOAD_MGMT as PHOTOSYSTEM_PROFILE_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_PROFILE_CONTROL as PHOTOSYSTEM_PROFILE_CONTROL } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";
import { description_micro_map_activate as description_micro_map_activate } from "./mgmt_mapping.js";

import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_metrics.js";
import { EVENT_METRICS_CONTROL_basics as EVENT_METRICS_CONTROL_basics } from "./mgmt_metrics.js";
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function business_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('business_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('business_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('business_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('business_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
    /* --------------------------------------------------------  */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());

        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/entity/file/business/upload';
    }
    /* --------------------------------------------------------  */
    if(form_entity.includes('settings_activity_type1_control')){
    	$('#standard_entity_modify_formCurrentIndex').val(select_button);
        $('#standard_entity_modify_control').val(select_val);
        $('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_title').val($('#standard_entity_modify_title').val());

        $DATA = $('.Interaction_Activity_UI_class');
        $URL = '/system/business/ajax';
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){

    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_registration_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('BUSINESS_registration_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options1")){
				SET_BUTTON_CLICK_ACTION("business_registration_options1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options2")){
				SET_BUTTON_CLICK_ACTION("business_registration_options2");
			}
			/* --------------------------------------------------------  
			if(document.getElementById("business_registration_options3")){
				SET_BUTTON_CLICK_ACTION("business_registration_options3");
				$('#business_registration_options3').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#business_registration_options3').attr('id'));
					$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
					standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}*/
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options4")){
				SET_BUTTON_CLICK_ACTION("business_registration_options4");
			}
		 	/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options5")){
                SET_BUTTON_CLICK_ACTION("business_registration_options5");
                $('#business_registration_options5').unbind().click(function(event) {
                    $('#standard_entity_modify_control').val($('#business_registration_options5').attr('id'));
                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                    settings_request_function(event);
                });
			 }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_title")){
				var reg_titlename_var = document.getElementById("business_registration_title");
				/* --------------------------------------------------------  */
				reg_titlename_var.onfocus = function() {
					/* --------------------------------------------------------  */
					$( "#business_registration_title" ).keyup(function(e) {
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById("business_registration_title").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#business_registration_title').val();
							$('#business_registration_title').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
						var $REG_UNAME = $('#business_registration_title').val();
						var string = document.getElementById("business_registration_title").value;
						reg_titlename_var.onblur = function() {
							if($REG_UNAME.length>2){
							//ENTRYdelay(function(){
								if(($('#business_registration_title').val().length>0)){
									$('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
									$('#standard_entity_modify_title').val($('#business_registration_title').val());
									$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
									windowload_script('REGISTRATION_basics - $REG_UNAME');
									business_title_validation_MGMT(e);
								}
								if(!($('#business_registration_title').val())){
									UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
								}
							//}, 1000 );
							}
						}
					});
				}
			}
			/* --------------------------------------------------------  */
				
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function title_ajax(){
				if($('#standard_results_ajax_control').val() == $('#business_registration_title').attr('id')){
					var $VAL =$('#standard_results_ajax_responseData').val();
					if ($VAL.indexOf("true") >=0){
						UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_correct', '');
					}
					if ($VAL.indexOf("false") >=0){
						UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_error', '');
					}
					$('#standard_results_ajax_responseData').val('');
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
				  mutations.forEach(function(mutation) {
					if($("#standard_results_ajax_control").val()){  
                        CONSOLE_MANAGER('BUSINESS_registration_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
						if(mutation.type=='attributes'){
							CONSOLE_MANAGER('BUSINESS_registration_basics',mutation.type+': attributes');
							/* -------------------------------------------------------- */
							//var $TEMP_VAL = $('#standard_results_ajax_control').val();
							if($('#standard_results_ajax_control').val() == $('#business_registration_title').attr('id')){
								title_ajax();
							}
							if($('#standard_results_ajax_control').val() == $('#business_registration_email_first').attr('id')){
								email_ajax();
							}
							if($('#standard_results_ajax_control').val() == $('#business_registration_email_second').attr('id')){
								email_ajax();
							}
							if($('#standard_results_ajax_control').val() == $('#business_registration_address').attr('id')){
								gender_ajax();
							}
							if($('#standard_results_ajax_control').val() == $('#business_registration_address2').attr('id')){
								gender_ajax();
							}
							/* -------------------------------------------------------- */
						}else if(mutation.type=='childList'){
							CONSOLE_MANAGER('business_instance_function',mutation.type+': childList');
							/* -------------------------------------------------------- */
						}
					}
				  });    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			function email_ajax(){
				if($('#standard_results_ajax_control').val() == $('#business_registration_email_first').attr('id')){
					if(!($('#standard_results_ajax_responseData').val())){
						document.getElementById("output_email1").className='output_email_correct';			
					}else{
						document.getElementById("output_email1").className='output_email_error';
					}
				}
				if($('#standard_results_ajax_control').val() == $('#business_registration_email_second').attr('id')){
					if(!($('#standard_results_ajax_responseData').val())){
						document.getElementById("output_email2").className='output_email_correct';			
					}else{
						document.getElementById("output_email2").className='output_email_error';
					}
				}

				if($('#business_registration_email_first').val().length >0 && $('#business_registration_email_second').val().length >0){
					var mtch = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
					if(mtch){
						document.getElementById("output_email1").className='output_email_correct';
						document.getElementById("output_email2").className='output_email_correct';
					}else{
						document.getElementById("output_email1").className='output_email_error';
						document.getElementById("output_email2").className='output_email_error';
					}
				}
				$('#standard_results_ajax_control').val('');
				$('#standard_entity_modify_email').val('');
				$('#standard_results_ajax_responseData').val('');
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_phone")){
				$( "#business_registration_phone" ).change(function(e) {
					phone_format(e);
				});
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				$('#business_registration_phone').on('keypress', function(e) {
					var key = e.charCode || e.keyCode || 0;
					var phone = $(this);
					if (phone.val().length === 0) {
						phone.val(phone.val() + '(');
					}
					// Auto-format- do not expose the mask as the user begins to type
					if (key !== 8 && key !== 9) {
						if (phone.val().length === 4) {
							phone.val(phone.val() + ')');
						}
						if (phone.val().length === 5) {
							phone.val(phone.val() + ' ');
						}
						if (phone.val().length === 9) {
							phone.val(phone.val() + '-');
						}
						if (phone.val().length >= 14) {
							phone.val(phone.val().slice(0, 13));
						}
				  }
				  // Allow numeric (and tab, backspace, delete) keys only
				  return (key == 8 ||
					key == 9 ||
					key == 46 ||
					(key >= 48 && key <= 57) ||
					(key >= 96 && key <= 105));
				})
				.on('focus', function() {
					var phone = $('#business_registration_phone');
					if (phone.val().length === 0) {
						phone.val('(');
					} else {
						var val = phone.val();
						phone.val('').val(val); // Ensure cursor remains at the end
					}
				})
				.on('blur', function() {
					var phone = $('#business_registration_phone');
					if (phone.val() === '(') {
						phone.val('');
					}
				});
			}
			function phone_format(e){
				var $text = $( "#business_registration_phone" ).val();
				$text = $text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
				$( "#business_registration_phone" ).val($text);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_email_first")){
				document.getElementById("output_email1").className='output_email_default';
				$( "#business_registration_email_first" ).keyup(function(e) {
					//ENTRYdelay(function(){
						if(($('#business_registration_email_first').val())){
							$('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
							$('#standard_entity_modify_email').val($('#business_registration_email_first').val());
							var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
							if(E_MGMT){ 
								document.getElementById("output_email1").className='output_email_correct';
							}else{
								document.getElementById("output_email1").className='output_email_error';
							}
							if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
							   	document.getElementById("output_email1").className='output_email_correct';
								document.getElementById("output_email2").className='output_email_correct';
							}
						}
						if(($('#business_registration_email_first').val().length === 0)){
							document.getElementById("output_email1").className='output_email_default';
							$('#standard_results_ajax_control').val('');
							$('#standard_entity_modify_email').val('');
							$('#standard_results_ajax_responseData').val('');
						}
					//}, 1000 );
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("business_registration_email_second")){
				document.getElementById("output_email2").className='output_email_default';
				$( "#business_registration_email_second" ).keyup(function(e) {
					// ENTRYdelay(function(){
						if(($('#business_registration_email_second').val())){
							$('#standard_entity_modify_control').val($('#business_registration_email_second').attr('id'));
							$('#standard_entity_modify_email').val($('#business_registration_email_second').val());
							
							//var E_MGMT = sys_email_check(document.getElementById("business_registration_email_second").value);
							var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
							if(E_MGMT){ 
								document.getElementById("output_email2").className='output_email_correct';
							}else{
								document.getElementById("output_email2").className='output_email_error';
							}
							if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
							   	document.getElementById("output_email1").className='output_email_correct';
								document.getElementById("output_email2").className='output_email_correct';
							}
						}
						if(($('#business_registration_email_second').val().length === 0)){
							document.getElementById("output_email2").className='output_email_default';
							$('#standard_results_ajax_control').val('');
							$('#standard_entity_modify_email').val('');
							$('#standard_results_ajax_responseData').val('');
						}
					//}, 1000 );
				});
			}
			/* ------------------------------------------------- */
			/* ------------------------------------------------- */
			setCheckedValue(false);
			$('#business_registration_options4').unbind().click(function(event) {
				setCheckedValue(true);
			});
			function setCheckedValue(val) {
				if((val)){
					if ($('#business_registration_termsacceptedchk').is(":checked")){
						$('#business_registration_termsacceptedchk').prop('checked', false);
						$("#business_registration_options4").removeClass('reg_check_button_active');
						$("#business_registration_options4").removeClass('reg_check_button');
						$("#business_registration_options4").removeClass('reg_check_button_error');
						$("#business_registration_options4").addClass('reg_check_button');
						document.getElementById("business_registration_termsaccepted").value = false;
						document.getElementById("business_registration_privacypolicy").value = false;
						document.getElementById("business_registration_human_check").value = false;
					}else{
						$('#business_registration_termsacceptedchk').prop('checked', true);
						$("#business_registration_options4").removeClass('reg_check_button_active');
						$("#business_registration_options4").removeClass('reg_check_button');
						$("#business_registration_options4").removeClass('reg_check_button_error');
						$("#business_registration_options4").addClass('reg_check_button_active');
						document.getElementById("business_registration_termsaccepted").value = true;
						document.getElementById("business_registration_privacypolicy").value = true;
						document.getElementById("business_registration_human_check").value = true;
					}
				}
				window_complete_script('function: BUSINESS_registration_basics');
				return null;
			}
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$( "#business_registration_address" ).focusin(function() {
			var $autoObj = MGMT_google_autocomplete('business_registration_address');
		});
		if(document.getElementById("business_registration_address")){
			
			$( "#business_registration_address" ).keyup(function(e) {
				/* --------------------------------------------------------  */
                //var codes = [9,13,32];
                var codes = [32];
                document.getElementById("business_registration_address").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
					var path = e.path || e.composedPath()
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#business_registration_address').val();
                    $('#business_registration_address').val($STRING.replace(/\s{2,}/g, ' '));
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /* --------------------------------------------------------  */
				var $autoObj = MGMT_google_autocomplete('business_registration_address');
			});
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		if(document.getElementById("business_registration_address")){
			$( "#business_registration_address2" ).keyup(function(e) {
				/* --------------------------------------------------------  */
                //var codes = [9,13,32];
                var codes = [32];
                document.getElementById("business_registration_address2").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
					var path = e.path || e.composedPath()
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#business_registration_address2').val();
                    $('#business_registration_address2').val($STRING.replace(/\s{2,}/g, ' '));
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /* --------------------------------------------------------  */
			});
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_registration_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_basics','ACTIVATED');//
            /* ------------------------------------------------- */
			business_header_background_activate();
			BUSINESS_PHOTO_basics();
            META_TAGS();
			/* --------------------------------------------------------  */
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_event_description_formCurrentIndex'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_event_description_options3'),null,null,null,null);
			/* --------------------------------------------------------  */
			if($('#map').is(':visible')) {
				micro_map_activate();
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("profile_member_menu_profile_pic_path")){
                if($('#profile_member_menu_profile_pic_path')){
                    var path_var = $('#profile_member_menu_profile_pic_path').val();
                    $('#profile_member_menu_options1').css('background-image','url('+path_var+')');
                }
				SET_BUTTON_CLICK_ACTION('profile_member_menu_options1');
                $('#profile_member_menu_options1').unbind().click(function(event) {
                    event.stopPropagation;
                   /// $('#standard_entity_modify_title').val($('#profile_username_index1').val());
                   // $('#standard_entity_modify_control').val('IMAGE_TYPE');
                   // standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)			
                });
            }
			/* ------------------------------------------------- */	
            SET_BUTTON_CLICK_ACTION('profile_info_options4active');
            SET_BUTTON_CLICK_ACTION('profile_info_options1');
            SET_BUTTON_CLICK_ACTION('profile_info_options2');
            SET_BUTTON_CLICK_ACTION('profile_info_options3');
			SET_BUTTON_CLICK_ACTION('profile_username_options2');
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("settings_event_activity_options1");
            if($('#settings_event_activity_options1').is(':visible')) {
                var $SCORE = parseInt($('#settings_event_activity_formValue').val());
                EVENT_METRICS_CONTROL_basics($('#settings_event_activity_options1'),$SCORE);
            }
			/* -------------------------------------------------------- */
            if($('#social_media_share_options1').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("social_media_share_options1");
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#social_media_options1').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options1");
                $('#social_media_options1').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options1').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options1'),null,null,null,null);
            }
            if($('#social_media_options2').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options2");
                $('#social_media_options2').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options2').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options2'),null,null,null,null);
            }
            if($('#social_media_options3').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options3");
                $('#social_media_options3').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options3').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options3'),null,null,null,null);
            }
            if($('#social_media_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options4");
                $('#social_media_options4').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options4').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options4'),null,null,null,null);
            }
            if($('#social_media_options5').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options5");
                $('#social_media_options5').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options5').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options5'),null,null,null,null);
            }
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			for(var $i=1;$i<parseInt(18);$i++ ){
				if($('#business_recommendation_options'+$i+'active').is(':visible')){
					SET_BUTTON_CLICK_ACTION('business_recommendation_options'+$i+'active');
				}
				if($('#business_recommendation_index'+$i).val()){
					SET_BUTTON_CLICK_ACTION('business_recommendation_options'+$i);
					header_background_activate($('#business_recommendation_formFilter'+$i), $('#business_recommendation_options'+$i))
					REGISTER_CLICK($('#business_recommendation_options'+$i),$i,$('#business_recommendation_index'+$i).val());
				}else{
					 $('#business_recommendation_options'+$i).prop('disabled', true);
				}
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$TITLE){
				$button_obj.unbind().click(function(event) {
					$('#standard_entity_modify_title').val($TITLE);
					$('#standard_entity_modify_index1').val($TITLE);
					//$('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			
			/* ------------------------------------------------- */
            /* ------------------------------------------------- */	
			/* ------------------------------------------------- */	
            SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options1');
            SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options2');
            SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options3');
            /* ------------------------------------------------- */	
            SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options1');
            SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options2');
            SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options3');
            SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options4');
			SET_BUTTON_CLICK_ACTION('business_settings_options5');
            /* ------------------------------------------------- */	
			/* ------------------------------------------------- */
			if($('#business_member_follow_action_options1')){
				SET_BUTTON_CLICK_ACTION("business_member_follow_action_options1");
			}
			if($('#business_member_follow_action_options2')){
				SET_BUTTON_CLICK_ACTION("business_member_follow_action_options2");
			}
			if($('#business_member_recommendation_action_options2')){
				SET_BUTTON_CLICK_ACTION("business_member_recommendation_action_options2");
			}
			if($('#business_member_follow_action_options3')){
				SET_BUTTON_CLICK_ACTION("business_member_follow_action_options3");
			}
			if($('#business_member_follow_action_options4')){
				SET_BUTTON_CLICK_ACTION("business_member_follow_action_options4");
			}
            /* -------------------------------------------------------- */
			/* ------------------------------------------------- */	
			if($('#business_file_upload_options2')){
				SET_BUTTON_CLICK_ACTION("business_file_upload_options2");
			}
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme1_action_frame_div" ).on( "mouseover", function() {
                CONSOLE_MANAGER('business_theme1_action_frame_div','MOUSE OVER');
                $('#business_file_segment1_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment1_upload_index1').val()==1){
                    $('#business_file_upload_options_options1').prop('disabled', false);
                    $('#business_file_upload_options_options1').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment1_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options2').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme1_action_frame_div','MOUSE OUT');
                $('#business_file_segment1_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment1_upload_index1').val()==1){
                    $('#business_file_upload_options_options1').prop('disabled', false);
                    $('#business_file_upload_options_options1').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment1_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options1').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme2_action_frame_div" ).on( "mouseover", function() {
                CONSOLE_MANAGER('business_theme2_action_frame_div','MOUSE OVER');
                $('#business_file_segment2_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment2_upload_index1').val()==1){
                    $('#business_file_upload_options_options2').prop('disabled', false);
                    $('#business_file_upload_options_options2').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment2_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options2').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme2_action_frame_div','MOUSE OUT');
                $('#business_file_segment2_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment2_upload_index1').val()==1){
                    $('#business_file_upload_options_options2').prop('disabled', false);
                    $('#business_file_upload_options_options2').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment2_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options2').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme3_action_frame_div" ).on( "mouseover", function() {
                CONSOLE_MANAGER('business_theme3_action_frame_div','MOUSE OVER');
                $('#business_file_segment3_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment3_upload_index1').val()==1){
                    $('#business_file_upload_options_options3').prop('disabled', false);
                    $('#business_file_upload_options_options3').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment3_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options3').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme3_action_frame_div','MOUSE OUT');
                $('#business_file_segment3_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment3_upload_index1').val()==1){
                    $('#business_file_upload_options_options3').prop('disabled', false);
                    $('#business_file_upload_options_options3').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment3_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options3').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme4_action_frame_div" ).on( "mouseover", function() {
                CONSOLE_MANAGER('business_theme4_action_frame_div','MOUSE OVER');
                $('#business_file_segment4_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment4_upload_index1').val()==1){
                    $('#business_file_upload_options_options4').prop('disabled', false);
                    $('#business_file_upload_options_options4').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment4_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options4').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme4_action_frame_div','MOUSE OUT');
                $('#business_file_segment4_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment4_upload_index1').val()==1){
                    $('#business_file_upload_options_options4').prop('disabled', false);
                    $('#business_file_upload_options_options4').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment4_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options4').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme5_action_frame_div" ).on( "mouseover", function() {
               CONSOLE_MANAGER('business_theme5_action_frame_div','MOUSE OVER');
                $('#business_file_segment5_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment5_upload_index1').val()==1){
                    $('#business_file_upload_options_options5').prop('disabled', false);
                    $('#business_file_upload_options_options5').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment5_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options5').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme5_action_frame_div','MOUSE OUT');
                $('#business_file_segment5_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment5_upload_index1').val()==1){
                    $('#business_file_upload_options_options5').prop('disabled', false);
                    $('#business_file_upload_options_options5').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment5_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options5').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme6_action_frame_div" ).on( "mouseover", function() {
               CONSOLE_MANAGER('business_theme6_action_frame_div','MOUSE OVER');
                $('#business_file_segment6_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment6_upload_index1').val()==1){
                    $('#business_file_upload_options_options6').prop('disabled', false);
                    $('#business_file_upload_options_options6').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment6_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options6').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme6_action_frame_div','MOUSE OUT');
                $('#business_file_segment6_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment6_upload_index1').val()==1){
                    $('#business_file_upload_options_options6').prop('disabled', false);
                    $('#business_file_upload_options_options6').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment6_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options6').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme7_action_frame_div" ).on( "mouseover", function() {
               CONSOLE_MANAGER('business_theme7_action_frame_div','MOUSE OVER');
                $('#business_file_segment7_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment7_upload_index1').val()==1){
                    $('#business_file_upload_options_options7').prop('disabled', false);
                    $('#business_file_upload_options_options7').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment7_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options7').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme7_action_frame_div','MOUSE OUT');
                $('#business_file_segment7_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment7_upload_index1').val()==1){
                    $('#business_file_upload_options_options7').prop('disabled', false);
                    $('#business_file_upload_options_options7').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment7_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options7').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme8_action_frame_div" ).on( "mouseover", function() {
               CONSOLE_MANAGER('business_theme8_action_frame_div','MOUSE OVER');
                $('#business_file_segment8_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment8_upload_index1').val()==1){
                    $('#business_file_upload_options_options8').prop('disabled', false);
                    $('#business_file_upload_options_options8').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment8_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options8').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme8_action_frame_div','MOUSE OUT');
                $('#business_file_segment8_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment8_upload_index1').val()==1){
                    $('#business_file_upload_options_options8').prop('disabled', false);
                    $('#business_file_upload_options_options8').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment8_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options8').prop('disabled', true);
                }
            });
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            $(".business_theme9_action_frame_div" ).on( "mouseover", function() {
               CONSOLE_MANAGER('business_theme9_action_frame_div','MOUSE OVER');
                $('#business_file_segment9_upload_theme_1').addClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').hide();
                //$('#event_guidelines_display').hide();
                /* --------------------------------------------------------  */
                if($('#business_file_segment9_upload_index1').val()==1){
                    $('#business_file_upload_options_options9').prop('disabled', false);
                    $('#business_file_upload_options_options9').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment9_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options9').prop('disabled', true);
                }
             }).on( "mouseout", function() {
                CONSOLE_MANAGER('business_theme9_action_frame_div','MOUSE OUT');
                $('#business_file_segment9_upload_theme_1').removeClass('event_theme_display_selected');
                //$('#event_mgmt_content_background_DIV').show();
                //$('#event_guidelines_display').show();
                /* --------------------------------------------------------  */
                if($('#business_file_segment9_upload_index1').val()==1){
                    $('#business_file_upload_options_options9').prop('disabled', false);
                    $('#business_file_upload_options_options9').unbind().click(function(event) {
                        //DELETE
                        $('#business_file_segment9_upload_options2').unbind().click();
                    });
                }else{
                    $('#business_file_upload_options_options9').prop('disabled', true);
                }
            });
			/* ------------------------------------------------- */	
			$('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
			if(document.getElementById('Entity_upload_Activity_UI_control')){
				/* ------------------------------------------------- 
				//WORKS
				if($('#Event_File_Upload_Label1').is(':visible')) {
					$('#Event_File_Upload_Label1').unbind().click(function(event) {
						$('#business_file_upload_options_control').val($('#Event_File_Upload_Label1').attr('id'));
						ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
					});
				}*/	
                /* ------------------------------------------------- */
                //WORKS
                if($('#Event_File_Upload_Label1').is(':visible')) {
                    $('#Event_File_Upload_Label1').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label1').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file1'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }
				/* ------------------------------------------------- */
				//WORKS
				if($('#Event_File_Upload_Label2').is(':visible')) {
                    $('#Event_File_Upload_Label2').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label2').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file2'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */	
				//WORKS
				if($('#Event_File_Upload_Label3').is(':visible')) {
                    $('#Event_File_Upload_Label3').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label3').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file3'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */
				//WORKS
				if($('#Event_File_Upload_Label4').is(':visible')) {
                    $('#Event_File_Upload_Label4').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label4').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file4'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */
				//WORKS
				if($('#Event_File_Upload_Label5').is(':visible')) {
                    $('#Event_File_Upload_Label5').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label5').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file5'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */	
				//WORKS
				if($('#Event_File_Upload_Label6').is(':visible')) {
                    $('#Event_File_Upload_Label6').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label6').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file6'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */
				//WORKS
				if($('#Event_File_Upload_Label7').is(':visible')) {
                    $('#Event_File_Upload_Label7').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label7').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file7'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
				/* ------------------------------------------------- */	
				if($('#Event_File_Upload_Label8').is(':visible')) {
                    $('#Event_File_Upload_Label8').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label8').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file8'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }     
                /* ------------------------------------------------- */
				//WORKS
                if($('#Event_File_Upload_Label9').is(':visible')) {
                    $('#Event_File_Upload_Label9').unbind().click(function(event) {
                        $('#business_file_upload_options_control').val($('#Event_File_Upload_Label9').attr('id'));
                        ACTIVATE_UPLOAD_FORM_LISTED($('#business_file_upload_options_file9'),document.getElementById("Entity_upload_Activity_UI_control"))
                        //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    });
                }                
			}
			/* ------------------------------------------------- */	
			$('.Business_upload_Activity_UI_class').attr("id", 'Business_upload_Activity_UI_control');
			if(document.getElementById('Business_upload_Activity_UI_control')){	
				if($('#Business_Photo_Upload_Label1').is(':visible')) {
					$('#Business_Photo_Upload_Label1').unbind().click(function(event) {
						$('#business_file_upload_options_control').val($('#Business_Photo_Upload_Label1').attr('id'));
						ACTIVATE_UPLOAD_FORM_INIT($('.Business_upload_Activity_UI_class'));
					});
				}
			}
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION('settings_event_guidelines_profile_options2');
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_sharing_options2');
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_social3_options2');
			/* ------------------------------------------------- */	
			/* ------------------------------------------------- */	
			if($('#settings_event_shares_options1')){//
				SET_BUTTON_CLICK_ACTION("settings_event_shares_options1");
			}
			if($('#settings_event_invites_options1')){//
				SET_BUTTON_CLICK_ACTION("settings_event_invites_options1");
			}
			if($('#settings_event_followers_options1')){//
				SET_BUTTON_CLICK_ACTION("settings_event_followers_options1");
			}
			if($('#settings_event_compatibility_options1')){//
				SET_BUTTON_CLICK_ACTION("settings_event_compatibility_options1");
			}
			if($('#settings_event_recommendations_options1')){//
				SET_BUTTON_CLICK_ACTION("settings_event_recommendations_options1");
			}
			if($('#settings_event_following_options1')){//settings_event_compatibility_submit
				SET_BUTTON_CLICK_ACTION("settings_event_following_options1");
			}
			SET_BUTTON_CLICK_ACTION("settings_event_following_formValue");
			$('#settings_event_following_formValue').unbind().click(function(event) {
				$('#settings_event_following_options1').unbind().click();
			});
			SET_BUTTON_CLICK_ACTION("settings_event_recommendations_formValue");
			$('#settings_event_recommendations_formValue').unbind().click(function(event) {
				$('#settings_event_recommendations_options1').unbind().click();
			});
			SET_BUTTON_CLICK_ACTION("settings_event_shares_formValue");
			$('#settings_event_shares_formValue').unbind().click(function(event) {
				$('#settings_event_shares_options1').unbind().click();
			});
			SET_BUTTON_CLICK_ACTION("settings_event_followers_formValue");
			$('#settings_event_followers_formValue').unbind().click(function(event) {
				$('#settings_event_followers_options1').unbind().click();
			});
			SET_BUTTON_CLICK_ACTION("settings_event_compatibility_formValue");
			$('#settings_event_compatibility_formValue').unbind().click(function(event) {
				$('#settings_event_compatibility_options1').unbind().click();
			});
			SET_BUTTON_CLICK_ACTION("settings_event_invites_formValue");
			$('#settings_event_invites_formValue').unbind().click(function(event) {
				$('#settings_event_compatibility_options1').unbind().click();
			});
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($('#media_menu_options1').is(':visible')) {
				if( $('#image_object').val()!='[]'){
					PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#media_menu_options1'),null,null,null,null);
				}
            }
			if($('#media_menu_index3').is(':visible')) {
				if( $('#image_object').val()!='[]'){
					PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#media_menu_index3'),null,null,null,null);
				}
            }
			SET_BUTTON_CLICK_ACTION("media_menu_options2");
			if($('#media_menu_index5').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("media_menu_index5");
                $('#media_menu_index5').unbind().click(function(event) {
					$('#media_menu_options2').unbind().click();
                });
            }
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('BUSINESS_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									CONSOLE_MANAGER('BUSINESS_basics',mutation.type+': attributes');
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
                                            var $length = parseInt($('#population_index_count').val())+1;
                                            var $code = $("#population_index").val();
                                            for(var $i=0; $i< $length; $i++){
                                                var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                                                //var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                                var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                                                var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                                                var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                                                var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                                                header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                                            }
											//}
											/* -------------------------------------------------------- */
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqUserId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('BUSINESS_basics','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
                                        else{
                                            CONSOLE_MANAGER('BUSINESS_basics','$DATES_obj_array = JSON ERROR');
                                        }
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('BUSINESS_basics',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							var $MODIFY_CNTRL = $('#standard_entity_modify_control').val();
                            CONSOLE_MANAGER('BUSINESS_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            /* --------------------------------------------------------  */	
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('BUSINESS_basics',mutation.type+': attributes');
                                /* -------------------------------------------------------- */
								if($('#window_view').is(':visible')){
                                    if($MODIFY_CNTRL=='EVENT_MAP_GLOBAL'){
                                        event_profile_pop_manager_map_activate();
                                        //$('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    /* -------------------------------------------------------- */
                                    if($MODIFY_CNTRL=='EVENT_DESCRIPTION_VIEW'){
                                        event_description_activate2();
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    //$('#event_register_div_blocker').hide();  
                                    /* -------------------------------------------------------- */
                                    if($MODIFY_CNTRL=='EVENT_DESCRIPTION_EDIT'){
                                        event_description_activate();
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    if($MODIFY_CNTRL=='EVENT_MAP_GLOBAL'){
                                        event_create_pop_manager_map_activate();
                                        //$('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
                                    }
								}
                                /* -------------------------------------------------------- */
								if(!$('#window_view').is(':visible')){
									
                                    
								}
								/* -------------------------------------------------------- */
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('BUSINESS_basics',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_PROFILE_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_count_activate(){//event_register_div_manager settings_event_description_edit_title"
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_title")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_title");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_title").value;

                        $('#settings_event_description_edit_title').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_title').val($('#settings_event_description_edit_title').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_title').val();
                                $('#settings_event_description_edit_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                        });
                    }
                    /* --------------------------------------------------------  */
                    var ttextarea = null;
                    var tresult = null;
                    var tNEW_VAL = null;

                    //var MAXVAL= $("#formCount4").val();
                    var tMAXVAL= $("#settings_event_description_edit_title").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter4").value=tMAXVAL;
                    if(document.getElementById("settings_event_description_edit_title")){
                        ttextarea = document.getElementById("settings_event_description_edit_title");
                        if(document.getElementById("settings_event_description_edit_formFilter4")){
                            tresult   = document.getElementById("settings_event_description_edit_formFilter4");
                            //result.value = tMAXVAL;
                            ttextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                tNEW_VAL = tMAXVAL - v.characters;
                                tresult.value = tNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText1")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_descriptionText1");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText1").value;

                        $('#settings_event_description_edit_descriptionText1').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText1').val($('#settings_event_description_edit_descriptionText1').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText1").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText1').val();
                                $('#settings_event_description_edit_descriptionText1').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText1').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText1').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var htextarea = null;
                    var hresult = null;
                    var hNEW_VAL = null;

                    //var hMAXVAL= $("#formCount1").val();
                    var hMAXVAL= $("#settings_event_description_edit_descriptionText1").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter1").value=hMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText1")){
                        htextarea = document.getElementById("settings_event_description_edit_descriptionText1");
                        if(document.getElementById("settings_event_description_edit_formFilter1")){
                            hresult   = document.getElementById("settings_event_description_edit_formFilter1");
                            //result.value = hMAXVAL;
                            htextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                hNEW_VAL = hMAXVAL - v.characters;
                                hresult.value = hNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText2")){
                    var reg_desciption_var2 = document.getElementById("settings_event_description_edit_descriptionText2");


                    reg_desciption_var2.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText2").value;

                        $('#settings_event_description_edit_descriptionText2').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText2').val($('#settings_event_description_edit_descriptionText2').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText2").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText2').val();
                                $('#settings_event_description_edit_descriptionText2').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText2').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText2').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var btextarea = null;
                    var bresult = null;
                    var bNEW_VAL = null;

                    //var bMAXVAL= $("#formCount2").val();
                    var bMAXVAL= $("#settings_event_description_edit_descriptionText2").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter2").value=bMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText2")){
                        btextarea = document.getElementById("settings_event_description_edit_descriptionText2");
                        if(document.getElementById("settings_event_description_edit_formFilter2")){
                            bresult   = document.getElementById("settings_event_description_edit_formFilter2");
                            //result.value = bMAXVAL;
                            btextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                bNEW_VAL = bMAXVAL - v.characters;
                                bresult.value = bNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText3")){
                    var reg_desciption_var3 = document.getElementById("settings_event_description_edit_descriptionText3");


                    reg_desciption_var3.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText3").value;

                        $('#settings_event_description_edit_descriptionText3').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText3').val($('#settings_event_description_edit_descriptionText3').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText3").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText3').val();
                                $('#settings_event_description_edit_descriptionText3').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText3').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText3').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var ftextarea = null;
                    var fresult = null;
                    var fNEW_VAL = null;

                    //var fMAXVAL= $("#formCount3").val();
                    var fMAXVAL= $("#settings_event_description_edit_descriptionText3").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter3").value=fMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText3")){
                        ftextarea = document.getElementById("settings_event_description_edit_descriptionText3");
                        if(document.getElementById("settings_event_description_edit_formFilter3")){
                            fresult   = document.getElementById("settings_event_description_edit_formFilter3");
                            //result.value = fMAXVAL;
                            ftextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                fNEW_VAL = fMAXVAL - v.characters;
                                fresult.value = fNEW_VAL;
                            }, false);
                        }
                    }
                }
                //setTimeout(is_review_complete, 500);
            }/*END OF FUNCTION*/
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_activate(){
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                /*
                    'STANDARD'
                    'TITLE_BODY'
                    'BODY'
                    
                    'HIDDEN'=>'any_visuallyhidden',
                    'STANDARD1'=>'event_edit_description_field1_1',
                    'STANDARD2'=>'event_edit_description_field1_2',
                    'STANDARD3'=>'event_edit_description_field1_3',
                    'TITLE_BODY1'=>'event_edit_description_field2_1',
                    'TITLE_BODY2'=>'event_edit_description_field2_3',
                    'BODY1'=>'event_edit_description_field3_1',
                    
                    'MENU_STANDARD_DEFAULT'=>'event_edit_description_button_default1',
                    'MENU_STANDARD_ACTIVE'=>'event_edit_description_button_active1',
                    'MENU_TITLE_DEFAULT'=>'event_edit_description_button_default2',
                    'MENU_TITLE_ACTIVE'=>'event_edit_description_button_active2',
                    'MENU_BODY_DEFAULT'=>'event_edit_description_button_default3',
                    'MENU_BODY_ACTIVE'=>'event_edit_description_button_active3',
                    
                    'TD_STANDARD1'=>'event_edit_pop_td2_1_ST',
                    'TD_STANDARD2'=>'event_edit_pop_td2_2_ST',
                    'TD_STANDARD3'=>'event_edit_pop_td2_3_ST',
                    
                    'TD_TITLE_BODY1'=>'event_edit_pop_td2_1_TB',
                    'TD_TITLE_BODY2'=>'event_edit_pop_td2_2_TB',
                    
                    'TD_BODY1'=>'event_edit_pop_td2_1_B',
                    
                    'STANDARD_BODY'
                    'DESCRIPTION'=>'business_display_bs_description',
                    'SCORE'=>'business_display_rating',
                    'MAP'=>'business_display_map'
                */
                /* -------------------------------------------------------- */
                $('#notificationsystem_menu_action').hide();
                //$('#notificationsystem_menu_action').show();
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options1");
                var $HIDDEN, $PARENT, $TITLE1, $TITLE2, $STANDARD1,$STANDARD2,$STANDARD3,$TITLE_BODY1,$TITLE_BODY2,$BODY1,$MENU_STANDARD_DEFAULT,$MENU_STANDARD_ACTIVE,$MENU_TITLE_DEFAULT,$MENU_TITLE_ACTIVE,$MENU_BODY_DEFAULT,$MENU_BODY_ACTIVE,$TD_STANDARD1,$TD_STANDARD2,$TD_STANDARD3,$TD_TITLE_BODY1,$TD_TITLE_BODY2,$TD_BODY1,$COUNT_STANDARD1,$COUNT_STANDARD2,$COUNT_STANDARD3,$COUNT_TITLE_BODY1,$COUNT_TITLE_BODY2,$COUNT_BODY1,$ST_DESCRIPTION, $ST_SCORE, $ST_MAP, $ST_BODY = null;
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_description_edit_index4').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_description_edit_index4').val());
                    if($DATES_obj_array != null){

                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    /* -------------------------------------------------------- */
                                    CONSOLE_MANAGER('event_description_activate','DATA FOR CLASS: '+element.HIDDEN+' -|COUNT|- '+element.STANDARD1+' -|STATUS|- '+element.STANDARD2+' -- '+element.STANDARD3+' -- '+element.TITLE_BODY1+' -|LIST|- '+element.TITLE_BODY2+' -- '+element.BODY1+' -|VIEW|- '+element.MENU_STANDARD_DEFAULT+' -- '+element.MENU_STANDARD_ACTIVE+' -|SCROLL|- '+element.MENU_TITLE_DEFAULT+' -- '+element.MENU_TITLE_ACTIVE);
                                    /* -------------------------------------------------------- */
                                    $PARENT = element.PARENT;
                                    $HIDDEN = element.HIDDEN;
                                    $TITLE1 = element.TITLE1;
                                    $TITLE2 = element.TITLE2;
                                    $STANDARD1 = element.STANDARD1;
                                    $STANDARD2 = element.STANDARD2;
                                    $STANDARD3 = element.STANDARD3;
                                    $TITLE_BODY1 = element.TITLE_BODY1;
                                    $TITLE_BODY2 = element.TITLE_BODY2;
                                    $BODY1 = element.BODY1;
                                    
                                    $TD_STANDARD1 = element.TD_STANDARD1;
                                    $TD_STANDARD2 = element.TD_STANDARD2;
                                    $TD_STANDARD3 = element.TD_STANDARD3;
                                    //$TD_TITLE_BODY1 = element.TD_TITLE_BODY1;
                                    //$TD_TITLE_BODY2 = element.TD_TITLE_BODY2;
                                    //$TD_BODY1 = element.TD_BODY1;
                                    
                                    
                                    $MENU_STANDARD_DEFAULT = element.MENU_STANDARD_DEFAULT;
                                    $MENU_STANDARD_ACTIVE = element.MENU_STANDARD_ACTIVE;
                                    $MENU_TITLE_DEFAULT = element.MENU_TITLE_DEFAULT;
                                    $MENU_TITLE_ACTIVE = element.MENU_TITLE_ACTIVE;
                                    $MENU_BODY_DEFAULT = element.MENU_BODY_DEFAULT;
                                    $MENU_BODY_ACTIVE = element.MENU_BODY_ACTIVE;
                                    
                                    $COUNT_STANDARD1 = element.COUNT_STANDARD1;
                                    $COUNT_STANDARD2 = element.COUNT_STANDARD2;
                                    $COUNT_STANDARD3 = element.COUNT_STANDARD3;
                                    $COUNT_TITLE_BODY1 = element.COUNT_TITLE_BODY1;
                                    $COUNT_TITLE_BODY2 = element.COUNT_TITLE_BODY2;
                                    $COUNT_BODY1 = element.COUNT_BODY1;
                                    
                                    $ST_BODY = element.STANDARD_BODY;
                                    $ST_DESCRIPTION = element.DESCRIPTION;
                                    $ST_SCORE = element.SCORE;
                                    $ST_MAP = element.MAP;
                                }
                            });
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options2");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options3");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options4");
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_title').addClass($TITLE1);
                            $('#settings_event_description_edit_title').removeClass($TITLE2);
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options2').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options2 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                
                                */
                                //if($('#settings_event_description_edit_options2').hasClass($MENU_STANDARD_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('STANDARD');
                                    /* ---------------STANDARD---------------------------------  */                         
                                    $('#settings_event_description_edit_options13').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options13').removeClass($ST_MAP);
                                    $('#settings_event_description_edit_options13').addClass($ST_MAP);
                                
                                    $('#settings_event_description_edit_options14').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options14').removeClass($ST_SCORE);
                                    $('#settings_event_description_edit_options14').addClass($ST_SCORE);
                                    
                                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                                    $('#settings_event_description_edit_descriptionText1').addClass($ST_BODY);
                                
                                    $('#settings_event_description_edit_formFilter1').show();
                                    $('#settings_event_description_edit_descriptionText1').show();
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        //$('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  
                                        if($('#settings_event_description_edit_options14').is(':visible')) {
                                            micro_map_activate();
                                        }
                                        */
                                        /* --------------------------------------------------------  */
                                        /* --------------------------------------------------------  */
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        //$('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        //$('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                //}
                                event_description_count_activate();
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options3').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options3 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                                */
                                /* --------------------------------------------------------  */
                                //if($('#settings_event_description_edit_options3').hasClass($MENU_TITLE_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                                    /* ----------NO MAP------------------------------------  */                                    
                                    $('#settings_event_description_edit_options13').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options13').removeClass($ST_MAP);
                                    $('#settings_event_description_edit_options13').addClass($HIDDEN);
                                
                                    $('#settings_event_description_edit_options14').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options14').removeClass($ST_SCORE);
                                    $('#settings_event_description_edit_options14').addClass($ST_SCORE);
                                    
                                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);//settings_event_description_formCurrentIndex
                                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                                    $('#settings_event_description_edit_descriptionText1').addClass($ST_BODY);
                                    
                                    $('#settings_event_description_edit_formFilter1').show();
                                    $('#settings_event_description_edit_descriptionText1').show();
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        //$('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        //$('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        //$('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options4').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options4 CLICK - '+$MENU_BODY_ACTIVE+' - '+$MENU_BODY_DEFAULT);
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                //if($('#settings_event_description_edit_options4').hasClass($MENU_BODY_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('BODY');
                                    /* ----------NO MAP SCORE DESCRIPTION-----------------------  */
                                    $('#settings_event_description_edit_options13').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options13').removeClass($ST_MAP);
                                    $('#settings_event_description_edit_options13').addClass($HIDDEN);
                                
                                    $('#settings_event_description_edit_options14').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_options14').removeClass($ST_SCORE);
                                    $('#settings_event_description_edit_options14').addClass($HIDDEN);
                                    
                                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                                    $('#settings_event_description_edit_descriptionText1').addClass($HIDDEN);
                                    
                                    $('#settings_event_description_edit_formFilter1').hide();
                                    $('#settings_event_description_edit_descriptionText1').hide();
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        //$('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_BODY1));
                                        $('#formCount2').val(parseInt(0));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        //$('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        //$('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        //$('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                        }
                    }
                }else{
                    CONSOLE_MANAGER('event_description_activate','INVALID JSON');
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('QR_CODE_DIV','QR CODE - PATH - --- -- - - '+$('#QR_CODE_DIV').attr('src'));
                if($('#QR_CODE_DIV').attr('src')){
                    var path_var = $('#QR_CODE_DIV').attr('src');
                    $('#settings_event_description_edit_options10').css('background-image','url('+path_var+')');
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('SCORE','DESCRIPTION SCORE VIEW ACTIVE');
                if($('#settings_event_description_edit_options14').is(':visible')) {
                    var $SCORE = parseInt($('#settings_event_activity_formValue').val());
                    EVENT_METRICS_CONTROL_basics($('#settings_event_description_edit_options14'),$SCORE);
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('GUIDELINE','DESCRIPTION GUIDELINE VIEW ACTIVE -  -  '+$('#settings_event_guidelines_profile_options1').css('background-image'));
                if($('#settings_event_guidelines_profile_options1').css('background-image')){
                    var path_var = $('#settings_event_guidelines_profile_options1').css('background-image');
                    $('#settings_event_description_edit_options15').css('background-image',path_var);
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                if($('#settings_event_description_edit_descriptionType').val()=='STANDARD' || !$('#settings_event_description_edit_descriptionType').val()){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE STANDARD');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    //$('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_STANDARD1));
                    $('#formCount2').val(parseInt($COUNT_STANDARD2));
                    $('#formCount3').val(parseInt($COUNT_STANDARD3));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                    $('#settings_event_description_edit_descriptionText1').addClass($ST_BODY);

                    //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    //$('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);

                    //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    //$('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                    
                    /* --------------------------------------------------------  */
                    if($('#settings_event_description_edit_options13').is(':visible')) {
                        description_micro_map_activate();
                    }
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='TITLE_BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE TITLE_BODY');
                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    //$('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                    $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                    $('#settings_event_description_edit_descriptionText1').addClass($ST_BODY);

                    //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    //$('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);

                    //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    //$('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE BODY');
                    $('#settings_event_description_edit_descriptionType').val('BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    //$('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_BODY1));
                    $('#formCount2').val(parseInt(0));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($ST_BODY);
                    $('#settings_event_description_edit_descriptionText1').addClass($HIDDEN);

                    //$('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    //$('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    //$('#settings_event_description_edit_descriptionText2').addClass($HIDDEN);

                    //$('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    //$('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    //$('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                event_description_count_activate();
                window_complete_script('form_event_creator_MGMT - event_description_activate');
            }/*END OF FUNCTION*/
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_activate2(){
                CONSOLE_MANAGER('event_description_activate2','ACTIVE');
                /* --------------------------------------------------------  */
                /*
                    'STANDARD'
                    'TITLE_BODY'
                    'BODY'
                    
                    'HIDDEN'=>'any_visuallyhidden',
                    'STANDARD1'=>'event_edit_description_field1_1',
                    'STANDARD2'=>'event_edit_description_field1_2',
                    'STANDARD3'=>'event_edit_description_field1_3',
                    'TITLE_BODY1'=>'event_edit_description_field2_1',
                    'TITLE_BODY2'=>'event_edit_description_field2_3',
                    'BODY1'=>'event_edit_description_field3_1',
                    
                    'MENU_STANDARD_DEFAULT'=>'event_edit_description_button_default1',
                    'MENU_STANDARD_ACTIVE'=>'event_edit_description_button_active1',
                    'MENU_TITLE_DEFAULT'=>'event_edit_description_button_default2',
                    'MENU_TITLE_ACTIVE'=>'event_edit_description_button_active2',
                    'MENU_BODY_DEFAULT'=>'event_edit_description_button_default3',
                    'MENU_BODY_ACTIVE'=>'event_edit_description_button_active3',
                    
                    'TD_STANDARD1'=>'event_edit_pop_td2_1_ST',
                    'TD_STANDARD2'=>'event_edit_pop_td2_2_ST',
                    'TD_STANDARD3'=>'event_edit_pop_td2_3_ST',
                    
                    'TD_TITLE_BODY1'=>'event_edit_pop_td2_1_TB',
                    'TD_TITLE_BODY2'=>'event_edit_pop_td2_2_TB',
                    
                    'TD_BODY1'=>'event_edit_pop_td2_1_B',
                */
                /* -------------------------------------------------------- */
                $('#notificationsystem_menu_action').hide();
                //$('#notificationsystem_menu_action').show();
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("settings_event_description_view_options1");
                var $HIDDEN, $PARENT, $TITLE1, $TITLE2, $STANDARD1,$STANDARD2,$STANDARD3,$TITLE_BODY1,$TITLE_BODY2,$BODY1,$MENU_STANDARD_DEFAULT,$MENU_STANDARD_ACTIVE,$MENU_TITLE_DEFAULT,$MENU_TITLE_ACTIVE,$MENU_BODY_DEFAULT,$MENU_BODY_ACTIVE,$TD_STANDARD1,$TD_STANDARD2,$TD_STANDARD3,$TD_TITLE_BODY1,$TD_TITLE_BODY2,$TD_BODY1,$COUNT_STANDARD1,$COUNT_STANDARD2,$COUNT_STANDARD3,$COUNT_TITLE_BODY1,$COUNT_TITLE_BODY2,$COUNT_BODY1,$ST_DESCRIPTION, $ST_SCORE, $ST_MAP, $ST_BODY = null;
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_description_view_index4').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_description_view_index4').val());
                    if($DATES_obj_array != null){

                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    /* -------------------------------------------------------- */
                                    CONSOLE_MANAGER('event_description_activate','DATA FOR CLASS: '+element.HIDDEN+' -|COUNT|- '+element.STANDARD1+' -|STATUS|- '+element.STANDARD2+' -- '+element.STANDARD3+' -- '+element.TITLE_BODY1+' -|LIST|- '+element.TITLE_BODY2+' -- '+element.BODY1+' -|VIEW|- '+element.MENU_STANDARD_DEFAULT+' -- '+element.MENU_STANDARD_ACTIVE+' -|SCROLL|- '+element.MENU_TITLE_DEFAULT+' -- '+element.MENU_TITLE_ACTIVE);
                                    /* -------------------------------------------------------- */
                                    $PARENT = element.PARENT;
                                    $HIDDEN = element.HIDDEN;
                                    $TITLE1 = element.TITLE1;
                                    $TITLE2 = element.TITLE2;
                                    $STANDARD1 = element.STANDARD1;
                                    $STANDARD2 = element.STANDARD2;
                                    $STANDARD3 = element.STANDARD3;
                                    $TITLE_BODY1 = element.TITLE_BODY1;
                                    $TITLE_BODY2 = element.TITLE_BODY2;
                                    $BODY1 = element.BODY1;
                                    
                                    $TD_STANDARD1 = element.TD_STANDARD1;
                                    $TD_STANDARD2 = element.TD_STANDARD2;
                                    $TD_STANDARD3 = element.TD_STANDARD3;
                                    //$TD_TITLE_BODY1 = element.TD_TITLE_BODY1;
                                    //$TD_TITLE_BODY2 = element.TD_TITLE_BODY2;
                                    //$TD_BODY1 = element.TD_BODY1;
                                    
                                    
                                    $MENU_STANDARD_DEFAULT = element.MENU_STANDARD_DEFAULT;
                                    $MENU_STANDARD_ACTIVE = element.MENU_STANDARD_ACTIVE;
                                    $MENU_TITLE_DEFAULT = element.MENU_TITLE_DEFAULT;
                                    $MENU_TITLE_ACTIVE = element.MENU_TITLE_ACTIVE;
                                    $MENU_BODY_DEFAULT = element.MENU_BODY_DEFAULT;
                                    $MENU_BODY_ACTIVE = element.MENU_BODY_ACTIVE;
                                    
                                    $COUNT_STANDARD1 = element.COUNT_STANDARD1;
                                    $COUNT_STANDARD2 = element.COUNT_STANDARD2;
                                    $COUNT_STANDARD3 = element.COUNT_STANDARD3;
                                    $COUNT_TITLE_BODY1 = element.COUNT_TITLE_BODY1;
                                    $COUNT_TITLE_BODY2 = element.COUNT_TITLE_BODY2;
                                    $COUNT_BODY1 = element.COUNT_BODY1;
                                    
                                    $ST_BODY = element.STANDARD_BODY;
                                    $ST_DESCRIPTION = element.DESCRIPTION;
                                    $ST_SCORE = element.SCORE;
                                    $ST_MAP = element.MAP;
                                }
                            });
                            //SET_BUTTON_CLICK_ACTION("settings_event_description_view_options2");
                            //SET_BUTTON_CLICK_ACTION("settings_event_description_view_options3");
                            //SET_BUTTON_CLICK_ACTION("settings_event_description_view_options4");
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_view_title').addClass($TITLE1);
                            $('#settings_event_description_view_title').removeClass($TITLE2);                            
                        }
                    }
                }else{
                    CONSOLE_MANAGER('event_description_activate','INVALID JSON');
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('QR_CODE_DIV','QR CODE - PATH - --- -- - - '+$('#QR_CODE_DIV').attr('src'));
                if($('#QR_CODE_DIV').attr('src')){
                    var path_var = $('#QR_CODE_DIV').attr('src');
                    $('#settings_event_description_view_options10').css('background-image','url('+path_var+')');
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('SCORE','DESCRIPTION SCORE VIEW ACTIVE');
                if($('#settings_event_description_view_options14').is(':visible')) {
                    var $SCORE = parseInt($('#settings_event_activity_formValue').val());
                    EVENT_METRICS_CONTROL_basics($('#settings_event_description_view_options14'),$SCORE);
                }
                /* --------------------------------------------------------  */
                CONSOLE_MANAGER('GUIDELINE','DESCRIPTION GUIDELINE VIEW ACTIVE -  -  '+$('#settings_event_guidelines_profile_options1').css('background-image'));
                if($('#settings_event_guidelines_profile_options1').css('background-image')){
                    var path_var = $('#settings_event_guidelines_profile_options1').css('background-image');
                    $('#settings_event_description_view_options15').css('background-image',path_var);
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                if($('#settings_event_description_view_descriptionType').val()=='STANDARD' || !$('#settings_event_description_view_descriptionType').val()){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE STANDARD');
                    /* --------------------------------------------------------  
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                    */
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    //$('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    //$('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    //$('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_STANDARD1));
                    $('#formCount2').val(parseInt($COUNT_STANDARD2));
                    $('#formCount3').val(parseInt($COUNT_STANDARD3));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                    //$('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                    //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_view_descriptionText1').removeClass($ST_BODY);
                    $('#settings_event_description_view_descriptionText1').addClass($ST_BODY);

                    //$('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                    //$('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                    //$('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                    //$('#settings_event_description_view_descriptionText2').addClass($STANDARD2);

                    //$('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                    //$('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                    //$('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                    
                    /* --------------------------------------------------------  */
                    if($('#settings_event_description_view_options13').is(':visible')) {
                        description_micro_map_activate();
                    }
                    /* --------------------------------------------------------  */
                }
                //event_description_count_activate();
            }/*END OF FUNCTION*/
			/* ------------------------------------------------- */	
			/* ------------------------------------------------- */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		window_complete_script('BUSINESS_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_recommendation_select_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_recommendation_select_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options1");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options1active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options2");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options2active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options3");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options4");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options4active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options1");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options2");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options1");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options1active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options2");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options2active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options3");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options3active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options4");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options4active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('BUSINESS_recommendation_select_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
					}	
				} 
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
				$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
                $('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options4').attr('id'));
                //$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
                 /*
                REGISTER_POP
                */
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options4'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options12').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options12");
				$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options12').attr('id'));
                //$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
                 /*
                REGISTER_POP
                */
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options12'),null,null,null,null);
            }
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if( $('#select_menu_item_verificationCode').val()){
				SET_BUTTON_CLICK_ACTION("select_menu_item_options9");
				$('#select_menu_item_options9').unbind().click(function(event) {
					$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
					$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
					$('#standard_entity_modify_index2').val($('#select_menu_item_uniqEventId').val());
					$('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
					$('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
					$('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
					$('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
					if($('#select_menu_item_index3')){
						$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
					}else{
						$('#standard_entity_modify_formEntity').val(null); 
					}
					$('#standard_entity_modify_index7').val($('#select_menu_item_verificationCode').val());
					$('#standard_entity_modify_index10').val( $('#select_menu_item_options9').attr('id'));
					//$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
					 /*
					REGISTER_POP
					*/
					$('#standard_entity_modify_control').val($('#select_menu_item_control').val());
					settings_request_function(event);
				});
				DURATION($('#select_menu_item_formFilter3'),$('#select_menu_item_options6'),$('#select_menu_item_title').val(),$('#select_menu_item_activityStatus').val(),$('#select_menu_item_duration').val());
			}	
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index5').val($INDEX05.val());
                    $('#standard_entity_modify_index6').val($INDEX06.val());
                    if($('#select_menu_item_index3')){
                        $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                    }else{
                        $('#standard_entity_modify_formEntity').val(null); 
                    }
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#select_menu_item_duration').val()==7){
				$('#select_menu_item_options20').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==14){
				$('#select_menu_item_options21').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==21){
				$('#select_menu_item_options22').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==30){
				$('#select_menu_item_options23').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==90){
				$('#select_menu_item_options24').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==180){
				$('#select_menu_item_options25').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==270){
				$('#select_menu_item_options26').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==360){
				$('#select_menu_item_options27').addClass('recommendation_active');
			}
			if(($('#select_menu_item_duration').val()=='UNLIMITED')||($('#select_menu_item_duration').val()=='FREE')||($('#select_menu_item_duration').val()==0)){
				$('#select_menu_item_options28').addClass('recommendation_active');
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('BUSINESS_recommendation_select_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if($('#standard_results_ajax_control').val()){
								$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
								if($('#search_request_control').length){
								   $SEARCH_CNTRL = $('#search_request_control').val();
								}

								if(mutation.type=='attributes'){
									if($("#window_view").is(':visible')){
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/**********		CONFIRM_MGMT	**********/
										if($RESULTS_CNTRL.includes('Interaction_Activity_UI_class')){
											//if($CONFIRM_CNTRL.includes('options2')){
											var close_div_var = null;
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {	
													$('#select_menu_item_options4').unbind().click();
													//windowload_script('function:  form_event_itemfunction_MGMT  ');
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											//}
										}
										/* -------------------------------------------------------- */
									}
								}else if(mutation.type=='childList'){
									/* --------------------------------------------------------  */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		window_complete_script('BUSINESS_recommendation_select_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_recommendation_management_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_recommendation_management_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();	BUSINESS_recommendation_management_basics
			if($('#event_manager_menu_index2').val()=='MAPVIEW'){
			   business_recommendations_map();
			}
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options1");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options1active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options2");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options2active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options3");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options4");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_menu_options4active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options1");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options2");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3");
			SET_BUTTON_CLICK_ACTION("recommendation_manager_logo_options3active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options1");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options1active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options2");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options2active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options3");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options3active");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options4");
			SET_BUTTON_CLICK_ACTION("event_manager_menu_options4active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('BUSINESS_recommendation_select_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
						var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
						
						var $OPTIONS15 = '#form_'+$code+'_'+$i+'_options15';
						var $OPTIONS17 = '#form_'+$code+'_'+$i+'_options17';
						var $OPTIONS18 = '#form_'+$code+'_'+$i+'_options18';
						var $OPTIONS19 = '#form_'+$code+'_'+$i+'_options19';
						
						var $OPTIONS20 = '#form_'+$code+'_'+$i+'_options20';
						var $OPTIONS21 = '#form_'+$code+'_'+$i+'_options21';
						var $OPTIONS22 = '#form_'+$code+'_'+$i+'_options22';
						var $OPTIONS23 = '#form_'+$code+'_'+$i+'_options23';
						var $OPTIONS24 = '#form_'+$code+'_'+$i+'_options24';
						var $OPTIONS25 = '#form_'+$code+'_'+$i+'_options25';
						var $OPTIONS26 = '#form_'+$code+'_'+$i+'_options26';
						var $OPTIONS27 = '#form_'+$code+'_'+$i+'_options27';
						var $OPTIONS28 = '#form_'+$code+'_'+$i+'_options28';
						
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						var $INDEX08 = '#form_'+$code+'_'+$i+'_index8';
						var $INDEX09 = '#form_'+$code+'_'+$i+'_index9';
						/* ------------------------------------------------- */
						DURATION($($OPTIONS15),$($OPTIONS15),$($TITLE).val(),$($INDEX08).val(),$($INDEX05).val());
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						/* ------------------------------------------------- */
						if($($INDEX09).val()!='ACTIVE'){
							REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options18');
							SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options19');
							REGISTER_POP($($OPTIONS18),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($OPTIONS19));
							REGISTER_POP($($OPTIONS19),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($OPTIONS19));
							REGISTER_CLICK($($OPTIONS20),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS21),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS22),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS23),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS24),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS25),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS26),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS27),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
							REGISTER_CLICK($($OPTIONS28),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07));
						}
						/* ------------------------------------------------- */
					}	
				} 
			}
			/* -------------------------------------------------------- */
			//CONFIRM_MGMT('select_menu_item_options4','.Interaction_Activity_UI_class');
			/* --------------------------------------------------------  */
			function REGISTER_POP($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06,$INDEX07,$INDEX019){
				
				$button_obj.unbind().click(function(event) {
					$('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index5').val($INDEX05.val());
                    $('#standard_entity_modify_index6').val($INDEX06.val());
                    if($('#select_menu_item_index3')){
                        $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                    }else{
                        $('#standard_entity_modify_formEntity').val(null); 
                    }
                    $('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_index10').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($('#event_manager_menu_control').val());
					$('#standard_entity_modify_dollarValue').val($INDEX019.val());
					settings_request_function(event);
				});
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                   	$('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index5').val($INDEX05.val());
                    $('#standard_entity_modify_index6').val($INDEX06.val());
                    if($('#select_menu_item_index3')){
                        $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                    }else{
                        $('#standard_entity_modify_formEntity').val(null); 
                    }
                    $('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_index10').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		window_complete_script('BUSINESS_recommendation_management_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_reporting_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_reporting_basics','ACTIVATED');
			/* -------------------------------------------------------- */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
					}	
				}
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.val());
					$('#standard_entity_modify_index7').val($INDEX07.val());
                    //$('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}
			/* -------------------------------------------------------- */		
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		window_complete_script('BUSINESS_reporting_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_settings_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_settings_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#account_metrics_control').val()){ 
				SETTINGS_METRICS_CONTROL();
			}
			/* --------------------------------------------------------  */
			business_header_background_activate();
			BUSINESS_PHOTO_basics();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("notification_options1");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_menu_options1");
			SET_BUTTON_CLICK_ACTION("settings_menu_options2");
			SET_BUTTON_CLICK_ACTION("settings_menu_options3");
			SET_BUTTON_CLICK_ACTION("settings_menu_options4");
			SET_BUTTON_CLICK_ACTION("settings_menu_options5");
			SET_BUTTON_CLICK_ACTION("settings_menu_options6");
			SET_BUTTON_CLICK_ACTION("settings_menu_options1active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options2active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options3active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options4active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options5active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options6active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("account_schedule_options6");
			SET_BUTTON_CLICK_ACTION("account_schedule_options8");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("account_menu_options1");
			SET_BUTTON_CLICK_ACTION("account_menu_options2");
			SET_BUTTON_CLICK_ACTION("account_menu_options3");
			SET_BUTTON_CLICK_ACTION("account_menu_options4");
			SET_BUTTON_CLICK_ACTION("account_menu_options5");
			SET_BUTTON_CLICK_ACTION("account_menu_options6");
			SET_BUTTON_CLICK_ACTION("account_menu_options1active");
			SET_BUTTON_CLICK_ACTION("account_menu_options2active");
			SET_BUTTON_CLICK_ACTION("account_menu_options3active");
			SET_BUTTON_CLICK_ACTION("account_menu_options4active");
			SET_BUTTON_CLICK_ACTION("account_menu_options5active");
			SET_BUTTON_CLICK_ACTION("account_menu_options6active");
			/* --------------------------------------------------------  */
			if($('#business_account_summary_options1')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options1");
			}
			if($('#business_account_summary_options2')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options2");
			}
			if($('#business_account_summary_options3')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options3");
			}
			if($('#business_account_summary_options4')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options4");
			}
            /* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';
			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_bio_bioselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_bio1_var, set_bio1_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_bio2_var, set_bio2_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupC+set1+"_input");
				//	var set_button_input_value = document.getElementById("account_bio_bioselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_bio3_var, set_bio3_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_bio4_var, set_bio4_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_bio5_var, set_bio5_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_bio6_var, set_bio6_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_bio7_var, set_bio7_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_bio8_var, set_bio8_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_bio9_var, set_bio9_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_bio10_var, set_bio10_value);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';

			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_geo_geoselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_geo1_var, set_geo1_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_geo2_var, set_geo2_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupC+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_geo3_var, set_geo3_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_geo4_var, set_geo4_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_geo5_var, set_geo5_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_geo6_var, set_geo6_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_geo7_var, set_geo7_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_geo8_var, set_geo8_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_geo9_var, set_geo9_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_geo10_var, set_geo10_value);
				}
			}
			/* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';
			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_socio_socioselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_socio1_var, set_socio1_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_socio2_var, set_socio2_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupC+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_socio3_var, set_socio3_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_socio4_var, set_socio4_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_socio5_var, set_socio5_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_socio6_var, set_socio6_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_socio7_var, set_socio7_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_socio8_var, set_socio8_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_socio9_var, set_socio9_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_socio10_var, set_socio10_value);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_security_options2")){
				SET_BUTTON_CLICK_ACTION("account_security_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options2'),null,null,null,null);
			}
			if(document.getElementById("account_security_options3")){
				SET_BUTTON_CLICK_ACTION("account_security_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options3'),null,null,null,null);
			}
			if(document.getElementById("account_security_options4")){
				SET_BUTTON_CLICK_ACTION("account_security_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options4'),null,null,null,null);
			}
			if(document.getElementById("account_security_options5")){
				SET_BUTTON_CLICK_ACTION("account_security_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options4'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_security_options2active")){
				SET_BUTTON_CLICK_ACTION("account_security_options2active");
			}
			if(document.getElementById("account_security_options3active")){
				SET_BUTTON_CLICK_ACTION("account_security_options3active");
			}
			if(document.getElementById("account_security_options4active")){
				SET_BUTTON_CLICK_ACTION("account_security_options4active");
			}
			if(document.getElementById("account_security_options5active")){
				SET_BUTTON_CLICK_ACTION("account_security_options5active");
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_notification_options2")){
				SET_BUTTON_CLICK_ACTION("account_notification_options2");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options2'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options3")){
				SET_BUTTON_CLICK_ACTION("account_notification_options3");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options3'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options4")){
				SET_BUTTON_CLICK_ACTION("account_notification_options4");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options4'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options5")){
				SET_BUTTON_CLICK_ACTION("account_notification_options5");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options5'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options6")){
				SET_BUTTON_CLICK_ACTION("account_notification_options6");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options6'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options7")){
				SET_BUTTON_CLICK_ACTION("account_notification_options7");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options7'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options9")){
				SET_BUTTON_CLICK_ACTION("account_notification_options9");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options9'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options10")){
				SET_BUTTON_CLICK_ACTION("account_notification_options10");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options10'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options11")){
				SET_BUTTON_CLICK_ACTION("account_notification_options11");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options11'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options12")){
				SET_BUTTON_CLICK_ACTION("account_notification_options12");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options12'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options14")){
				SET_BUTTON_CLICK_ACTION("account_notification_options14");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options14'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options15")){
				SET_BUTTON_CLICK_ACTION("account_notification_options15");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options15'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options16")){
				SET_BUTTON_CLICK_ACTION("account_notification_options16");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options16'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options17")){
				SET_BUTTON_CLICK_ACTION("account_notification_options17");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options17'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_schedule_options2")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options2'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options3")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options3'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options4")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options4'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options5")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options5");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options5'),null,null,null,null);
			}
			/* -------------------------------------------------------- */
			if(document.getElementById("account_metrics_options1active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options1active");
			}
			if(document.getElementById("account_metrics_options2active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options2active");
			}
			if(document.getElementById("account_metrics_options3active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options3active");
			}
			if(document.getElementById("account_metrics_options4active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options4active");
			}
			if(document.getElementById("account_metrics_options5active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options5active");
			}
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_settings_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_account_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('BUSINESS_account_basics','ACTIVATED');
			/* -------------------------------------------------------- */
			if($('.Pagination_UI_class_main')){
				SETTINGS_PAGINATION_CONTROL();
			}
            /* -------------------------------------------------------- */
            business_header_background_activate();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			search_bar_function('BUSINESS_account_basics');
            /* --------------------------------------------------------  */
			if($('#account_metrics_control').val()){ 
				SETTINGS_METRICS_CONTROL();
			}
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_menu_options1");
			SET_BUTTON_CLICK_ACTION("settings_menu_options2");
			SET_BUTTON_CLICK_ACTION("settings_menu_options3");
			SET_BUTTON_CLICK_ACTION("settings_menu_options4");
			SET_BUTTON_CLICK_ACTION("settings_menu_options5");
			SET_BUTTON_CLICK_ACTION("settings_menu_options6");
			SET_BUTTON_CLICK_ACTION("settings_menu_options1active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options2active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options3active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options4active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options5active");
			SET_BUTTON_CLICK_ACTION("settings_menu_options6active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("account_menu_options1");
			SET_BUTTON_CLICK_ACTION("account_menu_options2");
			SET_BUTTON_CLICK_ACTION("account_menu_options3");
			SET_BUTTON_CLICK_ACTION("account_menu_options4");
			SET_BUTTON_CLICK_ACTION("account_menu_options5");
			SET_BUTTON_CLICK_ACTION("account_menu_options6");
			SET_BUTTON_CLICK_ACTION("account_menu_options1active");
			SET_BUTTON_CLICK_ACTION("account_menu_options2active");
			SET_BUTTON_CLICK_ACTION("account_menu_options3active");
			SET_BUTTON_CLICK_ACTION("account_menu_options4active");
			SET_BUTTON_CLICK_ACTION("account_menu_options5active");
			SET_BUTTON_CLICK_ACTION("account_menu_options6active");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("business_settings_options2");
			SET_BUTTON_CLICK_ACTION("business_settings_options4");
			SET_BUTTON_CLICK_ACTION("business_settings_options1active");
			SET_BUTTON_CLICK_ACTION("business_settings_options2active");
			//SET_BUTTON_CLICK_ACTION("business_settings_options3active");
			SET_BUTTON_CLICK_ACTION("business_settings_options4active");
			SET_BUTTON_CLICK_ACTION("business_settings_options5active");
			SET_BUTTON_CLICK_ACTION("business_settings_options6active");
			SET_BUTTON_CLICK_ACTION("business_settings_options7active");
			/* --------------------------------------------------------  */
			if($('#business_account_summary_options1')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options1");
			}
			if($('#business_account_summary_options2')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options2");
			}
			if($('#business_account_summary_options3')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options3");
			}
			if($('#business_account_summary_options4')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options4");
			}
            SET_BUTTON_CLICK_ACTION("social_media_register_options20");
			SET_BUTTON_CLICK_ACTION("search_engine_index_register_options20");
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("business_settings_options3active")){//business_type_select  business_settings_businessTypeName
                SET_BUTTON_CLICK_ACTION("business_settings_options3active");
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_settings_options3active'),null,null,null,null);
                //$('#standard_entity_modify_control').val($('#business_settings_options3').attr('id'));
            }
            if(document.getElementById("business_settings_options3")){
                SET_BUTTON_CLICK_ACTION("business_settings_options3");
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_settings_options3'),null,null,null,null);
                //$('#standard_entity_modify_control').val($('#business_settings_options3').attr('id'));
            }
            /* --------------------------------------------------------  
            if($('#social_media_register_options1active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options1active");
                $('#social_media_register_options1active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options1active').attr('id')); 
                });
            }
            if($('#social_media_register_options1').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options1");
                $('#social_media_register_options1').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options1').attr('id')); 
                });
            }
            if($('#social_media_register_options2active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options2active");
                $('#social_media_register_options2active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options2').attr('id')); 
                });
            }
            if($('#social_media_register_options2').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options2");
                $('#social_media_register_options2').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options2').attr('id')); 
                });
            }
            if($('#social_media_register_options3active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options3active");
                $('#social_media_register_options3active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options3').attr('id')); 
                });
            }
            if($('#social_media_register_options3').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options3");
                $('#social_media_register_options3').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options3').attr('id')); 
                });
            }
            if($('#social_media_register_options4active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options4active");
                $('#social_media_register_options4active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options4').attr('id')); 
                });
            }
            if($('#social_media_register_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options4");
                $('#social_media_register_options4').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options4').attr('id')); 
                });
            }
            if($('#social_media_register_options5active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options5active");
                $('#social_media_register_options5active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options5').attr('id')); 
                });
            }
            if($('#social_media_register_options5').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options5");
                $('#social_media_register_options5').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options5').attr('id')); 
                });
            }
            if($('#social_media_register_options6active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options6active");
                $('#social_media_register_options6active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options6').attr('id')); 
                });
            }
            if($('#social_media_register_options6').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options6");
                $('#social_media_register_options6').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options6').attr('id')); 
                });
            }
            */
            /*
            if($('#social_media_register_options7').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options7");
                $('#social_media_register_options7').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options7').attr('id')); 
                });
            }
            if($('#social_media_register_options8').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options8");
                $('#social_media_register_options8').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options8').attr('id')); 
                });
            }
            if($('#social_media_register_options9').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options9");
                $('#social_media_register_options9').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options9').attr('id')); 
                });
            }
            */
            if($('#social_media_register_options10').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options10");
                $('#social_media_register_options10').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options10').attr('id')); 
                });
            }
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("business_settings_cohost_menu_options1");
			SET_BUTTON_CLICK_ACTION("business_settings_cohost_menu_options2");
			SET_BUTTON_CLICK_ACTION("business_settings_cohost_menu_options3");
			/* --------------------------------------------------------  */
			if(document.getElementById("business_settings_options6")){
				var submit_button = document.getElementById("business_settings_options6");
				SET_BUTTON_CLICK_ACTION("business_settings_options6");
				submit_button.addEventListener("click", function(event){
					$('#business_settings_control').val($('#settings_photobank_formCurrentIndex').val());
					$('#business_settings_formCurrentIndex').val($('#settings_photobank_formCurrentIndex').val());
				}, false);	
			}
			if($('#settings_photobank_index2').val()=='DISABLED'){
			 	$('#settings_photobank_options1').attr('disabled',true);
				$('#settings_photobank_options2').attr('disabled',true);
			}else{
				if(document.getElementById("settings_photobank_options1")){
					SET_BUTTON_CLICK_ACTION("settings_photobank_options1");
				}
				if(document.getElementById("settings_photobank_options2")){
					SET_BUTTON_CLICK_ACTION("settings_photobank_options2");
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_menu_options2")){
				var submit_button = document.getElementById("account_purchase_menu_options2");
				SET_BUTTON_CLICK_ACTION("account_purchase_menu_options2");
				submit_button.addEventListener("click", function(event){
				}, false);	
			}
			/* ------------------------------------------------- */	
			if(document.getElementById("account_general_silver_active")){
				var submit_button = document.getElementById("account_general_silver_active");
				SET_BUTTON_CLICK_ACTION("account_general_silver_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_silver_active");

				}, false);	
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_general_gold_active")){
				var submit_button = document.getElementById("account_general_gold_active");
				SET_BUTTON_CLICK_ACTION("account_general_gold_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_gold_active");

				}, false);	
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_general_platinum_active")){
				var submit_button = document.getElementById("account_general_platinum_active");
				SET_BUTTON_CLICK_ACTION("account_general_platinum_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_platinum_active");

				}, false);	
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_menu_options1")){
				var submit_button = document.getElementById("account_purchase_menu_options1");
				SET_BUTTON_CLICK_ACTION('account_purchase_menu_options1');
				submit_button.addEventListener("click", function(event){
				}, false);	
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function CheckAccount_val(option_val){
				var account_type_val = document.getElementById("account_general_accountType").value;
				if((option_val=='account_general_platinum_active' && account_type_val =='PLATINUM') || 
					(option_val=='account_general_gold_active' && account_type_val =='GOLD') ||
					(option_val=='account_general_silver_active' && account_type_val =='SILVER')){
					account_type_request_function(option_val);
					//window_complete_script();	
				}
				if((option_val=='account_general_select_redirect')){
					account_type_request_function(option_val);
				}
				option_val=null;
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option1_1")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option1_1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option1_2")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option1_2");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_1")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_2")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_2");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_3")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_3");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options1");
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options2");
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options3");
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options4");
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options5");
			SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options6");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options1");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options2");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options3");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options4");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options5");
			SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options6");
			/* --------------------------------------------------------  */
			if($('#business_logo_letter_mark1_options7')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options7");
			}
			if($('#business_logo_letter_mark2_options7')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark2_options7");
			}
			if($('#business_logo_brand_mark1_options7')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options7");
			}
			if($('#business_logo_brand_mark2_options7')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark2_options7");
			}
			if($('#business_logo_brand_mark1_options5active')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options5active");
			}
			if($('#business_logo_brand_mark2_options5active')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark2_options5active");
			}
			if($('#business_logo_brand_mark1_options6active')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark1_options6active");
			}
			if($('#business_logo_brand_mark2_options6active')){
				SET_BUTTON_CLICK_ACTION("business_logo_brand_mark2_options6active");
			}
			if($('#business_logo_letter_mark1_options6active')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options6active");
			}
			if($('#business_logo_letter_mark2_options6active')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark2_options6active");
			}
			if($('#business_logo_letter_mark1_options5active')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark1_options5active");
			}
			if($('#business_logo_letter_mark2_options5active')){
				SET_BUTTON_CLICK_ACTION("business_logo_letter_mark2_options5active");
			}
			/* --------------------------------------------------------  */
			if($('#business_file_upload_options2')){
				SET_BUTTON_CLICK_ACTION("business_file_upload_options2");
			}
			/* ------------------------------------------------- */
			if($('#business_template_options2')){
				SET_BUTTON_CLICK_ACTION("business_template_options2");
			}
			if($('#business_template_options2active')){
				SET_BUTTON_CLICK_ACTION("business_template_options2active");
			}
			if($('#business_template_options3')){
				SET_BUTTON_CLICK_ACTION("business_template_options3");
			}
			if($('#business_template_options3active')){
				SET_BUTTON_CLICK_ACTION("business_template_options3active");
			}
			if($('#business_template_options4')){
				SET_BUTTON_CLICK_ACTION("business_template_options4");
			}
			if($('#business_template_options4active')){
				SET_BUTTON_CLICK_ACTION("business_template_options4active");
			}
			if($('#business_template_options5')){
				SET_BUTTON_CLICK_ACTION("business_template_options5");
			}
			if($('#business_template_options5active')){
				SET_BUTTON_CLICK_ACTION("business_template_options5active");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index2').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options4");
				}else{
					$('#settings_field_compliance_edit_index1').prop("disabled",true);
					$('#settings_field_compliance_edit_index2').prop("disabled",true);
					$('#settings_field_compliance_edit_options1').prop("disabled",true);
					$('#settings_field_compliance_edit_options1active').prop("disabled",true);
					$('#settings_field_compliance_edit_options4').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index4').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options2");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options5");
				}else{
					$('#settings_field_compliance_edit_index3').prop("disabled",true);
					$('#settings_field_compliance_edit_index4').prop("disabled",true);
					$('#settings_field_compliance_edit_options2').prop("disabled",true);
					$('#settings_field_compliance_edit_options2active').prop("disabled",true);
					$('#settings_field_compliance_edit_options5').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index6').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options3");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options6");
				}else{
					$('#settings_field_compliance_edit_index5').prop("disabled",true);
					$('#settings_field_compliance_edit_index6').prop("disabled",true);
					$('#settings_field_compliance_edit_options3').prop("disabled",true);
					$('#settings_field_compliance_edit_options3active').prop("disabled",true);
					$('#settings_field_compliance_edit_options6').prop("disabled",true);
				}
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_select_options1")){
				SET_BUTTON_CLICK_ACTION("account_select_options1");
			}
			if(document.getElementById("account_general_options6")){
				SET_BUTTON_CLICK_ACTION("account_general_options6");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_select_options2")){
				SET_BUTTON_CLICK_ACTION("account_select_options2");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_select_options3")){
				SET_BUTTON_CLICK_ACTION("account_select_options3");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_select_options2active")){
				SET_BUTTON_CLICK_ACTION("account_select_options2active");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_select_options3active")){
				SET_BUTTON_CLICK_ACTION("account_select_options3active");
			}
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  */
			if(document.getElementById("business_settings_options2")){
				SET_BUTTON_CLICK_ACTION("business_settings_options2");
				$('#business_settings_options2').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#business_settings_options2').attr('id'));
					settings_request_function(event);
				});
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			$( "#settings_field_edit_address" ).focusin(function() {
				var $autoObj = MGMT_google_autocomplete('settings_field_edit_address');
			});
			if(document.getElementById("settings_field_edit_address")){

				$( "#settings_field_edit_address" ).keyup(function(e) {
					/* --------------------------------------------------------  */
					//var codes = [9,13,32];
					var codes = [32];
					document.getElementById("settings_field_edit_address").addEventListener('keydown', function(e) {
					if(!contains(codes, e.keyCode)) return;
						var path = e.path || e.composedPath()
						if(!path[0]) return;

						e.preventDefault();
						path[0].value += String.fromCharCode(e.keyCode);//
						var $STRING=$('#settings_field_edit_address').val();
						$('#settings_field_edit_address').val($STRING.replace(/\s{2,}/g, ' '));
					})
					function contains(arr, item) {
						for(var i=0;i<arr.length;i++)
						if(arr[i]==item)return true;
						return false;
					}
					/* --------------------------------------------------------  */
					var $autoObj = MGMT_google_autocomplete('settings_field_edit_address');
					/* --------------------------------------------------------  */
				});
			}
			SET_BUTTON_CLICK_ACTION("settings_field_edit_options1");
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_field_edit_formCurrentIndex")){
				$( "#settings_field_edit_formCurrentIndex" ).keyup(function(e) {
					/* --------------------------------------------------------  */
					//var codes = [9,13,32];
					var codes = [32];
					document.getElementById("settings_field_edit_formCurrentIndex").addEventListener('keydown', function(e) {
					if(!contains(codes, e.keyCode)) return;
						var path = e.path || e.composedPath()
						if(!path[0]) return;

						e.preventDefault();
						path[0].value += String.fromCharCode(e.keyCode);//
						var $STRING=$('#settings_field_edit_formCurrentIndex').val();
						$('#settings_field_edit_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
					})
					function contains(arr, item) {
						for(var i=0;i<arr.length;i++)
						if(arr[i]==item)return true;
						return false;
					}
					/* --------------------------------------------------------  */
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $CHECK = 0;
			SET_BUTTON_CLICK_ACTION('settings_activity_type1_activityStatus');
			if($('#settings_activity_type1_activityStatus').attr('type') == 'checkbox'){
				//var $CHECK = 0;
				if($('#settings_activity_type1_activityStatus').is(":checked")){$CHECK =1;}
				$('#settings_activity_type1_activityStatus').unbind().click(function(event) {
					business_instance_function('settings_activity_type1_control',$CHECK,event,$('#settings_activity_type1_activityStatus'));
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options2")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options2'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options3")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options3'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options4")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options4'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options5")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options5");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options5'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options2active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options2active");
			}
			if(document.getElementById("account_compliance_options3active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options3active");
			}
			if(document.getElementById("account_compliance_options4active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options4active");
			}
			if(document.getElementById("account_compliance_options5active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options5active");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options16")){
				//SET_BUTTON_CLICK_ACTION("account_compliance_options16");
			}
			if(document.getElementById("account_compliance_options17")){
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options17'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options18")){
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options18'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('photo_overlay');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							if($('#standard_entity_modify_control').val()){
								CONSOLE_MANAGER('BUSINESS_account_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								var $RESULTS_CNTRL = null; 
								var $SEARCH_CNTRL = null;
								$RESULTS_CNTRL = $('#standard_entity_modify_control').val();
								if(mutation.type=='attributes'){
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#settings_general_birthday').attr('id')){

									}
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#settings_general_gender').attr('id')){

									}
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#identity_select_control').val()){
										update_field();
									}
                                    /* -------------------------------------------------------- */
                                    if($RESULTS_CNTRL == $('#business_settings_businessTypeName').attr('id')){
                                        business_type_select();
                                        //$('#standard_entity_modify_control').val(null)
                                    }
                                    //if($RESULTS_CNTRL == $('#business_settings_businessTypeName').attr('id')){
                                        //business_type_select();
                                        //$('#standard_entity_modify_control').val(null)
                                    //}
									/* -------------------------------------------------------- */
								}else if(mutation.type=='childList'){
									/* -------------------------------------------------------- */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("form_data_purchase_index_object")){
				var $code = $("#form_data_purchase_index_object").val();
				for(var $i=1; $i<parseInt(4); $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						//var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						//var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						//var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						REGISTER_CLICK($($OPTIONS01));
						REGISTER_CLICK($($OPTIONS02));
						REGISTER_CLICK($($OPTIONS03));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */	
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj){
				$button_obj.unbind().click(function(event) {
                 	$('#standard_entity_modify_control').val($('#account_purchase_menu_control').val());
					standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						//var $SUBMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val()};
						//$ENTITYLISTMgmt[$i] =$SUBMgmt;
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK_h($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($OPTIONS11),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_h($($OPTIONS12),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
					}	
				}
			}
			/* ------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK_h($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}	
            /* --------------------------------------------------------  */
			if(document.getElementById("business_recommendation_manager_options1")){
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options1');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options1active');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options2');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options2active');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options3');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options4');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options4active');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options5');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options5active');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options6');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options6active');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options7');
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options7active');
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_recommendation_options1")){
				SET_BUTTON_CLICK_ACTION('settings_recommendation_options1');
				SET_BUTTON_CLICK_ACTION('settings_recommendation_index1');
				SET_BUTTON_CLICK_ACTION('settings_recommendation_options2');
			}
			/* --------------------------------------------------------  */
			/* ------------------------------------------------- */	
			$('.Logo1_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control1');
			if(document.getElementById('Entity_upload_Activity_UI_control1')){	
				if($('#LETTER_File_Upload_Label1').is(':visible')) {
					ACTIVATE_UPLOAD_FORM_INIT($('.Logo1_Activity_UI_class'));
				}
			}
			$('.Logo2_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control2');
			if(document.getElementById('Entity_upload_Activity_UI_control2')){	
				if($('#BRAND_File_Upload_Label1').is(':visible')) {
					ACTIVATE_UPLOAD_FORM_INIT($('.Logo2_Activity_UI_class'));
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- 
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS11),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS12),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
					}	
				}
			}
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
				});
			}*/
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count_items")){
				var Object_count = parseInt(document.getElementById("total_objects_count_items").value + 1);
				var $code = $("#index_object_items").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                        /*
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS01ACTIVE = '#form_'+$code+'_'+$i+'_options1active';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						if($($OPTIONS09).val()){
							var $START = moment($($OPTIONS09).val()).utc().local().format('dddd MMMM Do @LT');//	LLLL
						}
						if($($OPTIONS10).val()){
							var $END = moment($($OPTIONS10).val()).utc().local().format('dddd MMMM Do @LT');//dddd@LT
						}
						if($($PATHSEQUENCE).val()){
							header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						} */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						ITEMS_REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS09),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS11),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function ITEMS_REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
					$('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($('#business_settings_cohost_menu_control').attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_account_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function BUSINESS_COHOST_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_COHOST_SELECT_basics','ACTIVATED');
			/* -------------------------------------------------------- */
			SETTINGS_PAGINATION_CONTROL();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			search_bar_function('BUSINESS_COHOST_SELECT_basics');
			/* -------------------------------------------------------- */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						//var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						//var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						//var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $TITLE_SEQUENCE = '#form_'+$code+'_'+$i+'_title';
						var $PSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						if($($PSEQUENCE).val()){
							$($OPTIONS01).css('background-image','url('+$($PSEQUENCE).val()+')');
						}
						REGISTER_CLICK($($OPTIONS01),$i,$code);
						//REGISTER_CLICK($($OPTIONS02),$i,$code);
						//REGISTER_CLICK($($OPTIONS03),$i,$code);
						REGISTER_CLICK($($OPTIONS04),$i,$code);
						//REGISTER_CLICK($($OPTIONS05),$i,$code);
						REGISTER_CLICK($($OPTIONS06),$i,$code);
						REGISTER_CLICK($($OPTIONS05),$i,$code);
						REGISTER_CLICK($($TITLE_SEQUENCE),$i,$code);
						/*
						REGISTER_CLICK($($6SEQUENCE),$i,$code);
						*/
					}	
				}   
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code){
				$button_obj.unbind().click(function(event) {
                 	event.stopPropagation;
                    $('#standard_entity_modify_title').val($('#form_'+$code+'_'+$val_code+'_title').val());
                    $('#standard_entity_modify_index1').val($('#form_'+$code+'_'+$val_code+'_index1').val());
                    $('#standard_entity_modify_index2').val($('#form_'+$code+'_'+$val_code+'_index2').val());
                    $('#standard_entity_modify_index7').val($('#form_'+$code+'_'+$val_code+'_index7').val());
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_COHOST_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function BUSINESS_TYPE_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_TYPE_SELECT_basics','ACTIVATED');
			/* -------------------------------------------------------- */
			SETTINGS_PAGINATION_CONTROL();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* -------------------------------------------------------- */
			search_bar_function('BUSINESS_TYPE_SELECT_basics');
			/* -------------------------------------------------------- */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						//var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						REGISTER_CLICK($($OPTIONS01),$i,$code);
						REGISTER_CLICK($($OPTIONS02),$i,$code);
					}	
				}   
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code){
				$button_obj.unbind().click(function(event) {
                 	event.stopPropagation;
                	$('#standard_entity_modify_index1').val($('#form_'+$code+'_'+$val_code+'_index1').val());
					$('#standard_entity_modify_index2').val($('#form_'+$code+'_'+$val_code+'_index2').val());
                  	$('#standard_entity_modify_formFilter1').val($('#form_'+$code+'_'+$val_code+'_index2').val());
                  	$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                  	standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_TYPE_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	BUSINESS_Catalog                    ########################## */
export function BUSINESS_Catalog(){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('BUSINESS_Catalog','ACTIVATED');
			/* --------------------------------------------------------  */
            /* -------------------------------------------------------- */
            if($('#social_media_share_options1').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("social_media_share_options1");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("social_metrics_options1");
			SET_BUTTON_CLICK_ACTION("social_metrics_options2");
			SET_BUTTON_CLICK_ACTION("social_metrics_options3");
			SET_BUTTON_CLICK_ACTION("social_metrics_options4");
			SET_BUTTON_CLICK_ACTION("social_metrics_options5");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			$('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
			//$('.Listview_options_Activity_UI_class').attr("id", 'Listview_options_Activity_UI_class');
            $('#Event_File_Upload_Label').unbind().click(function(event) {
                $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label').attr('id'));

                ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
            });
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                    var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
					var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
					var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
					
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                   	header_background_activate($($IMAGESEQUENCE),$($OPTIONS01));
					//header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
					//header_background_activate($($EVENTEQUENCE),$($OPTIONS01));
					/* -------------------------------------------------------- */
                    if($($OPTIONS04).hasClass('photolist_upload')|| $($OPTIONS04).hasClass('photolist_upload_image')){// 
					//if($($OPTIONS04).hasClass('photolist_upload')){//photolist_upload_image    photolist_upload
                        REGISTER_UPLOAD($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						REGISTER_UPLOAD($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        /* -------------------------------------------------------- */
                        //REGISTER_OBJECT_CLICK($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_OBJECT_CLICK($($OPTIONS05),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_OBJECT_CLICK($($OPTIONS06),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        /* -------------------------------------------------------- */
                        REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
						REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        /* -------------------------------------------------------- */
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					}else{
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
						REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
						REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
						/* -------------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
						REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						REGISTER_VIEW($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						REGISTER_VIEW($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						REGISTER_VIEW($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					}
                }
            }
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    $EVENT.stopPropagation? $EVENT.stopPropagation() : $EVENT.cancelBubble = true;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_like_active')){
					    $button_obj.removeClass('photobucket_action_like_active');
                       	$button_obj.addClass('photobucket_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_action_like_disabled')){
						$button_obj.removeClass('photobucket_action_like_disabled');
                       	$button_obj.addClass('photobucket_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_action_dislike_active');
							$OPTIONS11.addClass('photobucket_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter3').val($OPTIONS11.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/photosystem/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			/* --------------------------------------------------------  */
			function REGISTER_UPLOAD($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				$button_obj.unbind().click(function(event) {
					$('#Event_File_Upload_Label').unbind().click();
					$('#entity_file_upload_options_index1').val($INDEX04.val());
					$('#entity_file_upload_options_index4').val($INDEX04.val());
					ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                    //ACTIVATE_UPLOAD_FORM_LISTED($button_obj,document.getElementById("Entity_upload_Activity_UI_class"))
				});
			}
			/* --------------------------------------------------------  */
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    $EVENT.stopPropagation;
					$EVENT.stopPropagation? $EVENT.stopPropagation() : $EVENT.cancelBubble = true;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_dislike_active')){
					    $button_obj.removeClass('photobucket_action_dislike_active');
                       	$button_obj.addClass('photobucket_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_action_like_active')){
							$OPTIONS10.removeClass('photobucket_action_like_active');
							$OPTIONS10.addClass('photobucket_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/photosystem/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			/* --------------------------------------------------------  */
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				//PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				//PHOTOBUCKET_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				//PHOTOBUCKET_PROFILE_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				if( $('#image_object').val()!='[]'){
					PHOTOSYSTEM_PROFILE_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,$INDEX02,$INDEX03,$INDEX04);
            }
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_OBJECT_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				$button_obj.unbind().click(function(event) {
					$('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('BUSINESS_Catalog','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									CONSOLE_MANAGER('BUSINESS_Catalog',mutation.type+': attributes');
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
                                            var $length = parseInt($('#population_index_count').val())+1;
                                            var $code = $("#population_index").val();
                                            for(var $i=0; $i< $length; $i++){
                                                var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                                                //var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                                var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                                                var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                                                var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                                                var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                                                header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                                            }
											//}
											/* -------------------------------------------------------- */
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqUserId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('BUSINESS_Catalog','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('BUSINESS_Catalog',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('BUSINESS_Catalog','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('BUSINESS_Catalog',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('BUSINESS_Catalog',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_PROFILE_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('BUSINESS_Catalog');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/