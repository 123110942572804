/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 

/*  PROFILE_basics
/*	PROFILE_listview_basics
/*	PROFILE_imagelistview_basics
/*	PROFILE_EVENT_Media
/*	MEMBER_basics
/*	MEMBER_listview_basics
/*	MEMBER_imagelistview_basics
/*	MEMBER_Media

/*  GENERAL_Settings_basics
/*	SECURITY_listview_basics 
/*  PROFILE_PIC_basics
/*  
/*	form_profileimageview_ajax_MGMT
/*	RECOMMENDATIONS_SELECT_basics
/*	IDENTITY_SELECT_basics
/*	GENERAL_Account_basics
/*
/*	SELECTION_Account_basics
/*	VALIDATE_Account_basics
/*	
/*	HISTORY_Account_basics
/*	IDENTITY_basics
/*
/*	Hourtime_24_function
/*	
/*	MAILBOX_listview_basics
/*	MAILBOX_SELECT_listview_basics
/*	MAILBOX_MAIL_listview_basics
/*	
/*
/*

trigger
'social_media'
profile_member_menu_pic_member_profile
profile_report_report
file_upload_button settings_general_email				if(e.keyCode == 32){	MEMBER_basics
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/********************/
/********************/
import * as $ from 'jquery';
import * as jQuery from 'jquery';
var momenttz = require('moment-timezone');

import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { META_TAGS as META_TAGS } from "./mgmt_control.js";

import { isJSON as isJSON } from "./mgmt_control.js";
import { createCountryElementFromHTML as createCountryElementFromHTML } from "./mgmt_control.js";
import { createKeyElementFromHTML as createKeyElementFromHTML } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";

import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { header_background_activate as header_background_activate } from "./mgmt_control.js";

import { PROFILE_PHOTO_basics as PROFILE_PHOTO_basics } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";

import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";

import { SETTINGS_MENU_CONTROL as SETTINGS_MENU_CONTROL } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { ACCOUNT_MENU_CONTROL as ACCOUNT_MENU_CONTROL } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
//import { GET_PROCESSED_ERRORS as GET_PROCESSED_ERRORS } from "./mgmt_control.js";

import { map_identity_country as map_identity_country } from "./mgmt_mapping.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { gender_ajax as gender_ajax } from "./mgmt_control.js";
import { birthday_request_function as birthday_request_function } from "./mgmt_control.js";


import { PROFILESYSTEM_LOAD_MGMT as PROFILESYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { INFORMATIONSYSTEM_LOAD_MGMT as INFORMATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";

import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_metrics.js";

import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { PHOTOBUCKET_PROFILE_LOAD_MGMT as PHOTOBUCKET_PROFILE_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_PROFILE_UPDATE as PHOTOBUCKET_PROFILE_UPDATE } from "./mgmt_control.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";

import { PROFILE_METRICS_CONTROL_basics as PROFILE_METRICS_CONTROL_basics } from "./mgmt_metrics.js";

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function profile_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('profile_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('profile_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('profile_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){

							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){

							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){

							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('profile_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */
                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index1').val($('#image_creator_name').val());
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/entity/file/profile/upload';
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){

    }/* END DATA RETURN */
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function Hourtime_24_function($button_obj,$val_code){
    CONSOLE_MANAGER('Hourtime_24_function',$button_obj+" button value: "+$button_obj.html());
    /* --------------------------------------------------------  */
	var $DATE = moment($button_obj.html(),'YYYY-MM-DDTHH:mm:ssZZ').format();
	var FINAL = moment($DATE).local().format('MMMM Do ha');
	if(moment($DATE).isValid()){
		var now = moment();
		var duration = moment.duration(now.diff($DATE));
		if(duration.asDays()>1){
			$button_obj.html(moment($DATE).format('MMMM Do'));
		}else if(duration.asDays()<1){
			if(duration.asHours()<24){
				$button_obj.html(Math.round(duration.asHours())+' hours ago');
			}
			if(duration.asHours()<1){
				$button_obj.html(Math.round(duration.asMinutes())+' minutes ago');
			}
			if(duration.asMinutes()<2){
				$button_obj.html('moments ago');
			}
		}
	}
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_basics                              ########################## */
export function PROFILE_basics(){
	$(document).ready(function(){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('PROFILE_basics','ACTIVATED');
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if($('#map').is(':visible')) {
            micro_map_activate();
        }
        /* --------------------------------------------------------  */
        if($('#profile_member_menu_options1active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_member_menu_options1active");
        }
        if($('#profile_username_options1active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options1active");
        }
        /* --------------------------------------------------------  */
        if($('#profile_member_menu_options2').is(':visible')) {
            var $SCORE = parseInt($('#profile_member_menu_options2').html());
            //$('#profile_member_menu_options2').html(' ');
            PROFILE_METRICS_CONTROL_basics($('#profile_member_menu_options2'),$SCORE);
        }
		/* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if($('#social_media_options1').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("social_media_options1");
            $('#social_media_options1').unbind().click(function(event) {
                $('#social_media_control').val($('#social_media_options1').attr('id')); 
			});
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options1'),null,null,null,null);
        }
        if($('#social_media_options2').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("social_media_options2");
            $('#social_media_options2').unbind().click(function(event) {
                $('#social_media_control').val($('#social_media_options2').attr('id')); 
			});
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options2'),null,null,null,null);
        }
        if($('#social_media_options3').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("social_media_options3");
            $('#social_media_options3').unbind().click(function(event) {
                $('#social_media_control').val($('#social_media_options3').attr('id')); 
			});
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options3'),null,null,null,null);
        }
        if($('#social_media_options4').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("social_media_options4");
            $('#social_media_options4').unbind().click(function(event) {
                $('#social_media_control').val($('#social_media_options4').attr('id')); 
			});
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options4'),null,null,null,null);
        }
        if($('#social_media_options5').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("social_media_options5");
            $('#social_media_options5').unbind().click(function(event) {
                $('#social_media_control').val($('#social_media_options5').attr('id')); 
			});
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options5'),null,null,null,null);
        }
        /* -------------------------------------------------------- */
        if($('#profile_username_options20active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options20active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options20active'),null,null,null,null);
			$('#profile_username_options20active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
        if($('#profile_username_options21active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options21active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options21active'),null,null,null,null);
			$('#profile_username_options21active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
        if($('#profile_username_options22active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options22active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options22active'),null,null,null,null);
			$('#profile_username_options22active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
        if($('#profile_username_options23active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options23active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options23active'),null,null,null,null);
			$('#profile_username_options23active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
        if($('#profile_username_options24active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options24active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options24active'),null,null,null,null);
			$('#profile_username_options24active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
		if($('#profile_username_options25active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options25active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options24active'),null,null,null,null);
			$('#profile_username_options25active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
		if($('#profile_username_options26active').is(':visible')) {
            SET_BUTTON_CLICK_ACTION("profile_username_options26active");
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#profile_username_options24active'),null,null,null,null);
			$('#profile_username_options26active').unbind().click(function(event) {
				$('#profile_username_options2').unbind().click();
			});
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        for(var $i=1;$i<parseInt(18);$i++ ){
            if($('#profile_menu_recommendation_options'+$i+'active').is(':visible')){
                SET_BUTTON_CLICK_ACTION('profile_menu_recommendation_options'+$i+'active');
            }
            if($('#profile_menu_recommendation_index'+$i).val()){
                SET_BUTTON_CLICK_ACTION('profile_menu_recommendation_options'+$i);
                header_background_activate($('#profile_menu_recommendation_formFilter'+$i), $('#profile_menu_recommendation_options'+$i))
                //REGISTER_CLICK($('#profile_menu_recommendation_options'+$i),$i);
            }else{
                 $('#profile_menu_recommendation_options'+$i).prop('disabled', true);
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        for(var $i=1;$i<parseInt(9);$i++ ){
            if($('#profile_menu_identity_index'+$i).val()){
                SET_BUTTON_CLICK_ACTION('profile_menu_identity_options'+$i+'active');
                //SET_BUTTON_CLICK_ACTION('profile_menu_identity_options'+$i);
                $('#profile_menu_identity_options'+$i).prop('disabled', true);
            }else{
                 $('#profile_menu_identity_options'+$i).prop('disabled', true);
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        function REGISTER_CLICK($button_obj,$val_code){
			$button_obj.unbind().click(function(event) {
                event.stopPropagation;
                $('#standard_entity_modify_control').val('PROFILE_RECOMMENDATION_SINGLE');
                $('#standard_entity_modify_username').val($('#profile_menu_recommendation_formFilter'+$val_code).val());
                $('#standard_entity_modify_formFilter1').val($('#profile_menu_recommendation_formFilter'+$val_code).val());
                standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event);											  
            });
        }		
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION('profile_member_menu_options2');
        /* --------------------------------------------------------  */
        if($('.ie_functional_table_td_row_side_active').is(':visible')){
            //media_image_select();
            //MEDIA_PHOTO_basics();
            //main_image
            PROFILE_PHOTO_basics();	
        }else{
            if(document.getElementById("profile_member_menu_profile_pic_path")){
                if($('#profile_member_menu_profile_pic_path')){
                    var path_var = $('#profile_member_menu_profile_pic_path').val();
                    $('#profile_member_menu_options1').css('background-image','url('+path_var+')');
                }
				SET_BUTTON_CLICK_ACTION('profile_member_menu_options1');
                $('#profile_member_menu_options1').unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($('#profile_username_index1').val());
                    $('#standard_entity_modify_control').val('IMAGE_TYPE');
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)			
                });
            }
            /* -------------------------------------------------------- */
            if(document.getElementsByClassName("Profile_member_Activity_UI_class")){
            //SET_BUTTON_CLICK_ACTION('profile_member_menu_options2');
            //SET_BUTTON_CLICK_ACTION('profile_member_menu_options2active');
            }
            /* ------------------------------------------------- */	
            SET_BUTTON_CLICK_ACTION('profile_info_options4active');
            SET_BUTTON_CLICK_ACTION('profile_info_options1');
            SET_BUTTON_CLICK_ACTION('profile_info_options2');
            SET_BUTTON_CLICK_ACTION('profile_info_options3');
			SET_BUTTON_CLICK_ACTION('profile_username_options2');
            /* ------------------------------------------------- */	
            if(document.getElementsByClassName("Profile_view_Activity_UI_class")){
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options1');
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options1active');
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options2');
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options2active');
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options3');
                SET_BUTTON_CLICK_ACTION('profile_view_menu_options3active');
            }
            /* ------------------------------------------------- */	
            SET_BUTTON_CLICK_ACTION('event_select_menu_options1');
        }
		window_complete_script('PROFILE_basics');
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
	});
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_listview_basics                     ########################## */
export function PROFILE_listview_basics(){
	$(document).ready(function() {
			CONSOLE_MANAGER('PROFILE_listview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('PROFILE_listview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				if($('#profile_select_options1').is(':visible')){
					for(var $i=1; $i<3; $i++){
						var $SEQUENCE = '#profile_select_options'+$i;
						SELECT_PROFILE_CLICK($($SEQUENCE),$i,$code);
					}
					var $PATHSEQUENCE = '#profile_select_profile_pic_path';
					header_background_activate($($PATHSEQUENCE),$('#profile_select_options1'));
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						var $DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														var className = $(element.SET_STATUS).attr('class');
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.itemviewCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
				/* -------------------------------------------------------- */  
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- EVENT_CREATE */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.val());
                    //$('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
				});
			}
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('PROFILE_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_imagelistview_basics                ########################## */
export function PROFILE_imagelistview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('PROFILE_imagelistview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('PROFILE_listview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						//var $SUBMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val()};
						//$ENTITYLISTMgmt[$i] =$SUBMgmt;
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						$DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.itemviewCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				var $form = 'BUSINESS';
				if(($INDEX01.val()>0)&&($INDEX02.val()>0)){
				   $form = 'EVENT';
				 }
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
				});
			}		
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('PROFILE_imagelistview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MEMBER_basics                               ########################## */
export function MEMBER_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MEMBER_basics','ACTIVATED');
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if($('#profile_member_menu_options2').is(':visible')) {
                var $SCORE = parseInt($('#profile_member_menu_options2').html());
                //$('#profile_member_menu_options2').html(' ');
                PROFILE_METRICS_CONTROL_basics($('#profile_member_menu_options2'),$SCORE);
            }
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if($('#social_media_options1').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options1");
                $('#social_media_options1').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options1').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options1'),null,null,null,null);
            }
            if($('#social_media_options2').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options2");
                $('#social_media_options2').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options2').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options2'),null,null,null,null);
            }
            if($('#social_media_options3').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options3");
                $('#social_media_options3').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options3').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options3'),null,null,null,null);
            }
            if($('#social_media_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options4");
                $('#social_media_options4').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options4').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options4'),null,null,null,null);
            }
            if($('#social_media_options5').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options5");
                $('#social_media_options5').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options5').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options5'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			for(var $i=1;$i<parseInt(18);$i++ ){
				if($('#member_menu_recommendation_index'+$i).val()){
					SET_BUTTON_CLICK_ACTION('member_menu_recommendation_options'+$i+'active');
					header_background_activate($('#member_menu_recommendation_formFilter'+$i), $('#member_menu_recommendation_options'+$i+'active'))
				}else{
					 $('#member_menu_recommendation_options'+$i).prop('disabled', true);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			for(var $i=1;$i<parseInt(9);$i++ ){
				if($('#member_menu_identity_index'+$i).val()==1){
					$('#member_menu_identity_options'+$i).prop('disabled', true);
				}else{
					$('#member_menu_identity_options'+$i).prop('disabled', true);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('.ie_functional_table_td_row_side_active').is(':visible')){
				//media_image_select();
				//MEDIA_PHOTO_basics();
				//main_image
				PROFILE_PHOTO_basics();
                CONSOLE_MANAGER('MEMBER_basics','PROFILE_PHOTO_basics ACTIVATED');
			}else{
				/* --------------------------------------------------------  */
				if(document.getElementById("profile_member_menu_profile_pic_path")){
					if($('#profile_member_menu_profile_pic_path').val()){
                        CONSOLE_MANAGER('MEMBER_basics','profile_member_menu_profile_pic_path ACTIVATED '+$('#profile_member_menu_profile_pic_path').val());
						var path_var = $('#profile_member_menu_profile_pic_path').val();
						$('#profile_member_menu_options1').css('background-image','url('+path_var+')');
					}
					//var $CONTROL = 'profile_member_menu_options1';
					//PROFILESYSTEM_LOAD_MGMT($('#profile_member_menu_options1'),null,null,null,null);
					SET_BUTTON_CLICK_ACTION('profile_member_menu_options1');
					$('#profile_member_menu_options1').unbind().click(function(event) {
						event.stopPropagation;
						$('#standard_entity_modify_title').val($('#profile_username_index1').val());
						$('#standard_entity_modify_control').val('IMAGE_TYPE');
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)			
					});
				}
				/* ------------------------------------------------- */	
				if(document.getElementsByClassName("Profile_member_Activity_UI_class")){
					//SET_BUTTON_CLICK_ACTION('profile_member_menu_options2');
					//SET_BUTTON_CLICK_ACTION('profile_member_menu_options2active');
				}
				/* ------------------------------------------------- */	
				SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options1');
                SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options2');
                SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options3');
				SET_BUTTON_CLICK_ACTION('profile_member_follow_action_options4');
				/* ------------------------------------------------- */	
                SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options1');
				SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options2');
				SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options3');
				SET_BUTTON_CLICK_ACTION('profile_member_follower_action_options4');
				/* ------------------------------------------------- */	
				SET_BUTTON_CLICK_ACTION('profile_info_options4active');
				SET_BUTTON_CLICK_ACTION('profile_info_options1');
				SET_BUTTON_CLICK_ACTION('profile_info_options2');
				SET_BUTTON_CLICK_ACTION('profile_info_options3');
				/* ------------------------------------------------- */	
				if(document.getElementsByClassName("Profile_view_Activity_UI_class")){
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options1');
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options1active');
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options2');
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options2active');
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options3');
					SET_BUTTON_CLICK_ACTION('profile_view_menu_options3active');
				}
				/* ------------------------------------------------- */	
				if(document.getElementsByClassName("Event_create_Activity_UI_class")){
					SET_BUTTON_CLICK_ACTION('event_select_menu_options1');
				}
				/* ------------------------------------------------- */	
				/* ------------------------------------------------- */	
			}
			/* ------------------------------------------------- */	
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		window_complete_script('MEMBER_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MEMBER_listview_basics                     ########################## */
export function MEMBER_listview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MEMBER_listview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('MEMBER_listview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				if($('#profile_select_options1').is(':visible')){
					for(var $i=1; $i<3; $i++){
						var $SEQUENCE = '#profile_select_options'+$i;
						SELECT_PROFILE_CLICK($($SEQUENCE),$i,$code);
					}
					$PATHSEQUENCE = '#profile_select_profile_pic_path';
					header_background_activate($($PATHSEQUENCE),$('#profile_select_options1'));
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						$DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.itemviewCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
				/* -------------------------------------------------------- */	   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- EVENT_CREATE */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.val());
					$('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}
			/* -------------------------------------------------------- */
			
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MEMBER_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MEMBER_imagelistview_basics                 ########################## */
export function MEMBER_imagelistview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MEMBER_imagelistview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('MEMBER_imagelistview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						//var $SUBMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val()};
						//$ENTITYLISTMgmt[$i] =$SUBMgmt;
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						$DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.itemviewCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
				/* -------------------------------------------------------- */   
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				var $form = 'BUSINESS';
				if(($INDEX01.val()>0)&&($INDEX02.val()>0)){
				   $form = 'EVENT';
				 }
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
				});
			}
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MEMBER_imagelistview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MEMBER_Media                    ########################## */
export function MEMBER_Media(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MEMBER_Media','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("social_metrics_options1");
			SET_BUTTON_CLICK_ACTION("social_metrics_options2");
			SET_BUTTON_CLICK_ACTION("social_metrics_options3");
			SET_BUTTON_CLICK_ACTION("social_metrics_options4");
			SET_BUTTON_CLICK_ACTION("social_metrics_options5");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			//SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
					
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                   	header_background_activate($($IMAGESEQUENCE),$($OPTIONS01));
					//header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
					//header_background_activate($($EVENTEQUENCE),$($OPTIONS01));
					/* -------------------------------------------------------- */
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
					REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
					REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
					/* -------------------------------------------------------- */
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
					REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                }
            }
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    $EVENT.stopPropagation? $EVENT.stopPropagation() : $EVENT.cancelBubble = true;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_like_active')){
					    $button_obj.removeClass('photobucket_action_like_active');
                       	$button_obj.addClass('photobucket_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_action_like_disabled')){
						$button_obj.removeClass('photobucket_action_like_disabled');
                       	$button_obj.addClass('photobucket_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_action_dislike_active');
							$OPTIONS11.addClass('photobucket_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter3').val($OPTIONS11.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/profile/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    $EVENT.stopPropagation;
					$EVENT.stopPropagation? $EVENT.stopPropagation() : $EVENT.cancelBubble = true;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_dislike_active')){
					    $button_obj.removeClass('photobucket_action_dislike_active');
                       	$button_obj.addClass('photobucket_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_action_like_active')){
							$OPTIONS10.removeClass('photobucket_action_like_active');
							$OPTIONS10.addClass('photobucket_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/profile/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				//PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				//PHOTOBUCKET_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				if( $('#image_object').val()!='[]'){
					PHOTOBUCKET_PROFILE_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){  
                                CONSOLE_MANAGER('MEMBER_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
                                            var $length = parseInt($('#population_index_count').val())+1;
                                            var $code = $("#population_index").val();
                                            for(var $i=0; $i< $length; $i++){
                                                var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                                                //var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                                var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                                                var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                                                var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                                                var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                                                header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                                            }
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqUserId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('MEMBER_Media','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('MEMBER_Media',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('MEMBER_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('MEMBER_Media',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('MEMBER_Media',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_PROFILE_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('MEMBER_Media');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_EVENT_Media                    ########################## */
export function PROFILE_EVENT_Media(){
		$(document).ready(function() {
			CONSOLE_MANAGER('PROFILE_EVENT_Media','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
					
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                   	header_background_activate($($IMAGESEQUENCE),$($OPTIONS01));
					//header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
					//header_background_activate($($EVENTEQUENCE),$($OPTIONS01));
					/* -------------------------------------------------------- */
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
					REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
					REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
					/* -------------------------------------------------------- */
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
					REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                }
            }
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_like_active')){
					    $button_obj.removeClass('photobucket_action_like_active');
                       	$button_obj.addClass('photobucket_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_action_like_disabled')){
						$button_obj.removeClass('photobucket_action_like_disabled');
                       	$button_obj.addClass('photobucket_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_action_dislike_active');
							$OPTIONS11.addClass('photobucket_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter3').val($OPTIONS11.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_dislike_active')){
					    $button_obj.removeClass('photobucket_action_dislike_active');
                       	$button_obj.addClass('photobucket_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_action_like_active')){
							$OPTIONS10.removeClass('photobucket_action_like_active');
							$OPTIONS10.addClass('photobucket_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				//PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				if(($('#image_object_photobucket').val()) ? $('#image_object_photobucket').val() : $('#image_object').val()!='[]'){
					PHOTOBUCKET_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('PROFILE_EVENT_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
                                            var $length = parseInt($('#population_index_count').val())+1;
                                            var $code = $("#population_index").val();
                                            for(var $i=0; $i< $length; $i++){
                                                var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                                                //var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';

                                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                                var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                                                var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                                                var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                                                var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';

                                                header_background_activate($($PATHSEQUENCE),$($OPTIONS01));

                                            }
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON(($('#image_object_photobucket').val()) ? $('#image_object_photobucket').val() : $('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse(($('#image_object_photobucket').val()) ? $('#image_object_photobucket').val() : $('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqEventId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('PROFILE_EVENT_Media','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object_photobucket').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('PROFILE_EVENT_Media',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('PROFILE_EVENT_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('PROFILE_EVENT_Media',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('PROFILE_EVENT_Media',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){

								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('PROFILE_EVENT_Media');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	GENERAL_Settings_basics                     ########################## */
export function GENERAL_Settings_basics(){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('GENERAL_Settings_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* ----------------------------GENERAL_Account_basics----------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SETTINGS_MENU_CONTROL('general');
			/* --------------------------------------------------------  */
			if($('#business_account_summary_options1')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options1");
			}
			if($('#business_account_summary_options2')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options2");
			}
			if($('#business_account_summary_options3')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options3");
			}
			if($('#business_account_summary_options4')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options4");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION('sys_return_options1');
			}
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("social_media_register_options20");
			SET_BUTTON_CLICK_ACTION("search_engine_index_register_options20");
            /* --------------------------------------------------------  
            if($('#social_media_register_options1active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options1active");
                $('#social_media_register_options1active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options1active').attr('id')); 
                });
            }
            if($('#social_media_register_options1').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options1");
                $('#social_media_register_options1').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options1').attr('id')); 
                });
            }
            if($('#social_media_register_options2active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options2active");
                $('#social_media_register_options2active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options2').attr('id')); 
                });
            }
            if($('#social_media_register_options2').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options2");
                $('#social_media_register_options2').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options2').attr('id')); 
                });
            }
            if($('#social_media_register_options3active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options3active");
                $('#social_media_register_options3active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options3').attr('id')); 
                });
            }
            if($('#social_media_register_options3').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options3");
                $('#social_media_register_options3').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options3').attr('id')); 
                });
            }
            if($('#social_media_register_options4active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options4active");
                $('#social_media_register_options4active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options4').attr('id')); 
                });
            }
            if($('#social_media_register_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options4");
                $('#social_media_register_options4').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options4').attr('id')); 
                });
            }
            if($('#social_media_register_options5active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options5active");
                $('#social_media_register_options5active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options5').attr('id')); 
                });
            }
            if($('#social_media_register_options5').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options5");
                $('#social_media_register_options5').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options5').attr('id')); 
                });
            }
            if($('#social_media_register_options6active').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options6active");
                $('#social_media_register_options6active').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options6').attr('id')); 
                });
            }
            if($('#social_media_register_options6').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options6");
                $('#social_media_register_options6').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options6').attr('id')); 
                });
            }
            */
            /*
            if($('#social_media_register_options7').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options7");
                $('#social_media_register_options7').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options7').attr('id')); 
                });
            }
            if($('#social_media_register_options8').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options8");
                $('#social_media_register_options8').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options8').attr('id')); 
                });
            }
            if($('#social_media_register_options9').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options9");
                $('#social_media_register_options9').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options9').attr('id')); 
                });
            }
            */
            if($('#social_media_register_options10').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_register_options10");
                $('#social_media_register_options10').unbind().click(function(event) {
                    $('#social_media_register_control').val($('#social_media_register_options10').attr('id')); 
                });
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_summary_options1")){
				SET_BUTTON_CLICK_ACTION("account_summary_options1");
			}
			if(document.getElementById("account_summary_options2")){
				SET_BUTTON_CLICK_ACTION("account_summary_options2");
			}
			if(document.getElementById("account_summary_options3")){
				SET_BUTTON_CLICK_ACTION("account_summary_options3");
			}
			if(document.getElementById("account_summary_options4")){
				SET_BUTTON_CLICK_ACTION("account_summary_options4");
			}
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("account_schedule_options6");
			SET_BUTTON_CLICK_ACTION("account_schedule_options8");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* 
			if(document.getElementById("settings_activity_type2_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type2_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type2_activityStatus");
				var Settings_Activity_activityStatus_label = 'userMessageNotification';
				var curr_val = document.getElementById("settings_activity_type2_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type2_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type2_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type2_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type3_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type3_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type3_activityStatus");
				var Settings_Activity_activityStatus_label = 'agendaNotification';
				var curr_val = document.getElementById("settings_activity_type3_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type3_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type3_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type3_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type4_activityStatus")){
				SET_BUTTON_CLICK_ACTION("settings_activity_type4_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type4_activityStatus");
				var Settings_Activity_activityStatus_label = 'agendaNotification';
				var curr_val = document.getElementById("settings_activity_type4_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type4_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type4_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type4_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type5_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type5_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type5_activityStatus");
				var Settings_Activity_activityStatus_label = 'followingNotification';
				var curr_val = document.getElementById("settings_activity_type5_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type5_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type5_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type5_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type6_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type6_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type6_activityStatus");
				var Settings_Activity_activityStatus_label = 'followersNotification';
				var curr_val = document.getElementById("settings_activity_type6_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type6_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type1_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type6_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type7_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type7_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type7_activityStatus");
				var Settings_Activity_activityStatus_label = 'recommendersNotification';
				var curr_val = document.getElementById("settings_activity_type7_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type7_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type7_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type7_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type8_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type8_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type8_activityStatus");
				var Settings_Activity_activityStatus_label = 'eventReqNotification';
				var curr_val = document.getElementById("settings_activity_type8_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type8_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type8_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type8_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type9_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type9_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type9_activityStatus");
				var Settings_Activity_activityStatus_label = 'proximityNotification';
				var curr_val = document.getElementById("settings_activity_type9_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type9_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type9_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type9_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			if(document.getElementById("settings_activity_type10_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type10_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type10_activityStatus");
				var Settings_Activity_activityStatus_label = 'calendarNotification';
				var curr_val = document.getElementById("settings_activity_type10_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type10_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type10_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type10_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			--------------------------------------------------------  */
			if(document.getElementById("confirmation_options1")){
				SET_BUTTON_CLICK_ACTION("confirmation_options1")
			}
			if(document.getElementById("notification_options1")){
				SET_BUTTON_CLICK_ACTION("notification_options1")
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("account_security_options2")){
				SET_BUTTON_CLICK_ACTION("account_security_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options2'),null,null,null,null);
			}
			if(document.getElementById("account_security_options3")){
				SET_BUTTON_CLICK_ACTION("account_security_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options3'),null,null,null,null);
			}
			if(document.getElementById("account_security_options4")){
				SET_BUTTON_CLICK_ACTION("account_security_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options4'),null,null,null,null);
			}
			if(document.getElementById("account_security_options5")){
				SET_BUTTON_CLICK_ACTION("account_security_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options4'),null,null,null,null);
			}
			/* --------------------------------------------------------  
			if(document.getElementById("account_security_options2active")){
				SET_BUTTON_CLICK_ACTION("account_security_options2active");
			}
			if(document.getElementById("account_security_options3active")){
				SET_BUTTON_CLICK_ACTION("account_security_options3active");
			}
			if(document.getElementById("account_security_options4active")){
				SET_BUTTON_CLICK_ACTION("account_security_options4active");
			}
			if(document.getElementById("account_security_options5active")){
				SET_BUTTON_CLICK_ACTION("account_security_options5active");
			}*/
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("account_security_options24")){
				SET_BUTTON_CLICK_ACTION("account_security_options24");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options24'),null,null,null,null);
			}
			if(document.getElementById("account_security_options25")){
				SET_BUTTON_CLICK_ACTION("account_security_options25");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options25'),null,null,null,null);
			}
			if(document.getElementById("account_security_options26")){
				SET_BUTTON_CLICK_ACTION("account_security_options26");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_security_options26'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_schedule_options2")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options2'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options3")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options3'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options4")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options4'),null,null,null,null);
			}
			if(document.getElementById("account_schedule_options5")){
				SET_BUTTON_CLICK_ACTION("account_schedule_options5");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_schedule_options5'),null,null,null,null);
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_notification_options2")){
				SET_BUTTON_CLICK_ACTION("account_notification_options2");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options2'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options3")){
				SET_BUTTON_CLICK_ACTION("account_notification_options3");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options3'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options4")){
				SET_BUTTON_CLICK_ACTION("account_notification_options4");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options4'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options5")){
				SET_BUTTON_CLICK_ACTION("account_notification_options5");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options5'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options6")){
				SET_BUTTON_CLICK_ACTION("account_notification_options6");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options6'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options7")){
				SET_BUTTON_CLICK_ACTION("account_notification_options7");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options7'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options9")){
				SET_BUTTON_CLICK_ACTION("account_notification_options9");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options9'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options10")){
				SET_BUTTON_CLICK_ACTION("account_notification_options10");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options10'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options11")){
				SET_BUTTON_CLICK_ACTION("account_notification_options11");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options11'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options12")){
				SET_BUTTON_CLICK_ACTION("account_notification_options12");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options12'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options14")){
				SET_BUTTON_CLICK_ACTION("account_notification_options14");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options14'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options15")){
				SET_BUTTON_CLICK_ACTION("account_notification_options15");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options15'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options16")){
				SET_BUTTON_CLICK_ACTION("account_notification_options16");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options16'),null,null,null,null);
			}
			if(document.getElementById("account_notification_options17")){
				SET_BUTTON_CLICK_ACTION("account_notification_options17");
				//NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_notification_options17'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';
			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_bio_bioselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_bio1_var, set_bio1_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_bio2_var, set_bio2_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupC+set1+"_input");
				//	var set_button_input_value = document.getElementById("account_bio_bioselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_bio3_var, set_bio3_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_bio4_var, set_bio4_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_bio5_var, set_bio5_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_bio6_var, set_bio6_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_bio7_var, set_bio7_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_bio8_var, set_bio8_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_bio9_var, set_bio9_value);
				}

				if(document.getElementById("account_bio_bioselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_bio_bioselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_bio_bioselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_bio_bioselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_bio_bioselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_bio10_var, set_bio10_value);
				}
			}
			/* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';

			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_geo_geoselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_geo1_var, set_geo1_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_geo2_var, set_geo2_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupC+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_geo3_var, set_geo3_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_geo4_var, set_geo4_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_geo5_var, set_geo5_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_geo6_var, set_geo6_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_geo7_var, set_geo7_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_geo8_var, set_geo8_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_geo9_var, set_geo9_value);
				}

				if(document.getElementById("account_geo_geoselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_geo_geoselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_geo_geoselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_geo_geoselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_geo_geoselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_geo10_var, set_geo10_value);
				}
			}
			/* --------------------------------------------------------  */
			var groupA ='A';var groupB ='B';var groupC ='C';var groupD ='D';var groupE ='E';
			var groupF ='F';var groupG ='G';var groupH ='H';var groupI ='I';var groupJ ='J';
			/* --------------------------------------------------------  */
			var set1=1;
			for(set1; set1<6; set1++){
				if(document.getElementById("account_socio_socioselect"+groupA+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupA+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupA+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupA+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupA+set1);
					//SET_SURVEY_ACTIVE(groupA,set_button_var,set_button_input_value, set_socio1_var, set_socio1_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupB+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupB+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupB+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupB+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupB+set1);
					//SET_SURVEY_ACTIVE(groupB,set_button_var,set_button_input_value, set_socio2_var, set_socio2_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupC+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupC+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupC+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupC+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupC+set1);
					//SET_SURVEY_ACTIVE(groupC,set_button_var,set_button_input_value, set_socio3_var, set_socio3_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupD+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupD+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupD+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupD+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupD+set1);
					//SET_SURVEY_ACTIVE(groupD,set_button_var,set_button_input_value, set_socio4_var, set_socio4_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupE+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupE+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupE+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupE+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupE+set1);
					//SET_SURVEY_ACTIVE(groupE,set_button_var,set_button_input_value, set_socio5_var, set_socio5_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupF+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupF+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupF+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupF+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupF+set1);
					//SET_SURVEY_ACTIVE(groupF,set_button_var,set_button_input_value, set_socio6_var, set_socio6_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupG+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupG+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupG+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupG+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupG+set1);
					//SET_SURVEY_ACTIVE(groupG,set_button_var,set_button_input_value, set_socio7_var, set_socio7_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupH+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupH+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupH+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupH+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupH+set1);
					//SET_SURVEY_ACTIVE(groupH,set_button_var,set_button_input_value, set_socio8_var, set_socio8_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupI+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupI+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupI+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupI+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupI+set1);
					//SET_SURVEY_ACTIVE(groupI,set_button_var,set_button_input_value, set_socio9_var, set_socio9_value);
				}

				if(document.getElementById("account_socio_socioselect"+groupJ+set1)){
					//var set_button_var = document.getElementById("account_socio_socioselect"+groupJ+set1);
					//var set_button_input_var = document.getElementById("account_socio_socioselect"+groupJ+set1+"_input");
					//var set_button_input_value = document.getElementById("account_socio_socioselect"+groupJ+set1+"_input").value;
					SET_BUTTON_CLICK_ACTION("account_socio_socioselect"+groupJ+set1);
					//SET_SURVEY_ACTIVE(groupJ,set_button_var,set_button_input_value, set_socio10_var, set_socio10_value);
				}
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('GENERAL_Settings_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	SECURITY_listview_basics                    ########################## */
export function SECURITY_listview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('SECURITY_listview_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* ------------------------------------------------- */
			/* ------------------------------------------------- */	
			if(document.getElementsByClassName("Profile_return_Activity_UI_class")){
				SET_BUTTON_CLICK_ACTION('profile_return_submit');
			}
			/* ------------------------------------------------- */	
			if(document.getElementsByClassName("Profile_filter_Activity_UI_class")){
				SET_BUTTON_CLICK_ACTION('profile_listview_active_filter');
			}
			/* ------------------------------------------------- */	
		/* -------------------------------------------------------- */	
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('SECURITY_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_PIC_basics                          ########################## */
export function PROFILE_PIC_basics(){
	$(document).ready(function(){
			CONSOLE_MANAGER('PROFILE_PIC_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('.ie_functional_table_td_row_side_active').is(':visible')){
				//media_image_select();
				//MEDIA_PHOTO_basics();
				//main_image
				PROFILE_PHOTO_basics();
			}else{
                /* ------------------------------------------------- */
                $('.Modify_Menu_UI_class').attr("id", 'Modify_Menu_UI');
                $('.File_Upload_Label_class_main').attr("id", 'profile_pic_main');
                $('.Settings_Profile_UI_class').attr("id", 'File_Profile_UI_control');
                /* ------------------------------------------------- */
                /* -----*///CLASS
                var STAT_ARRAY_MGMT = new Array();
                var STAT_PATH_MGMT = new Array();

                var $i=1;
                for($i; $i<13; $i++){
                    /* ------------------------------------------------- */
                    $('.File_Upload'+$i+'_UI_class').attr("id", 'File_Upload'+$i+'_UI_control');//File_Upload2_UI_class	//File_Upload1_UI_control
                    /* ------------------------------------------------- */
                    if(document.getElementById('File_Upload'+$i+'_UI_control')){	
                        //var B_SEQUENCE = 'file_upload'+i+'_option1';
                        //var L_SEQUENCE = 'File_Upload_Label'+i;
                        //var P_SEQUENCE = 'file_upload'+i+'_file_path';
                        //var P_SEQUENCE = 'file_upload'+i+'_thumb_path';
                        //var F_SEQUENCE = 'file_upload'+i+'_file';
                        //var ID_SEQUENCE ='File_Upload'+i+'_UI_control';

                        /* ------------------------------------------------- */
                        var $OPTIONS01 = 'file_upload'+$i+'_option1';
                        var $LABEL_SEQUENCE = 'File_Upload_Label'+$i;
                        //var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        //var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        //var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                        //var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                        //var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                        var $PATHSEQUENCE = 'file_upload'+$i+'_file_path';
                        var $THUMBSEQUENCE = 'file_upload'+$i+'_thumb_path';
                        var $FILE_SEQUENCE = 'file_upload'+$i+'_file';
                        var $ID_SEQUENCE ='File_Upload'+$i+'_UI_control';
                        //var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        //var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        //var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        //var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        /* ------------------------------------------------- */
                        if($('#'+$LABEL_SEQUENCE).is(':visible')) {
                            ACTIVATE_UPLOAD_FORM($('#'+$FILE_SEQUENCE),$('#'+$ID_SEQUENCE),$('#'+$ID_SEQUENCE));
                        }
                        /* ------------------------------------------------- */
                        if($('#'+$OPTIONS01).is(':visible')) {
                            REGISTER_CLICK($('#'+$OPTIONS01),$i,$PATHSEQUENCE);
                            STAT_ARRAY_MGMT[$i]=$OPTIONS01;
                            STAT_PATH_MGMT[$i]=document.getElementById($PATHSEQUENCE).value;
                            URL = $('#'+$THUMBSEQUENCE).val()+'?'+Math.random();
                            $('#'+$OPTIONS01).css('background-image','url('+URL+')');
                        }
                        /* ------------------------------------------------- */
                    }
                }
                /* ------------------------------------------------- */
                STAT_ARRAY_MGMT = STAT_ARRAY_MGMT.filter(function(n){ return n != undefined }); 
                STAT_PATH_MGMT = STAT_PATH_MGMT.filter(function(n){ return n != undefined });
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if($('#file_upload_option1').is(':visible')){
                    /* --------------------------------------------------------  */
                    if($('#file_upload_thumb_path').val()!=null && $('#file_upload_thumb_path').val()!='FALSE'){
                        //if($('#file_upload_file_path').val()!=null && $('#file_upload_file_path').val()!='FALSE' && $('#file_upload_file_path').val()!=' '){
                        if($('#file_upload_file_path').val()){
                            var URL_VAL = $('#file_upload_thumb_path').val();//+'?'+Math.random()
                            $('#file_upload_option1').css('background-image','url('+URL_VAL+')');
                            REGISTER_CLICK($('#file_upload_option1'),'file_upload_option1',$('#file_upload_file_path'));
                        }
                    }
                }
                /* --------------------------------------------------------  */
                function REGISTER_CLICK($button_obj,$INDEX_VAR,$PATHSEQUENCE){
                    if($INDEX_VAR=='file_upload_option1'){
                        $CONTROL = 'profile_pic_main';
                        $('#file_modify_option4').removeClass("bkground_div_controlProfileActive");
                        $('#file_modify_option4').removeClass("bkground_div_controlProfile");
                        $('#file_modify_option4').addClass("bkground_div_controlProfile");
                        /* --------------------------------------------------------  */
                        $button_obj.unbind().click(function(event) {
                            event.stopPropagation;
                            Set_manage_edit_image($INDEX_VAR);
                            $('#standard_entity_modify_formCurrentIndex').val($CONTROL);

                        });
                    }
                    else{
                         var $CONTROL = 'profile_pic_'+$INDEX_VAR;
                        /* --------------------------------------------------------  */
                        $button_obj.unbind().click(function(event) {
                            event.stopPropagation;
                            Set_manage_edit_image($INDEX_VAR);
                            $('#standard_entity_modify_formCurrentIndex').val($CONTROL);

                        });
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function ACTIVATE_UPLOAD_FORM($form_var,$submit_var,$class_var){	
                    if($form_var){
                        $form_var.change(function (){
                            ACTIVATE_UPLOAD_FORM_INIT($class_var);
                        });
                    }
                }/*END ON CHANGE */
                /* --------------------------------------------------------  */
                function Default_manage_edit_image(){
                    $('#manage_edit_image').css('background-image','url( )');

                    $('.setting_photo_manage_image').show();
                    $('.setting_photo_manage_image_active').hide();

                    $('#main_image').attr('src', ' ');
                    $('#main_image').attr('width', ' ');
                    $('#main_image').attr('height', ' ');
                    $('#main_image').css({'margin-left':'0px'});
                    $('#main_image').css({'margin-top':'0px'});

                }/*END ON CHANGE */
                /* --------------------------------------------------------  */
                function Set_manage_edit_image($INDEX_VAR){
                    Default_manage_edit_image();
                    function setMeta(url){
                        $('.setting_photo_manage_image').hide();
                        $('.setting_photo_manage_image_active').show();
                        var tmpImg = new Image();
                        tmpImg.src=url+'?'+Math.random(); //or  document.images[i].src;
                        $(tmpImg).one('load',function(){
                            var orgWidth = parseInt(tmpImg.width);
                            var orgHeight = parseInt(tmpImg.height);

                            var frameWidth = parseInt($('#setting_photo_image_window_td1_sub').width());
                            var frameHeight = parseInt($('#setting_photo_image_window_td1_sub').height())
                            /* --------------------------------------------------------  */
                            if((orgWidth>orgHeight)&&(orgWidth>frameWidth)){
                                //orgWidth = orgWidth - (((orgWidth-frameWidth)/orgWidth)*orgWidth);
                                //orgHeight = orgHeight - (((orgWidth-frameWidth)/orgWidth)*orgHeight);
                                orgHeight = orgHeight - (((orgWidth-frameWidth)/orgWidth)*orgHeight);
                                orgWidth = frameWidth;
                            }
                            else if((orgWidth<orgHeight)&&(orgHeight>frameHeight)){
                                orgHeight = frameHeight;


                            }else if((orgWidth==orgHeight)&&(orgWidth>frameHeight)){
                                orgWidth = frameWidth;
                                orgHeight = frameHeight;
                            }

                            ///else if(orgHeight>frameHeight){
                            //	orgWidth = orgWidth - (((orgHeight-frameHeight)/orgHeight)*orgWidth);
                            //	orgHeight = orgHeight - (((orgHeight-frameHeight)/orgHeight)*orgHeight);
                            //}
                            /* --------------------------------------------------------  */
                            $('#main_image').attr('width', orgWidth);
                            $('#main_image').attr('height', orgHeight);
                            $('#main_image').attr('src', url);	

                            //$('#main_image').css("margin-top", (frameHeight - orgHeight) / 2 + "px");
                            $('#main_image').css("margin-top", (frameHeight - orgHeight) / 2 + "px");
                            $('#main_image').css("margin-left", (frameWidth - orgWidth) / 2 + "px");
                            $('#main_image').css("position", 'absolute');	
                            /* --------------------------------------------------------  */
                            if($INDEX_VAR=='file_upload_option1'){
                                var $CONTROL = 'profile_pic_main';
                                var $INDEX_VAR = '0';
                            }else{
                                var $CONTROL = 'profile_pic_'+$INDEX_VAR;
                            }
                            /* --------------------------------------------------------  file_modify_option4*/
                            $('#parent_edit_image').unbind().click(function(event) {
                                event.stopPropagation;
                                $('#standard_entity_modify_control').val('image_window_double');
                                $('#standard_entity_modify_formCurrentIndex').val($INDEX_VAR);
                                standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event);
                            });
                        });
                    }
                    if($INDEX_VAR=='file_upload_option1'){
                        setMeta($('#file_upload_file_path').val());
                    }else{
                        //var P_SEQUENCE = 'file_upload'+$INDEX_VAR+'_file_path';
                        var $PATHSEQUENCE = 'file_upload'+$INDEX_VAR+'_file_path';
                        var $THUMBSEQUENCE = 'file_upload'+$INDEX_VAR+'_thumb_path';
                        setMeta($('#'+$PATHSEQUENCE).val()+'?'+Math.random());
                    }
                    SET_ACTIVE_MENU($INDEX_VAR,$('#'+$PATHSEQUENCE));
                }/*END ON CHANGE */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function SET_ACTIVE_MENU($INDEX_VAR,$PATHSEQUENCE){
                    if($('.Modify_Menu_UI_class').is(':visible')){
                        $('#file_modify_option2').addClass("bkground_div_controlCancelActive");
                        $('#file_modify_option3').addClass("bkground_div_controlDeleteActive");
                        $('#file_modify_option4').addClass("bkground_div_controlProfileActive");
                        $('#file_modify_option5').addClass("bkground_div_controlClearActive");

                        $('#file_modify_option2').removeClass("bkground_div_controlCancel");
                        $('#file_modify_option3').removeClass("bkground_div_controlDelete");
                        $('#file_modify_option4').removeClass("bkground_div_controlProfile");
                        $('#file_modify_option5').removeClass("bkground_div_controlClear");

                        if($INDEX_VAR=='file_upload_option1'){
                            $CONTROL = 'profile_pic_main';
                            $('#file_modify_option4').removeClass("bkground_div_controlProfileActive");
                            $('#file_modify_option4').removeClass("bkground_div_controlProfile");
                            $('#file_modify_option4').addClass("bkground_div_controlProfile");
                        }
                        else{
                            var $CONTROL = 'profile_pic_'+$INDEX_VAR;
                            var B_SEQUENCE = 'file_upload'+$INDEX_VAR+'_option1';
                            var L_SEQUENCE = 'File_Upload_Label'+$INDEX_VAR;
                            var P_SEQUENCE = 'file_upload'+$INDEX_VAR+'_file_path';
                            var F_SEQUENCE = 'file_upload'+$INDEX_VAR+'_file';
                            var ID_SEQUENCE ='File_Upload'+$INDEX_VAR+'_UI_control';
                        }

                        $('#file_modify_file_path').val('');
                        $('#file_modify_thumb_path').val('');
                        $('#file_modify_name').val('');
                        $('#file_modify_form_name').val('');
                        $('#file_modify_control').val($CONTROL);

                        if ($('#file_modify_option2').hasClass("bkground_div_controlCancelActive")) {
                            SET_BUTTON_CLICK_ACTION('file_modify_option2');
                            document.getElementById('file_modify_option2').addEventListener("click", function(e){
                                SET_INACTIVE_MENU();
                            }, false);	
                        }
                        if ($('#file_modify_option3').hasClass("bkground_div_controlDeleteActive")) {
                            SET_BUTTON_CLICK_ACTION('file_modify_option3');
                            document.getElementById('file_modify_option3').addEventListener("click", function(e){
                                $('#file_modify_form_name').val('file_modify_option3');
                                $('#file_modify_option1').unbind().click();
                            }, false);	
                        }
                        if ($('#file_modify_option4').hasClass("bkground_div_controlProfileActive")) {
                            SET_BUTTON_CLICK_ACTION('file_modify_option4');
                            document.getElementById('file_modify_option4').addEventListener("click", function(e){
                                if ($PATHSEQUENCE.val()) {
                                    CONSOLE_MANAGER('PROFILE_PIC_basics','FORM ATTACHED IMAGE: '+$PATHSEQUENCE);
                                    $('#file_modify_form_name').val('file_modify_option4');
                                    $('#file_modify_option1').unbind().click();
                                }
                                else { 
                                    CONSOLE_MANAGER('PROFILE_PIC_basics','NO ATTACHED IMAGE');
                                }                                
                            }, false);	
                        }
                        if ($('#file_modify_option5').hasClass("bkground_div_controlClearActive")) {
                            document.getElementById('file_modify_option5').addEventListener("click", function(e){
                                SET_INACTIVE_MENU();
                            }, false);	
                        }
                    }
                }
                /* --------------------------------------------------------  */
                function SET_INACTIVE_MENU(){
                    if($('.Modify_Menu_UI_class').is(':visible')){
                        $('#file_modify_option2').addClass("bkground_div_controlCancel");
                        $('#file_modify_option3').addClass("bkground_div_controlDelete");
                        $('#file_modify_option4').addClass("bkground_div_controlProfile");
                        $('#file_modify_option5').addClass("bkground_div_controlClear");

                        $('#file_modify_option2').removeClass("bkground_div_controlCancelActive");
                        $('#file_modify_option3').removeClass("bkground_div_controlDeleteActive");
                        $('#file_modify_option4').removeClass("bkground_div_controlProfileActive");
                        $('#file_modify_option5').removeClass("bkground_div_controlClearActive");

                        $('#file_modify_file_path').val('');
                        $('#file_modify_thumb_path').val('');
                        $('#file_modify_name').val('');
                        $('#file_modify_form_name').val('');
                        $('#file_modify_control').val('');
                        Default_manage_edit_image();
                        window_complete_script();
                    }
                }
                
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function SET_IMAGE_MENU(){
                    function PhotoControl(controlDiv) {
                        controlDiv.style.padding = '5px';
                        var controlUI = document.createElement('div');
                        controlUI.id = "controlUI_control";
                        controlUI.className = 'Local_build_UI_control_class';
                        controlDiv.appendChild(controlUI);
                        /************************************/

                        var bkground_div3 = document.createElement('div');
                        bkground_div3.className = 'bkground_div_controlDelete';
                        bkground_div3.id = "bkground_div_control3";
                        /**********/
                        var bkground_div2 = document.createElement('div');
                        bkground_div2.className = 'bkground_div_controlCancel';
                        bkground_div2.id = "bkground_div_control2";
                        /**********/	
                        var bkground_div1 = document.createElement('div');
                        bkground_div1.className = '';//bkground_div_controlReset
                        //bkground_div3.className = 'bkground_div_controlResetActive';
                        bkground_div1.id = "bkground_div_control1";
                        /*********
                        var bkground_div4 = document.createElement('div');
                        bkground_div4.className = 'bkground_div_controlStats';
                        bkground_div4.id = "bkground_div_control4";
                        */
                        /**********/
                        //var bkground_div5 = document.createElement('div');
                        //bkground_div5.className = 'bkground_div_controlSave';
                        //bkground_div5.id = "bkground_div_control5";
                        /**********/
                        var bkground_div6 = document.createElement('div');
                        bkground_div6.className = 'bkground_div_controlProfile';
                        bkground_div6.id = "bkground_div_control6";
                        /********************************************************/
                        /********************************************************/
                        /********************************************************/
                        // Set CSS for the control interior
                        var controlbutton1 = document.createElement('div');
                        controlbutton1.className = 'controlbutton_control';
                        controlbutton1.id = "controlbutton1_control";
                        controlbutton1.appendChild(bkground_div1);	/*  UNDEFINED  */
                        controlUI.appendChild(controlbutton1);
                        /**********/
                        var controlbutton2 = document.createElement('div');
                        controlbutton2.className = 'controlbutton_control';
                        controlbutton2.id = "controlbutton2_control";
                        controlbutton2.appendChild(bkground_div2);	/*  CANCEL  */
                        controlUI.appendChild(controlbutton2);
                        /**********/
                        var controlbutton3 = document.createElement('div');
                        controlbutton3.className = 'controlbutton_control';
                        controlbutton3.id = "controlbutton3_control";
                        controlbutton3.appendChild(bkground_div3);	/*  DELETE  */
                        controlUI.appendChild(controlbutton3);
                        /*********
                        var controlbutton4 = document.createElement('div');
                        controlbutton4.className = 'controlbutton_control';
                        controlbutton4.id = "controlbutton4_control";
                        controlbutton4.appendChild(bkground_div4);	
                        controlUI.appendChild(controlbutton4);
                        *//*  STATS  */
                        /**********/
                        var controlbutton5 = document.createElement('div');
                        controlbutton5.className = 'controlbutton_control';
                        controlbutton5.id = "controlbutton5_control";
                        controlbutton5.appendChild(bkground_div6);	/*  MAKE PROFILE  */
                        controlUI.appendChild(controlbutton5);
                    }
                    var edit_image_window_div = document.getElementById("manage_image_menu");
                    var PhotoControlDiv = document.createElement('div');
                    var homeControldiv = new PhotoControl(PhotoControlDiv);
                    PhotoControlDiv.index = 1;
                    if($('#manage_image_menu').is(':visible')){
                        if(document.getElementById("manage_image_menu").hasChildNodes()){
                            var element = document.getElementById("manage_image_menu");
                            while (element.firstChild) {
                              element.removeChild(element.firstChild);
                            }
                            if(document.getElementById("profileUI_control_id")){
                                if(document.getElementById("profileUI_control_id").hasChildNodes()){
                                    var element = document.getElementById("profileUI_control_id");
                                    while (element.firstChild) {
                                      element.removeChild(element.firstChild);
                                    }
                                }
                            }
                        }
                        if($(".imgareaselect-selection")){
                            $('#main_image').imgAreaSelect({remove:true});
                            $(".imgareaselect-selection").parent().remove();
                            $(".imgareaselect-outer").remove();	
                        }
                        edit_image_window_div.appendChild(PhotoControlDiv);
                        PhotoControlDiv.className = 'controlUI_control_class';
                        PhotoControlDiv.id = "controlUI_control_id";
                        PhotoControlDiv.style.visibility = "visible";
                        PhotoControlDiv.style.display = 'inline-block';
                    }
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    /* -----  *///BUTTONS
                    if(document.getElementById("controlbutton2_control")){	//SET DEFAULT IMAGE
                        var Control_Menu_button_2 = document.getElementById("controlbutton2_control");
                        Control_Menu_button_2.addEventListener("click", function(){

                            FP_D = document.getElementById('file_modify_file_path').value;
                            FT_D = document.getElementById('file_modify_thumb_path').value;
                            FN_D = document.getElementById('file_modify_name').value;

                            if((FP_D == null) || (FT_D == null) || (FN_D == null) || (!FP_D) || (!FT_D) || (!FN_D)){
                            }
                            if((FP_D) && (FT_D) && (FN_D)){
                                SET_DEFAULT_IMAGE();
                            }
                            FP_D = null; FT_D = null; FN_D = null;

                        }, false);	
                    }
                }
                //SET_IMAGE_MENU();
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function SET_PROFILE_IMAGE_MENU(){
                    function PhotoControl(controlDiv) {

                        controlDiv.style.padding = '5px';

                        var controlUI = document.createElement('div');
                        controlUI.id = "profilelUI_control";
                        controlUI.className = 'Local_build_UI_control_class';
                        controlDiv.appendChild(controlUI);
                        /************************************/
                        var bkground_div1 = document.createElement('div');
                        bkground_div1.className = '';
                        //bkground_div3.className = 'bkground_div_controlResetActive';
                        bkground_div1.id = "profile_div_control1";
                        /**********/
                        var bkground_div2 = document.createElement('div');
                        bkground_div2.className = '';
                        bkground_div2.id = "profile_div_control2";
                        /**********/
                        var bkground_div3 = document.createElement('div');
                        bkground_div3.className = '';
                        bkground_div3.id = "profile_div_control3";
                        /**********/
                        var bkground_div4 = document.createElement('div');
                        bkground_div4.className = 'bkground_div_controlCancelActive';
                        bkground_div4.id = "profile_div_control4";
                        /**********/
                        var bkground_div5 = document.createElement('div');
                        bkground_div5.className = 'bkground_div_controlSaveProfile';
                        bkground_div5.id = "profile_div_control5";
                        /**********/
                        /********************************************************/
                        /********************************************************/
                        /********************************************************/
                        // Set CSS for the control interior
                        var controlbutton1 = document.createElement('div');
                        controlbutton1.className = 'controlbutton_control';
                        controlbutton1.id = "profile1_control";
                        controlbutton1.appendChild(bkground_div1);	/*    */
                        controlUI.appendChild(controlbutton1);
                        /**********/
                        var controlbutton2 = document.createElement('div');
                        controlbutton2.className = 'controlbutton_control';
                        controlbutton2.id = "profile2_control";
                        controlbutton2.appendChild(bkground_div2);	/*    */
                        controlUI.appendChild(controlbutton2);
                        /**********/
                        var controlbutton3 = document.createElement('div');
                        controlbutton3.className = 'controlbutton_control';
                        controlbutton3.id = "profile3_control";
                        controlbutton3.appendChild(bkground_div3);	/*    */
                        controlUI.appendChild(controlbutton3);
                        /**********/
                        var controlbutton4 = document.createElement('div');
                        controlbutton4.className = 'controlbutton_control';
                        controlbutton4.id = "profile4_control";
                        controlbutton4.appendChild(bkground_div4);	/*  CANCEL  */
                        controlUI.appendChild(controlbutton4);
                        /**********/
                        var controlbutton5 = document.createElement('div');
                        controlbutton5.className = 'controlbutton_control';
                        controlbutton5.id = "profile5_control";
                        controlbutton5.appendChild(bkground_div5);	/*  MAKE PROFILE  */
                        controlUI.appendChild(controlbutton5);
                    }
                    var edit_image_window_div = document.getElementById("manage_image_menu");
                    var PhotoControlDiv = document.createElement('div');
                    var homeControldiv = new PhotoControl(PhotoControlDiv);
                    PhotoControlDiv.index = 1;
                    if(document.getElementById("manage_image_menu").hasChildNodes()){
                        var element = document.getElementById("manage_image_menu");
                        while (element.firstChild) {
                          element.removeChild(element.firstChild);
                        }
                    }
                    edit_image_window_div.appendChild(PhotoControlDiv);
                    PhotoControlDiv.className = 'controlUI_control_class';
                    PhotoControlDiv.id = "profileUI_control_id";
                    PhotoControlDiv.style.visibility = "visible";
                    PhotoControlDiv.style.display = 'inline-block';
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    if(document.getElementById('profile_div_control4')){
                        var Profile_Modify_reset_menu_var = document.getElementById('profile_div_control4');//RESET PROFILE MENU
                        SET_BUTTON_CLICK_ACTION('profile_div_control4');
                        Profile_Modify_reset_menu_var.addEventListener("click", function(){
                            //Modify_Submit_Reset($( "#File_Modify_reset"));
                            if($(".imgareaselect-selection")){
                                $('#main_image').imgAreaSelect({remove:true});
                                $(".imgareaselect-selection").parent().remove();
                                $(".imgareaselect-outer").remove();	
                            }
                            SET_IMAGE_MENU();
                            SET_ACTIVE_MENU();
                        }, false);	
                    }
                    /* ------------------------------------------------- */
                    if(document.getElementById('profile_div_control5')){
                        var Profile_Modify_save_menu_var = document.getElementById('profile_div_control5');//SAVE PROFILE MENU
                        SET_BUTTON_CLICK_ACTION('profile_div_control5');
                        Profile_Modify_save_menu_var.addEventListener("click", function(){
                            Modify_Profile_Submit($( "#image_editor_main_thumb_save"));
                        }, false);	
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function SET_ACTIVE_MENU11(IMAGE_VAR){
                    document.getElementById('bkground_div_control1').className = '';//bkground_div_controlResetActive
                    document.getElementById('bkground_div_control2').className = 'bkground_div_controlCancelActive';
                    document.getElementById('bkground_div_control3').className = 'bkground_div_controlDeleteActive';
                    //document.getElementById('bkground_div_control4').className = 'bkground_div_controlStatsActive';
                    document.getElementById('bkground_div_control6').className = 'bkground_div_controlProfileActive';
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function Modify_Submit(trigger_val){
                    var FP_V = null; var FT_V = null; var FN_V = null;

                    FP_V = document.getElementById('file_modify_file_path').value;
                    FT_V = document.getElementById('file_modify_thumb_path').value;
                    FN_V = document.getElementById('file_modify_name').value;
                    FF_V =document.getElementById('file_modify_form_name').value;

                    if((FP_V == null) || (FT_V == null) || (FN_V == null) || (!FP_V) || (!FT_V) || (!FN_V)){

                    }
                    if((FP_V) && (FT_V) && (FN_V)){
                        trigger_val.trigger( "click" );
                    }	
                    FP_V = null; FT_V = null; FN_V = null;
                }
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                function Modify_Profile_Submit(trigger_val){
                    var FP_V = null; var FT_V = null; var FN_V = null;

                    FP_V = document.getElementById('file_modify_file_path').value;
                    FT_V = document.getElementById('file_modify_thumb_path').value;
                    FN_V = document.getElementById('file_modify_name').value;
                    FF_V =document.getElementById('file_modify_form_name').value;

                    X1 = document.getElementById('image_editor_main_thumb_x1').value;
                    Y1 = document.getElementById('image_editor_main_thumb_y1').value;
                    X2 = document.getElementById('image_editor_main_thumb_x2').value;
                    Y2 = document.getElementById('image_editor_main_thumb_y2').value;
                    HEIGHT = document.getElementById('image_editor_main_thumb_height').value;
                    WIDTH = document.getElementById('image_editor_main_thumb_width').value;
                    FORM = document.getElementById('image_editor_main_thumb_form_name').value;

                    if((FP_V == null) || (FT_V == null) || (FN_V == null) || (!FP_V) || (!FT_V) || (!FN_V) ||
                    (X1 == null) || (Y1 == null) || (X2 == null) || (Y2 == null) || (HEIGHT == null) || (WIDTH == null) || 
                    (!X1) || (!Y1) || (!X2) || (!Y2) || (!HEIGHT) || (!WIDTH) || (!FORM)
                    ){

                    }
                    if((FP_V) && (FT_V) && (FN_V) && (X1) && (Y1) && (X2) && (Y2) && (HEIGHT) && (WIDTH) && (FORM)){
                        trigger_val.trigger( "click" );
                    }
                }
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                function Modify_Submit_Reset(trigger_val){
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
            }/*END if($('.ie_functional_table_td_row_side_active').is(':visible')){*/
		/* --------------------------------------------------------  */
		//});
		/* --------------------------------------------------------  */
		window_complete_script('PROFILE_PIC_basics');
	});
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	GENERAL_Account_basics                      ########################## */
export function GENERAL_Account_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('GENERAL_Account_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			ACCOUNT_MENU_CONTROL('general');
			/* -----  *///CLASS settings_field_edit_options1
			$('.Account_Type_UI_class').attr("id", "Account_Type_UI_control");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION('sys_return_options1');
			}
            /* --------------------------------------------------------  */
			if($('#account_metrics_control').val()){ 
				SETTINGS_METRICS_CONTROL();
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//var email_validation_path = '/settings_ajax_profile';	file_upload1_file
			//var SETTINGS_SUBMIT_BUTTON_ID = "Settings_General";
            //canvas
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#business_account_summary_options1')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options1");
			}
			if($('#business_account_summary_options2')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options2");
			}
			if($('#business_account_summary_options3')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options3");
			}
			if($('#business_account_summary_options4')){
				SET_BUTTON_CLICK_ACTION("business_account_summary_options4");
			}
			/* --------------------------------------------------------  */
			PROFILE_PIC_basics();
			/* --------------------------------------------------------  */
			if(document.getElementById("business_recommendation_manager_options1")){
				SET_BUTTON_CLICK_ACTION('business_recommendation_manager_options1');
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_menu_options2")){
				var submit_button = document.getElementById("account_purchase_menu_options2");
				SET_BUTTON_CLICK_ACTION("account_purchase_menu_options2");
				submit_button.addEventListener("click", function(event){
					
				}, false);	
			}
			/* ------------------------------------------------- */	
			if(document.getElementById("account_general_silver_active")){
				var submit_button = document.getElementById("account_general_silver_active");
	
				SET_BUTTON_CLICK_ACTION("account_general_silver_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_silver_active");
				}, false);	
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_general_gold_active")){
				var submit_button = document.getElementById("account_general_gold_active");
	
				SET_BUTTON_CLICK_ACTION("account_general_gold_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_gold_active");
				}, false);	
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_general_platinum_active")){
				var submit_button = document.getElementById("account_general_platinum_active");
				SET_BUTTON_CLICK_ACTION("account_general_platinum_active");
				submit_button.addEventListener("click", function(event){
					CheckAccount_val("account_general_platinum_active");
				}, false);	
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_menu_options1")){
				var submit_button = document.getElementById("account_purchase_menu_options1");
				SET_BUTTON_CLICK_ACTION('account_purchase_menu_options1');
				submit_button.addEventListener("click", function(event){
				}, false);	
			}
			/* --------------------------------------------------------  */
            /* -------------------------------------------------------- */
			if(document.getElementById("account_metrics_options1active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options1active");
			}
			if(document.getElementById("account_metrics_options2active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options2active");
			}
			if(document.getElementById("account_metrics_options3active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options3active");
			}
			if(document.getElementById("account_metrics_options4active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options4active");
			}
			if(document.getElementById("account_metrics_options5active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_options5active");
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            
			/* --------------------------------------------------------  */
			function CheckAccount_val(option_val){
				
				var account_type_val = document.getElementById("account_general_accountType").value;
				if((option_val=='account_general_platinum_active' && account_type_val =='PLATINUM') || 
					(option_val=='account_general_gold_active' && account_type_val =='GOLD') ||
					(option_val=='account_general_silver_active' && account_type_val =='SILVER')){
					account_type_request_function(option_val);
				}
				if((option_val=='account_general_select_redirect')){
					account_type_request_function(option_val);
				}
				option_val=null;
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_summary_options1")){
				SET_BUTTON_CLICK_ACTION("account_summary_options1");
			}
			if(document.getElementById("account_summary_options2")){
				SET_BUTTON_CLICK_ACTION("account_summary_options2");
			}
			if(document.getElementById("account_summary_options3")){
				SET_BUTTON_CLICK_ACTION("account_summary_options3");
			}
			if(document.getElementById("account_summary_options4")){
				SET_BUTTON_CLICK_ACTION("account_summary_options4");
			}
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						//var $SUBMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val()};
						//$ENTITYLISTMgmt[$i] =$SUBMgmt;
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS11),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS12),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
					}	
				}
			}
			//search_bar_function('PROFILE_listview_basics');
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)											  
				});
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count_items")){
				var Object_count = parseInt(document.getElementById("total_objects_count_items").value + 1);
				var $code = $("#index_object_items").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS01ACTIVE = '#form_'+$code+'_'+$i+'_options1active';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						if($($OPTIONS09).val()){
							var $START = moment($($OPTIONS09).val()).utc().local().format('dddd MMMM Do @LT');//	LLLL
						}
						if($($OPTIONS10).val()){
							var $END = moment($($OPTIONS10).val()).utc().local().format('dddd MMMM Do @LT');//dddd@LT
						}
						/* ------------------------------------------------- */
						//var $form_entity = $($INDEX01).val();
						if($($PATHSEQUENCE).val()){
							header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						}
						/* ------------------------------------------------- */
						//$($SEQUENCE).css("background-color",$($COLORSEQUENCE).val());
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						ITEMS_REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS09),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						ITEMS_REGISTER_CLICK($($OPTIONS11),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function ITEMS_REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
                    event.stopPropagation;
					$('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index2').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options4");
				}else{
					$('#settings_field_compliance_edit_index1').prop("disabled",true);
					$('#settings_field_compliance_edit_index2').prop("disabled",true);
					$('#settings_field_compliance_edit_options1').prop("disabled",true);
					$('#settings_field_compliance_edit_options1active').prop("disabled",true);
					$('#settings_field_compliance_edit_options4').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index4').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options2");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options5");
				}else{
					$('#settings_field_compliance_edit_index3').prop("disabled",true);
					$('#settings_field_compliance_edit_index4').prop("disabled",true);
					$('#settings_field_compliance_edit_options2').prop("disabled",true);
					$('#settings_field_compliance_edit_options2active').prop("disabled",true);
					$('#settings_field_compliance_edit_options5').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_field_compliance_edit_options1active")){
				if($('#settings_field_compliance_edit_index6').val()){
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options3");
					//SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options1active"); 
					SET_BUTTON_CLICK_ACTION("settings_field_compliance_edit_options6");
				}else{
					$('#settings_field_compliance_edit_index5').prop("disabled",true);
					$('#settings_field_compliance_edit_index6').prop("disabled",true);
					$('#settings_field_compliance_edit_options3').prop("disabled",true);
					$('#settings_field_compliance_edit_options3active').prop("disabled",true);
					$('#settings_field_compliance_edit_options6').prop("disabled",true);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options3")){
				SET_BUTTON_CLICK_ACTION('settings_general_options3');
			}
			if(document.getElementById("settings_general_options4")){
				SET_BUTTON_CLICK_ACTION('settings_general_options4');
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options10active")){
				if($('#settings_general_phone').html()){
					SET_BUTTON_CLICK_ACTION("settings_general_options10active");
				}else{
					$('#settings_general_options10active').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_general_options11active")){
				if($('#settings_general_email').html()){
					SET_BUTTON_CLICK_ACTION("settings_general_options11active");
				}else{
					$('#settings_general_options11active').prop("disabled",true);
				}
			}
			if(document.getElementById("settings_general_options12active")){
				if($('#settings_general_emailSecondary').html()){
					SET_BUTTON_CLICK_ACTION("settings_general_options12active");
				}else{
					$('#settings_general_options12active').prop("disabled",true);
				}
			}
			/* -------------------------------------------------------- GENERAL_Account_basics */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options1active")){//settings_general_username

				SET_BUTTON_CLICK_ACTION('settings_general_options1active');
				$('#settings_general_options1active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options2active")){//settings_general_firstName
				//var firstname_var = document.getElementById("settings_general_firstName").value;
				SET_BUTTON_CLICK_ACTION('settings_general_options2active');
				$('#settings_general_options2active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options3active")){
				//var lastname_var = document.getElementById("settings_general_lastName").value;
				SET_BUTTON_CLICK_ACTION('settings_general_options3active');
				$('#settings_general_options3active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options6active")){//settings_general_phone
				SET_BUTTON_CLICK_ACTION('settings_general_options6active');
				$('#settings_general_options6active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options4active")){//settings_general_birthday
				SET_BUTTON_CLICK_ACTION('settings_general_options4active');
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_general_options4active'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options5active")){//settings_general_gender
				SET_BUTTON_CLICK_ACTION('settings_general_options5active');
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_general_options5active'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options8active")){//settings_general_email
				SET_BUTTON_CLICK_ACTION('settings_general_options8active');
				$('#settings_general_options8active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options9active")){//settings_general_emailSecondary
				SET_BUTTON_CLICK_ACTION('settings_general_options9active');
				$('#settings_general_options9active').unbind().click(function(event) {
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            //SET_BUTTON_CLICK_ACTION('settings_general_temperature');//settings_general_phone
			if(document.getElementById("settings_general_options7active")){//settings_general_temperature
				SET_BUTTON_CLICK_ACTION('settings_general_options7active');
				$('#settings_general_options7active').unbind().click(function(event) {
				//	$('#standard_entity_modify_control').val($('#settings_general_temperature').attr('id'));
				//	settings_request_function(event);
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_general_options3");
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options2")){
				SET_BUTTON_CLICK_ACTION("settings_general_options2");
				$('#settings_general_options2').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_general_options2').attr('id'));
					settings_request_function(event);
				});
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_general_options1")){
				SET_BUTTON_CLICK_ACTION("settings_general_options1");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function ACTIVATE_OPTION(element_var, orig_value, field_element){
				/*************/
				if(element_var.attr('id') =='settings_general_temperature'){
					element_var.blur(function(e) {
						var temp_check = setInterval(function() { compareChange($("#settings_general_temperatureoptionset"), orig_value, temp_check,field_element);}, 300);
						temp_check;
					})
				}
				/*************/
				if(element_var.attr('id') =='settings_general_gender' || element_var.attr('id') =='settings_general_birthday'){
					element_var.blur(function(e) {
						var time_check = setInterval(function() { compareChange(element_var, orig_value, time_check,field_element);}, 500);
						time_check;
					})
				}
				/*************/
				if(element_var.attr('id') !='settings_general_gender' && element_var.attr('id') !='settings_general_birthday' && element_var.attr('id') !='settings_general_temperature'){
					element_var.bind("keyup blur change", function(e) {
						compareChange(element_var, orig_value, FUNCT=null,field_element); 
					})
				}
				/*************/
				function compareChange(ELEMENT_VAR, ORIGINAL_VALUE, FUNCT, FIELD_ELEMENT)
				{
					var NEW_VALUE = ELEMENT_VAR.val();
					if(ORIGINAL_VALUE != NEW_VALUE){
						document.getElementById("settings_general_options1").className='ls_form_submit_button_active';
					}	
					if(ORIGINAL_VALUE == NEW_VALUE){
						document.getElementById("settings_general_options1").className='ls_form_submit_button';
					}	
					if(FUNCT){
						clearTimeout(FUNCT); 
					} 	
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_activity_type1_activityStatus")){	
				SET_BUTTON_CLICK_ACTION("settings_activity_type1_activityStatus");
				var Settings_Activity_activityStatus_var = document.getElementById("settings_activity_type1_activityStatus");
				var Settings_Activity_activityStatus_label = 'activityStatus';
				var curr_val = document.getElementById("settings_activity_type1_activityStatus").value;
				FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
				$('#settings_activity_type1_activityStatus').unbind().click(function(event) {
					$('#standard_entity_modify_control').val($('#settings_activity_type1_control').val());//attr('id')
					FLIP_SWITCH_SETVAL_STATUS(Settings_Activity_activityStatus_var,Settings_Activity_activityStatus_label,curr_val);
					FLIP_SWITCH_OPTION_STATUS($('#settings_activity_type1_control'),Settings_Activity_activityStatus_label,curr_val,event)
				});
			}
			/* --------------------------------------------------------  */
			//if($("#settings_field_edit_formRecList").val()){
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("sys_return_options1");
                SET_BUTTON_CLICK_ACTION("settings_field_edit_options1");
				if(document.getElementById("settings_field_edit_options1")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options1")
				}
				/* -------------------------------------------------------- */
                $('#settings_field_edit_options1').unbind().click(function() {
                });
                text_editor_word_count(
                    $('#settings_field_edit_formCurrentIndex'),
                    $('#settings_field_edit_formRecList')
                );
                $('#settings_field_edit_formCurrentIndex').keyup(function(event) {
                    var key = String.fromCharCode(event.which);
					/* --------------------------------------------------------  */
                    //var codes = [9,13,32];
                    var codes = [32];
                    document.getElementById("settings_field_edit_formCurrentIndex").addEventListener('keydown', function(e) {
                    if(!contains(codes, e.keyCode)) return;
						var path = e.path || e.composedPath()
                        if(!path[0]) return;

                        e.preventDefault();
                        path[0].value += String.fromCharCode(e.keyCode);//
                        var $STRING=$('#settings_field_edit_formCurrentIndex').val();
                        $('#settings_field_edit_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
                    })
                    function contains(arr, item) {
                        for(var i=0;i<arr.length;i++)
                        if(arr[i]==item)return true;
                        return false;
                    }
                    /* --------------------------------------------------------  */
                    if((event.keyCode == $.ui.keyCode.ENTER /*ENTER*/)){
                        //return false;
                        event.stopPropagation();
                        $('#settings_field_edit_options1').unbind().click(function(event) {
                        });

                    }
                    if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                       (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
                       (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
                    /*
                    (key == 13 /ENTER/)
                    (key == 46 /DELETE/)
                    (key == 8 /BACKSPACE/)
                    */
                        text_editor_word_count(
                            $('#settings_field_edit_formCurrentIndex'),
                            $('#settings_field_edit_formRecList')
                        );
                    }
                });
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options2")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options2");
                    $('#settings_field_edit_options2').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options2').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options2active")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options2active");
                    $('#settings_field_edit_options2active').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options2').attr('id'));
                    });
				}
				/* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options3")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options3");
                    $('#settings_field_edit_options3').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options3').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options3active")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options3active");
                    $('#settings_field_edit_options3active').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options3').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options4")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options4");
                    $('#settings_field_edit_options4').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options4').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options4active")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options4active");
                    $('#settings_field_edit_options4active').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options4').attr('id'));
                    });
				}
				/* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options5")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options5");
                    $('#settings_field_edit_options5').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options5').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options5active")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options5active");
                    $('#settings_field_edit_options5active').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options5').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options6")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options6");
                    $('#settings_field_edit_options6').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options6').attr('id'));
                    });
				}
                /* -------------------------------------------------------- */
				if(document.getElementById("settings_field_edit_options6active")){
					SET_BUTTON_CLICK_ACTION("settings_field_edit_options6active");
                    $('#settings_field_edit_options6active').unbind().click(function() {
                        $('#settings_field_edit_formCurrentIndex').val($('#settings_field_edit_options6').attr('id'));
                    });
				}
            //}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options2")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options2");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options2'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options3")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options3");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options3'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options4")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options4");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options4'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options5")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options5");
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options5'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options2active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options2active");
			}
			if(document.getElementById("account_compliance_options3active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options3active");
			}
			if(document.getElementById("account_compliance_options4active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options4active");
			}
			if(document.getElementById("account_compliance_options5active")){
				SET_BUTTON_CLICK_ACTION("account_compliance_options5active");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_compliance_options16")){
				//SET_BUTTON_CLICK_ACTION("account_compliance_options16");
			}
			if(document.getElementById("account_compliance_options17")){
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options17'),null,null,null,null);
			}
			if(document.getElementById("account_compliance_options18")){
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#account_compliance_options18'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('photo_overlay');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							if($('#standard_entity_modify_control').val()){
                                CONSOLE_MANAGER('GENERAL_Account_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								//var $TEMP_CONTROL = $('#standard_results_ajax_control').val();
								var $RESULTS_CNTRL = null; 
								var $SEARCH_CNTRL = null;
								$RESULTS_CNTRL = $('#standard_entity_modify_control').val();
								if(mutation.type=='attributes'){
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#settings_general_birthday').attr('id')){
										birthday_request_function();
										$('#standard_entity_modify_control').val(null)
									}
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#settings_general_gender').attr('id')){
										gender_ajax();
										$('#standard_entity_modify_control').val(null)
									}
									/* -------------------------------------------------------- */
									if($RESULTS_CNTRL == $('#identity_select_control').val()){
										update_field();
									}
									/* -------------------------------------------------------- */
								}else if(mutation.type=='childList'){
									/* -------------------------------------------------------- */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("form_data_purchase_index_object")){
				var $code = $("#form_data_purchase_index_object").val();
				for(var $i=1; $i<parseInt(4); $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						//var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						//var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						//var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						REGISTER_CLICK1($($OPTIONS01));
						REGISTER_CLICK1($($OPTIONS02));
						REGISTER_CLICK1($($OPTIONS03));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */	
			/* -------------------------------------------------------- */
			function REGISTER_CLICK1($button_obj){
				$button_obj.unbind().click(function(event) {
                 	$('#standard_entity_modify_control').val($('#account_purchase_menu_control').val());
					standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("settings_field_edit_address")){
				$( "#settings_field_edit_address" ).focusin(function() {
					var $autoObj = MGMT_google_autocomplete('settings_field_edit_address');
				});
				if(document.getElementById("settings_field_edit_address")){

					$( "#settings_field_edit_address" ).keyup(function(e) {
						//if(e.keyCode == 32){
						//	$('#settings_field_edit_address').val($('#settings_field_edit_address').val()+' ');
						//}
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById("settings_field_edit_address").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#settings_field_edit_address').val();
							$('#settings_field_edit_address').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
						var $autoObj = MGMT_google_autocomplete('settings_field_edit_address');
						/* --------------------------------------------------------  */
					});
				}
				SET_BUTTON_CLICK_ACTION("settings_field_edit_options1");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('GENERAL_Account_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	SELECTION_Account_basics                    ########################## */
export function SELECTION_Account_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('SELECTION_Account_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			$('.Account_Type_UI_class').attr("id", "Selection_UI_control");
            SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options6'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options7'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options8'),null,null,null,null);
            /* --------------------------------------------------------  */
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options1active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options2active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options3active'),null,null,null,null);
            /* --------------------------------------------------------  */
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options6active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options7active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options8active'),null,null,null,null);
            /* --------------------------------------------------------  */
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options11active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options12active'),null,null,null,null);
            INFORMATIONSYSTEM_LOAD_MGMT($('#account_select_options13active'),null,null,null,null);
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("account_select_options21");
            SET_BUTTON_CLICK_ACTION("account_select_options22");
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */


            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */


            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */


            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var $CYCLE_CONTROL = $('#account_select_index18').val();
            var $DATES_obj_array=[];
            var jsonValidate  = isJSON($('#account_select_index19').val());
            var count=0;
            if(jsonValidate==true){
                $DATES_obj_array = JSON.parse($('#account_select_index19').val());
					if($DATES_obj_array != null){
						if($DATES_obj_array.length){
                            CONSOLE_MANAGER('SELECTION_Account_basics','$DATES_obj_array: ACCOUNT TYPE '+$DATES_obj_array[0]+' ACCOUNT CYCLE: '+$DATES_obj_array[1]+' CYCLE CONTROL: '+$CYCLE_CONTROL);
                            if($DATES_obj_array[0]=='SILVER' && $DATES_obj_array[1] == $CYCLE_CONTROL){
                                $('.select_account_table_td01').addClass('select_account_table_td_active');
                                $('.select_account_table_td02').removeClass('select_account_table_td_active');
                                $('.select_account_table_td03').removeClass('select_account_table_td_active');

                                $('#account_select_options4active').addClass($('#account_select_index12').val());
                                $('#account_select_options4active').removeClass($('#account_select_index13').val());
                                /* --------------------------------------------------------  */
                                $('#account_select_options4active').prop("disabled",true);
                                $('#account_select_options9active').prop("disabled",false);
                                $('#account_select_options14active').prop("disabled",false);
                                /* --------------------------------------------------------  */
                                if($('#account_select_options9active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options9active");
                                }
                                if($('#account_select_options14active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options14active");
                                }
                            }
                            if($DATES_obj_array[0]=='GOLD' && $DATES_obj_array[1] == $CYCLE_CONTROL){
                                $('.select_account_table_td01').removeClass('select_account_table_td_active');
                                $('.select_account_table_td02').addClass('select_account_table_td_active');
                                $('.select_account_table_td03').removeClass('select_account_table_td_active');

                                $('#account_select_options9active').addClass($('#account_select_index12').val());
                                $('#account_select_options9active').removeClass($('#account_select_index13').val());
                                /* --------------------------------------------------------  */
                                $('#account_select_options4active').prop("disabled",false);
                                $('#account_select_options9active').prop("disabled",true);
                                $('#account_select_options14active').prop("disabled",false);
                                /* --------------------------------------------------------  */
                                if($('#account_select_options4active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options4active");
                                }
                                if($('#account_select_options14active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options14active");
                                }
                            }
                            if($DATES_obj_array[0]=='PLATINUM' && $DATES_obj_array[1] == $CYCLE_CONTROL){
                                $('.select_account_table_td01').removeClass('select_account_table_td_active');
                                $('.select_account_table_td02').removeClass('select_account_table_td_active');
                                $('.select_account_table_td03').addClass('select_account_table_td_active');

                                $('#account_select_options14active').addClass($('#account_select_index12').val());
                                $('#account_select_options14active').removeClass($('#account_select_index13').val());
                                /* --------------------------------------------------------  */
                                $('#account_select_options4active').prop("disabled",false);
                                $('#account_select_options9active').prop("disabled",false);
                                $('#account_select_options14active').prop("disabled",true);
                                /* --------------------------------------------------------  */
                                if($('#account_select_options4active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options4active");
                                }
                                if($('#account_select_options9active').is(':visible')){
                                    SET_BUTTON_CLICK_ACTION("account_select_options9active");
                                }
                            }
                    }
                }
            }
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  
			if($('#account_select_options1active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options1active");
			}
			
			if($('#account_select_options2active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options2active");
			}
			
			if($('#account_select_options3active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options3active");
			}
            */
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  
			if($('#account_select_options5active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options5active");
			}
			
			if($('#account_select_options6active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options6active");
			}
			
			if($('#account_select_options7active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options7active");
			}
            
			if($('#account_select_options8active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options8active");
			}
            */
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  
			if($('#account_select_options10active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options10active");
			}
			
			if($('#account_select_options11active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options11active");
			}
			
			if($('#account_select_options12active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options12active");
			}
            
			if($('#account_select_options13active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options13active");
			}
            */
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  
			if($('#account_select_options15active').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_select_options15active");
			}
			
			if($('#account_validation_options1').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_validation_options1");
			}
			
			if($('#account_validation_options2').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_validation_options2");
			}
			
			if($('#account_validation_options3').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_validation_options3");
			}
			
			if($('#account_confirmation_options1').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_confirmation_options1");
			}
			
			if($('#account_confirmation_options2').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_confirmation_options2");
			}
			
			if($('#account_confirmation_options3').is(':visible')){
				SET_BUTTON_CLICK_ACTION("account_confirmation_options3");
			}
            */
			/* -------------------------------------------------------- */	
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('SELECTION_Account_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	HISTORY_Account_basics                      ########################## */
export function HISTORY_Account_basics(){
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('HISTORY_Account_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SETTINGS_PAGINATION_CONTROL();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option1_1")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option1_1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option1_2")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option1_2");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_1")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_2")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_2");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("account_purchase_history_option2_3")){
				SET_BUTTON_CLICK_ACTION("account_purchase_history_option2_3");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("account_summary_options1")){
				SET_BUTTON_CLICK_ACTION("account_summary_options1");
			}
			if(document.getElementById("account_summary_options2")){
				SET_BUTTON_CLICK_ACTION("account_summary_options2");
			}
			if(document.getElementById("account_summary_options3")){
				SET_BUTTON_CLICK_ACTION("account_summary_options3");
			}
			if(document.getElementById("account_summary_options4")){
				SET_BUTTON_CLICK_ACTION("account_summary_options4");
			}
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */	
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('HISTORY_Account_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MAILBOX_listview_basics                     ########################## */
export function MAILBOX_listview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MAILBOX_listview_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#mailbox_menu_options4').is(':visible')) {
				//SET_BUTTON_CLICK_ACTION("mailbox_menu_options4");
				$('#mailbox_menu_options4').unbind().click(function(event) {
					SELECT_ITEM_CHECK();
                });
			}
			if($('#mailbox_menu_options3').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("mailbox_menu_options3");
			}
			/* --------------------------------------------------------  */
			function VERIFY_ACTIVITY(){
				var $DATES_obj_array=[];
				var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
				var count=0;
				if(jsonValidate==true){
					$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
					if($DATES_obj_array != null){
						if($DATES_obj_array.length){
							if($('#mailbox_menu_options1').is(':visible')) {
								SET_BUTTON_CLICK_ACTION("mailbox_menu_options1");
								$('#standard_entity_modify_index1').val($('#mailbox_menu_options1').attr('id'));
								NOTIFICATIONSYSTEM_LOAD_MGMT($('#mailbox_menu_options1'),null,null,null,null);
							}
							if($('#mailbox_menu_options2').is(':visible')) {
								SET_BUTTON_CLICK_ACTION("mailbox_menu_options2");
								$('#mailbox_menu_options2').val($('#mailbox_menu_options2').attr('id'));
								NOTIFICATIONSYSTEM_LOAD_MGMT($('#mailbox_menu_options2'),null,null,null,null);
							}
							$('#mailbox_menu_options1').prop("disabled",false);
							$('#mailbox_menu_options2').prop("disabled",false);
						}else{
							$('#mailbox_menu_options1').prop("disabled",true);
							$('#mailbox_menu_options2').prop("disabled",true);
						}
					}
				}else{
					$('#mailbox_menu_options1').prop("disabled",true);
					$('#mailbox_menu_options2').prop("disabled",true);
                    CONSOLE_MANAGER('MAILBOX_listview_basics','$DATES_obj_array ERROR');
				}
			}
			VERIFY_ACTIVITY();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('MAILBOX_listview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						//var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						//var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						//header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						//REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						var str = $($INDEX02).val();
						if(str.indexOf("_MESSENGER") >= 0){
						   IMAGE_POPULATE($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						}
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						ITEM_CHECK($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						Hourtime_24_function($($OPTIONS08),$i);
					}	
				}
				//$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                 	$('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
					standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			function IMAGE_POPULATE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03){
				var $DATES_obj_array=[];
                var jsonValidate  = isJSON($button_obj.val());
				var count=0;
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($button_obj.val());
                    if($DATES_obj_array != null){
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.getUniqueUserId != "undefined"){
										if((element.getUniqueUserId != 'FALSE') && (element.PICTURE_PATH != null)){
											var d = document.createElement('div');
											$(d).addClass(element.USER_CLASS)
												.attr("id",count)
												.appendTo($button_obj);
											$(d).css('background-image', 'url(' + element.PICTURE_PATH + ')');
										}
                                    }
                                }
								count++;
                            });
                        }
                    }
                }
			}
			/* --------------------------------------------------------  */
			function ITEM_CHECK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
					/* -------------------------------------------------------- */
					//$('#standard_entity_modify_index5').val(json_text);//standard_entity_modify_entitylistMgmt
					var $DATES_obj_array=[];
                    var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                    if(jsonValidate==true){
                        $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                    }
					/* -------------------------------------------------------- */
					/* ---
						CHECK ARRAY FOR VALUE
							-IF NO VALUE, ADD VALUE 		(ADJUST CLASS)
							-IF VALUE, REMOVE FROM ARRAY	(ADJUST CLASS)
					--- */
					var ARRAY_CONTROL = checkValue($INDEX01.val(), $DATES_obj_array);
					if(ARRAY_CONTROL==false){
					   	var data = {};
						var itemname = $INDEX01.val();
						data.Item = itemname;
						$DATES_obj_array.push(data);
						$button_obj.removeClass('results_mailbox_frame_option_7_default');
						$button_obj.addClass('results_mailbox_frame_option_7_active');
						//VERIFY_ACTIVITY();
					}else{						
						$DATES_obj_array.forEach((element, index, array) => {
                            if(element){
                                if(typeof element.Item != "undefined"){
                                    if(element.Item == $INDEX01.val()){
                                        $DATES_obj_array.splice(index, 1);
                                    }
                                }
                            }
                        });
					   	$button_obj.removeClass('results_mailbox_frame_option_7_active');
						$button_obj.addClass('results_mailbox_frame_option_7_default');
						//VERIFY_ACTIVITY();
					}
					/* -------------------------------------------------------- */
					/* -------------------------------------------------------- */
					var sum;
					$.each( $DATES_obj_array, function( index, value ){
						for(var key in value) {
						}
					});
					var json_text = JSON.stringify($DATES_obj_array);
                 	$('#standard_entity_modify_entitylistMgmt').val(json_text);
					VERIFY_ACTIVITY();
                });
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function checkValue(test_value,arr){
			  var status = false;
			  $.each(arr, function( key, value ) {
                  if(test_value == value.Item){
                      status=true;
                  }else{
                  }
              });
			  return status;
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function SELECT_ITEM_CHECK(){
				var $DATES_obj_array=[];
				/* -------------------------------------------------------- */
				if($("#total_objects_count").length){
					var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
					var $code = $("#index_object").val();
					var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
					var count=0;
					if(jsonValidate==true){
						$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
						if($DATES_obj_array != null){
							if($DATES_obj_array.length == parseInt(document.getElementById("total_objects_count").value)&&($('#mailbox_menu_index10').val()==0)){
								for(var $i=1; $i<Object_count; $i++){
                                    if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                                        /* ------------------------------------------------- */
                                        var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                        $($OPTIONS07).removeClass('results_mailbox_frame_option_7_active');
                                        $($OPTIONS07).addClass('results_mailbox_frame_option_7_default');
                                    }
                                }
                                $('#standard_entity_modify_entitylistMgmt').val(null);
                                $('#mailbox_menu_index10').val(0);
								VERIFY_ACTIVITY();
							}else{
								/* ------------------------------------------------- */
								/* ------------------------------------------------- */
								ACTION_ITEM_CHECK();
							}
						}else{
							/* ------------------------------------------------- */
                            /* ------------------------------------------------- */
                            ACTION_ITEM_CHECK();
						}
					}else{
						ACTION_ITEM_CHECK();
					}
					/* ------------------------------------------------- */
					/* ------------------------------------------------- */
					$.each( $DATES_obj_array, function( index, value ){
						for(var key in value) {
						}
					});
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				function ACTION_ITEM_CHECK(){
					/* ---
                        READ IN ALL VALUES (FROM ALL LINES) AND POPULATE ARRAY BASED ON INDEX VALUE
                            -IF VALUE = 0, ADD VALUE 		(ADJUST CLASS)
                            -IF VALUE = 1, REMOVE FROM ARRAY	(ADJUST CLASS)
                    --- */
                    if($('#mailbox_menu_index10').val()==1){
                        for(var $i=1; $i<Object_count; $i++){
                            if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                                /* ------------------------------------------------- */
                                var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                $($OPTIONS07).removeClass('results_mailbox_frame_option_7_active');
                                $($OPTIONS07).addClass('results_mailbox_frame_option_7_default');
                            }
                        }
                        $('#standard_entity_modify_entitylistMgmt').val(null);
                        $('#mailbox_menu_index10').val(0);
						VERIFY_ACTIVITY();
                    }else if($('#mailbox_menu_index10').val()==0){
                        for(var $i=1; $i<Object_count; $i++){
                            if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                                var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                                var data = {};
                                var itemname = $($INDEX01).val();
                                data.Item = itemname;
                                $DATES_obj_array.push(data);
                                $($OPTIONS07).removeClass('results_mailbox_frame_option_7_default');
                                $($OPTIONS07).addClass('results_mailbox_frame_option_7_active');
                            }
                        }
                        var json_text = JSON.stringify($DATES_obj_array);
                        $('#standard_entity_modify_entitylistMgmt').val(json_text);//standard_entity_modify_entitylistMgmt
                        $('#mailbox_menu_index10').val(1);
						VERIFY_ACTIVITY();
                    }
				}
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MAILBOX_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MAILBOX_SELECT_listview_basics              ########################## */
export function MAILBOX_SELECT_listview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MAILBOX_SELECT_listview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			if($('#mailbox_menu_options3').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("mailbox_menu_options3");
			}
			if($('#mailbox_menu_options5').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("mailbox_menu_options5");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('MAILBOX_SELECT_listview_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						/* ------------------------------------------------- */
						//if(($('#mailbox_menu_formFilter1').val()!='MAILVIEW')&& $($OPTIONS05).hasClass("standard_frame_action_right_positive")) {
                        REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						//}
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */  
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                 	$('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* -------------------------------------------------------- */
			
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MAILBOX_SELECT_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	MAILBOX_MAIL_listview_basics              ########################## */
export function MAILBOX_MAIL_listview_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('MAILBOX_MAIL_listview_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			if($('#mailbox_full_options1').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("mailbox_full_options1");
			}
			if($('#mailbox_full_options2').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("mailbox_full_options2");
			}
			/* --------------------------------------------------------  */
			if($('#mailbox_full_formFilter6').val()>2){
				if($('#mailbox_full_options6').is(':visible')) {
					SET_BUTTON_CLICK_ACTION("mailbox_full_options6");
					NOTIFICATIONSYSTEM_LOAD_MGMT($('#mailbox_full_options6'),null,null,null,null);
				}
			}else{
				if($('#mailbox_full_options6').is(':visible')) {
					$('#mailbox_full_options6').prop("disabled",true);
				}
			}
			/* --------------------------------------------------------  */
			if($('#mailbox_full_formFilter7').val()<30){
				if($('#mailbox_full_options7').is(':visible')) {
					SET_BUTTON_CLICK_ACTION("mailbox_full_options7");
				}
			}else{
				if($('#mailbox_full_options7').is(':visible')) {
					$('#mailbox_full_options7').prop("disabled",true);
				}
			}	
			/* --------------------------------------------------------  */
            if($('#mailbox_full_options8').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("mailbox_full_options8");
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#mailbox_full_options8'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
			if($('#mailbox_full_formFilter5').length) {
				header_background_activate($('#mailbox_full_formFilter5'),$('#mailbox_full_options5'));
			}
			/* --------------------------------------------------------  */
			search_bar_function('MAILBOX_MAIL_listview_basics');
			/* --------------------------------------------------------  */
			if(document.getElementById("mailbox_full_messageboxinput")){
				$( "#mailbox_full_messageboxinput" ).keyup(function(e) {	
					/* --------------------------------------------------------  */
					var codes = [32];
					document.getElementById("mailbox_full_messageboxinput").addEventListener('keydown', function(e) {
					if(!contains(codes, e.keyCode)) return;	
						var path = e.path || e.composedPath()
						if(!path[0]) return;

						e.preventDefault();
						path[0].value += String.fromCharCode(e.keyCode);//
						var $STRING=$('#mailbox_full_messageboxinput').val();
						$('#mailbox_full_messageboxinput').val($STRING.replace(/\s{2,}/g, ' '));
					})
					function contains(arr, item) {
						for(var i=0;i<arr.length;i++)
						if(arr[i]==item)return true;
						return false;
					}
					/* --------------------------------------------------------  */
					if (event.keyCode === 13) {
						event.preventDefault();
						if($('#mailbox_full_messageboxinput').val().length>0){
							document.getElementById("mailbox_full_options1").unbind().click();
						}else{
							window_complete_script('MAILBOX_MAIL_listview_basics '); 
						}
					}
				});
			}
			/* --------------------------------------------------------  */
			var textarea = null;
			var result = null;
			var NEW_VAL = null;
			var MAXVAL= $("#mailbox_full_messageboxinput").attr("maxlength");
			document.getElementById("mailbox_full_wordCount").value=MAXVAL;
			if(document.getElementById("mailbox_full_messageboxinput")){
				textarea = document.getElementById("mailbox_full_messageboxinput");
				if(document.getElementById("mailbox_full_wordCount")){
					result   = document.getElementById("mailbox_full_wordCount");
					//result.value = MAXVAL;
					textarea.addEventListener("input", function(){
						var v = wordCount( this.value );
						NEW_VAL = MAXVAL - v.characters;
						result.value = NEW_VAL;
					}, false);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						if($($INDEX05).val()){
							header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						}
						/* ------------------------------------------------- 
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						*/
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */ 
				/* -------------------------------------------------------- */
				function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
					$button_obj.unbind().click(function(event) {
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val($INDEX01.val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
				}
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#rec_objects_count").length){
				var Object_count = parseInt(document.getElementById("rec_objects_count").value + 1);
				var $code = $("#rec_index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						/* ------------------------------------------------- */
						REGISTER_CLICK_REC($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_REC($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_REC($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK_REC($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
					}
				}
				/* -------------------------------------------------------- */ 
				/* -------------------------------------------------------- */
				function REGISTER_CLICK_REC($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
					$button_obj.unbind().click(function(event) {
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val('RECIPIENT');
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
				}
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MAILBOX_MAIL_listview_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	IDENTITY_basics                      ########################## */
export function IDENTITY_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('IDENTITY_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			map_identity_country();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("identity_select_identityCountry")){
				if($('#identity_select_index3').val()){
					var jsonValidate  = isJSON($('#identity_select_index3').val());
					if(jsonValidate==true){
						var $DATES_obj_array = JSON.parse($('#identity_select_index3').val());
						$("identity_select_identityCountry").empty();
                        var str = '';
                        $.each($DATES_obj_array, function(i,v) {
                            str += createCountryElementFromHTML(v,i);
                        });
					}else{
                        CONSOLE_MANAGER('IDENTITY_basics','identity_select_identityCountry: JSON ERROR');
					}
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("identity_select_messageboxinput")){
				if($('#identity_select_index2').val()){
					var jsonValidate  = isJSON($('#identity_select_index2').val());
					if(jsonValidate==true){
						var $DATES_obj_array = JSON.parse($('#identity_select_index2').val());
						$("identity_select_messageboxinput").empty();
                        var str = '';
                        $.each($DATES_obj_array, function(i,v) {
                            str += createKeyElementFromHTML(v,i);
                        });
					}else{
                        CONSOLE_MANAGER('IDENTITY_basics','identity_select_messageboxinput: JSON ERROR');
					}
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
						//var $2SEQUENCE = '#form_'+$code+'_'+$i+'_options2';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						//var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						//var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						REGISTER_CLICK($($SEQUENCE),$i,$code,$($INDEX01));
						if($($SEQUENCE).html()){
							if($('#identity_select_index2').val()){
								var jsonValidate  = isJSON($('#identity_select_index2').val());
								if(jsonValidate==true){
									var $DATES_obj_array = JSON.parse($('#identity_select_index2').val());
									if ($.inArray($($SEQUENCE).html(), $DATES_obj_array)>=0){
										update_class($($SEQUENCE))
									}else{
									}
								}
							}
						}
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function update_class($button_obj){
				var $ACTIVE = 'DISABLE';
				if($button_obj.hasClass("identity_result_label_active")){
					$button_obj.addClass('identity_result_label').removeClass('identity_result_label_active');
				}else{
					$button_obj.addClass('identity_result_label_active').removeClass('identity_result_label');
					$ACTIVE = 'ACTIVE';
				}
				return $ACTIVE;
			}
			/* --------------------------------------------------------  */
			function find_update_key($VALUE){
				var elements = document.querySelectorAll(".identity_result_key_selected");
				for (var i = 0; i < elements.length; i++) {
					var image = elements[i];
					if(image.getAttribute('value') == $VALUE){
						image.remove();
					}
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01){
				$button_obj.unbind().click(function(event) {
					var $html = $button_obj.html();
					//windowload_script('IDENTITY_basics (PROFILE)- REGISTER_CLICK');
					var $STATUS = update_class($button_obj);
					$('#standard_entity_modify_control').val($('#identity_select_control').val());
					$('#standard_entity_modify_formFilter1').val($STATUS);
					$('#standard_entity_modify_formCurrentIndex').val($INDEX01.val());
					settings_request_function(event);
					if($STATUS=='DISABLE'){
						find_update_key($html)
					}
				});
			}		
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */	
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('IDENTITY_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	form_profileimageview_ajax_MGMT   1408      ########################## */
export function form_profileimageview_ajax_MGMT(){
	$(document).ready(function(){
	
		/* 0101010101010101010101010101010101010101010
		IMAGE SWIPE OPTION 1:
			1. ON IMAGE CLICK - LOAD WINDOW POP-UP = DATA_MGMT_WINDOW("SET VARIABLE IN FORM")
			2. LOAD CHILD SWIPE WINDOWS = CHILD_POPULATOR_MGMT();
			3. ACTIVATE SWIPE PROGRAM = $(swipe);
			4. SET INITIAL IMAGE IN SWIPE = SET_WINDOW_MGMT(("CURRENT INDEX - 1","INITIATE")
			DATA_MGMT_SET(index_var);DATA_ACTIVATE_IMAGE()
		1010101010101010101010010110010110101010110101001*/
		DATA_MGMT_WINDOW();
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function CHILD_POPULATOR_MGMT(){
            CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','ACTIVATED');
			/* --------------------------------------------------------  */
			var STAT_H_LNGTH = null;
			
			if(document.getElementById('image_view_formLength').value != null){
				STAT_H_LNGTH = document.getElementById('image_view_formLength').value;
				
				if(STAT_H_LNGTH >= 20){
					STAT_H_LNGTH = 20;
					
				}else if(STAT_H_LNGTH<20 && STAT_H_LNGTH>0){
					//STAT_H_LNGTH = document.getElementById('image_view_formLength').value;
				}
				else{
					STAT_H_LNGTH=1;
				}
			}else{
				STAT_H_LNGTH=1;
			}
			h_style = "style='width:750px; '"; //top:10px; left:4px;
			var toAdd = document.createDocumentFragment();
			for(var i=0; i < STAT_H_LNGTH; i++){
			//	i = index_var;
			   var newDiv = document.createElement('div');
			   newDiv.id = 'CHILD_POPULATOR_MGMT'+i;
			   newDiv.className = 'Page';
			   newDiv.innerHTML = '<table class=""/><tr><td class="pop_window_full_td" id="MGMT_window_control'+i+'"/><div id="horizontal_load_'+i+'" class="loader_default" ></div></td></tr></table>';
			   toAdd.appendChild(newDiv);
			}
			document.getElementById("swiper_child").appendChild(toAdd);
		}
		/* --------------------------------------------------------  */
		function CHILD_DESTROY_MGMT(index_var){
			$( "#MGMT_window_control"+index_var ).empty()
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function SET_WINDOW_MGMT(last_index,index_var, INITIATE, set_index_var){
			var STAT_H_LNGTH = document.getElementById('image_view_formLength').value;//  LENGTH 20;
			//var current_page_MGMT = document.getElementById("pagination_current_page").value;
			var current_page_MGMT = 1;
			//var total_pages_MGMT = document.getElementById("pagination_total_pages").value;
			//var total_objects_count_MGMT = document.getElementById("total_objects_count").value;
			/*	
			/*	HAMMER WINDOW ASSIGNMENT
			/*	SET index_var // CHILD COUNT
			/*	SET set_index_var // OBJECT COUNT
			/*	
			/*	HammerCarousel.prototype (1) | DATA_MGMT_WINDOW (2) | DATA_MGMT_SET (3) | SET_WINDOW_MGMT (4) |
			*	
			*	child_var/i_var -> CONTROLS CHILD WINDOW MOVEMENT
			*	index_var/set_index_var  -> CONTROLS THE IMAGE/MAP OBJECT COUNT
			*	last_index/LAST_INDEX_COUNT  -> CONTROLS THE LAST OBJECT ACTIONED
			*/
			/* --------------------------------------------------------  */
			if((document.getElementById("image_control_formCurrentIndex").value != set_index_var)  && (set_index_var < STAT_H_LNGTH) || 
				(INITIATE == 'INITIATE')){
				
				//alert(INITIATE);
				/* --------------------------------------------------------  */
				if(document.getElementById("image_window_formCurrentIndex")){
					document.getElementById("image_window_formCurrentIndex").value=(set_index_var);
					document.getElementById("image_control_formCurrentIndex").value=(set_index_var);
					document.getElementById("image_view_formCurrentIndex").value=(set_index_var);
					document.getElementById("image_set_formCurrentIndex").value=(set_index_var);
					if(document.getElementById("image_stat_count_statcount")){
						var $_curr_index = numberWithCommas( parseInt(set_index_var+1));
						var $_tot_index = numberWithCommas( parseInt(STAT_H_LNGTH));
						document.getElementById("image_stat_count_statcount").value=$_curr_index +' to '+$_tot_index;
						//set_index_var+' of '.+STAT_H_LNGTH+1
					}
				}
				/* --------------------------------------------------------  */
				if(document.getElementById("image_control_formCurrentIndex").value==set_index_var){
					//alert("set MGMT_window_control: "+set_index_var);
					//DATA_MGMT_SET(index_var,"SET_WINDOW_MGMT");
					/* --------------------------------------------------------  */	
					//var pagination_control_option_MGMT = document.getElementById("pagination_control_option").value;
					var swiper_child_count = $("#swiper_child").children().length;
					/* --------------------------------------------------------  */	
					for(var i=0; i <swiper_child_count ; i++){
						if(index_var==i){
							$('#MGMT_window_control'+i).removeClass('disabled');
							$('#MGMT_window_control'+i).addClass('active');
							$('#horizontal_map_'+i).removeClass('disabled');
							$('#horizontal_map_'+i).addClass('active_map');
							$('#horizontal_image_'+i).removeClass('disabled');
							$('#horizontal_image_'+i).addClass('active_image');
						}
						if(index_var!=i){
							$( "#MGMT_window_control"+i ).empty()
							//$('#MGMT_window_control'+i).html("");
							$('#MGMT_window_control'+i).removeClass('active');
							$('#MGMT_window_control'+i).addClass('disabled');
							//$('#MGMT_window_control'+index_var).clear();
							$('#MGMT_window_control'+i).empty();
							//$('#MGMT_window_control'+index_var).remove();	
							//$('#MGMT_window_control'+index_var).destroy();
							$('#horizontal_map_'+i).addClass('disabled');
							$('#horizontal_map_'+i).removeClass('active_map');
							$('#horizontal_image_'+i).addClass('disabled');
							$('#horizontal_image_'+i).removeClass('active_image');
							
						}
					}
					windowload_script('function:  -  ln:  ');
					DATA_MGMT_SET(set_index_var,"SET_WINDOW_MGMT", index_var);
				}
				/* --------------------------------------------------------  */
			}else{
				/* --------------------------------------------------------  */
                CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','NO WINDOW SHIFT: '+index_var);
			}
			window_complete_script('function:  -  ln:  '); 
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function swipe() {
		  	var reqAnimationFrame = (function () {
				return window[Hammer.prefixed(window, "requestAnimationFrame")] || function (callback) {
			  setTimeout(callback, 1000 / 60);
				}
		  	})();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		  	function dirProp(direction, hProp, vProp) {
				return (direction & Hammer.DIRECTION_HORIZONTAL) ? hProp : vProp
		  	}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		  	function HammerCarousel(container, direction) {
				/*	
				/*	HAMMER WINDOW ASSIGNMENT
				/*	SET index_var // CHILD COUNT
				/*	SET set_index_var // OBJECT COUNT
				/*	
				/*	HammerCarousel.prototype (1) | DATA_MGMT_WINDOW (2) | DATA_MGMT_SET (3) | SET_WINDOW_MGMT (4) |
				*	child_var/i_var -> CONTROLS CHILD WINDOW MOVEMENT
				*	index_var/set_index_var  -> CONTROLS THE IMAGE/MAP OBJECT COUNT
				*	last_index/LAST_INDEX_COUNT  -> CONTROLS THE LAST OBJECT ACTIONED
				*/	
				/* --------------------------------------------------------  */
				var CURRENT_INDEX_SHIFT_MGMT =null;
				/* --------------------------------------------------------  */
				if(document.getElementById("image_view_formCurrentIndex").value != 'DEFAULT'){
					var STAT_H_PLCMENT = (document.getElementById("image_view_formCurrentIndex").value) - 1;//PLACEMENT
				}else{
					var STAT_H_PLCMENT = 0;
				}
				var LAST_INDEX_COUNT = STAT_H_PLCMENT;
				CURRENT_INDEX_SHIFT_MGMT = STAT_H_PLCMENT;
				/* --------------------------------------------------------  */
				this.container = container;
				this.direction = direction;
				this.panes = Array.prototype.slice.call(this.container.children,0);//0
				this.containerSize = this.container[dirProp(direction, 'offsetWidth', 'offsetHeight')];
				this.currentIndex = STAT_H_PLCMENT;//0
				//CURRENT_INDEX_SHIFT_MGMT = this.currentIndex;
				this.hammer = new Hammer.Manager(this.container);
				this.hammer.add(new Hammer.Pan({ direction: this.direction, threshold: 5 })); //10
				this.hammer.on("panstart panmove panend pancancel", Hammer.bindFn(this.onPan, this));
				//this.show(this.currentIndex);
				this.show(CURRENT_INDEX_SHIFT_MGMT);
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				document.getElementById("image_control_formCurrentIndex").value=STAT_H_PLCMENT;
		  	}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		  	HammerCarousel.prototype = {
				show: function (showIndex, percent, animate) {
					/* -------------------------------------------------------- */
					/* --------------------------------------------------------  */
			  	showIndex = Math.max(0, Math.min(showIndex, this.panes.length - 1));
			  	percent = percent || 0;
		
			  	var className = this.container.className;
			  	if (animate) {
					if (className.indexOf('animate') === -1) {
				  	this.container.className += ' animate';
					}
			  	} else {
					if (className.indexOf('animate') !== -1) {
				  	this.container.className = className.replace('animate', '').trim();
					}
			  	}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
			  	var paneIndex, pos, translate;        
			  	for (paneIndex = 0; paneIndex < this.panes.length; paneIndex++) { //
					pos = (this.containerSize / 100) * (((paneIndex - showIndex) * 100) + percent);
					translate = 'translate3d(' + pos + 'px, 0, 0)';
					this.panes[paneIndex].style.transform = translate;
					this.panes[paneIndex].style.mozTransform = translate;
					this.panes[paneIndex].style.webkitTransform = translate;
			  	}
			  	this.currentIndex = showIndex;
				//CURRENT_INDEX_SHIFT_MGMT = this.currentIndex;
				INDEX_SHIFT_SIGN_ORIG = this.currentIndex;
				},
		
				onPan: function (ev) {
			  	var delta = dirProp(this.direction, ev.deltaX, ev.deltaY),
				  	percent = (100 / this.containerSize) * delta,
				  	animate = false;
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
			  	if (ev.type == 'panend' || ev.type == 'pancancel') {
					if (Math.abs(percent) > 20 && ev.type == 'panend') {
				  	this.currentIndex += (percent < 0) ? 1 : -1;
					}
					percent = 0;
					animate = true;
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					//var swiper_child_count = $("#swiper_child").children().length;
					var STAT_H_LNGTH = document.getElementById('image_view_formLength').value;
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					if(document.getElementById("image_control_formCurrentIndex").value!=(this.currentIndex)){
						/* --------------------------------------------------------  */
						if(this.currentIndex < INDEX_SHIFT_SIGN_ORIG){
							INDEX_SHIFT_SIGN = (parseInt(INDEX_SHIFT_SIGN_ORIG - this.currentIndex))*-1;
						}
						else if(this.currentIndex > INDEX_SHIFT_SIGN_ORIG){
							INDEX_SHIFT_SIGN = (parseInt(this.currentIndex)) -INDEX_SHIFT_SIGN_ORIG;
						}
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
						CONTROL_BREAK= null;
						if(INDEX_SHIFT_SIGN >= 1){	
							INDEX_SHIFT_MGMT= parseInt(parseInt(document.getElementById("image_control_formCurrentIndex").value)+1);	
						
							if(INDEX_SHIFT_SIGN_ORIG ==19 && this.currentIndex==19){
								this.currentIndex =0;
								CONTROL_BREAK= null;
							}	
							if(this.currentIndex==20){
								this.currentIndex =0;
								CONTROL_BREAK= null;
							}
							if(INDEX_SHIFT_MGMT ==STAT_H_LNGTH){
								INDEX_SHIFT_MGMT=INDEX_SHIFT_MGMT-1;
								this.currentIndex= this.currentIndex-1;
								CONTROL_BREAK= 'BREAK';
							}
						}
						if(INDEX_SHIFT_SIGN < 0){
							INDEX_SHIFT_MGMT= parseInt(parseInt(document.getElementById("image_control_formCurrentIndex").value)-1);
						
							if(INDEX_SHIFT_SIGN_ORIG ==0 && this.currentIndex==-1){
								this.currentIndex =19;
								CONTROL_BREAK= null;
							}
							if(INDEX_SHIFT_MGMT ==-1 && (this.currentIndex==-1 || this.currentIndex==19)){
								this.currentIndex =0;
								INDEX_SHIFT_MGMT = 0;
								CONTROL_BREAK= 'BREAK';
							}
						}	
						/* --------------------------------------------------------  */
						SET_WINDOW_MGMT(INDEX_SHIFT_SIGN_ORIG,this.currentIndex, CONTROL_BREAK, INDEX_SHIFT_MGMT);
						//SET_WINDOW_MGMT(last_index,index_var, INITIATE, set_index_var)
					}else{
    
					}
			  	}
				/* --------------------------------------------------------  */
				/*	
				/*	HAMMER WINDOW SHIFT MANAGER
				/*	
				/*	SET_WINDOW_MGMT(last_index,index_var, INITIATE, set_index_var)
				/*	this.show(this.currentIndex, percent, animate);
				/*	
				*/
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
			  	this.show(this.currentIndex, percent, animate);
				/* --------------------------------------------------------  */
			  }
		  	};
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		  	var outer = new HammerCarousel(document.querySelector('.Swiper'), Hammer.DIRECTION_ALL);//DIRECTION_HORIZONTAL
		};
		//$(swipe);
		/* --------------------------------------------------------  */
		function DATA_MGMT_WINDOW(){
			var Data = $('.ImageView_ajax_Activity_UI_class').serialize();
			var url = '/entity/window';
			$.post(url,Data,{		
						
			})
			.done(function( data ) {
				if(data.responseCode==200 ){ 
					if(data.confirmation){              
										   
						var pop_var_up ='basic_message_window_stucture';
						basic_window_open(pop_var_up,pop_var_MGMT='null');
										
						$('#basic_status').html(data.response);
						/* --------------------------------------------------------  */
						if(document.getElementById("window_view_close_button")){
							var submit_button = document.getElementById("window_view_close_button");
							SET_BUTTON_CLICK_ACTION("window_view_close_button");
					
							submit_button.addEventListener("click", function(event){
								basic_window_close(close_div_var='basic_message_window_stucture');
								/* --------------------------------------------------------  */
								if(document.getElementById("image_control_formCurrentIndex")){
									document.getElementById("image_control_formCurrentIndex").value=null;
								}
								if(document.getElementById("image_control_formStatOption")){
									document.getElementById("image_control_formStatOption").value=null;
								}
								if(document.getElementById("image_view_formEventEntity")){
									document.getElementById("image_view_formEventEntity").value=null;
								}	
								window_complete_script('function:  -  ln:  '); 
							}, false);
						}
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
						/* MUST CALL ALL 3   --------------------------------------  */
						CHILD_POPULATOR_MGMT();
						$(swipe);
						/*	
						/*	HAMMER WINDOW ASSIGNMENT
						/*	SET index_var // CHILD COUNT
						/*	SET set_index_var // OBJECT COUNT
						/*	
						/*	HammerCarousel.prototype (1) | DATA_MGMT_WINDOW (2) | DATA_MGMT_SET (3) | SET_WINDOW_MGMT (4) |
						*/	
						//var current_page_MGMT = parseInt(document.getElementById("pagination_current_page").value);
						
						
						
						var current_page_MGMT = 0;
						var current_index_MGMT = null;
						var active_index_MGMT = null;
						
						if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_menu'){
							$PROFILE_TYPE = 'PROFILE';
							if(document.getElementById("profile_member_menu_formCurrentIndex").value == 'DEFAULT'){
								current_index_MGMT = 0;
								active_index_MGMT = 0;
								
							}else{
								current_index_MGMT = parseInt(document.getElementById("image_view_formCurrentIndex").value) -1;//
								active_index_MGMT = parseInt(document.getElementById("image_view_formCurrentIndex").value);//
							}
							
						}
						if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_member_menu'){
							$PROFILE_TYPE = 'MEMBER';
							if(document.getElementById("profile_member_member_menu_formCurrentIndex").value == 'DEFAULT'){
								current_index_MGMT = 0;
								active_index_MGMT = 0;
								
							}else{
								current_index_MGMT = parseInt(parseInt(document.getElementById("image_view_formCurrentIndex").value) - parseInt(1));//
								active_index_MGMT = parseInt(document.getElementById("image_view_formCurrentIndex").value);//
								
							}
							
						}
						if(current_page_MGMT > 2){//
							set_index_var = parseInt(current_page_MGMT) - 1 * 20 + parseInt(current_index_MGMT);
							SET_WINDOW_MGMT(null,current_index_MGMT,'INITIATE', set_index_var)
						}
						else if(current_page_MGMT > 1){//
							set_index_var = parseInt((parseInt(current_page_MGMT) *10) + current_index_MGMT);
							SET_WINDOW_MGMT(null,current_index_MGMT,'INITIATE', set_index_var)
						}else{
							SET_WINDOW_MGMT(null,current_index_MGMT,'INITIATE', current_index_MGMT)
						}
					}
				}
				else if(data.responseCode==400){
                    CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.responseCode);
					window_complete_script('function: form_profileimageview_ajax_MGMT');
				}
				else{
                    $('#basic_status').html(data);
					CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.responseCode);
					window_complete_script('function: form_profileimageview_ajax_MGMT');
				}
			})			
			.fail(function( jqXHR, textStatus, errorThrown) {//jqXHR, textStatus, errorThrown
				CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + textStatus + " | " + errorThrown);
				window_complete_script('function: form_profileimageview_ajax_MGMT');
			});	
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function DATA_ACTIVATE_IMAGE(child_var,i_var,index_image,index_window,caller,entity_index){
			if(document.getElementById("image_report_event_id")){
				document.getElementById("image_report_event_id").value=document.getElementById("image_set_formEventEntity").value;
			}
			if(document.getElementById("image_report_file")){
				document.getElementById("image_report_file").value=index_image;
			}
			if((index_window =='IMAGE_VIEW' || index_window=='DEFAULT') && (document.getElementById("image_control_formCurrentIndex").value==entity_index)){
				if(document.getElementById("image_load_formEntityPath")){
					/* --------------------------------------------------------  */
				  $("<img/>").attr("src", index_image).load(function(){
					s = {w:this.width, h:this.height};
					var MGMT_WIDTH = s.w;
					var MGMT_HEIGHT = s.h;
					var FRAME_SET_WIDTH = document.getElementById("MGMT_window_control"+child_var).offsetWidth;//entity_index
					var FRAME_SET_HEIGHT = document.getElementById("MGMT_window_control"+child_var).offsetHeight;//entity_index
					/* --------------------------------------------------------  */
					if(s.w > s.h){
						var OFFSET = FRAME_SET_WIDTH - (FRAME_SET_WIDTH*.23);
						if((s.w > s.h) || (s.w > document.getElementById("MGMT_window_control"+child_var).offsetWidth)){//entity_index
							MGMT_WIDTH = FRAME_SET_WIDTH; 
							
						}
						if((s.w > s.h) || (s.w < document.getElementById("MGMT_window_control"+child_var).offsetWidth)){//entity_index
							MGMT_WIDTH = FRAME_SET_WIDTH; 
							
						}
						//entity_index
						var FLOAT_L= (document.getElementById("MGMT_window_control"+child_var).offsetWidth - MGMT_WIDTH)/2;
						view_style = "style='width:"+MGMT_WIDTH+"; top:7px; left:"+FLOAT_L+"; max-width:"+MGMT_WIDTH+";'";//max-height:"+MGMT_HEIGHT+";
					}
					/* --------------------------------------------------------  */
					if(s.w < s.h){
						var OFFSET = FRAME_SET_HEIGHT - (FRAME_SET_HEIGHT*.23);
						if((s.w < s.h) || (s.h > document.getElementById("MGMT_window_control"+child_var).offsetWidth)){//entity_index
							MGMT_HEIGHT = FRAME_SET_HEIGHT; 
						}
						if((s.w < s.h) || (s.h < document.getElementById("MGMT_window_control"+child_var).offsetWidth)){//entity_index
							MGMT_HEIGHT = FRAME_SET_HEIGHT; 
						}
						//entity_index
						var FLOAT_L= (document.getElementById("MGMT_window_control"+child_var).offsetHeight - MGMT_HEIGHT)/2;
						 view_style = "style='height:"+MGMT_HEIGHT+"; top:7px; top:"+FLOAT_L+" max-height:"+FRAME_SET_HEIGHT+";'";//text-align: center; border:hidden;
					}
					/* --------------------------------------------------------  */
					if(s.w = s.h){
						var FLOAT_L= (document.getElementById("MGMT_window_control"+child_var).offsetWidth - MGMT_WIDTH)/2;
						view_style = "style='width:"+MGMT_WIDTH+"; top:7px; left:"+FLOAT_L+"; max-width:"+MGMT_WIDTH+";'";//max-height:"+MGMT_HEIGHT+";
					}
					/* --------------------------------------------------------  */
					window_complete_script('function:  -  ln:  7611');
					$('#horizontal_image_'+entity_index).html('<div><img class="horizontal_image" src="'+index_image+'" id="full_image" '+view_style+'/></div>');
					BUTTON_NO_DRAG('horizontal_image_'+entity_index);
					SET_MGMT_IMAGE_MENU(entity_index);
					/* --------------------------------------------------------  */	  
				  }); 
				}
			}
			if((index_window =='MAP_VIEW')  && (document.getElementById("image_control_formCurrentIndex").value==entity_index)){
				/* --------------------------------------------------------  */
				window_complete_script('function:  -  ln: 7623 ');
				
				var index_var = document.getElementById("image_set_formCurrentIndex").value;//image_load_formCurrentIndex	image_stat_options_fields_formCurrentIndex
				var event_var = document.getElementById("image_set_formEventEntity").value;//image_load_formEventEntity		image_stat_options_fields_formEventEntity
				var limit_var = document.getElementById("image_set_formArrayList").value;
				/* --------------------------------------------------------  */	
				function SET_COORDS(){
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					function DisLikeProgress (oEvent) {
					  if (oEvent.lengthComputable) {
						var percentComplete = (oEvent.loaded / oEvent.total)*100;
						$('#progress_loader_text').html(Math.round(percentComplete)+'%');
						$('#progress_loader_bar').css({width:Math.round(percentComplete)+'%'});
					  } else {
					  }
					}
					
					function LikeProgress (oEvent) {
					  if (oEvent.lengthComputable) {
						var percentComplete = (oEvent.loaded / oEvent.total)*100;
						$('#progress_loader_text').html(Math.round(percentComplete)+'%');
						$('#progress_loader_bar').css({width:Math.round(percentComplete)+'%'});
					  } else {
					  }
					}
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					if(document.getElementById('profile_member_member_menu_formEntityPath')){
						$VIEW_SERVICE = document.getElementById('profile_member_member_menu_formEntityPath').value
						$UNIQ_PROFILE = document.getElementById('profile_member_member_menu_formMemberEntity').value
					}
					if(document.getElementById('profile_member_menu_formEntityPath')){
						$VIEW_SERVICE = document.getElementById('profile_member_menu_formEntityPath').value
						$UNIQ_PROFILE = document.getElementById('profile_member_menu_formMemberEntity').value
					}
					//if(document.getElementById("image_view_formMemberEntity")){
					//	$UNIQ_PROFILE = document.getElementById('image_view_formMemberEntity').value
					//}	
					
					var $LONGITUDE = Number(document.getElementById("image_stat_options_fields_lon").value);
					var $LATITUDE = Number(document.getElementById("image_stat_options_fields_lat").value);
				
					var iconUser_Feature = new ol.Feature({
						//geometry: new ol.geom.Point(geolocation.getPosition())
						geometry: new ol.geom.Point(ol.proj.transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'))
						//geometry: new ol.geom.Point([$LONGITUDE,$LATITUDE]),
					});
				
					iconUser_Feature.setStyle(
						new ol.style.Style({
							image: new ol.style.Circle({
								radius: 6,
								fill: new ol.style.Fill({
							  		color: '#3399CC'
								}),
								//stroke: new ol.style.Stroke({
								//	color: '#fff',
								//	width: 2
								//}),
								stroke: new ol.style.Stroke({
									color: '#CCC',
									width: 3
								})
								
							})
						})
					);
					
					var vectorUserSource = new ol.source.Vector({
						features: [iconUser_Feature],
						strategy: ol.loadingstrategy.bbox,
						//strategy: ol.loadingstrategy.tile,
						//strategy: ol.loadingstrategy.all,
					});
					  
					var vectorUser = new ol.layer.Vector({
						source: vectorUserSource,
				//		blur: parseInt(15, 12),  // 15
				//		radius: parseInt(12, 12), // 8
						//['#00f', '#0ff', '#0f0', '#ff0', '#f00']
				//		gradient: ['#0f0', '#0f0']  // '#00f'blue, '#0ff'cyan, '#0f0'green, '#ff0'Yellow, '#f00'red     
					 });
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					var random_gen = Math.random(); 
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					var DISLIKE_source = new ol.source.Vector({
					  loader: function(extent, resolution, projection) {
						var url = '/heatmap_ajax/'+$VIEW_SERVICE+'/dislike/'+index_var+'/'+$UNIQ_PROFILE+'/'+random_gen;
						
						
						//var oReq = new XMLHttpRequest();
						window_start_load();
						XHR_Req_DISLIKE.addEventListener("progress", DisLikeProgress);
						XHR_Req_DISLIKE.addEventListener("load", transferComplete);
						XHR_Req_DISLIKE.addEventListener("error", transferFailed);
						XHR_Req_DISLIKE.addEventListener("abort", transferCanceled);
						XHR_Req_DISLIKE.onreadystatechange = function()
						{
							if (XHR_Req_DISLIKE.readyState == 4 && XHR_Req_DISLIKE.status == 200)
							{
								var format = new ol.format.KML({
									extractStyles: false 
								});
							  	var features = format.readFeatures(XHR_Req_DISLIKE.responseText,
								 	{featureProjection: projection});
							 	 DISLIKE_source.addFeatures(features);
							}
						}
						XHR_Req_DISLIKE.open("POST", url, true);
						XHR_Req_DISLIKE.send();
					  },
					  strategy: ol.loadingstrategy.bbox
					  //strategy: ol.loadingstrategy.all,
					});
					
					var vector_dislike = new ol.layer.Heatmap({
						source: DISLIKE_source,
					 	blur: parseInt(6), 
						radius: parseInt(3),
						//blur: parseInt(13, 7),  // 15
						//radius: parseInt(6, 7), // 8
						//['#00f', '#0ff', '#0f0', '#ff0', '#f00']e5e5ff
						gradient: ['#00f', '#7f7fbb', '#0f0', '#cccce3', '#800080']  // '#00f'blue, '#0ff'cyan, '#0f0'green, '#ff0'Yellow, '#f00'red
						   
					});
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					var LIKE_source = new ol.source.Vector({
					  loader: function(extent, resolution, projection) {
						var url = '/heatmap_ajax/'+$VIEW_SERVICE+'/like/'+index_var+'/'+$UNIQ_PROFILE+'/'+random_gen;
						window_start_load();
						XHR_Req_LIKE.addEventListener("progress", LikeProgress);
						XHR_Req_LIKE.addEventListener("load", transferComplete);
						XHR_Req_LIKE.addEventListener("error", transferFailed);
						XHR_Req_LIKE.addEventListener("abort", transferCanceled);
						XHR_Req_LIKE.onreadystatechange = function()
						{
							if (XHR_Req_LIKE.readyState == 4 && XHR_Req_LIKE.status == 200)
							{
								var format = new ol.format.KML({
									extractStyles: false 
								});
							  	var features = format.readFeatures(XHR_Req_LIKE.responseText,
								 	{featureProjection: projection});
							 	 LIKE_source.addFeatures(features);
							}
						}
						XHR_Req_LIKE.open("POST", url, true);
						XHR_Req_LIKE.send();
					  },
					  strategy: ol.loadingstrategy.bbox
					  //strategy: ol.loadingstrategy.all,
					});
					var vector_like = new ol.layer.Heatmap({
					 	source: LIKE_source,
					 	blur: parseInt(6), 
						radius: parseInt(3),
						opacity: parseInt(1),
						//blur: parseInt(13, 7),  // 15 10
						//radius: parseInt(6, 7), // 8 10
						//['#00f', '#0ff', '#0f0', '#ff0', '#f00']
						//gradient: ['#00f', '#CCF']  // '#00f'blue, '#0ff'cyan, '#0f0'green, '#ff0'Yellow, '#f00'red     
					});
					/* --------------------------------------------------------  */
					
					
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  	
					  var earthquakeFill = new ol.style.Fill({
						color: 'rgba(255, 153, 0, 0.8)'
					  });
					  var earthquakeStroke = new ol.style.Stroke({
						color: 'rgba(255, 204, 0, 0.2)',
						width: 1
					  });
					  var textFill = new ol.style.Fill({
						color: '#fff'
					  });
					  var textStroke = new ol.style.Stroke({
						color: 'rgba(0, 0, 0, 0.6)',
						width: 3
					  });
					  var invisibleFill = new ol.style.Fill({
						color: 'rgba(255, 255, 255, 0.01)'
					  });
				
					  function createEarthquakeStyle(feature) {
						// 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
						// standards-violating <magnitude> tag in each Placemark.  We extract it
						// from the Placemark's name instead.
						var name = feature.get('name');
						var magnitude = parseFloat(name.substr(2));
						var radius = 5 + 20 * (magnitude - 5);
				
						return new ol.style.Style({
						  geometry: feature.getGeometry(),
						  image: new ol.style.RegularShape({
							radius1: radius,
							radius2: 3,
							points: 5,
							angle: Math.PI,
							fill: earthquakeFill,
							stroke: earthquakeStroke
						  })
						});
					  }
				
					  var maxFeatureCount, vector_like_cluster;
					  function calculateClusterInfo(resolution) {
						maxFeatureCount = 0;
						var features = vector_like_cluster.getSource().getFeatures();
						var feature, radius;
						for (var i = features.length - 1; i >= 0; --i) {
						  feature = features[i];
						  var originalFeatures = feature.get('features');
						  var extent = ol.extent.createEmpty();
						  var j, jj;
						  for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
							ol.extent.extend(extent, originalFeatures[j].getGeometry().getExtent());
						  }
						  maxFeatureCount = Math.max(maxFeatureCount, jj);
						  radius = 0.25 * (ol.extent.getWidth(extent) + ol.extent.getHeight(extent)) /
							  resolution;
						  feature.set('radius', radius);
						}
					  }
				
					  var currentResolution;
					  function styleFunction(feature, resolution) {
						if (resolution != currentResolution) {
						  calculateClusterInfo(resolution);
						  currentResolution = resolution;
						}
						var style;
						var size = feature.get('features').length;
						if (size > 1) {
						  style = new ol.style.Style({
							image: new ol.style.Circle({
							  radius: feature.get('radius'),
							  fill: new ol.style.Fill({
								color: [255, 153, 0, Math.min(0.8, 0.4 + (size / maxFeatureCount))]
							  })
							}),
							text: new ol.style.Text({
							  text: size.toString(),
							  fill: textFill,
							  stroke: textStroke
							})
						  });
						} else {
						  var originalFeature = feature.get('features')[0];
						  style = createEarthquakeStyle(originalFeature);
						}
						return style;
					  }
				
					  function selectStyleFunction(feature) {
						var styles = [new ol.style.Style({
						  image: new ol.style.Circle({
							radius: feature.get('radius'),
							fill: invisibleFill
						  })
						})];
						var originalFeatures = feature.get('features');
						var originalFeature;
						for (var i = originalFeatures.length - 1; i >= 0; --i) {
						  originalFeature = originalFeatures[i];
						  styles.push(createEarthquakeStyle(originalFeature));
						}
						return styles;
					  }

					 vector_like_cluster = new ol.layer.Vector({
						source: new ol.source.Cluster({
						  distance: 40,
						  source: LIKE_source,
						}),
						style: styleFunction
					  });
					  */
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  */	
					/* --------------------------------------------------------  image_stat_count_statcount */	
					/* -------------------------------------------------------- vectorUser vector_dislike vector_like vector_like_cluster */	
					var vectorGroup = new ol.layer.Group({
						layers: [vectorUser,vector_like,vector_dislike],
						name: 'vector Group'
					});
					/* --------------------------------------------------------  */
					var raster = new ol.layer.Tile({
						source: new ol.source.Stamen({
							layer: 'toner-lite',
							cacheSize: 256,
						}),
						minResolution: 12,
						maxResolution: 50000
					});
					/*
						"toner":        MAKE_PROVIDER("toner", "png", 0, 20),
						"terrain":      MAKE_PROVIDER("terrain", "jpg", 4, 18),
						"watercolor":   MAKE_PROVIDER("watercolor", "jpg", 3, 16)
						http://maps.stamen.com/#watercolor/4/37.93/-91.63
					*/
					/* --------------------------------------------------------  */	
					var locate_center_Control = function (e) {
						var center = [$LONGITUDE,$LATITUDE];
						map.getView().setCenter(ol.proj.transform(center, 'EPSG:4326', 'EPSG:3857'));
					};
					var north_motion_Control = function (e) {
						
						var ZoomLevel = map.getView().getZoom();
						if (ZoomLevel >= 8) {
							MOTION_MGMT = Number(.1);
						}
						else if (ZoomLevel >= 6) {
							MOTION_MGMT = parseInt(1);
						}else{
							MOTION_MGMT = parseInt(5);
						}
						lonlat = ol.proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
						var lon = lonlat[0];
					 	var lat = lonlat[1];
						var NEW_LAT = lat+MOTION_MGMT;
						var center = [lon,NEW_LAT];
						
						map.getView().setCenter(ol.proj.transform(center, 'EPSG:4326', 'EPSG:3857'));
					};
					var south_motion_Control = function (e) {
						var ZoomLevel = map.getView().getZoom();
						if (ZoomLevel >= 8) {
							MOTION_MGMT = Number(.1);
						}
						else if(ZoomLevel >= 6) {
							MOTION_MGMT = parseInt(1);
						}else{
							MOTION_MGMT = parseInt(5);
						}
						lonlat = ol.proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
						var lon = lonlat[0];
					 	var lat = lonlat[1];
						var NEW_LAT = lat-MOTION_MGMT;
						var center = [lon,NEW_LAT];
						
						map.getView().setCenter(ol.proj.transform(center, 'EPSG:4326', 'EPSG:3857'));
					};
					var east_motion_Control = function (e) {
						var ZoomLevel = map.getView().getZoom();
						if (ZoomLevel >= 8) {
							MOTION_MGMT = Number(.1);
						}
						else if (ZoomLevel >= 6) {
							MOTION_MGMT = parseInt(1);
						}else{
							MOTION_MGMT = parseInt(5);
						}
						lonlat = ol.proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
						var lon = lonlat[0];
					 	var lat = lonlat[1];
						var NEW_LON = lon+MOTION_MGMT;
						var center = [NEW_LON,lat];
						
						map.getView().setCenter(ol.proj.transform(center, 'EPSG:4326', 'EPSG:3857'));
					};
					var west_motion_Control = function (e) {
						var ZoomLevel = map.getView().getZoom();
						if (ZoomLevel >= 8) {
							MOTION_MGMT = Number(.1);
						}
						else if (ZoomLevel >= 6) {
							MOTION_MGMT = parseInt(1);
						}else{
							MOTION_MGMT = parseInt(5);
						}
						lonlat = ol.proj.transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
						var lon = lonlat[0];
					 	var lat = lonlat[1];
						var NEW_LON = lon-MOTION_MGMT;
						var center = [NEW_LON,lat];
						
						map.getView().setCenter(ol.proj.transform(center, 'EPSG:4326', 'EPSG:3857'));
					};
					
					/* --------------------------------------------------------  */	
					$('#image_map_options_menu_map_north').unbind().click(function() {
						north_motion_Control();
					});
					$('#image_map_options_menu_map_south').unbind().click(function() {
						south_motion_Control();
					});
					$('#image_map_options_menu_map_east').unbind().click(function() {
						east_motion_Control();
					});
					$('#image_map_options_menu_map_west').unbind().click(function() {
						west_motion_Control();
					});
					$('#image_map_options_menu_locate').unbind().click(function() {
						locate_center_Control();
					});
					/* --------------------------------------------------------  */
					var vector_userSource = vectorUser.getSource();
					var userlistenerKey = vector_userSource.on('change', function(e) {
					  if (vector_userSource.getState() == 'ready') {
						ol.Observable.unByKey(userlistenerKey);
					  }
					});
					/* --------------------------------------------------------  */
					var vector_likeSource = vector_like.getSource();
					var likelistenerKey = vector_likeSource.on('change', function(e) {
					  if (vector_dislikeSource.getState() == 'ready') {
						var featureCount = vector_likeSource.getFeatures().length;
						$('.map_key_control_td_likes_key').html(featureCount);
						ol.Observable.unByKey(likelistenerKey);
					  }
					});
					/* --------------------------------------------------------  */
					var vector_dislikeSource = vector_dislike.getSource();
					var dislikelistenerKey = vector_dislikeSource.on('change', function(e) {
					  if (vector_dislikeSource.getState() == 'ready') {
						var featureCount = vector_dislikeSource.getFeatures().length;
						$('.map_key_control_td_dislikes_key').html(featureCount);
						ol.Observable.unByKey(dislikelistenerKey);
					  }
					});
					/* --------------------------------------------------------  */
					vectorGroup.on("change", function(evt) {
						/* --------------------------------------------------------  */
						var extent = ol.extent.createEmpty();
						vectorGroup.getLayers().forEach(function(layer) {
						  	ol.extent.extend(extent, layer.getSource().getExtent());
							//var layerSource = layer.getSource();
							var layerlistenerKey = layer.getSource().on("change", function(evt) {
								
								var source = evt.target;
								if (source.getState() === 'undefined') {
								}
								else if (source.getState() === 'loading') {
								}
								else if (source.getState() === 'ready') {
									ol.Observable.unByKey(layerlistenerKey);
									/* --------------------------------------------------------  */
									window_complete_load();
								}
								else if (source.getState() === 'error') {
								}
							});
						});
						/* --------------------------------------------------------  */
						/**	ZOOM AND PAN ACTION	***/	//duration: 1500,duration: 1500,
						pan = ol.animation.pan({duration: 100, source: map.getView().getCenter()})
						zoom = ol.animation.zoom({duration: 100, resolution: map.getView().getResolution()})
						map.beforeRender(pan, zoom) 
						map.getView().fit(extent, map.getSize());
					});
					/* --------------------------------------------------------  */	
					var map = new ol.Map({
						layers: [raster,vectorGroup], //waterLayer	,vectorLayervector,vector_dislike,,vector_out
						controls: [],
						controls: ol.control.defaults({
							zoom: false,
							attribution: false,
							rotate: false
						}).extend([
							new ol.control.ZoomSlider(),
							//new ol.control.Zoom(),
							//new ol.control.Rotate(),
							//new ol.control.OverviewMap(),
							//new ol.control.ScaleLine(),
							//new ol.control.FullScreen(),
							//new ol.control.MousePosition({
							//	coordinateFormat: ol.coordinate.createStringXY(4),
							//	projection: 'EPSG:4326'
							//})
						]),
						
						interactions: ol.interaction.defaults({
						altShiftDragRotate: false,
						doubleClickZoom: false,
						keyboard: false,
						mouseWheelZoom: false,
						shiftDragZoom: false,
						dragPan: false,
						pinchRotate: false,
						pinchZoom: false
					}),
					target: 'horizontal_map_'+entity_index,
						view: new ol.View({
							center: [$LONGITUDE,$LATITUDE],
							zoom: 2,
							maxZoom: 12, //12
							minZoom: 2,
						})
					});
				/* --------------------------------------------------------  */	
				/* --------------------------------------------------------  */	
				/* --------------------------------------------------------  */	
				}
				SET_COORDS();
			}
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function DATA_MGMT_SET(i_var, caller, child_var){
			if((document.getElementById("image_control_formCurrentIndex").value==i_var)){
				windowload_script('function:  -  ln:  ');
				if(document.getElementById("image_set_formEntity")){
					document.getElementById("image_set_formEntity").value=null;
					//document.getElementById("image_view_formEntity").value
				}
				if(document.getElementById("image_set_formEventEntity")){
					document.getElementById("image_set_formEventEntity").value=null;
					//document.getElementById("image_view_formEventEntity").value
				}
				if(document.getElementById("image_set_formEntityPath")){
					document.getElementById("image_set_formEntityPath").value=window.location.pathname;
				}
				if(document.getElementById("image_set_formArrayList")){
					document.getElementById("image_set_formArrayList").value=document.getElementById("image_view_formArrayList").value
				}
				if(document.getElementById("image_set_formLength")){
					document.getElementById("image_set_formLength").value=document.getElementById("image_view_formLength").value
				}
				var Data = $('.Set_Imagecontrol_Activity_UI_class').serialize();
				var url = null;
				if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_menu'){
					url = '/entity/profile_imageset';
				}
				if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_member_menu'){
				 	url = '/entity/member_imageset';
				}
				$.post(url,Data,{		

				})
				.done(function( data ) {
					if(data.responseCode==200 ){ 
						if(data.response){              
							/*	*/	
							var index_var = null;
							//alert("profile: "+data.UniqEntity);
							if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){  
								/* --------------------------------------------------------  */
								var swiper_child_count = $("#swiper_child").children().length;
								/*	
								/*	HAMMER WINDOW ASSIGNMENT
								/*	SET index_var // CHILD COUNT
								/*	SET set_index_var // OBJECT COUNT
								/*	
								/*	HammerCarousel.prototype (1) | DATA_MGMT_WINDOW (2) | DATA_MGMT_SET (3) | SET_WINDOW_MGMT (4) |
								*	
								*	child_var/i_var -> CONTROLS CHILD WINDOW MOVEMENT
								*	index_var/set_index_var  -> CONTROLS THE IMAGE/MAP OBJECT COUNT
								*	last_index/LAST_INDEX_COUNT  -> CONTROLS THE LAST OBJECT ACTIONED
								*/	
								index_var = i_var; 
								if((i_var>19) && (swiper_child_count <= 20)){//STAT_H_LNGTH
									
									/* --------------------------------------------------------  */
									/* -------------------------------------------------------- 10 */
									var LAST_INDEX_COUNT = (Math.abs(i_var) % 10);
									index_var = LAST_INDEX_COUNT;
								}
								
								$('#MGMT_window_control'+child_var).html(data.response);
								//SET_MGMT_IMAGE_MENU(data.entity_index);//index_var
								/* --------------------------------------------------------  */
								if(document.getElementById("image_stat_options_fields_formEntity")){
									document.getElementById("image_stat_options_fields_formEntity").value=data.FormEntity;
								}
								if(document.getElementById("image_stat_options_fields_formEventEntity")){
									document.getElementById("image_stat_options_fields_formEventEntity").value=data.FormEventEntity;
								}
								if(document.getElementById("image_stat_options_fields_formCurrentIndex")){
									document.getElementById("image_stat_options_fields_formCurrentIndex").value=data.FormCurrentIndex;
								}//index_var
								/* --------------------------------------------------------  */
								if(document.getElementById("image_set_formEventEntity")){
									document.getElementById("image_set_formEventEntity").value=data.FormEventEntity;
								}
								if(document.getElementById("image_set_formEntityPath")){
									document.getElementById("image_set_formEntityPath").value=data.FormEntityPath;
								}
								if(document.getElementById("image_set_formArrayList")){
									document.getElementById("image_set_formArrayList").value=data.FormArrayList;
								}
								if(document.getElementById("image_set_formCurrentIndex")){
									document.getElementById("image_set_formCurrentIndex").value=data.FormCurrentIndex;
								}
								if(document.getElementById("image_report_uniqMemId_Subject")){
									document.getElementById("image_report_uniqMemId_Subject").value=data.FormEventEntity;
								}
								if(document.getElementById("image_report_path")){
									document.getElementById("image_report_path").value=data.FormEntityPath;
								}
								
								/* --------------------------------------------------------  */
                                if(document.getElementById("image_window_formEntity")){
                                    document.getElementById("image_window_formEntity").value=document.getElementById("image_load_formEntity").value;
                                }
                                if(document.getElementById("image_window_formEntityPath")){
                                    document.getElementById("image_window_formEntityPath").value=document.getElementById("image_load_formEntityPath").value;
                                }
                                if(document.getElementById("image_window_formArrayList")){
                                    document.getElementById("image_window_formArrayList").value=document.getElementById("image_load_formArrayList").value;
                                }
                                if(document.getElementById("image_window_formCurrentIndex")){
                                    document.getElementById("image_window_formCurrentIndex").value=document.getElementById("image_load_formCurrentIndex").value;
                                }
                                /* --------------------------------------------------------  */
                                var options = {
                                  enableHighAccuracy: true,
                                  timeout: 120000,
                                  maximumAge: 0
                                };

                                function success(pos) {
                                    var crd = pos.coords;
                                    if(document.getElementById("image_stat_options_fields_lon")){
                                        if( !$('#image_stat_options_fields_lon').val() ) {
                                            document.getElementById("image_stat_options_fields_lon").value=crd.longitude;
                                        }
                                    }
                                    if(document.getElementById("image_stat_options_fields_lat")){
                                        if( !$('#image_stat_options_fields_lat').val() ) {
                                            document.getElementById("image_stat_options_fields_lat").value=crd.latitude;
                                        }
                                    }
                                };

                                function error(err) {
                                  console.warn('ERROR(' + err.code + '): ' + err.message);
                                  switch(err.code) {
                                        case err.PERMISSION_DENIED:
                                            break;
                                        case err.POSITION_UNAVAILABLE:
                                            break;
                                        case err.TIMEOUT:
                                            break;
                                        case err.UNKNOWN_ERROR:
                                            break;
                                    }
                                };
                                navigator.geolocation.getCurrentPosition(success, error, options);				
                                /* -------------------------------------------------------- */
                                /* -------------------------------------------------------- */
                                var geolocate = new ol.Geolocation({
                                    //projection: view.getProjection(),
                                    tracking: true,
                                    geolocationOptions: {
                                        enableHighAccuracy: false,
                                        maximumAge: 0,
                                        timeout: 7000
                                    }
                                });
                                geolocate.once('change', function(evt) {

                                });
							     /* --------------------------------------------------------  */
							}
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							if(document.getElementById("image_window_formEntity")){
								document.getElementById("image_window_formEntity").value=data.FormCurrentIndex;
							}
							if(document.getElementById("image_window_formEventEntity")){
								document.getElementById("image_window_formEventEntity").value=data.FormCurrentIndex;
							}
							if($('#MGMT_window_control'+child_var).is(':visible') && 
								$('#image_window_selection_view').is(':visible')  && (i_var == data.entity_index)) {
								var CURRENT_VIEW = '';
								var $IS_SET_VAR = 'UNSELECTED';
								/* --------------------------------------------------------  */
								if($("#image_window_selection_view").hasClass("File_Modify_option_default") && document.getElementById("image_window_formImage").value == 'MAP_VIEW') {
									$("#image_window_selection_view").addClass('File_Modify_option_image').removeClass('File_Modify_option_default');
									//data.window_index;	
									CURRENT_VIEW = 'MAP_VIEW'
									$IS_SET_VAR = 'DEFAULT';
									DATA_ACTIVATE_IMAGE(child_var,index_var,data.current_index,CURRENT_VIEW,"DATA_MGMT_SET",data.entity_index);
								}
								/* --------------------------------------------------------  */
								if($("#image_window_selection_view").hasClass("File_Modify_option_default") && (document.getElementById("image_window_formImage").value == 'IMAGE_VIEW' ||
									document.getElementById("image_window_formImage").value == 'DEFAULT'
								)){
									$("#image_window_selection_view").addClass('File_Modify_option_map').removeClass('File_Modify_option_default');	
									CURRENT_VIEW = 'IMAGE_VIEW'
									$IS_SET_VAR = 'DEFAULT';
									
									DATA_ACTIVATE_IMAGE(child_var,index_var,data.current_index,CURRENT_VIEW,"DATA_MGMT_SET",data.entity_index);
								}
								/* --------------------------------------------------------  */
								$('.File_Modify_option_image').unbind().click(function() {
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										if($('#image_window_selection_view').hasClass( "File_Modify_option_image" ) && 
										(document.getElementById("image_window_formImage").value == 'MAP_VIEW')) {
											$IS_SET_VAR = update_structure_view_switch_image();
										}
										else if($('#image_window_selection_view').hasClass( "File_Modify_option_map" ) && 
										(document.getElementById("image_window_formImage").value == 'IMAGE_VIEW') || 
										(document.getElementById("image_window_formImage").value == 'DEFAULT')) {
											$IS_SET_VAR = update_structure_view_switch_map();
										}
									}
								});
								/* --------------------------------------------------------  */
								$('.File_Modify_option_map').unbind().click(function() {
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										if($('#image_window_selection_view').hasClass( "File_Modify_option_map" )  && 
										(document.getElementById("image_window_formImage").value == 'IMAGE_VIEW') || 
										(document.getElementById("image_window_formImage").value == 'DEFAULT')) {
											$IS_SET_VAR = update_structure_view_switch_map();
										}
										else if($('#image_window_selection_view').hasClass( "File_Modify_option_image" ) && 
										(document.getElementById("image_window_formImage").value == 'MAP_VIEW')) {
											$IS_SET_VAR = update_structure_view_switch_map2();
										}
									}
								});	
								/* --------------------------------------------------------  */
								/* --------------------------------------------------------  */
								function change_input_value(input_val){
									if(document.getElementById("image_control_formCurrentIndex").value==data.entity_index){//i_var
										$("#image_window_formImage").val(input_val);
										CHILD_DESTROY_MGMT(index_var);
										DATA_MGMT_WINDOW_STRUCTURE(child_var,index_var,i_var);
									}
								}
								/* --------------------------------------------------------  */
								function update_structure_view_switch_image(){
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										change_input_value('IMAGE_VIEW');	
										$("#image_window_selection_view").addClass('File_Modify_option_map').removeClass('File_Modify_option_image');
										return 'IMAGE_VIEW';
									}
								}
								/* --------------------------------------------------------  */
								function update_structure_view_switch_image2(){
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										change_input_value('MAP_VIEW');	
										$("#image_window_selection_view").addClass('File_Modify_option_image').removeClass('File_Modify_option_map');
										return 'MAP_VIEW';
									}
								}
								/* --------------------------------------------------------  */
								function update_structure_view_switch_map(){
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										change_input_value('MAP_VIEW');
										$("#image_window_selection_view").addClass('File_Modify_option_image').removeClass('File_Modify_option_map');
										return 'MAP_VIEW';
									}
								}
								/* --------------------------------------------------------  */
								function update_structure_view_switch_map2(){
									if((document.getElementById("image_control_formCurrentIndex").value==data.entity_index)){
										change_input_value('IMAGE_VIEW');
										$("#image_window_selection_view").addClass('File_Modify_option_map').removeClass('File_Modify_option_image');
										return 'IMAGE_VIEW';
									}
								}
								if($IS_SET_VAR == 'UNSELECTED'){
									DATA_ACTIVATE_IMAGE(child_var,index_var,data.current_index,document.getElementById("image_window_formImage").value,"DATA_MGMT_SET LOOP",data.entity_index);
								}
							}
						}
						if(data.responseCode==400){
                            CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
							window_complete_script('function: form_profileimageview_ajax_MGMT');
						}
					}
					else if(data.responseCode==400){
						$('#MGMT_window_control'+child_var).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
					else if(data.responseCode=='EXIT'){
						$('#MGMT_window_control'+child_var).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
					else{
						$('#MGMT_window_control'+child_var).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
				})
				.fail(function( jqXHR, textStatus, errorThrown) {//jqXHR, textStatus, errorThrown
					CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + textStatus + " | " + errorThrown);
				    window_complete_script('function: form_profileimageview_ajax_MGMT');
				});	
			}
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function DATA_MGMT_WINDOW_STRUCTURE(i_var, caller,i_var_set){
			
			if(document.getElementById("image_control_formCurrentIndex").value==i_var_set){
				windowload_script('function: form_profileimageview_ajax_MGMT');				
				var Data = $('.Window_Imagecontrol_Activity_UI_class').serialize();
				var url = null;
				
				if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_menu'){
					url = '/entity/profile_imageset';
				}
				if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_member_menu'){
				 	url = '/entity/member_imageset';
				}
				$.post(url,Data,{		
				})
				.done(function( data ) {
					if(data.responseCode==200 ){ 
						if(data.response){
							/* -------------------------------------------------------- */
							if($('#MGMT_window_control'+i_var).hasClass('active') && (i_var_set == data.entity_index)){
								/* --------------------------------------------------------  */
								var swiper_child_count = $("#swiper_child").children().length;
								
								index_var = i_var; 
								if((i_var>19) && (swiper_child_count <= 20)){//STAT_H_LNGTH
								
									var INDEX_MULTIPLIER = Math.floor(parseInt(i_var / 10));
									var LAST_INDEX_COUNT = (Math.abs(i_var) % 10);
									
									INDEX_MULTIPLIER = (INDEX_MULTIPLIER*10) +LAST_INDEX_COUNT;
									index_var = INDEX_MULTIPLIER;
								}
								$('#MGMT_window_control'+index_var).html(data.response);
								DATA_ACTIVATE_IMAGE(i_var,index_var,data.current_index,data.window_index,"DATA_MGMT_WINDOW_STRUCTURE",data.entity_index);
								return false;
							}
							else{
                                CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.entity_index+'  ivar: '+i_var+" caller: "+caller);
								window_complete_script('function: form_profileimageview_ajax_MGMT');
							}
							/* -------------------------------------------------------- */
						}
						if(data.responseCode==400){
                            CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				            window_complete_script('function: form_profileimageview_ajax_MGMT');
						}
					}
					else if(data.responseCode==400){
						$('#MGMT_window_control'+entity_index).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT'); 
					}
					else if(data.responseCode=='EXIT'){
						$('#MGMT_window_control'+entity_index).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
					else{
						$('#MGMT_window_control'+entity_index).html(data.response);
						CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				        window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
				})			
				.fail(function( jqXHR, textStatus, errorThrown) {
                    CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + textStatus + " | " + errorThrown);
				    window_complete_script('function: form_profileimageview_ajax_MGMT');
				});
			}
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function SET_MGMT_IMAGE_MENU(index_var){
			if(document.getElementById("image_control_formCurrentIndex").value==index_var){
			/* --------------------------------------------------------  */
			if(document.getElementById("image_stat_options_menu_like_option_default")){
				SET_BUTTON_CLICK_ACTION("image_stat_options_menu_like_option_default");
				$("#image_stat_options_menu_like_option_default").unbind().click(function() {
					if ($("#image_stat_options_menu_like_option_default").hasClass("stat_like_option_active")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_like_option_active";
						}		
					}
					if ($("#image_stat_options_menu_like_option_default").hasClass("stat_like_option_default")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_like_option_default";
						}			
					}
					DATA_MGMT_IMAGE_OPTIONS();
				});
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("image_stat_options_menu_like_option_active")){
				SET_BUTTON_CLICK_ACTION("image_stat_options_menu_like_option_active");		
				$("#image_stat_options_menu_like_option_active").unbind().click(function() {
					if ($("#image_stat_options_menu_like_option_active").hasClass("stat_like_option_active")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_like_option_active";
						}		
					}
					if ($("#image_stat_options_menu_like_option_active").hasClass("stat_like_option_default")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_like_option_default";
						}	
					}
					DATA_MGMT_IMAGE_OPTIONS();
				});
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("image_stat_options_menu_dislike_option_active")){
				SET_BUTTON_CLICK_ACTION("image_stat_options_menu_dislike_option_active");	
				$("#image_stat_options_menu_dislike_option_active").unbind().click(function() {
					if ($("#image_stat_options_menu_dislike_option_active").hasClass("stat_dislike_option_active")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_dislike_option_active";
						}		
					}
					if ($("#image_stat_options_menu_dislike_option_active").hasClass("stat_dislike_option_default")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_dislike_option_default";
						}	
					}
					DATA_MGMT_IMAGE_OPTIONS();
				});
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("image_stat_options_menu_dislike_option_default")){
				SET_BUTTON_CLICK_ACTION("image_stat_options_menu_dislike_option_default");		
				$("#image_stat_options_menu_dislike_option_default").unbind().click(function() {
					if ($("#image_stat_options_menu_dislike_option_default").hasClass("stat_dislike_option_active")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_dislike_option_active";
						}		
					}
					if ($("#image_stat_options_menu_dislike_option_default").hasClass("stat_dislike_option_default")) {
						if(document.getElementById("image_stat_options_fields_formReport")){
							document.getElementById("image_stat_options_fields_formReport").value="image_stat_options_menu_dislike_option_default";
						}	
					}
					DATA_MGMT_IMAGE_OPTIONS();					
				});
			}
			/* --------------------------------------------------------  */
			}
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		function DATA_MGMT_IMAGE_OPTIONS(){
			//windowUnload_script('function:  -  ln:  ');
			var Data = $('.Image_Stat_Fields_Activity_UI_class').serialize();
			var url = null;
				
			if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_menu'){
				url = '/entity/profile_imageoptions';
			}
			if($('.Profile_member_Activity_UI_class').attr('name') =='profile_member_member_menu'){
				 url = '/entity/member_imageoptions';
			}
			$.post(url,Data,{		
			})
			.done(function( data ) {
				if(data.responseCode==200 ){ 
					if(data.confirmation){   
						/* --------------------------------------------------------  */
						if(data.response == 'image_stat_options_menu_like_option_active'){
							if($('#image_stat_options_menu_like_option_active').length){
								$("#image_stat_options_menu_like_option_active").addClass('stat_like_option_active').removeClass('stat_like_option_default');
							}
							if($('#image_stat_options_menu_like_option_default').length){
								$("#image_stat_options_menu_like_option_default").addClass('stat_like_option_active').removeClass('stat_like_option_default');
							}
							/* --------------------------------------------------------  */
							if($('#image_stat_options_menu_dislike_option_active').length){
								$("#image_stat_options_menu_dislike_option_active").addClass('stat_dislike_option_default').removeClass('stat_dislike_option_active');
							}
							if($('#image_stat_options_menu_dislike_option_default').length){
								$("#image_stat_options_menu_dislike_option_default").addClass('stat_dislike_option_default').removeClass('stat_dislike_option_active');
							}
						}
						if(data.response == 'image_stat_options_menu_like_option_default'){
							if($('#image_stat_options_menu_like_option_active').length){
								$("#image_stat_options_menu_like_option_active").addClass('stat_like_option_default').removeClass('stat_like_option_active');
							}
							if($('#image_stat_options_menu_like_option_default').length){
								$("#image_stat_options_menu_like_option_default").addClass('stat_like_option_default').removeClass('stat_like_option_active');
							}
						}
						/* --------------------------------------------------------  */
						if(data.response == 'image_stat_options_menu_dislike_option_active'){
							if($('#image_stat_options_menu_dislike_option_active').length){
								$("#image_stat_options_menu_dislike_option_active").addClass('stat_dislike_option_active').removeClass('stat_dislike_option_default');
							}
							if($('#image_stat_options_menu_dislike_option_default').length){
								$("#image_stat_options_menu_dislike_option_default").addClass('stat_dislike_option_active').removeClass('stat_dislike_option_default');
							}
							/* --------------------------------------------------------  */
							if($('#image_stat_options_menu_like_option_active').length){
								$("#image_stat_options_menu_like_option_active").addClass('stat_like_option_default').removeClass('stat_like_option_active');
							}
							if($('#image_stat_options_menu_like_option_default').length){
								$("#image_stat_options_menu_like_option_default").addClass('stat_like_option_default').removeClass('stat_like_option_active');
							}
						}
						
						if(data.response == 'image_stat_options_menu_dislike_option_default'){
							if($('#image_stat_options_menu_dislike_option_active').length){
								$("#image_stat_options_menu_dislike_option_active").addClass('stat_dislike_option_default').removeClass('stat_dislike_option_active');
							}
							if($('#image_stat_options_menu_dislike_option_default').length){
								$("#image_stat_options_menu_dislike_option_default").addClass('stat_dislike_option_default').removeClass('stat_dislike_option_active');
							}
						}
						/* --------------------------------------------------------  */
						if($('#image_stat_options_menu_like_count').length){
								$("#image_stat_options_menu_like_count").val(data.like_count);
						}
						if($('#image_stat_options_menu_dislike_count').length){
								$("#image_stat_options_menu_dislike_count").val(data.dislike_count);
						}
						/* --------------------------------------------------------  */
						window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
					if(data.responseCode==400){
                        CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
						window_complete_script('function: form_profileimageview_ajax_MGMT');
					}
				}
				else if(data.responseCode==400){
					CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				    window_complete_script('function: form_profileimageview_ajax_MGMT');
				}
				else if(data.responseCode=='EXIT'){
					CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				    window_complete_script('function: form_profileimageview_ajax_MGMT');
				}
				else{
					$('#basic_status').html(data);
					CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + data.response);
				    window_complete_script('function: form_profileimageview_ajax_MGMT');
				}
			})			
			.fail(function( jqXHR, textStatus, errorThrown) {//jqXHR, textStatus, errorThrown
				CONSOLE_MANAGER('form_profileimageview_ajax_MGMT','REQUEST ERROR: ' + textStatus + " | " + errorThrown);
				window_complete_script('function: form_profileimageview_ajax_MGMT');
			});	
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		//window_complete_script('form_profileimageview_ajax_MGMT');
	});
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function RECOMMENDATIONS_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('RECOMMENDATIONS_SELECT_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('RECOMMENDATIONS_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
				$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
                $('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options4').attr('id'));
                //$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
                 /*
                REGISTER_POP
                */
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options4'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options12').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options12");
				$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options12').attr('id'));
                //$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
                 /*
                REGISTER_POP
                */
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options12'),null,null,null,null);
            }
			/* -------------------------------------------------------- */
			if( $('#select_menu_item_verificationCode').val()){
				SET_BUTTON_CLICK_ACTION("select_menu_item_options9");
				$('#select_menu_item_options9').unbind().click(function(event) {
					$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
					$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
					$('#standard_entity_modify_index2').val($('#select_menu_item_uniqEventId').val());
					$('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
					$('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
					$('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
					$('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
					if($('#select_menu_item_index3')){
						$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
					}else{
						$('#standard_entity_modify_formEntity').val(null); 
					}
					$('#standard_entity_modify_index7').val($('#select_menu_item_verificationCode').val());
					$('#standard_entity_modify_index10').val( $('#select_menu_item_options9').attr('id'));
					//$('#standard_entity_modify_dollarValue').val($('#select_menu_item_dollarValue').val());
					 /*
					REGISTER_POP
					*/
					$('#standard_entity_modify_control').val($('#select_menu_item_control').val());
					//standard_entity_modify_dollarValue
					settings_request_function(event);
				});
				DURATION($('#select_menu_item_formFilter3'),$('#select_menu_item_options6'),$('#select_menu_item_title').val(),$('#select_menu_item_activityStatus').val(),$('#select_menu_item_duration').val());
			}	
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
				$button_obj.unbind().click(function(event) {
						/*SINGLE CLICK*/
						event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($INDEX04.val());
						$('#standard_entity_modify_index5').val($INDEX05.val());
						$('#standard_entity_modify_index6').val($INDEX06.val());
						if($('#select_menu_item_index3')){
							$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
						}else{
							$('#standard_entity_modify_formEntity').val(null); 
						}
						$('#standard_entity_modify_index7').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#select_menu_item_duration').val()==7){
				$('#select_menu_item_options20').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==14){
				$('#select_menu_item_options21').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==21){
				$('#select_menu_item_options22').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==30){
				$('#select_menu_item_options23').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==90){
				$('#select_menu_item_options24').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==180){
				$('#select_menu_item_options25').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==270){
				$('#select_menu_item_options26').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==360){
				$('#select_menu_item_options27').addClass('recommendation_active');
			}
			if(($('#select_menu_item_duration').val()=='UNLIMITED')||($('#select_menu_item_duration').val()=='FREE')||($('#select_menu_item_duration').val()==0)){
				$('#select_menu_item_options28').addClass('recommendation_active');
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('RECOMMENDATIONS_SELECT_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if($('#standard_results_ajax_control').val()){
								$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
								if($('#search_request_control').length){
								   $SEARCH_CNTRL = $('#search_request_control').val();
								}

								if(mutation.type=='attributes'){
									if($("#window_view").is(':visible')){
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/**********		CONFIRM_MGMT	**********/
										if($RESULTS_CNTRL.includes('Interaction_Activity_UI_class')){
											/*
											//var $CONFIRM_CNTRL = $('#settings_sys_confirm_index1').val();
											//if($CONFIRM_CNTRL.includes('options1')){
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {
													$('#'+ $CONFIRM_CNTRL).unbind().click();
													//windowload_script('function:  form_event_itemfunction_MGMT ');
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											//}
											*/
											//if($CONFIRM_CNTRL.includes('options2')){
											var close_div_var = null;
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {	
													$('#select_menu_item_options4').unbind().click();
													//windowload_script('function:  form_event_itemfunction_MGMT  ');
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											//}
										}
										/* -------------------------------------------------------- */
									}
								}else if(mutation.type=='childList'){
									/* --------------------------------------------------------  */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('function: RECOMMENDATIONS_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function IDENTITY_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('IDENTITY_SELECT_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('IDENTITY_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
						var $2SEQUENCE = '#form_'+$code+'_'+$i+'_options2';
						var $3SEQUENCE = '#form_'+$code+'_'+$i+'_options3';
						var $4SEQUENCE = '#form_'+$code+'_'+$i+'_options4';
						var $5SEQUENCE = '#form_'+$code+'_'+$i+'_options5';
						var $6SEQUENCE = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						REGISTER_CLICK($($SEQUENCE),$i,$code);
						REGISTER_CLICK($($2SEQUENCE),$i,$code);
						REGISTER_CLICK($($3SEQUENCE),$i,$code);
						REGISTER_CLICK($($4SEQUENCE),$i,$code);
						//REGISTER_CLICK($($5SEQUENCE),$i,$code);
						REGISTER_CLICK($($6SEQUENCE),$i,$code);
						//header_background_activate($($PATHSEQUENCE),$($SEQUENCE));
						if($($4SEQUENCE).val()==true){
							//REGISTER_CLICK($($4SEQUENCE),$i,$code);
							//REGISTER_CLICK($($5SEQUENCE),$i,$code);
						}
						if($($4SEQUENCE).val()==false){
							//REGISTER_CLICK($($4SEQUENCE),$i,$code);
							//REGISTER_CLICK($($5SEQUENCE),$i,$code);
						}
					}	
				}   
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code){
				$button_obj.single_double_click(function(event){
						/*SINGLE CLICK*/
						event.stopPropagation;
						//$('#standard_entity_modify_uniqEventId').val($('#select_menu_item_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#select_menu_item_registerCode').val());
						$('#standard_entity_modify_index1').val($button_obj.attr('id'));
						$('#standard_entity_modify_index2').val($('#form_'+$code+'_'+$val_code+'_index2').val());
						//$('#standard_entity_modify_title').val($('#form_'+$code+'_'+$val_code+'_index1').val());
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					}, function(event) {
						/*DOUBLE CLICK*/
						event.stopPropagation;

					}, function(event) {
						/*PRESS*/
						event.stopPropagation;

					},function(event) {
						/*SWIPE*/
						event.stopPropagation;
					}													  
				);
			}
		/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('IDENTITY_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
