/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 

/*  REGISTRATION_basics
/*  REGISTRATION_SOCIAL_basics
/*  LOGIN_basics
/*  RESET_basics
/*  RESET_ACTIVATION_basics
/*  ACTIVATION_basics
/*  
/*  


//login_remember_me	registration_username   console.log('   business_registration_options3 

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                         ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { sensitiveMGMT as sensitiveMGMT } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { filter_form_function as filter_form_function } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";

import { SETTINGS_MENU_CONTROL as SETTINGS_MENU_CONTROL } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";
import { htmlEscape as htmlEscape } from "./mgmt_control.js";

import { UPDATE_UNAME_CLASS as UPDATE_UNAME_CLASS } from "./mgmt_control.js";
import { Username_validation_MGMT as Username_validation_MGMT } from "./mgmt_control.js";
import { Phone_validation_MGMT as Phone_validation_MGMT } from "./mgmt_control.js";
import { Email_validation_MGMT as Email_validation_MGMT } from "./mgmt_control.js";

import { ENTRYdelay as ENTRYdelay } from "./mgmt_control.js";

import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";

import { gender_ajax as gender_ajax } from "./mgmt_control.js";
import { birthday_request_function as birthday_request_function } from "./mgmt_control.js";
import { business_type_select as business_type_select } from "./mgmt_control.js";
import { sys_email_check as sys_email_check } from "./mgmt_control.js";

import { sys_password_check as sys_password_check } from "./mgmt_control.js";
import { trigg_pass_match as trigg_pass_match } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { sys_pass_match_check as sys_pass_match_check } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { SET_GEOLOCATION as SET_GEOLOCATION } from "./mgmt_mapping.js";
import { business_title_validation_MGMT as business_title_validation_MGMT } from "./mgmt_control.js";
import { business_phone_validation_MGMT as business_phone_validation_MGMT } from "./mgmt_control.js";
import { BUSINESS_Email_validation_MGMT as BUSINESS_Email_validation_MGMT } from "./mgmt_control.js";
import { UPDATE_BTITLE_CLASS as UPDATE_BTITLE_CLASS } from "./mgmt_control.js";
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function  REGISTRATION_basics(){
	
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$('.Registration_UI_class').attr("id", "Registration_UI_control");
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('REGISTRATION_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//SET_BUTTON_CLICK_ACTION("Security_form1_options1");
			//SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            //setPrivacypolicy setProfileType
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var PASS_VALIDATE = false;
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
            SET_BUTTON_CLICK_ACTION("Security_form1_options1");
            $("#label_Security_form1_options1").unbind().click(function(){
                //$("#Security_form1_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
            SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            $("#label_Security_form2_options1").unbind().click(function(){
                //$("#Security_form2_options1").click();
            });
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_registration_options_control1");
            SET_BUTTON_CLICK_ACTION("registration_options_control1");
            $("#label_registration_options_control1").unbind().click(function(){
                //$("#registration_options_control1").click();
            });
            SET_BUTTON_CLICK_ACTION("label_registration_options_control2");
            SET_BUTTON_CLICK_ACTION("registration_options_control2");
            $("#label_registration_options_control2").unbind().click(function(){
                //$("#registration_options_control2").click();
            });
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('photo_overlay');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($('#standard_entity_modify_control').val()){
                                CONSOLE_MANAGER('REGISTRATION_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                //var $TEMP_CONTROL = $('#standard_results_ajax_control').val();
                                var $RESULTS_CNTRL = null; 
                                var $SEARCH_CNTRL = null;
                                $RESULTS_CNTRL = $('#standard_entity_modify_control').val();
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('REGISTRATION_basics',mutation.type+': attributes');
                                    CONSOLE_MANAGER('REGISTRATION_basics',$('#registration_index1').attr('id')+': -----  ACTIVE  ------'+' - '+$RESULTS_CNTRL);
                                    /* -------------------------------------------------------- */
                                    if($RESULTS_CNTRL == $('#registration_index1').attr('id')){
                                        birthday_request_function();
                                        setTimeout(is_review_complete, 500);
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    /* -------------------------------------------------------- 
                                    if($RESULTS_CNTRL == $('#registration_index2').attr('id')){
                                        gender_ajax();
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    */
                                    /* -------------------------------------------------------- */
                                    if($RESULTS_CNTRL == $('#business_registration_options3').attr('id')){
                                        business_type_select();
                                        setTimeout(is_review_complete, 500);
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                     CONSOLE_MANAGER('REGISTRATION_basics',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            var $controlString = 'false';
            if($('#business_registration_profileType').length){
                $controlString = $('#business_registration_profileType').val(); 
            }
            if($('#registration_profileType').length){
                $controlString = $('#registration_profileType').val();  
            }
            CONSOLE_MANAGER('REGISTRATION_basics',$controlString+' ACTIVATED');
            $('#registration_options1').attr('disabled',true);
            $('#business_registration_options1').attr('disabled',true);
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            if($controlString.indexOf("PROFILE_TYPE") >= 0){
                 
                $("#registration_username").attr("autocomplete", "off");
                $("#registration_username").val(null);
                $("#registration_username").html(null);
                $("#registration_firstName").attr("autocomplete", "off");
                $("#registration_firstName").val(null);
                $("#registration_firstName").html(null);
                $("#registration_lastName").attr("autocomplete", "off");
                $("#registration_lastName").val(null);
                $("#registration_lastName").html(null);
                $("#registration_phone").attr("autocomplete", "off");
                $("#registration_phone").val(null);
                $("#registration_phone").html(null);
                $("#registration_index1").attr("autocomplete", "off");
                $("#registration_index1").val(null);
                $("#registration_index1").html(null);
                $("#registration_email_first").attr("autocomplete", "off");
                $("#registration_email_first").val(null);
                $("#registration_email_first").html(null);
                $("#registration_email_second").attr("autocomplete", "off");
                $("#registration_email_second").val(null);
                $("#registration_email_second").html(null);
                $("#registration_password_first").attr("autocomplete", "off");
                $("#registration_password_first").val(null);
                $("#registration_password_first").html(null);
                $("#registration_password_second").attr("autocomplete", "off");
                $("#registration_password_second").val(null);
                $("#registration_password_second").html(null);
                
                $("#registration_address").attr("autocomplete", "off");
                $("#registration_address").val(null);
                $("#registration_address").html(null);
                //$('#registration_address').attr('disabled',true);
                $("#registration_address2").attr("autocomplete", "off");
                $("#registration_address2").val(null);
                $("#registration_address2").html(null);
                //$('#registration_address2').attr('disabled',true);
                $("#registration_city").attr("autocomplete", "off");
                $("#registration_city").val(null);
                $("#registration_city").html(null);
                //$('#registration_city').attr('disabled',true);
                $("#registration_state").attr("autocomplete", "off");
                $("#registration_state").val(null);
                $("#registration_state").html(null);
                //$('#registration_state').attr('disabled',true);
                $("#registration_state").attr("autocomplete", "off");
                $("#registration_state").val(null);
                $("#registration_state").html(null);
                //$('#registration_state').attr('disabled',true);
                $("#registration_postal").attr("autocomplete", "off");
                $("#registration_postal").val(null);
                $("#registration_postal").html(null);
                //$('#registration_postal').attr('disabled',true);
                //$("#registration_firstName").focus();
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_username")){
                    var reg_titlename_var = document.getElementById("registration_username");
                    /* --------------------------------------------------------  */
                    reg_titlename_var.onfocus = function() {
                        /* --------------------------------------------------------  */
                        $( "#registration_username" ).unbind().keyup(function(e) {
                            /* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("registration_username").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#registration_username').val();
                                $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#registration_username').val();
                            var string = document.getElementById("registration_username").value;
                            reg_titlename_var.onblur = function() {
                                var string = document.getElementById("registration_username").value;
                                var result = string.replace(/^\s+|\s+$/gm,'');
                                $('#registration_username').val(result);
                                if($REG_UNAME.length>2){
                                    if(($('#registration_username').val().length>0)){
                                        $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                        $('#standard_entity_modify_title').val($('#registration_username').val());
                                        //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                        //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                        //Username_validation_MGMT(false);
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if(!($('#registration_username').val())){
                                        UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                        setTimeout(is_review_complete, 500);
                                    }
                                }
                            }
                        });
                        $('#registration_username').on('keypress', function(e) {
                            CONSOLE_MANAGER('registration_username','keypress');
                            var $REG_UNAME = $('#registration_username').val();
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    //Username_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('focus', function() {
                            CONSOLE_MANAGER('registration_username','focus');
                            setTimeout(is_review_complete, 500);
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $REG_UNAME = $('#registration_username').val();
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    Username_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_phone").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_phone').val(result);
                            var $REG_PHONE = $('#registration_phone').val();
                            if($REG_PHONE.length>3){
                                phone_format(false);
                                CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                                if($REG_PHONE.length>13){
                                    $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                    //$('#standard_entity_modify_title').val($('#registration_username').val());
                                    $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                    //business_phone_validation_MGMT(e);
                                    Phone_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_email_first").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_email_first').val(result);
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#registration_email_second').val($('#registration_email_first').val())
							/* --------------------------------------------------------  */
                            var string = document.getElementById("registration_email_second").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_email_second').val(result);
                            var $REG_EMAIL1 = $('#registration_email_first').val();
                            var $REG_EMAIL2 = $('#registration_email_second').val();
                            CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                            if($REG_EMAIL1==$REG_EMAIL2){
                                if($REG_EMAIL1.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    //BUSINESS_Email_validation_MGMT(e);
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var $REG_FIRST_NAME = $('#registration_firstName').val();
                            if($REG_FIRST_NAME.length>1){
                                setTimeout(is_review_complete, 500);
                            }
                            /* --------------------------------------------------------  */
                            var $REG_LAST_NAME = $('#registration_lastName').val();
                            if($REG_LAST_NAME.length>1){
                                setTimeout(is_review_complete, 500);
                            }
                            /* --------------------------------------------------------  */
                        })
                        .on('blur', function() {
                            CONSOLE_MANAGER('registration_username','blur');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('keyup', function() {
                            CONSOLE_MANAGER('registration_username','keyup');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('change', function() {
                            CONSOLE_MANAGER('registration_username','change');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            var $REG_UNAME = $('#registration_username').val();
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    Username_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        });
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options2")){
                    SET_BUTTON_CLICK_ACTION("registration_options2");
                    $('#registration_options2').unbind().click(function(event) {
                        $('#standard_entity_modify_control').val($('#registration_options2').attr('id'));
                        settings_request_function(event);
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* AJAX LISTENERS  (MutationObserver) ---------------------  */
                var MODIFY_VIEW_LISTENER = (function (){
                    var target = document.getElementById('standard_results_ajax_control');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */	
                    var observer = new MutationObserver(function(mutations) {
                      mutations.forEach(function(mutation) {
                        if($("#standard_results_ajax_control").val()){  
                            CONSOLE_MANAGER('REGISTRATION_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            var $RESULTS_CNTRL = null;
                            var $SEARCH_CNTRL = null;
                            if($('#standard_results_ajax_control').val()){
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                if($('#search_request_control').length){
                                   $SEARCH_CNTRL = $('#search_request_control').val();
                                }
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('REGISTRATION_basics',mutation.type+': attributes');
                                    /* -------------------------------------------------------- */
                                    //var $TEMP_VAL = $('#standard_results_ajax_control').val();
                                    if( $RESULTS_CNTRL == $('#registration_username').attr('id')){
                                        username_ajax();
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if( $RESULTS_CNTRL == $('#registration_email_first').attr('id')){
                                        email_ajax();
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_full_field_check_positive
                                                ls_form_full_field_check_negative
                                            */
                                            $("#email_field_check").removeClass('any_visuallyhidden');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('ls_form_full_field_check_positive');
                                        }else{
                                            $("#email_field_check").removeClass('ls_form_full_field_check_positive');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('any_visuallyhidden');
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if( $RESULTS_CNTRL == $('#registration_phone').attr('id')){
                                        phone_format(true);
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_half_field_check_positive
                                                ls_form_half_field_check_negative
                                            */
                                            $("#phone_field_check").removeClass('any_visuallyhidden');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('ls_form_half_field_check_positive');
                                        }else{
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_positive');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('any_visuallyhidden');  
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    //$("#standard_results_ajax_control").val('');
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('REGISTRATION_basics',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        }
                      });    
                    });//END new MutationObserver
                    var config = { attributes: true, childList: true, characterData: true };
                    observer.observe(target, config);
                })();/*END FUNCTION */
                /* --------------------------------------------------------  */
                function username_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#registration_username').attr('id')){
                        if($('#standard_results_ajax_responseData').val()){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_username_correct', '');
                        }else{
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_username_error', '');
                        }
                        setTimeout(is_review_complete, 500);
                        $('#standard_results_ajax_control').val('');
                        $('#standard_entity_modify_username').val('');
                        $('#standard_results_ajax_responseData').val('');
                    }
                }
                /* --------------------------------------------------------  */
                function email_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#registration_email_first').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email1").className='output_email_correct';			
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                        }
                    }
                    else if($('#standard_results_ajax_control').val() == $('#registration_email_second').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email2").className='output_email_correct';			
                        }else{
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    if(($('#registration_email_first').val()) && ($('#registration_email_second').val())){
                        var mtch = sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());

                        if(mtch){
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    setTimeout(is_review_complete, 500);
                    $('#standard_results_ajax_control').val('');
                    $('#standard_entity_modify_email').val('');
                    $('#standard_results_ajax_responseData').val('');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("registration_firstName")){
                    var register_reg_firstname_var = document.getElementById("registration_firstName");
                }*/
                $('#registration_firstName').on('keypress', function(e) {
                    CONSOLE_MANAGER('registration_firstName','keypress');
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('registration_firstName','focus');
                    setTimeout(is_review_complete, 500);
                    var string = document.getElementById("registration_firstName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_firstName').val(result);
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_username").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_username').val(result);
                    var $REG_UNAME = $('#registration_username').val();
                    var $STRING=$('#registration_username').val();
                    $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                    if($REG_UNAME.length>2){
                        if(($('#registration_username').val().length>0)){
                            $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                            $('#standard_entity_modify_title').val($('#registration_username').val());
                            //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            //windowload_script('REGISTRATION_basics - $REG_UNAME');
                            Username_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        if(!($('#registration_username').val())){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_phone").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_phone').val(result);
                    var $REG_PHONE = $('#registration_phone').val();
                    if($REG_PHONE.length>3){
                        phone_format(false);
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_first").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_first').val(result);
					/* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_email_second').val($('#registration_email_first').val())
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_second").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_second').val(result);
                    var $REG_EMAIL1 = $('#registration_email_first').val();
                    var $REG_EMAIL2 = $('#registration_email_second').val();
                    CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                    if($REG_EMAIL1==$REG_EMAIL2){
                        if($REG_EMAIL1.length>5){
                            $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            $('#standard_entity_modify_email').val($('#registration_email_first').val());
                            //BUSINESS_Email_validation_MGMT(e);
                            Email_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_firstName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_firstName').val(result);
                    var $REG_FIRST_NAME = $('#registration_firstName').val();
                    if($REG_FIRST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_lastName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_lastName').val(result);
                    var $REG_LAST_NAME = $('#registration_lastName').val();
                    if($REG_LAST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                });
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("registration_lastName")){
                    var register_reg_lastname_var = document.getElementById("registration_lastName");
                }*/
                $('#registration_lastName').on('keypress', function(e) {
                    CONSOLE_MANAGER('registration_lastName','keypress');
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('registration_lastName','focus');
                    setTimeout(is_review_complete, 500);
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_username").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_username').val(result);
                    var $REG_UNAME = $('#registration_username').val();
                    var $STRING=$('#registration_username').val();
                    $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                    if($REG_UNAME.length>2){
                        if(($('#registration_username').val().length>0)){
                            $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                            $('#standard_entity_modify_title').val($('#registration_username').val());
                            //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            //windowload_script('REGISTRATION_basics - $REG_UNAME');
                            Username_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        if(!($('#registration_username').val())){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_phone").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_phone').val(result);
                    var $REG_PHONE = $('#registration_phone').val();
                    if($REG_PHONE.length>3){
                        phone_format(false);
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_first").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_first').val(result);
					/* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_email_second').val($('#registration_email_first').val())
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_second").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_second').val(result);
                    var $REG_EMAIL1 = $('#registration_email_first').val();
                    var $REG_EMAIL2 = $('#registration_email_second').val();
                    CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                    if($REG_EMAIL1==$REG_EMAIL2){
                        if($REG_EMAIL1.length>5){
                            $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            $('#standard_entity_modify_email').val($('#registration_email_first').val());
                            //BUSINESS_Email_validation_MGMT(e);
                            Email_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var $REG_FIRST_NAME = $('#registration_firstName').val();
                    if($REG_FIRST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                    var $REG_LAST_NAME = $('#registration_lastName').val();
                    if($REG_LAST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                });
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_phone")){
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_phone').on('change', function(e) {
                        CONSOLE_MANAGER('registration_phone','change');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(true);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            phone_format(true);
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_phone','keypress');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        phone_format(true);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('focus', function(e) {
                        CONSOLE_MANAGER('registration_phone','focus');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                //BUSINESS_Email_validation_MGMT(e);
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function(e) {
                        CONSOLE_MANAGER('registration_phone','blur');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            //business_phone_validation_MGMT(e);
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                        
                    })
                    .on('keyup', function(e) {
                        CONSOLE_MANAGER('registration_phone','keyup');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                    });
                }
                function phone_format(e){
                    CONSOLE_MANAGER('phone_format','----------------------------');
                    var $text = $( "#registration_phone" ).val();
                    $text = $text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    $( "#registration_phone" ).val($text);
                    
                    if ($( "#registration_phone" ).val().length === 0) {
                        $( "#registration_phone" ).val('(');
                    } else {
                        var val = $( "#registration_phone" ).val();
                        $( "#registration_phone" ).val('').val(val); // Ensure cursor remains at the end
                    }
                    //sys_phone_check
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_index1")){
                    SET_BUTTON_CLICK_ACTION("registration_index1");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_index1'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#registration_index1').attr('id'));
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_index2")){
                    SET_BUTTON_CLICK_ACTION("registration_index2");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_index2'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#registration_index2').attr('id'));
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_email_first")){
                    document.getElementById("output_email1").className='output_email_default';
                    var reg_email1_var = document.getElementById("registration_email_first");

                    reg_email1_var.onblur = function() {
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            //$('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#registration_email_first').val());
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                        
                    }
                    $('#registration_email_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_email_first','keypress');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_email_first','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        var $REG_PHONE = $('#registration_phone').val();
                        if($REG_PHONE.length>3){
                            phone_format(false);
                            CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#registration_username').val());
                                $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                //business_phone_validation_MGMT(e);
                                Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                //BUSINESS_Email_validation_MGMT(e);
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('registration_email_first','blur');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_email_first','keyup');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_email_first','change');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_email_second")){
                    document.getElementById("output_email2").className='output_email_default';
                    var reg_email2_var = document.getElementById("registration_email_second");

                    reg_email2_var.onblur = function() {
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            //$('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#registration_email_first').val());
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                document.getElementById("output_email2").className='output_email_correct';
                                var $REG_EMAIL = $('#registration_email_first').val();
                                CONSOLE_MANAGER('registration_email_second','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    //BUSINESS_Email_validation_MGMT(e);
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                        
                    }
                    $('#registration_email_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_email_second','keypress');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_email_second','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                //$('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        var $REG_PHONE = $('#registration_phone').val();
                        if($REG_PHONE.length>3){
                            phone_format(false);
                            CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#registration_username').val());
                                $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                //business_phone_validation_MGMT(e);
                                Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                //BUSINESS_Email_validation_MGMT(e);
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('registration_email_second','blur');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_email_second','keyup');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_email_second','change');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                document.getElementById("output_email2").className='output_email_correct';
                                var $REG_EMAIL = $('#registration_email_first').val();
                                CONSOLE_MANAGER('registration_email_second','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    //BUSINESS_Email_validation_MGMT(e);
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_password_first")){
                    var reg_pass1_var = document.getElementById("registration_password_first");	
                    reg_pass1_var.onfocus = function() {
                        //UPDATE_CLASS($("#output_pass1"), "output_password_default", ' ');
                    }
                    reg_pass1_var.onblur = function() {
                        var reg_pass1_var_filler_holder1 = +this.value;
                        //var PASS = sys_password_check(document.getElementById("registration_password_first").value);
                        //if(PASS.length>5){
                            var P_MGMT = sys_password_check(document.getElementById("registration_password_first").value);
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#registration_password_second').val($('#registration_password_first').val())
							/* --------------------------------------------------------  */
                            if(P_MGMT == true){ 
                                if($("#output_pass1.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                    UPDATE_CLASS($("#output_pass1"), "output_password_correct", " ");
                                }
                            }else{
                                if (document.getElementById("registration_password_first").value=='') {
                                }
                                if (document.getElementById("registration_password_first").value!='') {
                                    if( $("#output_pass1.default") || $("#output_pass2.weak") ){
                                        UPDATE_CLASS($("#output_pass1"), "output_password_error", " ");
                                    }
                                }
                            }
                            sys_pass_match_check($('#registration_password_first').val(),$('#registration_password_second').val());
                        //}
                        setTimeout(is_review_complete, 500);
                    }
                    $('#registration_password_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_password_first','keypress');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            checkVal("output_pass1");
                            //checkVal("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_password_first','focus');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_password_first','keyup');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass1");
                            //outputResult("output_pass2"); 
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_password_first','change');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass1");
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_password_second")){
                    var reg_pass2_var = document.getElementById("registration_password_second");
                    reg_pass2_var.onfocus = function() {
                    }
                    reg_pass2_var.onblur = function() {
                        var reg_pass2_var_filler_holder1 = +this.value;
                        //var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        //if(PASS.length>5){
                            var P_MGMT = sys_password_check(document.getElementById("registration_password_second").value);
                            if(P_MGMT){ 
                                if($("#output_pass2.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                    UPDATE_CLASS($("#output_pass2"), "output_password_correct", " ");
                                }
                                trigg_pass_match();
                            }else{
                                if (document.getElementById("registration_password_second").value=='') {
                                }
                                if (document.getElementById("registration_password_second").value!='') {
                                    if( $("#output_pass2.default") || $("#output_pass2.weak") ){
                                        UPDATE_CLASS($("#output_pass2"), "output_password_error", " ");
                                    }
                                }
                            }
                            sys_pass_match_check($('#registration_password_first').val(),$('#registration_password_second').val());
                        //}
                         //trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    }
                    $('#registration_password_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_password_second','keypress');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            //checkVal("output_pass1");
                            checkVal("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_password_second','focus');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_password_second','keyup');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            //outputResult("output_pass1");
                            outputResult("output_pass2"); 
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_password_second','change');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options4")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options4'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options4active")){
                    $('#registration_options4active').unbind().click(function(event) {
                        var $tempClass = $('#registration_options4active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#registration_options4active').removeClass($tempClass);
                       	    $('#registration_options4active').addClass('reg_check_button_active');
                            $('#registration_termsaccepted').val(htmlEscape("ACCEPTED"));
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#registration_options4active').removeClass($tempClass);
                       	    $('#registration_options4active').addClass('reg_check_button_default');
                            $('#registration_termsaccepted').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options5")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options5'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options5active")){
                    $('#registration_options5active').unbind().click(function(event) {
                        var $tempClass = $('#registration_options5active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#registration_options5active').removeClass($tempClass);
                       	    $('#registration_options5active').addClass('reg_check_button_active');
                            $('#registration_privacypolicy').val(htmlEscape("ACCEPTED"));
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#registration_options5active').removeClass($tempClass);
                       	    $('#registration_options5active').addClass('reg_check_button_default');
                            $('#registration_privacypolicy').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });                    
                }
            }
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            if ($controlString.indexOf("BUSINESS_TYPE") >= 0){
                   
                $("#business_registration_title").attr("autocomplete", "off");
                $("#business_registration_title").val(null);
                $("#business_registration_title").html(null);
                $("#business_registration_phone").attr("autocomplete", "off");
                $("#business_registration_phone").val(null);
                $("#business_registration_phone").html(null);
                $("#business_registration_options3").attr("autocomplete", "off");
                $("#business_registration_options3").val(null);
                $("#business_registration_options3").html(null);
                $("#business_registration_password_first").attr("autocomplete", "off");
                $("#business_registration_password_first").val(null);
                $("#business_registration_password_first").html(null);
                $("#business_registration_password_second").attr("autocomplete", "off");
                $("#business_registration_password_second").val(null);
                $("#business_registration_password_second").html(null);
                $("#business_registration_email_first").attr("autocomplete", "off");
                $("#business_registration_email_first").val(null);
                $("#business_registration_email_first").html(null);
                $("#business_registration_email_second").attr("autocomplete", "off");
                $("#business_registration_email_second").val(null);
                $("#business_registration_email_second").html(null);
                $("#business_registration_address").attr("autocomplete", "off");
                $("#business_registration_address").val(null);
                $("#business_registration_address").html(null);
                $("#business_registration_address2").attr("autocomplete", "off");
                $("#business_registration_address2").val(null);
                $("#business_registration_address2").html(null);
                $("#business_registration_city").attr("autocomplete", "off");
                $("#business_registration_city").val(null);
                $("#business_registration_city").html(null);
                $("#business_registration_state").attr("autocomplete", "off");
                $("#business_registration_state").val(null);
                $("#business_registration_state").html(null);
                $("#business_registration_country").attr("autocomplete", "off");
                $("#business_registration_country").val(null);
                $("#business_registration_country").html(null);
                //$("#business_registration_phone").focus();
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                //if(document.getElementById("business_registration_options1")){
                //    SET_BUTTON_CLICK_ACTION("business_registration_options1");
                //}
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options2")){
                    SET_BUTTON_CLICK_ACTION("business_registration_options2");
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options3")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options3'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#business_registration_options3').attr('id'));
                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                //if(document.getElementById("business_registration_options4")){
                //    SET_BUTTON_CLICK_ACTION("business_registration_options4");
                //}
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("business_registration_options5")){
                    SET_BUTTON_CLICK_ACTION("business_registration_options5");
                    $('#business_registration_options5').unbind().click(function(event) {
                        $('#standard_entity_modify_control').val($('#business_registration_options5').attr('id'));
                        $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                        settings_request_function(event);
                        setTimeout(is_review_complete, 500);
                    });
                 }*/
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_title")){
                    var reg_titlename_var = document.getElementById("business_registration_title");
                    /* --------------------------------------------------------  */
                    reg_titlename_var.onfocus = function() {
                        /* --------------------------------------------------------  */
                        $( "#business_registration_title" ).unbind().keyup(function(e) {
                            /* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("business_registration_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#business_registration_title').val();
                                $('#business_registration_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#business_registration_title').val();
                            var string = document.getElementById("business_registration_title").value;
                            reg_titlename_var.onblur = function() {
                                if($REG_UNAME.length>2){
                                    if(($('#business_registration_title').val().length>0)){
                                        $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                        $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                        $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                        //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                        business_title_validation_MGMT(e);
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if(!($('#business_registration_title').val())){
                                        UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                        setTimeout(is_review_complete, 500);
                                    }
                                }
                            }
                        });
                        $('#business_registration_title').on('keypress', function(e) {
                            CONSOLE_MANAGER('business_registration_title','keypress');
                            var $REG_UNAME = $('#business_registration_title').val();
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    //business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('focus', function() {
                            CONSOLE_MANAGER('business_registration_title','focus');
                            setTimeout(is_review_complete, 500);
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#business_registration_title').val();
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var $REG_PHONE = $('#business_registration_phone').val();
                            if($REG_PHONE.length>3){
                                business_phone_format(false);
                                CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                                if($REG_PHONE.length>13){
                                    $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                    //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    business_phone_validation_MGMT(e);
                                    //Phone_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('blur', function() {
                            CONSOLE_MANAGER('business_registration_title','blur');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('keyup', function() {
                            CONSOLE_MANAGER('business_registration_title','keyup');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('change', function() {
                            CONSOLE_MANAGER('business_registration_title','change');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            var $REG_UNAME = $('#business_registration_title').val();
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                    business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        });
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function title_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#business_registration_title').attr('id')){
                        var $VAL =$('#standard_results_ajax_responseData').val();
                        if ($VAL.indexOf("true") >=0){
                            UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_correct', '');
                            setTimeout(is_review_complete, 500);
                        }
                        if ($VAL.indexOf("false") >=0){
                            UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_error', '');
                            setTimeout(is_review_complete, 500);
                        }
                        $('#standard_results_ajax_responseData').val('');
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* AJAX LISTENERS  (MutationObserver) ---------------------  */
                var MODIFY_VIEW_LISTENER = (function (){
                    var target = document.getElementById('standard_results_ajax_control');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */	
                    var observer = new MutationObserver(function(mutations) {
                      mutations.forEach(function(mutation) {
                        if($("#standard_results_ajax_control").val()){  
                            CONSOLE_MANAGER('BUSINESS_registration_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            var $RESULTS_CNTRL = null;
                            var $SEARCH_CNTRL = null;
                            if($('#standard_results_ajax_control').val()){
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                if($('#search_request_control').length){
                                   $SEARCH_CNTRL = $('#search_request_control').val();
                                }
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('BUSINESS_registration_basics',mutation.type+': attributes');
                                    /* -------------------------------------------------------- */
                                    //var $TEMP_VAL = $('#standard_results_ajax_control').val();
                                    if($RESULTS_CNTRL == $('#business_registration_title').attr('id')){
                                        title_ajax();
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if($RESULTS_CNTRL == $('#business_registration_phone').attr('id')){
                                        business_phone_format(true);
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_half_field_check_positive
                                                ls_form_half_field_check_negative
                                            */
                                            $("#phone_field_check").removeClass('any_visuallyhidden');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('ls_form_half_field_check_positive');
                                        }else{
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_positive');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('any_visuallyhidden');  
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if($RESULTS_CNTRL == $('#business_registration_email_first').attr('id')){
                                        email_ajax();
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_full_field_check_positive
                                                ls_form_full_field_check_negative
                                            */
                                            $("#email_field_check").removeClass('any_visuallyhidden');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('ls_form_full_field_check_positive');
                                        }else{
                                            $("#email_field_check").removeClass('ls_form_full_field_check_positive');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('any_visuallyhidden');
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    //if($RESULTS_CNTRL == $('#business_registration_email_second').attr('id')){
                                    //    email_ajax();
                                    //    setTimeout(is_review_complete, 500);
                                   // }
                                    //if($('#standard_results_ajax_control').val() == $('#business_registration_address').attr('id')){
                                        //gender_ajax();
                                    //}
                                    //if($('#standard_results_ajax_control').val() == $('#business_registration_address2').attr('id')){
                                        //gender_ajax();
                                    //}
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('business_instance_function',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        }
                      });    
                    });//END new MutationObserver
                    var config = { attributes: true, childList: true, characterData: true };
                    observer.observe(target, config);
                })();/*END FUNCTION */
                /* --------------------------------------------------------  */
                function email_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#business_registration_email_first').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email1").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                        }
                    }
                    if($('#standard_results_ajax_control').val() == $('#business_registration_email_second').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email2").className='output_email_correct';	
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }

                    if($('#business_registration_email_first').val().length >0 && $('#business_registration_email_second').val().length >0){
                        var mtch = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(mtch){
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    $('#standard_results_ajax_control').val('');
                    $('#standard_entity_modify_email').val('');
                    $('#standard_results_ajax_responseData').val('');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_phone")){
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#business_registration_phone').on('change', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','change');
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            business_phone_validation_MGMT(false);
                            //Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','keypress');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            business_phone_validation_MGMT(false);
                            //Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    })
                    .on('focus', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','focus');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        //phone_format(false);
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            business_phone_validation_MGMT(false);
                            //Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_EMAIL1 = $('#business_registration_email_first').val();
                        var $REG_EMAIL2 = $('#business_registration_email_second').val();
                        CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                BUSINESS_Email_validation_MGMT(false);
                                //Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','blur');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            business_phone_validation_MGMT(false);
                            //Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        
                    })
                    .on('keyup', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','keyup');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  
                if(document.getElementById("business_registration_phone")){
                    $( "#business_registration_phone" ).unbind().change(function(e) {
                        phone_format(e);
                    });
                    
                    $( "#business_registration_phone" ).unbind().keyup(function(e) {
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','keyup: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            business_phone_validation_MGMT(e);
                            setTimeout(is_review_complete, 500);
                        }
                    });
                    $('#business_registration_phone').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','keypress');
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                      }
                      // Allow numeric (and tab, backspace, delete) keys only
                      return (key == 8 ||
                        key == 9 ||
                        key == 46 ||
                        (key >= 48 && key <= 57) ||
                        (key >= 96 && key <= 105));
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_phone','focus');
                        var phone = $('#business_registration_phone');
                        if (phone.val().length === 0) {
                            phone.val('(');
                        } else {
                            var val = phone.val();
                            phone.val('').val(val); // Ensure cursor remains at the end
                        }
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('business_registration_phone','blur');
                        var phone = $('#business_registration_phone');
                        if (phone.val() === '(') {
                            phone.val('');
                        }
                    });
                }
                */
                function business_phone_format(e){
                     CONSOLE_MANAGER('business_phone_format','---------------------------------');
                    var $text = $( "#business_registration_phone" ).val();
                    $text = $text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    $( "#business_registration_phone" ).val($text);
                    
                    if ($( "#business_registration_phone" ).val().length === 0) {
                        $( "#business_registration_phone" ).val('(');
                    } else {
                        var val = $( "#business_registration_phone" ).val();
                        $( "#business_registration_phone" ).val('').val(val); // Ensure cursor remains at the end
                    }
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_email_first")){
                    document.getElementById("output_email1").className='output_email_default';
                    var reg_email1_var = document.getElementById("business_registration_email_first");

                    reg_email1_var.onblur = function() {
                        if(($('#business_registration_email_first').val())){
                            //$('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#business_registration_email_first').val());
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    }
                    
                    /*
                    document.getElementById("output_email1").className='output_email_default';
                    $( "#business_registration_email_first" ).unbind().keyup(function(e) {
                        //ENTRYdelay(function(){
                            if(($('#business_registration_email_first').val())){
                                //$('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                //$('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                                if(E_MGMT){ 
                                    document.getElementById("output_email1").className='output_email_correct';
                                    setTimeout(is_review_complete, 500);
                                }else{
                                    document.getElementById("output_email1").className='output_email_error';
                                }
                                if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                    document.getElementById("output_email1").className='output_email_correct';
                                    document.getElementById("output_email2").className='output_email_correct';
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            if(($('#business_registration_email_first').val().length === 0)){
                                document.getElementById("output_email1").className='output_email_default';
                                $('#standard_results_ajax_control').val('');
                                $('#standard_entity_modify_email').val('');
                                $('#standard_results_ajax_responseData').val('');
                            }
                        //}, 500 );
                    });
                    */
                    $('#business_registration_email_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_email_first','keypress');
                        if(($('#business_registration_email_first').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_email_first','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_PHONE = $('#business_registration_phone').val();
                        if($REG_PHONE.length>3){
                            business_phone_format(false);
                            CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                business_phone_validation_MGMT(false);
                                //Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* -------------------------------------------------------- */
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('business_registration_email_first','blur');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_email_first','keyup');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_email_first','change');
                        
                        if(($('#business_registration_email_first').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_email_second")){
                    document.getElementById("output_email2").className='output_email_default';
                    var reg_email2_var = document.getElementById("business_registration_email_second");

                    reg_email2_var.onblur = function() {
                        if(($('#business_registration_email_second').val())){
                            //$('#standard_entity_modify_control').val($('#business_registration_email_second').attr('id'));
                            //$('#standard_entity_modify_email').val($('#business_registration_email_second').val());
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    }
                    $('#business_registration_email_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_email_second','keypress');
                        if(($('#business_registration_email_second').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_email_second','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                //windowload_script('REGISTRATION_basics - $REG_UNAME');
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_PHONE = $('#business_registration_phone').val();
                        if($REG_PHONE.length>3){
                            business_phone_format(false);
                            CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                business_phone_validation_MGMT(false);
                                //Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('business_registration_email_second','blur');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_email_second','keyup');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_email_second','change');
                        
                        if(($('#business_registration_email_second').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                    
                    /*
                    document.getElementById("output_email2").className='output_email_default';
                    $( "#business_registration_email_second" ).unbind().keyup(function(e) {
                        // ENTRYdelay(function(){
                            if(($('#business_registration_email_second').val())){
                                $('#standard_entity_modify_control').val($('#business_registration_email_second').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_second').val());

                                //var E_MGMT = sys_email_check(document.getElementById("business_registration_email_second").value);
                                var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                                if(E_MGMT){ 
                                    document.getElementById("output_email2").className='output_email_correct';
                                    
                                    var $REG_EMAIL = $('#business_registration_email_first').val();
                                    CONSOLE_MANAGER('business_registration_email_second','keyup: '+$REG_EMAIL.length);
                                    if($REG_EMAIL.length>5){
                                        $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                        $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                        BUSINESS_Email_validation_MGMT(e);
                                        setTimeout(is_review_complete, 500);
                                    }
                                }else{
                                    document.getElementById("output_email2").className='output_email_error';
                                }
                                if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                    document.getElementById("output_email1").className='output_email_correct';
                                    document.getElementById("output_email2").className='output_email_correct';
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            if(($('#business_registration_email_second').val().length === 0)){
                                document.getElementById("output_email2").className='output_email_default';
                                $('#standard_results_ajax_control').val('');
                                $('#standard_entity_modify_email').val('');
                                $('#standard_results_ajax_responseData').val('');
                            }
                        //}, 500 );
                    });
                    */
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_password_first")){
                    var reg_pass1_var = document.getElementById("business_registration_password_first");	
                    reg_pass1_var.onfocus = function() {
                        //UPDATE_CLASS($("#output_pass1"), "output_password_default", ' ');
                    }
                    reg_pass1_var.onblur = function() {
                        var reg_pass1_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("business_registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_password_second').val($('#business_registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(P_MGMT == true){ 
                            if($("#output_pass1.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass1"), "output_password_correct", " ");
                                setTimeout(is_review_complete, 500);
                            }
                        }else{
                            if (document.getElementById("business_registration_password_first").value=='') {
                            }
                            if (document.getElementById("business_registration_password_first").value!='') {
                                if( $("#output_pass1.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass1"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#business_registration_password_first').val(),$('#business_registration_password_second').val());
                    }
                    $('#business_registration_password_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_password_first','keypress');
                        trigg_pass_match();
                        checkVal("output_pass1");
                        //checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_password_first','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_password_first','keyup');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        //outputResult("output_pass2"); 
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_password_first','change');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_password_second")){
                    var reg_pass2_var = document.getElementById("business_registration_password_second");
                    reg_pass2_var.onfocus = function() {
                    }
                    reg_pass2_var.onblur = function() {
                        var reg_pass2_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("business_registration_password_second").value);
                        if(P_MGMT){ 
                            if($("#output_pass2.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass2"), "output_password_correct", " ");
                            }
                            trigg_pass_match();
                            setTimeout(is_review_complete, 500);
                        }else{
                            if (document.getElementById("business_registration_password_second").value=='') {
                            }
                            if (document.getElementById("business_registration_password_second").value!='') {
                                if( $("#output_pass2.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass2"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#business_registration_password_first').val(),$('#business_registration_password_second').val());
                    }
                    $('#business_registration_password_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_password_second','keypress');
                        trigg_pass_match();
                        //checkVal("output_pass1");
                        checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_password_second','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_password_second','keyup');
                        trigg_pass_match();
                        //outputResult("output_pass1");
                        outputResult("output_pass2"); 
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_password_second','change');
                        trigg_pass_match();
                        outputResult("output_pass2");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options4")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options4'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options4active")){
                    $('#business_registration_options4active').unbind().click(function(event) {
                        var $tempClass = $('#business_registration_options4active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#business_registration_options4active').removeClass($tempClass);
                       	    $('#business_registration_options4active').addClass('reg_check_button_active');
                            $('#business_registration_termsaccepted').val(htmlEscape("ACCEPTED"));
                            $("#business_registration_formFilter7").val(true);
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#business_registration_options4active').removeClass($tempClass);
                       	    $('#business_registration_options4active').addClass('reg_check_button_default');
                            $('#business_registration_termsaccepted').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options5")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options5'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options5active")){
                    $('#business_registration_options5active').unbind().click(function(event) {
                        var $tempClass = $('#business_registration_options5active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#business_registration_options5active').removeClass($tempClass);
                       	    $('#business_registration_options5active').addClass('reg_check_button_active');
                            $('#business_registration_privacypolicy').val(htmlEscape("ACCEPTED"));
                            $("#business_registration_formFilter6").val(true);
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#business_registration_options5active').removeClass($tempClass);
                       	    $('#business_registration_options5active').addClass('reg_check_button_default');
                            $('#business_registration_privacypolicy').val(htmlEscape("DENIED"));
                            
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
            }
            /*  END PROFILE CONTROL */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var complexity_pass1 = $("#output_pass1");
			var complexity_pass2 = $("#output_pass2");
			var strPassword;
			var charPassword;
			var minPasswordLength = 8;
			var baseScore = 0, score = 0;
			var num = {};
			num.Excess = 0;
			num.Upper = 0;
			num.Numbers = 0;
			num.Symbols = 0;
			var bonus = {};
			bonus.Excess = 3;
			bonus.Upper = 4;
			bonus.Numbers = 5;
			bonus.Symbols = 5;
			bonus.Combo = 0; 
			bonus.FlatLower = 0;
			bonus.FlatNumber = 0;
            /* --------------------------------------------------------  */
            if($("#registration_password_first").val()){
               outputResult("output_pass1");
            }
            if($("#business_registration_password_first").val()){
                outputResult("output_pass1"); 
            }	
			/* --------------------------------------------------------  */
            if($("#registration_password_second").val()){
               outputResult("output_pass2"); 
            }
            if($("#business_registration_password_second").val()){
                outputResult("output_pass2");
            }
            /* --------------------------------------------------------  */
			$("#registration_password_first").bind("keyup change", function(e) {
                if($("#registration_password_first").val()){
                   checkVal("output_pass1"); 
                }				
			})
			$("#registration_password_second").bind("keyup change", function(e) {
                if($("#registration_password_second").val()){
				    checkVal("output_pass2"); 
                }
			})
            $("#business_registration_password_first").bind("keyup change", function(e) {
                if($("#business_registration_password_first").val()){
                   checkVal("output_pass1"); 
                }				
			})
			$("#business_registration_password_second").bind("keyup change", function(e) {
                if($("#business_registration_password_second").val()){
				    checkVal("output_pass2"); 
                }
			})
			/*************/
			function checkVal(selection)
			{
				if(selection =="output_pass1"){
					init("output_pass1");
				}
				if(selection =="output_pass2"){
					init("output_pass2");
				}
				if(charPassword){
					if (charPassword.length >= minPasswordLength)
					{
						baseScore = 50;	
						analyzeString();	
						calcComplexity();		
					}
				}else
				{
					baseScore = 0;
				}
				outputResult(selection);
			}
			/*************/
			function init(selection)
			{
				if(selection =="output_pass1"){
                    if($("#registration_password_first").val()){
                        strPassword= $("#registration_password_first").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
				if(selection =="output_pass2"){
                    if($("#registration_password_second").val()){
                        strPassword= $("#registration_password_second").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
                if(selection =="output_pass1"){
                    if($("#business_registration_password_first").val()){
                        strPassword= $("#business_registration_password_first").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
				if(selection =="output_pass2"){
                    if($("#business_registration_password_second").val()){
                        strPassword= $("#business_registration_password_second").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
			}
			/*************/
			function analyzeString ()
			{	
				for (var i=0; i<charPassword.length;i++)
				{
					if (charPassword[i].match(/[A-Z]/g)) {num.Upper++;}
					if (charPassword[i].match(/[0-9]/g)) {num.Numbers++;}
					if (charPassword[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) {num.Symbols++;} 
				}
				num.Excess = charPassword.length - minPasswordLength;
				if (num.Upper && num.Numbers && num.Symbols)
				{
					bonus.Combo = 25; 
				}
				else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols))
				{
					bonus.Combo = 15; 
				}
				if (strPassword.match(/^[\sa-z]+$/))
				{ 
					bonus.FlatLower = -15;
				}
				if (strPassword.match(/^[\s0-9]+$/))
				{ 
					bonus.FlatNumber = -35;
				}
			}
			/*************/
			function calcComplexity()
			{
				score = baseScore + (num.Excess*bonus.Excess) + (num.Upper*bonus.Upper) + (num.Numbers*bonus.Numbers) + (num.Symbols*bonus.Symbols) + bonus.Combo + bonus.FlatLower + bonus.FlatNumber;
			}	
			/*************/
			function outputResult(selection)
			{
                /* --------------------------------------------------------  */
				if(selection =='output_pass1'){
                    /* --------------------------------------------------------  */
					if ($("#registration_password_first").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass1, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass1, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass1, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass1, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass1, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass1, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
                    /* --------------------------------------------------------  */
                    if ($("#business_registration_password_first").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass1, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass1, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass1, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass1, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass1, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass1, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
				}
                /* --------------------------------------------------------  */
				if(selection =='output_pass2'){
                    /* --------------------------------------------------------  */
					if ($("#registration_password_second").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass2, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass2, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;	
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass2, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass2, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass2, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass2, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
                    /* --------------------------------------------------------  */
                    if ($("#business_registration_password_second").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass2, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass2, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;	
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass2, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass2, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass2, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass2, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
				}
                setTimeout(is_review_complete, 500);
			}
			/*************/
			function UPDATE_CLASS(element_var, add_class, new_text){
				element_var.removeClass("default weak strong stronger strongest output_password_default output_password_correct ");
				element_var.removeClass("output_password_error output_match_error_top output_match_error_bottom");
				element_var.addClass(add_class);
				element_var.html(new_text);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("registration_options2")){
				var reset_button = document.getElementById("registration_options2");
				SET_BUTTON_CLICK_ACTION("registration_options2");
				reset_button.addEventListener("click", function(){	
				}, false);
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("registration_options1")){
				var submit_button = document.getElementById("registration_options1");
				SET_BUTTON_CLICK_ACTION("registration_options1");
                $('#registration_options1').unbind().click(function(event) {
                    if($("#output_username").hasClass("output_username_default") ||$("#output_username").hasClass("output_username_error")){
                        $("#registration_username").val('');
                        $("#business_registration_title").val('');
                    }
                    if($("#output_email1").hasClass("output_email_default") ||$("#output_email1").hasClass("output_email_error")){
                        $("#registration_email_first").val('');
                        $("#business_registration_email_first").val('');
                    }
                    if($("#output_email2").hasClass("output_email_default") ||$("#output_email2").hasClass("output_email_error")){
                        $("#registration_email_second").val('');
                        $("#business_registration_email_second").val('');
                    }
				});
			}
            /* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options1")){
				var submit_button = document.getElementById("business_registration_options1");
				SET_BUTTON_CLICK_ACTION("business_registration_options1");
                $('#business_registration_options1').unbind().click(function(event) {
                    if($("#output_username").hasClass("output_username_default") ||$("#output_username").hasClass("output_username_error")){
                        $("#registration_username").val('');
                        $("#business_registration_title").val('');
                    }
                    if($("#output_email1").hasClass("output_email_default") ||$("#output_email1").hasClass("output_email_error")){
                        $("#registration_email_first").val('');
                        $("#business_registration_email_first").val('');
                    }
                    if($("#output_email2").hasClass("output_email_default") ||$("#output_email2").hasClass("output_email_error")){
                        $("#registration_email_second").val('');
                        $("#business_registration_email_second").val('');
                    }
				});
			}
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            function is_review_complete(){
                //CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                CONSOLE_MANAGER('REGISTRATION_basics',$controlString+' is_review_complete --------------    ACTIVE     ----------');
                /* --------------------------------------------------------  */ 
                /* --------------------------------------------------------  */
                if ($controlString.indexOf("PROFILE_TYPE") >= 0){//translate_ajax_data
                    /* --------------------------------------------------------  */ 
                    if($("#output_username").hasClass("output_username_correct")){
                        $("#registration_formFilter1").val(true);
                    }else{
                        $("#registration_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#phone_field_check").hasClass("ls_form_half_field_check_positive")){
                        $("#registration_formFilter2").val(true);
                    }else{
                        $("#registration_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#email_field_check").hasClass("ls_form_full_field_check_positive")){
                        $("#registration_formFilter3").val(true);
                    }else{
                        $("#registration_formFilter3").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_index1").val()){
                        $("#registration_formFilter4").val(true);
                    }else{
                        $("#registration_formFilter4").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_privacypolicy").val()=='ACCEPTED'){
                        $("#registration_formFilter5").val(true);
                    }else{
                        $("#registration_formFilter5").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_termsaccepted").val()=='ACCEPTED'){
                        $("#registration_formFilter6").val(true);
                    }else{
                        $("#registration_formFilter6").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass1").hasClass("output_password_correct")){
                        $("#registration_formFilter7").val(true);
                    }else{
                        $("#registration_formFilter7").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass2").hasClass("output_password_correct")){
                        $("#registration_formFilter8").val(true);
                    }else{
                        $("#registration_formFilter8").val(null);
                    }
                    CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-1 (USERNAME): '+$("#registration_formFilter1").val()+' - 2 (PHONE): '+$("#registration_formFilter2").val()+' - 3 (EMAIL): '+$("#registration_formFilter3").val()); 
                    CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-4 (BIRTHDAY): '+$("#registration_formFilter4").val()+' -5 (PRIVACY): '+$("#registration_formFilter5").val()+' -6 (TERMS): '+$("#registration_formFilter6").val()+' -7 (PASS1): '+$("#registration_formFilter7").val()+' -8 (PASS2): '+$("#registration_formFilter8").val());
                    /* --------------------------------------------------------  */ 
                    if(($("#registration_formFilter1").val())&&($("#registration_formFilter2").val())&&
                       ($("#registration_formFilter3").val())&&($("#registration_formFilter4").val())&&
                       ($("#registration_formFilter5").val())&&($("#registration_formFilter6").val())&&
                       ($("#registration_formFilter7").val())&&($("#registration_formFilter8").val())
                      ){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ----------');
                        $('#registration_options1').attr('disabled',false);
                        $('#registration_options1').removeClass('ls_form_submit_button');
                       	$('#registration_options1').addClass('ls_form_submit_button_active');
                        SET_BUTTON_CLICK_ACTION("registration_options1");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options1'),null,null,null,null);
                        $('#standard_entity_modify_control').val($('#registration_options1').attr('id'));
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ----------');
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#registration_formFilter1").val()+' - 2: '+$("#registration_formFilter2").val()+' - 3: '+$("#registration_formFilter3").val());
                        
                        CONSOLE_MANAGER('is_review_complete','-4: '+$("#registration_formFilter4").val()+' -PRIVACY: '+$("#registration_formFilter5").val()+' -TERMS: '+$("#registration_formFilter6").val()+' -PASS1: '+$("#registration_formFilter7").val()+' -PASS2: '+$("#registration_formFilter8").val());
                        $('#registration_options1').attr('disabled',true); 
                        $('#registration_options1').removeClass('ls_form_submit_button_active');
                       	$('#registration_options1').addClass('ls_form_submit_button');
                        
                    }
                }
                /* --------------------------------------------------------  */ 
                /* --------------------------------------------------------  */
                if ($controlString.indexOf("BUSINESS_TYPE") >= 0){
                    /* --------------------------------------------------------  */ 
                    if($("#output_username").hasClass("output_title_correct")){
                        $("#business_registration_formFilter1").val(true);
                    }else{
                        $("#business_registration_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#phone_field_check").hasClass("ls_form_half_field_check_positive")){
                        $("#business_registration_formFilter2").val(true);
                    }else{
                        $("#business_registration_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#email_field_check").hasClass("ls_form_full_field_check_positive")){
                        $("#business_registration_formFilter3").val(true);
                    }else{
                        $("#business_registration_formFilter3").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_options3").val()){
                        $("#business_registration_formFilter4").val(true);
                    }else{
                        $("#business_registration_formFilter4").val(null);
                    }
                    /* --------------------------------------------------------  
                    if (($('#business_registration_address').val())&&($('#business_registration_city').val())&&($('#business_registration_state').val())&&($('#business_registration_country').val())) {
                        $("#business_registration_formFilter5").val(true);
                    }else{
                        $("#business_registration_formFilter5").val(null);
                    }
                    CONSOLE_MANAGER('is_review_complete','-address: '+$('#business_registration_address').val()+' - city: '+$('#business_registration_city').val()+' - state: '+$('#business_registration_state').val());
                    */ 
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_privacypolicy").val()=='ACCEPTED'){
                        $("#business_registration_formFilter6").val(true);
                    }else{
                        $("#business_registration_formFilter6").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_termsaccepted").val()=='ACCEPTED'){
                        $("#business_registration_formFilter7").val(true);
                    }else{
                        $("#business_registration_formFilter7").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass1").hasClass("output_password_correct")){
                        $("#business_registration_formFilter8").val(true);
                    }else{
                        $("#business_registration_formFilter8").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass2").hasClass("output_password_correct")){
                        $("#business_registration_formFilter9").val(true);
                    }else{
                        $("#business_registration_formFilter9").val(null);
                    }
                    /* --------------------------------------------------------  
                    ($("#business_registration_formFilter5").val())&&
                    */
                    CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-1 (TITLE): '+$("#business_registration_formFilter1").val()+' - 2 (PHONE): '+$("#business_registration_formFilter2").val()+' - 3 (EMAIL): '+$("#business_registration_formFilter3").val()); 
                    CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-4 (BUSINESS TYPE): '+$("#business_registration_formFilter4").val()+' -5 (PRIVACY): '+$("#business_registration_formFilter6").val()+' -6 (TERMS): '+$("#business_registration_formFilter7").val()+' -7 (PASS1): '+$("#business_registration_formFilter8").val()+' -8 (PASS2): '+$("#business_registration_formFilter9").val());
                    if($("#business_registration_formFilter1").val()&&$("#business_registration_formFilter2").val()&&
                       $("#business_registration_formFilter3").val()&&$("#business_registration_formFilter4").val()&&
                       $("#business_registration_formFilter6").val()&&
                       $("#business_registration_formFilter7").val()&&$("#business_registration_formFilter8").val()&&
                       $("#business_registration_formFilter9").val()
                      ){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ----------');
                        $('#business_registration_options1').attr('disabled',false);
                        $('#business_registration_options1').removeClass('ls_form_submit_button');
                       	$('#business_registration_options1').addClass('ls_form_submit_button_active');
                        //SET_BUTTON_CLICK_ACTION("business_registration_options1");
                        //CONFIRM_MGMT('registration_options1','.Registration_UI_class');
                        SET_BUTTON_CLICK_ACTION("business_registration_options1");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options1'),null,null,null,null);
                        $('#standard_entity_modify_control').val($('#business_registration_options1').attr('id'));
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ----------');
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#business_registration_formFilter1").val()+' - 2: '+$("#business_registration_formFilter2").val()+' - 3: '+$("#business_registration_formFilter3").val());
                        
                        CONSOLE_MANAGER('is_review_complete','-4: '+$("#business_registration_formFilter4").val()+' -5: '+$("#business_registration_formFilter5").val()+' -PRIVACY: '+$("#business_registration_formFilter6").val()+' -TERMS: '+$("#business_registration_formFilter7").val()+' -PASS1: '+$("#business_registration_formFilter8").val()+' -PASS2: '+$("#business_registration_formFilter9").val());
                        $('#business_registration_options1').removeClass('ls_form_submit_button_active');
                       	$('#business_registration_options1').addClass('ls_form_submit_button');
                        $('#business_registration_options1').attr('disabled',true); 
                    }
                }
            }
            /* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
        $('#registration_options1').attr('disabled',true);
        $('#business_registration_options1').attr('disabled',true);
        $("#registration_username").attr("autocomplete", "off");
        $("#registration_username").val(null);
        $("#registration_username").html(null);
        $("#registration_firstName").attr("autocomplete", "off");
        $("#registration_firstName").val(null);
        $("#registration_firstName").html(null);
        $("#registration_lastName").attr("autocomplete", "off");
        $("#registration_lastName").val(null);
        $("#registration_lastName").html(null);
        $("#registration_phone").attr("autocomplete", "off");
        $("#registration_phone").val(null);
        $("#registration_phone").html(null);
        $("#registration_index1").attr("autocomplete", "off");
        $("#registration_index1").val(null);
        $("#registration_index1").html(null);
        $("#registration_email_first").attr("autocomplete", "off");
        $("#registration_email_first").val(null);
        $("#registration_email_first").html(null);
        $("#registration_email_second").attr("autocomplete", "off");
        $("#registration_email_second").val(null);
        $("#registration_email_second").html(null);
        $("#registration_password_first").attr("autocomplete", "off");
        $("#registration_password_first").val(null);
        $("#registration_password_first").html(null);
        $("#registration_password_second").attr("autocomplete", "off");
        $("#registration_password_second").val(null);
        $("#registration_password_second").html(null);

        $("#registration_address").attr("autocomplete", "off");
        $("#registration_address").val(null);
        $("#registration_address").html(null);
        //$('#registration_address').attr('disabled',true);
        $("#registration_address2").attr("autocomplete", "off");
        $("#registration_address2").val(null);
        $("#registration_address2").html(null);
        //$('#registration_address2').attr('disabled',true);
        $("#registration_city").attr("autocomplete", "off");
        $("#registration_city").val(null);
        $("#registration_city").html(null);
        //$('#registration_city').attr('disabled',true);
        $("#registration_state").attr("autocomplete", "off");
        $("#registration_state").val(null);
        $("#registration_state").html(null);
        //$('#registration_state').attr('disabled',true);
        $("#registration_state").attr("autocomplete", "off");
        $("#registration_state").val(null);
        $("#registration_state").html(null);
        //$('#registration_state').attr('disabled',true);
        $("#registration_postal").attr("autocomplete", "off");
        $("#registration_postal").val(null);
        $("#registration_postal").html(null);
        
        $("#business_registration_title").attr("autocomplete", "off");
        $("#business_registration_title").val(null);
        $("#business_registration_title").html(null);
        $("#business_registration_phone").attr("autocomplete", "off");
        $("#business_registration_phone").val(null);
        $("#business_registration_phone").html(null);
        $("#business_registration_options3").attr("autocomplete", "off");
        $("#business_registration_options3").val(null);
        $("#business_registration_options3").html(null);
        $("#business_registration_password_first").attr("autocomplete", "off");
        $("#business_registration_password_first").val(null);
        $("#business_registration_password_first").html(null);
        $("#business_registration_password_second").attr("autocomplete", "off");
        $("#business_registration_password_second").val(null);
        $("#business_registration_password_second").html(null);
        $("#business_registration_email_first").attr("autocomplete", "off");
        $("#business_registration_email_first").val(null);
        $("#business_registration_email_first").html(null);
        $("#business_registration_email_second").attr("autocomplete", "off");
        $("#business_registration_email_second").val(null);
        $("#business_registration_email_second").html(null);
        $("#business_registration_address").attr("autocomplete", "off");
        $("#business_registration_address").val(null);
        $("#business_registration_address").html(null);
        $("#business_registration_address2").attr("autocomplete", "off");
        $("#business_registration_address2").val(null);
        $("#business_registration_address2").html(null);
        $("#business_registration_city").attr("autocomplete", "off");
        $("#business_registration_city").val(null);
        $("#business_registration_city").html(null);
        $("#business_registration_state").attr("autocomplete", "off");
        $("#business_registration_state").val(null);
        $("#business_registration_state").html(null);
        $("#business_registration_country").attr("autocomplete", "off");
        $("#business_registration_country").val(null);
        $("#business_registration_country").html(null);
		/* --------------------------------------------------------  */
		window_complete_script('REGISTRATION_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function  REGISTRATION_SOCIAL_basics(){
	
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$('.Registration_UI_class').attr("id", "Registration_UI_control");
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//SET_BUTTON_CLICK_ACTION("Security_form1_options1");
			//SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            //setPrivacypolicy setProfileType
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var PASS_VALIDATE = false;
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
            SET_BUTTON_CLICK_ACTION("Security_form1_options1");
            $("#label_Security_form1_options1").unbind().click(function(){
                //$("#Security_form1_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
            SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            $("#label_Security_form2_options1").unbind().click(function(){
                //$("#Security_form2_options1").click();
            });
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_registration_options_control1");
            SET_BUTTON_CLICK_ACTION("registration_options_control1");
            $("#label_registration_options_control1").unbind().click(function(){
                //$("#registration_options_control1").click();
            });
            SET_BUTTON_CLICK_ACTION("label_registration_options_control2");
            SET_BUTTON_CLICK_ACTION("registration_options_control2");
            $("#label_registration_options_control2").unbind().click(function(){
                //$("#registration_options_control2").click();
            });
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('photo_overlay');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($('#standard_entity_modify_control').val()){
                                CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                //var $TEMP_CONTROL = $('#standard_results_ajax_control').val();
                                var $RESULTS_CNTRL = null; 
                                var $SEARCH_CNTRL = null;
                                $RESULTS_CNTRL = $('#standard_entity_modify_control').val();
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',mutation.type+': attributes');
                                    CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',$('#registration_index1').attr('id')+': -----  ACTIVE  ------'+' - '+$RESULTS_CNTRL);
                                    /* -------------------------------------------------------- */
                                    if($RESULTS_CNTRL == $('#registration_index1').attr('id')){
                                        birthday_request_function();
                                        setTimeout(is_review_complete, 500);
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    /* -------------------------------------------------------- 
                                    if($RESULTS_CNTRL == $('#registration_index2').attr('id')){
                                        gender_ajax();
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    */
                                    /* -------------------------------------------------------- */
                                    if($RESULTS_CNTRL == $('#business_registration_options3').attr('id')){
                                        business_type_select();
                                        setTimeout(is_review_complete, 500);
                                        $('#standard_entity_modify_control').val(null)
                                    }
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                     CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            var $controlString = 'false';
            if($('#business_registration_profileType').length){
                $controlString = $('#business_registration_profileType').val(); 
            }
            if($('#registration_profileType').length){
                $controlString = $('#registration_profileType').val();  
            }
            CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',$controlString+' ACTIVATED');
            $('#registration_options1').attr('disabled',true);
            $('#business_registration_options1').attr('disabled',true);
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            if($controlString.indexOf("PROFILE_TYPE") >= 0){
                 
                //$("#registration_username").attr("autocomplete", "off");
                //$("#registration_username").val(null);
                //$("#registration_username").html(null);
                //$("#registration_firstName").attr("autocomplete", "off");
                //$("#registration_firstName").val(null);
                //$("#registration_firstName").html(null);
                //$("#registration_lastName").attr("autocomplete", "off");
                //$("#registration_lastName").val(null);
                //$("#registration_lastName").html(null);
                $("#registration_phone").attr("autocomplete", "off");
                $("#registration_phone").val(null);
                $("#registration_phone").html(null);
                $("#registration_index1").attr("autocomplete", "off");
                $("#registration_index1").val(null);
                $("#registration_index1").html(null);
                //$("#registration_email_first").attr("autocomplete", "off");
                //$("#registration_email_first").val(null);
                //$("#registration_email_first").html(null);
                $("#registration_email_second").attr("autocomplete", "off");
                $("#registration_email_second").val(null);
                $("#registration_email_second").html(null);
                $("#registration_password_first").attr("autocomplete", "off");
                $("#registration_password_first").val(null);
                $("#registration_password_first").html(null);
                $("#registration_password_second").attr("autocomplete", "off");
                $("#registration_password_second").val(null);
                $("#registration_password_second").html(null);
                
                $("#registration_address").attr("autocomplete", "off");
                $("#registration_address").val(null);
                $("#registration_address").html(null);
                //$('#registration_address').attr('disabled',true);
                $("#registration_address2").attr("autocomplete", "off");
                $("#registration_address2").val(null);
                $("#registration_address2").html(null);
                //$('#registration_address2').attr('disabled',true);
                $("#registration_city").attr("autocomplete", "off");
                $("#registration_city").val(null);
                $("#registration_city").html(null);
                //$('#registration_city').attr('disabled',true);
                $("#registration_state").attr("autocomplete", "off");
                $("#registration_state").val(null);
                $("#registration_state").html(null);
                //$('#registration_state').attr('disabled',true);
                $("#registration_state").attr("autocomplete", "off");
                $("#registration_state").val(null);
                $("#registration_state").html(null);
                //$('#registration_state').attr('disabled',true);
                $("#registration_postal").attr("autocomplete", "off");
                $("#registration_postal").val(null);
                $("#registration_postal").html(null);
                //$('#registration_postal').attr('disabled',true);
                //$("#registration_firstName").focus();
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_username")){
                    var reg_titlename_var = document.getElementById("registration_username");
                    /* --------------------------------------------------------  */
                    reg_titlename_var.onfocus = function() {
                        /* --------------------------------------------------------  */
                        $( "#registration_username" ).unbind().keyup(function(e) {
                            /* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("registration_username").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#registration_username').val();
                                $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#registration_username').val();
                            var string = document.getElementById("registration_username").value;
                            reg_titlename_var.onblur = function() {
                                var string = document.getElementById("registration_username").value;
                                var result = string.replace(/^\s+|\s+$/gm,'');
                                $('#registration_username').val(result);
                                if($REG_UNAME.length>2){
                                    if(($('#registration_username').val().length>0)){
                                        $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                        $('#standard_entity_modify_title').val($('#registration_username').val());
                                        $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                        //Username_validation_MGMT(false);
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if(!($('#registration_username').val())){
                                        UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                        setTimeout(is_review_complete, 500);
                                    }
                                }
                            }
                        });
                        $('#registration_username').on('keypress', function(e) {
                            CONSOLE_MANAGER('registration_username','keypress');
                            var $REG_UNAME = $('#registration_username').val();
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('focus', function() {
                            CONSOLE_MANAGER('registration_username','focus');
                            setTimeout(is_review_complete, 500);
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $REG_UNAME = $('#registration_username').val();
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Username_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_phone").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_phone').val(result);
                            var $REG_PHONE = $('#registration_phone').val();
                            if($REG_PHONE.length>3){
                                phone_format(false);
                                CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                                if($REG_PHONE.length>13){
                                    $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                    $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Phone_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var string = document.getElementById("registration_email_first").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_email_first').val(result);
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#registration_email_second').val($('#registration_email_first').val())
							/* --------------------------------------------------------  */
                            var string = document.getElementById("registration_email_second").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_email_second').val(result);
                            var $REG_EMAIL1 = $('#registration_email_first').val();
                            var $REG_EMAIL2 = $('#registration_email_second').val();
                            CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                            if($REG_EMAIL1==$REG_EMAIL2){
                                if($REG_EMAIL1.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var $REG_FIRST_NAME = $('#registration_firstName').val();
                            if($REG_FIRST_NAME.length>1){
                                setTimeout(is_review_complete, 500);
                            }
                            /* --------------------------------------------------------  */
                            var $REG_LAST_NAME = $('#registration_lastName').val();
                            if($REG_LAST_NAME.length>1){
                                setTimeout(is_review_complete, 500);
                            }
                            /* --------------------------------------------------------  */
                        })
                        .on('blur', function() {
                            CONSOLE_MANAGER('registration_username','blur');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('keyup', function() {
                            CONSOLE_MANAGER('registration_username','keyup');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('change', function() {
                            CONSOLE_MANAGER('registration_username','change');
                            var string = document.getElementById("registration_username").value;
                            var result = string.replace(/^\s+|\s+$/gm,'');
                            $('#registration_username').val(result);
                            var $STRING=$('#registration_username').val();
                            $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                            var $REG_UNAME = $('#registration_username').val();
                            if($REG_UNAME.length>2){
                                if(($('#registration_username').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                    $('#standard_entity_modify_title').val($('#registration_username').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Username_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#registration_username').val())){
                                    UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        });
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options2")){
                    SET_BUTTON_CLICK_ACTION("registration_options2");
                    $('#registration_options2').unbind().click(function(event) {
                        $('#standard_entity_modify_control').val($('#registration_options2').attr('id'));
                        settings_request_function(event);
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* AJAX LISTENERS  (MutationObserver) ---------------------  */
                var MODIFY_VIEW_LISTENER = (function (){
                    var target = document.getElementById('standard_results_ajax_control');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */	
                    var observer = new MutationObserver(function(mutations) {
                      mutations.forEach(function(mutation) {
                        if($("#standard_results_ajax_control").val()){  
                            CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            var $RESULTS_CNTRL = null;
                            var $SEARCH_CNTRL = null;
                            if($('#standard_results_ajax_control').val()){
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                if($('#search_request_control').length){
                                   $SEARCH_CNTRL = $('#search_request_control').val();
                                }
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',mutation.type+': attributes');
                                    /* -------------------------------------------------------- */
                                    //var $TEMP_VAL = $('#standard_results_ajax_control').val();
                                    if( $RESULTS_CNTRL == $('#registration_username').attr('id')){
                                        username_ajax();
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if( $RESULTS_CNTRL == $('#registration_email_first').attr('id')){
                                        email_ajax();
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_full_field_check_positive
                                                ls_form_full_field_check_negative
                                            */
                                            $("#email_field_check").removeClass('any_visuallyhidden');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('ls_form_full_field_check_positive');
                                        }else{
                                            $("#email_field_check").removeClass('ls_form_full_field_check_positive');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('any_visuallyhidden');
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if( $RESULTS_CNTRL == $('#registration_phone').attr('id')){
                                        phone_format(true);
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_half_field_check_positive
                                                ls_form_half_field_check_negative
                                            */
                                            $("#phone_field_check").removeClass('any_visuallyhidden');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('ls_form_half_field_check_positive');
                                        }else{
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_positive');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('any_visuallyhidden');  
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    //$("#standard_results_ajax_control").val('');
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('REGISTRATION_SOCIAL_basics',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        }
                      });    
                    });//END new MutationObserver
                    var config = { attributes: true, childList: true, characterData: true };
                    observer.observe(target, config);
                })();/*END FUNCTION */
                /* --------------------------------------------------------  */
                function username_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#registration_username').attr('id')){
                        if($('#standard_results_ajax_responseData').val()){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_username_correct', '');
                        }else{
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_username_error', '');
                        }
                        setTimeout(is_review_complete, 500);
                        $('#standard_results_ajax_control').val('');
                        $('#standard_entity_modify_username').val('');
                        $('#standard_results_ajax_responseData').val('');
                    }
                }
                /* --------------------------------------------------------  */
                function email_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#registration_email_first').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email1").className='output_email_correct';			
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                        }
                    }
                    else if($('#standard_results_ajax_control').val() == $('#registration_email_second').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email2").className='output_email_correct';			
                        }else{
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    if(($('#registration_email_first').val()) && ($('#registration_email_second').val())){
                        var mtch = sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());

                        if(mtch){
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    setTimeout(is_review_complete, 500);
                    $('#standard_results_ajax_control').val('');
                    $('#standard_entity_modify_email').val('');
                    $('#standard_results_ajax_responseData').val('');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("registration_firstName")){
                    var register_reg_firstname_var = document.getElementById("registration_firstName");
                }*/
                $('#registration_firstName').on('keypress', function(e) {
                    CONSOLE_MANAGER('registration_firstName','keypress');
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('registration_firstName','focus');
                    setTimeout(is_review_complete, 500);
                    var string = document.getElementById("registration_firstName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_firstName').val(result);
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_username").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_username').val(result);
                    var $REG_UNAME = $('#registration_username').val();
                    var $STRING=$('#registration_username').val();
                    $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                    if($REG_UNAME.length>2){
                        if(($('#registration_username').val().length>0)){
                            $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                            $('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Username_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        if(!($('#registration_username').val())){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_phone").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_phone').val(result);
                    var $REG_PHONE = $('#registration_phone').val();
                    if($REG_PHONE.length>3){
                        phone_format(false);
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_first").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_first').val(result);
					/* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_email_second').val($('#registration_email_first').val())
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_second").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_second').val(result);
                    var $REG_EMAIL1 = $('#registration_email_first').val();
                    var $REG_EMAIL2 = $('#registration_email_second').val();
                    CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                    if($REG_EMAIL1==$REG_EMAIL2){
                        if($REG_EMAIL1.length>5){
                            $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            $('#standard_entity_modify_email').val($('#registration_email_first').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Email_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_firstName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_firstName').val(result);
                    var $REG_FIRST_NAME = $('#registration_firstName').val();
                    if($REG_FIRST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_lastName").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_lastName').val(result);
                    var $REG_LAST_NAME = $('#registration_lastName').val();
                    if($REG_LAST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                });
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("registration_lastName")){
                    var register_reg_lastname_var = document.getElementById("registration_lastName");
                }*/
                $('#registration_lastName').on('keypress', function(e) {
                    CONSOLE_MANAGER('registration_lastName','keypress');
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('registration_lastName','focus');
                    setTimeout(is_review_complete, 500);
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_username").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_username').val(result);
                    var $REG_UNAME = $('#registration_username').val();
                    var $STRING=$('#registration_username').val();
                    $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                    if($REG_UNAME.length>2){
                        if(($('#registration_username').val().length>0)){
                            $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                            $('#standard_entity_modify_title').val($('#registration_username').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Username_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        if(!($('#registration_username').val())){
                            UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_phone").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_phone').val(result);
                    var $REG_PHONE = $('#registration_phone').val();
                    if($REG_PHONE.length>3){
                        phone_format(false);
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_first").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_first').val(result);
					/* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_email_second').val($('#registration_email_first').val())
                    /* --------------------------------------------------------  */
                    var string = document.getElementById("registration_email_second").value;
                    var result = string.replace(/^\s+|\s+$/gm,'');
                    $('#registration_email_second').val(result);
                    var $REG_EMAIL1 = $('#registration_email_first').val();
                    var $REG_EMAIL2 = $('#registration_email_second').val();
                    CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                    if($REG_EMAIL1==$REG_EMAIL2){
                        if($REG_EMAIL1.length>5){
                            $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            $('#standard_entity_modify_email').val($('#registration_email_first').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Email_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    }
                    /* --------------------------------------------------------  */
                    var $REG_FIRST_NAME = $('#registration_firstName').val();
                    if($REG_FIRST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                    var $REG_LAST_NAME = $('#registration_lastName').val();
                    if($REG_LAST_NAME.length>1){
                        setTimeout(is_review_complete, 500);
                    }
                    /* --------------------------------------------------------  */
                });
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_phone")){
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#registration_phone').on('change', function(e) {
                        CONSOLE_MANAGER('registration_phone','change');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(true);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            phone_format(true);
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_phone','keypress');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        phone_format(true);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('focus', function(e) {
                        CONSOLE_MANAGER('registration_phone','focus');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function(e) {
                        CONSOLE_MANAGER('registration_phone','blur');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#registration_phone').val();
                        CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                            $('#standard_entity_modify_phone').val($('#registration_phone').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            Phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                        
                    })
                    .on('keyup', function(e) {
                        CONSOLE_MANAGER('registration_phone','keyup');
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        phone_format(false);
                        setTimeout(is_review_complete, 500);
                    });
                }
                function phone_format(e){
                    CONSOLE_MANAGER('phone_format','----------------------------');
                    var $text = $( "#registration_phone" ).val();
                    $text = $text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    $( "#registration_phone" ).val($text);
                    
                    if ($( "#registration_phone" ).val().length === 0) {
                        $( "#registration_phone" ).val('(');
                    } else {
                        var val = $( "#registration_phone" ).val();
                        $( "#registration_phone" ).val('').val(val); // Ensure cursor remains at the end
                    }
                    //sys_phone_check
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_index1")){
                    SET_BUTTON_CLICK_ACTION("registration_index1");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_index1'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#registration_index1').attr('id'));
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_index2")){
                    SET_BUTTON_CLICK_ACTION("registration_index2");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_index2'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#registration_index2').attr('id'));
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_email_first")){
                    document.getElementById("output_email1").className='output_email_default';
                    var reg_email1_var = document.getElementById("registration_email_first");

                    reg_email1_var.onblur = function() {
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            //$('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#registration_email_first').val());
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                        
                    }
                    $('#registration_email_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_email_first','keypress');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_email_first','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        var $REG_PHONE = $('#registration_phone').val();
                        if($REG_PHONE.length>3){
                            phone_format(false);
                            CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('registration_email_first','blur');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_email_first','keyup');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_email_first','change');
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        if(($('#registration_email_first').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_first").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_email_second")){
                    document.getElementById("output_email2").className='output_email_default';
                    var reg_email2_var = document.getElementById("registration_email_second");

                    reg_email2_var.onblur = function() {
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            //$('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#registration_email_first').val());
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                document.getElementById("output_email2").className='output_email_correct';
                                var $REG_EMAIL = $('#registration_email_first').val();
                                CONSOLE_MANAGER('registration_email_second','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                        
                    }
                    $('#registration_email_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_email_second','keypress');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                //Email_validation_MGMT(e);
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_email_second','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_username").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_username').val(result);
                        var $REG_UNAME = $('#registration_username').val();
                        var $STRING=$('#registration_username').val();
                        $('#registration_username').val($STRING.replace(/[^a-z\s]/gi, '').replace(/[_\s]/g, '-'));
                        if($REG_UNAME.length>2){
                            if(($('#registration_username').val().length>0)){
                                $('#standard_entity_modify_control').val($('#registration_username').attr('id'));
                                $('#standard_entity_modify_title').val($('#registration_username').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Username_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#registration_username').val())){
                                UPDATE_UNAME_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_phone").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_phone').val(result);
                        var $REG_PHONE = $('#registration_phone').val();
                        if($REG_PHONE.length>3){
                            phone_format(false);
                            CONSOLE_MANAGER('registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#registration_phone').attr('id'));
                                $('#standard_entity_modify_phone').val($('#registration_phone').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_first").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_first').val(result);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_email_second').val($('#registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        var $REG_EMAIL1 = $('#registration_email_first').val();
                        var $REG_EMAIL2 = $('#registration_email_second').val();
                        CONSOLE_MANAGER('registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_firstName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_firstName').val(result);
                        var $REG_FIRST_NAME = $('#registration_firstName').val();
                        if($REG_FIRST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var string = document.getElementById("registration_lastName").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_lastName').val(result);
                        var $REG_LAST_NAME = $('#registration_lastName').val();
                        if($REG_LAST_NAME.length>1){
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('registration_email_second','blur');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_email_second','keyup');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_email_second','change');
                        var string = document.getElementById("registration_email_second").value;
                        var result = string.replace(/^\s+|\s+$/gm,'');
                        $('#registration_email_second').val(result);
                        if(($('#registration_email_second').val())){
                            var E_MGMT = sys_email_check(document.getElementById("registration_email_second").value);
                            if(E_MGMT){ 
                                document.getElementById("output_email2").className='output_email_correct';
                                var $REG_EMAIL = $('#registration_email_first').val();
                                CONSOLE_MANAGER('registration_email_second','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#registration_email_first').val(),$('#registration_email_second').val());
                        }
                        if(($('#registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_password_first")){
                    var reg_pass1_var = document.getElementById("registration_password_first");	
                    reg_pass1_var.onfocus = function() {
                        //UPDATE_CLASS($("#output_pass1"), "output_password_default", ' ');
                    }
                    reg_pass1_var.onblur = function() {
                        var reg_pass1_var_filler_holder1 = +this.value;
                        //var PASS = sys_password_check(document.getElementById("registration_password_first").value);
                        //if(PASS.length>5){
                            var P_MGMT = sys_password_check(document.getElementById("registration_password_first").value);
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#registration_password_second').val($('#registration_password_first').val())
							/* --------------------------------------------------------  */
                            if(P_MGMT == true){ 
                                if($("#output_pass1.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                    UPDATE_CLASS($("#output_pass1"), "output_password_correct", " ");
                                }
                            }else{
                                if (document.getElementById("registration_password_first").value=='') {
                                }
                                if (document.getElementById("registration_password_first").value!='') {
                                    if( $("#output_pass1.default") || $("#output_pass2.weak") ){
                                        UPDATE_CLASS($("#output_pass1"), "output_password_error", " ");
                                    }
                                }
                            }
                            sys_pass_match_check($('#registration_password_first').val(),$('#registration_password_second').val());
                        //}
                        setTimeout(is_review_complete, 500);
                    }
                    $('#registration_password_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_password_first','keypress');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            checkVal("output_pass1");
                            //checkVal("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_password_first','focus');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_password_first','keyup');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass1");
                            //outputResult("output_pass2"); 
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_password_first','change');
                        var PASS = sys_password_check(document.getElementById("registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#registration_password_second').val($('#registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass1");
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_password_second")){
                    var reg_pass2_var = document.getElementById("registration_password_second");
                    reg_pass2_var.onfocus = function() {
                    }
                    reg_pass2_var.onblur = function() {
                        var reg_pass2_var_filler_holder1 = +this.value;
                        //var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        //if(PASS.length>5){
                            var P_MGMT = sys_password_check(document.getElementById("registration_password_second").value);
                            if(P_MGMT){ 
                                if($("#output_pass2.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                    UPDATE_CLASS($("#output_pass2"), "output_password_correct", " ");
                                }
                                trigg_pass_match();
                            }else{
                                if (document.getElementById("registration_password_second").value=='') {
                                }
                                if (document.getElementById("registration_password_second").value!='') {
                                    if( $("#output_pass2.default") || $("#output_pass2.weak") ){
                                        UPDATE_CLASS($("#output_pass2"), "output_password_error", " ");
                                    }
                                }
                            }
                            sys_pass_match_check($('#registration_password_first').val(),$('#registration_password_second').val());
                        //}
                         //trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    }
                    $('#registration_password_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('registration_password_second','keypress');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            //checkVal("output_pass1");
                            checkVal("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('registration_password_second','focus');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('registration_password_second','keyup');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            //outputResult("output_pass1");
                            outputResult("output_pass2"); 
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('registration_password_second','change');
                        var PASS = sys_password_check(document.getElementById("registration_password_second").value);
                        if(PASS.length>5){
                            trigg_pass_match();
                            outputResult("output_pass2");
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options4")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options4'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options4active")){
                    $('#registration_options4active').unbind().click(function(event) {
                        var $tempClass = $('#registration_options4active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#registration_options4active').removeClass($tempClass);
                       	    $('#registration_options4active').addClass('reg_check_button_active');
                            $('#registration_termsaccepted').val(htmlEscape("ACCEPTED"));
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#registration_options4active').removeClass($tempClass);
                       	    $('#registration_options4active').addClass('reg_check_button_default');
                            $('#registration_termsaccepted').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options5")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options5'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("registration_options5active")){
                    $('#registration_options5active').unbind().click(function(event) {
                        var $tempClass = $('#registration_options5active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#registration_options5active').removeClass($tempClass);
                       	    $('#registration_options5active').addClass('reg_check_button_active');
                            $('#registration_privacypolicy').val(htmlEscape("ACCEPTED"));
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#registration_options5active').removeClass($tempClass);
                       	    $('#registration_options5active').addClass('reg_check_button_default');
                            $('#registration_privacypolicy').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });                    
                }
            }
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            if ($controlString.indexOf("BUSINESS_TYPE") >= 0){
                   
                //$("#business_registration_title").attr("autocomplete", "off");
                //$("#business_registration_title").val(null);
                //$("#business_registration_title").html(null);
                //$("#business_registration_phone").attr("autocomplete", "off");
                //$("#business_registration_phone").val(null);
                //$("#business_registration_phone").html(null);
                //$("#business_registration_options3").attr("autocomplete", "off");
                //$("#business_registration_options3").val(null);
                //$("#business_registration_options3").html(null);
                $("#business_registration_password_first").attr("autocomplete", "off");
                $("#business_registration_password_first").val(null);
                $("#business_registration_password_first").html(null);
                $("#business_registration_password_second").attr("autocomplete", "off");
                $("#business_registration_password_second").val(null);
                $("#business_registration_password_second").html(null);
                //$("#business_registration_email_first").attr("autocomplete", "off");
                //$("#business_registration_email_first").val(null);
                $("#business_registration_email_first").html(null);
                $("#business_registration_email_second").attr("autocomplete", "off");
                $("#business_registration_email_second").val(null);
                $("#business_registration_email_second").html(null);
                $("#business_registration_address").attr("autocomplete", "off");
                $("#business_registration_address").val(null);
                $("#business_registration_address").html(null);
                $("#business_registration_address2").attr("autocomplete", "off");
                $("#business_registration_address2").val(null);
                $("#business_registration_address2").html(null);
                $("#business_registration_city").attr("autocomplete", "off");
                $("#business_registration_city").val(null);
                $("#business_registration_city").html(null);
                $("#business_registration_state").attr("autocomplete", "off");
                $("#business_registration_state").val(null);
                $("#business_registration_state").html(null);
                $("#business_registration_country").attr("autocomplete", "off");
                $("#business_registration_country").val(null);
                $("#business_registration_country").html(null);
                //$("#business_registration_phone").focus();
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                //if(document.getElementById("business_registration_options1")){
                //    SET_BUTTON_CLICK_ACTION("business_registration_options1");
                //}
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options2")){
                    SET_BUTTON_CLICK_ACTION("business_registration_options2");
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options3")){
                    //SET_BUTTON_CLICK_ACTION("business_registration_options3");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options3'),null,null,null,null);
                    $('#standard_entity_modify_control').val($('#business_registration_options3').attr('id'));
                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                    //$('#business_registration_options3').unbind().click(function(event) {
                        //standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                    //});
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                //if(document.getElementById("business_registration_options4")){
                //    SET_BUTTON_CLICK_ACTION("business_registration_options4");
                //}
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  
                if(document.getElementById("business_registration_options5")){
                    SET_BUTTON_CLICK_ACTION("business_registration_options5");
                    $('#business_registration_options5').unbind().click(function(event) {
                        $('#standard_entity_modify_control').val($('#business_registration_options5').attr('id'));
                        $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                        settings_request_function(event);
                        setTimeout(is_review_complete, 500);
                    });
                 }*/
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_title")){
                    var reg_titlename_var = document.getElementById("business_registration_title");
                    /* --------------------------------------------------------  */
                    reg_titlename_var.onfocus = function() {
                        /* --------------------------------------------------------  */
                        $( "#business_registration_title" ).unbind().keyup(function(e) {
                            /* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("business_registration_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#business_registration_title').val();
                                $('#business_registration_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#business_registration_title').val();
                            var string = document.getElementById("business_registration_title").value;
                            reg_titlename_var.onblur = function() {
                                if($REG_UNAME.length>2){
                                    if(($('#business_registration_title').val().length>0)){
                                        $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                        $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                        $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                        $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                        business_title_validation_MGMT(e);
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if(!($('#business_registration_title').val())){
                                        UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                        setTimeout(is_review_complete, 500);
                                    }
                                }
                            }
                        });
                        $('#business_registration_title').on('keypress', function(e) {
                            CONSOLE_MANAGER('business_registration_title','keypress');
                            var $REG_UNAME = $('#business_registration_title').val();
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    //business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('focus', function() {
                            CONSOLE_MANAGER('business_registration_title','focus');
                            setTimeout(is_review_complete, 500);
                            /* --------------------------------------------------------  */
                            var $REG_UNAME = $('#business_registration_title').val();
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
                            var $REG_PHONE = $('#business_registration_phone').val();
                            if($REG_PHONE.length>3){
                                business_phone_format(false);
                                CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                                if($REG_PHONE.length>13){
                                    $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                    //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    business_phone_validation_MGMT(e);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                            /* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        })
                        .on('blur', function() {
                            CONSOLE_MANAGER('business_registration_title','blur');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('keyup', function() {
                            CONSOLE_MANAGER('business_registration_title','keyup');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            setTimeout(is_review_complete, 500);
                        })
                        .on('change', function() {
                            CONSOLE_MANAGER('business_registration_title','change');
                            var $STRING=$('#business_registration_title').val();
                            $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                            var $REG_UNAME = $('#business_registration_title').val();
                            if($REG_UNAME.length>2){
                                if(($('#business_registration_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                    $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    business_title_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                                if(!($('#business_registration_title').val())){
                                    UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                    setTimeout(is_review_complete, 500);
                                }
                            }
                        });
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function title_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#business_registration_title').attr('id')){
                        var $VAL =$('#standard_results_ajax_responseData').val();
                        if ($VAL.indexOf("true") >=0){
                            UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_correct', '');
                            setTimeout(is_review_complete, 500);
                        }
                        if ($VAL.indexOf("false") >=0){
                            UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_error', '');
                            setTimeout(is_review_complete, 500);
                        }
                        $('#standard_results_ajax_responseData').val('');
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* AJAX LISTENERS  (MutationObserver) ---------------------  */
                var MODIFY_VIEW_LISTENER = (function (){
                    var target = document.getElementById('standard_results_ajax_control');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */	
                    var observer = new MutationObserver(function(mutations) {
                      mutations.forEach(function(mutation) {
                        if($("#standard_results_ajax_control").val()){  
                            CONSOLE_MANAGER('BUSINESS_REGISTRATION_SOCIAL_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            var $RESULTS_CNTRL = null;
                            var $SEARCH_CNTRL = null;
                            if($('#standard_results_ajax_control').val()){
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                if($('#search_request_control').length){
                                   $SEARCH_CNTRL = $('#search_request_control').val();
                                }
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('BUSINESS_REGISTRATION_SOCIAL_basics',mutation.type+': attributes');
                                    /* -------------------------------------------------------- */
                                    //var $TEMP_VAL = $('#standard_results_ajax_control').val();
                                    if($RESULTS_CNTRL == $('#business_registration_title').attr('id')){
                                        title_ajax();
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if($RESULTS_CNTRL == $('#business_registration_phone').attr('id')){
                                        business_phone_format(true);
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_half_field_check_positive
                                                ls_form_half_field_check_negative
                                            */
                                            $("#phone_field_check").removeClass('any_visuallyhidden');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('ls_form_half_field_check_positive');
                                        }else{
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_positive');
                                            $("#phone_field_check").removeClass('ls_form_half_field_check_negative');
                                            $("#phone_field_check").addClass('any_visuallyhidden');  
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    if($RESULTS_CNTRL == $('#business_registration_email_first').attr('id')){
                                        email_ajax();
                                        if($('#standard_results_ajax_textStatus').val()=='valid'){
                                            /*
                                                ls_form_full_field_check_positive
                                                ls_form_full_field_check_negative
                                            */
                                            $("#email_field_check").removeClass('any_visuallyhidden');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('ls_form_full_field_check_positive');
                                        }else{
                                            $("#email_field_check").removeClass('ls_form_full_field_check_positive');
                                            $("#email_field_check").removeClass('ls_form_full_field_check_negative');
                                            $("#email_field_check").addClass('any_visuallyhidden');
                                        }
                                        setTimeout(is_review_complete, 500);
                                    }
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('business_instance_function',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        }
                      });    
                    });//END new MutationObserver
                    var config = { attributes: true, childList: true, characterData: true };
                    observer.observe(target, config);
                })();/*END FUNCTION */
                /* --------------------------------------------------------  */
                function email_ajax(){
                    if($('#standard_results_ajax_control').val() == $('#business_registration_email_first').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email1").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                        }
                    }
                    if($('#standard_results_ajax_control').val() == $('#business_registration_email_second').attr('id')){
                        if(!($('#standard_results_ajax_responseData').val())){
                            document.getElementById("output_email2").className='output_email_correct';	
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
					
					/* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#business_registration_email_second').val($('#business_registration_email_first').val())
                    /* --------------------------------------------------------  */
                    if($('#business_registration_email_first').val().length >0 && $('#business_registration_email_second').val().length >0){
                        var mtch = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(mtch){
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email1").className='output_email_error';
                            document.getElementById("output_email2").className='output_email_error';
                        }
                    }
                    $('#standard_results_ajax_control').val('');
                    $('#standard_entity_modify_email').val('');
                    $('#standard_results_ajax_responseData').val('');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_phone")){
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#business_registration_phone').on('change', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','change');
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            business_phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                            //phone_field_check
                        }
                    })
                    .on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','keypress');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            business_phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                    })
                    .on('focus', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','focus');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        //phone_format(false);
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            business_phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_EMAIL1 = $('#business_registration_email_first').val();
                        var $REG_EMAIL2 = $('#business_registration_email_second').val();
                        CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL1.length);
                        if($REG_EMAIL1==$REG_EMAIL2){
                            if($REG_EMAIL1.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','blur');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                        var $REG_PHONE = $('#business_registration_phone').val();
                        CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                        if($REG_PHONE.length>13){
                            $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                            //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                            $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                            $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                            $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                            business_phone_validation_MGMT(false);
                            setTimeout(is_review_complete, 500);
                        }
                        
                    })
                    .on('keyup', function(e) {
                        CONSOLE_MANAGER('business_registration_phone','keyup');
                        business_phone_format(false);
                        var key = e.charCode || e.keyCode || 0;
                        var phone = $(this);
                        if (phone.val().length === 0) {
                            phone.val(phone.val() + '(');
                        }
                        // Auto-format- do not expose the mask as the user begins to type
                        if (key !== 8 && key !== 9) {
                            if (phone.val().length === 4) {
                                phone.val(phone.val() + ')');
                            }
                            if (phone.val().length === 5) {
                                phone.val(phone.val() + ' ');
                            }
                            if (phone.val().length === 9) {
                                phone.val(phone.val() + '-');
                            }
                            if (phone.val().length >= 14) {
                                phone.val(phone.val().slice(0, 14));
                            }
                        }
                        // Allow numeric (and tab, backspace, delete) keys only
                        return (key == 8 ||
                            key == 9 ||
                            key == 46 ||
                            (key >= 48 && key <= 57) ||
                            (key >= 96 && key <= 105)
                        );
                        business_phone_format(false);
                        setTimeout(is_review_complete, 500);
                    });
                }
                function business_phone_format(e){
                     CONSOLE_MANAGER('business_phone_format','---------------------------------');
                    var $text = $( "#business_registration_phone" ).val();
                    $text = $text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                    $( "#business_registration_phone" ).val($text);
                    
                    if ($( "#business_registration_phone" ).val().length === 0) {
                        $( "#business_registration_phone" ).val('(');
                    } else {
                        var val = $( "#business_registration_phone" ).val();
                        $( "#business_registration_phone" ).val('').val(val); // Ensure cursor remains at the end
                    }
                    setTimeout(is_review_complete, 500);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_email_first")){
                    document.getElementById("output_email1").className='output_email_default';
                    var reg_email1_var = document.getElementById("business_registration_email_first");

                    reg_email1_var.onblur = function() {
                        if(($('#business_registration_email_first').val())){
                            //$('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                            //$('#standard_entity_modify_email').val($('#business_registration_email_first').val());
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    }
                    $('#business_registration_email_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_email_first','keypress');
                        if(($('#business_registration_email_first').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_email_first','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_PHONE = $('#business_registration_phone').val();
                        if($REG_PHONE.length>3){
                            business_phone_format(false);
                            CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                business_phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* -------------------------------------------------------- */
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('business_registration_email_first','blur');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_email_first','keyup');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_email_first','change');
                        
                        if(($('#business_registration_email_first').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_first').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_email_second")){
                    document.getElementById("output_email2").className='output_email_default';
                    var reg_email2_var = document.getElementById("business_registration_email_second");

                    reg_email2_var.onblur = function() {
                        if(($('#business_registration_email_second').val())){
                            //$('#standard_entity_modify_control').val($('#business_registration_email_second').attr('id'));
                            //$('#standard_entity_modify_email').val($('#business_registration_email_second').val());
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            if($('#business_registration_email_first').val()==$('#business_registration_email_second').val()){
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    }
                    $('#business_registration_email_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_email_second','keypress');
                        if(($('#business_registration_email_second').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email2").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email2").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_email_second','focus');
                        setTimeout(is_review_complete, 500);
                        /* --------------------------------------------------------  */
                        var $REG_UNAME = $('#business_registration_title').val();
                        var $STRING=$('#business_registration_title').val();
                        $('#business_registration_title').val($STRING.replace(/[_\s]/g, ' '));
                        if($REG_UNAME.length>2){
                            if(($('#business_registration_title').val().length>0)){
                                $('#standard_entity_modify_control').val($('#business_registration_title').attr('id'));
                                $('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                business_title_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                            if(!($('#business_registration_title').val())){
                                UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
                        var $REG_PHONE = $('#business_registration_phone').val();
                        if($REG_PHONE.length>3){
                            business_phone_format(false);
                            CONSOLE_MANAGER('business_registration_phone','change: '+$REG_PHONE.length);
                            if($REG_PHONE.length>13){
                                $('#standard_entity_modify_control').val($('#business_registration_phone').attr('id'));
                                //$('#standard_entity_modify_title').val($('#business_registration_title').val());
                                $('#standard_entity_modify_phone').val($('#business_registration_phone').val());
                                $('#standard_entity_modify_uniqBusinessId').val($('#business_registration_uniqBusinessId').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                business_phone_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        /* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('business_registration_email_second','blur');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_email_second','keyup');
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_email_second').val($('#business_registration_email_first').val())
                        /* --------------------------------------------------------  */
                        var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        if(E_MGMT){ 
                            document.getElementById("output_email1").className='output_email_correct';
                            document.getElementById("output_email2").className='output_email_correct';
                            /* --------------------------------------------------------  */
                            var $REG_EMAIL = $('#business_registration_email_first').val();
                            CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                            if($REG_EMAIL.length>5){
                                $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                BUSINESS_Email_validation_MGMT(false);
                                setTimeout(is_review_complete, 500);
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_email_second','change');
                        
                        if(($('#business_registration_email_second').val())){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							$('#business_registration_email_second').val($('#business_registration_email_first').val())
							/* --------------------------------------------------------  */
                            var E_MGMT = sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                            if(E_MGMT){ 
                                document.getElementById("output_email1").className='output_email_correct';
                                document.getElementById("output_email2").className='output_email_correct';
                                /* --------------------------------------------------------  */
                                var $REG_EMAIL = $('#business_registration_email_first').val();
                                CONSOLE_MANAGER('business_registration_email_first','keyup: '+$REG_EMAIL.length);
                                if($REG_EMAIL.length>5){
                                    $('#standard_entity_modify_control').val($('#business_registration_email_first').attr('id'));
                                    $('#standard_entity_modify_email').val($('#business_registration_email_first').val());
                                    $('#standard_entity_modify_socialId').val($('#GET_ITEM_ID').val());
                                    BUSINESS_Email_validation_MGMT(false);
                                    setTimeout(is_review_complete, 500);
                                }
                            }else{
                                document.getElementById("output_email1").className='output_email_error';
                            }
                            sys_email_match_check($('#business_registration_email_first').val(),$('#business_registration_email_second').val());
                        }
                        if(($('#business_registration_email_second').val().length === 0)){
                            document.getElementById("output_email1").className='output_email_default';
                            $('#standard_results_ajax_control').val('');
                            $('#standard_entity_modify_email').val('');
                            $('#standard_results_ajax_responseData').val('');
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_password_first")){
                    var reg_pass1_var = document.getElementById("business_registration_password_first");	
                    reg_pass1_var.onfocus = function() {
                        //UPDATE_CLASS($("#output_pass1"), "output_password_default", ' ');
                    }
                    reg_pass1_var.onblur = function() {
                        var reg_pass1_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("business_registration_password_first").value);
						/* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $('#business_registration_password_second').val($('#business_registration_password_first').val())
                        /* --------------------------------------------------------  */
                        if(P_MGMT == true){ 
                            if($("#output_pass1.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass1"), "output_password_correct", " ");
                                setTimeout(is_review_complete, 500);
                            }
                        }else{
                            if (document.getElementById("business_registration_password_first").value=='') {
                            }
                            if (document.getElementById("business_registration_password_first").value!='') {
                                if( $("#output_pass1.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass1"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#business_registration_password_first').val(),$('#business_registration_password_second').val());
                    }
                    $('#business_registration_password_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_password_first','keypress');
                        trigg_pass_match();
                        checkVal("output_pass1");
                        //checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_password_first','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_password_first','keyup');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        //outputResult("output_pass2"); 
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_password_first','change');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_password_second")){
                    var reg_pass2_var = document.getElementById("business_registration_password_second");
                    reg_pass2_var.onfocus = function() {
                    }
                    reg_pass2_var.onblur = function() {
                        var reg_pass2_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("business_registration_password_second").value);
                        if(P_MGMT){ 
                            if($("#output_pass2.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass2"), "output_password_correct", " ");
                            }
                            trigg_pass_match();
                            setTimeout(is_review_complete, 500);
                        }else{
                            if (document.getElementById("business_registration_password_second").value=='') {
                            }
                            if (document.getElementById("business_registration_password_second").value!='') {
                                if( $("#output_pass2.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass2"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#business_registration_password_first').val(),$('#business_registration_password_second').val());
                    }
                    $('#business_registration_password_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('business_registration_password_second','keypress');
                        trigg_pass_match();
                        //checkVal("output_pass1");
                        checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('business_registration_password_second','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('business_registration_password_second','keyup');
                        trigg_pass_match();
                        //outputResult("output_pass1");
                        outputResult("output_pass2"); 
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('business_registration_password_second','change');
                        trigg_pass_match();
                        outputResult("output_pass2");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options4")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options4'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options4active")){
                    $('#business_registration_options4active').unbind().click(function(event) {
                        var $tempClass = $('#business_registration_options4active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#business_registration_options4active').removeClass($tempClass);
                       	    $('#business_registration_options4active').addClass('reg_check_button_active');
                            $('#business_registration_termsaccepted').val(htmlEscape("ACCEPTED"));
                            $("#business_registration_formFilter7").val(true);
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#business_registration_options4active').removeClass($tempClass);
                       	    $('#business_registration_options4active').addClass('reg_check_button_default');
                            $('#business_registration_termsaccepted').val(htmlEscape("DENIED"));
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options5")){
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options5'),null,null,null,null);
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("business_registration_options5active")){
                    $('#business_registration_options5active').unbind().click(function(event) {
                        var $tempClass = $('#business_registration_options5active').attr('class')
                        if($tempClass.indexOf("default") >= 0){
                            $('#business_registration_options5active').removeClass($tempClass);
                       	    $('#business_registration_options5active').addClass('reg_check_button_active');
                            $('#business_registration_privacypolicy').val(htmlEscape("ACCEPTED"));
                            $("#business_registration_formFilter6").val(true);
                        }else if($tempClass.indexOf("active") >= 0){
                            $('#business_registration_options5active').removeClass($tempClass);
                       	    $('#business_registration_options5active').addClass('reg_check_button_default');
                            $('#business_registration_privacypolicy').val(htmlEscape("DENIED"));
                            
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
            }
            /*  END PROFILE CONTROL */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var complexity_pass1 = $("#output_pass1");
			var complexity_pass2 = $("#output_pass2");
			var strPassword;
			var charPassword;
			var minPasswordLength = 8;
			var baseScore = 0, score = 0;
			var num = {};
			num.Excess = 0;
			num.Upper = 0;
			num.Numbers = 0;
			num.Symbols = 0;
			var bonus = {};
			bonus.Excess = 3;
			bonus.Upper = 4;
			bonus.Numbers = 5;
			bonus.Symbols = 5;
			bonus.Combo = 0; 
			bonus.FlatLower = 0;
			bonus.FlatNumber = 0;
            /* --------------------------------------------------------  */
            if($("#registration_password_first").val()){
               outputResult("output_pass1");
            }
            if($("#business_registration_password_first").val()){
                outputResult("output_pass1"); 
            }	
			/* --------------------------------------------------------  */
            if($("#registration_password_second").val()){
               outputResult("output_pass2"); 
            }
            if($("#business_registration_password_second").val()){
                outputResult("output_pass2");
            }
            /* --------------------------------------------------------  */
			$("#registration_password_first").bind("keyup change", function(e) {
                if($("#registration_password_first").val()){
                   checkVal("output_pass1"); 
                }				
			})
			$("#registration_password_second").bind("keyup change", function(e) {
                if($("#registration_password_second").val()){
				    checkVal("output_pass2"); 
                }
			})
            $("#business_registration_password_first").bind("keyup change", function(e) {
                if($("#business_registration_password_first").val()){
                   checkVal("output_pass1"); 
                }				
			})
			$("#business_registration_password_second").bind("keyup change", function(e) {
                if($("#business_registration_password_second").val()){
				    checkVal("output_pass2"); 
                }
			})
			/*************/
			function checkVal(selection)
			{
				if(selection =="output_pass1"){
					init("output_pass1");
				}
				if(selection =="output_pass2"){
					init("output_pass2");
				}
				if(charPassword){
					if (charPassword.length >= minPasswordLength)
					{
						baseScore = 50;	
						analyzeString();	
						calcComplexity();		
					}
				}else
				{
					baseScore = 0;
				}
				outputResult(selection);
			}
			/*************/
			function init(selection)
			{
				if(selection =="output_pass1"){
                    if($("#registration_password_first").val()){
                        strPassword= $("#registration_password_first").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
				if(selection =="output_pass2"){
                    if($("#registration_password_second").val()){
                        strPassword= $("#registration_password_second").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
                if(selection =="output_pass1"){
                    if($("#business_registration_password_first").val()){
                        strPassword= $("#business_registration_password_first").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
				if(selection =="output_pass2"){
                    if($("#business_registration_password_second").val()){
                        strPassword= $("#business_registration_password_second").val();
                        charPassword = strPassword.split("");
                        num.Excess = 0;
                        num.Upper = 0;
                        num.Numbers = 0;
                        num.Symbols = 0;
                        bonus.Combo = 0; 
                        bonus.FlatLower = 0;
                        bonus.FlatNumber = 0;
                        baseScore = 0;
                        score =0;
                    }
				}
			}
			/*************/
			function analyzeString ()
			{	
				for (var i=0; i<charPassword.length;i++)
				{
					if (charPassword[i].match(/[A-Z]/g)) {num.Upper++;}
					if (charPassword[i].match(/[0-9]/g)) {num.Numbers++;}
					if (charPassword[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) {num.Symbols++;} 
				}
				num.Excess = charPassword.length - minPasswordLength;
				if (num.Upper && num.Numbers && num.Symbols)
				{
					bonus.Combo = 25; 
				}
				else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols))
				{
					bonus.Combo = 15; 
				}
				if (strPassword.match(/^[\sa-z]+$/))
				{ 
					bonus.FlatLower = -15;
				}
				if (strPassword.match(/^[\s0-9]+$/))
				{ 
					bonus.FlatNumber = -35;
				}
			}
			/*************/
			function calcComplexity()
			{
				score = baseScore + (num.Excess*bonus.Excess) + (num.Upper*bonus.Upper) + (num.Numbers*bonus.Numbers) + (num.Symbols*bonus.Symbols) + bonus.Combo + bonus.FlatLower + bonus.FlatNumber;
			}	
			/*************/
			function outputResult(selection)
			{
                /* --------------------------------------------------------  */
				if(selection =='output_pass1'){
                    /* --------------------------------------------------------  */
					if ($("#registration_password_first").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass1, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass1, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass1, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass1, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass1, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass1, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
                    /* --------------------------------------------------------  */
                    if ($("#business_registration_password_first").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass1, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass1, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass1, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass1, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass1, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass1, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
				}
                /* --------------------------------------------------------  */
				if(selection =='output_pass2'){
                    /* --------------------------------------------------------  */
					if ($("#registration_password_second").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass2, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass2, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;	
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass2, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass2, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass2, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass2, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
                    /* --------------------------------------------------------  */
                    if ($("#business_registration_password_second").val()== "")
					{ 
						UPDATE_CLASS(complexity_pass2, "weak", " ");
						PASS_VALIDATE = false;
					}
					else if ((charPassword) && (charPassword.length < minPasswordLength))
					{
						UPDATE_CLASS(complexity_pass2, "weak", "At least " + minPasswordLength+ " characters please!");
						PASS_VALIDATE = false;	
					}
					else if (score<50)
					{
						UPDATE_CLASS(complexity_pass2, "weak", "Weak!");
						PASS_VALIDATE = false;
					}
					else if (score>=50 && score<75)
					{
						UPDATE_CLASS(complexity_pass2, "strong", "Average!");
						PASS_VALIDATE = true;
					}
					else if (score>=75 && score<100)
					{
						UPDATE_CLASS(complexity_pass2, "stronger", "Strong!");
						PASS_VALIDATE = true;
					}
					else if (score>=100)
					{
						UPDATE_CLASS(complexity_pass2, "strongest", "Secure!");
						PASS_VALIDATE = true;
					}
				}
                setTimeout(is_review_complete, 500);
			}
			/*************/
			function UPDATE_CLASS(element_var, add_class, new_text){
				element_var.removeClass("default weak strong stronger strongest output_password_default output_password_correct ");
				element_var.removeClass("output_password_error output_match_error_top output_match_error_bottom");
				element_var.addClass(add_class);
				element_var.html(new_text);
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("registration_options2")){
				var reset_button = document.getElementById("registration_options2");
				SET_BUTTON_CLICK_ACTION("registration_options2");
				reset_button.addEventListener("click", function(){	
				}, false);
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("registration_options1")){
				var submit_button = document.getElementById("registration_options1");
				SET_BUTTON_CLICK_ACTION("registration_options1");
                $('#registration_options1').unbind().click(function(event) {
                    if($("#output_username").hasClass("output_username_default") ||$("#output_username").hasClass("output_username_error")){
                        $("#registration_username").val('');
                        $("#business_registration_title").val('');
                    }
                    if($("#output_email1").hasClass("output_email_default") ||$("#output_email1").hasClass("output_email_error")){
                        $("#registration_email_first").val('');
                        $("#business_registration_email_first").val('');
                    }
                    if($("#output_email2").hasClass("output_email_default") ||$("#output_email2").hasClass("output_email_error")){
                        $("#registration_email_second").val('');
                        $("#business_registration_email_second").val('');
                    }
				});
			}
            /* --------------------------------------------------------  */
			if(document.getElementById("business_registration_options1")){
				var submit_button = document.getElementById("business_registration_options1");
				SET_BUTTON_CLICK_ACTION("business_registration_options1");
                $('#business_registration_options1').unbind().click(function(event) {
                    if($("#output_username").hasClass("output_username_default") ||$("#output_username").hasClass("output_username_error")){
                        $("#registration_username").val('');
                        $("#business_registration_title").val('');
                    }
                    if($("#output_email1").hasClass("output_email_default") ||$("#output_email1").hasClass("output_email_error")){
                        $("#registration_email_first").val('');
                        $("#business_registration_email_first").val('');
                    }
                    if($("#output_email2").hasClass("output_email_default") ||$("#output_email2").hasClass("output_email_error")){
                        $("#registration_email_second").val('');
                        $("#business_registration_email_second").val('');
                    }
				});
			}
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            function is_review_complete(){
                CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                /* --------------------------------------------------------  */ 
                /* --------------------------------------------------------  */
                if ($controlString.indexOf("PROFILE_TYPE") >= 0){
                    /* --------------------------------------------------------  */ 
                    if($("#output_username").hasClass("output_username_correct")){
                        $("#registration_formFilter1").val(true);
                    }else{
                        $("#registration_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#phone_field_check").hasClass("ls_form_half_field_check_positive")){
                        $("#registration_formFilter2").val(true);
                    }else{
                        $("#registration_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#email_field_check").hasClass("ls_form_full_field_check_positive")){
                        $("#registration_formFilter3").val(true);
                    }else{
                        $("#registration_formFilter3").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_index1").val()){
                        $("#registration_formFilter4").val(true);
                    }else{
                        $("#registration_formFilter4").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_privacypolicy").val()=='ACCEPTED'){
                        $("#registration_formFilter5").val(true);
                    }else{
                        $("#registration_formFilter5").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#registration_termsaccepted").val()=='ACCEPTED'){
                        $("#registration_formFilter6").val(true);
                    }else{
                        $("#registration_formFilter6").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass1").hasClass("output_password_correct")){
                        $("#registration_formFilter7").val(true);
                    }else{
                        $("#registration_formFilter7").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass2").hasClass("output_password_correct")){
                        $("#registration_formFilter8").val(true);
                    }else{
                        $("#registration_formFilter8").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if(($("#registration_formFilter1").val())&&($("#registration_formFilter2").val())&&
                       ($("#registration_formFilter3").val())&&($("#registration_formFilter4").val())&&
                       ($("#registration_formFilter5").val())&&($("#registration_formFilter6").val())&&
                       ($("#registration_formFilter7").val())&&($("#registration_formFilter8").val())
                      ){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ----------');
                        $('#registration_options1').attr('disabled',false);
                        $('#registration_options1').removeClass('ls_form_submit_button');
                       	$('#registration_options1').addClass('ls_form_submit_button_active');
                        SET_BUTTON_CLICK_ACTION("registration_options1");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#registration_options1'),null,null,null,null);
                        $('#standard_entity_modify_control').val($('#registration_options1').attr('id'));
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ----------');
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#registration_formFilter1").val()+' - 2: '+$("#registration_formFilter2").val()+' - 3: '+$("#registration_formFilter3").val());
                        
                        CONSOLE_MANAGER('is_review_complete','-4: '+$("#registration_formFilter4").val()+' -PRIVACY: '+$("#registration_formFilter5").val()+' -TERMS: '+$("#registration_formFilter6").val()+' -PASS1: '+$("#registration_formFilter7").val()+' -PASS2: '+$("#registration_formFilter8").val());
                        $('#registration_options1').attr('disabled',true); 
                        $('#registration_options1').removeClass('ls_form_submit_button_active');
                       	$('#registration_options1').addClass('ls_form_submit_button');
                        
                    }
                }
                /* --------------------------------------------------------  */ 
                /* --------------------------------------------------------  */
                if ($controlString.indexOf("BUSINESS_TYPE") >= 0){
                    /* --------------------------------------------------------  */ 
                    if($("#output_username").hasClass("output_title_correct")){
                        $("#business_registration_formFilter1").val(true);
                    }else{
                        $("#business_registration_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#phone_field_check").hasClass("ls_form_half_field_check_positive")){
                        $("#business_registration_formFilter2").val(true);
                    }else{
                        $("#business_registration_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#email_field_check").hasClass("ls_form_full_field_check_positive")){
                        $("#business_registration_formFilter3").val(true);
                    }else{
                        $("#business_registration_formFilter3").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_options3").val()){
                        $("#business_registration_formFilter4").val(true);
                    }else{
                        $("#business_registration_formFilter4").val(null);
                    }
                    /* --------------------------------------------------------  
                    if (($('#business_registration_address').val())&&($('#business_registration_city').val())&&($('#business_registration_state').val())&&($('#business_registration_country').val())) {
                        $("#business_registration_formFilter5").val(true);
                    }else{
                        $("#business_registration_formFilter5").val(null);
                    }
                    CONSOLE_MANAGER('is_review_complete','-address: '+$('#business_registration_address').val()+' - city: '+$('#business_registration_city').val()+' - state: '+$('#business_registration_state').val());
                    */ 
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_privacypolicy").val()=='ACCEPTED'){
                        $("#business_registration_formFilter6").val(true);
                    }else{
                        $("#business_registration_formFilter6").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#business_registration_termsaccepted").val()=='ACCEPTED'){
                        $("#business_registration_formFilter7").val(true);
                    }else{
                        $("#business_registration_formFilter7").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass1").hasClass("output_password_correct")){
                        $("#business_registration_formFilter8").val(true);
                    }else{
                        $("#business_registration_formFilter8").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_pass2").hasClass("output_password_correct")){
                        $("#business_registration_formFilter9").val(true);
                    }else{
                        $("#business_registration_formFilter9").val(null);
                    }
                    /* -------------------------------------------------------- ($("#business_registration_formFilter5").val())&& */ 
                    if(($("#business_registration_formFilter1").val())&&($("#business_registration_formFilter2").val())&&
                       ($("#business_registration_formFilter3").val())&&($("#business_registration_formFilter4").val())&&
                       ($("#business_registration_formFilter6").val())&&
                       ($("#business_registration_formFilter7").val())&&($("#business_registration_formFilter8").val())&&
                       ($("#business_registration_formFilter9").val())
                      ){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ----------');
                        $('#business_registration_options1').attr('disabled',false);
                        $('#business_registration_options1').removeClass('ls_form_submit_button');
                       	$('#business_registration_options1').addClass('ls_form_submit_button_active');
                        //SET_BUTTON_CLICK_ACTION("business_registration_options1");
                        //CONFIRM_MGMT('registration_options1','.Registration_UI_class');
                        SET_BUTTON_CLICK_ACTION("business_registration_options1");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#business_registration_options1'),null,null,null,null);
                        $('#standard_entity_modify_control').val($('#business_registration_options1').attr('id'));
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ----------');
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#business_registration_formFilter1").val()+' - 2: '+$("#business_registration_formFilter2").val()+' - 3: '+$("#business_registration_formFilter3").val());
                        
                        CONSOLE_MANAGER('is_review_complete','-4: '+$("#business_registration_formFilter4").val()+' -5: '+$("#business_registration_formFilter5").val()+' -PRIVACY: '+$("#business_registration_formFilter6").val()+' -TERMS: '+$("#business_registration_formFilter7").val()+' -PASS1: '+$("#business_registration_formFilter8").val()+' -PASS2: '+$("#business_registration_formFilter9").val());
                        $('#business_registration_options1').removeClass('ls_form_submit_button_active');
                       	$('#business_registration_options1').addClass('ls_form_submit_button');
                        $('#business_registration_options1').attr('disabled',true); 
                    }
                }
            }
            /* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
        $('#registration_options1').attr('disabled',true);
        $('#business_registration_options1').attr('disabled',true);
        //$("#registration_username").attr("autocomplete", "off");
        //$("#registration_username").val(null);
        //$("#registration_username").html(null);
        //$("#registration_firstName").attr("autocomplete", "off");
        //$("#registration_firstName").val(null);
        //$("#registration_firstName").html(null);
        //$("#registration_lastName").attr("autocomplete", "off");
        //$("#registration_lastName").val(null);
        //$("#registration_lastName").html(null);
        $("#registration_phone").attr("autocomplete", "off");
        $("#registration_phone").val(null);
        $("#registration_phone").html(null);
        $("#registration_index1").attr("autocomplete", "off");
        $("#registration_index1").val(null);
        $("#registration_index1").html(null);
        //$("#registration_email_first").attr("autocomplete", "off");
        //$("#registration_email_first").val(null);
        //$("#registration_email_first").html(null);
        $("#registration_email_second").attr("autocomplete", "off");
        $("#registration_email_second").val(null);
        $("#registration_email_second").html(null);
        $("#registration_password_first").attr("autocomplete", "off");
        $("#registration_password_first").val(null);
        $("#registration_password_first").html(null);
        $("#registration_password_second").attr("autocomplete", "off");
        $("#registration_password_second").val(null);
        $("#registration_password_second").html(null);

        $("#registration_address").attr("autocomplete", "off");
        $("#registration_address").val(null);
        $("#registration_address").html(null);
        //$('#registration_address').attr('disabled',true);
        $("#registration_address2").attr("autocomplete", "off");
        $("#registration_address2").val(null);
        $("#registration_address2").html(null);
        //$('#registration_address2').attr('disabled',true);
        $("#registration_city").attr("autocomplete", "off");
        $("#registration_city").val(null);
        $("#registration_city").html(null);
        //$('#registration_city').attr('disabled',true);
        $("#registration_state").attr("autocomplete", "off");
        $("#registration_state").val(null);
        $("#registration_state").html(null);
        //$('#registration_state').attr('disabled',true);
        $("#registration_state").attr("autocomplete", "off");
        $("#registration_state").val(null);
        $("#registration_state").html(null);
        //$('#registration_state').attr('disabled',true);
        $("#registration_postal").attr("autocomplete", "off");
        $("#registration_postal").val(null);
        $("#registration_postal").html(null);
        
        //$("#business_registration_title").attr("autocomplete", "off");
        //$("#business_registration_title").val(null);
        //$("#business_registration_title").html(null);
        //$("#business_registration_phone").attr("autocomplete", "off");
        //$("#business_registration_phone").val(null);
        //$("#business_registration_phone").html(null);
        $("#business_registration_options3").attr("autocomplete", "off");
        $("#business_registration_options3").val(null);
        $("#business_registration_options3").html(null);
        $("#business_registration_password_first").attr("autocomplete", "off");
        $("#business_registration_password_first").val(null);
        $("#business_registration_password_first").html(null);
        $("#business_registration_password_second").attr("autocomplete", "off");
        $("#business_registration_password_second").val(null);
        $("#business_registration_password_second").html(null);
        //$("#business_registration_email_first").attr("autocomplete", "off");
        //$("#business_registration_email_first").val(null);
        //$("#business_registration_email_first").html(null);
        $("#business_registration_email_second").attr("autocomplete", "off");
        $("#business_registration_email_second").val(null);
        $("#business_registration_email_second").html(null);
        $("#business_registration_address").attr("autocomplete", "off");
        $("#business_registration_address").val(null);
        $("#business_registration_address").html(null);
        $("#business_registration_address2").attr("autocomplete", "off");
        $("#business_registration_address2").val(null);
        $("#business_registration_address2").html(null);
        $("#business_registration_city").attr("autocomplete", "off");
        $("#business_registration_city").val(null);
        $("#business_registration_city").html(null);
        $("#business_registration_state").attr("autocomplete", "off");
        $("#business_registration_state").val(null);
        $("#business_registration_state").html(null);
        $("#business_registration_country").attr("autocomplete", "off");
        $("#business_registration_country").val(null);
        $("#business_registration_country").html(null);
		/* --------------------------------------------------------  */
		window_complete_script('REGISTRATION_SOCIAL_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	LOGIN_basics                                ########################## */
export function LOGIN_basics(){
	CONSOLE_MANAGER('LOGIN_basics','CONNECTED');
    $(document).ready(function() {
        /* --------------------------------------------------------  */
        CONSOLE_MANAGER('LOGIN_basics','ACTIVATED');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        $('.Login_UI_class').attr("id", "Login_UI_control");
        $('.Reset_UI_class').attr("id", "Reset_UI_control");
		/* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
		//$("#reset_div_set").html($("#reset_div").html());
		//$("#reset_div").html(null);
		reset_options1
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        $("#inputEmail").attr("autocomplete", "off");
        $("#inputEmail").val(null);
        $("#inputEmail").html(null);
        $("#inputPassword").attr("autocomplete", "off");
        $("#inputPassword").val(null);
        $("#inputPassword").html(null);
        //$("#inputEmail").focus();
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("login_remember_me");
        //SET_BUTTON_CLICK_ACTION("reset_options1");
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
        SET_BUTTON_CLICK_ACTION("Security_form1_options1");
        $("#label_Security_form1_options1").unbind().click(function(){
            //$("#Security_form1_options1").click();
        });
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
        SET_BUTTON_CLICK_ACTION("Security_form2_options1");
        $("#label_Security_form2_options1").unbind().click(function(){
            //$("#Security_form2_options1").click();
        });
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("label_registration_options1");
        SET_BUTTON_CLICK_ACTION("registration_options1");
        $("#label_registration_options1").unbind().click(function(){
            //$("#registration_options1").click();
        });
        SET_BUTTON_CLICK_ACTION("label_registration_options2");
        SET_BUTTON_CLICK_ACTION("registration_options2");
        $("#label_registration_options2").unbind().click(function(){
            //$("#registration_options1").click();
        });
        /* --------------------------------------------------------  */
        //SET_BUTTON_CLICK_ACTION("label_reset_options1");
        //SET_BUTTON_CLICK_ACTION("reset_options1");
		var goto = $('#reset_url').prop('href');
		//CONSOLE_MANAGER('###########  RESET DOMAIN.  ##########',goto);
        $("#label_reset_options1").unbind().click(function(e){
			e.preventDefault();
			window.location.href = goto;
        });
		$("#reset_options1").unbind().click(function(e){
			e.preventDefault();
			window.location.href = goto;
        });
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("label_checkbox_remember_me");
        $("#label_checkbox_remember_me").unbind().click(function(){
            $("#checkbox_remember_me").click();
        });
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        SET_BUTTON_CLICK_ACTION("label_activate_options1");
        SET_BUTTON_CLICK_ACTION("activate_options1");
        $("#label_activate_options1").unbind().click(function(){
            //$("#activate_options1").click();
        });
        /* --------------------------------------------------------  */
        $('#checkbox_remember_me').prop('checked',true);
        $("#login_remember_me").unbind().click(function(){
            for(var i=0;i<1;i++){
                var chk = document.getElementById('checkbox_remember_me').checked;
                if (chk===true) {	
                    $('#checkbox_remember_me').prop('checked',false);
                    $('#login_remember_me').removeClass('login_check_button_active');
                    $('#login_remember_me').addClass('login_check_button_default');
                } 
                if (chk===false){
                    $('#checkbox_remember_me').prop('checked',true); 
                    $('#login_remember_me').addClass('login_check_button_active');
                    $('#login_remember_me').removeClass('login_check_button_default');
                }
            }
            window_complete_script('login_remember_me');
        });
        $("#login_options1").unbind().click(function(e){
            if((!$('#inputEmail').val())||(!$('#inputPassword').val())){
                e.preventDefault();
                setTimeout(window_complete_script('LOGIN_basics - null value'), 3000);
            }
        });
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if(document.getElementById("inputEmail")){
            var login_email_var = document.getElementById("inputEmail");
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            $( "#inputEmail" ).unbind().keyup(function(e) {
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var $REG_EMAIL = $('#inputEmail').val();
                var string = document.getElementById("inputEmail").value;
                login_email_var.onblur = function() {
                    if($REG_EMAIL.length>1){
                        var U_MGMT = sys_email_check(document.getElementById("inputEmail").value);
                        if((U_MGMT == false) ){
                            $("#Filter1").val(null);
                            setTimeout(is_review_complete, 500);
                        }else{
                            $("#Filter1").val(true);
                            setTimeout(is_review_complete, 500);
                        }
                    }else if($REG_EMAIL.length<1){
                        $("#Filter1").val(false);
                         setTimeout(is_review_complete, 500);
                    }
                }                    
            });
            $('#inputEmail').on('keypress', function(e) {
                CONSOLE_MANAGER('inputEmail','keypress');
                $("#Filter1").val(null);
                setTimeout(is_review_complete, 500);
            })
            .on('focus', function() {
                CONSOLE_MANAGER('inputEmail','focus');
                $("#Filter1").val(null);
                setTimeout(is_review_complete, 500);
            })
            .on('blur', function() {
                CONSOLE_MANAGER('inputEmail','blur');
                $("#Filter1").val(null);
                setTimeout(is_review_complete, 500);
            })
            .on('keyup', function() {
                CONSOLE_MANAGER('inputEmail','keyup');
                var $REG_EMAIL = $('#inputEmail').val();
                if($REG_EMAIL.length>1){
                    var U_MGMT = sys_email_check(document.getElementById("inputEmail").value);
                    if((U_MGMT == false) ){
                        $("#Filter1").val(null);
                        setTimeout(is_review_complete, 500);
                    }else{
                        $("#Filter1").val(true);
                        setTimeout(is_review_complete, 500);
                    }
                }else if($REG_EMAIL.length<1){
                    $("#Filter1").val(null);
                }
            })
            .on('change', function() {
                CONSOLE_MANAGER('inputEmail','change');
                var $REG_EMAIL = $('#inputEmail').val();
                if($REG_EMAIL.length>1){
                    var U_MGMT = sys_email_check(document.getElementById("inputEmail").value);
                    if((U_MGMT == false) ){
                        $("#Filter1").val(false);
                        setTimeout(is_review_complete, 500);
                    }else{
                        $("#Filter1").val(true);
                        setTimeout(is_review_complete, 500);
                    }
                }else if($REG_EMAIL.length<1){
                    $("#Filter1").val(false);
                }
            });
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if(document.getElementById("inputPassword")){
            var confirm_code_var = document.getElementById("inputPassword");
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            $( "#inputPassword" ).unbind().keyup(function(e) {
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var $REG_CODE = $('#inputPassword').val();
                confirm_code_var.onblur = function() {
                    if($REG_CODE.length>5){
                        $("#Filter2").val(true);
                        setTimeout(is_review_complete, 500);
                    }
                    else if($REG_CODE.length==0){
                        $("#Filter2").val(null);
                        setTimeout(is_review_complete, 500);
                    }else{
                        $("#Filter2").val(null);
                        setTimeout(is_review_complete, 500);
                    }
                }
            });
            $('#inputPassword').on('keypress', function(e) {
                CONSOLE_MANAGER('inputPassword','keypress');
                setTimeout(is_review_complete, 500);
            })
            .on('focus', function() {
                CONSOLE_MANAGER('inputPassword','focus');
                setTimeout(is_review_complete, 500);
            })
            .on('blur', function() {
                CONSOLE_MANAGER('inputPassword','blur');
                setTimeout(is_review_complete, 500);
            })
            .on('keyup', function() {
                CONSOLE_MANAGER('inputPassword','keyup');
                var $REG_CODE = $('#inputPassword').val();
                if($REG_CODE.length>5){
                    $("#Filter2").val(true);
                    setTimeout(is_review_complete, 500);
                }
                else if($REG_CODE.length==0){
                    $("#Filter2").val(null);
                    setTimeout(is_review_complete, 500);
                }else{
                    $("#Filter2").val(null);
                    setTimeout(is_review_complete, 500);
                }
            })
            .on('change', function() {
                CONSOLE_MANAGER('inputPassword','change');
                var $REG_CODE = $('#inputPassword').val();
                if($REG_CODE.length>5){
                    $("#Filter2").val(true);
                    setTimeout(is_review_complete, 500);
                }
                else if($REG_CODE.length==0){
                    $("#Filter2").val(null);
                    setTimeout(is_review_complete, 500);
                }else{
                    $("#Filter2").val(null);
                    setTimeout(is_review_complete, 500);
                }
            });
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        function is_review_complete(){
            CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(($("#Filter1").val())&&($("#Filter2").val())){
                CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- ');
                $('#login_options1').attr('disabled',false);
                $('#login_options1').removeClass('login_submit_default');
                $('#login_options1').addClass('login_submit_active');
                SET_BUTTON_CLICK_ACTION("login_options1");
                SET_BUTTON_CLICK_ACTION("label_login_options1");
                $("#label_login_options1").unbind().click(function(){
                    $("#login_options1").click();
                });
            }else{
                CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- ');
                CONSOLE_MANAGER('is_review_complete','-1: '+$("#Filter1").val()+' - 2: '+$("#Filter2").val());
                $('#login_options1').removeClass('login_submit_active');
                $('#login_options1').addClass('login_submit_default');
                $('#login_options1').attr('disabled',true); 
            }
        }
        /* -------------------------------------------------------- */	
    });/**	END OF $(document).ready(function()*/
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    $("#inputEmail").attr("autocomplete", "off");
    $("#inputEmail").val(null);
    $("#inputEmail").html(null);
    $("#inputPassword").attr("autocomplete", "off");
    $("#inputPassword").val(null);
    $("#inputPassword").html(null);
    $('#login_options1').attr('disabled',true);
    //$("#inputEmail").focus();
    /* -------------------------------------------------------- */
    window_complete_script('LOGIN_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	RESET_basics                                ########################## */
export function RESET_basics(){
	
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
       	/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('RESET_basics','ACTIVATED');//ACTIVATION_basics
			/* --------------------------------------------------------  */
            $("#reset_request_email").attr("autocomplete", "off");
            $("#reset_request_email").val(null);
            $("#reset_request_email").html(null);
            $("#reset_request_registerCode").attr("autocomplete", "off");
            $("#reset_request_registerCode").val(null);
            $("#reset_request_registerCode").html(null);
            $("#reset_request_password_first").attr("autocomplete", "off");
            $("#reset_request_password_first").val(null);
            $("#reset_request_password_first").html(null);
            $("#reset_request_password_second").attr("autocomplete", "off");
            $("#reset_request_password_second").val(null);
            $("#reset_request_password_second").html(null);
            /* --------------------------------------------------------  */
            if(!$("#reset_request_index1").val()){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#reset_request_index1").val()=='EXPIRED'){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#reset_request_index1").val()=='ERROR'){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
            SET_BUTTON_CLICK_ACTION("Security_form1_options1");
            $("#label_Security_form1_options1").unbind().click(function(){
                $("#Security_form1_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
            SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            $("#label_Security_form2_options1").unbind().click(function(){
                $("#Security_form2_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("reset_request_options3");
            $("#reset_request_options3").unbind().click(function(){
                $('#reset_request_control').val($('#reset_request_options3').attr('id'));
            });
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('.Reset_UI_class').is(":visible")){
                $('.Reset_UI_class').attr("id", "Reset_UI_control");
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_email")){
                    var confirm_email_var = document.getElementById("reset_request_email");
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $( "#reset_request_email" ).unbind().keyup(function(e) {
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        var $REG_EMAIL = $('#reset_request_email').val();
                        var string = document.getElementById("reset_request_email").value;
                        confirm_email_var.onblur = function() {
                            if($REG_EMAIL.length>1){
                                var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                                if((U_MGMT == false) ){
                                    if (document.getElementById("reset_request_email").value=='') {
                                    }
                                    if (document.getElementById("reset_request_email").value!='') {
                                    }
                                    document.getElementById("output_email").className='output_email_error';
                                    setTimeout(is_review_complete, 500);
                                }else{
                                    document.getElementById("output_email").className='output_email_correct';
                                    setTimeout(is_review_complete, 500);
                                }
                            }else if($REG_EMAIL.length<1){
                                document.getElementById("output_email").className=' ';
                            }
                        }                    
                    });
                    $('#reset_request_email').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_email','keypress');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_email','focus');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('reset_request_email','blur');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_email','keyup');
                        var $REG_EMAIL = $('#reset_request_email').val();
                        if($REG_EMAIL.length>1){
                            var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                            if((U_MGMT == false) ){
                                if (document.getElementById("reset_request_email").value=='') {
                                }
                                if (document.getElementById("reset_request_email").value!='') {
                                }
                                document.getElementById("output_email").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_email").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }else if($REG_EMAIL.length<1){
                            document.getElementById("output_email").className=' ';
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_email','change');
                        var $REG_EMAIL = $('#reset_request_email').val();
                        if($REG_EMAIL.length>1){
                            var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                            if((U_MGMT == false) ){
                                if (document.getElementById("reset_request_email").value=='') {
                                }
                                if (document.getElementById("reset_request_email").value!='') {
                                }
                                document.getElementById("output_email").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_email").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }else if($REG_EMAIL.length<1){
                            document.getElementById("output_email").className=' ';
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_registerCode")){
                    var confirm_code_var = document.getElementById("reset_request_registerCode");
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $( "#reset_request_registerCode" ).unbind().keyup(function(e) {
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        var string = document.getElementById("reset_request_registerCode").value;
                        confirm_code_var.onblur = function() {
                            if($REG_CODE.length==6){
                                document.getElementById("output_code").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                            else if($REG_CODE.length==0){
                                document.getElementById("output_code").className=' ';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_code").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    });
                    $('#reset_request_registerCode').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_registerCode','keypress');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','focus');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','blur');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','keyup');
                        var string = $( "#reset_request_registerCode" ).val();
                        var $STRING = string.toUpperCase();
                        $( "#reset_request_registerCode" ).val($STRING.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'));
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        if($REG_CODE.length==6){
                            document.getElementById("output_code").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                        else if($REG_CODE.length==0){
                            document.getElementById("output_code").className=' ';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_code").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','change');
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        if($REG_CODE.length==6){
                            document.getElementById("output_code").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                        else if($REG_CODE.length==0){
                            document.getElementById("output_code").className=' ';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_code").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function is_review_complete(){
                    CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    if (!$("#reset_request_index1").val()){
                        /* --------------------------------------------------------  */ 
                        if($("#output_email").hasClass("output_email_correct")){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter1").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if(($("#reset_request_formFilter1").val())){
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            /* --------------------------------------------------------  */
                            /* --------------------------------------------------------  
                            $("#reset_request_options2").unbind().click(function(e){
                                e.preventDefault();
                                $("#reset_request_control").val($("#reset_request_registerCode").attr('id')); 
                            });*/
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button');
                            $('#reset_request_options2').attr('disabled',true); 
                        }
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    else if($("#reset_request_index1").val()=='EXPIRED'){
                        /* --------------------------------------------------------  */ 
                        if($("#output_email").hasClass("output_email_correct")){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter1").val(null);
                        }
                        /* --------------------------------------------------------  
                        if($("#output_code").hasClass("output_email_correct")){
                            $("#reset_request_formFilter2").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }*/ 
                        /* --------------------------------------------------------  */ 
                        if(($("#reset_request_formFilter1").val())){//&&($("#reset_request_formFilter2").val())
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            /*
                            $('#reset_request_options1').attr('disabled',false);
                            $('#reset_request_options1').removeClass('ls_form_submit_button');
                            $('#reset_request_options1').addClass('ls_form_submit_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options1");
                            */
                            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options1'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options2').attr('id'));
                            /* --------------------------------------------------------  */
                            /* -------------------------------------------------------- 
                            $("#reset_request_options1").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_email").attr('id')); 
                            }); */
                            /* --------------------------------------------------------  
                            $("#reset_request_options2").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_registerCode").attr('id')); 
                            });*/
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                            $('#reset_request_options1').addClass('ls_form_submit_button');
                            $('#reset_request_options1').attr('disabled',true); 
                        }
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    else if($("#reset_request_index1").val()=='ERROR'){
                        /* --------------------------------------------------------  */ 
                        if($("#output_email").hasClass("output_email_correct")){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter1").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if($("#output_code").hasClass("output_email_correct")){
                            $("#reset_request_formFilter2").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if(($("#reset_request_formFilter1").val())&&($("#reset_request_formFilter2").val())){
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            $('#reset_request_options1').attr('disabled',false);
                            $('#reset_request_options1').removeClass('ls_form_submit_button');
                            $('#reset_request_options1').addClass('ls_form_submit_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options1");
                            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options1'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options2').attr('id'));
                            /* --------------------------------------------------------  */
                            /* --------------------------------------------------------  */
                            $("#reset_request_options1").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_email").attr('id')); 
                            });
                            /* --------------------------------------------------------  
                            $("#reset_request_options2").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_registerCode").attr('id')); 
                            });*/
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                            $('#reset_request_options1').addClass('ls_form_submit_button');
                            $('#reset_request_options1').attr('disabled',true); 
                        }
                    }
                }
			}
		});/**	END OF $(document).ready(function()*/
        $("#reset_request_email").attr("autocomplete", "off");
        $("#reset_request_email").val(null);
        $("#reset_request_email").html(null);
        $("#reset_request_registerCode").attr("autocomplete", "off");
        $("#reset_request_registerCode").val(null);
        $("#reset_request_registerCode").html(null);
        $("#reset_request_password_first").attr("autocomplete", "off");
        $("#reset_request_password_first").val(null);
        $("#reset_request_password_first").html(null);
        $("#reset_request_password_second").attr("autocomplete", "off");
        $("#reset_request_password_second").val(null);
        $("#reset_request_password_second").html(null);
	   	/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('RESET_basics');	
}	
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	RESET_ACTIVATION_basics                     ########################## */
export function RESET_ACTIVATION_basics(){
	
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
       	/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('RESET_ACTIVATION_basics','ACTIVATED');//ACTIVATION_basics
			/* --------------------------------------------------------  */
            $("#reset_request_email").attr("autocomplete", "off");
            $("#reset_request_email").val(null);
            $("#reset_request_email").html(null);
            $("#reset_request_registerCode").attr("autocomplete", "off");
            $("#reset_request_registerCode").val(null);
            $("#reset_request_registerCode").html(null);
            $("#reset_request_password_first").attr("autocomplete", "off");
            $("#reset_request_password_first").val(null);
            $("#reset_request_password_first").html(null);
            $("#reset_request_password_second").attr("autocomplete", "off");
            $("#reset_request_password_second").val(null);
            $("#reset_request_password_second").html(null);
            /* --------------------------------------------------------  */
            if(!$("#reset_request_index1").val()){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#reset_request_index1").val()=='EXPIRED'){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#reset_request_index1").val()=='ERROR'){
                $('#reset_request_options1').attr('disabled',true); 
                $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                $('#reset_request_options1').addClass('ls_form_submit_button');

                $('#reset_request_options2').attr('disabled',true); 
                $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#reset_request_options2').addClass('ls_form_submit_long_button');
            }
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
            SET_BUTTON_CLICK_ACTION("Security_form1_options1");
            $("#label_Security_form1_options1").unbind().click(function(){
                $("#Security_form1_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
            SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            $("#label_Security_form2_options1").unbind().click(function(){
                $("#Security_form2_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("reset_request_options3");
            $("#reset_request_options3").unbind().click(function(){
                $('#reset_request_control').val($('#reset_request_options3').attr('id'));
            });
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('.Reset_UI_class').is(":visible")){
                $('.Reset_UI_class').attr("id", "Reset_UI_control");
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_email")){
                    var confirm_email_var = document.getElementById("reset_request_email");
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $( "#reset_request_email" ).unbind().keyup(function(e) {
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        var $REG_EMAIL = $('#reset_request_email').val();
                        var string = document.getElementById("reset_request_email").value;
                        confirm_email_var.onblur = function() {
                            if($REG_EMAIL.length>1){
                                var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                                if((U_MGMT == false) ){
                                    if (document.getElementById("reset_request_email").value=='') {
                                    }
                                    if (document.getElementById("reset_request_email").value!='') {
                                    }
                                    document.getElementById("output_email").className='output_email_error';
                                    setTimeout(is_review_complete, 500);
                                }else{
                                    document.getElementById("output_email").className='output_email_correct';
                                    setTimeout(is_review_complete, 500);
                                }
                            }else if($REG_EMAIL.length<1){
                                document.getElementById("output_email").className=' ';
                            }
                        }                    
                    });
                    $('#reset_request_email').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_email','keypress');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_email','focus');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('reset_request_email','blur');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_email','keyup');
                        var $REG_EMAIL = $('#reset_request_email').val();
                        if($REG_EMAIL.length>1){
                            var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                            if((U_MGMT == false) ){
                                if (document.getElementById("reset_request_email").value=='') {
                                }
                                if (document.getElementById("reset_request_email").value!='') {
                                }
                                document.getElementById("output_email").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_email").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }else if($REG_EMAIL.length<1){
                            document.getElementById("output_email").className=' ';
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_email','change');
                        var $REG_EMAIL = $('#reset_request_email').val();
                        if($REG_EMAIL.length>1){
                            var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                            if((U_MGMT == false) ){
                                if (document.getElementById("reset_request_email").value=='') {
                                }
                                if (document.getElementById("reset_request_email").value!='') {
                                }
                                document.getElementById("output_email").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_email").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }else if($REG_EMAIL.length<1){
                            document.getElementById("output_email").className=' ';
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_registerCode")){
                    var confirm_code_var = document.getElementById("reset_request_registerCode");
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $( "#reset_request_registerCode" ).unbind().keyup(function(e) {
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        var string = document.getElementById("reset_request_registerCode").value;
                        confirm_code_var.onblur = function() {
                            if($REG_CODE.length==6){
                                document.getElementById("output_code").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                            else if($REG_CODE.length==0){
                                document.getElementById("output_code").className=' ';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_code").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }
                        }
                    });
                    $('#reset_request_registerCode').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_registerCode','keypress');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','focus');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('blur', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','blur');
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','keyup');
                        var string = $( "#reset_request_registerCode" ).val();
                        var $STRING = string.toUpperCase();
                        $( "#reset_request_registerCode" ).val($STRING.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'));
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        if($REG_CODE.length==6){
                            document.getElementById("output_code").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                        else if($REG_CODE.length==0){
                            document.getElementById("output_code").className=' ';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_code").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_registerCode','change');
                        var $REG_CODE = $('#reset_request_registerCode').val();
                        if($REG_CODE.length==6){
                            document.getElementById("output_code").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                        else if($REG_CODE.length==0){
                            document.getElementById("output_code").className=' ';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_code").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_password_first")){
                    var reg_pass1_var = document.getElementById("reset_request_password_first");	
                    reg_pass1_var.onfocus = function() {
                        //UPDATE_CLASS($("#output_pass1"), "output_password_default", ' ');
                    }
                    reg_pass1_var.onblur = function() {
                        var reg_pass1_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("reset_request_password_first").value);
                        if(P_MGMT == true){ 
                            if($("#output_pass1.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass1"), "output_password_correct", " ");
                                setTimeout(is_review_complete, 500);
                            }
                        }else{
                            if (document.getElementById("reset_request_password_first").value=='') {
                            }
                            if (document.getElementById("reset_request_password_first").value!='') {
                                if( $("#output_pass1.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass1"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#reset_request_password_first').val(),$('#reset_request_password_second').val());
                    }
                    $('#reset_request_password_first').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_password_first','keypress');
                        trigg_pass_match();
                        checkVal("output_pass1");
                        //checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_password_first','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_password_first','keyup');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        //outputResult("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_password_first','change');
                        trigg_pass_match();
                        outputResult("output_pass1");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("reset_request_password_second")){
                    var reg_pass2_var = document.getElementById("reset_request_password_second");
                    reg_pass2_var.onfocus = function() {
                    }
                    reg_pass2_var.onblur = function() {
                        var reg_pass2_var_filler_holder1 = +this.value;
                        var P_MGMT = sys_password_check(document.getElementById("reset_request_password_second").value);
                        if(P_MGMT){ 
                            if($("#output_pass2.strong") || $("#output_pass2.stronger") || $("#output_pass2.strongest")){
                                UPDATE_CLASS($("#output_pass2"), "output_password_correct", " ");
                            }
                            trigg_pass_match();
                            setTimeout(is_review_complete, 500);
                        }else{
                            if (document.getElementById("reset_request_password_second").value=='') {
                            }
                            if (document.getElementById("reset_request_password_second").value!='') {
                                if( $("#output_pass2.default") || $("#output_pass2.weak") ){
                                    UPDATE_CLASS($("#output_pass2"), "output_password_error", " ");
                                }
                            }
                        }
                        sys_pass_match_check($('#reset_request_password_first').val(),$('#reset_request_password_second').val());
                    }
                    $('#reset_request_password_second').on('keypress', function(e) {
                        CONSOLE_MANAGER('reset_request_password_second','keypress');
                        trigg_pass_match();
                        //checkVal("output_pass1");
                        checkVal("output_pass2");
                        setTimeout(is_review_complete, 500);
                    })
                    .on('focus', function() {
                        CONSOLE_MANAGER('reset_request_password_second','focus');
                        trigg_pass_match();
                        setTimeout(is_review_complete, 500);
                    })
                    .on('keyup', function() {
                        CONSOLE_MANAGER('reset_request_password_second','keyup');
                        trigg_pass_match();
                        //outputResult("output_pass1");
                        outputResult("output_pass2"); 
                        setTimeout(is_review_complete, 500);
                    })
                    .on('change', function() {
                        CONSOLE_MANAGER('reset_request_password_second','change');
                        trigg_pass_match();
                        outputResult("output_pass2");
                        setTimeout(is_review_complete, 500);
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var complexity_pass1 = $("#output_pass1");
                var complexity_pass2 = $("#output_pass2");
                var strPassword;
                var charPassword;
                var minPasswordLength = 8;
                var baseScore = 0, score = 0;
                var num = {};
                num.Excess = 0;
                num.Upper = 0;
                num.Numbers = 0;
                num.Symbols = 0;
                var bonus = {};
                bonus.Excess = 3;
                bonus.Upper = 4;
                bonus.Numbers = 5;
                bonus.Symbols = 5;
                bonus.Combo = 0; 
                bonus.FlatLower = 0;
                bonus.FlatNumber = 0;
                /* --------------------------------------------------------  */
                if($("#reset_request_password_first").val()){
                   outputResult("output_pass1");
                }
                /* --------------------------------------------------------  */
                if($("#reset_request_password_second").val()){
                   outputResult("output_pass2"); 
                }
                /* --------------------------------------------------------  */
                $("#reset_request_password_first").bind("keyup change", function(e) {
                    if($("#reset_request_password_first").val()){
                       checkVal("output_pass1"); 
                    }				
                })
                $("#reset_request_password_second").bind("keyup change", function(e) {
                    if($("#reset_request_password_second").val()){
                        checkVal("output_pass2"); 
                    }
                })
                /*************/
                function checkVal(selection)
                {
                    if(selection =="output_pass1"){
                        init("output_pass1");
                    }
                    if(selection =="output_pass2"){
                        init("output_pass2");
                    }
                    if(charPassword){
                        if (charPassword.length >= minPasswordLength)
                        {
                            baseScore = 50;	
                            analyzeString();	
                            calcComplexity();		
                        }
                    }else
                    {
                        baseScore = 0;
                    }
                    outputResult(selection);
                }
                /*************/
                function init(selection)
                {
                    if(selection =="output_pass1"){
                        if($("#reset_request_password_first").val()){
                            strPassword= $("#reset_request_password_first").val();
                            charPassword = strPassword.split("");
                            num.Excess = 0;
                            num.Upper = 0;
                            num.Numbers = 0;
                            num.Symbols = 0;
                            bonus.Combo = 0; 
                            bonus.FlatLower = 0;
                            bonus.FlatNumber = 0;
                            baseScore = 0;
                            score =0;
                        }
                    }
                    if(selection =="output_pass2"){
                        if($("#reset_request_password_second").val()){
                            strPassword= $("#reset_request_password_second").val();
                            charPassword = strPassword.split("");
                            num.Excess = 0;
                            num.Upper = 0;
                            num.Numbers = 0;
                            num.Symbols = 0;
                            bonus.Combo = 0; 
                            bonus.FlatLower = 0;
                            bonus.FlatNumber = 0;
                            baseScore = 0;
                            score =0;
                        }
                    }
                }
                /*************/
                function analyzeString ()
                {	
                    for (var i=0; i<charPassword.length;i++)
                    {
                        if (charPassword[i].match(/[A-Z]/g)) {num.Upper++;}
                        if (charPassword[i].match(/[0-9]/g)) {num.Numbers++;}
                        if (charPassword[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) {num.Symbols++;} 
                    }
                    num.Excess = charPassword.length - minPasswordLength;
                    if (num.Upper && num.Numbers && num.Symbols)
                    {
                        bonus.Combo = 25; 
                    }
                    else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols))
                    {
                        bonus.Combo = 15; 
                    }
                    if (strPassword.match(/^[\sa-z]+$/))
                    { 
                        bonus.FlatLower = -15;
                    }
                    if (strPassword.match(/^[\s0-9]+$/))
                    { 
                        bonus.FlatNumber = -35;
                    }
                }
                /*************/
                function calcComplexity()
                {
                    score = baseScore + (num.Excess*bonus.Excess) + (num.Upper*bonus.Upper) + (num.Numbers*bonus.Numbers) + (num.Symbols*bonus.Symbols) + bonus.Combo + bonus.FlatLower + bonus.FlatNumber;
                }	
                /*************/
                function outputResult(selection)
                {
                    var PASS_VALIDATE=null;
                    /* --------------------------------------------------------  */
                    if(selection =='output_pass1'){
                        /* --------------------------------------------------------  */
                        if ($("#reset_request_password_first").val()== "")
                        { 
                            UPDATE_CLASS(complexity_pass1, "weak", " ");
                            PASS_VALIDATE = false;
                        }
                        else if ((charPassword) && (charPassword.length < minPasswordLength))
                        {
                            UPDATE_CLASS(complexity_pass1, "weak", "At least " + minPasswordLength+ " characters please!");
                            PASS_VALIDATE = false;
                        }
                        else if (score<50)
                        {
                            UPDATE_CLASS(complexity_pass1, "weak", "Weak!");
                            PASS_VALIDATE = false;
                        }
                        else if (score>=50 && score<75)
                        {
                            UPDATE_CLASS(complexity_pass1, "strong", "Average!");
                            PASS_VALIDATE = true;
                        }
                        else if (score>=75 && score<100)
                        {
                            UPDATE_CLASS(complexity_pass1, "stronger", "Strong!");
                            PASS_VALIDATE = true;
                        }
                        else if (score>=100)
                        {
                            UPDATE_CLASS(complexity_pass1, "strongest", "Secure!");
                            PASS_VALIDATE = true;
                        }
                    }
                    /* --------------------------------------------------------  */
                    if(selection =='output_pass2'){
                        /* --------------------------------------------------------  */
                        if ($("#reset_request_password_second").val()== "")
                        { 
                            UPDATE_CLASS(complexity_pass2, "weak", " ");
                            PASS_VALIDATE = false;
                        }
                        else if ((charPassword) && (charPassword.length < minPasswordLength))
                        {
                            UPDATE_CLASS(complexity_pass2, "weak", "At least " + minPasswordLength+ " characters please!");
                            PASS_VALIDATE = false;	
                        }
                        else if (score<50)
                        {
                            UPDATE_CLASS(complexity_pass2, "weak", "Weak!");
                            PASS_VALIDATE = false;
                        }
                        else if (score>=50 && score<75)
                        {
                            UPDATE_CLASS(complexity_pass2, "strong", "Average!");
                            PASS_VALIDATE = true;
                        }
                        else if (score>=75 && score<100)
                        {
                            UPDATE_CLASS(complexity_pass2, "stronger", "Strong!");
                            PASS_VALIDATE = true;
                        }
                        else if (score>=100)
                        {
                            UPDATE_CLASS(complexity_pass2, "strongest", "Secure!");
                            PASS_VALIDATE = true;
                        }
                    }
                    setTimeout(is_review_complete, 500);
                }
                /*************/
                function UPDATE_CLASS(element_var, add_class, new_text){
                    element_var.removeClass("default weak strong stronger strongest output_password_default output_password_correct ");
                    element_var.removeClass("output_password_error output_match_error_top output_match_error_bottom");
                    element_var.addClass(add_class);
                    element_var.html(new_text);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                function is_review_complete(){
                    CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    if (!$("#reset_request_index1").val()){
                        /* --------------------------------------------------------  */
                        var U_MGMT = sys_email_check(document.getElementById("reset_request_email").value);
                        CONSOLE_MANAGER('is_review_complete - sys_email_check',$("#reset_request_email").val());
                        CONSOLE_MANAGER('is_review_complete - sys_email_check',U_MGMT+'  -----  '+$("#reset_request_index1").val());
                        if((U_MGMT != false) ){   
                        //if(sys_email_check(document.getElementById("reset_request_email").value)){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if($("#output_code").hasClass("output_email_correct")){
                            $("#reset_request_formFilter2").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if($("#output_pass1").hasClass("output_password_correct")){
                            $("#reset_request_formFilter3").val(true);
                        }else{
                            $("#reset_request_formFilter3").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if($("#output_pass2").hasClass("output_password_correct")){
                            $("#reset_request_formFilter4").val(true);
                        }else{
                            $("#reset_request_formFilter4").val(null);
                        }
                        /* --------------------------------------------------------  */
                        //($("#reset_request_formFilter1").val())&&
                        if(($("#reset_request_formFilter2").val())&&($("#reset_request_formFilter3").val())&&($("#reset_request_formFilter4").val())){
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            $('#reset_request_options1').attr('disabled',false);
                            $('#reset_request_options1').removeClass('ls_form_submit_button');
                            $('#reset_request_options1').addClass('ls_form_submit_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options1");
                            $("#reset_request_options1").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_options1").attr('id')); 
                            });
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            /* --------------------------------------------------------  */
                            /* --------------------------------------------------------  */
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button_active');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button');
                            $('#reset_request_options2').attr('disabled',true); 
                        }
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    else if($("#reset_request_index1").val()=='EXPIRED'){
                        /* --------------------------------------------------------  */ 
                        if($("#output_email").hasClass("output_email_correct")){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter1").val(null);
                        }
                        /* --------------------------------------------------------  
                        if($("#output_code").hasClass("output_email_correct")){
                            $("#reset_request_formFilter2").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }*/ 
                        /* --------------------------------------------------------  */ 
                        if(($("#reset_request_formFilter1").val())){//&&($("#reset_request_formFilter2").val())
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            /*
                            $('#reset_request_options1').attr('disabled',false);
                            $('#reset_request_options1').removeClass('ls_form_submit_button');
                            $('#reset_request_options1').addClass('ls_form_submit_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options1");
                            */
                            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options1'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options2').attr('id'));
                            /* --------------------------------------------------------  */
                            /* -------------------------------------------------------- 
                            $("#reset_request_options1").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_email").attr('id')); 
                            }); */
                            /* --------------------------------------------------------  
                            $("#reset_request_options2").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_registerCode").attr('id')); 
                            });*/
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                            $('#reset_request_options1').addClass('ls_form_submit_button');
                            $('#reset_request_options1').attr('disabled',true); 
                        }
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    else if($("#reset_request_index1").val()=='ERROR'){
                        /* --------------------------------------------------------  */ 
                        if($("#output_email").hasClass("output_email_correct")){
                            $("#reset_request_formFilter1").val(true);
                        }else{
                            $("#reset_request_formFilter1").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if($("#output_code").hasClass("output_email_correct")){
                            $("#reset_request_formFilter2").val(true);
                        }else{
                            $("#reset_request_formFilter2").val(null);
                        }
                        /* --------------------------------------------------------  */ 
                        if(($("#reset_request_formFilter1").val())&&($("#reset_request_formFilter2").val())){
                            CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#reset_request_index1").val());
                            $('#reset_request_options1').attr('disabled',false);
                            $('#reset_request_options1').removeClass('ls_form_submit_button');
                            $('#reset_request_options1').addClass('ls_form_submit_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options1");
                            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options1'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options1').attr('id'));
                            $('#reset_request_options2').attr('disabled',false);
                            $('#reset_request_options2').removeClass('ls_form_submit_long_button');
                            $('#reset_request_options2').addClass('ls_form_submit_long_button_active');
                            SET_BUTTON_CLICK_ACTION("reset_request_options2");
                            NOTIFICATIONSYSTEM_LOAD_MGMT($('#reset_request_options2'),null,null,null,null);
                            //$('#standard_entity_modify_control').val($('#reset_request_options2').attr('id'));
                            /* --------------------------------------------------------  */
                            /* --------------------------------------------------------  */
                            $("#reset_request_options1").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_email").attr('id')); 
                            });
                            /* --------------------------------------------------------  
                            $("#reset_request_options2").unbind().click(function(){
                                $("#reset_request_control").val($("#reset_request_registerCode").attr('id')); 
                            });*/
                        }else{
                            CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#reset_request_index1").val());
                            CONSOLE_MANAGER('is_review_complete','-1: '+$("#reset_request_formFilter1").val()+' - 2: '+$("#reset_request_formFilter2").val());
                            $('#reset_request_options1').removeClass('ls_form_submit_button_active');
                            $('#reset_request_options1').addClass('ls_form_submit_button');
                            $('#reset_request_options1').attr('disabled',true); 
                        }
                    }
                }
			}
		});/**	END OF $(document).ready(function()*/
        $("#reset_request_email").attr("autocomplete", "off");
        $("#reset_request_email").val(null);
        $("#reset_request_email").html(null);
        $("#reset_request_registerCode").attr("autocomplete", "off");
        $("#reset_request_registerCode").val(null);
        $("#reset_request_registerCode").html(null);
        $("#reset_request_password_first").attr("autocomplete", "off");
        $("#reset_request_password_first").val(null);
        $("#reset_request_password_first").html(null);
        $("#reset_request_password_second").attr("autocomplete", "off");
        $("#reset_request_password_second").val(null);
        $("#reset_request_password_second").html(null);
	   	/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('RESET_ACTIVATION_basics');	
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ACTIVATION_basics                           ########################## */
export function ACTIVATION_basics(){
	
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
       	/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('ACTIVATION_basics','ACTIVATED');//$("#inputEmail").focus();
			/* --------------------------------------------------------  */
            $("#confirmation_request_email").attr("autocomplete", "off");
            $("#confirmation_request_email").val(null);
            $("#confirmation_request_email").html(null);
            $("#confirmation_request_registerCode").attr("autocomplete", "off");
            $("#confirmation_request_registerCode").val(null);
            $("#confirmation_request_registerCode").html(null);
            /* --------------------------------------------------------  */
            if(!$("#confirmation_request_index1").val()){
                $('#confirmation_request_options1').attr('disabled',true); 
                $('#confirmation_request_options1').removeClass('ls_form_submit_button_active');
                $('#confirmation_request_options1').addClass('ls_form_submit_button');

                $('#confirmation_request_options2').attr('disabled',true); 
                $('#confirmation_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#confirmation_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#confirmation_request_index1").val()=='EXPIRED'){
                $('#confirmation_request_options1').attr('disabled',true); 
                $('#confirmation_request_options1').removeClass('ls_form_submit_button_active');
                $('#confirmation_request_options1').addClass('ls_form_submit_button');

                $('#confirmation_request_options2').attr('disabled',true); 
                $('#confirmation_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#confirmation_request_options2').addClass('ls_form_submit_long_button');
            }
            if($("#confirmation_request_index1").val()=='ERROR'){
                $('#confirmation_request_options1').attr('disabled',true); 
                $('#confirmation_request_options1').removeClass('ls_form_submit_button_active');
                $('#confirmation_request_options1').addClass('ls_form_submit_button');

                $('#confirmation_request_options2').attr('disabled',true); 
                $('#confirmation_request_options2').removeClass('ls_form_submit_long_button_active');
                $('#confirmation_request_options2').addClass('ls_form_submit_long_button');
            }
            $('#confirmation_request_options3').attr('disabled',true); 
            $('#confirmation_request_options3').removeClass('ls_form_submit_button_active');
            $('#confirmation_request_options3').addClass('ls_form_submit_button');
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form1_options1");
            SET_BUTTON_CLICK_ACTION("Security_form1_options1");
            $("#label_Security_form1_options1").unbind().click(function(){
                $("#Security_form1_options1").click();
            });
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("label_Security_form2_options1");
            SET_BUTTON_CLICK_ACTION("Security_form2_options1");
            $("#label_Security_form2_options1").unbind().click(function(){
                $("#Security_form2_options1").click();
            });
            
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            $('.Reset_UI_class').attr("id", "Reset_UI_control");
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("confirmation_request_email")){
                var confirm_email_var = document.getElementById("confirmation_request_email");
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                $( "#confirmation_request_email" ).unbind().keyup(function(e) {
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    var $REG_EMAIL = $('#confirmation_request_email').val();
                    var string = document.getElementById("confirmation_request_email").value;
                    confirm_email_var.onblur = function() {
                        if($REG_EMAIL.length>1){
                            var U_MGMT = sys_email_check(document.getElementById("confirmation_request_email").value);
                            if((U_MGMT == false) ){
                                if (document.getElementById("confirmation_request_email").value=='') {
                                }
                                if (document.getElementById("confirmation_request_email").value!='') {
                                }
                                document.getElementById("output_email").className='output_email_error';
                                setTimeout(is_review_complete, 500);
                            }else{
                                document.getElementById("output_email").className='output_email_correct';
                                setTimeout(is_review_complete, 500);
                            }
                        }else if($REG_EMAIL.length<1){
                            document.getElementById("output_email").className=' ';
                        }
                    }                    
                });
                $('#confirmation_request_email').on('keypress', function(e) {
                    CONSOLE_MANAGER('confirmation_request_email','keypress');
                    setTimeout(is_review_complete, 500);
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('confirmation_request_email','focus');
                    setTimeout(is_review_complete, 500);
                })
                .on('blur', function() {
                    CONSOLE_MANAGER('confirmation_request_email','blur');
                    setTimeout(is_review_complete, 500);
                })
                .on('keyup', function() {
                    CONSOLE_MANAGER('confirmation_request_email','keyup');
                    var $REG_EMAIL = $('#confirmation_request_email').val();
                    if($REG_EMAIL.length>1){
                        var U_MGMT = sys_email_check(document.getElementById("confirmation_request_email").value);
                        if((U_MGMT == false) ){
                            if (document.getElementById("confirmation_request_email").value=='') {
                            }
                            if (document.getElementById("confirmation_request_email").value!='') {
                            }
                            document.getElementById("output_email").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                    }else if($REG_EMAIL.length<1){
                        document.getElementById("output_email").className=' ';
                    }
                    setTimeout(is_review_complete, 500);
                })
                .on('change', function() {
                    CONSOLE_MANAGER('confirmation_request_email','change');
                    var $REG_EMAIL = $('#confirmation_request_email').val();
                    if($REG_EMAIL.length>1){
                        var U_MGMT = sys_email_check(document.getElementById("confirmation_request_email").value);
                        if((U_MGMT == false) ){
                            if (document.getElementById("confirmation_request_email").value=='') {
                            }
                            if (document.getElementById("confirmation_request_email").value!='') {
                            }
                            document.getElementById("output_email").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_email").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                    }else if($REG_EMAIL.length<1){
                        document.getElementById("output_email").className=' ';
                    }
                    setTimeout(is_review_complete, 500);
                });
            }
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("confirmation_request_registerCode")){
                var confirm_code_var = document.getElementById("confirmation_request_registerCode");
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                $( "#confirmation_request_registerCode" ).unbind().keyup(function(e) {
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    var $REG_CODE = $('#confirmation_request_registerCode').val();
                    var string = document.getElementById("confirmation_request_registerCode").value;
                    confirm_code_var.onblur = function() {
                        if($REG_CODE.length==6){
                            document.getElementById("output_code").className='output_email_correct';
                            setTimeout(is_review_complete, 500);
                        }
                        else if($REG_CODE.length==0){
                            document.getElementById("output_code").className=' ';
                            setTimeout(is_review_complete, 500);
                        }else{
                            document.getElementById("output_code").className='output_email_error';
                            setTimeout(is_review_complete, 500);
                        }
                    }
                });
                $('#confirmation_request_registerCode').on('keypress', function(e) {
                    CONSOLE_MANAGER('confirmation_request_registerCode','keypress');
                    setTimeout(is_review_complete, 500);
                })
                .on('focus', function() {
                    CONSOLE_MANAGER('confirmation_request_registerCode','focus');
                    setTimeout(is_review_complete, 500);
                })
                .on('blur', function() {
                    CONSOLE_MANAGER('confirmation_request_registerCode','blur');
                    setTimeout(is_review_complete, 500);
                })
                .on('keyup', function() {
                    CONSOLE_MANAGER('confirmation_request_registerCode','keyup');
                    var string = $( "#confirmation_request_registerCode" ).val();
                    var $STRING = string.toUpperCase();
                    $( "#confirmation_request_registerCode" ).val($STRING.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '-'));
                    var $REG_CODE = $('#confirmation_request_registerCode').val();
                    if($REG_CODE.length==6){
                        document.getElementById("output_code").className='output_email_correct';
                        setTimeout(is_review_complete, 500);
                    }
                    else if($REG_CODE.length==0){
                        document.getElementById("output_code").className=' ';
                        setTimeout(is_review_complete, 500);
                    }else{
                        document.getElementById("output_code").className='output_email_error';
                        setTimeout(is_review_complete, 500);
                    }
                    setTimeout(is_review_complete, 500);
                })
                .on('change', function() {
                    CONSOLE_MANAGER('confirmation_request_registerCode','change');
                    var $REG_CODE = $('#confirmation_request_registerCode').val();
                    if($REG_CODE.length==6){
                        document.getElementById("output_code").className='output_email_correct';
                        setTimeout(is_review_complete, 500);
                    }
                    else if($REG_CODE.length==0){
                        document.getElementById("output_code").className=' ';
                        setTimeout(is_review_complete, 500);
                    }else{
                        document.getElementById("output_code").className='output_email_error';
                        setTimeout(is_review_complete, 500);
                    }
                    setTimeout(is_review_complete, 500);
                });
            }
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            function is_review_complete(){
                CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if (!$("#confirmation_request_index1").val()){
                    /* --------------------------------------------------------  */ 
                    if($("#output_email").hasClass("output_email_correct")){
                        $("#confirmation_request_formFilter1").val(true);
                    }else{
                        $("#confirmation_request_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_code").hasClass("output_email_correct")){
                        $("#confirmation_request_formFilter2").val(true);
                    }else{
                        $("#confirmation_request_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if(($("#confirmation_request_formFilter1").val())&&($("#confirmation_request_formFilter2").val())){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        $('#confirmation_request_options1').attr('disabled',false);
                        $('#confirmation_request_options1').removeClass('ls_form_submit_button');
                       	$('#confirmation_request_options1').addClass('ls_form_submit_button_active');
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options1");
                        //NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options1'),null,null,null,null);
                        //$('#standard_entity_modify_control').val($('#confirmation_request_options1').attr('id'));
                        $('#confirmation_request_options2').attr('disabled',false);
                        $('#confirmation_request_options2').removeClass('ls_form_submit_long_button');
                       	$('#confirmation_request_options2').addClass('ls_form_submit_long_button_active');
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options2");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options2'),null,null,null,null);
                        //$('#standard_entity_modify_control').val($('#confirmation_request_options2').attr('id'));
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $("#confirmation_request_options1").unbind().click(function(){
                            $("#confirmation_request_control").val($("#confirmation_request_email").attr('id')); 
                        });
                        /* --------------------------------------------------------  
                        $("#confirmation_request_options2").unbind().click(function(){
                            $("#confirmation_request_control").val($("#confirmation_request_registerCode").attr('id')); 
                        });*/
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else if($("#confirmation_request_formFilter1").val()){
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#confirmation_request_formFilter1").val()+' - 2: '+$("#confirmation_request_formFilter2").val());
                        $('#confirmation_request_options1').removeClass('ls_form_submit_button_active');
                       	$('#confirmation_request_options1').addClass('ls_form_submit_button');
                        $('#confirmation_request_options1').attr('disabled',true); 
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                else if($("#confirmation_request_index1").val()=='EXPIRED'){
                    /* --------------------------------------------------------  */ 
                    if($("#output_email").hasClass("output_email_correct")){
                        $("#confirmation_request_formFilter1").val(true);
                    }else{
                        $("#confirmation_request_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if(($("#confirmation_request_formFilter1").val())){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        $('#confirmation_request_options2').attr('disabled',false);
                        $('#confirmation_request_options2').removeClass('ls_form_submit_long_button');
                       	$('#confirmation_request_options2').addClass('ls_form_submit_long_button_active');
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options2");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options2'),null,null,null,null);
                        //$('#standard_entity_modify_control').val($('#confirmation_request_options1').attr('id'));
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  
                        $("#confirmation_request_options2").unbind().click(function(e){
                            e.preventDefault();
                            $("#confirmation_request_control").val($("#confirmation_request_registerCode").attr('id')); 
                        });*/
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else if($("#confirmation_request_formFilter1").val()){
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#confirmation_request_formFilter1").val()+' - 2: '+$("#confirmation_request_formFilter2").val());
                        $('#confirmation_request_options2').removeClass('ls_form_submit_long_button_active');
                       	$('#confirmation_request_options2').addClass('ls_form_submit_long_button');
                        $('#confirmation_request_options2').attr('disabled',true); 
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                else if($("#confirmation_request_index1").val()=='ERROR'){
                    /* --------------------------------------------------------  */ 
                    if($("#output_email").hasClass("output_email_correct")){
                        $("#confirmation_request_formFilter1").val(true);
                    }else{
                        $("#confirmation_request_formFilter1").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if($("#output_code").hasClass("output_email_correct")){
                        $("#confirmation_request_formFilter2").val(true);
                    }else{
                        $("#confirmation_request_formFilter2").val(null);
                    }
                    /* --------------------------------------------------------  */ 
                    if(($("#confirmation_request_formFilter1").val())&&($("#confirmation_request_formFilter2").val())){
                        CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        $('#confirmation_request_options1').attr('disabled',false);
                        $('#confirmation_request_options1').removeClass('ls_form_submit_button');
                       	$('#confirmation_request_options1').addClass('ls_form_submit_button_active');
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options1");
                        //NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options1'),null,null,null,null);
                        //$('#standard_entity_modify_control').val($('#confirmation_request_options1').attr('id'));
                        $('#confirmation_request_options2').attr('disabled',false);
                        $('#confirmation_request_options2').removeClass('ls_form_submit_long_button');
                       	$('#confirmation_request_options2').addClass('ls_form_submit_long_button_active');
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options2");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options2'),null,null,null,null);
                        //$('#standard_entity_modify_control').val($('#confirmation_request_options2').attr('id'));
                        /* --------------------------------------------------------  */
                        /* --------------------------------------------------------  */
                        $("#confirmation_request_options1").unbind().click(function(){
                            $("#confirmation_request_control").val($("#confirmation_request_email").attr('id')); 
                        });
                        /* --------------------------------------------------------  
                        $("#confirmation_request_options2").unbind().click(function(){
                            $("#confirmation_request_control").val($("#confirmation_request_registerCode").attr('id')); 
                        });*/
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else if($("#confirmation_request_formFilter1").val()){
                        /* --------------------------------------------------------  */
                        $('#confirmation_request_options3').attr('disabled',false);
                        SET_BUTTON_CLICK_ACTION("confirmation_request_options3");
                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#confirmation_request_options3'),null,null,null,null);
                        //$("#confirmation_request_options3").unbind().click(function(){
                        //    $('#confirmation_request_control').val($('#confirmation_request_options3').attr('id'));
                        //});
                    }else{
                        CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ---------- '+$("#confirmation_request_index1").val());
                        CONSOLE_MANAGER('is_review_complete','-1: '+$("#confirmation_request_formFilter1").val()+' - 2: '+$("#confirmation_request_formFilter2").val());
                        $('#confirmation_request_options1').removeClass('ls_form_submit_button_active');
                       	$('#confirmation_request_options1').addClass('ls_form_submit_button');
                        $('#confirmation_request_options1').attr('disabled',true); 
                    }
                }
            }
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
        $("#confirmation_request_email").attr("autocomplete", "off");
        $("#confirmation_request_email").val(null);
        $("#confirmation_request_email").html(null);
        $("#confirmation_request_registerCode").attr("autocomplete", "off");
        $("#confirmation_request_registerCode").val(null);
        $("#confirmation_request_registerCode").html(null);
	   	/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('ACTIVATION_basics');	
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/