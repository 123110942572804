/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  
/*  
/*  SETTINGS_METRICS_CONTROL
/*  WINDOW_PROFILE_METRICS_CONTROL_basics
/*	FEED_METRICS_CONTROL_basics
/*  SOCIAL_METRICS_CONTROL_basics
/*  PROFILE_METRICS_CONTROL_basics
/*  EVENT_METRICS_CONTROL_basics
/*  
/*  
/*
/*  METRICS_SCORE_REPORT01
/*  METRICS_SCORE_REPORT02
/*  METRICS_SCORE_REPORT03
/*  METRICS_SCORE_REPORT04
/*  METRICS_SCORE_REPORT05
/*  METRICS_SCORE_REPORT06
/*  METRICS_SCORE_SPAN
/*
/*  
/*
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

/********************/
/********************/
//import Chart from 'chart.js/auto';
//var Chart = require('chart.js');
import Chart from 'chart.js/auto';
import { getRelativePosition } from 'chart.js/helpers'

var momenttz = require('moment-timezone');
import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { feed_translate_ajax_data as feed_translate_ajax_data } from "./mgmt_control.js";

import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { starttime_window_open as starttime_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { initiate_starttime_creator as initiate_starttime_creator } from "./mgmt_control.js";
import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { activate_Hammer_swipe_MGMT as activate_Hammer_swipe_MGMT } from "./mgmt_control.js";
import { option_page_men as option_page_men } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";
import { event_item_map_activate as event_item_map_activate} from "./mgmt_mapping.js";
import { map_identity_event_control as map_identity_event_control} from "./mgmt_mapping.js";
import { metrics_meta_map_activate as metrics_meta_map_activate} from "./mgmt_mapping.js";

import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { duration as duration } from "./mgmt_control.js";
import { micro_duration as micro_duration } from "./mgmt_control.js";

import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { text_to_field as text_to_field } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	PROFILE_METRICS_CONTROL_basics              ########################## */
export function PROFILE_METRICS_CONTROL_basics($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('PROFILE_METRICS_CONTROL_basics','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine=null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.9)',
                orange: 'rgb(255, 159, 64, 0.9)',
                yellow: 'rgb(255, 205, 86, 0.9)',
                green: 'rgb(75, 192, 192, 0.9)',
                blue: 'rgb(54, 162, 235, 0.9)',
                purple: 'rgb(153, 102, 255, 0.9)',
                grey: 'rgb(201, 203, 207, 0.9)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            //values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('PROFILE_METRICS_CONTROL_basics','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());

                CONSOLE_MANAGER('PROFILE_METRICS_CONTROL_basics','Data01: '+JSON.stringify(randomScalingFactor())+'Data02: '+JSON.stringify(randomScalingFactor())+'Data03: '+JSON.stringify(randomScalingFactor())+'Data04: '+JSON.stringify(randomScalingFactor())+'Data05: '+JSON.stringify(randomScalingFactor()));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                            ],
                            backgroundColor: [
                                window.chartColors.red,
                                window.chartColors.orange,
                                window.chartColors.yellow,
                                window.chartColors.green,
                                window.chartColors.blue,
                            ],
                            //label: 'Dataset 1'
                        }],
                        /*
                        labels: [
                            'Red',
                            'Orange',
                            'Yellow',
                            'Green',
                            'Blue'
                        ]
                        */                    
                    },

                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Doughnut Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        }
                    }
                };
                (function(event) {
                    if(myLine){
                        myLine.destroy(); 
                    }
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                     ########################## */
export function SETTINGS_METRICS_CONTROL(){
    CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','ACTIVATED');
	/*
		https://www.chartjs.org/samples/latest/
	
		//"rgb(102, 250, 60)"
		//https://www.chartjs.org/samples/latest/scales/time/line.html
		//https://www.chartjs.org/samples/latest/scales/time/combo.html
		//https://www.chartjs.org/samples/latest/charts/area/line-datasets.html
		//https://www.chartjs.org/samples/latest/charts/scatter/basic.html
		//https://www.chartjs.org/samples/latest/charts/polar-area.html
		//https://www.chartjs.org/samples/latest/scriptable/bubble.html
		//https://www.chartjs.org/samples/latest/scriptable/pie.html
		//https://www.chartjs.org/samples/latest/charts/doughnut.html
	
	*/
	var $i=6;
    for($i; $i<16; $i++){
        /* ------------------------------------------------- */
        /* ------------------------------------------------- */
        if(document.getElementById('account_metrics_options'+$i+'active')){
            /* ------------------------------------------------- */
            var $OPTIONS = '#account_metrics_options'+$i+'active';
            var $COLOR = '#account_metrics_color'+$i;
            /* ------------------------------------------------- */
            if($($OPTIONS).is(':visible')) {
                CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','account_metrics_options ACTIVATED '+$($COLOR).val());
                $($OPTIONS).css("background-color", $($COLOR).val());
            }
            /* ------------------------------------------------- */
        }
    }
	/********************************************************/
	/********************************************************/
	/* --------------------------------------------------------  */
	$(document).ready(function() {
		/********************************************************/
		/********************************************************/
		window.chartColors = {
			red: 'rgb(255, 99, 132, 0.9)',
            orange: 'rgb(255, 159, 64, 0.9)',
            yellow: 'rgb(255, 205, 86, 0.9)',
            green: 'rgb(75, 192, 192, 0.9)',
            blue: 'rgb(54, 162, 235, 0.9)',
            purple: 'rgb(153, 102, 255, 0.9)',
            grey: 'rgb(201, 203, 207, 0.9)',
		};

		(function(global) {
			var MONTHS = [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			];

			var COLORS = [
				'#4dc9f6',
				'#f67019',
				'#f53794',
				'#537bc4',
				'#acc236',
				'#166a8f',
				'#00a950',
				'#58595b',
				'#8549ba'
			];

			var Samples = {};
			var Color = COLORS;

			Samples.utils = {
				// Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
				srand: function(seed) {
					this._seed = seed;
				},

				rand: function(min, max) {
					var seed = this._seed;
					min = min === undefined ? 0 : min;
					max = max === undefined ? 1 : max;
					this._seed = (seed * 9301 + 49297) % 233280;
					return min + (this._seed / 233280) * (max - min);
				},

				numbers: function(config) {
					var cfg = config || {};
					var min = cfg.min || 0;
					var max = cfg.max || 1;
					var from = cfg.from || [];
					var count = cfg.count || 8;
					var decimals = cfg.decimals || 8;
					var continuity = cfg.continuity || 1;
					var dfactor = Math.pow(10, decimals) || 0;
					var data = [];
					var i, value;

					for (i = 0; i < count; ++i) {
						value = (from[i] || 0) + this.rand(min, max);
						if (this.rand() <= continuity) {
							data.push(Math.round(dfactor * value) / dfactor);
						} else {
							data.push(null);
						}
					}

					return data;
				},

				labels: function(config) {
					var cfg = config || {};
					var min = cfg.min || 0;
					var max = cfg.max || 1000000;
					var count = cfg.count || 8;
					var step = (max - min) / count;
					var decimals = cfg.decimals || 8;
					var dfactor = Math.pow(10, decimals) || 0;
					var prefix = cfg.prefix || '';
					var values = [];
					var i;

					for (i = min; i < max; i += step) {
						values.push(prefix + Math.round(dfactor * i) / dfactor);
					}

					return values;
				},

				months: function(config) {
					var cfg = config || {};
					var count = cfg.count || 12;
					var section = cfg.section;
					var values = [];
					var i, value;

					for (i = 0; i < count; ++i) {
						value = MONTHS[Math.ceil(i) % 12];
						values.push(value.substring(0, section));
					}

					return values;
				},

				color: function(index) {
					return COLORS[index % COLORS.length];
				},
                /*
				transparentize: function(color, opacity) {
					var alpha = opacity === undefined ? 0.5 : 1 - opacity;
					return Color(color).alpha(alpha).rgbString();
				}
                */
			};

			// DEPRECATED
			window.randomScalingFactor = function() {
                //CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
				return Math.round(Samples.utils.rand(0, 1000000));
			};

			// INITIALIZATION

			Samples.utils.srand(Date.now());

			// Google Analytics
			/* eslint-disable 
			if (document.location.hostname.match(/^(www\.)?chartjs\.org$/)) {
				(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
				(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
				m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
				})(window,document,'script','//www.google-analytics.com/analytics.js','ga');
				ga('create', 'UA-28909194-3', 'auto');
				ga('send', 'pageview');
			}
			*/
			/* eslint-enable */

		}(this));
		/********************************************************/
		/********************************************************/
		//var timeFormat = 'MM/DD/YYYY HH:mm';
		var timeFormat = 'YYYY-MM-DD';

		function newDateString(days) {
			var $x = moment().add(days, 'd').format(timeFormat);
			
            CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','newDateString: '+$x+' of type: '+jQuery.type($x));
			return moment().add(days, 'd').format(timeFormat); //
		}
		//var color = Chart.helpers.color;
		/********************************************************/
		/********************************************************/
		var $DATA_SET01 = $('#account_metrics_dataSet1').val();
		$DATA_SET01 = JSON.parse($DATA_SET01);
        CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET01: '+$DATA_SET01);
		/********************************************************/
		var $DATA_SET02 = $('#account_metrics_dataSet2').val();
		$DATA_SET02 = JSON.parse($DATA_SET02);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET02: '+$DATA_SET02);
		/********************************************************/
		var $DATA_SET03 = $('#account_metrics_dataSet3').val();
		$DATA_SET03 = JSON.parse($DATA_SET03);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET03: '+$DATA_SET03);
		/********************************************************/
		var $DATA_SET04 = $('#account_metrics_dataSet4').val();
		$DATA_SET04 = JSON.parse($DATA_SET04);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET04: '+$DATA_SET04);
		/********************************************************/
		var $DATA_SET05 = $('#account_metrics_dataSet5').val();
		$DATA_SET05 = JSON.parse($DATA_SET05);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET05: '+$DATA_SET05);
		/********************************************************/
		var $DATA_SET06 = $('#account_metrics_dataSet6').val();
		$DATA_SET06 = JSON.parse($DATA_SET06);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET06: '+$DATA_SET06);
		/********************************************************/
		var $DATA_SET07 = $('#account_metrics_dataSet7').val();
		$DATA_SET07 = JSON.parse($DATA_SET07);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET07: '+$DATA_SET07);
		/********************************************************/
		var $DATA_SET08 = $('#account_metrics_dataSet8').val();
		$DATA_SET08 = JSON.parse($DATA_SET08);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET08: '+$DATA_SET08);
		/********************************************************/
		var $DATA_SET09 = $('#account_metrics_dataSet9').val();
		$DATA_SET09 = JSON.parse($DATA_SET09);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET09: '+$DATA_SET09);
		/********************************************************/
		var $DATA_SET10 = $('#account_metrics_dataSet10').val();
		$DATA_SET10 = JSON.parse($DATA_SET10);
		CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','------------- $DATA_SET10: '+$DATA_SET10);
		/********************************************************/
        CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','Data01: '+JSON.stringify($DATA_SET01)+' Data02: '+JSON.stringify($DATA_SET02)+' Data03: '+JSON.stringify($DATA_SET03)+' Data04: '+JSON.stringify($DATA_SET04)+' Data05: '+JSON.stringify($DATA_SET05)+' Data06: '+JSON.stringify($DATA_SET06)+' Data07: '+JSON.stringify($DATA_SET07)+' Data08: '+JSON.stringify($DATA_SET08)+' Data09: '+JSON.stringify($DATA_SET09)+' Data10: '+JSON.stringify($DATA_SET10));
		/********************************************************/
		/*	CHART CONFIGURATION(S)	*/
		/*	DEFAULT CHART (bar): WINDOW LOAD VS USER INTERACTION DATA SETS
		/*
		*/
		var activity_per_load_config = {
			type: 'bar',
			data: {
				labels: $DATA_SET01,
				datasets: $DATA_SET02
			},
			options: {
				tooltips: {enabled: false},
				hover: {mode: null},
				title: {
					display: false,
					text: 'Chart.js Combo Time Scale'
				},
                plugins: {
                    tooltip: {
                        enabled: false // <-- this option disables tooltips
                    },
                    legend: {
                        display: false
                    }
                },
				scales: {
					/********************************************************/
					/*	HORIZONAL (OUTSIDE) AXIS	*/
					xAxes: [{
						stacked: true,
						type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
						time: {
							parser: timeFormat,
							unit: 'week', 
							// round: 'day'
							tooltipFormat: 'll HH:mm'
						},
						scaleLabel: {
							display: false,
							labelString: 'Date'
						},
						//ticks: {
						//	display: false //this will remove only the label
						//}
					}],
					/********************************************************/
					/*	VERICLE (OUTSIDE) AXIS	*/
					yAxes: [{
						stacked: true,
						scaleLabel: {
							display: false,
							labelString: 'value'
						}
					}]
				},
                
			}
		};
		/********************************************************/
		/********************************************************/
		/*	CHART CONFIGURATION(S)	*/
		/*	TIME SEGMENT CHART (line): DAY TIME (6AM TO 6PM) ACTIVITY VS NIGHT TIME (6PM TO 6AM) ACTIVITY
		/*
		*/
		//var MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
		var activity_per_day_segment_config = {
			type: 'line',
			data: {
				labels: $DATA_SET01,
				datasets: $DATA_SET03
			},
			options: {
				tooltips: {enabled: false},
				hover: {mode: null},
				responsive: true,
				title: {
					display: false,
					text: 'Chart.js Line Chart'
				},
                plugins: {
                    tooltip: {
                        enabled: false // <-- this option disables tooltips
                    },
                    legend: {
                        display: false
                    }
                },
				scales: {
					xAxes: [{
						//display: true,
						type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
						time: {
							parser: timeFormat,
							unit: 'week', 
							// round: 'day'
							tooltipFormat: 'll HH:mm'
						},
						scaleLabel: {
							display: false,
							labelString: 'Date'
						}
					}],
					yAxes: [{
						//display: true,
						scaleLabel: {
							display: false,
							labelString: 'Value'
						}
					}]
				}
			}
		};
		/********************************************************/
		/********************************************************/
		/*	CHART CONFIGURATION(S)	*/
		/*	ACTIVITY COMPARISON CHART (doughnut): THIS CHART SHOWS THE COMPARISON BETWEEN EACH DATA SET
		/*
		*/
		var activity_per_data_segment_config = {
			type: 'doughnut',
			data: {
				//labels: $DATA_SET01,
				datasets: [{
					data: $DATA_SET04.data,
					backgroundColor: $DATA_SET04.backgroundColor,
					label: $DATA_SET04.label
				}],
			},
			options: {
				tooltips: {enabled: false},
				hover: {mode: null},
				responsive: true,
				legend: {
					position: 'top',
				},
				title: {
					display: false,
					text: 'Chart.js Doughnut Chart'
				},
				animation: {
					animateScale: true,
					animateRotate: true
				},
                plugins: {
                    tooltip: {
                        enabled: false // <-- this option disables tooltips
                    },
                    legend: {
                        display: false
                    }
                }
			}
		};
		/********************************************************/
		/********************************************************/
		/*	CHART CONFIGURATION(S)	*/
		/*	TIME SEGMENT CHART (line) : THIS CHART SHOWS THE AGGREGATE BETWEEN EACH DATA SET
		/*
		*/
		var activity_stacked_data_segment_config = {
			type: 'line',
			data: {
				labels: $DATA_SET01,
				datasets: $DATA_SET05
			},
			options: {
				showTooltips: false,
				responsive: true,
				title: {
					display: false,
					text: 'Chart.js Line Chart - Stacked Area'
				},
                plugins: {
                    tooltip: {
                        enabled: false // <-- this option disables tooltips
                    },
                    legend: {
                        display: false
                    }
                },
				tooltips: {enabled: false},
				hover: {mode: null},
				scales: {
					xAxes: [{
						type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
						time: {
							parser: timeFormat,
							unit: 'week', 
							// round: 'day'
							tooltipFormat: 'll HH:mm'
						},
						scaleLabel: {
							display: false,
							labelString: 'Date'
						},
					}],
					yAxes: [{
						stacked: true,
						scaleLabel: {
							display: false,
							labelString: 'Value'
						}
					}]
				}
			}
		};
		/********************************************************//********************************************************//********************************************************/
		/********************************************************//********************************************************//********************************************************/
		/********************************************************//********************************************************//********************************************************/
		/*	INITIATE CHART	*/
		/*	DEFAULT CHART: INITIATE CHART LOAD ON WINDOW LOAD
		/*
		*/
		//window.onload = function() {
		(function(event) {
			/********************************************************/
			/*	CHART CREATOR	*/
			//Chart.defaults.global.legend.display = false;
			var ctx = document.getElementById('canvas').getContext('2d');
			window.myLine = new Chart(ctx, activity_per_load_config);
		//};
		}());
		/********************************************************//********************************************************/
		/********************************************************/
		/*	ESTABLISH CHART SELECTION OPTIONS	*/
		/*	DEFAULT CHART (1): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_options2
		*	CHART (2): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_options3
		*	CHART (3): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_options4
		*	CHART (4): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_options5
		*/
		/********************************************************/
		if(document.getElementById("account_metrics_options2")){	
			//SET_BUTTON_CLICK_ACTION("account_metrics_options2");
			$('#account_metrics_options2').unbind().click(function(event) {
                CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','account_metrics_options2 chart default click');
				window.myLine.destroy();
				/********************************************************/
				//Chart.defaults.global.legend.display = false;
				var ctx = document.getElementById('canvas').getContext('2d');
				window.myLine = new Chart(ctx, activity_per_load_config);
			});
		}
		/********************************************************/
		if(document.getElementById("account_metrics_options3")){	
			//SET_BUTTON_CLICK_ACTION("account_metrics_options3");
			$('#account_metrics_options3').unbind().click(function(event) {
                CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','account_metrics_options3 chart default click');
				window.myLine.destroy();
				/********************************************************/
				//Chart.defaults.global.legend.display = false;
				var ctx = document.getElementById('canvas').getContext('2d');
				window.myLine = new Chart(ctx, activity_per_day_segment_config);
			});
		}/********************************************************/
		if(document.getElementById("account_metrics_options4")){	
			//SET_BUTTON_CLICK_ACTION("account_metrics_options4");
			$('#account_metrics_options4').unbind().click(function(event) {
                CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','account_metrics_options4 chart default click');
				window.myLine.destroy();
				/********************************************************/
				//Chart.defaults.global.legend.display = false;
				var ctx = document.getElementById('canvas').getContext('2d');
				window.myLine = new Chart(ctx, activity_per_data_segment_config);
				window.myLine.options.circumference = Math.PI;
				window.myLine.options.rotation = -Math.PI;
				window.myLine.update();
				/*
				if (window.myLine.options.circumference === Math.PI) {
					window.myLine.options.circumference = 2 * Math.PI;
					window.myLine.options.rotation = -Math.PI / 2;
				} else {
					window.myLine.options.circumference = Math.PI;
					window.myLine.options.rotation = -Math.PI;
				}
				*/
			});
		}
		/********************************************************/
		if(document.getElementById("account_metrics_options5")){	
			//SET_BUTTON_CLICK_ACTION("account_metrics_options5");
			$('#account_metrics_options5').unbind().click(function(event) {
                CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','account_metrics_options5 chart default click');
				window.myLine.destroy();
				/********************************************************/
				//Chart.defaults.global.legend.display = false;
				var ctx = document.getElementById('canvas').getContext('2d');
				window.myLine = new Chart(ctx, activity_stacked_data_segment_config);
			});
		}
		/********************************************************/
		/********************************************************/
	/* -------------------------------------------------------- */
	});/**	END OF $(document).ready(function()*/
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	WINDOW_PROFILE_METRICS_CONTROL_basics       ########################## */
export function WINDOW_PROFILE_METRICS_CONTROL_basics(){
	$(document).ready(function(){
        CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','ACTIVATED');
		/*
			https://www.chartjs.org/samples/latest/

			//"rgb(102, 250, 60)"
			//https://www.chartjs.org/samples/latest/scales/time/line.html
			//https://www.chartjs.org/samples/latest/scales/time/combo.html
			//https://www.chartjs.org/samples/latest/charts/area/line-datasets.html
			//https://www.chartjs.org/samples/latest/charts/scatter/basic.html
			//https://www.chartjs.org/samples/latest/charts/polar-area.html
			//https://www.chartjs.org/samples/latest/scriptable/bubble.html
			//https://www.chartjs.org/samples/latest/scriptable/pie.html
			//https://www.chartjs.org/samples/latest/charts/doughnut.html

		*/
		
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			/********************************************************/
			var $i=8;
			for($i; $i<22; $i++){
				/* ------------------------------------------------- */
				/* ------------------------------------------------- */
				if(document.getElementById('account_metrics_window_options'+$i+'active')){
					/* ------------------------------------------------- */
					var $OPTIONS = '#account_metrics_window_options'+$i+'active';
					var $COLOR = '#account_metrics_window_color'+$i;
					/* ------------------------------------------------- */
					if($($OPTIONS).is(':visible')) {
                        CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options ACTIVATED '+$($COLOR).val());
						$($OPTIONS).css("background-color", $($COLOR).val());
					}
				}
			}
			/* -------------------------------------------------------- */
			if(document.getElementById("account_metrics_window_options2active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options2active");
			}
			if(document.getElementById("account_metrics_window_options3active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options3active");
			}
			if(document.getElementById("account_metrics_window_options4active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options4active");
			}
			if(document.getElementById("account_metrics_window_options5active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options5active");
			}
			if(document.getElementById("account_metrics_window_options6active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options6active");
			}
			if(document.getElementById("account_metrics_window_options7active")){
				SET_BUTTON_CLICK_ACTION("account_metrics_window_options7active");
			}
			/********************************************************/
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/********************************************************/
			//var timeFormat = 'MM/DD/YYYY HH:mm';
			var timeFormat = 'YYYY-MM-DD';
			/********************************************************/
			/********************************************************/
			var $DATA_SET01 = $('#account_metrics_window_dataSet1').val();
			$DATA_SET01 = JSON.parse($DATA_SET01);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET01: '+$DATA_SET01);
			/********************************************************/
			var $DATA_SET02 = $('#account_metrics_window_dataSet2').val();
			$DATA_SET02 = JSON.parse($DATA_SET02);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET02: '+$DATA_SET02);
			/********************************************************/
			var $DATA_SET03 = $('#account_metrics_window_dataSet3').val();
			$DATA_SET03 = JSON.parse($DATA_SET03);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET03: '+$DATA_SET03);
			/********************************************************/
			var $DATA_SET04 = $('#account_metrics_window_dataSet4').val();
			$DATA_SET04 = JSON.parse($DATA_SET04);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET04: '+$DATA_SET04);
			/********************************************************/
			var $DATA_SET05 = $('#account_metrics_window_dataSet5').val();
			$DATA_SET05 = JSON.parse($DATA_SET05);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET05: '+$DATA_SET05);
			/********************************************************/
			var $DATA_SET06 = $('#account_metrics_window_dataSet6').val();
			$DATA_SET06 = JSON.parse($DATA_SET06);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET06: '+$DATA_SET06);
			/********************************************************/
			var $DATA_SET07 = $('#account_metrics_window_dataSet7').val();
			$DATA_SET07 = JSON.parse($DATA_SET07);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET07: '+$DATA_SET07);
			/********************************************************/
			var $DATA_SET08 = $('#account_metrics_window_dataSet8').val();
			$DATA_SET08 = JSON.parse($DATA_SET08);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET08: '+$DATA_SET08);
			/********************************************************/
			var $DATA_SET09 = $('#account_metrics_window_dataSet9').val();
			$DATA_SET09 = JSON.parse($DATA_SET09);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET09: '+$DATA_SET09);
			/********************************************************/
			var $DATA_SET10 = $('#account_metrics_window_dataSet10').val();
			$DATA_SET10 = JSON.parse($DATA_SET10);
            CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','------------- $DATA_SET10: '+$DATA_SET10);
			/********************************************************/
            CONSOLE_MANAGER('SETTINGS_METRICS_CONTROL','Data01: '+JSON.stringify($DATA_SET01)+' Data02: '+JSON.stringify($DATA_SET02)+' Data03: '+JSON.stringify($DATA_SET03)+' Data04: '+JSON.stringify($DATA_SET04)+' Data05: '+JSON.stringify($DATA_SET05)+' Data06: '+JSON.stringify($DATA_SET06)+' Data07: '+JSON.stringify($DATA_SET07)+' Data08: '+JSON.stringify($DATA_SET08)+' Data09: '+JSON.stringify($DATA_SET09)+' Data10: '+JSON.stringify($DATA_SET10));
			/********************************************************/
			/*	CHART CONFIGURATION(S)	*/
			/*	DEFAULT CHART (bar): WINDOW LOAD VS USER INTERACTION DATA SETS
			/*
			*/
			var activity_per_load_config = {
				type: 'bar',
				data: {
					labels: $DATA_SET01,
					datasets: $DATA_SET02
				},
				options: {
					tooltips: {enabled: false},
					hover: {mode: null},
					title: {
						display: false,
						text: 'Chart.js Combo Time Scale'
					},
                    plugins: {
                        tooltip: {
                            enabled: false // <-- this option disables tooltips
                        },
                        legend: {
                            display: false
                        }
                    },
					scales: {
						/********************************************************/
						/*	HORIZONAL (OUTSIDE) AXIS	*/
						xAxes: [{
							stacked: true,
							type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
							time: {
								parser: timeFormat,
								unit: 'week', 
								// round: 'day'
								tooltipFormat: 'll HH:mm'
							},
							scaleLabel: {
								display: false,
								labelString: 'Date'
							},
							//ticks: {
							//	display: false //this will remove only the label
							//}
						}],
						/********************************************************/
						/*	VERICLE (OUTSIDE) AXIS	*/
						yAxes: [{
							stacked: true,
							scaleLabel: {
								display: false,
								labelString: 'value'
							}
						}]
					},
				}
			};
			/********************************************************/
			/********************************************************/
			/*	CHART CONFIGURATION(S)	*/
			/*	TIME SEGMENT CHART (line): DAY TIME (6AM TO 6PM) ACTIVITY VS NIGHT TIME (6PM TO 6AM) ACTIVITY
			/*
			*/
			var activity_per_day_segment_config = {
				type: 'line',
				data: {
					labels: $DATA_SET01,
					datasets: $DATA_SET03
				},
				options: {
					tooltips: {enabled: false},
					hover: {mode: null},
					responsive: true,
					title: {
						display: false,
						text: 'Chart.js Line Chart'
					},
                    plugins: {
                        tooltip: {
                            enabled: false // <-- this option disables tooltips
                        },
                        legend: {
                            display: false
                        }
                    },
					scales: {
						xAxes: [{
							//display: true,
							type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
							time: {
								parser: timeFormat,
								unit: 'week', 
								// round: 'day'
								tooltipFormat: 'll HH:mm'
							},
							scaleLabel: {
								display: false,
								labelString: 'Date'
							}
						}],
						yAxes: [{
							//display: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							}
						}]
					}
				}
			};
			/********************************************************/
			/********************************************************/
			/*	CHART CONFIGURATION(S)	*/
			/*	ACTIVITY COMPARISON CHART (doughnut): THIS CHART SHOWS THE COMPARISON BETWEEN EACH DATA SET
			/*
			*/
			var activity_per_data_segment_config = {
				type: 'doughnut',
				data: {
					//labels: $DATA_SET01,
					datasets: [{
						data: $DATA_SET04.data,
						backgroundColor: $DATA_SET04.backgroundColor,
						label: $DATA_SET04.label
					}],
				},
				options: {
					tooltips: {enabled: false},
					hover: {mode: null},
					responsive: true,
					legend: {
						position: 'top',
					},
					title: {
						display: false,
						text: 'Chart.js Doughnut Chart'
					},
					animation: {
						animateScale: true,
						animateRotate: true
					},
                    plugins: {
                        tooltip: {
                            enabled: false // <-- this option disables tooltips
                        },
                        legend: {
                            display: false
                        }
                    },
				}
			};
			/********************************************************/
			/********************************************************/
			/*	CHART CONFIGURATION(S)	*/
			/*	TIME SEGMENT CHART (line) : THIS CHART SHOWS THE AGGREGATE BETWEEN EACH DATA SET
			/*
			*/
			var activity_stacked_data_segment_config = {
				type: 'line',
				data: {
					labels: $DATA_SET01,
					datasets: $DATA_SET05
				},
				options: {
					showTooltips: false,
					responsive: true,
					title: {
						display: false,
						text: 'Chart.js Line Chart - Stacked Area'
					},
                    plugins: {
                        tooltip: {
                            enabled: false // <-- this option disables tooltips
                        },
                        legend: {
                            display: false
                        }
                    },
					tooltips: {enabled: false},
					hover: {mode: null},
					scales: {
						xAxes: [{
							type: 'time', //https://www.chartjs.org/docs/latest/axes/cartesian/time.html
							time: {
								parser: timeFormat,
								unit: 'week', 
								// round: 'day'
								tooltipFormat: 'll HH:mm'
							},
							scaleLabel: {
								display: false,
								labelString: 'Date'
							},
						}],
						yAxes: [{
							stacked: true,
							scaleLabel: {
								display: false,
								labelString: 'Value'
							}
						}]
					}
				}
			};
			/********************************************************//********************************************************//********************************************************/
			/********************************************************//********************************************************//********************************************************/
			/********************************************************//********************************************************//********************************************************/
			function view_shift($view){
				if($view==$('#account_metrics_window_options2').attr('id')){
					$('#canvas').css("display", "block");
					$('#map').html(" ");
					$('#map').css("display", "none");
				}
				if($view==$('#account_metrics_window_options3').attr('id')){
					$('#canvas').css("display", "block");
					$('#map').html(" ");
					$('#map').css("display", "none");
				}
				if($view==$('#account_metrics_window_options4').attr('id')){
					$('#canvas').css("display", "block");
					$('#map').html(" ");
					$('#map').css("display", "none");
				}
				if($view==$('#account_metrics_window_options5').attr('id')){
					$('#canvas').css("display", "block");
					$('#map').html(" ");
					$('#map').css("display", "none");
				}
				if($view==$('#account_metrics_window_options6').attr('id')){
					$('#canvas').css("display", "none");
					$('#map').html(" ");
					$('#map').css("display", "block");
				}
				if($view==$('#account_metrics_window_options7').attr('id')){
					$('#canvas').css("display", "none");
					$('#map').html(" ");
					$('#map').css("display", "block");
				}
			}
			/*	INITIATE CHART	*/
			/*	DEFAULT CHART: INITIATE CHART LOAD ON WINDOW LOAD
			/*
			*/
			(function(event) {
				/********************************************************/
				/*	CHART CREATOR	*/
				view_shift($('#account_metrics_window_options2').attr('id'));
				//Chart.defaults.global.legend.display = false;
				var ctx = document.getElementById('canvas').getContext('2d');
				window.myLine = new Chart(ctx, activity_per_load_config);
			}());
			/********************************************************//********************************************************/
			/********************************************************/
			/*	ESTABLISH CHART SELECTION OPTIONS	*/
			/*	DEFAULT CHART (1): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_window_options2
			*	CHART (2): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_window_options3
			*	CHART (3): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_window_options4
			*	CHART (4): WINDOW LOAD VS USER INTERACTION DATA SETS - account_metrics_window_options5
			*/
			/********************************************************/
			if(document.getElementById("account_metrics_window_options2")){
				$('#account_metrics_window_options2').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options2 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options2').attr('id'));
					/********************************************************/
					//Chart.defaults.global.legend.display = false;
					var ctx = document.getElementById('canvas').getContext('2d');
					window.myLine = new Chart(ctx, activity_per_load_config);
				});
			}
			/********************************************************/
			if(document.getElementById("account_metrics_window_options3")){
				$('#account_metrics_window_options3').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options3 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options3').attr('id'));
					/********************************************************/
					//Chart.defaults.global.legend.display = false;
					var ctx = document.getElementById('canvas').getContext('2d');
					window.myLine = new Chart(ctx, activity_per_day_segment_config);
				});
			}
			/********************************************************/
			if(document.getElementById("account_metrics_window_options4")){
				$('#account_metrics_window_options4').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options4 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options4').attr('id'));
					/********************************************************/
					//Chart.defaults.global.legend.display = false;
					var ctx = document.getElementById('canvas').getContext('2d');
					window.myLine = new Chart(ctx, activity_per_data_segment_config);
					window.myLine.options.circumference = Math.PI;
					window.myLine.options.rotation = -Math.PI;
					window.myLine.update();
					/*
					if (window.myLine.options.circumference === Math.PI) {
						window.myLine.options.circumference = 2 * Math.PI;
						window.myLine.options.rotation = -Math.PI / 2;
					} else {
						window.myLine.options.circumference = Math.PI;
						window.myLine.options.rotation = -Math.PI;
					}
					*/
				});
			}
			/********************************************************/
			if(document.getElementById("account_metrics_window_options5")){
				$('#account_metrics_window_options5').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options5 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options5').attr('id'));
					/********************************************************/
					//Chart.defaults.global.legend.display = false;
					var ctx = document.getElementById('canvas').getContext('2d');
					window.myLine = new Chart(ctx, activity_stacked_data_segment_config);
				});
			}
			/********************************************************/
			if(document.getElementById("account_metrics_window_options6")){
				$('#account_metrics_window_options6').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options6 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options6').attr('id'));
					/********************************************************/
					metrics_meta_map_activate();
				});
			}
			/********************************************************/
			if(document.getElementById("account_metrics_window_options7")){
				$('#account_metrics_window_options7').unbind().click(function(event) {
                    CONSOLE_MANAGER('WINDOW_PROFILE_METRICS_CONTROL_basics','account_metrics_window_options7 chart default click');
					window.myLine.destroy();
					view_shift($('#account_metrics_window_options7').attr('id'));
					/********************************************************/
					metrics_meta_map_activate();
				});
			}
			/********************************************************/
			/********************************************************/
		/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('WINDOW_PROFILE_METRICS_CONTROL_basics','COMPLETE');
	});
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	FEED_METRICS_CONTROL_basics                 ########################## */
export function FEED_METRICS_CONTROL_basics($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('FEED_METRICS_CONTROL_basics','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine=null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.9)',
                orange: 'rgb(255, 159, 64, 0.9)',
                yellow: 'rgb(255, 205, 86, 0.9)',
                green: 'rgb(75, 192, 192, 0.9)',
                blue: 'rgb(54, 162, 235, 0.9)',
                purple: 'rgb(153, 102, 255, 0.9)',
                grey: 'rgb(201, 203, 207, 0.9)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('FEED_METRICS_CONTROL_basics','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());

                CONSOLE_MANAGER('FEED_METRICS_CONTROL_basics','Data01: '+JSON.stringify(randomScalingFactor())+' Data02: '+JSON.stringify(randomScalingFactor())+' Data03: '+JSON.stringify(randomScalingFactor())+' Data04: '+JSON.stringify(randomScalingFactor())+' Data05: '+JSON.stringify(randomScalingFactor()));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                            ],
                            backgroundColor: [
                                window.chartColors.red,
                                window.chartColors.orange,
                                window.chartColors.yellow,
                                window.chartColors.green,
                                window.chartColors.blue,
                            ],
                            label: 'Dataset 1'
                        }],
                        labels: [
                            'Red',
                            'Orange',
                            'Yellow',
                            'Green',
                            'Blue'
                        ]
                    },

                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Doughnut Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        },
                    }
                };
                (function(event) {
                    if(myLine){
                        myLine.destroy(); 
                    }
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    //Chart.defaults.global.legend.display = false;
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '1px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    //canv.style.padding = '10px';
                    var ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    window.myLine = new Chart(ctx, activity_per_load_config);
                    //var ctx = document.getElementById("CANVAS").getContext('2d');//
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);

                    //
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	SOCIAL_METRICS_CONTROL_basics                 ########################## */
export function SOCIAL_METRICS_CONTROL_basics($OBJECT1,$VAL1,$OBJECT2,$VAL2){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('SOCIAL_METRICS_CONTROL_basics','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.9)',
                orange: 'rgb(255, 159, 64, 0.9)',
                yellow: 'rgb(255, 205, 86, 0.9)',
                green: 'rgb(75, 192, 192, 0.9)',
                blue: 'rgb(54, 162, 235, 0.9)',
                purple: 'rgb(153, 102, 255, 0.9)',
                grey: 'rgb(201, 203, 207, 0.9)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('SOCIAL_METRICS_CONTROL_basics','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());
            
                CONSOLE_MANAGER('SOCIAL_METRICS_CONTROL_basics','Data01: '+JSON.stringify(randomScalingFactor())+' Data02: '+JSON.stringify(randomScalingFactor())+' Data03: '+JSON.stringify(randomScalingFactor())+' Data04: '+JSON.stringify(randomScalingFactor())+' Data05: '+JSON.stringify(randomScalingFactor()));
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                            ],
                            backgroundColor: [
                                window.chartColors.red,
                                window.chartColors.orange,
                                window.chartColors.yellow,
                                window.chartColors.green,
                                window.chartColors.blue,
                            ],
                            label: 'Dataset 1'
                        }],
                        labels: [
                            'Red',
                            'Orange',
                            'Yellow',
                            'Green',
                            'Blue'
                        ]
                    },

                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Doughnut Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        },
                    }
                };
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                (function(event) {
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    //Chart.defaults.global.legend.display = false;
                    var canv1 = document.createElement("CANVAS");
                    canv1.id = $VAL1;
                    canv1.height = $OBJECT1.height(); //get original canvas height
                    canv1.width = $OBJECT1.width();
                    canv1.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv1.style.top = '0px';
                    canv1.style.marginTop = '1px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    //canv.style.padding = '10px';
                    var ctx = canv1.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    window.myLine = new Chart(ctx, activity_per_load_config);
                    //var ctx = document.getElementById("CANVAS").getContext('2d');//
                    document.getElementById($OBJECT1.attr('id')).appendChild(canv1);

                    //
                //};
                }());
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var activity_per_load_config2 = {
                    type: 'radar',
                    data: {
                        datasets: [{
                        label: 'My First Dataset',
                        data: [65, 59, 90, 81, 56, 55],
                        fill: true,
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        borderColor: 'rgb(255, 99, 132)',
                        pointBackgroundColor: 'rgb(255, 99, 132)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                      }, {
                        label: 'My Second Dataset',
                        data: [28, 48, 40, 19, 96, 27],
                        fill: true,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgb(54, 162, 235)',
                        pointBackgroundColor: 'rgb(54, 162, 235)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(54, 162, 235)'
                      }],
                        labels: [
                            'SOCIAL 1',
                            'SOCIAL 2',
                            'SOCIAL 3',
                            'SOCIAL 4',
                            'SOCIAL 5',
                            'SOCIAL 6'
                        ]
                    },

                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            display: false
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Radar Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        },
                    }
                };
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                (function(event) {
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    //Chart.defaults.global.legend.display = false;
                    var canv2 = document.createElement("CANVAS");
                    canv2.id = $VAL2;
                    canv2.height = $OBJECT2.height(); //get original canvas height
                    canv2.width = $OBJECT2.width();
                    canv2.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv2.style.top = '0px';
                    canv2.style.marginTop = '1px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    //canv.style.padding = '10px';
                    var ctx = canv2.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    window.myLine = new Chart(ctx, activity_per_load_config2);
                    //var ctx = document.getElementById("CANVAS").getContext('2d');//
                    document.getElementById($OBJECT2.attr('id')).appendChild(canv2);

                    //
                //};
                }());
            }(this));
            
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_METRICS_CONTROL_basics              ########################## */
export function EVENT_METRICS_CONTROL_basics($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('EVENT_METRICS_CONTROL_basics','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine=null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.9)',
                orange: 'rgb(255, 159, 64, 0.9)',
                yellow: 'rgb(255, 205, 86, 0.9)',
                green: 'rgb(75, 192, 192, 0.9)',
                blue: 'rgb(54, 162, 235, 0.9)',
                purple: 'rgb(153, 102, 255, 0.9)',
                grey: 'rgb(201, 203, 207, 0.9)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('EVENT_METRICS_CONTROL_basics','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());
            
                CONSOLE_MANAGER('EVENT_METRICS_CONTROL_basics','Data01: '+JSON.stringify(randomScalingFactor())+' Data02: '+JSON.stringify(randomScalingFactor())+' Data03: '+JSON.stringify(randomScalingFactor())+' Data04: '+JSON.stringify(randomScalingFactor())+' Data05: '+JSON.stringify(randomScalingFactor()));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: [
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                                randomScalingFactor(),
                            ],
                            backgroundColor: [
                                window.chartColors.red,
                                window.chartColors.orange,
                                window.chartColors.yellow,
                                window.chartColors.green,
                                window.chartColors.blue,
                            ],
                            label: 'Dataset 1'
                        }],
                        labels: [
                            'Red',
                            'Orange',
                            'Yellow',
                            'Green',
                            'Blue'
                        ]
                    },

                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Doughnut Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        }
                    }
                };
                (function(event) {
                    if(myLine){
                        myLine.destroy(); 
                    }
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '8px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '8px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT01              ########################## */
export function METRICS_SCORE_REPORT01($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT01','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine01 = null;
            CONSOLE_MANAGER('METRICS_SCORE_REPORT01','Data01_created #####: '+$('#METRICS_DATASET_01').val());
            /*
                {"labels":["Red","Blue","Yellow"],"datasets":[{"label":"My First Dataset","data":[300,50,100],"backgroundColor":["rgb(255, 99, 132)","rgb(54, 162, 235)","rgb(255, 205, 86)"],"hoverOffset":4}]}

            */
            /* --------------------------------------------------------  */
            var activity_per_load_config = {
                type: 'doughnut',//https://www.chartjs.org/docs/latest/charts/doughnut.html
                data: JSON.parse($('#METRICS_DATASET_01').val()),
                options: {
                    tooltips: {enabled: false},
                    hover: {mode: null},
                    responsive: true,
                    legend: {
                        position: 'top',
                    },
                    title: {
                        display: false,
                        text: 'Chart.js Doughnut Chart'
                    },
                    animation: {
                        animateScale: true,
                        animateRotate: true
                    },
                    plugins: {
                        tooltip: {
                            enabled: false // <-- this option disables tooltips
                        },
                        legend: {
                            display: false // <-- this option disables legend
                        }
                    }
                }
            };
            (function(event) {
                if(myLine01){
                    myLine01.destroy();
                    myLine01.clear();
                }
                $('#metrics_container_1_DIV').html('');
                $OBJECT.html(' ');
                /********************************************************/
                /*	CHART CREATOR	*/
                var canv = document.createElement("CANVAS");
                canv.id = $VAL;
                canv.height = $OBJECT.height(); //get original canvas height
                canv.width = $OBJECT.width();
                canv.style.position = 'absolute';
                //canv.style.left = '0px';
                canv.style.top = '0px';
                canv.style.marginTop = '5px';
                //canv.style.marginLeft = '2px';
                //canv.style.textAlign = "center";
                //canv.style.verticalAlign = "middle";
                canv.style.padding = '5px';
                const ctx = canv.getContext("2d");
                //ctx.fillStyle = "#FF0000";
                //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                //var myChart;
                window.myLine01 = new Chart(ctx, activity_per_load_config);
                document.getElementById($OBJECT.attr('id')).appendChild(canv);
                window_complete_script('METRICS_SCORE_REPORT01 - GRAPH CANVAS');
            //};
            }());
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT02              ########################## */
export function METRICS_SCORE_REPORT02($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT02','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine02 = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.4)',
                orange: 'rgb(255, 159, 64, 0.4)',
                yellow: 'rgb(255, 205, 86, 0.4)',
                green: 'rgb(75, 192, 192, 0.4)',
                blue: 'rgb(54, 162, 235, 0.4)',
                purple: 'rgb(153, 102, 255, 0.4)',
                grey: 'rgb(201, 203, 207, 0.4)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;
                Samples.Utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            //values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                Samples.Utils.srand(Date.now());
            
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_REPORT02','randomScalingFactor: '+Math.round(Samples.Utils.rand(0, 1000000)));
                    return Math.round(Samples.Utils.rand(0, 1000000));
                };

                // INITIALIZATION



                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};
                const labels = Samples.Utils.months({count: 7});
                const data = {
                  labels: labels,
                  datasets: [
                    {
                      label: 'My First dataset',
                      data: Samples.Utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.red,
                      backgroundColor: window.chartColors.red,
                      fill: true
                    },
                    {
                      label: 'My Second dataset',
                      data: Samples.Utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.blue,
                      backgroundColor: window.chartColors.blue,
                      fill: true
                    },
                    {
                      label: 'My Third dataset',
                      data: Samples.Utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.green,
                      backgroundColor: window.chartColors.green,
                      fill: true
                    },
                    {
                      label: 'My Fourth dataset',
                      data: Samples.Utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.yellow,
                      backgroundColor: window.chartColors.yellow,
                      fill: true
                    }
                  ]
                };
                CONSOLE_MANAGER('METRICS_SCORE_REPORT02','Data: '+JSON.stringify(data));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'line',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        plugins: {
                          title: {
                            display: true,
                            text: (ctx) => 'Chart.js Line Chart - stacked=' + ctx.chart.options.scales.y.stacked
                          },
                          tooltip: {
                            mode: 'index'
                          },
                        },
                        interaction: {
                          mode: 'nearest',
                          axis: 'x',
                          intersect: false
                        },
                        scales: {
                          x: {
                            title: {
                              display: true,
                              text: 'Month'
                            }
                          },
                          y: {
                            stacked: true,
                            title: {
                              display: true,
                              text: 'Value'
                            }
                          }
                        }
                    }

                };
                (function(event) {
                    if(myLine02){
                        myLine02.destroy();
                        myLine02.clear();
                    }
                    $('#metrics_container_2_DIV').html('');
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine02 = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                    window_complete_script('METRICS_SCORE_REPORT02 - GRAPH CANVAS');
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT03              ########################## */
export function METRICS_SCORE_REPORT03($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT03','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine03 = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.4)',
                orange: 'rgb(255, 159, 64, 0.4)',
                yellow: 'rgb(255, 205, 86, 0.4)',
                green: 'rgb(75, 192, 192, 0.4)',
                blue: 'rgb(54, 162, 235, 0.4)',
                purple: 'rgb(153, 102, 255, 0.4)',
                grey: 'rgb(201, 203, 207, 0.4)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;
                
                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            //values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /*
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    */
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_REPORT03','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());

                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};
                const labels = Samples.utils.months({count: 7});
                /**/
                const data = {
                  labels: labels,
                  datasets: [
                    {
                      label: 'Dataset 1',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      backgroundColor: window.chartColors.red,
                    },
                    {
                      label: 'Dataset 2',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      backgroundColor: window.chartColors.blue,
                    },
                    {
                      label: 'Dataset 3',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      backgroundColor: window.chartColors.green,
                    },
                  ]
                };
                
                CONSOLE_MANAGER('METRICS_SCORE_REPORT03','Data: '+JSON.stringify(data));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'bar',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        plugins: {
                          title: {
                            display: true,
                            text: (ctx) => 'Chart.js Line Chart '
                          },
                          tooltip: {
                            mode: 'index'
                          },
                        },
                        interaction: {
                          mode: 'nearest',
                          axis: 'x',
                          intersect: false
                        },
                        scales: {
                          x: {
                            stacked: true,
                          },
                          y: {
                            stacked: true
                          }
                        }
                    }

                };
                (function(event) {
                    if(myLine03){
                        myLine03.destroy();
                        myLine03.clear();
                    }
                    $('#metrics_container_3_DIV').html('');
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine03 = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                    window_complete_script('METRICS_SCORE_REPORT03 - GRAPH CANVAS');
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT04              ########################## */
export function METRICS_SCORE_REPORT04($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT04','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine04 = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.4)',
                orange: 'rgb(255, 159, 64, 0.4)',
                yellow: 'rgb(255, 205, 86, 0.4)',
                green: 'rgb(75, 192, 192, 0.4)',
                blue: 'rgb(54, 162, 235, 0.4)',
                purple: 'rgb(153, 102, 255, 0.4)',
                grey: 'rgb(201, 203, 207, 0.4)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            //values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /**/
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_REPORT04','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());
                
                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

                const labels = Samples.utils.months({count: 7});
                const data = {
                  labels: labels,
                  datasets: [
                    {
                      label: 'Dataset 1',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.red,
                      backgroundColor: window.chartColors.red,
                    },
                    {
                      label: 'Dataset 2',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.blue,
                      backgroundColor: window.chartColors.blue,
                    }
                  ]
                };
                
                CONSOLE_MANAGER('METRICS_SCORE_REPORT04','Data01: '+JSON.stringify(data));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'radar',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Radar Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        }
                    }
                };
                (function(event) {
                    if(myLine04){
                        myLine04.destroy();
                        myLine04.clear();
                    }
                    $('#metrics_container_4_DIV').html('');
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine04 = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                    window_complete_script('METRICS_SCORE_REPORT04 - GRAPH CANVAS');
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT05              ########################## */
export function METRICS_SCORE_REPORT05($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT05','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine05 = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.4)',
                orange: 'rgb(255, 159, 64, 0.4)',
                yellow: 'rgb(255, 205, 86, 0.4)',
                green: 'rgb(75, 192, 192, 0.4)',
                blue: 'rgb(54, 162, 235, 0.4)',
                purple: 'rgb(153, 102, 255, 0.4)',
                grey: 'rgb(201, 203, 207, 0.4)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },
                    
                    points: function(config) {
                      const xs = this.numbers(config);
                      const ys = this.numbers(config);
                      return xs.map((x, i) => ({x, y: ys[i]}));
                    },

                    bubbles: function(config) {
                      return this.points(config).map(pt => {
                        pt.r = this.rand(config.rmin, config.rmax);
                        return pt;
                      });
                    },
                    
                    
                    
                    
                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    transparentize: function (r, g, b, alpha) {
                        const a = (1 - alpha) * 255;
                        const calc = x => Math.round((x - a)/alpha);

                        return `rgba(${calc(r)}, ${calc(g)}, ${calc(b)}, ${alpha})`;
                    }
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_REPORT05','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // INITIALIZATION

                Samples.utils.srand(Date.now());
                
                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: -100, max: 100};

                const labels = Samples.utils.months({count: 7});
                const data = {
                  labels: labels,
                  datasets: [
                    {
                      label: 'Dataset 1',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.red,
                      backgroundColor: window.chartColors.red,
                    },
                    {
                      label: 'Dataset 2',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.blue,
                      backgroundColor: window.chartColors.blue,
                    }
                  ]
                };

                CONSOLE_MANAGER('METRICS_SCORE_REPORT05','Data01: '+JSON.stringify(data));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'bar',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        indexAxis: 'y',
                        // Elements options apply to all of the options unless overridden in a dataset
                        // In this case, we are setting the border of each horizontal bar to be 2px wide
                        elements: {
                          bar: {
                            borderWidth: 2,
                          }
                        },
                        responsive: true,
                        plugins: {
                          legend: {
                            position: 'right',
                          },
                          title: {
                            display: true,
                            text: 'Chart.js Horizontal Bar Chart'
                          }
                        }
                      }
                        
                };
                (function(event) {
                    if(myLine05){
                        myLine05.destroy();
                        myLine05.clear();
                    }
                    $('#metrics_container_5_DIV').html('');
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine05 = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                    window_complete_script('METRICS_SCORE_REPORT05 - GRAPH CANVAS');
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_REPORT06              ########################## */
export function METRICS_SCORE_REPORT06($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_REPORT06','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLine06 = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132, 0.4)',
                orange: 'rgb(255, 159, 64, 0.4)',
                yellow: 'rgb(255, 205, 86, 0.4)',
                green: 'rgb(75, 192, 192, 0.4)',
                blue: 'rgb(54, 162, 235, 0.4)',
                purple: 'rgb(153, 102, 255, 0.4)',
                grey: 'rgb(201, 203, 207, 0.4)',
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;

                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },
                    points: function(config) {
                      const xs = this.numbers(config);
                      const ys = this.numbers(config);
                      return xs.map((x, i) => ({x, y: ys[i]}));
                    },

                    bubbles: function(config) {
                      return this.points(config).map(pt => {
                        pt.r = this.rand(config.rmin, config.rmax);
                        return pt;
                      });
                    },
                    
                    
                    
                    
                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    transparentize: function (r, g, b, alpha) {
                        const a = (1 - alpha) * 255;
                        const calc = x => Math.round((x - a)/alpha);

                        return `rgba(${calc(r)}, ${calc(g)}, ${calc(b)}, ${alpha})`;
                    }
                };
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_REPORT06','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                // 

                Samples.utils.srand(Date.now());

                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};

                const labels = Samples.utils.months({count: 7});
                const data = {
                  labels: labels,
                  datasets: [
                    {
                      label: 'Dataset 1',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.red,
                      backgroundColor: window.chartColors.red,
                      stack: 'combined',
                      type: 'bar'
                    },
                    {
                      label: 'Dataset 2',
                      data: Samples.utils.numbers(NUMBER_CFG),
                      borderColor: window.chartColors.blue,
                      backgroundColor: window.chartColors.blue,
                      stack: 'combined'
                    }
                  ]
                };
                
                CONSOLE_MANAGER('METRICS_SCORE_REPORT06','Data01: '+JSON.stringify(data));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'line',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Stacked Line/Bar Chart'
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        },
                        scales: {
                          y: {
                            stacked: true
                          }
                        }
                    }
                };
                (function(event) {
                    if(myLine06){
                        myLine06.destroy();
                        myLine06.clear();
                    }
                    $('#metrics_container_6_DIV').html('');
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLine06 = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                    window_complete_script('METRICS_SCORE_REPORT06 - GRAPH CANVAS');
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	METRICS_SCORE_SPAN              ########################## */
export function METRICS_SCORE_SPAN($OBJECT,$VAL){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
        CONSOLE_MANAGER('METRICS_SCORE_SPAN','ACTIVATED');
		/********************************************************/
		/********************************************************/
		/* --------------------------------------------------------  */
		$(document).ready(function() {
            var myLineSpan = null;
            
            window.chartColors = {
                red: 'rgb(255, 99, 132)',
                orange: 'rgb(255, 159, 64)',
                yellow: 'rgb(255, 205, 86)',
                green: 'rgb(75, 192, 192)',
                blue: 'rgb(54, 162, 235)',
                purple: 'rgb(153, 102, 255)',
                grey: 'rgb(201, 203, 207)'
            };

            (function(global) {
                var MONTHS = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];

                var COLORS = [
                    '#4dc9f6',
                    '#f67019',
                    '#f53794',
                    '#537bc4',
                    '#acc236',
                    '#166a8f',
                    '#00a950',
                    '#58595b',
                    '#8549ba'
                ];

                var Samples = {};
                var Color = COLORS;
                Samples.utils = {
                    // Adapted from http://indiegamr.com/generate-repeatable-random-numbers-in-js/
                    srand: function(seed) {
                        this._seed = seed;
                    },

                    rand: function(min, max) {
                        var seed = this._seed;
                        min = min === undefined ? 0 : min;
                        max = max === undefined ? 1 : max;
                        this._seed = (seed * 9301 + 49297) % 233280;
                        return min + (this._seed / 233280) * (max - min);
                    },

                    numbers: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1;
                        var from = cfg.from || [];
                        var count = cfg.count || 8;
                        var decimals = cfg.decimals || 8;
                        var continuity = cfg.continuity || 1;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var data = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = (from[i] || 0) + this.rand(min, max);
                            if (this.rand() <= continuity) {
                                data.push(Math.round(dfactor * value) / dfactor);
                            } else {
                                data.push(null);
                            }
                        }

                        return data;
                    },

                    labels: function(config) {
                        var cfg = config || {};
                        var min = cfg.min || 0;
                        var max = cfg.max || 1000000;
                        var count = cfg.count || 8;
                        var step = (max - min) / count;
                        var decimals = cfg.decimals || 8;
                        var dfactor = Math.pow(10, decimals) || 0;
                        var prefix = cfg.prefix || '';
                        var values = [];
                        var i;

                        for (i = min; i < max; i += step) {
                            //values.push(prefix + Math.round(dfactor * i) / dfactor);
                        }

                        //return values;
                    },

                    months: function(config) {
                        var cfg = config || {};
                        var count = cfg.count || 12;
                        var section = cfg.section;
                        var values = [];
                        var i, value;

                        for (i = 0; i < count; ++i) {
                            value = MONTHS[Math.ceil(i) % 12];
                            values.push(value.substring(0, section));
                        }

                        return values;
                    },

                    color: function(index) {
                        return COLORS[index % COLORS.length];
                    },
                    /**/
                    transparentize: function(color, opacity) {
                        var alpha = opacity === undefined ? 0.5 : 1 - opacity;
                        return Color(color).alpha(alpha).rgbString();
                    }
                    
                };
                Samples.utils.srand(Date.now());
            
                // DEPRECATED
                window.randomScalingFactor = function() {
                    //CONSOLE_MANAGER('METRICS_SCORE_SPAN','randomScalingFactor: '+Math.round(Samples.utils.rand(0, 1000000)));
                    return Math.round(Samples.utils.rand(0, 1000000));
                };

                const DATA_COUNT = 7;
                const NUMBER_CFG = {count: DATA_COUNT, min: 5, max: 10};
                /*
                const data = {
                  labels: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6'],
                  datasets: [
                    {
                      label: 'Dataset',
                      data: Samples.utils.numbers({count: 6, min: -100, max: 100}),
                      borderColor: window.chartColors.red,
                      //backgroundColor: Samples.utils.transparentize(window.chartColors.red, 0.5),
                      backgroundColor: window.chartColors.red,
                      pointStyle: 'circle',
                      pointRadius: 10,
                      pointHoverRadius: 15
                    }, {
                      label: 'Dashed',
                      fill: false,
                      backgroundColor: window.chartColors.green,
                      borderColor: window.chartColors.green,
                      borderDash: [5, 5],
                      data: Samples.utils.numbers(NUMBER_CFG),
                    }
                  ]
                };
                */
                //CONSOLE_MANAGER('METRICS_SCORE_SPAN','Data: '+JSON.stringify(data));
                //const data = $('#METRICS_DATASET_SPAN').val();
                /* --------------  LABEL */
                var $DATASET_L01,$DATASET_L02,$DATASET_L03,$DATASET_L04,$DATASET_L05,$DATASET_L06,$DATASET_L07,$DATASET_L08,$DATASET_L09,$DATASET_L10 = null;
                var $DATASET_L11,$DATASET_L12,$DATASET_L13,$DATASET_L14,$DATASET_L15,$DATASET_L16,$DATASET_L17 = null;
                /* --------------  DATASET*/
                var $DATASET_DS01,$DATASET_DS02,$DATASET_DS03,$DATASET_DS04,$DATASET_DS05,$DATASET_DS06,$DATASET_DS07 = null;
                var $DATASET_DS11,$DATASET_DS12,$DATASET_DS13,$DATASET_DS14,$DATASET_DS15,$DATASET_DS16,$DATASET_DS17 = null;
                /* --------------  DATASET VIEW*/
                var $DATASET_DV_LABEL01,$DATASET_DV_FILL01,$DATASET_DV_BGCOLOR01,$DATASET_DV_BORDERCOLOR01,$DATASET_DV_BORDERDASH01,$DATASET_DV_DATA01 = null;
                var $DATASET_DV_POINTRADIUS01,$DATASET_DV_POINTSTYLE01,$DATASET_DV_POINTHOVERRADIUS01 = null;
                
                var $DATASET_DV_LABEL02,$DATASET_DV_FILL02,$DATASET_DV_BGCOLOR02,$DATASET_DV_BORDERCOLOR02,$DATASET_DV_BORDERDASH02,$DATASET_DV_DATA02 = null;
                var $DATASET_DV_POINTRADIUS02,$DATASET_DV_POINTSTYLE02,$DATASET_DV_POINTHOVERRADIUS02 = null;
                /* --------------  DATA*/
                var $DATASET_D01,$DATASET_D02,$DATASET_D03,$DATASET_D04,$DATASET_D05,$DATASET_D06,$DATASET_D07 = null;
                var $DATASET_D11,$DATASET_D12,$DATASET_D13,$DATASET_D14,$DATASET_D15,$DATASET_D16,$DATASET_D17 = null;
                /* --------------  BACKGROUND*/
                var $DATASET_B01,$DATASET_B02,$DATASET_B03,$DATASET_B04,$DATASET_B05,$DATASET_B06,$DATASET_B07 = null;
                var $DATASET_B11,$DATASET_B12,$DATASET_B13,$DATASET_B14,$DATASET_B15,$DATASET_B16,$DATASET_B17 = null;
                
                var $DATES_obj_array = null;
                var jsonValidate  = isJSON($('#metrics_form_formFilter50').val());
                if(jsonValidate==true){
                    CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01: jsonValidate: '+jsonValidate); 
                    $DATES_obj_array = JSON.parse($('#metrics_form_formFilter50').val());
                    if($DATES_obj_array != null){
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.labels != "undefined"){
                                        if(element.labels  && element.datasets ){
                                            CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01'+' INDEX: '+index+' || element.labels: ' +element.labels+' -element.datasets: '+jQuery.isArray(element.datasets)+' -element.data: '+element.data+' -element.backgroundColor: '+element.backgroundColor);

                                            /* ---------------  */
                                            /* ---------------  */
                                            for(var i in element.labels) {
                                                CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01'+' LABEL: '+element.labels[i]);
                                                /* ---------------  */
                                                if(i==0){
                                                    $DATASET_L01 = element.labels[i];
                                                }
                                                if(i==1){
                                                    $DATASET_L02 = element.labels[i];
                                                }
                                                if(i==2){
                                                    $DATASET_L03 = element.labels[i];
                                                }
                                                if(i==3){
                                                    $DATASET_L04 = element.labels[i];
                                                }
                                                if(i==4){
                                                    $DATASET_L05 = element.labels[i];
                                                }
                                                if(i==5){
                                                    $DATASET_L06 = element.labels[i];
                                                }
                                                if(i==6){
                                                    $DATASET_L07 = element.labels[i];
                                                }
                                                //
                                                if(i==7){
                                                    $DATASET_L08 = element.labels[i];
                                                }
                                                if(i==8){
                                                    $DATASET_L09 = element.labels[i];
                                                }
                                                if(i==9){
                                                    $DATASET_L10 = element.labels[i];
                                                }
                                                if(i==10){
                                                    $DATASET_L11 = element.labels[i];
                                                }
                                                if(i==11){
                                                    $DATASET_L12 = element.labels[i];
                                                }
                                                if(i==12){
                                                    $DATASET_L13 = element.labels[i];
                                                }
                                                if(i==13){
                                                    $DATASET_L14 = element.labels[i];
                                                }
                                                if(i==14){
                                                    $DATASET_L15 = element.labels[i];
                                                }
                                                if(i==15){
                                                    $DATASET_L16 = element.labels[i];
                                                }
                                                if(i==16){
                                                    $DATASET_L17 = element.labels[i];
                                                }
                                                /* ---------------  */
                                            }
                                            /* ---------------  */
                                            var $object = element.datasets;
                                            jQuery.each($object, function(i, val ) {
                                                CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01 DATASETS: '+i+' DATA: '+val);
                                                /* ---------------  */
                                                if(i==0){
                                                    $DATASET_DS01 = val;
                                                    jQuery.each($DATASET_DS01, function(s, nval ) {
                                                        CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 00 DATASETS VIEW: '+s+' DATA: '+nval);
                                                        if(s=='label'){
                                                           $DATASET_DV_LABEL01 = nval;
                                                        }
                                                        if(s=='fill'){
                                                            $DATASET_DV_FILL01 = nval;
                                                        }
                                                        if(s=='backgroundColor'){
                                                            $DATASET_DV_BGCOLOR01 = nval;
                                                        }
                                                        if(s=='pointStyle'){
                                                           $DATASET_DV_POINTSTYLE01 = nval;
                                                        }
                                                        if(s=='pointRadius'){
                                                            $DATASET_DV_POINTRADIUS01 = nval;
                                                        }
                                                        if(s=='pointHoverRadius'){
                                                            $DATASET_DV_POINTHOVERRADIUS01 = nval;
                                                        }
                                                        if(s=='borderColor'){
                                                            $DATASET_DV_BORDERCOLOR01 = nval;
                                                        }
                                                        if(s=='borderDash'){
                                                            $DATASET_DV_BORDERDASH01 = nval;
                                                        }
                                                        if(s=='data'){
                                                            $DATASET_DV_DATA01 = nval;
                                                        }
                                                    });
                                                }
                                                if(i==1){
                                                    $DATASET_DS02 = val;
                                                    jQuery.each($DATASET_DS02, function(s, nval ) {
                                                        CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01 DATASETS VIEW: '+s+' DATA: '+nval);
                                                        if(s=='label'){
                                                           $DATASET_DV_LABEL02 = nval;
                                                        }
                                                        if(s=='fill'){
                                                            $DATASET_DV_FILL02 = nval;
                                                        }
                                                        if(s=='backgroundColor'){
                                                            $DATASET_DV_BGCOLOR02 = nval;
                                                        }
                                                        if(s=='pointStyle'){
                                                           $DATASET_DV_POINTSTYLE02 = nval;
                                                        }
                                                        if(s=='pointRadius'){
                                                            $DATASET_DV_POINTRADIUS02 = nval;
                                                        }
                                                        if(s=='pointHoverRadius'){
                                                            $DATASET_DV_POINTHOVERRADIUS02 = nval;
                                                        }
                                                        if(s=='borderColor'){
                                                            $DATASET_DV_BORDERCOLOR02 = nval;
                                                        }
                                                        if(s=='borderDash'){
                                                            $DATASET_DV_BORDERDASH02 = nval;
                                                        }
                                                        if(s=='data'){
                                                            $DATASET_DV_DATA02 = nval;
                                                        }
                                                    });
                                                }
                                                /* ---------------  */
                                            });
                                            /* ---------------  */
                                            for(var i in element.data) {
                                                CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01'+' DATA: '+element.data[i]);
                                                /* ---------------  */
                                                if(i==0){
                                                    $DATASET_D01 = element.data[i];
                                                }
                                                if(i==1){
                                                    $DATASET_D02 = element.data[i];
                                                }
                                                if(i==2){
                                                    $DATASET_D03 = element.data[i];
                                                }
                                                if(i==3){
                                                    $DATASET_D04 = element.data[i];
                                                }
                                                if(i==4){
                                                    $DATASET_D05 = element.data[i];
                                                }
                                                if(i==5){
                                                    $DATASET_D06 = element.data[i];
                                                }
                                                if(i==6){
                                                    $DATASET_D07 = element.data[i];
                                                }
                                                /* ---------------  */
                                            } 
                                            /* ---------------  */
                                            for(var i in element.backgroundColor) {
                                                CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01'+' BACKGROUND: '+element.backgroundColor[i]);
                                                /* ---------------  */
                                                if(i==0){
                                                    $DATASET_B01 = element.backgroundColor[i];
                                                }
                                                if(i==1){
                                                    $DATASET_B02 = element.backgroundColor[i];
                                                }
                                                if(i==2){
                                                    $DATASET_B03 = element.backgroundColor[i];
                                                }
                                                if(i==3){
                                                    $DATASET_B04 = element.backgroundColor[i];
                                                }
                                                if(i==4){
                                                    $DATASET_B05 = element.backgroundColor[i];
                                                }
                                                if(i==5){
                                                    $DATASET_B06 = element.backgroundColor[i];
                                                }
                                                if(i==6){
                                                    $DATASET_B07 = element.backgroundColor[i];
                                                }
                                                /* ---------------  */
                                            } 
                                            /* ---------------  */
                                        }
                                    }
                                }
                            });
                        }
                    }
                }else{
                    CONSOLE_MANAGER('METRICS_reporting_basics','METRICS_SCORE_SPAN 01: jsonValidate: '+jsonValidate);  
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                const $LABEL = [];
                /* ------------ */
                if($DATASET_L01!=null){
                   $LABEL.push($DATASET_L01);
                }
                /* ------------ */
                if($DATASET_L02!=null){
                   $LABEL.push($DATASET_L02);
                }
                /* ------------ */
                if($DATASET_L03!=null){
                   $LABEL.push($DATASET_L03);
                }
                /* ------------ */
                if($DATASET_L04!=null){
                   $LABEL.push($DATASET_L04);
                }
                /* ------------ */
                if($DATASET_L05!=null){
                   $LABEL.push($DATASET_L05);
                }
                /* ------------ */
                if($DATASET_L06!=null){
                   $LABEL.push($DATASET_L06);
                }
                /* ------------ */
                if($DATASET_L07!=null){
                   $LABEL.push($DATASET_L07);
                }
                /* ------------ */
                if($DATASET_L08!=null){
                   $LABEL.push($DATASET_L08);
                }
                /* ------------ */
                if($DATASET_L09!=null){
                   $LABEL.push($DATASET_L09);
                }
                /* ------------ */
                if($DATASET_L10!=null){
                   $LABEL.push($DATASET_L10);
                }
                /* ------------ */
                if($DATASET_L11!=null){
                   $LABEL.push($DATASET_L11);
                }
                /* ------------ */
                if($DATASET_L12!=null){
                   $LABEL.push($DATASET_L12);
                }
                /* ------------ */
                if($DATASET_L13!=null){
                   $LABEL.push($DATASET_L13);
                }
                /* ------------ */
                if($DATASET_L14!=null){
                   $LABEL.push($DATASET_L14);
                }
                /* ------------ */
                if($DATASET_L15!=null){
                   $LABEL.push($DATASET_L15);
                }
                /* ------------ */
                if($DATASET_L16!=null){
                   $LABEL.push($DATASET_L16);
                }
                /* ------------ */
                if($DATASET_L17!=null){
                   $LABEL.push($DATASET_L17);
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                const data = {
                  labels: $LABEL,
                  datasets: [
                    {
                      label: $DATASET_DV_LABEL01,
                      data: $DATASET_DV_DATA01,
                      borderColor: $DATASET_DV_BORDERCOLOR01,
                      backgroundColor: $DATASET_DV_BGCOLOR01,
                      pointStyle: $DATASET_DV_POINTSTYLE01,
                      pointRadius: $DATASET_DV_POINTRADIUS01,
                      pointHoverRadius: $DATASET_DV_POINTHOVERRADIUS01
                    }, {
                      label: $DATASET_DV_LABEL02,
                      fill: $DATASET_DV_FILL02,
                      backgroundColor: $DATASET_DV_BGCOLOR02,
                      borderColor: $DATASET_DV_BORDERCOLOR02,
                      borderDash: $DATASET_DV_BORDERDASH02,
                      data: $DATASET_DV_DATA02,
                    }
                  ]
                };
                
                CONSOLE_MANAGER('METRICS_SCORE_SPAN','Data: '+Samples.utils.numbers({count: 7, min: 1, max: 10}));
                /* --------------------------------------------------------  */
                var activity_per_load_config = {
                    type: 'line',
                    data: data,
                    options: {
                        tooltips: {enabled: false},
                        hover: {mode: null},
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        plugins: {
                            tooltip: {
                                enabled: false // <-- this option disables tooltips
                            },
                            legend: {
                                display: false
                            }
                        }
                    }

                };
                (function(event) {
                    if(myLineSpan){
                        myLineSpan.destroy(); 
                    }
                    $OBJECT.html(' ');
                    /********************************************************/
                    /*	CHART CREATOR	*/
                    var canv = document.createElement("CANVAS");
                    canv.id = $VAL;
                    canv.height = $OBJECT.height(); //get original canvas height
                    canv.width = $OBJECT.width();
                    canv.style.position = 'absolute';
                    //canv.style.left = '0px';
                    canv.style.top = '0px';
                    canv.style.marginTop = '5px';
                    //canv.style.marginLeft = '2px';
                    //canv.style.textAlign = "center";
                    //canv.style.verticalAlign = "middle";
                    canv.style.padding = '5px';
                    const ctx = canv.getContext("2d");
                    //ctx.fillStyle = "#FF0000";
                    //ctx.fillRect(0, 0, $OBJECT.width(), $OBJECT.height());
                    //var myChart;
                    window.myLineSpan = new Chart(ctx, activity_per_load_config);
                    document.getElementById($OBJECT.attr('id')).appendChild(canv);
                //};
                }());
            }(this));
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/