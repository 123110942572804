/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 

/*  BUSINESS_COHOST_INDEX_basics
/*	
/*	
/*	
/*	
/*	
/*	
/*	
/*
/*	
/*	
/*	
/*		href_Function		.32	var codes = [32];       
/*
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";

import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";

import { settings_request_function as settings_request_function } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { business_title_validation_MGMT as business_title_validation_MGMT } from "./mgmt_control.js";
import { ENTRYdelay as ENTRYdelay } from "./mgmt_control.js";
import { UPDATE_BTITLE_CLASS as UPDATE_BTITLE_CLASS } from "./mgmt_control.js";

import { title_ajax as title_ajax } from "./mgmt_control.js";
import { email_ajax as email_ajax } from "./mgmt_control.js";
import { gender_ajax as gender_ajax } from "./mgmt_control.js";

import { business_header_background_activate as business_header_background_activate } from "./mgmt_control.js";
import { BUSINESS_PHOTO_basics as BUSINESS_PHOTO_basics } from "./mgmt_control.js";
import { header_background_activate as header_background_activate } from "./mgmt_control.js";

import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";


import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { business_recommendations_map as business_recommendations_map } from "./mgmt_mapping.js";

import { PROFILESYSTEM_LOAD_MGMT as PROFILESYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_metrics.js";
import { PHOTOBUCKET_PROFILE_LOAD_MGMT as PHOTOBUCKET_PROFILE_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_PROFILE_UPDATE as PHOTOBUCKET_PROFILE_UPDATE } from "./mgmt_control.js";

import { PHOTOSYSTEM_PROFILE_LOAD_MGMT as PHOTOSYSTEM_PROFILE_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_PROFILE_CONTROL as PHOTOSYSTEM_PROFILE_CONTROL } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function business_cohost_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('business_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('business_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('business_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('business_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
    /* --------------------------------------------------------  */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());

        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/entity/file/business/upload';
    }
    /* --------------------------------------------------------  */
    if(form_entity.includes('settings_activity_type1_control')){
    	$('#standard_entity_modify_formCurrentIndex').val(select_button);
        $('#standard_entity_modify_control').val(select_val);
        $('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_title').val($('#standard_entity_modify_title').val());

        $DATA = $('.Interaction_Activity_UI_class');
        $URL = '/system/business/ajax';
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){

    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function BUSINESS_COHOST_INDEX_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('BUSINESS_COHOST_INDEX_basics','ACTIVATED');
			/* -------------------------------------------------------- */
			if($('.Pagination_UI_class_main')){
				SETTINGS_PAGINATION_CONTROL();
			}
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			search_bar_function('BUSINESS_COHOST_INDEX_basics');
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("cohost_menu_options1");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options2");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options3");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options4");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options5");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options6");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options1active");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options2active");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options3active");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options4active");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options5active");
			SET_BUTTON_CLICK_ACTION("cohost_menu_options6active");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('BUSINESS_COHOST_INDEX_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/