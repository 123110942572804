/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  
/*  feed_item_instance_function
/*
/*  Activity_scroll_function_MGMT   
                Activity_scroll_function_MGMT01
/*  ACTIVITY_MATRIX_basics
/*  Activity_feed_function_MGMT
/*	
/*  
/*  
/* 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';
/********************/
/********************/
import infiniteScroll from 'infinite-scroll';
//import  fetchUrl = require("fetch").fetchUrl;
import Cropper from 'cropperjs';
//import "jquery-ui/ui/widgets/datepicker";
import "jquery-datetimepicker";

var momenttz = require('moment-timezone');
import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT as PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_UPDATE as PHOTOBUCKET_CONTRIBUTION_UPDATE } from "./mgmt_control.js";

import { PHOTOSYSTEM_FEED_LOAD_MGMT as PHOTOSYSTEM_FEED_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_FEED_CONTRIBUTION_LOAD_MGMT as PHOTOBUCKET_FEED_CONTRIBUTION_LOAD_MGMT } from "./mgmt_control.js";



import { feed_translate_ajax_data as feed_translate_ajax_data } from "./mgmt_control.js";
import { control_feed_loader_ajax_control as control_feed_loader_ajax_control } from "./mgmt_control.js";


import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { starttime_window_open as starttime_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { initiate_starttime_creator as initiate_starttime_creator } from "./mgmt_control.js";
import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { activate_Hammer_swipe_MGMT as activate_Hammer_swipe_MGMT } from "./mgmt_control.js";
import { option_page_men as option_page_men } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";
import { event_item_map_activate as event_item_map_activate} from "./mgmt_mapping.js";
import { map_identity_event_control as map_identity_event_control} from "./mgmt_mapping.js";
import { feed_map_activate as feed_map_activate} from "./mgmt_mapping.js";


import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { duration as duration } from "./mgmt_control.js";
import { micro_duration as micro_duration } from "./mgmt_control.js";

import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_control.js";
import { text_to_field as text_to_field } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";
import { matrix_map_activate as matrix_map_activate } from "./mgmt_mapping.js";

import { FEED_METRICS_CONTROL_basics as FEED_METRICS_CONTROL_basics } from "./mgmt_metrics.js";

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function feed_item_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('feed_item_instance_function',select_button);
    /* --------------------------------------------------------  */
    /*
        feed_item_instance_function('event_activity_interaction_options', $but_obj.attr('id'),$but_obj.val(),$EVENT);
    */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('feed_item_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('feed_item_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('feed_item_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
	/* ------------------------------------------------- */
    if(form_entity.includes('event_activity_interaction_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/account/update';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_item_settings_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/description/modify';
    }
    /* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('holiday_event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/description/modify';
    }
    /* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('Activity_scroll_function_MGMT')){
        /* ------------------------------------------------- */
        if(select_val.includes('NEXT')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/profile/update/'+parseInt(select_button);
        }
        /* ------------------------------------------------- */
        if(select_val.includes('END')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/profile/last/';
        }
        /* ------------------------------------------------- */
        if(select_val.includes('Interaction_Like')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/profile/like/dislike/interaction/feed/ajax';
        }
        /* ------------------------------------------------- */
        if(select_val.includes('Interaction_Follow')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/profile/following/interaction/feed/ajax';
        }
        /* ------------------------------------------------- 
        if(select_val.includes('FEED_LOADER_NEXT')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/profile/update/'+parseInt(select_button);
        }*/
        /* ------------------------------------------------- 
        if(select_val.includes('FEED_LOADER_LAST')){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/profile/last/';
        }*/
    }
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){
    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	             ########################## */
export function  Activity_scroll_function_MGMT(){
		$(document).ready(function() {
			CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED');
			/* --------------------------------------------------------  */
            $(window).scroll(function() {
                if ( $(window).scrollTop() >= 3769 ) {
                    //$(window).scrollTop(201);
                }
                else if ( $(window).scrollTop() == 0 ) {
                    //$(window).scrollTop(18629);
                } 
                $('html,body').scrollTop(0);
                $(window).scrollTop(0);
                //window.scrollTop(0, 0);
            });
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3active');
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options4');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options5');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options6');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options7');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options8');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options9');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options10');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options11');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options12');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options13');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options14');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options15');
            /* --------------------------------------------------------/QR/TRANSMISSION/  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            const targetElement = document.getElementById("container_scroll");
            let height = parseInt(window.innerHeight) - parseInt(55);
            $('#container_scroll').css('height', height);
            /* --------------------------------------------------------  */
            function reportWindowSize() {
                height = parseInt(window.innerHeight) - parseInt(55);
                $('#container_scroll').css('height', height);
            }
            window.addEventListener("resize", reportWindowSize);
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            targetElement.addEventListener('scroll', (event) => {
                var totalPage = parseInt($('#totalPages').val());
                var $PAGE = parseInt($('#page_control').val());
                var $SCROLLTOP = parseInt(targetElement.scrollTop);
                /*
                    ($SCROLLTOP / $PAGE ) > $INNERHEIGHT - ($INNERHEIGHT *.20)
                    # ('SCROLL POSITION' DIVIDED BY 'PAGE') > (INNERHEIGHT(container_scroll height) - (20% OF INNERHEIGHT));
                
                */
                var $OFFHEIGHT = parseInt(targetElement.offsetHeight);
                var $INNERHEIGHT = parseInt($('#container_scroll').innerHeight());
                CONSOLE_MANAGER('Activity_scroll_function_MGMT','--  Listener: targetElement:  -- SCROLL position: '+$SCROLLTOP+' SCROLL INNER HEIGHT: '+$INNERHEIGHT+' $PAGE: '+$PAGE);
                if(($SCROLLTOP / $PAGE ) > $INNERHEIGHT - ($INNERHEIGHT * parseInt(.60))){
                    CONSOLE_MANAGER('Activity_scroll_function_MGMT','-------  PERFORM LISTERNER LOAD ----------------');
                    $('#page_control').val(parseInt($PAGE) + parseInt(1));
                    /* -------------------------------------------------------- */
                    if(parseInt($('#page_control').val()) <= totalPage){
                        //feed_item_instance_function('Activity_scroll_function_MGMT', 'FEED_LOADER_NEXT',parseInt($('#page_control').val()),event);
                        //return '/profile/update/'+parseInt($('#page_control').val());
                        /* ------------------------------------------------- */
                        $('#standard_entity_modify_control').val('FEED_LOADER_NEXT');
                        $('#standard_entity_modify_formReport').val(parseInt($('#page_control').val()));
                        var $DATA = $('.Interaction_Activity_UI_class');
                        var $URL = '/profile/update/'+parseInt($('#page_control').val())+'/'+parseInt($('#totalPages').val());
                        var $DATA_RETURN = control_feed_loader_ajax_control(
                            $DATA,
                            $URL,
                            event
                        );
                        if($DATA_RETURN){

                        }/* END DATA RETURN */
                        
                    }else{
                        //feed_item_instance_function('Activity_scroll_function_MGMT', 'FEED_LOADER_LAST',parseInt($('#page_control').val()),event);
                        //return '/profile/last/';
                        /* ------------------------------------------------- */
                        $('#standard_entity_modify_control').val('FEED_LOADER_LAST');
                        $('#standard_entity_modify_formReport').val(parseInt($('#page_control').val()));
                        var $DATA = $('.Interaction_Activity_UI_class');
                        var $URL = '/profile/last/'+parseInt($('#page_control').val())+'/'+parseInt($('#totalPages').val());
                        var $DATA_RETURN = control_feed_loader_ajax_control(
                            $DATA,
                            $URL,
                            event
                        );
                        if($DATA_RETURN){

                        }/* END DATA RETURN */
                    }
                    //translate_ajax_data
                    //feed_translate_ajax_data(JSON.stringify(event));
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('standard_results_ajax_responseData');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#standard_results_ajax_responseData").val()){
                                CONSOLE_MANAGER('SCROLL LISTENER MODIFY_VIEW_LISTENER: -- Activity_scroll_function_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                var $RESULTS_CNTRL, $SEARCH_CNTRL, $LOOP_CNTRL = null;
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                /* -------------------------------------------------------- */
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('Activity_scroll_function_MGMT',mutation.type+': attributes');
                                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT','RESPONSE DATA: ' +"\n"+'      '+ JSON.stringify($('#standard_results_ajax_responseData').val()));
                                    CONSOLE_MANAGER('Activity_scroll_function_MGMT','RESPONSE DATA: standard_results_ajax_currentPage: '+parseInt($('#standard_results_ajax_currentPage').val()));
                                    /* -------------------------------------------------------- */
                                    if(parseInt($('#standard_results_ajax_currentPage').val())<=0){
                                        
                                    }else if(parseInt($('#standard_results_ajax_currentPage').val())==1){
                                       
									}else{
                                        REGISTER_SCROLL_ITEMS(($('#standard_results_ajax_currentPage').val()) ? parseInt($('#standard_results_ajax_currentPage').val()):1);
									}
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                            translate_ajax_clear(true);
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            //$('#standard_entity_modify_currentPage').val(parseInt(1));
            $('#standard_entity_modify_formEntity').val(parseInt(0));
            REGISTER_SCROLL_ITEMS(1);
            /* --------------------------------------------------------  */
			function REGISTER_SCROLL_ITEMS($PAGE){
				var $ENTITYLISTMgmt = [];
				var $ENTITYLISTCOORDMgmt = [];
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var Object_count = 0;
                var $code = 0;
                /* -------------------------------------------------------- */
				if($PAGE){
                    if(($PAGE==1)&&(parseInt($('#standard_entity_modify_formEntity').val())==0)){
                        Object_count = parseInt($("#total_objects_count"+$PAGE).val()) + 2;
                        $code = $("#index_object"+$PAGE).val();
                        CONSOLE_MANAGER('SCROLL REGISTER_SCROLL_ITEMS: -- Activity_scroll_function_MGMT - FIRST PAGE LOAD RESULTS','REGISTER_SCROLL_ITEMS COUNT: '+Object_count+' CODE: '+$code+' PAGE: '+parseInt($PAGE));
                    }
                    else if($PAGE == parseInt($('#standard_results_ajax_currentPage').val())){
                        Object_count = parseInt($("#total_objects_count"+$PAGE).val()) + 2;
                        $code = $("#index_object"+$PAGE).val();
                        $('#totalPages').val(parseInt($('#standard_results_ajax_formFilter5').val()));
                        CONSOLE_MANAGER('SCROLL REGISTER_SCROLL_ITEMS: -- Activity_scroll_function_MGMT - AJAX RESULTS','REGISTER_SCROLL_ITEMS COUNT: '+Object_count+' CODE: '+$code+' PAGE: '+parseInt($PAGE));
                    }else{
                        CONSOLE_MANAGER('Activity_scroll_function_MGMT',' ---- REGISTER_SCROLL_ITEMS - NO ENTRIES --- ');
                    }
                    /* -------------------------------------------------------- */
				    /* -------------------------------------------------------- */
					if((Object_count>0)){
						for(var $i=1; $i<Object_count; $i++){
							/* ------------------------------------------------- */
							if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
								/* ------------------------------------------------- */
								var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
								var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
								var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
								var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
								var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
								var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
								var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
								var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
								var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
								var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
								
                                /* ------------------------------------------------- */
                                var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
                                var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
                                var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
                                /* ------------------------------------------------- */
                                var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
                                var $OPTIONS15 = '#form_'+$code+'_'+$i+'_options15';
                                var $OPTIONS16 = '#form_'+$code+'_'+$i+'_options16';
                                var $OPTIONS17 = '#form_'+$code+'_'+$i+'_options17';
                                var $OPTIONS18 = '#form_'+$code+'_'+$i+'_options18';
                                var $OPTIONS19 = '#form_'+$code+'_'+$i+'_options19';
                                var $OPTIONS20 = '#form_'+$code+'_'+$i+'_options20';
                                var $OPTIONS21 = '#form_'+$code+'_'+$i+'_options21';
                                var $OPTIONS21ACTIVE = '#form_'+$code+'_'+$i+'_options21active';
								/* ------------------------------------------------- */
								var $OPTIONS30 = '#form_'+$code+'_'+$i+'_options30';
								var $OPTIONS31 = '#form_'+$code+'_'+$i+'_options31';
								var $OPTIONS32 = '#form_'+$code+'_'+$i+'_options32';
                                var $OPTIONS33 = '#form_'+$code+'_'+$i+'_options33';
								/* ------------------------------------------------- */
								var $OPTIONS40 = '#form_'+$code+'_'+$i+'_options40';
                                var $OPTIONS40ACTIVE = '#form_'+$code+'_'+$i+'_options40active';
								var $OPTIONS41 = '#form_'+$code+'_'+$i+'_options41';
                                var $OPTIONS41ACTIVE = '#form_'+$code+'_'+$i+'_options41active';
								var $OPTIONS42 = '#form_'+$code+'_'+$i+'_options42';
                                var $OPTIONS42ACTIVE = '#form_'+$code+'_'+$i+'_options42active';
                                var $OPTIONS43 = '#form_'+$code+'_'+$i+'_options43';
                                var $OPTIONS43ACTIVE = '#form_'+$code+'_'+$i+'_options43active';
                                var $OPTIONS44 = '#form_'+$code+'_'+$i+'_options44';
                                var $OPTIONS44ACTIVE = '#form_'+$code+'_'+$i+'_options44active';
                                var $OPTIONS45 = '#form_'+$code+'_'+$i+'_options45';
                                var $OPTIONS45ACTIVE = '#form_'+$code+'_'+$i+'_options45active';
                                var $OPTIONS46 = '#form_'+$code+'_'+$i+'_options46';
                                var $OPTIONS46ACTIVE = '#form_'+$code+'_'+$i+'_options46active';
                                var $OPTIONS47 = '#form_'+$code+'_'+$i+'_options47';
                                var $OPTIONS47ACTIVE = '#form_'+$code+'_'+$i+'_options47active';
                                var $OPTIONS48 = '#form_'+$code+'_'+$i+'_options48';
                                var $OPTIONS48ACTIVE = '#form_'+$code+'_'+$i+'_options48active';
                                var $OPTIONS49 = '#form_'+$code+'_'+$i+'_options49';
								var $OPTIONS50 = '#form_'+$code+'_'+$i+'_options50';
                                var $OPTIONS50ACTIVE = '#form_'+$code+'_'+$i+'_options50active';
                                /* ------------------------------------------------- */
                                var $OPTIONS60 = '#form_'+$code+'_'+$i+'_options60';
                                var $OPTIONS60ACTIVE = '#form_'+$code+'_'+$i+'_options60active';
                                /* ------------------------------------------------- */
                                var $OPTIONS61 = '#form_'+$code+'_'+$i+'_options61';
								/* ------------------------------------------------- */
								var $OPTIONS70 = '#form_'+$code+'_'+$i+'_options70';
								var $OPTIONS71 = '#form_'+$code+'_'+$i+'_options71';
								var $OPTIONS72 = '#form_'+$code+'_'+$i+'_options72';
								var $OPTIONS73 = '#form_'+$code+'_'+$i+'_options73';
								var $OPTIONS74 = '#form_'+$code+'_'+$i+'_options74';
								var $OPTIONS75 = '#form_'+$code+'_'+$i+'_options75';
								/* ------------------------------------------------- */
								var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
								//var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
								//var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
								var $TITLE = '#form_'+$code+'_'+$i+'_title';
								var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
								var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
								var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
								var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
								var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
								var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
                                var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
                                var $INDEX08 = '#form_'+$code+'_'+$i+'_index8';
								/* ------------------------------------------------- */
								var $INDEX10 = '#form_'+$code+'_'+$i+'_index10';
								var $INDEX11 = '#form_'+$code+'_'+$i+'_index11';
								var $INDEX12 = '#form_'+$code+'_'+$i+'_index12';
								var $INDEX13 = '#form_'+$code+'_'+$i+'_index13';
								var $INDEX14 = '#form_'+$code+'_'+$i+'_index14';
								var $INDEX15 = '#form_'+$code+'_'+$i+'_index15';
                                /* ------------------------------------------------- */
                                
                                
                                
                                /* ------------------------------------------------- */
                                
                                
                                /* ------------------------------------------------- */
                                var $INDEX20 = '#form_'+$code+'_'+$i+'_index20';
								var $INDEX21 = '#form_'+$code+'_'+$i+'_index21';
								var $INDEX22 = '#form_'+$code+'_'+$i+'_index22';
								var $INDEX23 = '#form_'+$code+'_'+$i+'_index23';
								var $INDEX24 = '#form_'+$code+'_'+$i+'_index24';
                                /* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                # IS FOLLOWING / IS REGISTERED / IS CHECKED-IN
                                #   30 DISLIKE_CONTROL
                                #   31 DOESLIKE_CONTROL
                                #   32 IS FOLLOWING
                                #   33 IS REGISTERED
                                #   34 IS CHECKED-IN
                                #   35 IS RECOMMENDING / NO RECOMMENDING
                                #   
                                */
                                var $INDEX30 = '#form_'+$code+'_'+$i+'_index30';
								var $INDEX31 = '#form_'+$code+'_'+$i+'_index31';
								var $INDEX32 = '#form_'+$code+'_'+$i+'_index32';
								var $INDEX33 = '#form_'+$code+'_'+$i+'_index33';
								var $INDEX34 = '#form_'+$code+'_'+$i+'_index34';
                                var $INDEX35 = '#form_'+$code+'_'+$i+'_index35';
                                
                                /* ------------------------------------------------- */
                                
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT','--- $INDEX21 value ---: '+$($INDEX21).val());
                                /* ------------------------------------------------- */
                                /*
                                    control - $ENTITY_LIMITED_LIST[0][$i]['getEventTheme']
                                    index1 - $ENTITY_LIMITED_LIST[0][$i]['getProfileType']
                                    index2 - $ENTITY_LIMITED_LIST[0][$i]['getUniqEventId']
                                    index3 - $ENTITY_LIMITED_LIST[0][$i]['getRegisterCode']
                                    index4 - $ENTITY_LIMITED_LIST[0][$i]['getUniqMemId']
                                    index5 - $ENTITY_LIMITED_LIST[0][$i]['getUniqImageId']
                                    index6 - $ENTITY_LIMITED_LIST[0][$i]['IMAGE_CONTROL_TYPE']
                                    index7 - $ENTITY_LIMITED_LIST[0][$i]['CONTROL_TYPE']
                                    index8 - $ENTITY_LIMITED_LIST[0][$i][''getEventTheme']
                                    index9 - $ENTITY_LIMITED_LIST[0][$i]['SET_SCORE']
                                    index10 - $ENTITY_LIMITED_LIST[0][$i]['SET_FOLLOWERS']
                                    index11 - $ENTITY_LIMITED_LIST[0][$i]['SET_RECOMMENDATIONS']
                                    index12 - $ENTITY_LIMITED_LIST[0][$i]['SET_ATTENDEES']
                                    index13 - $ENTITY_LIMITED_LIST[0][$i]['SET_TOTAL_RECOMMENDATIONS']
                                    index14 - $ENTITY_LIMITED_LIST[0][$i]['SET_CHECKIN_STATUS']
                                    index15 - $ENTITY_LIMITED_LIST[0][$i]['ATTENDEES_CONTROL']
                                    index16 - $ENTITY_LIMITED_LIST[0][$i]['SET_PROXIMITY_TO_ENTITY']
                                    index17 - $ENTITY_LIMITED_LIST[0][$i]['SET_EVENT_CITY_WEATHER']
                                    index18 - $ENTITY_LIMITED_LIST[0][$i]['SET_LOCAL_USER_WEATHER']
                                    
                                    index20 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOSYSTEM_MANAGER_COUNT']
                                    index21 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOSYSTEM_MANAGER']
                                    index22 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER_TOTAL_COUNT']
                                    index23 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER_COUNT']
                                    index24 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER']
                                
                                */
                                /* ------------------------------------------------- */
                                var $THEME_CONTROL = '#form_'+$code+'_'+$i+'_control';
								/* ------------------------------------------------- */
								//$($OPTIONS04).css("background-color",$($COLORSEQUENCE).val());
								//$("#index_object_item").val().attr("background-color",$($INDEX04).val());
								/* ------------------------------------------------- 
								  	var $ID_VAL = $("#"+$PAGE+'_'+$i).val();
									$("#"+$ID_VAL ).addClass($($INDEX04).val());
									$($OPTIONS70).removeClass('any_visuallyhidden');
									$($OPTIONS71).removeClass('any_visuallyhidden');
									$($OPTIONS72).removeClass('any_visuallyhidden');
									$($OPTIONS73).removeClass('any_visuallyhidden');
									$($OPTIONS74).removeClass('any_visuallyhidden');
									$($OPTIONS75).removeClass('any_visuallyhidden');
									
									$($OPTIONS70).addClass($($INDEX10).val());
									$($OPTIONS71).addClass($($INDEX11).val());
									$($OPTIONS72).addClass($($INDEX12).val());
									$($OPTIONS73).addClass($($INDEX13).val());
									$($OPTIONS74).addClass($($INDEX14).val());
									$($OPTIONS75).addClass($($INDEX15).val());
								
                                */
                                var $FILE_PATH00 = '#form_'+$code+'_'+$i+'_file_path';
                                var $FILE_PATH01 = '#form_'+$code+'_'+$i+'_file_path1';
                                var $FILE_PATH02 = '#form_'+$code+'_'+$i+'_file_path2';
                                var $FILE_PATH03 = '#form_'+$code+'_'+$i+'_file_path3';
                                var $FILE_PATH04 = '#form_'+$code+'_'+$i+'_file_path4';
                                var $FILE_PATH05 = '#form_'+$code+'_'+$i+'_file_path5';
                                var $FILE_PATH06 = '#form_'+$code+'_'+$i+'_file_path6';
                                var $FILE_PATH07 = '#form_'+$code+'_'+$i+'_file_path7';
                                var $FILE_PATH08 = '#form_'+$code+'_'+$i+'_file_path8';
                                var $FILE_PATH09 = '#form_'+$code+'_'+$i+'_file_path9';
                                var $FILE_PATH10 = '#form_'+$code+'_'+$i+'_file_path10';
                                /* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                if($($INDEX01).val() && $($INDEX08).val()){
                                    /*
                                    ENTITY STRUCTER
                                        #   FRAME_BREAK
                                        #   PARENT_EVENT
                                        #   DESCRIPTION_EVENT
                                        #   IMAGE_EVENT
                                        #
                                        #   PARENT_HOLIDAY
                                        #   DESCRIPTION_HOLIDAY
                                        #   IMAGE_HOLIDAY
                                        #
                                        #   POLL_EVENT
                                        #   BUSINESS_EVENT
                                        #   USER_EVENT
                                    
                                    
                                    EVENT & HOLIDAY
                                        #   STANDARD
                                        #   STANDARD-TWO
                                        #   DISPLAY-ONE
                                        #   DISPLAY-TWO
                                        #   DISPLAY-THREE
                                    
                                    
                                    BUSINESS & PROFILE
                                        #   BASIC
                                        #   BLOCK
                                        #   SLICE
                                        #   SHOWROOM
                                        #   MANAGED
                                    */
                                    var str_profile = $($INDEX01).val();
                                    var str_entity_type = $($INDEX07).val();
                                    var str_theme = $($INDEX08).val();
                                    CONSOLE_MANAGER('Activity_scroll_function_MGMT','--- ENTITY Profile Type Value: '+str_profile+ ' || ENTITY Structure Type Value:'+str_entity_type+' || ENTITY Theme Type Value: '+str_theme);
                                    /* ------------------------------------------------- */
                                    if(str_entity_type=='PARENT_EVENT'){
                                        header_background_activate($($FILE_PATH00),$($OPTIONS20));
                                    }
                                    /* ------------------------------------------------- */
                                    if(str_profile=='EVENT_TYPE' || str_profile=='HOLIDAY_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED PROFILE: '+str_profile);
                                        if (str_theme.indexOf("STANDARD") >= 0 && str_theme.indexOf("STANDARD-") <= -1){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH10),$($OPTIONS11));
                                        }
                                        else if (str_theme.indexOf("STANDARD-TWO") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH08),$($OPTIONS12));
                                            header_background_activate($($FILE_PATH09),$($OPTIONS13));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-ONE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH02),$($OPTIONS14));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-TWO") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH03),$($OPTIONS15));
                                            header_background_activate($($FILE_PATH04),$($OPTIONS16));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-THREE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            //header_background_activate($($FILE_PATH00),$($OPTIONS01));
                                            /* ------------------------------------------------- */
                                            //header_background_activate($($FILE_PATH01),$($OPTIONS01));
                                            //header_background_activate($($FILE_PATH02),$($OPTIONS01));

                                            //header_background_activate($($FILE_PATH02),$($OPTIONS14));
                                            
                                            //header_background_activate($($FILE_PATH03),$($OPTIONS15));
                                            //header_background_activate($($FILE_PATH04),$($OPTIONS16));
                                            
                                            header_background_activate($($FILE_PATH05),$($OPTIONS17));
                                            header_background_activate($($FILE_PATH06),$($OPTIONS18));
                                            header_background_activate($($FILE_PATH07),$($OPTIONS19));
                                        }
                                    }
                                    /* ------------------------------------------------- */
                                    else if(str_profile=='BUSINESS_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED PROFILE: '+str_profile);
                                        if (str_theme.indexOf("BASIC") >= 0 ){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("BLOCK") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("SLICE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("SHOWROOM") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("MANAGED") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                    }
                                    /* ------------------------------------------------- */
                                    else if(str_profile=='PROFILE_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT','ACTIVATED PROFILE: '+str_profile);
                                            
                                    }
                                }
                                
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                # DOES LIKE / DIS LIKE
                                #   30 DISLIKE_CONTROL
                                #   31 DOESLIKE_CONTROL
                                #
                                #   container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE  
                                #   container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT
                                #
                                #   container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE
                                #      container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT
                                */
                                if($($INDEX30).val()=='FALSE' && $($INDEX31).val()=='FALSE'){
                                    $($OPTIONS71).addClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                    $($OPTIONS71).removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                    $($OPTIONS72).addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                    $($OPTIONS72).removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                }
                                if($($INDEX30).val()=='TRUE' && $($INDEX31).val()=='FALSE'){
                                    $($OPTIONS71).addClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                    $($OPTIONS71).removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                    $($OPTIONS72).addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                    $($OPTIONS72).removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                }
                                if($($INDEX30).val()=='FALSE' && $($INDEX31).val()=='TRUE'){
                                    $($OPTIONS71).addClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                    $($OPTIONS71).removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                    $($OPTIONS72).addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                    $($OPTIONS72).removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                }
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                # IS FOLLOWING / IS REGISTERED / IS CHECKED-IN
                                #   30 DISLIKE_CONTROL
                                #   31 DOESLIKE_CONTROL
                                #   32 IS FOLLOWING
                                #   33 IS REGISTERED
                                #   34 IS CHECKED-IN
                                #   35 IS RECOMMENDING / NO RECOMMENDING
                                #   
                                #
                                #   event_feed_OTHER_activity_follow_positive  
                                #   event_feed_OTHER_activity_follow_neutral
                                #
                                #   
                                #   
                                */
                                if($($INDEX32).val()=='FALSE'){
                                    $($OPTIONS61).addClass('event_feed_OTHER_activity_follow_neutral');
                                    $($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_positive');
                                }
                                if($($INDEX32).val()=='TRUE'){
                                    $($OPTIONS61).addClass('event_feed_OTHER_activity_follow_positive');
                                    $($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_neutral');
                                }
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                #   33 IS REGISTERED
                                #   
                                #
                                #   event_feed_OTHER_activity_follow_positive  
                                #   event_feed_OTHER_activity_follow_neutral
                                #
                                #   
                                #   
                                */
                                if($($INDEX33).val()=='FALSE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_neutral');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_positive');
                                }
                                if($($INDEX33).val()=='TRUE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_positive');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_neutral');
                                }
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                #   34 IS CHECKED-IN
                                #   
                                #
                                #   event_feed_OTHER_activity_follow_positive  
                                #   event_feed_OTHER_activity_follow_neutral
                                #
                                #   
                                #   
                                */
                                if($($INDEX34).val()=='FALSE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_neutral');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_positive');
                                }
                                if($($INDEX34).val()=='TRUE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_positive');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_neutral');
                                }
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /*****************ACTIVITY VALUES*************
                                # 
                                #   35 IS RECOMMENDING / NO RECOMMENDING
                                #   
                                #
                                #   event_feed_OTHER_activity_follow_positive  
                                #   event_feed_OTHER_activity_follow_neutral
                                #
                                #   
                                #   
                                */
                                if($($INDEX35).val()=='FALSE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_neutral');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_positive');
                                }
                                if($($INDEX35).val()=='TRUE'){
                                    //$($OPTIONS61).addClass('event_feed_OTHER_activity_follow_positive');
                                    //$($OPTIONS61).removeClass('event_feed_OTHER_activity_follow_neutral');
                                }
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /*  SCORE   */
                                if($($OPTIONS41ACTIVE).val()){
                                   REGISTER_SCORE_METRICS($($OPTIONS40),$i);
                                   //FEED_METRICS_CONTROL_basics($($OPTIONS40),$i);
                                }   
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /*  QR CODE   */
								//<img id="QR_CODE_DIV" src="{{ qr_code_data_uri(form_menu_variables.QR_CODE_PATH, 'custom') }}" />
                                
                                //$($OPTIONS60ACTIVE).attr('src', $($OPTIONS60ACTIVE).val());
                                /*
                                var img = document.createElement("IMG");
                                img.src = $($OPTIONS60ACTIVE).val();
                                $($OPTIONS60ACTIVE).html(img);
                                */
                                //$($OPTIONS60ACTIVE).html('<img id="QR_CODE_DIV" src=qr_code_data_uri("'+$($OPTIONS60ACTIVE).val()+'", custom) />'); 
                                //$($OPTIONS60ACTIVE).prepend('<img id="QR_CODE_DIV" src=qr_code_data_uri("'+$($OPTIONS60ACTIVE).val()+'", custom) />');
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */	
								//ITEM_DURATION($($OPTIONS10),$($OPTIONS05),$($OPTIONS07));
                                /* ------------------------------------------------- *//* ------------------------------------------------- */
                                /* ------------------------------------------------- *//* ------------------------------------------------- */
                                
                                
								/* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* HEADER MENU */
								REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /*  HEADER ENTRY   */
                                $($OPTIONS03).html($i);
                                
                                /* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /* FOOTER MENU */
                                REGISTER_METRICS_CLICK($($OPTIONS70),$i,$code);
                                /* ------------------------------------------------- */
                                /* FOOTER MENU */
                                INTERACTION_REGISTER_CLICK($($OPTIONS71),$($OPTIONS72),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                INTERACTION_REGISTER_CLICK($($OPTIONS72),$($OPTIONS71),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                /* ------------------------------------------------- */
                                /* PAGE OPTIONS */
                                REGISTER_CLICK($($OPTIONS14),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS15),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS16),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS17),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS18),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS19),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS20),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS21),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS21ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                
								/* ------------------------------------------------- */
								REGISTER_CLICK($($OPTIONS30),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS31),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS32),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								/* ------------------------------------------------- */
                                /*  SCORE   */
								REGISTER_CLICK($($OPTIONS40),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),);
                                REGISTER_CLICK($($OPTIONS40ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  FOLLOWERS   */
                                REGISTER_CLICK($($OPTIONS41),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS41ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS42),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  RECOMMENDATION   */
                                REGISTER_CLICK($($OPTIONS43),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS43ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS44),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  DESCRIPTION   */
                                REGISTER_DESCRIPTION_CLICK($($OPTIONS45),$i,$code);
                                REGISTER_DESCRIPTION_CLICK($($OPTIONS45ACTIVE),$i,$code);
                                /* ------------------------------------------------- */
                                /*  CATALOG   */
                                REGISTER_CATALOG_VIEW($($OPTIONS46),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                REGISTER_CATALOG_VIEW($($OPTIONS46ACTIVE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                REGISTER_CATALOG_VIEW($($OPTIONS47),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                /* ------------------------------------------------- */
                                /*  MEDIA   */
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS48),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS48ACTIVE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS49),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                /* ------------------------------------------------- */
                                /*  GUIDELINE   */
                                REGISTER_CLICK($($OPTIONS50),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS50ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  FOLLOWING MENU  */
                                INTERACTION_FOLLOW_CLICK($($OPTIONS61),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                /* ------------------------------------------------- */
                                REGISTER_CLICK($($OPTIONS60),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),);
                                REGISTER_CLICK($($OPTIONS60ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								/* ------------------------------------------------- 
								REGISTER_CLICK($($OPTIONS70),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS71),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS72),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS73),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS74),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS75),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                */
							}
							/* ------------------------------------------------- */
							if($('#form_'+$code+'_'+$i+'_options60').is(':visible')){
								CONSOLE_MANAGER('Activity_scroll_function_MGMT','--- FEED: EVALUATE LOADED CONTENT ---');
								var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
								//var $OPTIONS60 = '#form_'+$code+'_'+$i+'_options60';
								var $TOTAL = parseInt($('#totalPages').val());
								var $PREVIOUS_PAGE = parseInt($PAGE);
								if(($TOTAL <= parseInt($PAGE))){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT','--- FEED: LAST PAGE ---');
								   	$($OPTIONS01).addClass('container_scroll_complete_tail');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
								}
								else if($TOTAL > parseInt($PAGE)){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT','---FEED: ADDITIONAL PAGES AVAILABLE---');
									$($OPTIONS01).removeClass('container_scroll_complete_tail');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
									$($OPTIONS01).addClass('any_visuallyhidden');
									//$($OPTIONS60).removeClass('container_scroll_tail_frame');
									//$($OPTIONS60).addClass('any_visuallyhidden');
								}
								/*
								if($TOTAL < $PREVIOUS_PAGE){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT','---3---');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
									$($OPTIONS01).removeClass('container_scroll_complete_tail');
									//$($OPTIONS01).addClass('any_visuallyhidden');
									$($OPTIONS60).removeClass('container_scroll_tail_frame');
									$($OPTIONS60).addClass('any_visuallyhidden');
								}
								*/
							}
						}
                        
                    //$('#standard_results_ajax_responseData').val(null);
						//$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
                        /* -------------------------------------------------------- */
                        function REGISTER_SCORE_METRICS($button_obj,$INDEX01){
                            FEED_METRICS_CONTROL_basics($button_obj,$INDEX01);
                        }
						/* -------------------------------------------------------- */
                        function REGISTER_CATALOG_VIEW($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX20,$INDEX21){
                            //CONSOLE_MANAGER('REGISTER_CATALOG_VIEW','REGISTER value -- --- -- : '+$INDEX21.val());
                            $button_obj.unbind().click(function(event) {
                                $('#standard_entity_modify_profileType').val($INDEX01.val());
                                PHOTOSYSTEM_FEED_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX20,$INDEX21,'VIEW');
                            });
                        }
                        /* --------------------------------------------------------  */
                        function REGISTER_CONTRIBUTION_VIEW($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX23,$INDEX24){
                            //CONSOLE_MANAGER('REGISTER_CONTRIBUTION_VIEW','REGISTER');
                            $button_obj.unbind().click(function(event) {
                                $('#standard_entity_modify_profileType').val($INDEX01.val());
                                PHOTOBUCKET_FEED_CONTRIBUTION_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX23,$INDEX24,'VIEW');
                            });
                        }
						/* -------------------------------------------------------- */
						function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
							$button_obj.unbind().click(function(event) {
								event.stopPropagation;
								$('#standard_entity_modify_title').val($TITLE.val());
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($button_obj.attr('id'));
								$('#standard_entity_modify_control').val($INDEX01.val());
								standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function REGISTER_METRICS_CLICK($button_obj,$val_code,$code){
							//CONSOLE_MANAGER('REGISTER_METRICS_CLICK','CLICK: '+$button_obj.attr('id'));
                            //$button_obj.unbind().click(function(event) {
                            NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,null,null,null);
                            //});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function REGISTER_DESCRIPTION_CLICK($button_obj,$val_code,$code){
							//CONSOLE_MANAGER('REGISTER_DESCRIPTION_CLICK','CLICK: '+$button_obj.attr('id'));
                            //$button_obj.unbind().click(function(event) {
                            NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,null,null,null);
                            //});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function INTERACTION_REGISTER_CLICK($button_obj1,$button_obj2,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
							$button_obj1.unbind().click(function(event) {
                                event.stopPropagation;
                                /* -------------------------------------------------------- */
                                var str = $button_obj1.val();
                                CONSOLE_MANAGER('profile_action_class','value: '+str);
                                if (str.indexOf("setDislike") >= 0){
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                    }
                                }
                                else if (str.indexOf("setDoeslike") >= 0){
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                    }
                                }
								/* -------------------------------------------------------- */
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($INDEX04.val());
                                $('#standard_entity_modify_index5').val($INDEX05.val());
                                $('#standard_entity_modify_index6').val($INDEX06.val());
                                $('#standard_entity_modify_index7').val($button_obj1.val());
								$('#standard_entity_modify_control').val($button_obj1.attr('id'));
                                feed_item_instance_function('Activity_scroll_function_MGMT', 'Interaction_Like',$button_obj1.attr('id'),event);
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function INTERACTION_FOLLOW_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
                            var str = $button_obj.val();
                            var $DATES_obj_array = null;
                            var $FOLLOW_TEXT,$FOLLOWING_TEXT = null;
                            CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK','value: '+str);
                            /* -------------------------------------------------------- */
                            var jsonValidate  = isJSON($button_obj.val());
                            if(jsonValidate==true){
                                $DATES_obj_array = JSON.parse($button_obj.val());
                                if($DATES_obj_array != null){

                                    if($DATES_obj_array.length > 0){
                                        $DATES_obj_array.forEach((element, index, array) => {
                                            if(element){
                                                if(typeof element.following != "undefined"){
                                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.following);
                                                    $FOLLOWING_TEXT = element.following;
                                                }
                                                if(typeof element.follow != "undefined"){
                                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.follow);
                                                    $FOLLOW_TEXT = element.follow
                                                }
                                            }
                                        });
                                    }
                                }
                            }else{
                                CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','false');
                            }                            
                            /* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							$button_obj.unbind().click(function(event) {
                                CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK','CLICK: '+$button_obj.attr('id'));
                                event.stopPropagation;
                                /* -------------------------------------------------------- */
                                var jsonValidate  = isJSON($button_obj.val());
                                if(jsonValidate==true){
                                    $DATES_obj_array = JSON.parse($button_obj.val());
                                    if($DATES_obj_array != null){

                                        if($DATES_obj_array.length > 0){
                                            $DATES_obj_array.forEach((element, index, array) => {
                                                if(element){
                                                    if(typeof element.following != "undefined"){
                                                        CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.following);
                                                        $FOLLOWING_TEXT = element.following;
                                                    }
                                                    if(typeof element.follow != "undefined"){
                                                        CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.follow);
                                                        $FOLLOW_TEXT = element.follow
                                                    }
                                                }
                                            });
                                        }
                                    }
                                }else{
                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','false');
                                }
                                /* -------------------------------------------------------- */
                                CONSOLE_MANAGER('follow_action_class','value: '+$button_obj.val());
                                /* -------------------------------------------------------- */
                                /* -------------------------------------------------------- */
                                /*
                                    event_feed_OTHER_activity_follow_neutral
                                    event_feed_OTHER_activity_follow_positive
                                */
                                if($button_obj.hasClass('event_feed_OTHER_activity_follow_positive')){
                                    $button_obj.removeClass('event_feed_OTHER_activity_follow_positive');
                                    $button_obj.addClass('event_feed_OTHER_activity_follow_neutral');
                                    //$button_obj.val(false);
                                    $button_obj.html($FOLLOW_TEXT);
                                }else{
                                    $button_obj.removeClass('event_feed_OTHER_activity_follow_neutral');
                                    $button_obj.addClass('event_feed_OTHER_activity_follow_positive');
                                    //$button_obj.val(true);
                                    $button_obj.html($FOLLOWING_TEXT);
                                }
								/* -------------------------------------------------------- */
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($INDEX04.val());
                                $('#standard_entity_modify_index5').val($INDEX05.val());
                                $('#standard_entity_modify_index6').val($INDEX06.val());
                                $('#standard_entity_modify_index7').val($button_obj.val());
								$('#standard_entity_modify_control').val($button_obj.attr('id'));
                                feed_item_instance_function('Activity_scroll_function_MGMT', 'Interaction_Follow',$button_obj.attr('id'),event);
                                
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
                        function REGISTER_MAP($LAT,$LON,$FRAME,$i){
                            feed_map_activate($LAT,$LON,$FRAME,$i);
                        }
						/* -------------------------------------------------------- 
						var conn = new ab.Session('ws://127.0.0.1:8080',
							function($EVENT) {
								$EVENT.stopPropagation;
								var $DATES_obj_array=[];	
								conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
									var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
									if(jsonValidate==true){
										$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
										if($DATES_obj_array != null){

											if($DATES_obj_array.length > 0){
												$DATES_obj_array.forEach((element, index, array) => {
													if(element){
														if(typeof element.GET_UNIQ_ID != "undefined"){
															if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
																var className = $(element.SET_STATUS).attr('class');
																$(element.SET_STATUS).removeClass(className);
																$(element.SET_STATUS).addClass(data.itemviewCLASS);
															}
														}
													}
												});
											}
										}
									}
								});
							},
							function() {
                            
							},			   
							{'skipSubprotocolCheck': true}		   
						);*//*END FUNCTION */
					}
				}
			}
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('Activity_scroll_function_MGMT');
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	             ########################## */
export function  Activity_scroll_function_MGMT01(){
		$(document).ready(function() {
			CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED');
			/* --------------------------------------------------------  */
            $(window).scroll(function() {
                if ( $(window).scrollTop() >= 3769 ) {
                    //$(window).scrollTop(201);
                }
                else if ( $(window).scrollTop() == 0 ) {
                    //$(window).scrollTop(18629);
                } 
                $('html,body').scrollTop(0);
                $(window).scrollTop(0);
                //window.scrollTop(0, 0);
            });
            /* --------------------------------------------------------  */
            
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3active');
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options4');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options5');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options6');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options7');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options8');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options9');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options10');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options11');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options12');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options13');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options14');
			SET_BUTTON_CLICK_ACTION('matrix_form_buttons_options15');
            /* --------------------------------------------------------/QR/TRANSMISSION/  */
            /* --------------------------------------------------------  */
            
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /*
                https://infinite-scroll.com/
                https://infinite-scroll.com/options.html
                https://www.npmjs.com/package/infinite-scroll
                https://github.com/metafizzy/infinite-scroll
            */
            var $container = new InfiniteScroll('.container_scroll_load', { //'.container_scroll' '.container_scroll_load'
                path: function($e) {
                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','--  InfiniteScroll CORE CALL:  -- TOTAL PAGE COUNT: '+parseInt($('#totalPages').val()));
                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','#####  InfiniteScroll CORE LOAD LOOP: '+this.loadCount);
                    var PAGE_CONTROL = parseInt($('#page_control').val())+this.loadCount;
                    var totalPage = parseInt($('#totalPages').val());
                    
                    //CONSOLE_MANAGER('SCROLL CONTAINER CALL: -- Activity_scroll_function_MGMT01','----------- PAGE_CONTROL: '+parseInt(PAGE_CONTROL)+' --------------  totalPage: '+totalPage);
                    if(parseInt(PAGE_CONTROL) <= totalPage){//this.loadCount
                        CONSOLE_MANAGER('SCROLL CONTAINER CALL: -- Activity_scroll_function_MGMT01','----------- PAGE_CONTROL (NEXT PAGE): '+parseInt(PAGE_CONTROL)+' --------------  totalPage: '+totalPage);
                        //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','----------- FETCH NEXT PAGE');
                        //feed_item_instance_function('Activity_scroll_function_MGMT01', 'NEXT',parseInt(PAGE_CONTROL),$e);
                        return '/profile/update/'+parseInt(PAGE_CONTROL);//this.loadCount   parseInt($('#page_control').val()) * 
                    }else{
                        CONSOLE_MANAGER('SCROLL CONTAINER CALL: -- Activity_scroll_function_MGMT01','----------- PAGE_CONTROL (LAST PAGE): '+parseInt(PAGE_CONTROL)+' --------------  totalPage: '+totalPage);
                        //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','----------- FETCH LAST PAGE');
                        //feed_item_instance_function('Activity_scroll_function_MGMT01', 'END',parseInt(PAGE_CONTROL),$e);
                        //$container.infiniteScroll('destroy')
                        return '/profile/last/';
                    }
                   
                },
                // REQUIRED. Determines the URL for the next page
                // Set to selector string to use the href of the next page's link
                // path: '.pagination__next'
                // Or set with {{#}} in place of the page number in the url
                // path: '/blog/page/{{#}}'
                // or set with function
                // path: function() {
                //   return return '/articles/P' + ( ( this.loadCount + 1 ) * 10 );
                // }

                append: undefined,
                // REQUIRED for appending content
                // Appends selected elements from loaded page to the container

                checkLastPage: true,
                // Checks if page has path selector element
                // Set to string if path is not set as selector string:
                //   checkLastPage: '.pagination__next'

                prefill: false,
                // Loads and appends pages on intialization until scroll requirement is met.

                responseBody: 'json',//text json
                // Sets the method used on the response.
                // Set to 'json' to load JSON.

                domParseResponse: true,
                // enables parsing response body into a DOM
                // disable to load flat text

                fetchOptions: {
                    mode: 'cors',
                    cache: 'no-cache',
                    //credentials: 'same-origin',
                    //headers: {
                     //   'X-Session-Id': '33vscths658h7996d324rqft1s',
                    //},
                },
                // sets custom settings for the fetch() request
                // for setting headers, cors, or POST method
                // can be set to an object, or a function that returns an object

                outlayer: false,
                // Integrates Masonry, Isotope or Packery
                // Appended items will be added to the layout

                scrollThreshold: 3769, //5000
                // Sets the distance between the viewport to scroll area
                // for scrollThreshold event to be triggered.

                elementScroll: false, //false '.container_scroll' 'container_scroll_load'
                // Sets scroller to an element for overflow element scrolling

                loadOnScroll: true,
                // Loads next page when scroll crosses over scrollThreshold

                history: false,//'replace'
                // Changes the browser history and URL.
                // Set to 'push' to use history.pushState()
                //    to create new history entries for each page change.

                historyTitle: false,
                // Updates the window title. Requires history enabled.

                hideNav: '.pagination',
                // Hides navigation element

                status: '.scroll-status',
                // Displays status elements indicating state of page loading:
                // .infinite-scroll-request, .infinite-scroll-load, .infinite-scroll-error
                // status: '.page-load-status'

                button: false,
                // Enables a button to load pages on click
                // button: '.load-next-button'

                //onInit: undefined,
                // called on initialization
                // useful for binding events on init
                // onInit: function() {
                //   this.on( 'append', function() {...})
                // }
                onInit: function() {
                    this.on( 'load', function() {
                        CONSOLE_MANAGER('SCROLL onInit: -- Activity_scroll_function_MGMT01','########## InfiniteScroll LOAD ########');
                        //$('#standard_entity_modify_formEntity').val(parseInt(0));
                        //REGISTER_SCROLL_ITEMS(1);
                        //$(window).scrollTop(0);
                  });
                },

                debug: true,
                // Logs events and state changes to the console.
            })
			/* -------------------------------------------------------- JSON.stringify : */
			var $COUNT = 0;
			var nextIndex = 0;
            var PATH_CONTROL = null;
            
            $container.on( 'load', function( event, body, response ) {
				feed_translate_ajax_data(JSON.stringify(event));
                CONSOLE_MANAGER('SCROLL load: -- Activity_scroll_function_MGMT01','--  FEED LOAD CALL:  -- standard_results_ajax_entityCount: ' + ($('#standard_results_ajax_entityCount').val()) ? parseInt($('#standard_results_ajax_entityCount').val()):0);
                $COUNT = ($('#standard_results_ajax_entityCount').val()) ? parseInt($('#standard_results_ajax_entityCount').val()):0 ;
				if($COUNT>0){
					var itemsHTML = $('#standard_results_ajax_responseData').val();
					var $items =  $( itemsHTML );
                    $('.container_scroll').append( itemsHTML );
					//$container.infiniteScroll( 'appendItems', $items );
				}else{
					var itemsHTML = $('#scroll_tail').val();
					var $items =  $( itemsHTML );
					//$container.infiniteScroll( 'appendItems', $items );
                    $container.infiniteScroll('destroy')
				}
                //translate_ajax_clear(true);
            });
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            //.infiniteScroll
			$container.on( 'append', function( event, response, path, items ) {
                CONSOLE_MANAGER('SCROLL LISTENER append: -- Activity_scroll_function_MGMT01','LISTENER:  - APPENDED: ' + response);
			});
			/* -------------------------------------------------------- INVALID DATA SET */
            //.infiniteScroll
			$container.on( 'request', function( event, path ) {
                //return '/profile/update/'+parseInt(this.loadCount);
				CONSOLE_MANAGER('SCROLL LISTENER request: -- Activity_scroll_function_MGMT01','LISTENER:  - REQUEST: ' + path);              
			});
			/* --------------------------------------------------------  */
            //.infiniteScroll
			$container.on( 'error', function( event, error, path ) {
				CONSOLE_MANAGER('SCROLL LISTENER error: -- Activity_scroll_function_MGMT01','LISTENER:  - ERROR: '+ error);
			})
			/* --------------------------------------------------------  */
            //.infiniteScroll
			$container.on( 'last', function( event, response, path ) {
                //return '/profile/last/'
                CONSOLE_MANAGER('SCROLL LISTENER last: -- Activity_scroll_function_MGMT01','LISTENER:  - LAST: ' + response);
                $container.infiniteScroll('destroy')
               
               
			});
            
			// load initial page
			//$container.infiniteScroll('loadNextPage');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('standard_results_ajax_responseData');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#standard_results_ajax_responseData").val()){
                                
                                CONSOLE_MANAGER('SCROLL LISTENER MODIFY_VIEW_LISTENER: -- Activity_scroll_function_MGMT01','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                var $RESULTS_CNTRL, $SEARCH_CNTRL, $LOOP_CNTRL = null;
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                /* -------------------------------------------------------- */
                                if(mutation.type=='attributes'){
                                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT01',mutation.type+': attributes');
                                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','RESPONSE DATA: ' +"\n"+'      '+ JSON.stringify($('#standard_results_ajax_responseData').val()));
                                    /* -------------------------------------------------------- */
                                    if(parseInt($('#standard_entity_modify_currentPage').val())<=0){
                                        
                                    }else if(parseInt($('#standard_entity_modify_currentPage').val())==1){
                                       
									}else{
                                        //if(PATH_CONTROL.indexOf('last') > -1){
                                            
                                        //}else{
                                            REGISTER_SCROLL_ITEMS(($('#standard_entity_modify_currentPage').val()) ? parseInt($('#standard_entity_modify_currentPage').val()):1);
                                        //}
									}
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    //CONSOLE_MANAGER('Activity_scroll_function_MGMT01',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                                
                            }
                            //translate_ajax_clear(true);
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            //$('#standard_entity_modify_currentPage').val(parseInt(1));
            $('#standard_entity_modify_formEntity').val(parseInt(0));
            REGISTER_SCROLL_ITEMS(1);
            /* --------------------------------------------------------  */
			function REGISTER_SCROLL_ITEMS($PAGE){
				var $ENTITYLISTMgmt = [];
				var $ENTITYLISTCOORDMgmt = [];
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var Object_count = 0;
                var $code = 0;
                CONSOLE_MANAGER('SCROLL LISTENER MODIFY_VIEW_LISTENER: -- Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS COUNT: '+parseInt($("#total_objects_count").val()+parseInt($PAGE)));
				if($PAGE){
                    if(($PAGE==1)&&(parseInt($('#standard_entity_modify_formEntity').val())==0)){
                        //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS - CORRECT');
                        Object_count = parseInt($("#total_objects_count"+$PAGE).val() + 2);
                        $code = $("#index_object"+$PAGE).val();
                        //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS CURRENT PAGE: '+parseInt($PAGE));
                        //CONSOLE_MANAGER('SCROLL LISTENER MODIFY_VIEW_LISTENER: -- Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS COUNT: '+parseInt($("#total_objects_count"+$PAGE).val()));
                        $('#standard_entity_modify_formEntity').val(parseInt($PAGE + 1));
                        $('#standard_entity_modify_currentPage').val(parseInt($PAGE + 1));
                    }else{
                        /* -------------------------------------------------------- */
                        if(parseInt($PAGE)>=2){
                            /*
                                https://www.geeksforgeeks.org/how-to-scroll-to-specific-element-using-jquery/
                            
                            
                            */
                            /*
                            $('html,body').scrollTop(500);
                            $('.container_scroll_load').scrollTop(10);
                            $('.container_scroll').scrollTop(500);
                            */
                        }
                        /* -------------------------------------------------------- */
                        if($PAGE == parseInt($('#standard_entity_modify_formEntity').val())){
                            if($("#index_object"+$PAGE).val()){
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS - CORRECT');
                                Object_count = parseInt($("#total_objects_count"+$PAGE).val() + 2);
                                $code = $("#index_object"+$PAGE).val();
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS CURRENT PAGE: '+parseInt($PAGE));
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS COUNT: '+parseInt($("#total_objects_count"+$PAGE).val()));
                                $('#standard_entity_modify_formEntity').val(parseInt($PAGE + 1));
                                $('#standard_entity_modify_currentPage').val(parseInt($PAGE + 1));
                            }else{
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS - OTHER');
                            }
                        }else{
                            //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS - MODIFIED');
                            /*

                            $PAGE = $PAGE - 1;
                            Object_count = parseInt($("#total_objects_count"+$PAGE).val() + 2);
                            $code = $("#index_object"+$PAGE).val();
                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS CURRENT PAGE: '+parseInt($PAGE));
                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','REGISTER_SCROLL_ITEMS COUNT: '+parseInt($("#total_objects_count"+$PAGE).val()));
                            $('#standard_entity_modify_formEntity').val(parseInt($PAGE));
                            */
                        }
                    }
					if((Object_count>0)){
						for(var $i=1; $i<Object_count; $i++){
							/* ------------------------------------------------- */
							if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
								/* ------------------------------------------------- */
								var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
								var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
								var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
								var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
								var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
								var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
								var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
								var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
								var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
								var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
								
                                /* ------------------------------------------------- */
                                var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
                                var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
                                var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
                                /* ------------------------------------------------- */
                                var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
                                var $OPTIONS15 = '#form_'+$code+'_'+$i+'_options15';
                                var $OPTIONS16 = '#form_'+$code+'_'+$i+'_options16';
                                var $OPTIONS17 = '#form_'+$code+'_'+$i+'_options17';
                                var $OPTIONS18 = '#form_'+$code+'_'+$i+'_options18';
                                var $OPTIONS19 = '#form_'+$code+'_'+$i+'_options19';
                                var $OPTIONS20 = '#form_'+$code+'_'+$i+'_options20';
                                var $OPTIONS21 = '#form_'+$code+'_'+$i+'_options21';
                                var $OPTIONS21ACTIVE = '#form_'+$code+'_'+$i+'_options21active';
								/* ------------------------------------------------- */
								var $OPTIONS30 = '#form_'+$code+'_'+$i+'_options30';
								var $OPTIONS31 = '#form_'+$code+'_'+$i+'_options31';
								var $OPTIONS32 = '#form_'+$code+'_'+$i+'_options32';
                                var $OPTIONS33 = '#form_'+$code+'_'+$i+'_options33';
								/* ------------------------------------------------- */
								var $OPTIONS40 = '#form_'+$code+'_'+$i+'_options40';
                                var $OPTIONS40ACTIVE = '#form_'+$code+'_'+$i+'_options40active';
								var $OPTIONS41 = '#form_'+$code+'_'+$i+'_options41';
                                var $OPTIONS41ACTIVE = '#form_'+$code+'_'+$i+'_options41active';
								var $OPTIONS42 = '#form_'+$code+'_'+$i+'_options42';
                                var $OPTIONS42ACTIVE = '#form_'+$code+'_'+$i+'_options42active';
                                var $OPTIONS43 = '#form_'+$code+'_'+$i+'_options43';
                                var $OPTIONS43ACTIVE = '#form_'+$code+'_'+$i+'_options43active';
                                var $OPTIONS44 = '#form_'+$code+'_'+$i+'_options44';
                                var $OPTIONS44ACTIVE = '#form_'+$code+'_'+$i+'_options44active';
                                var $OPTIONS45 = '#form_'+$code+'_'+$i+'_options45';
                                var $OPTIONS45ACTIVE = '#form_'+$code+'_'+$i+'_options45active';
                                var $OPTIONS46 = '#form_'+$code+'_'+$i+'_options46';
                                var $OPTIONS46ACTIVE = '#form_'+$code+'_'+$i+'_options46active';
                                var $OPTIONS47 = '#form_'+$code+'_'+$i+'_options47';
                                var $OPTIONS47ACTIVE = '#form_'+$code+'_'+$i+'_options47active';
                                var $OPTIONS48 = '#form_'+$code+'_'+$i+'_options48';
                                var $OPTIONS48ACTIVE = '#form_'+$code+'_'+$i+'_options48active';
                                var $OPTIONS49 = '#form_'+$code+'_'+$i+'_options49';
								var $OPTIONS50 = '#form_'+$code+'_'+$i+'_options50';
                                var $OPTIONS50ACTIVE = '#form_'+$code+'_'+$i+'_options50active';
                                /* ------------------------------------------------- */
                                var $OPTIONS60 = '#form_'+$code+'_'+$i+'_options60';
                                var $OPTIONS60ACTIVE = '#form_'+$code+'_'+$i+'_options60active';
                                /* ------------------------------------------------- */
                                var $OPTIONS61 = '#form_'+$code+'_'+$i+'_options61';
								/* ------------------------------------------------- */
								var $OPTIONS70 = '#form_'+$code+'_'+$i+'_options70';
								var $OPTIONS71 = '#form_'+$code+'_'+$i+'_options71';
                                SET_BUTTON_CLICK_ACTION('#form_'+$code+'_'+$i+'_options71');
								var $OPTIONS72 = '#form_'+$code+'_'+$i+'_options72';
                                SET_BUTTON_CLICK_ACTION('#form_'+$code+'_'+$i+'_options72');
								var $OPTIONS73 = '#form_'+$code+'_'+$i+'_options73';
								var $OPTIONS74 = '#form_'+$code+'_'+$i+'_options74';
								var $OPTIONS75 = '#form_'+$code+'_'+$i+'_options75';
								/* ------------------------------------------------- */
								var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
								//var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
								//var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
								var $TITLE = '#form_'+$code+'_'+$i+'_title';
								var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
								var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
								var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
								var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
								var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
								var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
                                var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
                                var $INDEX08 = '#form_'+$code+'_'+$i+'_index8';
								/* ------------------------------------------------- */
								var $INDEX10 = '#form_'+$code+'_'+$i+'_index10';
								var $INDEX11 = '#form_'+$code+'_'+$i+'_index11';
								var $INDEX12 = '#form_'+$code+'_'+$i+'_index12';
								var $INDEX13 = '#form_'+$code+'_'+$i+'_index13';
								var $INDEX14 = '#form_'+$code+'_'+$i+'_index14';
								var $INDEX15 = '#form_'+$code+'_'+$i+'_index15';
                                /* ------------------------------------------------- */
                                
                                
                                
                                /* ------------------------------------------------- */
                                
                                
                                /* ------------------------------------------------- */
                                var $INDEX20 = '#form_'+$code+'_'+$i+'_index20';
								var $INDEX21 = '#form_'+$code+'_'+$i+'_index21';
								var $INDEX22 = '#form_'+$code+'_'+$i+'_index22';
								var $INDEX23 = '#form_'+$code+'_'+$i+'_index23';
								var $INDEX24 = '#form_'+$code+'_'+$i+'_index24';
                                
                                //CONSOLE_MANAGER('Activity_scroll_function_MGMT01','--- $INDEX21 value ---: '+$($INDEX21).val());
                                /* ------------------------------------------------- */
                                /*
                                    control - $ENTITY_LIMITED_LIST[0][$i]['getEventTheme']
                                    index1 - $ENTITY_LIMITED_LIST[0][$i]['getProfileType']
                                    index2 - $ENTITY_LIMITED_LIST[0][$i]['getUniqEventId']
                                    index3 - $ENTITY_LIMITED_LIST[0][$i]['getRegisterCode']
                                    index4 - $ENTITY_LIMITED_LIST[0][$i]['getUniqMemId']
                                    index5 - $ENTITY_LIMITED_LIST[0][$i]['getUniqImageId']
                                    index6 - $ENTITY_LIMITED_LIST[0][$i]['IMAGE_CONTROL_TYPE']
                                    index7 - $ENTITY_LIMITED_LIST[0][$i]['CONTROL_TYPE']
                                    index8 - $ENTITY_LIMITED_LIST[0][$i][''getEventTheme']
                                    index9 - $ENTITY_LIMITED_LIST[0][$i]['SET_SCORE']
                                    index10 - $ENTITY_LIMITED_LIST[0][$i]['SET_FOLLOWERS']
                                    index11 - $ENTITY_LIMITED_LIST[0][$i]['SET_RECOMMENDATIONS']
                                    index12 - $ENTITY_LIMITED_LIST[0][$i]['SET_ATTENDEES']
                                    index13 - $ENTITY_LIMITED_LIST[0][$i]['SET_TOTAL_RECOMMENDATIONS']
                                    index14 - $ENTITY_LIMITED_LIST[0][$i]['SET_CHECKIN_STATUS']
                                    index15 - $ENTITY_LIMITED_LIST[0][$i]['ATTENDEES_CONTROL']
                                    index16 - $ENTITY_LIMITED_LIST[0][$i]['SET_PROXIMITY_TO_ENTITY']
                                    index17 - $ENTITY_LIMITED_LIST[0][$i]['SET_EVENT_CITY_WEATHER']
                                    index18 - $ENTITY_LIMITED_LIST[0][$i]['SET_LOCAL_USER_WEATHER']
                                    
                                    index20 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOSYSTEM_MANAGER_COUNT']
                                    index21 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOSYSTEM_MANAGER']
                                    index22 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER_TOTAL_COUNT']
                                    index23 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER_COUNT']
                                    index24 - $ENTITY_LIMITED_LIST[0][$i]['PHOTOBUCKET_MANAGER']
                                
                                */
                                /* ------------------------------------------------- */
                                var $THEME_CONTROL = '#form_'+$code+'_'+$i+'_control';
								/* ------------------------------------------------- */
								//$($OPTIONS04).css("background-color",$($COLORSEQUENCE).val());
								//$("#index_object_item").val().attr("background-color",$($INDEX04).val());
								/* ------------------------------------------------- 
								  	var $ID_VAL = $("#"+$PAGE+'_'+$i).val();
									$("#"+$ID_VAL ).addClass($($INDEX04).val());
									$($OPTIONS70).removeClass('any_visuallyhidden');
									$($OPTIONS71).removeClass('any_visuallyhidden');
									$($OPTIONS72).removeClass('any_visuallyhidden');
									$($OPTIONS73).removeClass('any_visuallyhidden');
									$($OPTIONS74).removeClass('any_visuallyhidden');
									$($OPTIONS75).removeClass('any_visuallyhidden');
									
									$($OPTIONS70).addClass($($INDEX10).val());
									$($OPTIONS71).addClass($($INDEX11).val());
									$($OPTIONS72).addClass($($INDEX12).val());
									$($OPTIONS73).addClass($($INDEX13).val());
									$($OPTIONS74).addClass($($INDEX14).val());
									$($OPTIONS75).addClass($($INDEX15).val());
								
                                */
                                var $FILE_PATH00 = '#form_'+$code+'_'+$i+'_file_path';
                                var $FILE_PATH01 = '#form_'+$code+'_'+$i+'_file_path1';
                                var $FILE_PATH02 = '#form_'+$code+'_'+$i+'_file_path2';
                                var $FILE_PATH03 = '#form_'+$code+'_'+$i+'_file_path3';
                                var $FILE_PATH04 = '#form_'+$code+'_'+$i+'_file_path4';
                                var $FILE_PATH05 = '#form_'+$code+'_'+$i+'_file_path5';
                                var $FILE_PATH06 = '#form_'+$code+'_'+$i+'_file_path6';
                                var $FILE_PATH07 = '#form_'+$code+'_'+$i+'_file_path7';
                                var $FILE_PATH08 = '#form_'+$code+'_'+$i+'_file_path8';
                                var $FILE_PATH09 = '#form_'+$code+'_'+$i+'_file_path9';
                                var $FILE_PATH10 = '#form_'+$code+'_'+$i+'_file_path10';
                                /* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                if($($INDEX01).val() && $($INDEX08).val()){
                                    /*
                                    ENTITY STRUCTER
                                        #   FRAME_BREAK
                                        #   PARENT_EVENT
                                        #   DESCRIPTION_EVENT
                                        #   IMAGE_EVENT
                                        #
                                        #   PARENT_HOLIDAY
                                        #   DESCRIPTION_HOLIDAY
                                        #   IMAGE_HOLIDAY
                                        #
                                        #   POLL_EVENT
                                        #   BUSINESS_EVENT
                                        #   USER_EVENT
                                    
                                    
                                    EVENT & HOLIDAY
                                        #   STANDARD
                                        #   STANDARD-TWO
                                        #   DISPLAY-ONE
                                        #   DISPLAY-TWO
                                        #   DISPLAY-THREE
                                    
                                    
                                    BUSINESS & PROFILE
                                        #   BASIC
                                        #   BLOCK
                                        #   SLICE
                                        #   SHOWROOM
                                        #   MANAGED
                                    */
                                    var str_profile = $($INDEX01).val();
                                    var str_entity_type = $($INDEX07).val();
                                    var str_theme = $($INDEX08).val();
                                    CONSOLE_MANAGER('Activity_scroll_function_MGMT01','--- ENTITY Profile Type Value: '+str_profile+ ' || ENTITY Structure Type Value:'+str_entity_type+' || ENTITY Theme Type Value: '+str_theme);
                                    /* ------------------------------------------------- */
                                    if(str_entity_type=='PARENT_EVENT'){
                                        header_background_activate($($FILE_PATH00),$($OPTIONS20));
                                    }
                                    /* ------------------------------------------------- */
                                    if(str_profile=='EVENT_TYPE' || str_profile=='HOLIDAY_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED PROFILE: '+str_profile);
                                        if (str_theme.indexOf("STANDARD") >= 0 && str_theme.indexOf("STANDARD-") <= -1){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH10),$($OPTIONS11));
                                        }
                                        else if (str_theme.indexOf("STANDARD-TWO") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH08),$($OPTIONS12));
                                            header_background_activate($($FILE_PATH09),$($OPTIONS13));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-ONE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH02),$($OPTIONS14));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-TWO") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            header_background_activate($($FILE_PATH03),$($OPTIONS15));
                                            header_background_activate($($FILE_PATH04),$($OPTIONS16));
                                        }
                                        else if (str_theme.indexOf("DISPLAY-THREE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                            /* ------------------------------------------------- */
                                            //header_background_activate($($FILE_PATH00),$($OPTIONS01));
                                            /* ------------------------------------------------- */
                                            //header_background_activate($($FILE_PATH01),$($OPTIONS01));
                                            //header_background_activate($($FILE_PATH02),$($OPTIONS01));

                                            //header_background_activate($($FILE_PATH02),$($OPTIONS14));
                                            
                                            //header_background_activate($($FILE_PATH03),$($OPTIONS15));
                                            //header_background_activate($($FILE_PATH04),$($OPTIONS16));
                                            
                                            header_background_activate($($FILE_PATH05),$($OPTIONS17));
                                            header_background_activate($($FILE_PATH06),$($OPTIONS18));
                                            header_background_activate($($FILE_PATH07),$($OPTIONS19));
                                        }
                                    }
                                    /* ------------------------------------------------- */
                                    else if(str_profile=='BUSINESS_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED PROFILE: '+str_profile);
                                        if (str_theme.indexOf("BASIC") >= 0 ){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("BLOCK") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("SLICE") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("SHOWROOM") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                        else if (str_theme.indexOf("MANAGED") >= 0){
                                            CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED THEME: '+str_theme);
                                             /* ------------------------------------------------- */
                                        }
                                    }
                                    /* ------------------------------------------------- */
                                    else if(str_profile=='PROFILE_TYPE'){
                                        CONSOLE_MANAGER('Activity_scroll_function_MGMT01','ACTIVATED PROFILE: '+str_profile);
                                            
                                    }
                                }
                                
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /*  SCORE   */
                                if($($OPTIONS41ACTIVE).val()){
                                   FEED_METRICS_CONTROL_basics($($OPTIONS40),$i);
                                }   
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /*  QR CODE   */
								//<img id="QR_CODE_DIV" src="{{ qr_code_data_uri(form_menu_variables.QR_CODE_PATH, 'custom') }}" />
                                
                                //$($OPTIONS60ACTIVE).attr('src', $($OPTIONS60ACTIVE).val());
                                /*
                                var img = document.createElement("IMG");
                                img.src = $($OPTIONS60ACTIVE).val();
                                $($OPTIONS60ACTIVE).html(img);
                                */
                                //$($OPTIONS60ACTIVE).html('<img id="QR_CODE_DIV" src=qr_code_data_uri("'+$($OPTIONS60ACTIVE).val()+'", custom) />'); 
                                //$($OPTIONS60ACTIVE).prepend('<img id="QR_CODE_DIV" src=qr_code_data_uri("'+$($OPTIONS60ACTIVE).val()+'", custom) />');
                                /* ------------------------------------------------- */
								/* ------------------------------------------------- */	
								//ITEM_DURATION($($OPTIONS10),$($OPTIONS05),$($OPTIONS07));
                                /* ------------------------------------------------- *//* ------------------------------------------------- */
                                /* ------------------------------------------------- *//* ------------------------------------------------- */
                                
                                
								/* ------------------------------------------------- */
								/* ------------------------------------------------- */
                                /* HEADER MENU */
								REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /*  HEADER ENTRY   */
                                $($OPTIONS03).html($i);
                                
                                /* ------------------------------------------------- */
                                /* ------------------------------------------------- */
                                /* FOOTER MENU */
                                REGISTER_METRICS_CLICK($($OPTIONS70),$i,$code);
                                /* ------------------------------------------------- */
                                /* FOOTER MENU */
                                INTERACTION_REGISTER_CLICK($($OPTIONS71),$($OPTIONS72),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                INTERACTION_REGISTER_CLICK($($OPTIONS72),$($OPTIONS71),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                /* ------------------------------------------------- */
                                /* PAGE OPTIONS */
                                REGISTER_CLICK($($OPTIONS14),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS15),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS16),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS17),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS18),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS19),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS20),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS21),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS21ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                
								/* ------------------------------------------------- */
								REGISTER_CLICK($($OPTIONS30),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS31),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS32),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								/* ------------------------------------------------- */
                                /*  SCORE   */
								REGISTER_CLICK($($OPTIONS40),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),);
                                REGISTER_CLICK($($OPTIONS40ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  FOLLOWERS   */
                                REGISTER_CLICK($($OPTIONS41),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS41ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS42),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  RECOMMENDATION   */
                                REGISTER_CLICK($($OPTIONS43),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS43ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS44),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  DESCRIPTION   */
                                REGISTER_DESCRIPTION_CLICK($($OPTIONS45),$i,$code);
                                REGISTER_DESCRIPTION_CLICK($($OPTIONS45ACTIVE),$i,$code);
                                /* ------------------------------------------------- */
                                /*  CATALOG   */
                                REGISTER_CATALOG_VIEW($($OPTIONS46),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                REGISTER_CATALOG_VIEW($($OPTIONS46ACTIVE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                REGISTER_CATALOG_VIEW($($OPTIONS47),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX20),$($INDEX21));
                                /* ------------------------------------------------- */
                                /*  MEDIA   */
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS48),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS48ACTIVE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                REGISTER_CONTRIBUTION_VIEW($($OPTIONS49),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX23),$($INDEX24));
                                /* ------------------------------------------------- */
                                /*  GUIDELINE   */
                                REGISTER_CLICK($($OPTIONS50),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                REGISTER_CLICK($($OPTIONS50ACTIVE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                /* ------------------------------------------------- */
                                /*  FOLLOWING MENU  */
                                INTERACTION_FOLLOW_CLICK($($OPTIONS61),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                                /* ------------------------------------------------- */
                                
								/* ------------------------------------------------- 
								REGISTER_CLICK($($OPTIONS70),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS71),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS72),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS73),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS74),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
								REGISTER_CLICK($($OPTIONS75),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                                */
							}
							/* ------------------------------------------------- */
							if($('#form_'+$code+'_'+$i+'_options60').is(':visible')){
								CONSOLE_MANAGER('Activity_scroll_function_MGMT01','--- FEED: EVALUATE LOADED CONTENT ---');
								var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
								//var $OPTIONS60 = '#form_'+$code+'_'+$i+'_options60';
								var $TOTAL = parseInt($('#totalPages').val());
								var $PREVIOUS_PAGE = parseInt($PAGE);
								if(($TOTAL <= parseInt($PAGE))){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT01','--- FEED: LAST PAGE ---');
								   	$($OPTIONS01).addClass('container_scroll_complete_tail');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
								}
								else if($TOTAL > parseInt($PAGE)){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT01','---FEED: ADDITIONAL PAGES AVAILABLE---');
									$($OPTIONS01).removeClass('container_scroll_complete_tail');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
									$($OPTIONS01).addClass('any_visuallyhidden');
									//$($OPTIONS60).removeClass('container_scroll_tail_frame');
									//$($OPTIONS60).addClass('any_visuallyhidden');
								}
								/*
								if($TOTAL < $PREVIOUS_PAGE){
									CONSOLE_MANAGER('Activity_scroll_function_MGMT01','---3---');
									$($OPTIONS01).removeClass('container_scroll_load_tail');
									$($OPTIONS01).removeClass('container_scroll_complete_tail');
									//$($OPTIONS01).addClass('any_visuallyhidden');
									$($OPTIONS60).removeClass('container_scroll_tail_frame');
									$($OPTIONS60).addClass('any_visuallyhidden');
								}
								*/
							}
						}
                        
                        $('#standard_results_ajax_responseData').val(null);
						//$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
						/* -------------------------------------------------------- */
                        function REGISTER_CATALOG_VIEW($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX20,$INDEX21){
                            //CONSOLE_MANAGER('REGISTER_CATALOG_VIEW','REGISTER value -- --- -- : '+$INDEX21.val());
                            $button_obj.unbind().click(function(event) {
                                PHOTOSYSTEM_FEED_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX20,$INDEX21);
                            });
                        }
                        /* --------------------------------------------------------  */
                        function REGISTER_CONTRIBUTION_VIEW($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX23,$INDEX24){
                            //CONSOLE_MANAGER('REGISTER_CONTRIBUTION_VIEW','REGISTER');
                            $button_obj.unbind().click(function(event) {
                                PHOTOBUCKET_FEED_CONTRIBUTION_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX23,$INDEX24);
                            });
                        }
						/* -------------------------------------------------------- */
						function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
							$button_obj.unbind().click(function(event) {
								event.stopPropagation;
								$('#standard_entity_modify_title').val($TITLE.val());
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($button_obj.attr('id'));
								$('#standard_entity_modify_control').val($INDEX01.val());
								standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)												  
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function REGISTER_METRICS_CLICK($button_obj,$val_code,$code){
							//CONSOLE_MANAGER('REGISTER_METRICS_CLICK','CLICK: '+$button_obj.attr('id'));
                            //$button_obj.unbind().click(function(event) {
                            NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,null,null,null);
                            //});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function REGISTER_DESCRIPTION_CLICK($button_obj,$val_code,$code){
							//CONSOLE_MANAGER('REGISTER_DESCRIPTION_CLICK','CLICK: '+$button_obj.attr('id'));
                            //$button_obj.unbind().click(function(event) {
                            NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,null,null,null);
                            //});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function INTERACTION_REGISTER_CLICK($button_obj1,$button_obj2,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
                            var Butt_id = $button_obj1.attri('id');
                            //SET_BUTTON_CLICK_ACTION(Butt_id);
							$button_obj1.unbind().click(function(event) {
                                event.stopPropagation;
                                /* -------------------------------------------------------- */
                                var str = $button_obj1.val();
                                CONSOLE_MANAGER('profile_action_class','value: '+str);
                                if (str.indexOf("setDislike") >= 0){
                                    /* -------------------------------------------------------- */
                                    /* FOR THE OTHER FORMATTED PAGES */
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* FOR THE STANDARD FORMATTED PAGES */
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                    }
                                }
                                else if (str.indexOf("setDoeslike") >= 0){
                                    /* -------------------------------------------------------- */
                                    /* FOR THE OTHER FORMATTED PAGES */
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_OTHER_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_OTHER_footer_frame_option_DISLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_OTHER_footer_frame_option_DOESLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_OTHER_footer_frame_option_DOESLIKE_ACTIVE');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* FOR THE STANDARD FORMATTED PAGES */
                                    /* -------------------------------------------------------- */
                                    if($button_obj2.hasClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE')){
                                        $button_obj2.removeClass('container_page_STANDARD_footer_frame_option_DISLIKE_ACTIVE');
                                        $button_obj2.addClass('container_page_STANDARD_footer_frame_option_DISLIKE_DEFAULT');
                                    }
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($button_obj1.hasClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE')){
                                        $button_obj1.removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                        $button_obj1.addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                    }else{
                                        $button_obj1.removeClass('container_page_STANDARD_footer_frame_option_DOESLIKE_DEFAULT');
                                        $button_obj1.addClass('container_page_STANDARD_footer_frame_option_DOESLIKE_ACTIVE');
                                    }
                                }
								/* -------------------------------------------------------- */
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($INDEX04.val());
                                $('#standard_entity_modify_index5').val($INDEX05.val());
                                $('#standard_entity_modify_index6').val($INDEX06.val());
                                $('#standard_entity_modify_index7').val($button_obj1.val());
								$('#standard_entity_modify_control').val($button_obj1.attr('id'));
                                feed_item_instance_function('Activity_scroll_function_MGMT01', 'Interaction_Like',$button_obj1.attr('id'),event);
                                window_complete_script('Activity_scroll_function_MGMT01');
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
						function INTERACTION_FOLLOW_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
                            var str = $button_obj.val();
                            var $DATES_obj_array = null;
                            var $FOLLOW_TEXT,$FOLLOWING_TEXT = null;
                            CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK','value: '+str);
                            /* -------------------------------------------------------- */
                            var jsonValidate  = isJSON($button_obj.val());
                            if(jsonValidate==true){
                                $DATES_obj_array = JSON.parse($button_obj.val());
                                if($DATES_obj_array != null){

                                    if($DATES_obj_array.length > 0){
                                        $DATES_obj_array.forEach((element, index, array) => {
                                            if(element){
                                                if(typeof element.following != "undefined"){
                                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.following);
                                                    $FOLLOWING_TEXT = element.following;
                                                }
                                                if(typeof element.follow != "undefined"){
                                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.follow);
                                                    $FOLLOW_TEXT = element.follow
                                                }
                                            }
                                        });
                                    }
                                }
                            }else{
                                CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','false');
                            }                            
                            /* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							$button_obj.unbind().click(function(event) {
                                CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK','CLICK: '+$button_obj.attr('id'));
                                event.stopPropagation;
                                /* -------------------------------------------------------- */
                                var jsonValidate  = isJSON($button_obj.val());
                                if(jsonValidate==true){
                                    $DATES_obj_array = JSON.parse($button_obj.val());
                                    if($DATES_obj_array != null){

                                        if($DATES_obj_array.length > 0){
                                            $DATES_obj_array.forEach((element, index, array) => {
                                                if(element){
                                                    if(typeof element.following != "undefined"){
                                                        CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.following);
                                                        $FOLLOWING_TEXT = element.following;
                                                    }
                                                    if(typeof element.follow != "undefined"){
                                                        CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','value: '+element.follow);
                                                        $FOLLOW_TEXT = element.follow
                                                    }
                                                }
                                            });
                                        }
                                    }
                                }else{
                                    CONSOLE_MANAGER('INTERACTION_FOLLOW_CLICK jsonValidate','false');
                                }
                                /* -------------------------------------------------------- */
                                CONSOLE_MANAGER('follow_action_class','value: '+$button_obj.val());
                                /* -------------------------------------------------------- */
                                /* -------------------------------------------------------- */
                                /*
                                    event_feed_OTHER_activity_follow_neutral
                                    event_feed_OTHER_activity_follow_positive
                                */
                                if($button_obj.hasClass('event_feed_OTHER_activity_follow_positive')){
                                    $button_obj.removeClass('event_feed_OTHER_activity_follow_positive');
                                    $button_obj.addClass('event_feed_OTHER_activity_follow_neutral');
                                    //$button_obj.val(false);
                                    $button_obj.html($FOLLOW_TEXT);
                                }else{
                                    $button_obj.removeClass('event_feed_OTHER_activity_follow_neutral');
                                    $button_obj.addClass('event_feed_OTHER_activity_follow_positive');
                                    //$button_obj.val(true);
                                    $button_obj.html($FOLLOWING_TEXT);
                                }
								/* -------------------------------------------------------- */
								$('#standard_entity_modify_index1').val($INDEX01.val());
								$('#standard_entity_modify_index2').val($INDEX02.val());
								$('#standard_entity_modify_index3').val($INDEX03.val());
								$('#standard_entity_modify_index4').val($INDEX04.val());
                                $('#standard_entity_modify_index5').val($INDEX05.val());
                                $('#standard_entity_modify_index6').val($INDEX06.val());
                                $('#standard_entity_modify_index7').val($button_obj.val());
								$('#standard_entity_modify_control').val($button_obj.attr('id'));
                                feed_item_instance_function('Activity_scroll_function_MGMT01', 'Interaction_Follow',$button_obj.attr('id'),event);
                                
							});
						}
                        /* -------------------------------------------------------- */
						/* -------------------------------------------------------- */
                        function REGISTER_MAP($LAT,$LON,$FRAME,$i){
                            feed_map_activate($LAT,$LON,$FRAME,$i);
                        }
						/* -------------------------------------------------------- 
						var conn = new ab.Session('ws://127.0.0.1:8080',
							function($EVENT) {
								$EVENT.stopPropagation;
								var $DATES_obj_array=[];	
								conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
									var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
									if(jsonValidate==true){
										$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
										if($DATES_obj_array != null){

											if($DATES_obj_array.length > 0){
												$DATES_obj_array.forEach((element, index, array) => {
													if(element){
														if(typeof element.GET_UNIQ_ID != "undefined"){
															if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
																var className = $(element.SET_STATUS).attr('class');
																$(element.SET_STATUS).removeClass(className);
																$(element.SET_STATUS).addClass(data.itemviewCLASS);
															}
														}
													}
												});
											}
										}
									}
								});
							},
							function() {
                            
							},			   
							{'skipSubprotocolCheck': true}		   
						);*//*END FUNCTION */
					}
				}
                //$('#container_scroll').css({
                //    overflow: 'auto',
                //    height: 'auto'
                //});
			}
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('Activity_scroll_function_MGMT01');
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ACTIVITY_MATRIX_basics                       ########################## */
export function  ACTIVITY_MATRIX_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('ACTIVITY_MATRIX_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  
			SET_BUTTON_CLICK_ACTION('matrix_form_options28');
            $('#matrix_form_options28').unbind().click(function(e) {
				e.preventDefault();
				profile_geocode();
				setTimeout(function () {
					$('#matrix_form_options27').unbind().click();
				}, 2000);
            });
            */
			SET_BUTTON_CLICK_ACTION("matrix_form_options76");
			SET_BUTTON_CLICK_ACTION("matrix_form_options77");
			/* --------------------------------------------------------  */
			if($("#map")){
				matrix_map_activate();
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('ACTIVITY_MATRIX_basics');
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('standard_results_ajax_control');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#standard_results_ajax_control").val()){  
                                CONSOLE_MANAGER('ACTIVITY_MATRIX_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                                $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                                if($('#search_request_control').length){
                                   $SEARCH_CNTRL = $('#search_request_control').val();
                                }
                                /* -------------------------------------------------------- */
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('ACTIVITY_MATRIX_basics',mutation.type+': attributes');
                                    //$OBJECT.val($("#standard_results_ajax_responseData").val());
                                    /* -------------------------------------------------------- */
                                    if($DATA.attr('class')=='Scroll_Activity_UI_class'){
                                         scrollToVertCenter(true);
                                    }
                                    /* -------------------------------------------------------- */
                                    //$("#standard_results_ajax_control").val('');

                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('ACTIVITY_MATRIX_basics',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
			/* ------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//$("#matrix_form_index1").val($("#matrix_form_formFilter1").val())
			//$("#matrix_form_index2").val($("#matrix_form_formFilter2").val())
			//$("#matrix_form_index3").val($("#matrix_form_formFilter3").val())
			//$("#matrix_form_index4").val($("#matrix_form_formFilter4").val())
            //$("#matrix_form_index5").val($("#matrix_form_formFilter5").val())
            //$("#matrix_form_index6").val($("#matrix_form_formFilter6").val())
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- 
			$("#matrix_form_options40").unbind().click(function(event) {
				$('#standard_entity_modify_index1').val($("#matrix_form_index1").val());
				$('#standard_entity_modify_index2').val($("#matrix_form_index2").val());
				$('#standard_entity_modify_index3').val($("#matrix_form_index3").val());
				$('#standard_entity_modify_index4').val($("#matrix_form_index4").val());
				$('#standard_entity_modify_control').val($("#matrix_form_options40").attr('id'));
				standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
			});
			 */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(($('#matrix_form_control').val()=='TRUE')||($('#matrix_form_control').val()==true)){
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if($('#matrix_form_formFilter1').val()){
					var jsonValidate  = isJSON($('#matrix_form_formFilter1').val());
					if(jsonValidate==true){
						var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter1').val());
						var $i=0;
						for($i=0; $i<6;$i++){
							if($('#matrix_form_options'+$i).is(':visible')){
								modify_active($('#matrix_form_options'+$i),$i);
								$.each($DATES_obj_array, function (index, value) {
									if($i==index){
										if(parseInt(value)){
											$('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_1_active');
										}
									}
								});
							}
						}
					}
				}
                /* --------------------------------------------------------  */
				if($('#matrix_form_formFilter2').val()){
					var jsonValidate  = isJSON($('#matrix_form_formFilter2').val());
					if(jsonValidate==true){
						var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter2').val());
						var $i=0;
						for($i=parseInt(6); $i<12;$i++){
							if($('#matrix_form_options'+$i).is(':visible')){
								modify_active_sub1($('#matrix_form_options'+$i),$i);
								$.each($DATES_obj_array, function (index, value) {
									if($i==index){
										if(parseInt(value)){
											$('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_2_active');
										}
									}
								});
							}
						}
					}
				}
				/* --------------------------------------------------------  */
				if($('#matrix_form_formFilter3').val()){
					var jsonValidate  = isJSON($('#matrix_form_formFilter3').val());
					if(jsonValidate==true){
                        CONSOLE_MANAGER('matrix_form_formFilter3','JSON VALID');
						var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter3').val());
						var $i=0;
						for($i=parseInt(12); $i<42;$i++){
							if($('#matrix_form_options'+$i).is(':visible')){
                                CONSOLE_MANAGER('matrix_form_formFilter3','VISIBLE:  matrix_form_options'+$i);
								modify_active_sub2($('#matrix_form_options'+$i),$i);
								$.each($DATES_obj_array, function (index, value) {
									if($i==index){
										if(parseInt(value)){
											$('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_3_active');
										}
									}
								});
							}
						}
					}
				}
				/* -------------------------------------------------------- */
				if($('#matrix_form_formFilter4').val()){
					var jsonValidate  = isJSON($('#matrix_form_formFilter4').val());
					if(jsonValidate==true){
						var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter4').val());
						var $i=0;
						for($i=parseInt(60); $i<66;$i++){
							if($('#matrix_form_options'+$i).is(':visible')){
								modify_active_sub3($('#matrix_form_options'+$i),$i);
								$.each($DATES_obj_array, function (index, value) {
									if($i==index){
										if(parseInt(value)){
											$('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_4_active');
										}
									}
								});
							}
						}
					}
				} 
				/* --------------------------------------------------------  */
				if($('#matrix_form_formFilter5').val()){
					var jsonValidate  = isJSON($('#matrix_form_formFilter5').val());
					if(jsonValidate==true){
                        CONSOLE_MANAGER('matrix_form_formFilter3','JSON VALID');
						var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter5').val());
						var $i=0;
						for($i=parseInt(66); $i<76;$i++){
							if($('#matrix_form_options'+$i).is(':visible')){
                                CONSOLE_MANAGER('matrix_form_formFilter5','VISIBLE:  matrix_form_options'+$i);
								modify_active_sub4($('#matrix_form_options'+$i),$i);
								$.each($DATES_obj_array, function (index, value) {
									if($i==index){
										if(parseInt(value)){
											$('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_5_active');
										}
									}
								});
							}
						}
					}
				}
				
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				function modify_active($Button,$Button_var){
					$Button.unbind().click(function(event) {
						event.stopPropagation;
						if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_1_active')){
							$Button.removeClass('form_filter_menu_option_1_active');
							update_matrix_array($('#matrix_form_formFilter1'),$('#matrix_form_index1'),$Button_var,false)
						}else{
							$Button.addClass('form_filter_menu_option_1_active');
							update_matrix_array($('#matrix_form_formFilter1'),$('#matrix_form_index1'),$Button_var,true)
						}
					});
				}
                function modify_active_sub1($Button,$Button_var){
					$Button.unbind().click(function(event) {
						event.stopPropagation;
						if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_2_active')){
							$Button.removeClass('form_filter_menu_option_2_active');
							update_matrix_array($('#matrix_form_formFilter2'),$('#matrix_form_index2'),$Button_var,false)
						}else{
							$Button.addClass('form_filter_menu_option_2_active');
							update_matrix_array($('#matrix_form_formFilter2'),$('#matrix_form_index2'),$Button_var,true)
						}
					});
				}
				function modify_active_sub2($Button,$Button_var){
					$Button.unbind().click(function(event) {
						event.stopPropagation;
                        CONSOLE_MANAGER('modify_active_sub2','ACTIVATE- '+$Button.attr('id'));
						if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_3_active')){
							$Button.removeClass('form_filter_menu_option_3_active');
							update_matrix_array($('#matrix_form_formFilter3'),$('#matrix_form_index3'),$Button_var,false)
						}else{
							$Button.addClass('form_filter_menu_option_3_active');
							update_matrix_array($('#matrix_form_formFilter3'),$('#matrix_form_index3'),$Button_var,true)
						}
					});
				}
				function modify_active_sub3($Button,$Button_var){
					$Button.unbind().click(function(event) {
						event.stopPropagation;
						if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_4_active')){
							$Button.removeClass('form_filter_menu_option_4_active');
							update_matrix_array($('#matrix_form_formFilter4'),$('#matrix_form_index4'),$Button_var,false)
						}else{
							$Button.addClass('form_filter_menu_option_4_active');
							update_matrix_array($('#matrix_form_formFilter4'),$('#matrix_form_index4'),$Button_var,true)
						}
					});
				}
				function modify_active_sub4($Button,$Button_var){
					$Button.unbind().click(function(event) {
						event.stopPropagation;
                        CONSOLE_MANAGER('modify_active_sub4','ACTIVATE- '+$Button.attr('id'));
						if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_5_active')){
							$Button.removeClass('form_filter_menu_option_5_active');
							update_matrix_array($('#matrix_form_index5'),$('#matrix_form_index5'),$Button_var,false)
						}else{
							$Button.addClass('form_filter_menu_option_5_active');
							update_matrix_array($('#matrix_form_formFilter5'),$('#matrix_form_index5'),$Button_var,true)
						}
					});
				}
			}
			/* ------------------------------------------------- */	
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		//window_complete_script('ACTIVITY_MATRIX_basics');
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function update_matrix_array($OBJECT,$INDEX,$Button_var,$CONTROL){
    //$("#matrix_form_index1").val($("#matrix_form_formFilter1").val())
    //$("#matrix_form_index2").val($("#matrix_form_formFilter2").val())
    //$("#matrix_form_index3").val($("#matrix_form_formFilter3").val())
    //$("#matrix_form_index4").val($("#matrix_form_formFilter4").val())
    //$("#matrix_form_index5").val($("#matrix_form_formFilter5").val())
    //$("#matrix_form_index6").val($("#matrix_form_formFilter6").val())
    CONSOLE_MANAGER('update_matrix_array','$OBJECT ID = '+$OBJECT.attr('id'));
    CONSOLE_MANAGER('update_matrix_array','$OBJECT VALUE 1 = '+$OBJECT.val());
    CONSOLE_MANAGER('update_matrix_array','$INDEX VALUE = '+$INDEX.val());
    /*
    var SET_OBJECT = null;
    SET_OBJECT = $OBJECT.val()

    $.each(SET_OBJECT, function(index, element) {
        CONSOLE_MANAGER('update_matrix_array','SET_OBJECT[index] = '+SET_OBJECT[index]);
    });
    */
    var jsonValidate  = isJSON($OBJECT.val());
    if(jsonValidate==true){
        var $STIGIFY = null;
        CONSOLE_MANAGER('update_matrix_array','$OBJECT VALUE 2 = '+$OBJECT.val());
        var $DATES_obj_array = JSON.parse($OBJECT.val());
        CONSOLE_MANAGER('update_matrix_array','$DATES_obj_array VALUE 3 = '+JSON.stringify($DATES_obj_array));//
        if($CONTROL==true){
            var $tempVal = parseInt($Button_var)+1;
            CONSOLE_MANAGER('IF update_matrix_array','$tempVal = '+$tempVal);
            /* ------------------------------------------------- */
            /* ------------------------------------------------- */
            var $i=0;
            for($i=parseInt(6); $i<12;$i++){
                if($('#matrix_form_options'+$i).is(':visible')){
                    //modify_active_sub1($('#matrix_form_options'+$i),$i);
                    $.each($DATES_obj_array, function (index, value) {
                        if($i==index){
                            if(parseInt(value)){
                                $('#matrix_form_options'+index).removeClass('form_filter_menu_option_2_active');
							    //update_matrix_array($('#matrix_form_formFilter2'),$('#matrix_form_index2'),$Button_var,true)
                                $DATES_obj_array[index] = parseInt(0);
                                //$STIGIFY = JSON.stringify($DATES_obj_array);
                                //CONSOLE_MANAGER('ELSE update_matrix_array','$STIGIFY = '+$STIGIFY);
                                //$OBJECT.val($STIGIFY);
                                //$INDEX.val($STIGIFY);
                            }
                        }
                    });
                }
            }
            /* ------------------------------------------------- */
            /* ------------------------------------------------- */
            $.each($DATES_obj_array, function (index, value) {
                if(index == $Button_var){
                    var $VAR1 = parseInt(index);
                    CONSOLE_MANAGER('IF update_matrix_array','index = '+index);
                    CONSOLE_MANAGER('IF update_matrix_array','$Button_var = '+$Button_var);
                    CONSOLE_MANAGER('IF update_matrix_array','value = '+value);
                    $DATES_obj_array[index] = $VAR1  + parseInt(1);
                    CONSOLE_MANAGER('IF update_matrix_array','$DATES_obj_array[index] = '+ $VAR1 + parseInt(1));
                    $STIGIFY = JSON.stringify($DATES_obj_array);
                    CONSOLE_MANAGER('IF update_matrix_array','$STIGIFY = '+$STIGIFY);
                    $OBJECT.val($STIGIFY);
                    $INDEX.val($STIGIFY);
                }
            });
        }else{
            var $tempVal = parseInt($Button_var)+1;
            CONSOLE_MANAGER('ELSE update_matrix_array','$tempVal = '+$tempVal);
            $.each($DATES_obj_array, function (index, value) {
                if(index == $Button_var){
                    $DATES_obj_array[index] = parseInt(0);
                    $STIGIFY = JSON.stringify($DATES_obj_array);
                    CONSOLE_MANAGER('ELSE update_matrix_array','$STIGIFY = '+$STIGIFY);
                    $OBJECT.val($STIGIFY);
                    $INDEX.val($STIGIFY);
                }
            });
        }
    }
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	             ########################## */
export function  Activity_feed_function_MGMT(){
		$(document).ready(function() {
            CONSOLE_MANAGER('Activity_feed_function_MGMT','ACTIVATE');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options1active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options2active');
			SET_BUTTON_CLICK_ACTION('profile_view_menu_options3active');
			/* --------------------------------------------------------  */
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
			/*END FUNCTION */
			$( document ).ready(function(event) {
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				function initiate_feed_items(){
					/* --------------------------------------------------------  */
					var $ENTITYLISTMgmt = [];
					//var $SUBMgmt = {};
					/* --------------------------------------------------------  */
					var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
					var $code = $("#indexvariables_objects").val();
					for(var $i=0; $i<Object_count; $i++){

						var $SEQUENCE = '#form_'+$code+'_'+$i+'_button';
						var $FSEQUENCE = '#form_'+$code+'_'+$i+'_buttonFlare';
						var $LSEQUENCE = '#form_'+$code+'_'+$i+'_buttonLabel';
						var $VSEQUENCE = '#form_'+$code+'_'+$i+'_formValue';
						var $ISEQUENCE = '#form_'+$code+'_'+$i+'_buttonIcon';
						var $CCSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
						var $FCSEQUENCE = '#form_'+$code+'_'+$i+'_formCoords';
						var $RCSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_file_path';
						var $EVENTSEQUENCE = '#form_'+$code+'_'+$i+'_uniqEventId';
						var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
						
						var $3SEQUENCE = '#form_'+$code+'_'+$i+'_options3';
						var $4SEQUENCE = '#form_'+$code+'_'+$i+'_options4';
						var $5SEQUENCE = '#form_'+$code+'_'+$i+'_options5';
						var $6SEQUENCE = '#form_'+$code+'_'+$i+'_options6';
						
						var $DISLIKESEQUENCE = '#form_'+$code+'_'+$i+'_options7';
						var $LIKESEQUENCE = '#form_'+$code+'_'+$i+'_options8';

						if($($SEQUENCE).is(':visible')){
							//$SUBMgmt = $SUBMgmt{$($EVENTSEQUENCE).val(),$($RCSEQUENCE).val()};
							//var $SUBMgmt = [$($EVENTSEQUENCE).val(),$($RCSEQUENCE).val()];
							var $SUBMgmt = {GET_UNIQ_ID:$($EVENTSEQUENCE).val(),GET_ITEM_ID:$($RCSEQUENCE).val(),SET_STATUS:$EVENTSTATUS};
							//$ENTITYLISTMgmt.push($SUBMgmt);
							$ENTITYLISTMgmt[$i] =$SUBMgmt;
							
							ACTIVITY_AJAX($($DISLIKESEQUENCE),$i,$code);
							ACTIVITY_AJAX($($LIKESEQUENCE),$i,$code);
							REGISTER_CLICK($($SEQUENCE),$i,$code);
							REGISTER_CLICK($($3SEQUENCE),$i,$code);
							REGISTER_CLICK($($4SEQUENCE),$i,$code);
							REGISTER_CLICK($($5SEQUENCE),$i,$code);
							REGISTER_CLICK($($6SEQUENCE),$i,$code);
						}
					}
					$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));//
					/* --------------------------------------------------------  */
					window_complete_script('function: Activity_feed_function_MGMT - ln: 26663');
				}
				initiate_feed_items();
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				function ACTIVITY_AJAX($button_obj,$val_code,$code){
					$button_obj.unbind().click(function(event) {
						$EVENT = event;
						/*SINGLE CLICK*/
						event.stopPropagation;
						$('#standard_entity_modify_uniqEventId').val($('#form_'+$code+'_'+$val_code+'_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						//$('#standard_entity_modify_control').val($('#form_'+$val_code+'_control').val());
						/* --------------------------------------------------------  */
						var $DATA = $('.Feed_interaction_Activity_UI_class');
						var $URL = '/system/profile/item/interaction/feed/ajax';
						/* ------------------------------------------------- */	
						var $DATA_RETURN = control_update_noview_ajax(
							$DATA,
							$URL,
							$EVENT
						);
						if($DATA_RETURN){

						}/* END DATA RETURN */
						/* --------------------------------------------------------  */
					});
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				/* -------------------------------------------------------- */
				 var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						var $DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														var className = $(element.SET_STATUS).attr('class');
														//item_event_activity_active	item_event_activity_pending	item_event_activity_complete
														//activityCLASS	scheduleCLASS	itemviewCLASS
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.activityCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
				/* -------------------------------------------------------- */
			}); 
			/* --------------------------------------------------------  */
			function activate_feed_media($button_obj,$val_code,$control){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
                    //media_image_pop_function(event)
                    $('#standard_entity_modify_uniqEventId').val($('#form_'+$val_code+'_uniqEventId').val());
                    $('#standard_entity_modify_registerCode').val($('#form_'+$val_code+'_registerCode').val());
                    //$('#standard_entity_modify_control').val($('#form_'+$val_code+'_control').val());
                    //standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj, $val_code,$code){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
                    //media_image_pop_function(event)
                    $('#standard_entity_modify_uniqEventId').val($('#form_'+$code+'_'+$val_code+'_uniqEventId').val());
                    $('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
                    $('#standard_entity_modify_control').val('form_click');//+$val_code+'_control'
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}		
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
				  	mutations.forEach(function(mutation) {
						$(document).ready(function() {
							if($("#standard_results_ajax_control").val()){  
                                CONSOLE_MANAGER('Activity_feed_function_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('Activity_feed_function_MGMT',mutation.type+': attributes');
									//$OBJECT.val($("#standard_results_ajax_responseData").val());
									/* -------------------------------------------------------- */
									$CONTROL_RETURN = $("#standard_results_ajax_control").val();
									if($CONTROL_RETURN.includes('standard_results_ajax_scrollActive_refresh')){
										if(parseInt($("#standard_results_ajax_entityCount").val())>0){
											//$("#feed_functional_ajax_now_load").append($("#standard_results_ajax_responseData").val());
											$("#feed_functional_ajax_now_load").prepend($("#standard_results_ajax_responseData").val());
											register_feed_items();
										}
									}
									if($CONTROL_RETURN.includes('standard_results_ajax_scrollActive_history')){
										if(parseInt($("#standard_results_ajax_entityCount").val())>0){
											$("#feed_functional_ajax_historic_load").append($("#standard_results_ajax_responseData").val());
											//$("#feed_functional_ajax_historic_load").prepend($("#standard_results_ajax_responseData").val());
											register_feed_items();
										}
									}
									/* -------------------------------------------------------- */
									$("#standard_results_ajax_responseData").val('');
									$("#standard_results_ajax_control").val('');
									$("#standard_results_ajax_entityMgmt").val('');
									$("#standard_results_ajax_entityCount").val('');

									$("#standard_results_ajax_entityuniquelistMgmt").val('');
									$("#standard_results_ajax_limit").val('');
									$("#standard_results_ajax_offset").val('');

									$("#standard_results_ajax_timeOffset").val('');
									$("#standard_results_ajax_startTimeOffset").val('');
									$("#standard_results_ajax_endTimeOffset").val('');
									//$('#standard_results_ajax_scrollActive').val(null);
									//$('#standard_results_ajax_scrollDirection').val(null);
								}else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('Activity_feed_function_MGMT',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
				  	});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();

			})();/*END FUNCTION */
	/* -------------------------------------------------------- */
	});/**	END OF $(document).ready(function()*/
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/