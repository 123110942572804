/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  
/*  ADMIN_OPTIONS_LOAD
/*  
/*  ADMIN_LISTVIEW_basics
/*	
/*  
/*  
/*  
/*
/*  
/*  
/*  
/* 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

/********************/
import * as spectrum from 'spectrum-colorpicker2';
/********************/
import Cropper from 'cropperjs';

var momenttz = require('moment-timezone');
import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT as PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_UPDATE as PHOTOBUCKET_CONTRIBUTION_UPDATE } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";

import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { initiate_holiday_creator as initiate_holiday_creator } from "./mgmt_control.js";
import { initiate_holiday_daily_creator as initiate_holiday_daily_creator } from "./mgmt_control.js";
import { initiate_holiday_weekly_creator as initiate_holiday_weekly_creator } from "./mgmt_control.js";
import { initiate_holiday_monthly_creator as initiate_holiday_monthly_creator } from "./mgmt_control.js";
import { initiate_holiday_yearly_creator as initiate_holiday_yearly_creator } from "./mgmt_control.js";

import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_LISTED as ACTIVATE_UPLOAD_FORM_LISTED } from "./mgmt_control.js";

import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { event_create_pop_map_activate as event_create_pop_map_activate } from "./mgmt_mapping.js";
import { event_create_pop_manager_map_activate as event_create_pop_manager_map_activate } from "./mgmt_mapping.js";
import { event_profile_pop_manager_map_activate as event_profile_pop_manager_map_activate } from "./mgmt_mapping.js";

import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";

import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_control.js";

import { EVENT_METRICS_CONTROL_basics as EVENT_METRICS_CONTROL_basics } from "./mgmt_metrics.js";
import { media_image_activate as media_image_activate } from "./mgmt_control.js";

/*

momenttz

*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function admin_item_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('admin_item_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('admin_item_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('admin_item_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('admin_item_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
	/* ------------------------------------------------- */
    if(form_entity.includes('event_activity_interaction_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/account/update';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_item_settings_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/description/modify';
    }
    /* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('holiday_event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/description/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/description/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_itinerary_creator_control')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/schedule/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index1').val($('#image_creator_name').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());
		/*-------------------------------------------------------- */
		if(document.getElementById("image_creator_formFilter2")){
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/upload';
			}
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_PHOTOSYSTEM_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/photosystem/upload';
			}
		}else{
			var $DATA = $('.Interaction_Activity_UI_class');
        	var $URL = '/entity/file/upload';
		}
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){
    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
var ADMIN_OPTIONS_LOAD = (function () {
    /* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    if($('#admin_mgmt_formFilter1').val()<1){
        CONSOLE_MANAGER('ADMIN_OPTIONS_LOAD','NOT ACTIVATED');
    }
    else if($('#admin_mgmt_formFilter1').val()>1){
        CONSOLE_MANAGER('ADMIN_OPTIONS_LOAD','NOT ACTIVATED');
    }
    else if($('#admin_mgmt_formFilter1').val()==1){
        CONSOLE_MANAGER('ADMIN_OPTIONS_LOAD','ACTIVATED');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var $YEAR = moment().utc().local().format('YYYY');
        var $MONTH = moment().utc().local().format('MMMM');
        var $DAY = moment().utc().local().format('D');
        var $TIMEZONE = momenttz.tz.guess(true);
        //$('#userlog_active_menu_index1').val($YEAR);
        //$('#userlog_active_menu_index2').val($MONTH.toUpperCase());
        //$('#userlog_active_menu_index3').val($DAY);
        //$('#userlog_active_menu_formCurrentIndex').val($TIMEZONE);
        /* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
        /* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
        /* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
        /* ###############   	sys_loging_options	            		########################## */
        /* -----  */
        if(document.getElementById("admin_mgmt_options1active")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options1active");	
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options1")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options1");
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options2")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options2");	
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options3")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options3");			
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options4")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options4");		
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options5")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options5");
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options6")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options6");
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options7")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options7");
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options8")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options8");	
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options9")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options9");			
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options10")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options10");		
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options11")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options11");
        }
        /* -----  */
        if(document.getElementById("admin_mgmt_options12")){
            SET_BUTTON_CLICK_ACTION("admin_mgmt_options12");
        }
        /************************************//************************************/
        /************************************//************************************/
        $('#admin_mgmt_options1').unbind().click(function(event) {
            if($('#admin_mgmt_search_parameters01').val().length==0){
                //event.stopPropagation();
                event.preventDefault();
                //event.stopImmediatePropagation();
                window_complete_script('function: search_bar_function'); 
            }
        });
        $('#admin_mgmt_options2').unbind().click(function(event) {
            if($('#admin_mgmt_search_parameters02').val().length==0){
                event.preventDefault();
                window_complete_script('function: search_bar_function'); 
            }
        });
        $('#admin_mgmt_options3').unbind().click(function(event) {
            if($('#admin_mgmt_search_parameters03').val().length==0){
                event.preventDefault();
                window_complete_script('function: search_bar_function'); 
            }
        });
        $('#admin_mgmt_options4').unbind().click(function(event) {
            if($('#admin_mgmt_search_parameters04').val().length==0){
                event.preventDefault();
                window_complete_script('function: search_bar_function'); 
            }
        });
        /* --------------------------------------------------------  */
        const r_expression = new RegExp("\\w+\\s", "g");
        /* --------------------------------------------------------  */
        //if(document.getElementById("admin_mgmt_search_parameters01").length){
        /* --------------------------------------------------------  */
        document.getElementById("admin_mgmt_search_parameters01").onfocus = function(event) { 
            CONSOLE_MANAGER('admin_mgmt_search_parameters01','FOCUS');
            $('#admin_mgmt_search_parameters01').keyup(function(e) {
                var string = document.getElementById("admin_mgmt_search_parameters01").value;
                var key = String.fromCharCode(e.which);
                //var codes = [9,13,32];
                var codes = [32];
                CONSOLE_MANAGER('admin_mgmt_search_parameters01','keyup: '+key);
                document.getElementById("admin_mgmt_search_parameters01").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    var action = e.type;
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING1=$('#admin_mgmt_search_parameters01').val();
                    CONSOLE_MANAGER('admin_mgmt_search_parameters01','string: '+$STRING1);
                    $('#admin_mgmt_search_parameters01').val($STRING1.replace(/\s{2,}/g, ' '));
                    /* --------------------------------------------------------  */
                    if (action != "keypress" && e.keyCode == 32 && e.target.value.endsWith(". ")) {
                        CONSOLE_MANAGER('keypress action has been skipped, space-bar has been pressed and the input ends with','period');
                        e.target.value = e.target.value.replace(new RegExp(". " + "$"), " ");
                    }
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /*
                if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                   (e.keyCode == $.ui.keyCode.DELETE )|| 
                   (e.keyCode == $.ui.keyCode.BACKSPACE )){

                }
                */
            });
            /* --------------------------------------------------------  */
            document.getElementById("admin_mgmt_search_parameters01").onfocus = function(event) {
               $(document).on('keypress',function(e) {
                    if(e.which == 13) {
                        $('#admin_mgmt_options1').unbind().click(); 
                    }
                });
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        document.getElementById("admin_mgmt_search_parameters02").onfocus = function(event) { 
            CONSOLE_MANAGER('admin_mgmt_search_parameters02','FOCUS');
            $('#admin_mgmt_search_parameters02').keyup(function(e) {
                var string = document.getElementById("admin_mgmt_search_parameters02").value;
                var key = String.fromCharCode(e.which);
                //var codes = [9,13,32];
                var codes = [32];
                CONSOLE_MANAGER('admin_mgmt_search_parameters02','keyup: '+key);
                document.getElementById("admin_mgmt_search_parameters02").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    var action = e.type;
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#admin_mgmt_search_parameters02').val();
                    CONSOLE_MANAGER('admin_mgmt_search_parameters02','string: '+$STRING);
                    $('#admin_mgmt_search_parameters02').val($STRING.replace(/\s{2,}/g, ' '));
                    /* --------------------------------------------------------  */
                    if (action != "keypress" && e.keyCode == 32 && e.target.value.endsWith(". ")) {
                        CONSOLE_MANAGER('keypress action has been skipped, space-bar has been pressed and the input ends with','period');
                        e.target.value = e.target.value.replace(new RegExp(". " + "$"), " ");
                    }
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /*
                if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                   (e.keyCode == $.ui.keyCode.DELETE )|| 
                   (e.keyCode == $.ui.keyCode.BACKSPACE )){

                }
                */
            });
            /* --------------------------------------------------------  */
            document.getElementById("admin_mgmt_search_parameters02").onfocus = function(event) {
               $(document).on('keypress',function(e) {
                    if(e.which == 13) {
                        $('#admin_mgmt_options2').unbind().click(); 
                    }
                });
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        document.getElementById("admin_mgmt_search_parameters03").onfocus = function(event) { 
            CONSOLE_MANAGER('admin_mgmt_search_parameters03','FOCUS');
            $('#admin_mgmt_search_parameters03').keyup(function(e) {
                var string = document.getElementById("admin_mgmt_search_parameters03").value;
                var key = String.fromCharCode(e.which);
                //var codes = [9,13,32];
                var codes = [32];
                CONSOLE_MANAGER('admin_mgmt_search_parameters03','keyup: '+key);
                document.getElementById("admin_mgmt_search_parameters03").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    var action = e.type;
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#admin_mgmt_search_parameters03').val();
                    CONSOLE_MANAGER('admin_mgmt_search_parameters03','string: '+$STRING);
                    $('#admin_mgmt_search_parameters03').val($STRING.replace(/\s{2,}/g, ' '));
                    /* --------------------------------------------------------  */
                    if (action != "keypress" && e.keyCode == 32 && e.target.value.endsWith(". ")) {
                        CONSOLE_MANAGER('keypress action has been skipped, space-bar has been pressed and the input ends with','period');
                        e.target.value = e.target.value.replace(new RegExp(". " + "$"), " ");
                    }
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /*
                if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                   (e.keyCode == $.ui.keyCode.DELETE )|| 
                   (e.keyCode == $.ui.keyCode.BACKSPACE )){

                }
                */
            });
            /* --------------------------------------------------------  */
            document.getElementById("admin_mgmt_search_parameters03").onfocus = function(event) {
               $(document).on('keypress',function(e) {
                    if(e.which == 13) {
                        $('#admin_mgmt_options3').unbind().click(); 
                    }
                });
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        document.getElementById("admin_mgmt_search_parameters04").onfocus = function(event) { 
            $('#admin_mgmt_search_parameters04').keyup(function(e) {
                var string = document.getElementById("admin_mgmt_search_parameters04").value;
                var key = String.fromCharCode(e.which);
                //var codes = [9,13,32];
                var codes = [32];
                CONSOLE_MANAGER('admin_mgmt_search_parameters04','keyup: '+key);
                document.getElementById("admin_mgmt_search_parameters04").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    var action = e.type;
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#admin_mgmt_search_parameters04').val();
                    CONSOLE_MANAGER('admin_mgmt_search_parameters04','string: '+$STRING);
                    $('#admin_mgmt_search_parameters04').val($STRING.replace(/\s{2,}/g, ' '));
                    /* --------------------------------------------------------  */
                    if (action != "keypress" && e.keyCode == 32 && e.target.value.endsWith(". ")) {
                        CONSOLE_MANAGER('keypress action has been skipped, space-bar has been pressed and the input ends with','period');
                        e.target.value = e.target.value.replace(new RegExp(". " + "$"), " ");
                    }
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /*
                if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                   (e.keyCode == $.ui.keyCode.DELETE )|| 
                   (e.keyCode == $.ui.keyCode.BACKSPACE )){

                }
                */
            });
            /* --------------------------------------------------------  */
            document.getElementById("admin_mgmt_search_parameters04").onfocus = function(event) {
               $(document).on('keypress',function(e) {
                    if(e.which == 13) {
                        $('#admin_mgmt_options4').unbind().click(); 
                    }
                });
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        document.getElementById("admin_mgmt_search_parameters05").onfocus = function(event) { 
            $('#admin_mgmt_search_parameters05').keyup(function(e) {
                var string = document.getElementById("admin_mgmt_search_parameters05").value;
                var key = String.fromCharCode(e.which);
                //var codes = [9,13,32];
                var codes = [32];
                CONSOLE_MANAGER('admin_mgmt_search_parameters05','keyup: '+key);
                document.getElementById("admin_mgmt_search_parameters05").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    var action = e.type;
                    if(!path[0]) return;

                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#admin_mgmt_search_parameters05').val();
                    CONSOLE_MANAGER('admin_mgmt_search_parameters05','string: '+$STRING);
                    $('#admin_mgmt_search_parameters05').val($STRING.replace(/\s{2,}/g, ' '));
                    /* --------------------------------------------------------  */
                    if (action != "keypress" && e.keyCode == 32 && e.target.value.endsWith(". ")) {
                        CONSOLE_MANAGER('keypress action has been skipped, space-bar has been pressed and the input ends with','period');
                        e.target.value = e.target.value.replace(new RegExp(". " + "$"), " ");
                    }
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /*
                if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                   (e.keyCode == $.ui.keyCode.DELETE )|| 
                   (e.keyCode == $.ui.keyCode.BACKSPACE )){

                }
                */
            });
            /* --------------------------------------------------------  */
            document.getElementById("admin_mgmt_search_parameters05").onfocus = function(event) {
               $(document).on('keypress',function(e) {
                    if(e.which == 13) {
                        $('#admin_mgmt_options5').unbind().click(); 
                    }
                });
            }
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        
        
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        
    }
})();
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                                            ########################## */
export function ADMIN_LISTVIEW_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('ADMIN_LISTVIEW_basics','ACTIVATED');
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('ADMIN_LISTVIEW_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						//var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.val());
					$('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('ADMIN_LISTVIEW_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/


/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/



/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/