/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 

/*  MGMT_mapping_basics
/*	
/*		SET_GEOLOCATION
/*		MGMT_google_autocomplete
/*		
/*		map_key_activate
/*		update_map_tools
/*		Temp_CenterMap
/*
/*		meta_geocode
/*		geocode
/*		profile_geocode
/*	
/*		multifunction_map_set_selection
/*		multifunction_map_reset
		multifunction_map_activate
		event_create_map_activate
        event_create_pop_map_activate
        event_create_pop_manager_map_activate
        event_profile_pop_manager_map_activate
		micro_map_activate
        description_micro_map_activate
		event_map_activate
		image_meta_map_activate
		feed_map_activate
		event_item_map_activate
		matrix_map_activate
        initiatPhotobucketPins

/*		activate_Stat_view_MGMT
/*		select_item_date_map_activate	|| FUNCTION USED TO ACTIVATE MAP LOCATION SELECTION AND ASSIGNMENT OF NEW EVENT DATES/LOCATIONS
/*		select_date_map_set_selection
/*		select_date_map_set_register	|| REGISTER NEW LOCATIONS ON MAPS BY PRESS
/*		select_date_map_frame_mgmt	|| DISPLAY LOCATION FRAME WHEN SELECTED ON MAPS - FUNCTIONALITY BASED ON ZOOM

	
		item_map
		initiatItemPins
		initiatMultiItemPins
		initiatEventItemPins
		initiatCreatePins
		initiatComplianceControlEventPins
		map_identity_event_control
		
		map_identity_country
		business_recommendations_map
		metrics_meta_map_activate 
        
        
        //import {Client} from "@googlemaps/google-maps-services-js";
        //var GOOGLE_MAPS_API_KEY='AIzaSyCOZFABEYCgmhlnGGM5ARkuM98f1iTkuhI';
        //const client = new Client({});
        //const googleMapsClient = require('@google/maps').createClient({
        //  key: 'AIzaSyCOZFABEYCgmhlnGGM5ARkuM98f1iTkuhI'
        //});


        $.ajax({
            //AIzaSyCOZFABEYCgmhlnGGM5ARkuM98f1iTkuhI
            //url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
            url: 'https://maps.googleapis.com/maps/api/geocode/json',
            method: 'GET',
            data: {
                'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
                'latlng': lat+','+lon,
                //'latlng': '40.7209087,-73.9836303',
                //'no_annotations': 1
            },
            dataType: 'json',
            statusCode: {
                200: function(response){
                    var $status = response.status;
                    if($status.indexOf("OK") > -1){	
                        for (var i = 0; i < response.results[0].address_components.length; i++) {
                            var addressType = response.results[0].address_components[i].types[0];
                            if (componentForm[addressType]) {
                                var val = response.results[0].address_components[i][componentForm[addressType]];
                                if(addressType == 'neighborhood'){
                                    $TEMP_OBJ['neighborhood'] = val;
                                }
                                if(addressType == 'street_number'){
                                    $TEMP_OBJ['address1'] = val;
                                }
                                if(addressType == 'route'){
                                    $TEMP_OBJ['address2'] = val;
                                }
                                if(addressType == 'sublocality_level_1'){
                                    $TEMP_OBJ['address3'] = val;
                                }
                                if(addressType == 'country'){
                                    $TEMP_OBJ['country'] = val;
                                }
                                if(addressType == 'administrative_area_level_1'){
                                    $TEMP_OBJ['state'] = val;
                                }
                                if(addressType == 'locality'){
                                    $TEMP_OBJ['city'] = val;
                                }
                                if(addressType == 'sublocality_level_1'){
                                    $TEMP_OBJ['city'] = val;
                                }
                                if(addressType == 'postal_code'){
                                    $TEMP_OBJ['postal'] = val;
                                }
                            }
                        }
                    }
                    $tempadd = $( "#map_key_search_parameters" ).val().split(',')[0];
                    CONSOLE_MANAGER('geocode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
                },
                402: function(){
                    
                }
            }
        });
/*
/*	map_key_options1
/*	
/*	SET_GEOLOCATION event_create_map_activate
/*	
/*	EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED			business_registration_address  User denied the request for initiatPhotobucketPins
/*	activate_Stat_view_MGMT
/*
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

import { GeocodingApi, Configuration } from '@stadiamaps/api';
//const config = new Configuration({ apiKey: "YOUR-API-KEY" });
//const api = new GeocodingApi(config);

import {StadiaMaps} from 'ol/source.js';///StadiaMaps stamen

import 'ol/ol.css';
import Feature from 'ol/Feature';
import Geolocation from 'ol/Geolocation';
import Map from 'ol/Map';
import View from 'ol/View';
import Point from 'ol/geom/Point';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {OSM, Vector as VectorSource} from 'ol/source';
import {Circle as CircleStyle, Fill, Stroke, Style} from 'ol/style';
import GeoJSON from 'ol/format/GeoJSON';
import {Group as LayerGroup} from 'ol/layer';
//import Stamen from 'ol/source';///Stamen
import KML from 'ol/format/KML';
import Polygon from 'ol/geom/Polygon';
import {Icon} from 'ol/style';
import {addProjection, addCoordinateTransforms, transform} from 'ol/proj';
import Text from 'ol/style/Text';
import {defaults as defaultControls, Attribution} from 'ol/control';
import {Cluster} from 'ol/source';
import * as olExtent from 'ol/extent';
import {unByKey} from 'ol/Observable'
import {bbox} from 'ol/loadingstrategy';
import {get as getProjection, transformExtent} from 'ol/proj';
import {LineString} from 'ol/geom';
import {defaults as defaultInteractions, PinchZoom, Pointer as PointerInteraction} from 'ol/interaction';
import {TileJSON} from 'ol/source';
import {easeOut} from 'ol/easing';
import {getVectorContext} from 'ol/render';
import {DragPan, MouseWheelZoom, defaults} from 'ol/interaction';
import {platformModifierKeyOnly} from 'ol/events/condition';

import Select from 'ol/interaction/Select.js';
import {altKeyOnly, click, pointerMove} from 'ol/events/condition.js';
import {fromLonLat} from 'ol/proj.js';
/********************/
import { map_window_close as map_window_close } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { filter_form_function as filter_form_function } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";

import { event_instance_function as event_instance_function } from "./mgmt_event.js";
import { profile_instance_function as profile_instance_function } from "./mgmt_profile.js";

import { update_matrix_array as update_matrix_array } from "./mgmt_feed.js";

/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function MGMT_mapping_basics(){
	$(document).ready(function(){
        CONSOLE_MANAGER('MGMT_mapping_basics','ACTIVATED');
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		window_complete_script('MGMT_mapping_basics');
	});
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function SET_STADIA_ATTRIBUTION($OBJECT_id){
	CONSOLE_MANAGER('SET_STADIA_ATTRIBUTION','ACTIVATED');
    /* ------------------------- */
    /*
        width > 530px
        stadiamaps_attribution_SMALL1
        stadiamaps_attribution_SMALL2
        stadiamaps_attribution_LARGE1
    */
    var $WIDTH_CONTROL = $('#'+$OBJECT_id).width();
    if($WIDTH_CONTROL>530){
        CONSOLE_MANAGER('SET_STADIA_ATTRIBUTION','ACTIVATED LARGE WIDTH: '+$WIDTH_CONTROL);
        //var iDiv = document.getElementById("view_map");
        //var iDiv = $OBJECT;
        var iDiv = document.getElementById($OBJECT_id);
        var istring01="<div id=\'URL01\'><a href='https://www.stadiamaps.com/' target='_blank' class='stadiamaps_text'>@Stadia Maps</a></div>";
        var istring02="<div id=\'URL02\'><a href='https://stamen.com/' target='_blank' class='stadiamaps_text'>@Stamen Design</a></div>";
        var istring03="<div id=\'URL03\'><a href='https://www.openstreetmap.org/about/' target='_blank' class='stadiamaps_text'>@OpenStreetMap contributors</a></div>";
        /* ------------------------- */
        var innerDiv01 = document.createElement('div');
        innerDiv01.className = 'stadiamaps_attribution_SMALL1';
        innerDiv01.innerHTML=istring01;
        /* ------------------------- */
        var innerDiv02 = document.createElement('div');
        innerDiv02.className = 'stadiamaps_attribution_SMALL2';
        innerDiv02.innerHTML=istring02;
        /* ------------------------- */
        var innerDiv03 = document.createElement('div');
        innerDiv03.className = 'stadiamaps_attribution_LARGE1';
        innerDiv03.innerHTML=istring03;
        /* ------------------------- */
        iDiv.appendChild(innerDiv01);
        iDiv.appendChild(innerDiv02);
        iDiv.appendChild(innerDiv03);
        /* ------------------------- */
    }else if($WIDTH_CONTROL>330){
        CONSOLE_MANAGER('SET_STADIA_ATTRIBUTION','ACTIVATED MID WIDTH: '+$WIDTH_CONTROL);
        var iDiv = document.getElementById($OBJECT_id);
        var istring01="<div id=\'URL01\'><a href='https://www.stadiamaps.com/' target='_blank' class='stadiamaps_text'>@Stadia Maps</a></div>";
        var istring02="<div id=\'URL02\'><a href='https://stamen.com/' target='_blank' class='stadiamaps_text'>@Stamen Design</a></div>";
        var istring03="<div id=\'URL03\'><a href='https://www.openstreetmap.org/about/' target='_blank' class='stadiamaps_text'>@OpenStreetMap contributors</a></div>";
        /* ------------------------- */
        var innerDiv01 = document.createElement('div');
        innerDiv01.className = 'stadiamaps_attribution_SMALL1';
        innerDiv01.innerHTML=istring01;
        /* ------------------------- */
        var innerDiv02 = document.createElement('div');
        innerDiv02.className = 'stadiamaps_attribution_SMALL2';
        innerDiv02.innerHTML=istring02;
        /* ------------------------- */
        var innerDiv03 = document.createElement('div');
        innerDiv03.className = 'stadiamaps_attribution_LARGE1';
        innerDiv03.innerHTML=istring03;
        /* ------------------------- */
        iDiv.appendChild(innerDiv01);
        //iDiv.appendChild(innerDiv02);
        //iDiv.appendChild(innerDiv03);
        /* ------------------------- */
    }else{
        CONSOLE_MANAGER('SET_STADIA_ATTRIBUTION','NOT-ACTIVATED WIDTH: '+$WIDTH_CONTROL);
    }
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function SET_STADIA_GEOLOCATION($LAT,$LON){
	CONSOLE_MANAGER('SET_STADIA_GEOLOCATION','ACTIVATED');
    const api = new GeocodingApi();
    //SET_STADIA_GEOLOCATION(parseFloat($LAT),parseFloat($LON))
    var result = 'FALSE';
    /* --------------------------------------------------------  */
    // Make an API call! The responses use the standard promise API.
    // You can use either the callback interface...
    api.reverse({ pointLat: parseFloat($LAT), pointLon: parseFloat($LON) }).then(function (result) {
        CONSOLE_MANAGER('----   SET_STADIA_GEOLOCATION  ------','RESULTS: '+ JSON.stringify(result));
    }, function (err) {
        CONSOLE_MANAGER('----   SET_STADIA_GEOLOCATION  ------','ERROR: '+ JSON.stringify(err));
    });
    /* --------------------------------------------------------  */

    // ... or the await keyword in an async context
    //const res = await api.search({ text: "Põhja pst 27a" });
    //CONSOLE_MANAGER('----   SET_STADIA_GEOLOCATION  ------','AWAIT RESULTS: '+ res);
	/* --------------------------------------------------------  */
    return result;
}
setTimeout(function() {
    //SET_STADIA_GEOLOCATION(parseFloat($('#page_load_mgmt__lat').val()),parseFloat($('#page_load_mgmt__lon').val()))
}, 1000);
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function SET_GEOLOCATION(){
	CONSOLE_MANAGER('SET_GEOLOCATION','ACTIVATED');
	var $COORDS = [];
	/* --------------------------------------------------------  */
	var options = {
		enableHighAccuracy: true,
		timeout: 120000,
		maximumAge: 0
	};
					
	function success(pos) {
		var crd = pos.coords;
		CONSOLE_MANAGER('SET_GEOLOCATION','Your current position is: '+"\n"+'      Latitude : ' + crd.latitude+"\n"+'      Longitude: ' + crd.longitude+"\n"+'      More or less ' + crd.accuracy + ' meters.');
		$COORDS.push(crd.longitude);
		$COORDS.push(crd.latitude);
		$COORDS.push(crd.accuracy);
		/* --------------------------------------------------------  */
		if($('#map_key_lon')){
			$('#map_key_lon').val(crd.longitude);
		}
		if($('#map_key_lat')){
			$('#map_key_lat').val(crd.latitude);
		}
		/* --------------------------------------------------------  */
		if($('#event_menu01_lon').val()){
			$('#event_menu01_lon').val(crd.longitude);
		}
		if($('#event_menu01_lat').val()){
			$('#event_menu01_lat').val(crd.latitude);
		}
		/* --------------------------------------------------------  */
	};			
	function error(err) {
        CONSOLE_MANAGER('SET_GEOLOCATION','ERROR(' + err.code + '): ' + err.message);
	 	switch(err.code) {
			case err.PERMISSION_DENIED:
				$COORDS.push(err.PERMISSION_DENIED);
                CONSOLE_MANAGER('SET_GEOLOCATION','ERROR - User denied the request for Geolocation.');
				break;
			case err.POSITION_UNAVAILABLE:
				$COORDS.push(err.POSITION_UNAVAILABLE);
                CONSOLE_MANAGER('SET_GEOLOCATION','ERROR - Location information is unavailable.');
				break;
			case err.TIMEOUT:
				$COORDS.push(err.TIMEOUT);
                CONSOLE_MANAGER('SET_GEOLOCATION','ERROR - The request to get user location timed out.');
				break;
			case err.UNKNOWN_ERROR:
				$COORDS.push(err.UNKNOWN_ERROR);
                CONSOLE_MANAGER('SET_GEOLOCATION','ERROR - An unknown error occurred.');
				break;
		}
		//$COORDS.push(err.code);
        window_complete_script('SET_GEOLOCATION - MAPPING - ERROR - LOADING CLOSE');
	};
	setTimeout(navigator.geolocation.getCurrentPosition(success, error, options), 1000)					
	//navigator.geolocation.getCurrentPosition(success, error, options);				
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/**/
	var geolocation = new Geolocation({
		trackingOptions: {
			enableHighAccuracy: false,
			maximumAge: 0,
			timeout: 7500
		}
	});
	geolocation.once('change', function(evt) {
        CONSOLE_MANAGER('SET_GEOLOCATION','Your OL geolocation change: '+"\n"+'      getPosition : ' + geolocation.getPosition()+"\n"+'      getAccuracy: ' + geolocation.getAccuracy()+"\n"+'      getAltitude: ' + geolocation.getAltitude()+"\n"+'      getAltitudeAccuracy: ' + geolocation.getAltitudeAccuracy()+"\n"+'      getHeading: ' + geolocation.getHeading()+"\n"+'      getSpeed: ' + geolocation.getSpeed());
        /*
            geolocation.getPosition()
            geolocation.getAccuracy()
            geolocation.getAltitude()
            geolocation.getAltitudeAccuracy()
            geolocation.getHeading()
            geolocation.getSpeed()
        */
	});
	return $COORDS;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
if(document.getElementById('map_key_lon')){
	var MODIFY_VIEW_LISTENER = (function (){
		var target = document.getElementById('map_key_lon');
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */	
		var observer = new MutationObserver(function(mutations) {
			mutations.forEach(function(mutation) {
				$(document).ready(function() {
					if(($("#map_key_lon").val())&&($("#map_key_lat").val())){
                        CONSOLE_MANAGER('map_key_lon','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
						/* -------------------------------------------------------- */
						if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('map_key_lon',mutation.type+': attributes');
							window_complete_script('SET_GEOLOCATION - MAPPING - LOADING CLOSE');
							/* -------------------------------------------------------- */
						}else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('map_key_lon',mutation.type+': childList');
							/* -------------------------------------------------------- */
						}
					}
				});
			});    
		});//END new MutationObserver
		var config = { attributes: true, childList: true, characterData: true };
		observer.observe(target, config);
		//observer.disconnect();
	})();/*END FUNCTION */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
 /* --------------------------------------------------------	*/
/* --------------------------------------------------------  */
export function address_update($EVENT){
    CONSOLE_MANAGER('address_update','ACTIVATED');
    if($("#event_creator_address" ).is(':visible')){
        if($( "#event_creator_formFilter1" ).val()=='CHILD'){
            $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
            $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
            $('#standard_entity_modify_environmentOption').val($( "#event_creator_environmentOption" ).val());
            $('#standard_entity_modify_address').val($( "#event_creator_address" ).val());
            $('#standard_entity_modify_country').val($( "#event_creator_country" ).val());
            $('#standard_entity_modify_state').val($( "#event_creator_state" ).val());
            $('#standard_entity_modify_city').val($( "#event_creator_city" ).val());
            $('#standard_entity_modify_lat').val($( "#event_creator_lat" ).val());
            $('#standard_entity_modify_lon').val($( "#event_creator_lon" ).val());
            $('#standard_entity_modify_postal').val($( "#event_creator_postal" ).val());
            $('#standard_entity_modify_index5').val($('#event_creator_profileType').val());
            event_instance_function('event_activity_interaction_options', $('#event_creator_address').attr('id'),$('#event_creator_address').val(),$EVENT);
        }
    }
    if($("#event_creator_address" )){
        if($( "#event_creator_formFilter1" ).val()=='CHILD'){
            $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
            $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
            $('#standard_entity_modify_environmentOption').val($( "#event_creator_environmentOption" ).val());
            $('#standard_entity_modify_address').val($( "#event_creator_address" ).val());
            $('#standard_entity_modify_country').val($( "#event_creator_country" ).val());
            $('#standard_entity_modify_state').val($( "#event_creator_state" ).val());
            $('#standard_entity_modify_city').val($( "#event_creator_city" ).val());
            $('#standard_entity_modify_lat').val($( "#event_creator_lat" ).val());
            $('#standard_entity_modify_lon').val($( "#event_creator_lon" ).val());
            $('#standard_entity_modify_postal').val($( "#event_creator_postal" ).val());
            $('#standard_entity_modify_index5').val($('#event_creator_profileType').val());
            event_instance_function('event_activity_interaction_options', $('#event_creator_address').attr('id'),$('#event_creator_address').val(),$EVENT);
        }
    }
	/* --------------------------------------------------------  */
	if($("#image_creator_address" ).is(':visible')){
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
        $('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
        $('#standard_entity_modify_address').val($( "#image_creator_address" ).val());
		$('#standard_entity_modify_address2').val($( "#image_creator_address2").val());
        $('#standard_entity_modify_country').val($( "#image_creator_country" ).val());
        $('#standard_entity_modify_state').val($( "#image_creator_state" ).val());
        $('#standard_entity_modify_city').val($( "#image_creator_city" ).val());
        $('#standard_entity_modify_lat').val($( "#image_creator_imageExifLatitude" ).val());
        $('#standard_entity_modify_lon').val($( "#image_creator_imageExifLongitude" ).val());
		$('#standard_entity_modify_index1').val($( "#image_creator_name" ).val());
        $('#standard_entity_modify_index5').val($('#image_creator_formFilter2').val());
		//$('#standard_entity_modify_formCurrentIndex').val($( "#image_creator_formCurrentIndex" ).val());
		//$('#standard_entity_modify_control').val('image_creator_address');
		//var currval = document.getElementById("settings_activity_type2_activityStatus").value;
		//if(currval==1){
        /* --------------------------------------------------------  */
        if($('#image_creator_formFilter2').val()=='EVENT_TYPE_PHOTOSYSTEM_TYPE' || $('#image_creator_formFilter2').val()=='HOLIDAY_TYPE_PHOTOSYSTEM_TYPE'){
            event_instance_function('image_activity_interaction_options', $('#image_creator_address').attr('id'),$('#image_creator_address').val(),$EVENT);
        }
        if($('#image_creator_formFilter2').val()=='EVENT_TYPE' || $('#image_creator_formFilter2').val()=='HOLIDAY_TYPE'){
            event_instance_function('image_activity_interaction_options', $('#image_creator_address').attr('id'),$('#image_creator_address').val(),$EVENT);
        }
        /* --------------------------------------------------------  */
        if($('#image_creator_formFilter2').val()=='PROFILE_TYPE_PHOTOSYSTEM_TYPE' || $('#image_creator_formFilter2').val()=='BUSINESS_TYPE_PHOTOSYSTEM_TYPE'){
            profile_instance_function('image_activity_interaction_options', $('#image_creator_address').attr('id'),$('#image_creator_address').val(),$EVENT);
        }
        if($('#image_creator_formFilter2').val()=='PROFILE_TYPE' || $('#image_creator_formFilter2').val()=='BUSINESS_TYPE'){
            profile_instance_function('image_activity_interaction_options', $('#image_creator_address').attr('id'),$('#image_creator_address').val(),$EVENT);
        }
		//}
    }
}
export function profile_geocode(){
	CONSOLE_MANAGER('profile_geocode','ACTIVITY');
	var lat = $("#map_key_lat").val();
	var lon = $("#map_key_lon").val();
	var address = null;
    var $tempadd=null;
	var $TEMP_OBJ = {
        'neighborhood':'unregistered',
        'country':'unregistered', 
        'state':'unregistered',  
        'city':'unregistered', 
        'postal':'unregistered',  
        'address1':'unregistered',  
        'address2':'unregistered',
        'address3':'unregistered',
        'latitude':parseInt(0.0),  
        'longitude':parseInt(0.0), 
    };
	var componentForm = {
      street_number: 'long_name',
      route: 'long_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
	
  	$.ajax({
		url: 'https://maps.googleapis.com/maps/api/geocode/json',
    	method: 'GET',
    	data: {
    		'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
			'latlng': lat+','+lon,
    	},
    	dataType: 'json',
    	statusCode: {
      		200: function(response){
				var $status = response.status;
				if($status.indexOf("OK") > -1){	
					for (var i = 0; i < response.results[0].address_components.length; i++) {
						var addressType = response.results[0].address_components[i].types[0];
						if (componentForm[addressType]) {
							var $tempadd = response.results[0].address_components[i][componentForm[addressType]];
                            CONSOLE_MANAGER('profile_geocode','response.results[0].addressType: ' + addressType+': '+$tempadd);
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = $tempadd;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = $tempadd;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = $tempadd;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = $tempadd;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = $tempadd;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = $tempadd;
							}
						}
					}
                    CONSOLE_MANAGER('profile_geocode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
 
					if($("#matrix_form_lon")){
						$( "#matrix_form_address" ).val(response.results[0]['formatted_address']);
						$( "#matrix_form_city" ).val($TEMP_OBJ['city']);
						$( "#matrix_form_state" ).val($TEMP_OBJ['state']);
						$( "#matrix_form_country" ).val($TEMP_OBJ['country']);
						$( "#matrix_form_lat" ).val(response.results[0]['geometry'].location.lat);
						$( "#matrix_form_lon" ).val(response.results[0]['geometry'].location.lng);
						$( "#matrix_form_postal" ).val($TEMP_OBJ['postal']);
					}
				}
			},
			402: function(){
                CONSOLE_MANAGER('profile_geocode','hit free-trial daily limit');
			}
    	}
  	});
}
export function virtualcode($MAP){
    CONSOLE_MANAGER('virtualcode','ACTIVITY');
	var lat = $("#map_key_lat").val();
	var lon = $("#map_key_lon").val();
	var address = null;
	var $tempadd = null;
	var $TEMP_OBJ = {
        'neighborhood':'unregistered',
        'country':'unregistered', 
        'state':'unregistered',  
        'city':'unregistered', 
        'postal':'unregistered',  
        'address1':'unregistered',  
        'address2':'unregistered',
        'address3':'unregistered',
        'latitude':parseInt(0.0),  
        'longitude':parseInt(0.0), 
    };
	var componentForm = {
      street_number: 'long_name',
      route: 'long_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };

  	$.ajax({
		url: 'https://maps.googleapis.com/maps/api/geocode/json',
    	method: 'GET',
    	data: {
    		'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
			'latlng': lat+','+lon,
    	},
    	dataType: 'json',
    	statusCode: {
      		200: function(response){
				var $status = response.status;
				if($status.indexOf("OK") > -1){	
					for (var i = 0; i < response.results[0].address_components.length; i++) {
						var addressType = response.results[0].address_components[i].types[0];
						if (componentForm[addressType]) {
							var $tempadd = response.results[0].address_components[i][componentForm[addressType]];
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = $tempadd;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = $tempadd;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = $tempadd;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = $tempadd;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = $tempadd;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = $tempadd;
							}
                            CONSOLE_MANAGER('virtualcode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
						}
					}
					$("#event_creator_environmentOption").val('VIRTUAL');
					$( "#event_creator_city" ).val($TEMP_OBJ['city']);
					$( "#event_creator_state" ).val($TEMP_OBJ['state']);
					$( "#event_creator_country" ).val($TEMP_OBJ['country']);
                    //$( "#event_creator_lat" ).val(response.results[0]['geometry'].location.lat);
					//$( "#event_creator_lon" ).val(response.results[0]['geometry'].location.lng);
					//$( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
				}
			},
			402: function(){
                CONSOLE_MANAGER('virtualcode','hit free-trial daily limit');
			}
    	}
  	});
	
	setTimeout(
  	function($EVENT) 
  	{
	$.ajax({
		url: 'https://maps.googleapis.com/maps/api/geocode/json',
    	method: 'GET',
    	data: {
    		'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
			'address': $( "#event_creator_city" ).val(),
    	},
    	dataType: 'json',
    	statusCode: {
      		200: function(response){  // success	status
				var $status = response.status;
				if($status.indexOf("OK") > -1){	
					for (var i = 0; i < response.results[0].address_components.length; i++) {
						var addressType = response.results[0].address_components[i].types[0];
						if (componentForm[addressType]) {
							var $tempadd = response.results[0].address_components[i][componentForm[addressType]];
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = $tempadd;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = $tempadd;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = $tempadd;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = $tempadd;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = $tempadd;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = $tempadd;
							}
                            CONSOLE_MANAGER('virtualcode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
						}
					}
					$("#event_creator_environmentOption").val('VIRTUAL');
					$( "#event_creator_address" ).val('Virtual location || '+$TEMP_OBJ['city']);
					$( "#event_creator_city" ).val($TEMP_OBJ['city']);
					$( "#event_creator_state" ).val($TEMP_OBJ['state']);
					$( "#event_creator_country" ).val($TEMP_OBJ['country']);
					$( "#event_creator_lat" ).val(response.results[0]['geometry'].location.lat);
					$( "#event_creator_lon" ).val(response.results[0]['geometry'].location.lng);
					//$( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
					address_update($EVENT);
					window_complete_script('virtualcode');  
				}
			},
			402: function(){
				CONSOLE_MANAGER('virtualcode','hit free-trial daily limit');
				window_complete_script('virtualcode');  
			}
    	}
  	});
	}, 3000);
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function geocode($MAP){
	CONSOLE_MANAGER('geocode','ACTIVATED');
	var lat = $("#map_key_lat").val();
	var lon = $("#map_key_lon").val();
	var address = null;
	var $tempadd = null;
	var $TEMP_OBJ = {
        'neighborhood':'unregistered',
        'country':'unregistered', 
        'state':'unregistered',  
        'city':'unregistered', 
        'postal':'unregistered',  
        'address1':'unregistered',  
        'address2':'unregistered',
        'address3':'unregistered',
        'latitude':parseInt(0.0),  
        'longitude':parseInt(0.0), 
    };
	var componentForm = {
      street_number: 'long_name',
      route: 'long_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
	
  	$.ajax({
		url: 'https://maps.googleapis.com/maps/api/geocode/json',
    	method: 'GET',
    	data: {
    		'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
			'latlng': lat+','+lon,
    	},
    	dataType: 'json',
    	statusCode: {
      		200: function(response){
				var $status = response.status;
				if($status.indexOf("OK") > -1){	
					for (var i = 0; i < response.results[0].address_components.length; i++) {
						var addressType = response.results[0].address_components[i].types[0];
						if (componentForm[addressType]) {
							var $tempadd = response.results[0].address_components[i][componentForm[addressType]];
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = $tempadd;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = $tempadd;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = $tempadd;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = $tempadd;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = $tempadd;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = $tempadd;
							}
						}
					}
                    CONSOLE_MANAGER('geocode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
				}
				$( "#event_creator_environmentOption" ).val('PHYSICAL');
				$( "#event_creator_address" ).val(response.results[0]['formatted_address']);
				$( "#event_creator_city" ).html($TEMP_OBJ['city']);
                $( "#event_creator_state" ).html($TEMP_OBJ['state']);
                $( "#event_creator_country" ).html($TEMP_OBJ['country']);
                $( "#event_creator_city" ).val($TEMP_OBJ['city']);
                $( "#event_creator_state" ).val($TEMP_OBJ['state']);
                $( "#event_creator_country" ).val($TEMP_OBJ['country']);
				$( "#event_creator_lat" ).val(response.results[0]['geometry'].location.lat);
				$( "#event_creator_lon" ).val(response.results[0]['geometry'].location.lng);
                $( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
				address_update(response);
				window_complete_script('geocode');
			},
			402: function(){
                CONSOLE_MANAGER('meta_geocode','hit free-trial daily limit');
				window_complete_script('geocode');
			}
    	}
  	});
	return true
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function meta_geocode($MAP){
	CONSOLE_MANAGER('meta_geocode','ACTIVATED');
	var lat = $('#image_creator_imageExifLatitude').val();
	var lon = $('#image_creator_imageExifLongitude').val();
	var address = null;
	var $tempadd = null;
	var $TEMP_OBJ = {
        'neighborhood':'unregistered',
        'country':'unregistered', 
        'state':'unregistered',  
        'city':'unregistered', 
        'postal':'unregistered',  
        'address1':'unregistered',  
        'address2':'unregistered',
        'address3':'unregistered',
        'latitude':parseInt(0.0),  
        'longitude':parseInt(0.0), 
    };
	var componentForm = {
      street_number: 'long_name',
      route: 'long_name',
      neighborhood: 'long_name',
      sublocality_level_1: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      administrative_area_level_2: 'long_name',
      country: 'long_name',
      postal_code: 'short_name'
    };
	
  	$.ajax({
		url: 'https://maps.googleapis.com/maps/api/geocode/json',
    	method: 'GET',
    	data: {
    		'key': 'AIzaSyDPf1ponAbf4OM6KpDqJ1JE69M48NiM3D8',
			'latlng': lat+','+lon,
    	},
    	dataType: 'json',
    	statusCode: {
      		200: function(response){
				var $status = response.status;
				if($status.indexOf("OK") > -1){	
					for (var i = 0; i < response.results[0].address_components.length; i++) {
						var addressType = response.results[0].address_components[i].types[0];
						if (componentForm[addressType]) {
							var val = response.results[0].address_components[i][componentForm[addressType]];
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = val;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = val;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = val;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = val;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = val;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = val;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = val;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = val;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = val;
							}
						}
					}
				}
				if($("#image_creator_address" ).val()){
					   
				
				}else{
					if($( "#image_creator_address" )){
						$tempadd = $( "#map_key_search_parameters" ).val().split(',')[0];
                        CONSOLE_MANAGER('geocode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);

						$( "#image_creator_address" ).val($tempadd);
						var currval = document.getElementById("settings_activity_type2_activityStatus").value;
						if(currval==1){
							$( "#image_creator_address2" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);//+' '+$TEMP_OBJ['address3']
							$( "#image_creator_city" ).html(null);//$TEMP_OBJ['city']
							$( "#image_creator_state" ).html($TEMP_OBJ['state']);
							$( "#image_creator_country" ).html($TEMP_OBJ['country']);
							$( "#image_creator_city" ).val(null);//$TEMP_OBJ['city']
							$( "#image_creator_state" ).val($TEMP_OBJ['state']);
							$( "#image_creator_country" ).val($TEMP_OBJ['country']);
							$( "#image_creator_imageExifLatitude" ).val($TEMP_OBJ['latitude']);
							$( "#image_creator_imageExifLongitude" ).val($TEMP_OBJ['longitude']);
							//$( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
						}
					}
					address_update(response);
					window_complete_script('meta_geocode');
				}
			},
			402: function(){
                CONSOLE_MANAGER('meta_geocode','hit free-trial daily limit');
				window_complete_script('meta_geocode');
			}
    	}
  	});
	return true
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function MGMT_google_autocomplete($INPUT){
    CONSOLE_MANAGER('MGMT_google_autocomplete','ACTIVATED: '+$INPUT);

    var $tempadd = null;
    var $TEMP_OBJ = {
        'neighborhood':'unregistered',
        'country':'unregistered', 
        'state':'unregistered',  
        'city':'unregistered', 
        'postal':'unregistered',  
        'address1':'unregistered',  
        'address2':'unregistered',
        'address3':'unregistered',
        'latitude':parseInt(0.0),  
        'longitude':parseInt(0.0), 
    };
    /* --------------------------------------------------------  */
	let map;
	let $PLACES;
	async function initAutocomplete() {
  		const { Map } = await google.maps.importLibrary("maps");
		const { $PLACES } = await google.maps.importLibrary("places");
		/* --------------------------------------------------------  */
		try {
			var autocomplete = new google.maps.places.Autocomplete(document.getElementById($INPUT));
			//var autocomplete = new $PLACES.places.Autocomplete(document.getElementById($INPUT));
			//var autocomplete = new client.maps(input);
			// Bind the map's bounds (viewport) property to the autocomplete object,
			// so that the autocomplete requests use the current map bounds for the
			// bounds option in the request.
			//autocomplete.bindTo('bounds', map);
			autocomplete.addListener('place_changed', function($EVENT) {
				CONSOLE_MANAGER('MGMT_google_autocomplete','LOCATED');
				var place = autocomplete.getPlace();
				if (!place.geometry) {
				  // User entered the name of a Place that was not suggested and
				  // pressed the Enter key, or the Place Details request failed.
				  //window.alert("No details available for input: '" + place.name + "'");
				  return;
				}
				// If the place has a geometry, then present it on a map.
				if (place.geometry.viewport) {
				  //map.fitBounds(place.geometry.viewport);
				}else {

				}
				CONSOLE_MANAGER('MGMT_google_autocomplete','place.geometry.location: ' + place.geometry.location+' - '+place.geometry.viewport);
				var componentForm = {
					street_number: 'long_name',
					route: 'long_name',
					neighborhood: 'long_name',
					sublocality_level_1: 'long_name',
					locality: 'long_name',
					administrative_area_level_1: 'long_name',
					administrative_area_level_2: 'long_name',
					country: 'long_name',
					postal_code: 'short_name'
				  };
				/* --------------------------------------------------------  */
				var address = '';
				if (place.address_components) {
					address = [
						(place.address_components[0] && place.address_components[0].long_name || ''),
						(place.address_components[1] && place.address_components[1].long_name || ''),
						(place.address_components[2] && place.address_components[2].long_name || '')
					].join(' ');

					$TEMP_OBJ['latitude'] = place.geometry.location.lat();
					$TEMP_OBJ['longitude'] = place.geometry.location.lng();

					for (var i = 0; i < place.address_components.length; i++) {
						var addressType = place.address_components[i].types[0];
						if (componentForm[addressType]) {
							var $tempadd = place.address_components[i][componentForm[addressType]];
							if(addressType == 'neighborhood'){
								$TEMP_OBJ['neighborhood'] = $tempadd;
							}
							if(addressType == 'street_number'){
								$TEMP_OBJ['address1'] = $tempadd;
							}
							if(addressType == 'route'){
								$TEMP_OBJ['address2'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['address3'] = $tempadd;
							}
							if(addressType == 'country'){
								$TEMP_OBJ['country'] = $tempadd;
							}
							if(addressType == 'administrative_area_level_1'){
								$TEMP_OBJ['state'] = $tempadd;
							}
							if(addressType == 'locality'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'sublocality_level_1'){
								$TEMP_OBJ['city'] = $tempadd;
							}
							if(addressType == 'postal_code'){
								$TEMP_OBJ['postal'] = $tempadd;
							}
						}
					}
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					CONSOLE_MANAGER('meta_geocode','RESPONSE: '+"\n"+'      FULL : ' + $tempadd+"\n"+'      address1 : ' + $TEMP_OBJ['address1']+"\n"+'      address2: ' + $TEMP_OBJ['address2']+"\n"+'      neighborhood: ' + $TEMP_OBJ['neighborhood']+"\n"+'      country: ' + $TEMP_OBJ['country']+"\n"+'      state: ' + $TEMP_OBJ['state']+"\n"+'      city: ' + $TEMP_OBJ['city']+"\n"+'      latitude: ' + $TEMP_OBJ['latitude']+"\n"+'      longitude: ' + $TEMP_OBJ['longitude']+"\n"+'      postal: ' + $TEMP_OBJ['postal']);
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					if(($INPUT.indexOf($( "#business_registration_address").attr('id')) > -1)){
						CONSOLE_MANAGER('business_registration_address','ACTIVATED');
						$( "#business_registration_address2" ).val('('+$( "#business_registration_address" ).val().split(',')[0]+')');
						if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']!='unregistered')){
						   $( "#business_registration_address" ).val($TEMP_OBJ['neighborhood']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#business_registration_address" ).val($TEMP_OBJ['city']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#business_registration_address" ).val($TEMP_OBJ['address2']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#business_registration_address" ).val($TEMP_OBJ['address1']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#business_registration_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}else{
							$( "#business_registration_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}
						$( "#business_registration_city" ).val($TEMP_OBJ['city']);
						$( "#business_registration_state" ).val($TEMP_OBJ['state']);
						$( "#business_registration_country" ).val($TEMP_OBJ['country']);
						$( "#business_registration_lat" ).val($TEMP_OBJ['latitude']);
						$( "#business_registration_lon" ).val($TEMP_OBJ['longitude']);
						$( "#business_registration_postal" ).val($TEMP_OBJ['postal']);
						$( "#business_registration_formFilter5" ).val(true);
					}
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					else if(($INPUT.indexOf($( "#location_search_request_search_parameters").attr('id')) > -1)){
						CONSOLE_MANAGER('location_search_request_search_parameters','ACTIVATED');
						$( "#location_search_request_address2" ).val('('+$( "#location_search_request_address" ).val().split(',')[0]+')');
						if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']!='unregistered')){
							$( "#location_search_request_address" ).val($TEMP_OBJ['neighborhood']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#location_search_request_address" ).val($TEMP_OBJ['city']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#location_search_request_address" ).val($TEMP_OBJ['address2']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#location_search_request_address" ).val($TEMP_OBJ['address1']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#location_search_request_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}else{
							$( "#location_search_request_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}
						$( "#location_search_request_city" ).val($TEMP_OBJ['city']);
						$( "#location_search_request_state" ).val($TEMP_OBJ['state']);
						$( "#location_search_request_country" ).val($TEMP_OBJ['country']);
						$( "#location_search_request_lat" ).val($TEMP_OBJ['latitude']);
						$( "#location_search_request_lon" ).val($TEMP_OBJ['longitude']);
					}
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					else if(($INPUT.indexOf($( "#settings_field_edit_address").attr('id')) > -1)){
						CONSOLE_MANAGER('settings_field_edit_address','ACTIVATED');
						$( "#settings_field_edit_address2" ).val('('+$( "#settings_field_edit_address" ).val().split(',')[0]+')');
						if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']!='unregistered')){
						   $( "#settings_field_edit_address" ).val($TEMP_OBJ['neighborhood']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#settings_field_edit_address" ).val($TEMP_OBJ['city']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#settings_field_edit_address" ).val($TEMP_OBJ['address2']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#settings_field_edit_address" ).val($TEMP_OBJ['address1']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#settings_field_edit_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}else{
							$( "#settings_field_edit_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}
						$( "#settings_field_edit_formCurrentIndex" ).val($( "#settings_field_edit_control" ).val());
						$( "#settings_field_edit_index1" ).val($TEMP_OBJ['city']);
						$( "#settings_field_edit_index2" ).val($TEMP_OBJ['state']);
						$( "#settings_field_edit_index3" ).val($TEMP_OBJ['country']);
						$( "#settings_field_edit_city" ).html($TEMP_OBJ['city']);
						$( "#settings_field_edit_state" ).html($TEMP_OBJ['state']);
						$( "#settings_field_edit_country" ).html($TEMP_OBJ['country']);
						$( "#settings_field_edit_city" ).val($TEMP_OBJ['city']);
						$( "#settings_field_edit_state" ).val($TEMP_OBJ['state']);
						$( "#settings_field_edit_country" ).val($TEMP_OBJ['country']);
						$( "#settings_field_edit_lat" ).val($TEMP_OBJ['latitude']);
						$( "#settings_field_edit_lon" ).val($TEMP_OBJ['longitude']);
						$( "#settings_field_edit_postal" ).val($TEMP_OBJ['postal']);
					}
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					else if(($INPUT.indexOf($( "#event_creator_address").attr('id')) > -1)){
						CONSOLE_MANAGER('event_creator_address','ACTIVATED');
						$( "#event_creator_address" ).val('('+$( "#event_creator_address" ).val().split(',')[0]+')');
						if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']!='unregistered')){
						   $( "#event_creator_address" ).val($TEMP_OBJ['neighborhood']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#event_creator_address" ).val($TEMP_OBJ['city']);
						}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#event_creator_address" ).val($TEMP_OBJ['address2']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#event_creator_address" ).val($TEMP_OBJ['address1']);
						}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
							$( "#event_creator_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}else{
							$( "#event_creator_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
						}
						$( "#event_creator_environmentOption" ).val('PHYSICAL');
						$( "#event_creator_city" ).html($TEMP_OBJ['city']);
						$( "#event_creator_state" ).html($TEMP_OBJ['state']);
						$( "#event_creator_country" ).html($TEMP_OBJ['country']);
						$( "#event_creator_city" ).val($TEMP_OBJ['city']);
						$( "#event_creator_state" ).val($TEMP_OBJ['state']);
						$( "#event_creator_country" ).val($TEMP_OBJ['country']);
						$( "#event_creator_lat" ).val($TEMP_OBJ['latitude']);
						$( "#event_creator_lon" ).val($TEMP_OBJ['longitude']);
						$( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
						address_update($EVENT);
					}
					/* --------------------------------------------------------  */
					/* --------------------------------------------------------  */
					else if(($INPUT.indexOf($( "#map_key_search_parameters").attr('id')) > -1)){
						CONSOLE_MANAGER('map_key_search_parameters','ACTIVATED');
						/* --------------------------------------------------------image_meta_map_activate  */
						if($(".Options_create_Activity_UI_class").length){//.is(':visible')
							var $tempadd = $( "#map_key_search_parameters" ).val().split(',')[0];
							$( "#image_creator_address" ).val($tempadd);
							$( "#image_creator_address" ).html($tempadd);
							$TEMP_OBJ['address1'] = ($TEMP_OBJ['address1'] == "unregistered") ? " " : $TEMP_OBJ['address1'];
							$TEMP_OBJ['address2'] = ($TEMP_OBJ['address2'] == "unregistered") ? " " : $TEMP_OBJ['address2'];
							$TEMP_OBJ['city'] = ($TEMP_OBJ['city'] == "unregistered") ? " " :$TEMP_OBJ['city'];
							$TEMP_OBJ['state'] = ($TEMP_OBJ['state'] == "unregistered") ? " " : $TEMP_OBJ['state'];
							$TEMP_OBJ['country'] = ($TEMP_OBJ['country'] == "unregistered") ? " " : $TEMP_OBJ['country'] ;
							if($( "#image_creator_address").length){
								$( "#image_creator_address2" ).html($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);	
								$( "#image_creator_city" ).html($TEMP_OBJ['city']);
								$( "#image_creator_state" ).html($TEMP_OBJ['state']);
								$( "#image_creator_country" ).html($TEMP_OBJ['country']);
								$( "#image_creator_address2" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
								$( "#image_creator_city" ).val($TEMP_OBJ['city']);
								$( "#image_creator_state" ).val($TEMP_OBJ['state']);
								$( "#image_creator_country" ).val($TEMP_OBJ['country']);
								$( "#image_creator_imageExifLatitude" ).val($TEMP_OBJ['latitude']);
								$( "#image_creator_imageExifLongitude" ).val($TEMP_OBJ['longitude']);
								address_update($EVENT);
							}
						}
						if($(".Listview_options_Activity_UI_class").is(':visible') && !$("#event_creator_address")){// var $clear_val= 
						   $("#event_locations_menu_lat").val($TEMP_OBJ['latitude']);
						   $("#event_locations_menu_lon").val($TEMP_OBJ['longitude']);
						   Temp_CenterMap(Number($('#event_locations_menu_lon').val()), Number($('#event_locations_menu_lat').val()), parseInt(12), $('#map').data(),true);
						}
						if($("#event_creator_address")){
							CONSOLE_MANAGER('event_creator_address','ACTIVATED');
							var $tempadd = $( "#map_key_search_parameters" ).val();//.split(',')[0];
							$( "#event_creator_options15" ).val($tempadd);
							$( "#event_creator_options15" ).html($tempadd);
							$( "#event_creator_address" ).val($tempadd)
							//$( "#event_creator_address" ).val('('+$( "#map_key_search_parameters" ).val().split(',')[0]+')');
							//$( "#event_creator_address" ).val('('+$( "#event_creator_address" ).val().split(',')[0]+')');
							if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']!='unregistered')){
							   $( "#event_creator_address" ).val($TEMP_OBJ['neighborhood']);
							}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
								$( "#event_creator_address" ).val($TEMP_OBJ['city']);
							}else if(($TEMP_OBJ['address1']=='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
								$( "#event_creator_address" ).val($TEMP_OBJ['address2']);
							}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']=='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
								$( "#event_creator_address" ).val($TEMP_OBJ['address1']);
							}else if(($TEMP_OBJ['address1']!='unregistered')&&($TEMP_OBJ['address2']!='unregistered')&&($TEMP_OBJ['neighborhood']=='unregistered')){
								$( "#event_creator_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
							}else{
								$( "#event_creator_address" ).val($TEMP_OBJ['address1']+' '+$TEMP_OBJ['address2']);
							}
							//$( "#map_key_search_parameters" ).val($( "#event_creator_address" ).val());

							$( "#event_creator_environmentOption" ).val('PHYSICAL');
							$( "#event_creator_city" ).html($TEMP_OBJ['city']);
							$( "#event_creator_state" ).html($TEMP_OBJ['state']);
							$( "#event_creator_country" ).html($TEMP_OBJ['country']);
							$( "#event_creator_city" ).val($TEMP_OBJ['city']);
							$( "#event_creator_state" ).val($TEMP_OBJ['state']);
							$( "#event_creator_country" ).val($TEMP_OBJ['country']);
							$( "#event_creator_lat" ).val($TEMP_OBJ['latitude']);
							$( "#event_creator_lon" ).val($TEMP_OBJ['longitude']);
							$( "#event_creator_postal" ).val($TEMP_OBJ['postal']);
							address_update($EVENT);
						}
					}
				}
				/* --------------------------------------------------------  */
			});
		}
		catch(err) {
			CONSOLE_MANAGER('MGMT_google_autocomplete','CONNECTION ERROR: '+JSON.stringify(err));
			CONSOLE_MANAGER('MGMT_google_autocomplete','CONNECTION ERROR: '+err);
		}
	}
	initAutocomplete();
    /* ------------------------------------------------- */
    /* ------------------------------------------------- */
	return true
}
/* ------------------------------------------------- */	
/* ------------------------------------------------- */
export function item_map($SETCOLOR,$SETADDRESS,$SETCITY,$SETSTATE,$SETCOUNTRY,$SETLAT,$SETLONG,$SETACTIVE){
    CONSOLE_MANAGER('item_map','ACIVATED');
	if($('#map').length){
		windowload_script('item_map');
		var vectorSource = new VectorSource({});
		/* ------------------------------------------------- */	
		/* ------------------------------------------------- */	
		var vectorGroup = new LayerGroup({
			source: vectorSource,
			name: 'vector Group'
		}); 
		
		var vectorLayerRegisterDates = new VectorLayer({
			source: initiatItemPins(),			
		});
		/* ------------------------------------------------- */	
		/* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
		rasterLayer.on(["precompose", "prerender"], function(event) {

		});
		rasterLayer.on(["postcompose", "postrender"], function(event) {

		});
		/* ------------------------------------------------- */
		vectorGroup.once("change", function(evt) {
			/* --------------------------------------------------------  */
			var size =0;
			var extent = olExtent.createEmpty();
			evt.preventDefault();
			vectorGroup.getLayers().forEach(function(layer) {
				features = layer.getSource().getFeatures()
				var size = features.length
				if(size>0){
					olExtent.extend(extent, layer.getSource().getExtent());
					var layerlistenerKey = layer.getSource().on("change", function(evt) {
						var source = evt.target;
						if (source.getState() === 'undefined') {
                            CONSOLE_MANAGER('item_map','layer undefined');
						}
						else if (source.getState() === 'loading') {
                            CONSOLE_MANAGER('item_map','layer loading');
						}
						else if (source.getState() === 'ready') {
							Observable.unByKey(layerlistenerKey);
						}
						else if (source.getState() === 'error') {
                            CONSOLE_MANAGER('item_map','layer error');
						}
					});
				}
			});
			/* --------------------------------------------------------  */
			/**	ZOOM AND PAN ACTION	***/	//duration: 1500,duration: 1500,
            //pan = ol.animation.pan({duration: 100, source: map.getView().getCenter()});
            //zoom = ol.animation.zoom({duration: 100, resolution: map.getView().getResolution()});
			//map.beforeRender(pan, zoom);
			if(size>0){  
                //map.beforeRender(pan, zoom);
				///map.beforeRender(zoom);//pan, 
                //map.getView().fit(extent, map.getSize());
			}
		});
        /* -------------------------------------------------------- */
        var x = document.getElementById("map");
        if (x.style.display === "none") {               
           /* -------------------------------------------------------- */
            var map = new Map({
                layers: [
                    rasterLayer,
                    new LayerGroup({
                      layers: [
                        vectorGroup,
                        vectorLayerRegisterDates
                      ]
                    })
                ],//vectorLayerRegisterDates	vectorGroup_multif
                controls: defaultControls({
                    zoom: false,
                    attribution: false,
                    rotate: false,
                }),
                /*
                ,vectorLayer2,vectorj, vectorLayery,vectorLayer
                TileJSON
                */
                /*
                interactions: defaultInteractions().extend([new ol.interaction.Select({
                    condition: function(evt) {
                        return evt.originalEvent.type == 'mousemove' ||
                        evt.type == 'singleclick';
                    },
                    style: selectStyleFunction
                })]),

                transform([$('#set_long').val(), $('#set_lat').val()], 'EPSG:4326', 'EPSG:3857')
                */
                target: 'map',
                view: new View({
                    center:[0,0],
                    zoom: 3,
                    //resolution: $RESOLUTION,
                    minZoom: 3,
                    maxZoom: 18
                })
            });
            /* -------------------------------------------------------- */
            x.style.display = "block";
            map.updateSize();
            /* -------------------------------------------------------- */
            if($('#map')){
               $('#map').data('map', map);
            }
            /* -------------------------------------------------------- */
            SET_STADIA_ATTRIBUTION($('#map').attr('id'));
            /* -------------------------------------------------------- */
            var $INITAL_RENDER_CONTROL = false;
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            map.on('rendercomplete', e => {

                /* -------------------------------------------------------- */
            })
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            var restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
            map.on('postrender', function() {
                var view = map.getView();
                var zoom = view.getZoom();
                var worldExtent = view.getProjection().getExtent();
                var extent = view.calculateExtent(map.getSize());
                if (!olExtent.containsExtent(worldExtent, extent)) {
                    var newExtent = olExtent.getIntersection(extent, restrictedExtent);
                    view.fit(newExtent, map.getSize());
                }
                /* -------------------------------------------------------- */
                if(($INITAL_RENDER_CONTROL==false)){
                    CONSOLE_MANAGER('item_map','$INITAL_RENDER_CONTROL = FALSE');
                    setTimeout(SET_GEOLOCATION(), 1000);
                    map_key_activate(map,'item_map - MAPPING:item_map',parseInt(0));
                    //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                    window_complete_script('item_map');
                    $INITAL_RENDER_CONTROL = true;
                }
            });
            /* -------------------------------------------------------- */
            var projection = getProjection('EPSG:3857');
            var world_extent = projection.getWorldExtent();
            console.info('world extent lon1'+world_extent); 
            /* -------------------------------------------------------- */
              /*
              ol.style.Style.
              $SETCOLOR
              $SETADDRESS
              $SETCITY
              $SETSTATE
              $SETCOUNTRY
              $SETLAT
              $SETLONG
              pulse
              */
            var $GET_COORDS = setTimeout(SET_GEOLOCATION(), 1000);
            setTimeout(function(){
                /*
                  if(($GET_COORDS[0]=='undefined')||($GET_COORDS[1]=='undefined') || ($GET_COORDS[0]==null)||($GET_COORDS[1]==null)){
                      var iconFeature = new Feature({      
                          geometry: new Point(transform([$('#location_validation_lon').val(),$('#location_validation_lat').val()], 'EPSG:4326', 'EPSG:3857')),
                          name: 'NULL'
                      });
                      var iconStyle = new Stroke({
                          image: new CircleStyle({
                              anchor: [$('#location_validation_lon').val(), $('#location_validation_lat').val()],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              radius: 8,
                              stroke: new Stroke({
                                  color: 'rgba(239,239,239, 0.7)',
                                  width: 3,
                                  //lineCap: 'round' ,
                                  lineJoin: 'bevel' ,
                              }),
                              fill: new Fill({
                                  color: $SETCOLOR
                              })
                          }),
                      });

                      iconFeature.setStyle(iconStyle);

                      var vectorSource = new VectorSource({
                          features: [iconFeature]
                      });
                      var vectorLayer = new VectorLayer({
                          source: vectorSource
                      });
                      map.addLayer(vectorLayer);
                      CenterMap($('#location_validation_lon').val(), $('#location_validation_lat').val(),12)
                  }
            */
                if($SETACTIVE==false){//($SETLAT==0 || $SETLAT==0.0) && (($SETLONG==0 || $SETLONG==0.0))
                    /*
                    if(($SETLAT==0)||($SETLAT==0.0)){
                       $SETLAT=$('#location_validation_lat').val()
                    }
                    if(($SETLONG==0)||($SETLONG==0.0)){
                       $SETLONG=$('#location_validation_lon').val()
                    }




                    var iconFeature = new Feature({      
                        geometry: new Point(transform([0,0], 'EPSG:4326', 'EPSG:3857')),
                        name: 'NULL'
                    });
                    iconFeature.setStyle(new Style({
                        image: new CircleStyle({
                            radius: 8,
                            stroke: new Stroke({
                                color: 'rgba(255,255,255, 0.0)',
                                width: 3,
                                //lineCap: 'round' 
                                lineJoin: 'bevel',
                            }),
                            fill: new Fill({
                                color: 'rgba(255,255,255, 0.0)',
                            })
                        })
                    }));

                    var vectorSource = new VectorSource({
                        features: [iconFeature]
                    });
                    var vectorLayer = new VectorLayer({
                        source: vectorSource
                    });
                    map.addLayer(vectorLayer);
                    */
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    CenterMap($('#map_key_lon').val(),$('#map_key_lat').val(),12)
                }/*END IF FUNCTION */
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                else if(($SETLAT) && ($SETLONG)){
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    /* SELECTED ITEM */
                    var iconFeature = new Feature({      
                        geometry: new Point(transform([$SETLONG,$SETLAT], 'EPSG:4326', 'EPSG:3857')),
                        name: 'NULL'
                    });
                    iconFeature.setStyle(new Style({
                        image: new CircleStyle({
                            radius: 8,
                            stroke: new Stroke({
                                color: 'rgba(239,239,239, 0.7)',
                                width: 3,
                                //lineCap: 'round' /* square */,
                                lineJoin: 'bevel' /* round */,
                            }),
                            fill: new Fill({
                                color: $SETCOLOR
                            })
                        })
                    }));

                    var vectorSource = new VectorSource({
                        //features: [iconFeature]
                    });
                    var vectorLayer = new VectorLayer({
                        source: vectorSource
                    });
                    map.addLayer(vectorLayer);
                    /* ------------------------------------------------- */
                    const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
                    var interval = window.setInterval(function(){
                        flash(iconFeature);
                    },3000);
                    vectorSource.on('addfeature', function(e) {
                        flash(e.feature);
                    });
                    vectorSource.addFeature(iconFeature);
                    /* ------------------------------------------------- */
                    function StopFunction() {
                      clearInterval(interval);
                    }
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    CenterMap($SETLONG,$SETLAT,12)
                }/*END IF FUNCTION */
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                //SET_BUTTON_CLICK_ACTION("location_search_request_options3");
                $('#location_search_request_options3').unbind().click(function(event) {
                    CenterMap($SETLONG,$SETLAT,12)
                });
            }, 3000)
        } else {
            x.style.display = "none";
        }
		/* ------------------------------------------------- */
        function CenterMap(long, lat, $zoom) {
            map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
            if($zoom == null){
                $zoom =3;
            }
            map.getView().setZoom($zoom);
        }/*END FUNCTION */
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
		function AddFeature(long, lat) {
			var iconFeature = new Feature({      
                geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
                //name: 'NULL'
            });
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 8,
                    stroke: new Stroke({
                        color: 'rgba(239,45,239, 0.7)',
                        width: 3,
                        //lineCap: 'round' /* square */,
                        lineJoin: 'bevel' /* round */,
                    }),
                    fill: new Fill({
                        color: 'rgba(47,45,239, 0.7)'
                    })
                })
            }));
			//tempSource.addFeature(iconFeature);
			var tempSource = new VectorSource({
				features: [iconFeature]
			});
            var tempLayer = new VectorLayer({
				 source: tempSource
			 });
            map.addLayer(tempLayer);
			$( "#location_search_request_search_parameters").on('change', function(){
				if(tempLayer.getSource()){
					tempLayer.getSource().removeFeature(iconFeature)
				}
			});
        }/*END FUNCTION */
        /* --------------------------------------------------------  */
		if(document.getElementById("location_search_request_lat")){
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('location_search_request_lat');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							if($("#location_search_request_lat").val()){  
								CONSOLE_MANAGER('item_map','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									CONSOLE_MANAGER('item_map',mutation.type+': attributes');
									CenterMap($("#location_search_request_lon").val(),$("#location_search_request_lat").val(),12);
									AddFeature($("#location_search_request_lon").val(),$("#location_search_request_lat").val());
									/* -------------------------------------------------------- */
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('item_map',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		}
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function map_key_activate($MAP,$REF,$COUNTVAR){
    CONSOLE_MANAGER('map_key_activate','ACTIVATED');
	var zoom;
	/* --------------------------------------------------------  */
	$('#map_key_options1').unbind().click(function(event) {
        if($('#map_key_lon').val()!=0){//&& $('#map_key_lon').val()!=0.0
            Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), $MAP.getView().getZoom(), $MAP, false);
        }else{
            //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), $MAP.getView().getZoom(), $MAP, false);
            setTimeout(SET_GEOLOCATION(), 1000);
        }
		
	})
	/* --------------------------------------------------------  */
	$('#map_key_options2').unbind().click(function(event) {
		zoom = $MAP.getView().getZoom();
		$MAP.getView().setZoom(zoom+1);
	})
	/* --------------------------------------------------------  */
	$('#map_key_options3').unbind().click(function(event) {
		zoom = $MAP.getView().getZoom();
		$MAP.getView().setZoom(zoom-1);
	})
	/* --------------------------------------------------------  */
	$('#map_key_options4').unbind().click(function(event) {

	})
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	setTimeout(function(){
		var iconUser_Feature = new Feature({
			geometry: new Point(transform([Number($('#map_key_lon').val()),Number($('#map_key_lat').val())], 'EPSG:4326', 'EPSG:3857'))
		});
		/*
		iconUser_Feature.setStyle(
			new Style({
				image: new CircleStyle({
					radius: 6,
					fill: new Fill({
						color:'rgba(239,239,45, 0.7)' //'#3399CC'
					}),
					//stroke: new Stroke({
					//	color: '#fff',
					//	width: 2
					//}),
					stroke: new Stroke({
						color: 'rgba(239,25,239, 0.7)',
						width: 3
					})
				})
			})
		);
		*/
		iconUser_Feature.setStyle(new Style({
            image: new CircleStyle({
                radius: 6,
                stroke: new Stroke({
                    color: 'rgba(239,25,239, 0.7)',
                    width: 3,
                    //lineCap: 'round' /* square */,
                    lineJoin: 'bevel' /* round */,
                }),
                fill: new Fill({
                    color: 'rgba(239,239,45, 0.7)',
                })
            })
        }));
		
		var vectorUserSource = new VectorSource({
			features: [iconUser_Feature],
			//strategy: bbox,
		});
		var vectorUser = new VectorLayer({
			source: vectorUserSource,   
		 });
		//$('#map').addLayer(vectorUser)
		$MAP.addLayer(vectorUser);
	}, $COUNTVAR)
	/* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function update_map_tools(map,vectorSource){
	if($('#set_resolution').val()){
		var $RESOLUTION = Number($('#set_resolution').val());// 0
		map.getView().setResolution($RESOLUTION);
	}
	if(($('#set_long').val()) && ($('#set_lat').val())){
		var $LONGITUDE = Number($('#set_long').val());
		var $LATITUDE = Number($('#set_lat').val());
		map.getView().setCenter(transform([$LONGITUDE, $LATITUDE], 'EPSG:4326', 'EPSG:3857'))
	}
	if($('#set_zoom').val()){
		var $ZOOM = Number($('#set_zoom').val());
		map.getView().setZoom($ZOOM);
	}
	/* -------------------------------------------------------- */
	map.getView().on('propertychange', function(event) {
		switch (event.key) {
			case 'center':
				var $center = transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
				var $zoom = map.getView().getZoom();
				$('#media_pagination_options_formLat').val($center[1]);
				$('#media_pagination_options_formLon').val($center[0]);
				$('#media_pagination_options_formZoom').val($zoom);
				$('#media_pagination_options_formResolution').val(map.getView().getResolution());
			break;	
			case 'resolution':
				var $center = transform(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
				var $zoom = map.getView().getZoom();
				$('#media_pagination_options_formLat').val($center[1]);
				$('#media_pagination_options_formLon').val($center[0]);
				$('#media_pagination_options_formZoom').val($zoom);
				$('#media_pagination_options_formResolution').val(map.getView().getResolution());
			break;
	   }
	});
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function Temp_CenterMap(long, lat, $zoom, map, $CLEAR_val) {	
    CONSOLE_MANAGER('Temp_CenterMap','ACTIVATED');
    CONSOLE_MANAGER('Temp_CenterMap','$CLEAR_val: '+$CLEAR_val);
	if($zoom == null){
		$zoom =17;
	}
    if($CLEAR_val){
        if($CLEAR_val==false){
            if($('#map').length){
                var map =$('#map').data('map');
                map.getView().setZoom($zoom);
                map.getView().animate({
                    center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                    duration: 2000
                });
            }else{
                map.getView().setZoom($zoom);
                map.getView().animate({
                    center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                    duration: 2000
                });
            }
        }else{
            /* Temp_CenterMap  $CLEAR_val == TRUE  */ 
            if($('#map').length){
                var map =$('#map').data('map');
                /* ------------------------------------------------- */
                map.getLayers().getArray().forEach(function(layer){
                    if (layer.getSource().getFeatures) {
                        var features = layer.getSource().getFeatures();
                        CONSOLE_MANAGER('Temp_CenterMap','FOUND AND REMOVED FEATURES '+features);
                        map.removeLayer(layer);
                    }
                });
                /* ------------------------------------------------- */
                map.getView().setZoom($zoom);
                map.getView().animate({
                    center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                    duration: 2000
                });
            }else{
                /* ------------------------------------------------- */
                map.getLayers().getArray().forEach(function(layer){
                    if (layer.getSource().getFeatures) {
                        var features = layer.getSource().getFeatures();
                        CONSOLE_MANAGER('Temp_CenterMap','FOUND AND REMOVED FEATURES '+features);
                        map.removeLayer(layer);
                    }
                });
                /* ------------------------------------------------- */
                map.getView().setZoom($zoom);
                map.getView().animate({
                    center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                    duration: 2000
                });
            }
        }
    }else{
        if($('#map').length){
            var map =$('#map').data('map');
            map.getView().setZoom($zoom);
            map.getView().animate({
                center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                duration: 2000
            });
        }else{
            map.getView().setZoom($zoom);
            map.getView().animate({
                center: map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857')),
                duration: 2000
            });
        }
    }
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function initiatItemPins(){
    CONSOLE_MANAGER('initiatItemPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    if(document.getElementById("total_objects_count_item")){
        var Object_count = parseInt(document.getElementById("total_objects_count_item").value + 1);
        var $code = $("#index_object_item").val();
        for(var $i=1; $i<Object_count; $i++){
            if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                var $SETCITY = '#form_'+$code+'_'+$i+'_city';
                var $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                var $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                var $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                var $SETLONG = '#form_'+$code+'_'+$i+'_lon';
                //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                /* ------------------------------------------------- */
				if((!$($SELECTEDSEQUENCE).val())){
					var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                $DATES_obj_array[$i] =$COORDMgmt;
				}
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                //REGISTER_CLICK($($SEQUENCE),$COORDMgmt);
            }	
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.GET_UNIQ_ID != "undefined"){
                        if((element.GET_UNIQ_ID)  && (element.GET_ITEM_ID) && (element.LONG) && (element.LAT) && (element.COLOR) && (element.START) && (element.END)){
                            storeCoordinate(element.LONG, element.LAT, pfeatures);
                            pin_count.push(element.COLOR);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].GET_UNIQ_ID],
                        name: [$DATES_data_array[i].GET_UNIQ_ID,$DATES_data_array[i].GET_ITEM_ID],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					piconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 5,
							stroke: new Stroke({
								color: 'rgba(150,255,255, 0.7)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: $DATES_data_array[i].COLOR,
							})
						})
					}));
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatMultiItemPins(){
    CONSOLE_MANAGER('initiatMultiItemPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    if(document.getElementById("total_objects_count_item")){
        var Object_count = parseInt(document.getElementById("total_objects_count_item").value + 1);
        var $code = $("#index_object_item").val();
        for(var $i=1; $i<Object_count; $i++){
            if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                var $SETCITY = '#form_'+$code+'_'+$i+'_city';
                var $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                var $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                var $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                var $SETLONG = '#form_'+$code+'_'+$i+'_lon';
                /* ------------------------------------------------- */
                var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                $DATES_obj_array[$i] =$COORDMgmt;
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
            }	
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.GET_UNIQ_ID != "undefined"){
                        if((element.GET_UNIQ_ID)  && (element.GET_ITEM_ID) && (element.LONG) && (element.LAT) && (element.COLOR) && (element.START) && (element.END)){
                            storeCoordinate(element.LONG, element.LAT, pfeatures);
                            pin_count.push(element.COLOR);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].GET_UNIQ_ID],
                        name: [$DATES_data_array[i].GET_UNIQ_ID,$DATES_data_array[i].GET_ITEM_ID],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					piconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(150,255,255, 0.7)',
								width: 3,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: $DATES_data_array[i].COLOR,
							})
						})
					}));
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatEventItemPins(rasterLayer){
    CONSOLE_MANAGER('initiatEventItemPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    if($('#event_creator_formCoords').val()){
		$DATES_obj_array = JSON.parse($('#event_creator_formCoords').val());
		$DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
		if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.getUniqEventId != "undefined"){
                        if((element.getUniqEventId)  && (element.getRegisterCode) && (element.getLon) && (element.getLat) && (element.getColorCode) && (element.getStartDate) && (element.getEndDate) && (element.getEnvironmentOption) && (element.getEventStatus)){
                            storeCoordinate(element.getLon, element.getLat, pfeatures);
                            pin_count.push(element.getColorCode);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        lat: [$DATES_data_array[i].getLat],
                        lon: [$DATES_data_array[i].getLon],
                        id: [$DATES_data_array[i].getUniqEventId],
                        registerCode: [$DATES_data_array[i].getRegisterCode],
                        name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					var $color = $DATES_data_array[i].getColorCode;
					if($DATES_data_array[i].EVENT_STATUS =='COMPLETE'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 3,
                                stroke: new Stroke({
                                    color: 'rgba(255,0,0, 0.7)',
                                    width: 1,
                                    //lineCap: 'round' ,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    //color: 'rgba(239,239,239, 0.7)',
									color: $color,
                                })
                            })
                        }));
					}else if($DATES_data_array[i].EVENT_STATUS =='PENDING'){
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(255,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							/* --------------------------------------------------------  */
							if($( "#event_creator_formFilter1" ).val()=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(255,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							/* --------------------------------------------------------  */
							else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
								/* --------------------------------------------------------  */
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' /* square */,
											lineJoin: 'bevel' /* round */,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								/* ------------------------------------------------- */
								const duration = 3000;
                                function flash(feature) {
                                  const start = Date.now();
                                  const flashGeom = feature.getGeometry().clone();
                                  const listenerKey = rasterLayer.on('postrender', animate);

                                  function animate(event) {
                                    const frameState = event.frameState;
                                    const elapsed = frameState.time - start;
                                    if (elapsed >= duration) {
                                      unByKey(listenerKey);
                                      return;
                                    }
                                    const vectorContext = getVectorContext(event);
                                    const elapsedRatio = elapsed / duration;
                                    // radius will be 5 at start and 30 at end.
                                    const radius = easeOut(elapsedRatio) * 25 + 5;
                                    const opacity = easeOut(1 - elapsedRatio);

                                    const style = new Style({
                                      image: new CircleStyle({
                                        radius: radius,
                                        stroke: new Stroke({
                                          color: 'rgba(4, 144, 242, ' + opacity + ')',
                                          //color: $DATES_data_array[i].getColorCode,
                                          width: 0.25 + opacity,
                                        }),
                                      }),
                                    });

                                    vectorContext.setStyle(style);
                                    vectorContext.drawGeometry(flashGeom);
                                    // tell OpenLayers to continue postrender animation
                                    if($('#map')){
                                        var map = $('#map').data('map');
                                    }
                                    if($('#view_map')){
                                        var map = $('#view_map').data('map');
                                    }
                                    map.render();
                                  }
                                }
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}else if($DATES_data_array[i].EVENT_STATUS =='ACTIVE'){
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(0,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							/* --------------------------------------------------------  */
							if($( "#event_creator_formFilter1" ).val()=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(0,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							/* --------------------------------------------------------  */
							else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
								/* --------------------------------------------------------  */
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' /* square */,
											lineJoin: 'bevel' /* round */,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								/* ------------------------------------------------- */
								const duration = 3000;
                                function flash(feature) {
                                  const start = Date.now();
                                  const flashGeom = feature.getGeometry().clone();
                                  const listenerKey = rasterLayer.on('postrender', animate);

                                  function animate(event) {
                                    const frameState = event.frameState;
                                    const elapsed = frameState.time - start;
                                    if (elapsed >= duration) {
                                      unByKey(listenerKey);
                                      return;
                                    }
                                    const vectorContext = getVectorContext(event);
                                    const elapsedRatio = elapsed / duration;
                                    // radius will be 5 at start and 30 at end.
                                    const radius = easeOut(elapsedRatio) * 25 + 5;
                                    const opacity = easeOut(1 - elapsedRatio);

                                    const style = new Style({
                                      image: new CircleStyle({
                                        radius: radius,
                                        stroke: new Stroke({
                                          color: 'rgba(4, 144, 242, ' + opacity + ')',
                                          //color: $DATES_data_array[i].getColorCode,
                                          width: 0.25 + opacity,
                                        }),
                                      }),
                                    });

                                    vectorContext.setStyle(style);
                                    vectorContext.drawGeometry(flashGeom);
                                    // tell OpenLayers to continue postrender animation
                                    if($('#map')){
                                        var map = $('#map').data('map');
                                    }
                                    if($('#view_map')){
                                        var map = $('#view_map').data('map');
                                    }
                                    map.render();
                                  }
                                }
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }/*END IF*/
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatEventPins(rasterLayer){
    CONSOLE_MANAGER('initiatEventPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    
    const tileLayer = new TileLayer({
      source: new OSM({
        wrapX: false,
      }),
    });
    
    if($('#settings_event_general_mapPath').val()){
		$DATES_obj_array = JSON.parse($('#settings_event_general_mapPath').val());
		$DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
		if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.getUniqEventId != "undefined"){
                        if((element.getUniqEventId)  && (element.getRegisterCode) && (element.getLon) && (element.getLat) && (element.getColorCode) && (element.getStartDate) && (element.getEndDate) && (element.getEnvironmentOption) && (element.getEventStatus)){
                            CONSOLE_MANAGER('COORDINATES LOD: ',element.getLon+' LAT: '+element.getLat);
                            storeCoordinate(element.getLon, element.getLat, pfeatures);
                            pin_count.push(element.getColorCode);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].getUniqEventId],
                        registerCode: [$DATES_data_array[i].getRegisterCode],
                        name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					var $color = $DATES_data_array[i].getColorCode;
                    piconFeature.setStyle(new Style({
                        image: new CircleStyle({
                            radius: 8,
                            stroke: new Stroke({
                                color: 'rgba(0,255,0, 0.7)',
                                width: 2,
                                //lineCap: 'round' ,
                                lineJoin: 'bevel' ,
                            }),
                            fill: new Fill({
                                //color: 'rgba(239,239,239, 0.7)',
                                color: $color,
                            })
                        })
                    }));
                    /* --------------------------------------------------------  */
                    if($DATES_data_array[i].getRegisterCode==$('#settings_event_general_registerCode').val()){
                        const duration = 3000;
                        function flash(feature) {
                          const start = Date.now();
                          const flashGeom = feature.getGeometry().clone();
                          const listenerKey = rasterLayer.on('postrender', animate);

                          function animate(event) {
                            const frameState = event.frameState;
                            const elapsed = frameState.time - start;
                            if (elapsed >= duration) {
                              unByKey(listenerKey);
                              return;
                            }
                            const vectorContext = getVectorContext(event);
                            const elapsedRatio = elapsed / duration;
                            // radius will be 5 at start and 30 at end.
                            const radius = easeOut(elapsedRatio) * 25 + 5;
                            const opacity = easeOut(1 - elapsedRatio);

                            const style = new Style({
                              image: new CircleStyle({
                                radius: radius,
                                stroke: new Stroke({
                                  color: 'rgba(4, 144, 242, ' + opacity + ')',
                                  //color: $DATES_data_array[i].getColorCode,
                                  width: 0.25 + opacity,
                                }),
                              }),
                            });

                            vectorContext.setStyle(style);
                            vectorContext.drawGeometry(flashGeom);
                            // tell OpenLayers to continue postrender animation
                            if($('#map')){
                                var map = $('#map').data('map');
                            }
                            if($('#view_map')){
                                var map = $('#view_map').data('map');
                            }
                            map.render();
                          }
                        }
                        var interval = window.setInterval(function(){
                            flash(piconFeature);
                        },3000);
                         
                        $vectorpins.on('addfeature', function(e) {
                            flash(e.feature);
                        });
                    }
                    /* --------------------------------------------------------  */
                    $vectorpins.addFeature(piconFeature);
                    /* --------------------------------------------------------  
                    var $EVENT_CONTROL = 'PARENT';
                    if($( "#event_creator_formFilter1" ).val()){
                        $EVENT_CONTROL = $( "#event_creator_formFilter1" ).val();
                    }else if($( "#settings_event_general_index2" ).val()){
                        $EVENT_CONTROL = $( "#settings_event_general_index2" ).val();
                    }
                    
                    if($EVENT_CONTROL=='PARENT'){yle(new Style({
                            image: new CircleStyle({
                                radius: 30,
                                stroke: new Stroke({
                                    color: 'rgba(0,255,0, 0.7)',
                                    //color: $DATES_data_array[i].getColorCode,
                                    width: 2,
                                    //lineCap: 'round' ,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    color: 'rgba(150,255,255, 0.0)',
                                })
                            })
                        }));
                    }
                    else if($EVENT_CONTROL=='CHILD'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 8,
                                stroke: new Stroke({
                                    color: 'rgba(239,45,239, 0.0)',
                                    width: 3,
                                    //lineCap: 'round' // square,
                                    lineJoin: 'bevel' // round,
                                }),
                                fill: new Fill({
                                    color: 'rgba(47,45,239, 0.0)'
                                })
                            })
                        }));
                        
                        var duration = 3000;
                        function flash(feature) {
                            var start = new Date().getTime();
                            var listenerKey = rasterLayer.on('postrender', animate);

                            function animate(event) {
                                var vectorContext = getVectorContext(event);
                                var frameState = event.frameState;
                                var flashGeom = feature.getGeometry().clone();
                                var elapsed = frameState.time - start;
                                var elapsedRatio = elapsed / duration;
                                // radius will be 5 at start and 30 at end.
                                var radius = easeOut(elapsedRatio) * 25 + 5;
                                var opacity = easeOut(1 - elapsedRatio);

                                var style = new Style({
                                    image: new CircleStyle({
                                        radius: radius,
                                        stroke: new Stroke({
                                            //color: 'rgba(255, 0, 0, ' + opacity + ')',
                                            //color: 'rgba(0, 255 , 0, ' + opacity + ')',
                                            color: 'rgba(0,255,0, 0.7)',
                                            //color: $color,
                                            //color: $DATES_data_array[i].getColorCode,
                                        width: 0.25 + opacity
                                        })
                                    })
                                });
                                vectorContext.setStyle(style);
                                vectorContext.drawGeometry(flashGeom);

                                if (elapsed > duration) {
                                  unByKey(listenerKey);
                                  return;
                                }
                            // tell OpenLayers to continue postrender animation
                                var map = $('#map').data('map');
                                map.render();
                            }
                        }
                        var interval = window.setInterval(function(){
                            flash(piconFeature);
                        },3000);
                        $vectorpins.on('addfeature', function(e) {
                            flash(e.feature);
                        });
                    }
                    $vectorpins.addFeature(piconFeature);
                    */
                    /*
					if($DATES_data_array[i].EVENT_STATUS =='COMPLETE'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 3,
                                stroke: new Stroke({
                                    color: 'rgba(255,0,0, 0.7)',
                                    width: 1,
                                    //lineCap: 'round' ,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    //color: 'rgba(239,239,239, 0.7)',
									color: $color,
                                })
                            })
                        }));
					}else if($DATES_data_array[i].EVENT_STATUS =='PENDING'){
						
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(255,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							
							if($( "#event_creator_formFilter1" ).val()=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(255,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							
							else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
								
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								
								var duration = 3000;
								function flash(feature) {
									var start = new Date().getTime();
									var listenerKey = rasterLayer.on('postrender', animate);

									function animate(event) {
										var vectorContext = getVectorContext(event);
										var frameState = event.frameState;
										var flashGeom = feature.getGeometry().clone();
										var elapsed = frameState.time - start;
										var elapsedRatio = elapsed / duration;
										// radius will be 5 at start and 30 at end.
										var radius = easeOut(elapsedRatio) * 25 + 5;
										var opacity = easeOut(1 - elapsedRatio);

										var style = new Style({
											image: new CircleStyle({
												radius: radius,
												stroke: new Stroke({
													//color: 'rgba(255, 0, 0, ' + opacity + ')',
													//color: 'rgba(0, 255 , 0, ' + opacity + ')',
													color: 'rgba(255,255,0, 0.7)',
													//color: $color,
													//color: $DATES_data_array[i].getColorCode,
												width: 0.25 + opacity
												})
											})
										});
										vectorContext.setStyle(style);
										vectorContext.drawGeometry(flashGeom);

										if (elapsed > duration) {
										  unByKey(listenerKey);
										  return;
										}
									// tell OpenLayers to continue postrender animation
										var map = $('#map').data('map');
										map.render();
									}
								}
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}else if($DATES_data_array[i].EVENT_STATUS =='ACTIVE'){
						
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(0,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							
                            var $EVENT_CONTROL = 'PARENT';
                            if($( "#event_creator_formFilter1" ).val()){
                                $EVENT_CONTROL = $( "#event_creator_formFilter1" ).val();
                            }else if($( "#settings_event_general_index2" ).val()){
                                $EVENT_CONTROL = $( "#settings_event_general_index2" ).val();
                            }
                            
							if($EVENT_CONTROL=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(0,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							
							else if($EVENT_CONTROL=='CHILD'){
								
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' // square ,
											lineJoin: 'bevel' // round ,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								
								var duration = 3000;
								function flash(feature) {
									var start = new Date().getTime();
									var listenerKey = rasterLayer.on('postrender', animate);

									function animate(event) {
										var vectorContext = getVectorContext(event);
										var frameState = event.frameState;
										var flashGeom = feature.getGeometry().clone();
										var elapsed = frameState.time - start;
										var elapsedRatio = elapsed / duration;
										// radius will be 5 at start and 30 at end.
										var radius = easeOut(elapsedRatio) * 25 + 5;
										var opacity = easeOut(1 - elapsedRatio);

										var style = new Style({
											image: new CircleStyle({
												radius: radius,
												stroke: new Stroke({
													//color: 'rgba(255, 0, 0, ' + opacity + ')',
													//color: 'rgba(0, 255 , 0, ' + opacity + ')',
													color: 'rgba(0,255,0, 0.7)',
													//color: $color,
													//color: $DATES_data_array[i].getColorCode,
												width: 0.25 + opacity
												})
											})
										});
										vectorContext.setStyle(style);
										vectorContext.drawGeometry(flashGeom);

										if (elapsed > duration) {
										  unByKey(listenerKey);
										  return;
										}
									// tell OpenLayers to continue postrender animation
										var map = $('#map').data('map');
										map.render();
									}
								}
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}
                    $vectorpins.addFeature(piconFeature);
                    */
                    
                }
            }
        }
    }/*END IF*/
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatMicroPins(rasterLayer){
    CONSOLE_MANAGER('initiatMicroPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    if($('#settings_event_general_formCoords').val()){
		$DATES_obj_array = JSON.parse($('#settings_event_general_formCoords').val());
		$DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
		if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.getUniqEventId != "undefined"){
                        if((element.getUniqEventId)  && (element.getRegisterCode) && (element.getLon) && (element.getLat) && (element.getColorCode) && (element.getStartDate) && (element.getEndDate) && (element.getEnvironmentOption) && (element.getEventStatus)){
                            storeCoordinate(element.getLon, element.getLat, pfeatures);
                            pin_count.push(element.getColorCode);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].getUniqEventId],
                        registerCode: [$DATES_data_array[i].getRegisterCode],
                        name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					var $color = $DATES_data_array[i].getColorCode;
                    /* --------------------------------------------------------  */
                    if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 8,
                                stroke: new Stroke({
                                    //color: 'rgba(0,255,0, 0.7)',
									color: $color,
                                    width: 2,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    color: $color,
                                })
                            })
                        }));
                    }
                    /* --------------------------------------------------------  */
                    else if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
                        /* --------------------------------------------------------  */
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 8,
                                stroke: new Stroke({
                                    color: 'rgba(239,45,239, 0.0)',
                                    width: 3,
                                    lineJoin: 'bevel' /* round */,
                                }),
                                fill: new Fill({
                                    color: 'rgba(47,45,239, 0.0)'
                                })
                            })
                        }));
                        /* ------------------------------------------------- */
                        const duration = 3000;
                        function flash(feature) {
                          const start = Date.now();
                          const flashGeom = feature.getGeometry().clone();
                          const listenerKey = rasterLayer.on('postrender', animate);

                          function animate(event) {
                            const frameState = event.frameState;
                            const elapsed = frameState.time - start;
                            if (elapsed >= duration) {
                              unByKey(listenerKey);
                              return;
                            }
                            const vectorContext = getVectorContext(event);
                            const elapsedRatio = elapsed / duration;
                            // radius will be 5 at start and 30 at end.
                            const radius = easeOut(elapsedRatio) * 25 + 5;
                            const opacity = easeOut(1 - elapsedRatio);

                            const style = new Style({
                              image: new CircleStyle({
                                radius: radius,
                                stroke: new Stroke({
                                  color: 'rgba(4, 144, 242, ' + opacity + ')',
                                  //color: $DATES_data_array[i].getColorCode,
                                  width: 0.25 + opacity,
                                }),
                              }),
                            });

                            vectorContext.setStyle(style);
                            vectorContext.drawGeometry(flashGeom);
                            // tell OpenLayers to continue postrender animation
                            if($('#map')){
                                var map = $('#map').data('map');
                            }
                            if($('#view_map')){
                                var map = $('#view_map').data('map');
                            }
                            map.render();
                          }
                        }
                        var interval = window.setInterval(function(){
                            flash(piconFeature);
                        },3000);
                        $vectorpins.on('addfeature', function(e) {
                            flash(e.feature);
                        });
                    }
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }/*END IF*/
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
	if($('#business_settings_formCoords').val()){
		$DATES_obj_array = JSON.parse($('#business_settings_formCoords').val());
		$DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
		if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.getUniqMemId != "undefined"){
                        if((element.getUniqMemId)  && (element.getLon) && (element.getLat) && (element.getEnvironmentOption)){
                            storeCoordinate(element.getLon, element.getLat, pfeatures);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].getUniqMemId],
                        name: [$DATES_data_array[i].getUniqMemId],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					var $color = 'rgba(0,255,0, 0.7)';// $DATES_data_array[i].getColorCode;
                    /* --------------------------------------------------------  */
                    if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 8,
                                stroke: new Stroke({
                                    //color: 'rgba(0,255,0, 0.7)',
									color: $color,
                                    width: 2,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    color: $color,
                                })
                            })
                        }));
                    }
                    /* --------------------------------------------------------  */
                    else if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
                        /* --------------------------------------------------------  */
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 8,
                                stroke: new Stroke({
                                    color: 'rgba(239,45,239, 0.0)',
                                    width: 3,
                                    lineJoin: 'bevel' /* round */,
                                }),
                                fill: new Fill({
                                    color: 'rgba(47,45,239, 0.0)'
                                })
                            })
                        }));
                        /* ------------------------------------------------- */
                        const duration = 3000;
                        function flash(feature) {
                          const start = Date.now();
                          const flashGeom = feature.getGeometry().clone();
                          const listenerKey = rasterLayer.on('postrender', animate);

                          function animate(event) {
                            const frameState = event.frameState;
                            const elapsed = frameState.time - start;
                            if (elapsed >= duration) {
                              unByKey(listenerKey);
                              return;
                            }
                            const vectorContext = getVectorContext(event);
                            const elapsedRatio = elapsed / duration;
                            // radius will be 5 at start and 30 at end.
                            const radius = easeOut(elapsedRatio) * 25 + 5;
                            const opacity = easeOut(1 - elapsedRatio);

                            const style = new Style({
                              image: new CircleStyle({
                                radius: radius,
                                stroke: new Stroke({
                                  color: 'rgba(4, 144, 242, ' + opacity + ')',
                                  //color: $DATES_data_array[i].getColorCode,
                                  width: 0.25 + opacity,
                                }),
                              }),
                            });

                            vectorContext.setStyle(style);
                            vectorContext.drawGeometry(flashGeom);
                            // tell OpenLayers to continue postrender animation
                            if($('#map')){
                                var map = $('#map').data('map');
                            }
                            if($('#view_map')){
                                var map = $('#view_map').data('map');
                            }
                            map.render();
                          }
                        }
                        var interval = window.setInterval(function(){
                            flash(piconFeature);
                        },3000);
                        $vectorpins.on('addfeature', function(e) {
                            flash(e.feature);
                        });
                    }
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }/*END IF*/
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */		
function initiatMapPins(){
    CONSOLE_MANAGER('initiatMapPins','ACTIVATED');
    var $vectorpins = new VectorSource({});
    var $Path_obj_array =  [];
    if($('#event_multi_upload_select_mapPath').val()){
        $Path_obj_array = JSON.parse($('#event_multi_upload_select_mapPath').val()); // my array	
        $Path_obj_array = $.grep($Path_obj_array,function(n){ return n == 0 || n });

        $temp_length = $Path_obj_array.length;
        $temp_start = parseInt(1);
        $temp_end = parseInt($Path_obj_array.length - 1);

        if($Path_obj_array[0]['_map_style']=='FLAG_POINTS'){
            //$PINS_obj_array['default_start']+' - '+$PINS_obj_array['default_finish']
            if($temp_length>2){
                var iconFeature5 = new Feature({
                    //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_start]['_longitude'], $Path_obj_array[$temp_start]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                 });
                var iconStyle5 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //src: 'https://openlayers.org/en/v3.20.1/examples/data/icon.png'
                        src: $START_PIN_TEMPLATE
                    }))
                 });
                var iconFeature6 = new Feature({
                            //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_end]['_longitude'], $Path_obj_array[$temp_end]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                  });
                var iconStyle6 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //color: '#8959A8',
                        src: $FINISH_PIN_TEMPLATE
                    }))
                });
                iconFeature5.setStyle(iconStyle5);
                iconFeature6.setStyle(iconStyle6);
                $vectorpins.addFeature( iconFeature5 );
                $vectorpins.addFeature( iconFeature6 );
            }else{
                var iconFeature5 = new Feature({
                    //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_start]['_longitude'], $Path_obj_array[$temp_start]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                 });
                var iconStyle5 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //src: 'https://openlayers.org/en/v3.20.1/examples/data/icon.png'
                        src: $START_PIN_TEMPLATE
                    }))
                 });
                iconFeature5.setStyle(iconStyle5);
                $vectorpins.addFeature( iconFeature5 );
            }	
        }/*END IF*/
    }/*END IF*/
    return $vectorpins;
}/*END FUNCTION*/
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */		
function initiatCreatePins(){
    CONSOLE_MANAGER('initiatCreatePins','ACTIVATED');
    var $vectorpins = new VectorSource({});
    var $Path_obj_array =  [];
    if($('#event_creator_mapPath').val()){
        $Path_obj_array = JSON.parse($('#event_creator_mapPath').val()); // my array	
        $Path_obj_array = $.grep($Path_obj_array,function(n){ return n == 0 || n });

        $temp_length = $Path_obj_array.length;
        $temp_start = parseInt(1);
        $temp_end = parseInt($Path_obj_array.length - 1);

        if($Path_obj_array[0]['_map_style']=='FLAG_POINTS'){
            //$PINS_obj_array['default_start']+' - '+$PINS_obj_array['default_finish']
            if($temp_length>2){
                var iconFeature5 = new Feature({
                    //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_start]['_longitude'], $Path_obj_array[$temp_start]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                 });
                var iconStyle5 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //src: 'https://openlayers.org/en/v3.20.1/examples/data/icon.png'
                        src: $START_PIN_TEMPLATE
                    }))
                 });
                var iconFeature6 = new Feature({
                            //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_end]['_longitude'], $Path_obj_array[$temp_end]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                  });
                var iconStyle6 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //color: '#8959A8',
                        src: $FINISH_PIN_TEMPLATE
                    }))
                });
                iconFeature5.setStyle(iconStyle5);
                iconFeature6.setStyle(iconStyle6);
                $vectorpins.addFeature( iconFeature5 );
                $vectorpins.addFeature( iconFeature6 );
            }else{
                var iconFeature5 = new Feature({
                    //geometry: new Point([$Path_obj_array[1]['_longitude'], $Path_obj_array[1]['_latitude']]),
                    geometry: new Point(transform([$Path_obj_array[$temp_start]['_longitude'], $Path_obj_array[$temp_start]['_latitude']], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'Null Island',
                    //population: 4000,
                    //rainfall: 500
                 });
                var iconStyle5 = new Style({
                    image: new Icon(/** @type {olx.style.IconOptions} */ ({
                              anchor: [0.5, 33],
                              anchorXUnits: 'fraction',
                              anchorYUnits: 'pixels',
                              //src: 'https://openlayers.org/en/v3.20.1/examples/data/icon.png'
                        src: $START_PIN_TEMPLATE
                    }))
                 });
                iconFeature5.setStyle(iconStyle5);
                $vectorpins.addFeature( iconFeature5 );
            }	
        }/*END IF*/
    }/*END IF*/
    return $vectorpins;
}/*END FUNCTION*/
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatRecommendationPins(){
    CONSOLE_MANAGER('initiatRecommendationPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    if(document.getElementById("total_objects_count")){
        var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
        var $code = $("#index_object").val();
        for(var $i=0; $i<Object_count; $i++){
            var $SETLAT = '#form_'+$code+'_'+$i+'_lat';
            var $SETLONG = '#form_'+$code+'_'+$i+'_lon';
            /* ------------------------------------------------- */
            var $COORDMgmt = {LAT:$($SETLAT).val(),LONG:$($SETLONG).val()};
            $DATES_obj_array[$i] =$COORDMgmt;
            /* ------------------------------------------------- */
            /* ------------------------------------------------- */
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        if($DATES_obj_array.length > 0){
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if((element.LONG) && (element.LAT)){
                        storeCoordinate(element.LONG, element.LAT, pfeatures);
                        $DATES_data_array[$count_obj]=element;
                        $count_obj++;
                    }
                }
            });
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        //id: [$DATES_data_array[i].GET_UNIQ_ID],
                        //name: [$DATES_data_array[i].GET_UNIQ_ID,$DATES_data_array[i].GET_ITEM_ID],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					piconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 5,
							stroke: new Stroke({
								color: 'rgba(150,255,255, 0.7)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(150,255,255, 0.7)',
							})
						})
					}));
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatComplianceControlEventPins(rasterLayer){
    CONSOLE_MANAGER('initiatComplianceControlEventPins','ACTIVATED');
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
    if($('#event_locations_menu_formCoords').val()){
		$DATES_obj_array = JSON.parse($('#event_locations_menu_formCoords').val());
		$DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
		if($DATES_obj_array.length > 0){
            var pin_count = [];
            var $DATES_data_array = [];
            function storeCoordinate(xVal, yVal, array) {
                array.push([xVal,yVal]);
            }
            var pfeatures = [];
            var $count_obj = 0;
            $DATES_obj_array.forEach((element, index, array) => {
                if(element){
                    if(typeof element.getUniqEventId != "undefined"){
                        if((element.getUniqEventId)  && (element.getRegisterCode) && (element.getLon) && (element.getLat) && (element.getColorCode) && (element.getStartDate) && (element.getEndDate) && (element.getEnvironmentOption) && (element.getEventStatus)){
                            storeCoordinate(element.getLon, element.getLat, pfeatures);
                            pin_count.push(element.getColorCode);
                            $DATES_data_array[$count_obj]=element;
                            $count_obj++;
                        }
                    }
                }
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(pfeatures.length > 0){
                for (var i = 0; i < pfeatures.length; i++){
                    var coord = pfeatures[i];
                    if(jQuery.type(pfeatures[i]) === "array"){
                        coord = pfeatures[i].toString();
                        CONSOLE_MANAGER('COORDINATES',parseInt(i +1)+'/'+pfeatures.length+': '+coord);
                    }
                    var piconFeature = new Feature({
                        id: [$DATES_data_array[i].getUniqEventId],
                        registerCode: [$DATES_data_array[i].getRegisterCode],
                        name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
                        geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))
                    });
					var $color = $DATES_data_array[i].getColorCode;
					if($DATES_data_array[i].EVENT_STATUS =='COMPLETE'){
                        piconFeature.setStyle(new Style({
                            image: new CircleStyle({
                                radius: 3,
                                stroke: new Stroke({
                                    color: 'rgba(255,0,0, 0.7)',
                                    width: 1,
                                    //lineCap: 'round' ,
                                    lineJoin: 'bevel' ,
                                }),
                                fill: new Fill({
                                    //color: 'rgba(239,239,239, 0.7)',
									color: $color,
                                })
                            })
                        }));
					}else if($DATES_data_array[i].EVENT_STATUS =='PENDING'){
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(255,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							/* --------------------------------------------------------  */
							if($( "#event_locations_menu_formFilter1" ).val()=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(255,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							/* --------------------------------------------------------  */
							else if($( "#event_locations_menu_formFilter1" ).val()=='CHILD'){
								/* --------------------------------------------------------  */
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' /* square */,
											lineJoin: 'bevel' /* round */,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								/* ------------------------------------------------- */
								const duration = 3000;
                                function flash(feature) {
                                  const start = Date.now();
                                  const flashGeom = feature.getGeometry().clone();
                                  const listenerKey = rasterLayer.on('postrender', animate);

                                  function animate(event) {
                                    const frameState = event.frameState;
                                    const elapsed = frameState.time - start;
                                    if (elapsed >= duration) {
                                      unByKey(listenerKey);
                                      return;
                                    }
                                    const vectorContext = getVectorContext(event);
                                    const elapsedRatio = elapsed / duration;
                                    // radius will be 5 at start and 30 at end.
                                    const radius = easeOut(elapsedRatio) * 25 + 5;
                                    const opacity = easeOut(1 - elapsedRatio);

                                    const style = new Style({
                                      image: new CircleStyle({
                                        radius: radius,
                                        stroke: new Stroke({
                                          color: 'rgba(4, 144, 242, ' + opacity + ')',
                                          //color: $DATES_data_array[i].getColorCode,
                                          width: 0.25 + opacity,
                                        }),
                                      }),
                                    });

                                    vectorContext.setStyle(style);
                                    vectorContext.drawGeometry(flashGeom);
                                    // tell OpenLayers to continue postrender animation
                                    if($('#map')){
                                        var map = $('#map').data('map');
                                    }
                                    if($('#view_map')){
                                        var map = $('#view_map').data('map');
                                    }
                                    map.render();
                                  }
                                }
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}else if($DATES_data_array[i].EVENT_STATUS =='ACTIVE'){
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='PHYSICAL'){
							piconFeature.setStyle(new Style({
								image: new CircleStyle({
									radius: 8,
									stroke: new Stroke({
										color: 'rgba(0,255,0, 0.7)',
										width: 2,
										//lineCap: 'round' ,
										lineJoin: 'bevel' ,
									}),
									fill: new Fill({
										//color: 'rgba(239,239,239, 0.7)',
										color: $color,
									})
								})
							}));
						}
						/* --------------------------------------------------------  */
						if($DATES_data_array[i].getEnvironmentOption=='VIRTUAL'){
							/* --------------------------------------------------------  */
							if($( "#event_locations_menu_formFilter1" ).val()=='PARENT'){
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 30,
										stroke: new Stroke({
											color: 'rgba(0,255,0, 0.7)',
											//color: $DATES_data_array[i].getColorCode,
											width: 2,
											//lineCap: 'round' ,
											lineJoin: 'bevel' ,
										}),
										fill: new Fill({
											color: 'rgba(150,255,255, 0.0)',
										})
									})
								}));
							}
							/* --------------------------------------------------------  */
							else if($( "#event_locations_menu_formFilter1" ).val()=='CHILD'){
								/* --------------------------------------------------------  */
								piconFeature.setStyle(new Style({
									image: new CircleStyle({
										radius: 8,
										stroke: new Stroke({
											color: 'rgba(239,45,239, 0.0)',
											width: 3,
											//lineCap: 'round' /* square */,
											lineJoin: 'bevel' /* round */,
										}),
										fill: new Fill({
											color: 'rgba(47,45,239, 0.0)'
										})
									})
								}));
								/* ------------------------------------------------- */
								const duration = 3000;
                                function flash(feature) {
                                  const start = Date.now();
                                  const flashGeom = feature.getGeometry().clone();
                                  const listenerKey = rasterLayer.on('postrender', animate);

                                  function animate(event) {
                                    const frameState = event.frameState;
                                    const elapsed = frameState.time - start;
                                    if (elapsed >= duration) {
                                      unByKey(listenerKey);
                                      return;
                                    }
                                    const vectorContext = getVectorContext(event);
                                    const elapsedRatio = elapsed / duration;
                                    // radius will be 5 at start and 30 at end.
                                    const radius = easeOut(elapsedRatio) * 25 + 5;
                                    const opacity = easeOut(1 - elapsedRatio);

                                    const style = new Style({
                                      image: new CircleStyle({
                                        radius: radius,
                                        stroke: new Stroke({
                                          color: 'rgba(4, 144, 242, ' + opacity + ')',
                                          //color: $DATES_data_array[i].getColorCode,
                                          width: 0.25 + opacity,
                                        }),
                                      }),
                                    });

                                    vectorContext.setStyle(style);
                                    vectorContext.drawGeometry(flashGeom);
                                    // tell OpenLayers to continue postrender animation
                                    if($('#map')){
                                        var map = $('#map').data('map');
                                    }
                                    if($('#view_map')){
                                        var map = $('#view_map').data('map');
                                    }
                                    map.render();
                                  }
                                }
								var interval = window.setInterval(function(){
									flash(piconFeature);
								},3000);
								$vectorpins.on('addfeature', function(e) {
									flash(e.feature);
								});
							}
						}
					}
                    $vectorpins.addFeature(piconFeature);
                }
            }
        }
    }/*END IF*/
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    return $vectorpins;
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function initiatPhotobucketPins($LAT,$LONG){
    CONSOLE_MANAGER('initiatPhotobucketPins','ACTIVATED LAT: '+$LAT+' LONG: '+$LONG);
	
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    if(document.getElementById("photobucket_contribution_menu_middle_bottom")){
        CONSOLE_MANAGER('initiatPhotobucketPins','getElementById LAT: '+$LAT+' LONG: '+$LONG);        
        /* -------------------------------------------------------- */	
        //terrain watercolor toner	toner-lite
        /* -------------------------------------------------------- */
        $("#photobucket_contribution_menu_middle_bottom").css({display: "none"});
        var x = document.getElementById("photobucket_contribution_menu_middle_bottom");
        if (x.style.display === "none") {
            var tempLayer = new VectorLayer({});
            var $ZOOM = 2;
            var $INITAL_RENDER_CONTROL = false;
            /* --------------------------------------------------------  
            var rasterLayer = new TileLayer({
                source: new Stamen({
                    layer: 'toner-lite'
                })
            });
            */
            var rasterLayer = new TileLayer({
                source: new StadiaMaps({
                    layer: 'stamen_toner_lite',
                    retina: true,
                })
            });
            /* --------------------------------------------------------*/
           /* -------------------------------------------------------- */
            var map = new Map({
                layers: [rasterLayer],
                controls: defaultControls({
                  zoom: false,
                  attribution: false,
                  rotate: false,
                }),
                //maxResolution: 'auto',
                //target: document.getElementById('photobucket_contribution_menu_middle_bottom'),
                target: document.getElementById('photobucket_contribution_menu_middle_bottom'),
                view: new View({
                    //minZoom: 9,
                    //maxZoom: 14,
                    center:[0,0],
                    //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                    zoom: 11,
                }),
                interactions: defaultInteractions({
                doubleClickZoom: false,
                dragAndDrop: false,
                dragPan: false,
                keyboardPan: false,
                keyboardZoom: false,
                mouseWheelZoom: false,
                pointer: true,
                select: true
              }).extend([
                new MouseWheelZoom({
                    condition: platformModifierKeyOnly,
                    }) 
                ]),
            });
            /* -------------------------------------------------------- */
            x.style.display = "block";
            map.updateSize();
            /* -------------------------------------------------------- */
            if($('#map')){
               $('#map').data('map', map);
            }
            /* -------------------------------------------------------- */
            SET_STADIA_ATTRIBUTION($('#map').attr('id'));
            /* -------------------------------------------------------- */
            /* ------------------------------------------------- */
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            map.on('rendercomplete', e => {
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */

            })
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            var $tempextent = olExtent.createEmpty();
            var $tempcount = 0;
            var $tempsum = 0;
            var $tempfeatures = [];
            /* -------------------------------------------------------- */	
            var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
            map.on('postrender', function() {
                var view = map.getView();
                var zoom = view.getZoom();
                var worldExtent = view.getProjection().getExtent();
                var extent_mgmt = view.calculateExtent(map.getSize());
                //////////********************
                if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                    var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                    view.fit(newExtent, map.getSize());
                }
                /* --------------------------------------------------------  */
                if(($INITAL_RENDER_CONTROL==false)){
                    CONSOLE_MANAGER('initiatPhotobucketPins','$INITAL_RENDER_CONTROL = FALSE');
                    //setTimeout(SET_GEOLOCATION(), 1000);
                    /* -------------------------------------------------------- */
                    if((($LAT!=null)&&($LONG!=null)) && (($LAT!=false)&&($LONG!=false)) && (($LAT!='FALSE')&&($LONG!='FALSE'))){
                        AddFeature($LONG, $LAT, $INITAL_RENDER_CONTROL, map)
                        $ZOOM = 14;
                        setTimeout(CenterMap($LONG, $LAT,$ZOOM), 2000);//map.getView().getZoom()
                    }else{
                        //setTimeout(SET_GEOLOCATION(), 1000);
                        Temp_CenterMap(Number(parseInt(0)), Number(parseInt(0)), parseInt(2), map, false);
                        //$ZOOM = 2;
                    }
                    /* -------------------------------------------------------- */
                    window_complete_script('initiatPhotobucketPins - LOADING CLOSE');
                    $INITAL_RENDER_CONTROL = true;
                }
            });
        } else {
            x.style.display = "none";
        }
        /* ------------------------------------------------- */
        function AddFeature(long, lat, $INITAL_RENDER_CONTROL, $MAP) {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('AddFeature','ACTIVATE');
                /* ------------------------------------------------- 
                map.removeLayer(tempLayer);
                map.getLayers().getArray().forEach(function(layer){
                    if (layer.getSource().getFeatures) {
                        map.removeLayer(layer);
                        var features = layer.getSource().getFeatures();
                        CONSOLE_MANAGER('initiatPhotobucketPins','AddFeature - features: '+features);
                    }
                });*/
                /* ------------------------------------------------- */
                var iconFeature = new Feature({      
                    geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
                    //name: 'NULL'
                });
                iconFeature.setStyle(new Style({
                    image: new CircleStyle({
                        radius: 12,
                        stroke: new Stroke({
                            color: 'rgba(239,45,239, 0.7)',
                            width: 2,
                            //lineCap: 'round' /* square */,
                            lineJoin: 'bevel' /* round */,
                        }),
                        fill: new Fill({
                            color: 'rgba(47,45,239, 0.3)'
                        })
                    })
                }));
                var tempSource = new VectorSource({
                    features: [iconFeature]
                });
                var tempLayer = new VectorLayer({
                     source: tempSource
                });
                //map.addLayer(tempLayer);
                /**/
                var layersToRemove = [];
                $MAP.getLayers().forEach(function (layer) {
                    if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
                        layersToRemove.push(layer);
                    }
                });
                var len = layersToRemove.length;
                for(var i = 0; i < len; i++) {
                    $MAP.removeLayer(layersToRemove[i]);
                }
                tempLayer.setSource(tempSource);
                $MAP.addLayer(tempLayer);
                tempLayer.set('name', 'tempLayer');
                
            }
            
        }/*END FUNCTION */
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        function CenterMap(long, lat, $zoom) {
            map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
            if($zoom == null){
                $zoom =11;
            }
            map.getView().setZoom($zoom);
        }/*END FUNCTION */
    }
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    //return $vectorpins;
}
/* --------------------------------------------------------  */
/*********************************************************************************************************************/
/***********************	//////////////////////multifunction_map_set_selection	**********************************/
/* --------------------------------------------------------  */
export function multifunction_map_set_selection($MGMT){
	CONSOLE_MANAGER('multifunction_map_set_selection','ACTIVATED');
	/* --------------------------------------------------------  */
	/**/
	$MGMT.on('click', function(e){
		e.stopPropagation;
		$tempcount= 0;
	  	var attsCollector = [];
	  	$MGMT.forEachFeatureAtPixel(e.pixel, function(feature, layer) { 
			e.stopPropagation;
			if($tempcount == 0){
				$('#'+feature.get('id')).addClass('Map_marker_active')
				windowUnload_script('function: multifunction_map_set_selection');
				attsCollector.push(feature.get('name'));
				$tempcoord = feature.getGeometry().getCoordinates();
				$tempcoord = transform($tempcoord, 'EPSG:3857', 'EPSG:4326');
				//$tempcoord = transform($tempcoord, 'EPSG:4326','EPSG:3857');
				$temp_long = $tempcoord[0];
				$temp_lat = $tempcoord[1];
				Temp_CenterMap($temp_long, $temp_lat, $MGMT.getView().getZoom(), $MGMT, false)
				$('#search_request_control').val('multifunction_map_activate');
				$('#search_request_uniqEventId').val(attsCollector[0][0]);
				$('#search_request_registerCode').val(attsCollector[0][1]);
				Map_item_click_submit(attsCollector[0]);
			}
			$tempcount++;
		});
	})
	/* --------------------------------------------------------  */
}/*END FUNCTION */	
/*********************************************************************************************************************/
/* --------------------------------------------------------  */
export function select_date_map_set_register($MGMT){
	CONSOLE_MANAGER('select_date_map_set_register','ACTIVATED');
	/* --------------------------------------------------------  */
	var interactions = $MGMT.getInteractions().getArray();
	var pinchRotateInteraction = interactions.filter(function(interaction) {
	  	return interaction instanceof defaultInteractions().extend([new PinchZoom()])
	})[0];
	/* -------------------------------------------------------- */
	$(document).ready(function () {
		var countdown;
		$MGMT.getTargetElement().addEventListener('mousedown', function($EVENT_ACTION) {
			$('#standard_results_ajax_pressCount').val(3);
			$tempcoord = transform($MGMT.getEventCoordinate($EVENT_ACTION), 'EPSG:3857', 'EPSG:4326');
			timeleft = $('#standard_results_ajax_pressCount').val();
            countdown = setInterval(function(){
                if($('#standard_results_ajax_pressCount').val() >= 0){
                    $('#standard_results_ajax_pressCount').val(timeleft);
                    OnPress_Action_Map_Select(parseInt(timeleft),$MGMT.getEventCoordinate($EVENT_ACTION),$MGMT.getEventPixel($EVENT_ACTION),$MGMT,$EVENT_ACTION);
                    timeleft--;
                   	if($('#standard_results_ajax_pressCount').val() < 0){
                        clearInterval(countdown);
                    }
                }else{
                    clearInterval(countdown);
                }
            },500);
		});
		$MGMT.getTargetElement().addEventListener('mouseup', function(e) {
			clearInterval(countdown);
			if(document.getElementById('OnPress_Action')){
			   Clear_press(true);
			}
		});
		$MGMT.getTargetElement().addEventListener('mouseleave', function(e) {
			clearInterval(countdown);
			if(document.getElementById('OnPress_Action')){
			   Clear_press(true);
			}
		});
		/* -------------------------------------------------------- */
		function onpropertychange() {
			$MGMT.dispatchEvent('movestart');
		  	var view = $MGMT.getView();
		  	view.un('propertychange', onpropertychange);
		  	$MGMT.on('moveend', function() {
				view.on('propertychange', onpropertychange);
		  	});
		};
		$MGMT.getView().on('propertychange', onpropertychange);
		/* -------------------------------------------------------- */
		$MGMT.on('movestart',function(e){
			clearInterval(countdown);
			if(document.getElementById('OnPress_Action')){
			   Clear_press(true);
			}
		});
		$MGMT.on('moveend', function(e) {
			clearInterval(countdown);
			if(document.getElementById('OnPress_Action')){
			   Clear_press(true);
			}
		});
		/* -------------------------------------------------------- */
	});
    /* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function select_date_map_frame_mgmt($MGMT,$CALLER){
	CONSOLE_MANAGER('select_date_map_frame_mgmt','ACTIVATED');
	/* --------------------------------------------------------  */
	/* -------------------------------------------------------- */
	$(document).ready(function () {
		var currZoom = $MGMT.getView().getZoom();
		$MGMT.on('moveend', function(e) {
			var newZoom = $MGMT.getView().getZoom();
			if (currZoom != newZoom) {
				currZoom = newZoom;
				$('#map_key_formZoom').val(currZoom);
			}
		});
		/* -------------------------------------------------------- */
	});
	/* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('map_key_formZoom');
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    CONSOLE_MANAGER('select_date_map_frame_mgmt','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                    var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                    if(mutation.type=='attributes'){
						/* --------------------------------------------------------  */
						if($('#event_item_date_map_formFilter1').val()==2){
							activate_date_map_frame_mgmt($MGMT,$CALLER);
						}
						/* --------------------------------------------------------  */
                    }else if(mutation.type=='childList'){
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
    /* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function activate_date_map_frame_mgmt($MGMT,$CALLER){
	CONSOLE_MANAGER('activate_date_map_frame_mgmt','ACTIVATED');
	/* -------------------------------------------------------- */
	/*
	$('#map_key_select_location_formResolution')
	$('#map_key_select_location_formZoom')
	$('#map_key_select_location_formCoords')
	$('#map_key_select_location_lat')
	$('#map_key_select_location_lon')
	
	$('#map_key_formResolution')
	$('#map_key_location_formZoom')
	$('#map_key_location_formCoords')
	$('#map_key_location_lat')
	$('#map_key_location_lon')
	*/
	if($('#map_key_formZoom').val()>=16){
		$('#map_key_frame').hide();
		$('#map_key_frame').css("display", "inline-block");
	}
	if($('#map_key_formZoom').val()<16){
		$('#map_key_frame').hide();
		$('#map_key_frame').css("display", "none");
	}
    /* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
}/*END FUNCTION */	
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function multifunction_map_reset($MAP,$MGMT1){
	CONSOLE_MANAGER('multifunction_map_reset','ACTIVATED');
	/* --------------------------------------------------------  */
	$MGMT1.getLayers().forEach(function(layer) {
		//olExtent.extend($tempextent, layer.getSource().getExtent());
		var source = layer.getSource();
		var features = source.getFeatures();
		if (features != null && features.length > 0) {
			for (x in features) {
				var properties = features[x].getProperties();
				var id = properties.id;
				//source.removeFeature(features[x]);
			}
		}
		source.clear();
	});
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   		MEDIA PROFILE FILESYSTEM VIEW		    ########################## */
/*
	form_media_index_MGMT
	profile_image_windowfunction_MGMT
	server_image_windowfunction_MGMT
*/
/* --------------------------------------------------------  */
export function activate_Stat_view_MGMT(form_entity){
	CONSOLE_MANAGER('activate_Stat_view_MGMT','ACTIVATED');
	windowload_script('activate_Stat_view_MGMT');
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var Data = null;
	var earthquakeFill = new Fill({
		color: 'rgba(255, 153, 0, 0.8)'
	});
	var earthquakeStroke = new Stroke({
	  	color: 'rgba(255, 204, 0, 0.2)',
	  	width: 1
	});
	var textFill = new Fill({
		color: '#fff'
	});
	var textStroke = new Stroke({
	  	color: 'rgba(0, 0, 0, 0.6)',
	  	width: 3
	});
	var invisibleFill = new Fill({
	  	color: 'rgba(255, 255, 255, 0.01)'
	});
	var maxFeatureCount, vector;
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */	
	function calculateLikePercent($SIZE) {
		var $TOTAL = parseInt(600);
		var $PERCENT = '';
		//$('#media_view_window').attr('value') == 'MULTI';
		//$('#media_view_window').attr('value') == 'MULTI';
		var $VAL = Math.floor((parseInt($SIZE)/$TOTAL)*100);
		if($VAL>=1){
			$PERCENT = ""+$VAL+"%";
		}
		return $PERCENT;
	}
	function calculateDisLikePercent($SIZE) {
		var $TOTAL = parseInt(500);
		var $PERCENT = '';
		//$('#media_view_window').attr('value') == 'MULTI';
		//$('#media_view_window').attr('value') == 'MULTI';
		var $VAL = Math.floor((parseInt($SIZE)/$TOTAL)*100);
		if($VAL>=1){
			$PERCENT = ""+$VAL+"%";
		}
		return $PERCENT;
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */	
	function calculatelikeClusterInfo(resolution) {
	 	var maxFeatureCount = 0;
		//var features = like_vector.getSource().getFeatures();
		var features = LikeLayer.getSource().getFeatures();
	  	var feature, radius;
	  	for (var i = features.length - 1; i >= 0; --i) {
			feature = features[i];
			var originalFeatures = feature.get('features');
			var extent = olExtent.createEmpty();
			var j, jj;
			var tmagnitude = [];
			for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
			  	olExtent.extend(extent, originalFeatures[j].getGeometry().getExtent());
				var tname = originalFeatures[j].get('name');
				tmagnitude.push(parseFloat(tname.substr(2)));
			}
			var maxFeatureCount = Math.max(maxFeatureCount, jj);
			var radius = 0.25 * (olExtent.getWidth(extent) + olExtent.getHeight(extent))/resolution;
			feature.set('radius', radius);
			var total=0;
		  	var e;
		  	for(e = 0; e<tmagnitude.length;e++) { total += tmagnitude[e]; }
		  	feature.set('count_mag', e);
			feature.set('count_magnitude', total);
	  	}
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */	
	function calculatedislikeClusterInfo(resolution) {
	  	var maxFeatureCount = 0;
		//var features = dislike_vector.getSource().getFeatures();
		var features = DisLikeLayer.getSource().getFeatures();
	  	var feature, radius;
	  	for (var i = features.length - 1; i >= 0; --i) {
			feature = features[i];
			var originalFeatures = feature.get('features');
			var extent = olExtent.createEmpty();
			var j, jj;
			var tmagnitude = [];
			for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
		  		olExtent.extend(extent, originalFeatures[j].getGeometry().getExtent());
				var tname = originalFeatures[j].get('name');
				tmagnitude.push(parseFloat(tname.substr(2)));
			}
			var maxFeatureCount = Math.max(maxFeatureCount, jj);
			var radius = 0.25 * (olExtent.getWidth(extent) + olExtent.getHeight(extent)) /
			resolution;
			feature.set('radius', radius);
			//feature.set('count_mag', jj);
		  	var total=0;
		  	var e;
		  	for(e = 0; e<tmagnitude.length;e++) { total += tmagnitude[e]; }
		  	feature.set('count_mag', e);
			feature.set('count_magnitude', total);
	  	}
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	var currentResolution;
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	function like_styleFunction(feature, resolution) {
		calculatelikeClusterInfo(resolution);
		var currentResolution = resolution;
	  	var style;
	  	var size = feature.get('features').length;
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
		var $magFil = [100, 149, 237];
		var $tempmag_count_text = 1;
		var $temp_mag_count = feature.get('count_mag');
		var $temp_magnitude_count = feature.get('count_magnitude');
		var $percent = calculateDisLikePercent(size);
		if($temp_magnitude_count){
			$tempmag_count_text = (feature.get('radius') * parseInt(5.1));
		}
	  	if (size > 1) {
			style = new Style({
			 	image: new CircleStyle({
					radius: feature.get('radius'),
					fill: new Fill({
				 		// color: [$magFil[0],$magFil[1],$magFil[2], Math.min(0.8, 0.4 + (size / maxFeatureCount))]
						color: [$magFil[0],$magFil[1],$magFil[2], 0.4]
					})
			  	}),
			  	text: new Text({
					text: $percent,
					//text: size.toString(),
					//text: $tempmag_count_text,
					fill: textFill,
					stroke: textStroke
			  	})
				});
		} else {
		 	style = new Style({
		  		image: new CircleStyle({
					radius: 5,
					fill: new Fill({
			  			color: [$magFil[0],$magFil[1],$magFil[2], 0.4]
					})
		  		}),
		  		text: new Text({
					text: $percent,
					//text: size.toString(),
					//text: $tempmag_count_text,
					fill: textFill,
					stroke: textStroke
		  		})
			});
	  	}
	  return style;
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	function dislike_styleFunction(feature, resolution) {
		calculatedislikeClusterInfo(resolution);
		var currentResolution = resolution;
	  	var style;
	  	var size = feature.get('features').length;
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
		var $magFil = [255, 153, 0];
		var $tempmag_count_text = 1;
		var $temp_mag_count = feature.get('count_mag');
		var $temp_magnitude_count = feature.get('count_magnitude');
		var $percent = calculateDisLikePercent(size);
		if($temp_magnitude_count){
			$tempmag_count_text = (feature.get('radius') * parseInt(5.1));
		}
	  	if (size > 1) {
			style = new Style({
		  		image: new CircleStyle({
					radius: feature.get('radius'),
					fill: new Fill({
			  			color: [$magFil[0],$magFil[1],$magFil[2], 0.4 ]
					})
				}),
		  		text: new Text({
					text: $percent,
					//text: size.toString(),
					//text: $tempmag_count_text,
					fill: textFill,
					stroke: textStroke
		  		})
			});
		} else {
		  	style = new Style({
		  		image: new CircleStyle({
					radius: 5,
					fill: new Fill({
			  			color: [$magFil[0],$magFil[1],$magFil[2], 0.7]
					})
				}),
		  		text: new Text({
					text: $percent,
					//text: size.toString(),
					//text: $tempmag_count_text,
					fill: textFill,
					stroke: textStroke
		  		})
			});
	  	}
	  return style;
	}
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    /* -------------------------------------------------------- */
    $("#map_openlayer").css({display: "none"});
    var x = document.getElementById("map_openlayer");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        /* --------------------------------------------------------  */        
        var map = new Map({
            layers: [rasterLayer],
            /*
            layers: [
              new TileLayer({
                source: new ol.source.OSM()
              })
            ],
            */
            controls: defaultControls({
                zoom: false,
                attribution: false,
                rotate: false,
            }),
            target: 'map_openlayer',
            //target: 'map',
            view: new View({
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 7,
                minZoom: 3,
                maxZoom: 11
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('activate_Stat_view_MGMT','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'activate_Stat_view_MGMT - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('activate_Stat_view_MGMT - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent = view.calculateExtent(map.getSize());
            if (!olExtent.containsExtent(worldExtent, extent)) {
                var newExtent = olExtent.getIntersection(extent, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
        });
        var projection = getProjection('EPSG:3857');
        var world_extent = projection.getWorldExtent();
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var template_feature_like_source = new VectorSource({
            url: '../mapping/TEMPLATE_LIKE.kml',
            format: new KML({
                extractStyles: false
            }),
        });
        var template_feature_dislike_source = new VectorSource({
            url: '../mapping/TEMPLATE_DISLIKE.kml',
            format: new KML({
                extractStyles: false
            }),
        });
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var cluster01 = new Cluster({
            source: template_feature_like_source,
            distance: parseInt(40),
            geometryFunction: function(feature) {
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var geom = feature.getGeometry();
                return geom.getType() == 'Point' ? geom : null;
            }
        });
        var cluster02 = new Cluster({
            source: template_feature_dislike_source,
            distance: parseInt(40),
            geometryFunction: function(feature) {
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var geom = feature.getGeometry();
                return geom.getType() == 'Point' ? geom : null;
            }
        });
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var LikeLayer = new VectorLayer({
            source: cluster01,
            //strategy: bbox,
            style: like_styleFunction
            /*
            style: function(feature) {
                var size = feature.get('features').length;
                var style = styleCache[size];
                if (!style) {

                    $MGMT = getLikeAttributes(size);
                    style = new Style({
                        image: new CircleStyle({
                        radius:$MGMT[0],
                        //radius: 10,
                        fill: new Fill({
                            color: $MGMT[1]
                            //color: [25,24,145, 0.6]
                        })
                      }),
                      text: new Text({
                        //text: size.toString(),
                        fill: textFill,
                        stroke: textStroke
                      })
                    });
                    styleCache[size] = style;
                }
                $SIZE = parseInt(0);
                return style;
            }
            */
        });
        var DisLikeLayer = new VectorLayer({
            source: cluster02,
            //strategy: bbox,
            style: dislike_styleFunction
            /*
            style: function(feature) {
                var size = feature.get('features').length;

                var style = styleCache[size];
                if (!style) {
                    $MGMT = getdisLikeAttributes(size);
                    style = new Style({
                        image: new CircleStyle({
                        radius:$MGMT[0],
                        //radius: 10,
                        fill: new Fill({
                            color: $MGMT[1]
                            //color: [25,24,145, 0.6]
                        })
                      }),
                      text: new Text({
                        //text: size.toString(),
                        fill: textFill,
                        stroke: textStroke
                      })
                    });
                    styleCache[size] = style;
                }
                $SIZE = parseInt(0);
                return style;
            }
            */
        });
        map.addLayer(LikeLayer);
        map.addLayer(DisLikeLayer);
        update_map_tools(map,LikeLayer);
    } else {
        x.style.display = "none";
    }
}/*END FUNCTION */
/* -------------------------------------------------------- */ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function multifunction_map_activate(){
	CONSOLE_MANAGER('multifunction_map_activate','ACTIVATED');
	windowload_script('multifunction_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('multifunction_map_activate');
		
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
			$('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
			$('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				//map_window_close(close_div_var='map_message_window_stucture');
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
				//window_complete_script('function:  -  ln:  '); 

			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
			$('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
			$('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				//windowUnload_script('function: multifunction_map_activate REGISTER_CLICK -  ln:  ');
				//Map_item_click_submit(event);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
				//map_window_close(close_div_var='map_message_window_stucture');

			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
			//))))OOOOO
			$('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
			$('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				//map_window_close(close_div_var='map_message_window_stucture');
				//window_complete_script('function:  -  ln:  '); 

			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var vectorLayerMapPath = new VectorLayer({
            source: initiatMapPins(),
        });	
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatMultiItemPins(),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,
            layers: [vectorLayerRegisterDates,vectorLayerMapPath],
            name: 'vector Group'
        });
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        /* --------------------------------------------------------  */
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 18,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 3,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('multifunction_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'multifunction_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('multifunction_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */

        });
        /* -------------------------------------------------------- */
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
    } else {
        x.style.display = "none";
    }
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_create_map_activate(){
    CONSOLE_MANAGER('event_create_map_activate','ACTIVATED');
	windowload_script('event_create_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('event_create_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventItemPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* --------------------------------------------------------  */
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        NOTIFICATIONSYSTEM_LOAD_MGMT($('#map'),null,null,null,null);
        var $INITAL_RENDER_CONTROL = false;
        /*
        map.on('singleclick', function(evt){
            z-index
        });
        */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_create_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                //map_key_activate(map,'event_create_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('event_create_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  
        $('#event_creator_options4').unbind().click(function($EVENT) {
           geocode(map);
        });
        $('#event_creator_options5').unbind().click(function($EVENT) {
            virtualcode(map);
        });
        */
        /* --------------------------------------------------------  */
        if(document.getElementById("event_creator_lat")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('event_creator_lat');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#event_creator_lat").val()){
                                CONSOLE_MANAGER('event_create_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('event_create_map_activate',mutation.type+': attributes');
                                    CenterMap($("#event_creator_lon").val(),$("#event_creator_lat").val(),12);
                                    AddFeature($("#event_creator_lon").val(),$("#event_creator_lat").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('event_create_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */		
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
    } else {
        x.style.display = "none";
    }
	/* ------------------------------------------------- */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
    var tempLayer = new VectorLayer({});
    /* ------------------------------------------------- */
    function AddFeature(long, lat) {//initiatEventItemPins
		var tempSource = new VectorSource({});
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
		map.removeLayer(tempLayer);
		/* ------------------------------------------------- */
		var $VERIFY = $("#event_creator_environmentOption").val();
		var $STATUS = $("#event_creator_eventStatus").val();
		var interval = null;
		/* ------------------------------------------------- */
		if($STATUS.indexOf("COMPLETE") > -1){
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 3,
                    stroke: new Stroke({
                        color: 'rgba(255,0,0, 0.7)',
                        width: 1,
                        //lineCap: 'round' ,
                        lineJoin: 'bevel' ,
                    }),
                    fill: new Fill({
                        color: 'rgba(239,239,239, 0.7)',
                        //color: $color,
                    })
                })
            }));
		}
		else if($STATUS.indexOf("PENDING") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							//color: 'rgba(239,45,239, 0.7)',
							color: 'rgba(255,255,0, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(255,255,0, 0.7)',
								//color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		else if($STATUS.indexOf("ACTIVE") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							color: 'rgba(239,45,239, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		var layersToRemove = [];
		map.getLayers().forEach(function (layer) {
			if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
				layersToRemove.push(layer);
			}
		});
		var len = layersToRemove.length;
		for(var i = 0; i < len; i++) {
			map.removeLayer(layersToRemove[i]);
		}
		tempLayer.setSource(tempSource);
        map.addLayer(tempLayer);
		tempLayer.set('name', 'tempLayer');
    }/*END FUNCTION */
    
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_create_pop_map_activate(){
    CONSOLE_MANAGER('event_create_pop_map_activate','ACTIVATED');
	windowload_script('event_create_pop_map_activate');
    /* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------click  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
        CONSOLE_MANAGER('event_create_pop_map_activate','REGISTER_CLICK - ACTIVATED');
        /*
		$('#search_request_control').val('event_create_pop_map_activate');
		
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
								  
		);
        */
	}
	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#eventsystem_div_01_map").css({display: "none"});
    var x = document.getElementById("eventsystem_div_01_map");
    if (x.style.display === "none") {
        
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventItemPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* -------------------------------------------------------- */	
        
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              //zoom: false,
              attribution: false,
              //rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('eventsystem_div_01_map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            //dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            //mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#eventsystem_div_01_map')){
           $('#eventsystem_div_01_map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#eventsystem_div_01_map').attr('id'));
        /* -------------------------------------------------------- */
        //NOTIFICATIONSYSTEM_LOAD_MGMT($('#eventsystem_div_01_map'),null,null,null,null);
        var $INITAL_RENDER_CONTROL = false;
        /*
        map.on('singleclick', function(evt){

        });
        */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_create_pop_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'event_create_pop_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('event_create_pop_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
            /* -------------------------------------------------------- */
            $( "#map_key_search_parameters" ).focusin(function() {
                var $autoObj0 = MGMT_google_autocomplete('map_key_search_parameters');
                $( ".pac-container" ).css('z-index', 3000);
            }); 

            if($('#event_creator_address').length){
                var $tempadd = $( "#event_creator_address" ).val();//.split(',')[0];
                $( "#map_key_search_parameters" ).val($tempadd);
                $( "#map_key_search_parameters" ).html($tempadd);
                //$("#map_key_search_parameters").focusin();
                //$("#map_key_search_parameters").keydown();
                //$("#map_key_search_parameters").focus().trigger('focusin');
                //$( "#event_creator_address" ).val($tempadd)
                //$('#map_key_search_parameters').val($('#event_creator_address').val());
                //$clear_val    Temp_CenterMap  map_key_search_parameters
            } 
            /* -------------------------------------------------------- event_creator_address    */
            if(document.getElementById("map_key_search_parameters")){
                var $autoObj1 = MGMT_google_autocomplete('map_key_search_parameters');
                $( "#map_key_search_parameters" ).keyup(function(e) {
                    $( ".pac-container" ).css('z-index', 3000);
                    //var codes = [9,13,32];
                    var codes = [32];
                    document.getElementById("map_key_search_parameters").addEventListener('keydown', function(e) {
                    if(!contains(codes, e.keyCode)) return;
                        var path = e.path || e.composedPath()
                        if(!path[0]) return;

                        e.preventDefault();
                        path[0].value += String.fromCharCode(e.keyCode);//
                        var $STRING=$('#map_key_search_parameters').val();
                        $('#map_key_search_parameters').val($STRING.replace(/\s{2,}/g, ' '));
                    })
                    function contains(arr, item) {
                        for(var i=0;i<arr.length;i++)
                        if(arr[i]==item)return true;
                        return false;
                    }
                    var $autoObj2 = MGMT_google_autocomplete('map_key_search_parameters');

                });
            }
        })
        /* --------------------------------------------------------  */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
        /* --------------------------------------------------------  */
        /* -------------------------------------------------------- */
        $('#eventsystem_div_01_search_address_div_option').unbind().click(function(event) {
            CONSOLE_MANAGER('eventsystem_div_01_search_address_div_option','RESET CLICK');
            //$('#map_key_search_parameters').html(' ');
            //$('#map_key_search_parameters').value(' ');
            //$('#event_creator_options15').html(null);
            //$('#event_creator_options15').value(null);
            //$('#event_creator_address').value('unregistered');
            //$('#event_creator_city').value('unregistered');
            //$('#event_creator_state').value('unregistered');
            //$('#event_creator_country').value('unregistered');
            //$('#event_creator_postal').value('unregistered');
        });
        /* --------------------------------------------------------  */
        /* -------------------------------------------------------- */
        $('#eventsystem_div_01_search_address_div_close').unbind().click(function(event) {
            CONSOLE_MANAGER('eventsystem_div_01_search_address_div_close','CLOSE CLICK');
            map_window_close('map_message_window_stucture');
            basic_window_close('basic_message_window_stucture');
            //window_view_close_button
            //$('#map_key_search_parameters').html(' ');
            //$('#map_key_search_parameters').value(' ');
            //$('#event_creator_options15').html(null);
            //$('#event_creator_options15').value(null);
            //$('#event_creator_address').value('unregistered');
            //$('#event_creator_city').value('unregistered');
            //$('#event_creator_state').value('unregistered');
            //$('#event_creator_country').value('unregistered');
            //$('#event_creator_postal').value('unregistered');
        });
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        /* --------------------------------------------------------  
        $('#event_creator_options4').unbind().click(function($EVENT) {
           geocode(map);
        });
        $('#event_creator_options5').unbind().click(function($EVENT) {
            virtualcode(map);
        });
        isjson setRegisterCode click
        */
        /* --------------------------------------------------------  */
        if(document.getElementById("event_creator_lat")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('event_creator_lat');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#event_creator_lat").val()){
                                CONSOLE_MANAGER('event_create_pop_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('event_create_pop_map_activate',mutation.type+': attributes');
                                    CenterMap($("#event_creator_lon").val(),$("#event_creator_lat").val(),12);
                                    AddFeature($("#event_creator_lon").val(),$("#event_creator_lat").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('event_create_pop_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */		
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
    } else {
        x.style.display = "none";
    }
	/* ------------------------------------------------- */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
    var tempLayer = new VectorLayer({});
    /* ------------------------------------------------- */
    function AddFeature(long, lat) {//initiatEventItemPins
		var tempSource = new VectorSource({});
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
		map.removeLayer(tempLayer);
		/* ------------------------------------------------- */
		var $VERIFY = $("#event_creator_environmentOption").val();
		var $STATUS = $("#event_creator_eventStatus").val();
		var interval = null;
		/* ------------------------------------------------- */
		if($STATUS.indexOf("COMPLETE") > -1){
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 3,
                    stroke: new Stroke({
                        color: 'rgba(255,0,0, 0.7)',
                        width: 1,
                        //lineCap: 'round' ,
                        lineJoin: 'bevel' ,
                    }),
                    fill: new Fill({
                        color: 'rgba(239,239,239, 0.7)',
                        //color: $color,
                    })
                })
            }));
		}
		else if($STATUS.indexOf("PENDING") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							//color: 'rgba(239,45,239, 0.7)',
							color: 'rgba(255,255,0, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(255,255,0, 0.7)',
								//color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		else if($STATUS.indexOf("ACTIVE") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							color: 'rgba(239,45,239, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		var layersToRemove = [];
		map.getLayers().forEach(function (layer) {
			if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
				layersToRemove.push(layer);
			}
		});
		var len = layersToRemove.length;
		for(var i = 0; i < len; i++) {
			map.removeLayer(layersToRemove[i]);
		}
		tempLayer.setSource(tempSource);
        map.addLayer(tempLayer);
		tempLayer.set('name', 'tempLayer');
    }/*END FUNCTION */
    
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_create_pop_manager_map_activate(){
    CONSOLE_MANAGER('event_create_pop_manager_map_activate','ACTIVATED');
	windowload_script('event_create_pop_manager_map_activate');
    /* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------click  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('event_create_pop_manager_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#view_map").css({display: "none"});
    var x = document.getElementById("view_map");
    if (x.style.display === "none") {
        
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventItemPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              //zoom: false,
              attribution: false,
              //rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('view_map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            //dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            //mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        $('#view_map').data('map', map);
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#view_map').attr('id'));
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        //NOTIFICATIONSYSTEM_LOAD_MGMT($('#view_map'),null,null,null,null);
        var $INITAL_RENDER_CONTROL = false;
        /*
        map.on('singleclick', function(evt){

        });
        */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_create_pop_manager_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'event_create_pop_manager_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('event_create_pop_manager_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
            /* -------------------------------------------------------- */
            $( "#map_key_search_parameters" ).focusin(function() {
                var $autoObj0 = MGMT_google_autocomplete('map_key_search_parameters');
                $( ".pac-container" ).css('z-index', 3000);
            }); 

            if($('#event_creator_address').length){
                var $tempadd = $( "#event_creator_address" ).val();//.split(',')[0];
                $( "#map_key_search_parameters" ).val($tempadd);
                $( "#map_key_search_parameters" ).html($tempadd);
                //$("#map_key_search_parameters").focusin();
                //$("#map_key_search_parameters").keydown();
                //$("#map_key_search_parameters").focus().trigger('focusin');
                //$( "#event_creator_address" ).val($tempadd)
                //$('#map_key_search_parameters').val($('#event_creator_address').val());
                //$clear_val    Temp_CenterMap  map_key_search_parameters
            } 
            /* -------------------------------------------------------- event_creator_address    */
            if(document.getElementById("map_key_search_parameters")){
                var $autoObj1 = MGMT_google_autocomplete('map_key_search_parameters');
                $( "#map_key_search_parameters" ).keyup(function(e) {
                    $( ".pac-container" ).css('z-index', 3000);
                    //var codes = [9,13,32];
                    var codes = [32];
                    document.getElementById("map_key_search_parameters").addEventListener('keydown', function(e) {
                    if(!contains(codes, e.keyCode)) return;
                        var path = e.path || e.composedPath()
                        if(!path[0]) return;

                        e.preventDefault();
                        path[0].value += String.fromCharCode(e.keyCode);//
                        var $STRING=$('#map_key_search_parameters').val();
                        $('#map_key_search_parameters').val($STRING.replace(/\s{2,}/g, ' '));
                    })
                    function contains(arr, item) {
                        for(var i=0;i<arr.length;i++)
                        if(arr[i]==item)return true;
                        return false;
                    }
                    var $autoObj2 = MGMT_google_autocomplete('map_key_search_parameters');
                });
            }
        })
        /* --------------------------------------------------------  
        var highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(255,255,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        var selected_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgb(0,191,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        const rollover_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(0,255,0, 0.3)',
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3,
            }),
        });
        */
        /* --------------------------------------------------------  */
        /*
            id: [$DATES_data_array[i].getUniqEventId],
            registerCode: [$DATES_data_array[i].getRegisterCode],
            name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
            geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))

        */
        map.on('singleclick', function(e) {
            //CONSOLE_MANAGER('map','singleclick');
            $('#event_edit_pop_map_div1_2_1').hide();
            map.forEachFeatureAtPixel(e.pixel, function(f) {
                displayFeatureInfo(e.pixel);
                //REGISTER_CLICK('ACTIVE',e,e.pixel);
            });
        });
        var displayFeatureInfo = function(pixel) {
            var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
                return feature;
            });
            if (feature) {
                //CONSOLE_MANAGER('feature',pixel);
                $('#event_edit_pop_map_div1_2_1').show();
                var $GEO = feature.get('geometry');
                var $ID = feature.get('id');
                var $REGCODE = feature.get('registerCode');
                var $LAT = feature.get('lat');
                var $LON = feature.get('lon');
                /* --------------------------------------------------------  
                const source = new VectorSource({
                  wrapX: false,
                });
                const vector = new VectorLayer({
                  source: source,
                });

                function addRandomFeature() {
                  const geom = new Point(fromLonLat([$LON, $LAT]));
                  const feature = new Feature(geom);
                  source.addFeature(feature);
                }

                const duration = 3000;
                function flash(feature) {
                  const start = Date.now();
                  const flashGeom = feature.getGeometry().clone();
                  const listenerKey = tileLayer.on('postrender', animate);

                  function animate(event) {
                    const frameState = event.frameState;
                    const elapsed = frameState.time - start;
                    if (elapsed >= duration) {
                      unByKey(listenerKey);
                      return;
                    }
                    const vectorContext = getVectorContext(event);
                    const elapsedRatio = elapsed / duration;
                    // radius will be 5 at start and 30 at end.
                    const radius = easeOut(elapsedRatio) * 25 + 5;
                    const opacity = easeOut(1 - elapsedRatio);

                    const style = new Style({
                      image: new CircleStyle({
                        radius: radius,
                        stroke: new Stroke({
                          color: 'rgba(255, 0, 0, ' + opacity + ')',
                          width: 0.25 + opacity,
                        }),
                      }),
                    });

                    vectorContext.setStyle(style);
                    vectorContext.drawGeometry(flashGeom);
                    // tell OpenLayers to continue postrender animation
                    map.render();
                  }
                }

                source.on('addfeature', function (e) {
                  flash(e.feature);
                });
                addRandomFeature;
                //window.setInterval(addRandomFeature, 1000);
                */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_1_status");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_1_title");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_2_time");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address1");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address2");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address3");
                $('#event_edit_pop_map_div1_2_1_status,#event_edit_pop_map_div1_2_1_title,#event_edit_pop_map_div1_2_2_time,#event_edit_pop_map_div1_2_3_address1,#event_edit_pop_map_div1_2_3_address2  ,#event_edit_pop_map_div1_2_3_address3').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_1_status').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_1_status').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                /* --------------------------------------------------------  
                $('#event_edit_pop_map_div1_2_1_title').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_1_title').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_1_title').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_2_time').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_2_time').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_2_time').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address1').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address1').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address1').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address2').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address2').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address2').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options2'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address3').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address3').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address3').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options3'),event)
                });*/
                /* --------------------------------------------------------  */
                //CONSOLE_MANAGER('map','Event registerCode '+$REGCODE);
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#event_creator_formCoords').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#event_creator_formCoords').val());
                    if($DATES_obj_array != null){
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.getRegisterCode != "undefined"){
                                        if( element.getRegisterCode == $REGCODE){
                                            CONSOLE_MANAGER('map','Event registerCode in JSON: '+$REGCODE);
                                            CONSOLE_MANAGER('map','Event LAT: '+element.getLat+' LON: '+element.getLon);
                                            CONSOLE_MANAGER('map','Event THEME: '+element.getEventTheme);
                                            /* --------------------------------------------------------  */
                                            map.getView().setZoom(16);
                                            map.getView().animate({
                                                center: map.getView().setCenter(transform([element.getLon, element.getLat], 'EPSG:4326', 'EPSG:3857')),
                                                duration: 2000
                                            });
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_1_status').val(element.EVENT_STATUS);
                                            $('#event_edit_pop_map_div1_2_1_status').html(element.EVENT_STATUS);
                                            $('#event_edit_pop_map_div1_2_1_title').val(element.getTitle);
                                            $('#event_edit_pop_map_div1_2_1_title').html(element.getTitle);
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_2_time').val(element.getStartDate);
                                            $('#event_edit_pop_map_div1_2_2_time').html(element.getStartDate);
                                            //$('#event_edit_pop_map_div1_2_1_title').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_1_title').html(element.getTitle);
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_3_address1').val(element.getCity);
                                            $('#event_edit_pop_map_div1_2_3_address1').html(element.getCity);
                                            //$('#event_edit_pop_map_div1_2_3_address2').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address2').html(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address3').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address3').html(element.getTitle);flash

                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            } else {
                //info.innerHTML = '&nbsp;';

            }
        }
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
        /* --------------------------------------------------------  */
        /* -------------------------------------------------------- */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */		
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
        /* --------------------------------------------------------  */
        if(document.getElementById("event_creator_lat")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('event_creator_lat');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#event_creator_lat").val()){
                                CONSOLE_MANAGER('event_create_pop_manager_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('event_create_pop_manager_map_activate',mutation.type+': attributes');
                                    CenterMap($("#event_creator_lon").val(),$("#event_creator_lat").val(),12);
                                    AddFeature($("#event_creator_lon").val(),$("#event_creator_lat").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('event_create_pop_manager_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
    function REGISTER_CLICK($STATUS,event,coords){
        CONSOLE_MANAGER('REGISTER_CLICK','coords: '+coords);
        /*
		windowload_script('map_identity_event_control Initiate_coord');
		$('#standard_entity_modify_uniqEventId').val($('#event_locations_menu_uniqEventId').val());
        $('#standard_entity_modify_registerCode').val($('#event_locations_menu_registerCode').val());
		$('#standard_entity_modify_formCoords').val(coords);
        $('#standard_entity_modify_control').val($('#event_locations_menu_control').val());
		$('#standard_entity_modify_formCurrentIndex').val($('#event_locations_menu_formCurrentIndex').val());
		$('#standard_entity_modify_index1').val(coords);
        $('#standard_entity_modify_formFilter1').val($STATUS);
        settings_request_function(event);
		if($STATUS=='DISABLE'){
			find_update_key($('#event_locations_menu_messagedisplayinput').val());
		}
        */
    }
	/* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    var AJAX_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    CONSOLE_MANAGER('map_identity_event_control','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                    var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                    if($("#standard_results_ajax_control").val()){
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': attributes');
                            $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                            if($('#search_request_control').length){
                               $SEARCH_CNTRL = $('#search_request_control').val();
                            }
                            if(($('#standard_results_ajax_control').val())){
                                /* -------------------------------------------------------- */
                                if($('#standard_results_ajax_control').val()==$('#event_locations_menu_control').val()){
                                    $('#event_locations_menu_identityCountryCoords').val($('#standard_results_ajax_entitylistMgmt').val());
                                    var arr = [];
                                    var $DATES_obj_array = JSON.parse($('#standard_results_ajax_entitytypeMgmt').val());
                                    if(Object.keys($DATES_obj_array).length >0){
                                        var $COUNTRY_COUNT, $STATE_COUNT, $CITY_COUNT, $COUNTY_COUNT = parseInt(0);
                                        var $COUNTRY, $STATE, $CITY, $COUNTY = [];
                                        $COUNTRY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTRY_COUNT);
                                        $STATE_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_STATE_COUNT);
                                        $CITY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_CITY_COUNT);
                                        $COUNTY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTY_COUNT);

                                        $COUNTRY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTRY;
                                        $STATE=$DATES_obj_array.COMPLIANCE_RANGE_STATE;
                                        $CITY=$DATES_obj_array.COMPLIANCE_RANGE_CITY;
                                        $COUNTY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTY;
                                        $('#event_locations_menu_options5active').html($COUNTRY_COUNT);
                                        $('#event_locations_menu_options6').html(null);
                                        $('#event_locations_menu_options6').html($COUNTRY);

                                        $('#event_locations_menu_options7active').html($STATE_COUNT);
                                        $('#event_locations_menu_options8').html(null);
                                        $('#event_locations_menu_options8').html($STATE);

                                        $('#event_locations_menu_options9active').html($CITY_COUNT);
                                        $('#event_locations_menu_options10').html(null);
                                        $('#event_locations_menu_options10').html($CITY);

                                        $('#event_locations_menu_options11active').html($COUNTY_COUNT);
                                        $('#event_locations_menu_options12').html(null);
                                        $('#event_locations_menu_options12').html($COUNTY);
                                    }
                                }
                            }
                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': childList');
                            /* --------------------------------------------------------  */
                        } 
                        translate_ajax_clear(true);
                    }
                    $RESULTS_CNTRL,$SEARCH_CNTRL = null;
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
	/* --------------------------------------------------------  
    $('#event_creator_options4').unbind().click(function($EVENT) {
       geocode(map);
    });
    $('#event_creator_options5').unbind().click(function($EVENT) {
       	virtualcode(map);
    });
    */
	/* ------------------------------------------------- */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
    var tempLayer = new VectorLayer({});
    /* ------------------------------------------------- */
    function AddFeature(long, lat) {//initiatEventItemPins
		var tempSource = new VectorSource({});
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
		map.removeLayer(tempLayer);
		/* ------------------------------------------------- */
		var $VERIFY = $("#event_creator_environmentOption").val();
		var $STATUS = $("#event_creator_eventStatus").val();
		var interval = null;
		/* ------------------------------------------------- */
		if($STATUS.indexOf("COMPLETE") > -1){
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 3,
                    stroke: new Stroke({
                        color: 'rgba(255,0,0, 0.7)',
                        width: 1,
                        //lineCap: 'round' ,
                        lineJoin: 'bevel' ,
                    }),
                    fill: new Fill({
                        //color: 'rgba(239,239,239, 0.7)',
                        color: $color,
                    })
                })
            }));
		}
		else if($STATUS.indexOf("PENDING") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							//color: 'rgba(239,45,239, 0.7)',
							color: 'rgba(255,255,0, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(255,255,0, 0.7)',
								//color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		else if($STATUS.indexOf("ACTIVE") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							color: 'rgba(239,45,239, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- 
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    */
				}
			}
		}
		var layersToRemove = [];
		map.getLayers().forEach(function (layer) {
			if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
				layersToRemove.push(layer);
			}
		});
		var len = layersToRemove.length;
		for(var i = 0; i < len; i++) {
			map.removeLayer(layersToRemove[i]);
		}
		tempLayer.setSource(tempSource);
        map.addLayer(tempLayer);
		tempLayer.set('name', 'tempLayer');
    }/*END FUNCTION */
    
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_profile_pop_manager_map_activate(){
    CONSOLE_MANAGER('event_profile_pop_manager_map_activate','ACTIVATED');
	windowload_script('event_profile_pop_manager_map_activate');
    /* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------click  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('event_profile_pop_manager_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#view_map").css({display: "none"});
    var x = document.getElementById("view_map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventPins(rasterLayer),		//	initiatEventItemPins
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* -------------------------------------------------------- */	
        
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              //zoom: false,
              attribution: false,
              //rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('view_map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
                multiWorld: true,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            //dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            //mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        $('#view_map').data('map', map);
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#view_map').attr('id'));
        /* -------------------------------------------------------- */
        //NOTIFICATIONSYSTEM_LOAD_MGMT($('#view_map'),null,null,null,null);
        var $INITAL_RENDER_CONTROL = false;
        /*
        map.on('singleclick', function(evt){

        });
        */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_profile_pop_manager_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'event_profile_pop_manager_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('event_profile_pop_manager_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
            /* -------------------------------------------------------- 
            $( "#map_key_search_parameters" ).focusin(function() {
                var $autoObj0 = MGMT_google_autocomplete('map_key_search_parameters');
                $( ".pac-container" ).css('z-index', 3000);
            }); 
            */
            /*
            if($('#event_creator_address').length){
                var $tempadd = $( "#event_creator_address" ).val();//.split(',')[0];
                $( "#map_key_search_parameters" ).val($tempadd);
                $( "#map_key_search_parameters" ).html($tempadd);
                //$("#map_key_search_parameters").focusin();
                //$("#map_key_search_parameters").keydown();
                //$("#map_key_search_parameters").focus().trigger('focusin');
                //$( "#event_creator_address" ).val($tempadd)
                //$('#map_key_search_parameters').val($('#event_creator_address').val());
                //$clear_val    Temp_CenterMap  map_key_search_parameters
            } 
            */
            /* --------------------------------------------------------  
            if(document.getElementById("map_key_search_parameters")){
                var $autoObj1 = MGMT_google_autocomplete('map_key_search_parameters');
                $( "#map_key_search_parameters" ).keyup(function(e) {
                    $( ".pac-container" ).css('z-index', 3000);
                    //var codes = [9,13,32];
                    var codes = [32];
                    document.getElementById("map_key_search_parameters").addEventListener('keydown', function(e) {
                    if(!contains(codes, e.keyCode)) return;
                        var path = e.path || e.composedPath()
                        if(!path[0]) return;

                        e.preventDefault();
                        path[0].value += String.fromCharCode(e.keyCode);//
                        var $STRING=$('#map_key_search_parameters').val();
                        $('#map_key_search_parameters').val($STRING.replace(/\s{2,}/g, ' '));
                    })
                    function contains(arr, item) {
                        for(var i=0;i<arr.length;i++)
                        if(arr[i]==item)return true;
                        return false;
                    }
                    var $autoObj2 = MGMT_google_autocomplete('map_key_search_parameters');

                });
            }
            */
        })
        /* --------------------------------------------------------  
        var highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(255,255,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        var selected_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgb(0,191,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        const rollover_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(0,255,0, 0.3)',
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3,
            }),
        });
        */
        /* --------------------------------------------------------  */
        /*
            id: [$DATES_data_array[i].getUniqEventId],
            registerCode: [$DATES_data_array[i].getRegisterCode],
            name: [$DATES_data_array[i].getUniqEventId,$DATES_data_array[i].getRegisterCode],
            geometry: new Point(transform(pfeatures[i], 'EPSG:4326','EPSG:3857'))

        */
        map.on('singleclick', function(e) {
            //CONSOLE_MANAGER('map','singleclick');
            $('#event_edit_pop_map_div1_2_1').hide();
            map.forEachFeatureAtPixel(e.pixel, function(f) {
                displayFeatureInfo(e.pixel);
                //REGISTER_CLICK('ACTIVE',e,e.pixel);
            });
        });
        var displayFeatureInfo = function(pixel) {
            var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
                return feature;
            });
            if (feature) {
                //CONSOLE_MANAGER('feature',pixel);
                $('#event_edit_pop_map_div1_2_1').show();
                var $GEO = feature.get('geometry');
                var $ID = feature.get('id');
                var $REGCODE = feature.get('registerCode');
                var $LAT = feature.get('lat');
                var $LON = feature.get('lon');
                /* --------------------------------------------------------  
                const source = new VectorSource({
                  wrapX: false,
                });
                const vector = new VectorLayer({
                  source: source,
                });

                function addRandomFeature() {
                  const geom = new Point(fromLonLat([$LON, $LAT]));
                  const feature = new Feature(geom);
                  source.addFeature(feature);
                }

                const duration = 3000;
                function flash(feature) {
                  const start = Date.now();
                  const flashGeom = feature.getGeometry().clone();
                  const listenerKey = tileLayer.on('postrender', animate);

                  function animate(event) {
                    const frameState = event.frameState;
                    const elapsed = frameState.time - start;
                    if (elapsed >= duration) {
                      unByKey(listenerKey);
                      return;
                    }
                    const vectorContext = getVectorContext(event);
                    const elapsedRatio = elapsed / duration;
                    // radius will be 5 at start and 30 at end.
                    const radius = easeOut(elapsedRatio) * 25 + 5;
                    const opacity = easeOut(1 - elapsedRatio);

                    const style = new Style({
                      image: new CircleStyle({
                        radius: radius,
                        stroke: new Stroke({
                          color: 'rgba(255, 0, 0, ' + opacity + ')',
                          width: 0.25 + opacity,
                        }),
                      }),
                    });

                    vectorContext.setStyle(style);
                    vectorContext.drawGeometry(flashGeom);
                    // tell OpenLayers to continue postrender animation
                    map.render();
                  }
                }

                source.on('addfeature', function (e) {
                  flash(e.feature);
                });
                addRandomFeature;
                //window.setInterval(addRandomFeature, 1000);
                */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_1_status");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_1_title");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_2_time");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address1");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address2");
                SET_BUTTON_CLICK_ACTION("event_edit_pop_map_div1_2_3_address3");
                $('#event_edit_pop_map_div1_2_1_status,#event_edit_pop_map_div1_2_1_title,#event_edit_pop_map_div1_2_2_time,#event_edit_pop_map_div1_2_3_address1,#event_edit_pop_map_div1_2_3_address2  ,#event_edit_pop_map_div1_2_3_address3').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_1_status').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_1_status').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                /* --------------------------------------------------------  
                $('#event_edit_pop_map_div1_2_1_title').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_1_title').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_1_title').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_2_time').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_2_time').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_2_time').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address1').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address1').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address1').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address2').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address2').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address2').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options2'),event)
                });
                $('#event_edit_pop_map_div1_2_3_address3').unbind().click(function(event) {
                    event.stopPropagation;
                        $('#standard_entity_modify_index1').val($ID);
                        $('#standard_entity_modify_index2').val($ID);
                        $('#standard_entity_modify_index3').val($REGCODE);
                        $('#standard_entity_modify_index7').val($('#event_edit_pop_map_div1_2_3_address3').attr('id'));
                        $('#standard_entity_modify_control').val($('#event_edit_pop_map_div1_2_3_address3').attr('id'));
                        standard_entity_modify_submit_MGMT($('#standard_entity_modify_options3'),event)
                });*/
                /* --------------------------------------------------------  */
                //CONSOLE_MANAGER('map','Event registerCode '+$REGCODE);
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_general_formCoords').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_general_formCoords').val());
                    if($DATES_obj_array != null){
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.getRegisterCode != "undefined"){
                                        if( element.getRegisterCode == $REGCODE){
                                            CONSOLE_MANAGER('map','Event registerCode in JSON: '+$REGCODE);
                                            CONSOLE_MANAGER('map','Event LAT: '+element.getLat+' LON: '+element.getLon);
                                            CONSOLE_MANAGER('map','Event THEME: '+element.getEventTheme);
                                            /* --------------------------------------------------------  */
                                            map.getView().setZoom(16);
                                            map.getView().animate({
                                                center: map.getView().setCenter(transform([element.getLon, element.getLat], 'EPSG:4326', 'EPSG:3857')),
                                                duration: 2000
                                            });
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_1_status').val(element.EVENT_STATUS);
                                            $('#event_edit_pop_map_div1_2_1_status').html(element.EVENT_STATUS);
                                            $('#event_edit_pop_map_div1_2_1_title').val(element.getTitle);
                                            $('#event_edit_pop_map_div1_2_1_title').html(element.getTitle);
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_2_time').val(element.getStartDate);
                                            $('#event_edit_pop_map_div1_2_2_time').html(element.getStartDate);
                                            //$('#event_edit_pop_map_div1_2_1_title').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_1_title').html(element.getTitle);
                                            /* --------------------------------------------------------  */
                                            $('#event_edit_pop_map_div1_2_3_address1').val(element.getCity);
                                            $('#event_edit_pop_map_div1_2_3_address1').html(element.getCity);
                                            //$('#event_edit_pop_map_div1_2_3_address2').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address2').html(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address3').val(element.getTitle);
                                            //$('#event_edit_pop_map_div1_2_3_address3').html(element.getTitle);flash

                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            } else {
                //info.innerHTML = '&nbsp;';

            }
        }
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
        /* ---micro_map_activate-----------------------------------------------------  */
        /* -------------------------------------------------------- */
        /* --------------------------------------------------------  */
        if(document.getElementById("settings_event_general_lat")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('settings_event_general_lat');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#settings_event_general_lat").val()){
                                CONSOLE_MANAGER('event_profile_pop_manager_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('event_profile_pop_manager_map_activate',mutation.type+': attributes');
                                    CenterMap($("#settings_event_general_lon").val(),$("#settings_event_general_lat").val(),12);
                                    AddFeature($("#settings_event_general_lon").val(),$("#settings_event_general_lat").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('event_profile_pop_manager_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */		
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
    function REGISTER_CLICK($STATUS,event,coords){
        CONSOLE_MANAGER('REGISTER_CLICK','coords: '+coords);
        /*
		windowload_script('map_identity_event_control Initiate_coord');
		$('#standard_entity_modify_uniqEventId').val($('#event_locations_menu_uniqEventId').val());
        $('#standard_entity_modify_registerCode').val($('#event_locations_menu_registerCode').val());
		$('#standard_entity_modify_formCoords').val(coords);
        $('#standard_entity_modify_control').val($('#event_locations_menu_control').val());
		$('#standard_entity_modify_formCurrentIndex').val($('#event_locations_menu_formCurrentIndex').val());
		$('#standard_entity_modify_index1').val(coords);
        $('#standard_entity_modify_formFilter1').val($STATUS);
        settings_request_function(event);
		if($STATUS=='DISABLE'){
			find_update_key($('#event_locations_menu_messagedisplayinput').val());
		}
        */
    }
	/* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    var AJAX_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    CONSOLE_MANAGER('map_identity_event_control','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                    var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                    if($("#standard_results_ajax_control").val()){
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': attributes');
                            $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                            if($('#search_request_control').length){
                               $SEARCH_CNTRL = $('#search_request_control').val();
                            }
                            if(($('#standard_results_ajax_control').val())){
                                /* -------------------------------------------------------- */
                                if($('#standard_results_ajax_control').val()==$('#event_locations_menu_control').val()){
                                    $('#event_locations_menu_identityCountryCoords').val($('#standard_results_ajax_entitylistMgmt').val());
                                    var arr = [];
                                    var $DATES_obj_array = JSON.parse($('#standard_results_ajax_entitytypeMgmt').val());
                                    if(Object.keys($DATES_obj_array).length >0){
                                        var $COUNTRY_COUNT, $STATE_COUNT, $CITY_COUNT, $COUNTY_COUNT = parseInt(0);
                                        var $COUNTRY, $STATE, $CITY, $COUNTY = [];
                                        $COUNTRY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTRY_COUNT);
                                        $STATE_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_STATE_COUNT);
                                        $CITY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_CITY_COUNT);
                                        $COUNTY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTY_COUNT);

                                        $COUNTRY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTRY;
                                        $STATE=$DATES_obj_array.COMPLIANCE_RANGE_STATE;
                                        $CITY=$DATES_obj_array.COMPLIANCE_RANGE_CITY;
                                        $COUNTY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTY;
                                        $('#event_locations_menu_options5active').html($COUNTRY_COUNT);
                                        $('#event_locations_menu_options6').html(null);
                                        $('#event_locations_menu_options6').html($COUNTRY);

                                        $('#event_locations_menu_options7active').html($STATE_COUNT);
                                        $('#event_locations_menu_options8').html(null);
                                        $('#event_locations_menu_options8').html($STATE);

                                        $('#event_locations_menu_options9active').html($CITY_COUNT);
                                        $('#event_locations_menu_options10').html(null);
                                        $('#event_locations_menu_options10').html($CITY);

                                        $('#event_locations_menu_options11active').html($COUNTY_COUNT);
                                        $('#event_locations_menu_options12').html(null);
                                        $('#event_locations_menu_options12').html($COUNTY);
                                    }
                                }
                            }
                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': childList');
                            /* --------------------------------------------------------  */
                        } 
                        translate_ajax_clear(true);
                    }
                    $RESULTS_CNTRL,$SEARCH_CNTRL = null;
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
	/* --------------------------------------------------------  
    $('#event_creator_options4').unbind().click(function($EVENT) {
       geocode(map);
    });
    $('#event_creator_options5').unbind().click(function($EVENT) {
       	virtualcode(map);
    });
    */
	/* ------------------------------------------------- */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
    var tempLayer = new VectorLayer({});
    /* ------------------------------------------------- 
    function AddFeature(long, lat) {//initiatEventItemPins
		var tempSource = new VectorSource({});
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
		map.removeLayer(tempLayer);
		
		var $VERIFY = $("#settings_event_general_environmentOption").val();
		var $STATUS = $("#event_creator_eventStatus").val();
		var interval = null;
		
		if($STATUS.indexOf("COMPLETE") > -1){
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 3,
                    stroke: new Stroke({
                        color: 'rgba(255,0,0, 0.7)',
                        width: 1,
                        //lineCap: 'round' ,
                        lineJoin: 'bevel' ,
                    }),
                    fill: new Fill({
                        //color: 'rgba(239,239,239, 0.7)',
                        color: $color,
                    })
                })
            }));
		}
		else if($STATUS.indexOf("PENDING") > -1){
			
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							//color: 'rgba(239,45,239, 0.7)',
							color: 'rgba(255,255,0, 0.7)',
							width: 2,
							//lineCap: 'round' ,
							lineJoin: 'bevel' ,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(255,255,0, 0.7)',
								//color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' ,
								lineJoin: 'bevel' ,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' ,
								lineJoin: 'bevel' ,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					
					var duration = 3000;
					function flash(feature) {
						var start = new Date().getTime();
						var listenerKey = rasterLayer.on('postrender', animate);

						function animate(event) {
							var vectorContext = getVectorContext(event);
							var frameState = event.frameState;
							var flashGeom = feature.getGeometry().clone();
							var elapsed = frameState.time - start;
							var elapsedRatio = elapsed / duration;
							// radius will be 5 at start and 30 at end.
							var radius = easeOut(elapsedRatio) * 25 + 5;
							var opacity = easeOut(1 - elapsedRatio);

							var style = new Style({
								image: new CircleStyle({
									radius: radius,
									stroke: new Stroke({
										//color: 'rgba(255, 0, 0, ' + opacity + ')',
										color: 'rgba(0, 255 , 0, ' + opacity + ')',
									width: 0.25 + opacity
									})
								})
							});
							vectorContext.setStyle(style);
							vectorContext.drawGeometry(flashGeom);

							if (elapsed > duration) {
							  unByKey(listenerKey);
							  return;
							}
						// tell OpenLayers to continue postrender animation
							map.render();
						}
					}
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
				}
			}
		}
		else if($STATUS.indexOf("ACTIVE") > -1){
			
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							color: 'rgba(239,45,239, 0.7)',
							width: 2,
							//lineCap: 'round' ,
							lineJoin: 'bevel' ,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' ,
								lineJoin: 'bevel' ,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' ,
								lineJoin: 'bevel' ,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					var duration = 3000;
					function flash(feature) {
						var start = new Date().getTime();
						var listenerKey = rasterLayer.on('postrender', animate);

						function animate(event) {
							var vectorContext = getVectorContext(event);
							var frameState = event.frameState;
							var flashGeom = feature.getGeometry().clone();
							var elapsed = frameState.time - start;
							var elapsedRatio = elapsed / duration;
							// radius will be 5 at start and 30 at end.
							var radius = easeOut(elapsedRatio) * 25 + 5;
							var opacity = easeOut(1 - elapsedRatio);

							var style = new Style({
								image: new CircleStyle({
									radius: radius,
									stroke: new Stroke({
										//color: 'rgba(255, 0, 0, ' + opacity + ')',
										color: 'rgba(0, 255 , 0, ' + opacity + ')',
									width: 0.25 + opacity
									})
								})
							});
							vectorContext.setStyle(style);
							vectorContext.drawGeometry(flashGeom);

							if (elapsed > duration) {
							  unByKey(listenerKey);
							  return;
							}
						// tell OpenLayers to continue postrender animation
							map.render();
						}
					}
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
				}
			}
		}
		var layersToRemove = [];
		map.getLayers().forEach(function (layer) {
			if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
				layersToRemove.push(layer);
			}
		});
		var len = layersToRemove.length;
		for(var i = 0; i < len; i++) {
			map.removeLayer(layersToRemove[i]);
		}
		tempLayer.setSource(tempSource);
        map.addLayer(tempLayer);
		tempLayer.set('name', 'tempLayer');
    } END FUNCTION */
    
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function micro_map_activate(){
    CONSOLE_MANAGER('micro_map_activate','ACTIVATED');
	windowload_script('micro_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('micro_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);

			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;
			}													  
		);
	}
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    CONSOLE_MANAGER('micro_map_activate','x.style.display = '+x.style.display);
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatMicroPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* -------------------------------------------------------- */	
        //terrain watercolor toner	toner-lite
        /* -------------------------------------------------------- */
    
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'), 
            //target: (document.getElementById('map')) ? document.getElementById('settings_event_description_edit_options14') : document.getElementById('map'),
            //target: document.getElementById('settings_event_description_edit_options14'),
            view: new View({
                //minZoom: 9,
                //maxZoom: 14,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('micro_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                window_complete_script('micro_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* --------------------------------------------------------  */
        map.on('singleclick', function(evt){
            $('#event_menu01_options1').unbind().click();
        });
        /* -------------------------------------------------------- */
        if($('#settings_event_general_formCoords').val()){
            $DATES_obj_array = JSON.parse($('#settings_event_general_formCoords').val());
            $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
            if($DATES_obj_array.length > 0){
                var $DATES_data_array = [];
                $DATES_obj_array.forEach((element, index, array) => {
                    if(element){
                        if(typeof element.getUniqEventId != "undefined"){
                            if(element.getEnvironmentOption == 'PHYSICAL'){
                                //CenterMap(element.getLon, element.getLon, 14);
                                map.getView().setZoom(7);
                            }
                        }
                        if(typeof element.getUniqEventId != "undefined"){
                            if(element.getEnvironmentOption == 'VIRTUAL'){
                                //CenterMap(element.getLon, element.getLon, 9);
                                map.getView().setZoom(7);
                            }
                        }
                    }
                });
            }
        }
         /* -------------------------------------------------------- */
        if($('#business_settings_formCoords').val()){
            $DATES_obj_array = JSON.parse($('#business_settings_formCoords').val());
            $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
            if($DATES_obj_array.length > 0){
                var $DATES_data_array = [];
                $DATES_obj_array.forEach((element, index, array) => {
                    if(element){
                        if(typeof element.getUniqMemId != "undefined"){
                            if(element.getEnvironmentOption == 'PHYSICAL'){
                                //CenterMap(element.getLon, element.getLon, 14);
                                map.getView().setZoom(13);
                            }
                        }
                        if(typeof element.getUniqMemId != "undefined"){
                            if(element.getEnvironmentOption == 'VIRTUAL'){
                               //	CenterMap(element.getLon, element.getLon, 9);
                                map.getView().setZoom(7);
                            }
                        }
                    }
                });
            }
        }
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function description_micro_map_activate(){
    CONSOLE_MANAGER('description_micro_map_activate','ACTIVATED');
	windowload_script('description_micro_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('description_micro_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);

			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;
			}													  
		);
	}
	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#settings_event_description_edit_options13").css({display: "none"});
    if($("#settings_event_description_edit_options13").is(":visible")){
        var x = document.getElementById("settings_event_description_edit_options13");
        if (x.style.display === "none") {
            /* --------------------------------------------------------  
            var rasterLayer = new TileLayer({
                source: new Stamen({
                    layer: 'toner-lite'
                })
            });
            */
            var rasterLayer = new TileLayer({
                source: new StadiaMaps({
                    layer: 'stamen_toner_lite',
                    retina: true,
                })
            });
            /* --------------------------------------------------------*/
            var vectorLayerRegisterDates = new VectorLayer({
                source: initiatMicroPins(rasterLayer),			
            });
            /* --------------------------------------------------------*/
            var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
                layers: [vectorLayerRegisterDates],
                name: 'vector Group'
            });
            /* -------------------------------------------------------- */	
            
            var map = new Map({
                layers: [rasterLayer,vectorGroup_multif],
                controls: defaultControls({
                  zoom: false,
                  attribution: false,
                  rotate: false,
                }),
                //maxResolution: 'auto',
                //target: document.getElementById('map'), 
                //target: (document.getElementById('map')) ? document.getElementById('settings_event_description_edit_options13') : document.getElementById('map'),
                target: document.getElementById('settings_event_description_edit_options13'),
                view: new View({
                    //minZoom: 9,
                    //maxZoom: 14,
                    center:[0,0],
                    //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                    zoom: 8,
                }),
                interactions: defaultInteractions({
                doubleClickZoom: false,
                dragAndDrop: false,
                dragPan: false,
                keyboardPan: false,
                keyboardZoom: false,
                mouseWheelZoom: false,
                pointer: true,
                select: true
              }).extend([

                new MouseWheelZoom({
                  condition: platformModifierKeyOnly,
                }) ]),
            });
            /* -------------------------------------------------------- */
            x.style.display = "block";
            map.updateSize();
            /* -------------------------------------------------------- */
            if($('#settings_event_description_edit_options13')){
               $('#settings_event_description_edit_options13').data('map', map);
            }
            /* -------------------------------------------------------- */
            SET_STADIA_ATTRIBUTION($('#settings_event_description_edit_options13').attr('id'));
            /* -------------------------------------------------------- */
            if($('#settings_event_description_edit_options13')){
               $('#settings_event_description_edit_options13').data('map', map);
            }
            if($('#settings_event_description_view_options13')){
               $('#settings_event_description_view_options13').data('map', map);
            }
            var $INITAL_RENDER_CONTROL = false;
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            map.on('rendercomplete', e => {
                if(($INITAL_RENDER_CONTROL==false)){
                    CONSOLE_MANAGER('description_micro_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                    setTimeout(SET_GEOLOCATION(), 1000);
                    window_complete_script('description_micro_map_activate - LOADING CLOSE');
                    $INITAL_RENDER_CONTROL = true;
                }
            })
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            var $tempextent = olExtent.createEmpty();
            var $tempcount = 0;
            var $tempsum = 0;
            var $tempfeatures = [];
            vectorGroup_multif.getLayers().forEach(function(layer) {
                olExtent.extend($tempextent, layer.getSource().getExtent());
                var features = layer.getSource().getFeatures();
                $tempfeatures[$tempcount]  = parseInt(features.length);
                $tempcount++;
            });
            $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
            if($tempsum > 0){
                map.getView().fit($tempextent, map.getSize());
            }
            /* -------------------------------------------------------- */	
            var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
            map.on('postrender', function() {
                var view = map.getView();
                var zoom = view.getZoom();
                var worldExtent = view.getProjection().getExtent();
                var extent_mgmt = view.calculateExtent(map.getSize());
                //////////********************
                if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                    var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                    view.fit(newExtent, map.getSize());
                }
                /* --------------------------------------------------------  */
            });
            /* --------------------------------------------------------  */
            map.on('singleclick', function(evt){
                $('#event_menu01_options1').unbind().click();
            });
            /* -------------------------------------------------------- */
            if($('#settings_event_general_formCoords').val()){
                $DATES_obj_array = JSON.parse($('#settings_event_general_formCoords').val());
                $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
                if($DATES_obj_array.length > 0){
                    var $DATES_data_array = [];
                    $DATES_obj_array.forEach((element, index, array) => {
                        if(element){
                            if(typeof element.getUniqEventId != "undefined"){
                                if(element.getEnvironmentOption == 'PHYSICAL'){
                                    //CenterMap(element.getLon, element.getLon, 14);
                                    map.getView().setZoom(7);
                                }
                            }
                            if(typeof element.getUniqEventId != "undefined"){
                                if(element.getEnvironmentOption == 'VIRTUAL'){
                                    //CenterMap(element.getLon, element.getLon, 9);
                                    map.getView().setZoom(7);
                                }
                            }
                        }
                    });
                }
            }
             /* -------------------------------------------------------- */
            if($('#business_settings_formCoords').val()){
                $DATES_obj_array = JSON.parse($('#business_settings_formCoords').val());
                $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
                if($DATES_obj_array.length > 0){
                    var $DATES_data_array = [];
                    $DATES_obj_array.forEach((element, index, array) => {
                        if(element){
                            if(typeof element.getUniqMemId != "undefined"){
                                if(element.getEnvironmentOption == 'PHYSICAL'){
                                    //CenterMap(element.getLon, element.getLon, 14);
                                    map.getView().setZoom(13);
                                }
                            }
                            if(typeof element.getUniqMemId != "undefined"){
                                if(element.getEnvironmentOption == 'VIRTUAL'){
                                   //	CenterMap(element.getLon, element.getLon, 9);
                                    map.getView().setZoom(7);
                                }
                            }
                        }
                    });
                }
            }
        } else {
            x.style.display = "none";
        }
    }
    if($("#settings_event_description_view_options13").is(":visible")){
        $("#settings_event_description_view_options13").css({display: "none"});
        var x = document.getElementById("settings_event_description_view_options13");
        if (x.style.display === "none") {
            /* --------------------------------------------------------  
            var rasterLayer = new TileLayer({
                source: new Stamen({
                    layer: 'toner-lite'
                })
            });
            */
            var rasterLayer = new TileLayer({
                source: new StadiaMaps({
                    layer: 'stamen_toner_lite',
                    retina: true,
                })
            });
            /* --------------------------------------------------------*/
            var vectorLayerRegisterDates = new VectorLayer({
                source: initiatMicroPins(rasterLayer),			
            });
            /* --------------------------------------------------------*/
            var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
                layers: [vectorLayerRegisterDates],
                name: 'vector Group'
            });
            /* -------------------------------------------------------- */	
            var map = new Map({
                layers: [rasterLayer,vectorGroup_multif],
                controls: defaultControls({
                  zoom: false,
                  attribution: false,
                  rotate: false,
                }),
                //maxResolution: 'auto',
                //target: document.getElementById('map'), 
                //target: (document.getElementById('map')) ? document.getElementById('settings_event_description_view_options13') : document.getElementById('map'),
                target: document.getElementById('settings_event_description_view_options13'),
                view: new View({
                    //minZoom: 9,
                    //maxZoom: 14,
                    center:[0,0],
                    //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                    zoom: 8,
                }),
                interactions: defaultInteractions({
                doubleClickZoom: false,
                dragAndDrop: false,
                dragPan: false,
                keyboardPan: false,
                keyboardZoom: false,
                mouseWheelZoom: false,
                pointer: true,
                select: true
              }).extend([

                new MouseWheelZoom({
                  condition: platformModifierKeyOnly,
                }) ]),
            });
            /* -------------------------------------------------------- */
            x.style.display = "block";
            map.updateSize();
            /* -------------------------------------------------------- */
            if($('#settings_event_description_view_options13')){
               $('#settings_event_description_view_options13').data('map', map);
            }
            /* -------------------------------------------------------- */
            SET_STADIA_ATTRIBUTION($('#settings_event_description_view_options13').attr('id'));
            /* -------------------------------------------------------- */
            if($('#settings_event_description_edit_options13')){
               $('#settings_event_description_edit_options13').data('map', map);
            }
            if($('#settings_event_description_view_options13')){
               $('#settings_event_description_view_options13').data('map', map);
            }
            var $INITAL_RENDER_CONTROL = false;
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            map.on('rendercomplete', e => {
                if(($INITAL_RENDER_CONTROL==false)){
                    CONSOLE_MANAGER('description_micro_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                    setTimeout(SET_GEOLOCATION(), 1000);
                    window_complete_script('description_micro_map_activate - LOADING CLOSE');
                    $INITAL_RENDER_CONTROL = true;
                }
            })
            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
            var $tempextent = olExtent.createEmpty();
            var $tempcount = 0;
            var $tempsum = 0;
            var $tempfeatures = [];
            vectorGroup_multif.getLayers().forEach(function(layer) {
                olExtent.extend($tempextent, layer.getSource().getExtent());
                var features = layer.getSource().getFeatures();
                $tempfeatures[$tempcount]  = parseInt(features.length);
                $tempcount++;
            });
            $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
            if($tempsum > 0){
                map.getView().fit($tempextent, map.getSize());
            }
            /* -------------------------------------------------------- */	
            var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
            map.on('postrender', function() {
                var view = map.getView();
                var zoom = view.getZoom();
                var worldExtent = view.getProjection().getExtent();
                var extent_mgmt = view.calculateExtent(map.getSize());
                //////////********************
                if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                    var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                    view.fit(newExtent, map.getSize());
                }
                /* --------------------------------------------------------  */
            });
            /* --------------------------------------------------------  */
            map.on('singleclick', function(evt){
                $('#event_menu01_options1').unbind().click();
            });
            /* -------------------------------------------------------- */
            if($('#settings_event_general_formCoords').val()){
                $DATES_obj_array = JSON.parse($('#settings_event_general_formCoords').val());
                $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
                if($DATES_obj_array.length > 0){
                    var $DATES_data_array = [];
                    $DATES_obj_array.forEach((element, index, array) => {
                        if(element){
                            if(typeof element.getUniqEventId != "undefined"){
                                if(element.getEnvironmentOption == 'PHYSICAL'){
                                    //CenterMap(element.getLon, element.getLon, 14);
                                    map.getView().setZoom(7);
                                }
                            }
                            if(typeof element.getUniqEventId != "undefined"){
                                if(element.getEnvironmentOption == 'VIRTUAL'){
                                    //CenterMap(element.getLon, element.getLon, 9);
                                    map.getView().setZoom(7);
                                }
                            }
                        }
                    });
                }
            }
             /* -------------------------------------------------------- */
            if($('#business_settings_formCoords').val()){
                $DATES_obj_array = JSON.parse($('#business_settings_formCoords').val());
                $DATES_obj_array = $.grep($DATES_obj_array,function(n){ return n == 0 || n });
                if($DATES_obj_array.length > 0){
                    var $DATES_data_array = [];
                    $DATES_obj_array.forEach((element, index, array) => {
                        if(element){
                            if(typeof element.getUniqMemId != "undefined"){
                                if(element.getEnvironmentOption == 'PHYSICAL'){
                                    //CenterMap(element.getLon, element.getLon, 14);
                                    map.getView().setZoom(13);
                                }
                            }
                            if(typeof element.getUniqMemId != "undefined"){
                                if(element.getEnvironmentOption == 'VIRTUAL'){
                                   //	CenterMap(element.getLon, element.getLon, 9);
                                    map.getView().setZoom(7);
                                }
                            }
                        }
                    });
                }
            }
        } else {
            x.style.display = "none";
        }
    }
    
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_map_activate(){
	CONSOLE_MANAGER('event_map_activate','ACTIVATED');
	windowload_script('event_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('event_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        rasterLayer.on(["precompose", "prerender"], function(event) {
            CONSOLE_MANAGER('MAP RasterLayer','LOADING (prerender)');
        });
        rasterLayer.on(["postcompose", "postrender"], function(event) {
             CONSOLE_MANAGER('MAP RasterLayer','LOADING (postrender)');
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'event_map_activate - MAPPING:',parseInt(0));
                window_complete_script('event_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if(document.getElementById("event_creator_lat")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('event_creator_lat');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#event_creator_lat").val()){ 
                                CONSOLE_MANAGER('event_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('event_map_activate',mutation.type+': attributes');
                                    CenterMap($("#event_creator_lon").val(),$("#event_creator_lat").val(),12);
                                    AddFeature($("#event_creator_lon").val(),$("#event_creator_lat").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('event_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
        /* --------------------------------------------------------  */
        var $length = 0;
        if(parseInt($('#total_objects_count_item').val())>0){
            $length = parseInt($('#total_objects_count_item').val())+1;
        }
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        var $ENTITYLISTMgmt = [];
        var $code = $("#index_object_item").val();
        if(parseInt($('#total_objects_count_item').val())>0){
            for(var $i=1; $i< $length; $i++){

                var $SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
                var $CSEQUENCE = '#form_'+$code+'_'+$i+'_options2';
                var $SSEQUENCE = '#form_'+$code+'_'+$i+'_startDate';
                var $ESEQUENCE = '#form_'+$code+'_'+$i+'_endDate';
                var $STATUSSEQUENCE = '#form_'+$code+'_'+$i+'_activityStatus';
                var $VSEQUENCE = '#form_'+$code+'_'+$i+'_registerCode';
                var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                var $EVENTSTATUS = '#form_'+$code+'_'+$i+'_options9';
                var $UNIQSEQUENCE = '#form_'+$code+'_'+$i+'_uniqMemId';
                var $SETCOLOR = null;
                var $SETADDRESS =  null;
                var $SETCITY =  null;
                var $SETSTATE = null;
                var $SETCOUNTRY = null;
                var $SETLAT = null;
                var $SETLONG = null;
                //if(($($SELECTEDSEQUENCE).val())){
                    $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                    $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                    $SETCITY = '#form_'+$code+'_'+$i+'_city';
                    $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                    $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                    $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                    $SETLONG = '#form_'+$code+'_'+$i+'_lon';

               // }
                if($($SEQUENCE)){
                    /* ------------------------------------------------- */	
                    var $COORDMgmt = {GET_UNIQ_ID:$($UNIQSEQUENCE).val(),GET_ITEM_ID:$($VSEQUENCE).val(),SET_STATUS:$($EVENTSTATUS).val(),LAT:$($SETLAT).val(),LONG:$($SETLONG).val(),COLOR:$($COLORSEQUENCE).val(),START:$($SSEQUENCE).val(),END:$($ESEQUENCE).val()};
                    $DATES_obj_array[$i] =$COORDMgmt;
                    REGISTER_CLICK($($SEQUENCE), $COORDMgmt,map);
                }
            }
        }
    } else {
        x.style.display = "none";
    }
	/* ------------------------------------------------- */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
    var tempLayer = new VectorLayer({});
    /* ------------------------------------------------- */
    function AddFeature(long, lat) {
		var tempSource = new VectorSource({});
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
		map.removeLayer(tempLayer);
		/* ------------------------------------------------- */
		var $VERIFY = $("#event_creator_environmentOption").val();
		var $STATUS = $("#event_creator_eventStatus").val();
		var interval = null;
		/* ------------------------------------------------- */
		if($STATUS.indexOf("COMPLETE") > -1){
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 3,
                    stroke: new Stroke({
                        color: 'rgba(255,0,0, 0.7)',
                        width: 1,
                        //lineCap: 'round' ,
                        lineJoin: 'bevel' ,
                    }),
                    fill: new Fill({
                        //color: 'rgba(239,239,239, 0.7)',
                        color: $color,
                    })
                })
            }));
		}
		else if($STATUS.indexOf("PENDING") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							//color: 'rgba(239,45,239, 0.7)',
							color: 'rgba(255,255,0, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(255,255,0, 0.7)',
								//color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- */
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
                    
				}
			}
		}
		else if($STATUS.indexOf("ACTIVE") > -1){
			/* ------------------------------------------------- */
			if($VERIFY.indexOf("PHYSICAL") > -1){
				iconFeature.setStyle(new Style({
					image: new CircleStyle({
						radius: 8,
						stroke: new Stroke({
							color: 'rgba(239,45,239, 0.7)',
							width: 2,
							//lineCap: 'round' /* square */,
							lineJoin: 'bevel' /* round */,
						}),
						fill: new Fill({
							color: 'rgba(47,45,239, 0.7)'
						})
					})
				}));
				tempSource.addFeature(iconFeature);
				window.clearInterval(interval);
			}
			else if($VERIFY.indexOf("VIRTUAL") > -1){//pulse
				/* ------------------------------------------------- */
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.3)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.3)'
							})
						})
					}));
					tempSource.addFeature(iconFeature);
					window.clearInterval(interval);
				}
				/* ------------------------------------------------- */
				else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					iconFeature.setStyle(new Style({
						image: new CircleStyle({
							radius: 8,
							stroke: new Stroke({
								color: 'rgba(239,45,239, 0.0)',
								width: 2,
								//lineCap: 'round' /* square */,
								lineJoin: 'bevel' /* round */,
							}),
							fill: new Fill({
								color: 'rgba(47,45,239, 0.0)'
							})
						})
					}));
					/* ------------------------------------------------- */
					const duration = 3000;
                    function flash(feature) {
                      const start = Date.now();
                      const flashGeom = feature.getGeometry().clone();
                      const listenerKey = rasterLayer.on('postrender', animate);

                      function animate(event) {
                        const frameState = event.frameState;
                        const elapsed = frameState.time - start;
                        if (elapsed >= duration) {
                          unByKey(listenerKey);
                          return;
                        }
                        const vectorContext = getVectorContext(event);
                        const elapsedRatio = elapsed / duration;
                        // radius will be 5 at start and 30 at end.
                        const radius = easeOut(elapsedRatio) * 25 + 5;
                        const opacity = easeOut(1 - elapsedRatio);

                        const style = new Style({
                          image: new CircleStyle({
                            radius: radius,
                            stroke: new Stroke({
                              color: 'rgba(4, 144, 242, ' + opacity + ')',
                              //color: $DATES_data_array[i].getColorCode,
                              width: 0.25 + opacity,
                            }),
                          }),
                        });

                        vectorContext.setStyle(style);
                        vectorContext.drawGeometry(flashGeom);
                        // tell OpenLayers to continue postrender animation
                        if($('#map')){
                            var map = $('#map').data('map');
                        }
                        if($('#view_map')){
                            var map = $('#view_map').data('map');
                        }
                        map.render();
                      }
                    }
					interval = window.setInterval(function(){
						flash(iconFeature);
					},3000);
					tempSource.on('addfeature', function(e) {
						flash(e.feature);
					});
					tempSource.addFeature(iconFeature);
					function StopFunction() {
					  clearInterval(interval);
					}
					/* ------------------------------------------------- */
					$('#event_creator_options4').unbind().click(function(event) {
						StopFunction();
					});
					$('#event_creator_options5').unbind().click(function(event) {
						StopFunction();
					});
				}
			}
		}
		var layersToRemove = [];
		map.getLayers().forEach(function (layer) {
			if (layer.get('name') != undefined && layer.get('name') === 'tempLayer') {
				layersToRemove.push(layer);
			}
		});
		var len = layersToRemove.length;
		for(var i = 0; i < len; i++) {
			map.removeLayer(layersToRemove[i]);
		}
		tempLayer.setSource(tempSource);
        map.addLayer(tempLayer);
		tempLayer.set('name', 'tempLayer');
    }/*END FUNCTION */
    
}
/* -------------------------------------------------------- */
/* --------------------------------------------------------  */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function image_meta_map_activate(){
	CONSOLE_MANAGER('image_meta_map_activate','ACTIVATED');
	windowload_script('image_meta_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('image_meta_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;
			}													  
		);
	}
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var map = new Map({
            layers: [rasterLayer],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                //minZoom: 9,
                //maxZoom: 14,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 4,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('image_meta_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'image_meta_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('image_meta_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
                /* -------------------------------------------------------- */
                if(($('#image_creator_imageExifLongitude').val())&&($('#image_creator_imageExifLatitude').val())){
                    meta_geocode(map);
                    AddFeature($("#image_creator_imageExifLongitude").val(),$("#image_creator_imageExifLatitude").val());
                    Temp_CenterMap($('#image_creator_imageExifLongitude').val(), $('#image_creator_imageExifLatitude').val(), 10, map, false);//map.getView().getZoom()
                }else{

                }
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* --------------------------------------------------------  */
        map.on('singleclick', function(evt){
        });
        /* --------------------------------------------------------  */
        if(document.getElementById("image_creator_imageExifLongitude")){
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('image_creator_imageExifLongitude');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */	
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            if($("#image_creator_imageExifLatitude").val()){
                                CONSOLE_MANAGER('image_meta_map_activate','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('image_meta_map_activate',mutation.type+': attributes');
                                    CenterMap($("#image_creator_imageExifLongitude").val(),$("#image_creator_imageExifLatitude").val(),12);
                                    AddFeature($("#image_creator_imageExifLongitude").val(),$("#image_creator_imageExifLatitude").val());
                                    /* -------------------------------------------------------- */
                                }else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('image_meta_map_activate',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                }
                            }
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();
            })();/*END FUNCTION */
        }
        
    } else {
        x.style.display = "none";
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */
    function AddFeature(long, lat) {
        CONSOLE_MANAGER('AddFeature','ACTIVATE');
        /* ------------------------------------------------- */
        map.getLayers().getArray().forEach(function(layer){
            if (layer.getSource().getFeatures) {
                map.removeLayer(layer);
                var features = layer.getSource().getFeatures();
                CONSOLE_MANAGER('image_meta_map_activate','AddFeature - features: '+features);
            }
        });
        /* ------------------------------------------------- */
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
        iconFeature.setStyle(new Style({
            image: new CircleStyle({
                radius: 8,
                stroke: new Stroke({
                    color: 'rgba(239,45,239, 0.7)',
                    width: 3,
                    //lineCap: 'round' /* square */,
                    lineJoin: 'bevel' /* round */,
                }),
                fill: new Fill({
                    color: 'rgba(47,45,239, 0.7)'
                })
            })
        }));
        var tempSource = new VectorSource({
            features: [iconFeature]
        });
        var tempLayer = new VectorLayer({
             source: tempSource
         });
        map.addLayer(tempLayer);
        $("#map_key_search_parameters").on('change', function(){
            if(tempLayer.getSource()){
                tempLayer.getSource().removeFeature(iconFeature)
            }
        });
    }/*END FUNCTION */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function metrics_meta_map_activate(){
	CONSOLE_MANAGER('metrics_meta_map_activate','ACTIVATED');
	windowload_script('metrics_meta_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	var $vectorpins = new VectorSource({});
    var $DATES_obj_array=[];
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('metrics_meta_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;
			}													  
		);
	}
	
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var map = new Map({
            layers: [rasterLayer],//,vectorGroup_multif
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('metrics_meta_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'metrics_meta_map_activate - MAPPING:',parseInt(0));
                //Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                setTimeout(CenterMap($('#map_key_lon').val(), $('#map_key_lat').val(), map.getView().getZoom()), 2000)
                window_complete_script('metrics_meta_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
    } else {
        x.style.display = "none";
    }
    /* -------------------------------------------------------- */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function select_item_date_map_activate(){
	CONSOLE_MANAGER('select_item_date_map_activate','ACTIVATED');
	windowload_script('select_item_date_map_activate');
	/* -------------------------------------------------------- */
	var vectorDatepins = new VectorSource({});
	var vectorPathpins = new VectorSource({});
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT){
		$('#search_request_control').val('multifunction_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				map_window_close(close_div_var='map_message_window_stucture');
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
				window_complete_script('function: select_item_date_map_activate'); 
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				windowUnload_script('function: select_item_date_map_activate');
				Map_item_click_submit(event);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				map_window_close(close_div_var='map_message_window_stucture');
				window_complete_script('function:select_item_date_map_activate'); 
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* --------------------------------------------------------*/
        /* --------------------------------------------------------*/
        var vectorLayerMapPath = new VectorLayer({
            source: initiatMapPins(),
        });	
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatItemPins(),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,
            layers: [vectorLayerRegisterDates,vectorLayerMapPath],
            name: 'vector Group'
        });
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                //zoom: 3,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('select_item_date_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'select_item_date_map_activate - MAPPING:',parseInt(0));
                window_complete_script('select_item_date_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
          $tempextent = olExtent.createEmpty();
          $tempcount = 0;
          $tempsum = 0;
          $tempfeatures = [];
          vectorGroup_multif.getLayers().forEach(function(layer) {
              olExtent.extend($tempextent, layer.getSource().getExtent());
              features = layer.getSource().getFeatures();
              $tempfeatures[$tempcount]  = parseInt(features.length);
              $tempcount++;
          });
          $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
          if($tempsum > 0){
              map.getView().fit($tempextent, map.getSize());
          }
          /* -------------------------------------------------------- */	
          var restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
          map.on('postrender', function() {
              var view = map.getView();
              var zoom = view.getZoom();
              var worldExtent = view.getProjection().getExtent();
              var extent_mgmt = view.calculateExtent(map.getSize());
              //////////********************
              if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                  var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                  view.fit(newExtent, map.getSize());
              }
              /* --------------------------------------------------------  */
          });
          /* -------------------------------------------------------- */
        $('#map_key_formZoom').val(map.getView().getZoom());
        select_date_map_set_register(map);
        /* -------------------------------------------------------- */
        select_date_map_frame_mgmt(map,'select_item_date_map_activate');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        if(document.getElementById('map_key_select_location_options1')){
            var submit_button = document.getElementById('map_key_select_location_options1');	
            submit_button.addEventListener("click", function($event){
                $event.stopPropagation;
            }, false);	
        }
        /* --------------------------------------------------------  */
        if(document.getElementById('map_key_select_location_options2')){
            var submit_button = document.getElementById('map_key_select_location_options2');	
            submit_button.addEventListener("click", function($event){
                $event.stopPropagation;
            }, false);	
        }
        /* --------------------------------------------------------  */
        if(document.getElementById('map_key_select_location_options3')){
            var submit_button = document.getElementById('map_key_select_location_options3');	
            submit_button.addEventListener("click", function($event){
                $event.stopPropagation;
            }, false);	
        }
        /* --------------------------------------------------------  */
        if(document.getElementById('map_key_select_location_options4')){	
            var submit_button = document.getElementById('map_key_select_location_options4');	
            submit_button.addEventListener("click", function($event){
                $event.stopPropagation;
                date = moment();
                selectedaddMap(date,$event);
            }, false);	
        }
        /* -------------------------------------------------------- */
    } else {
        x.style.display = "none";
    }
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function event_item_map_activate(){
	CONSOLE_MANAGER('event_item_map_activate','ACTIVATED');
	windowload_script('event_item_map_activate');
	
	/* --------------------------------------------------------  */
	function REGISTER_CLICK($MGMT,$ITEMMGMT, map){
		$('#search_request_control').val('event_item_map_activate');
		$MGMT.single_double_click(function(event){
				/*SINGLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*DOUBLE CLICK*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
				Temp_CenterMap($ITEMMGMT.LONG, $ITEMMGMT.LAT, map.getView().getZoom(), map, false);
			}, function(event) {
				/*PRESS*/
				event.stopPropagation;
                $('#search_request_uniqEventId').val($ITEMMGMT.GET_UNIQ_ID);
                $('#search_request_registerCode').val($ITEMMGMT.GET_ITEM_ID);
			},function(event) {
				/*SWIPE*/
				event.stopPropagation;	
			}													  
		);
	}
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById("map");
    if (x.style.display === "none") {
        /* -------------------------------------------------------- */
        var vectorDatepins = new VectorSource({});
        var vectorPathpins = new VectorSource({});
        var $vectorpins = new VectorSource({});
        var $DATES_obj_array=[];
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatEventPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* --------------------------------------------------------  */
        var map = new Map({
            layers: [rasterLayer,vectorGroup_multif],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                minZoom: 3,
                maxZoom: 20,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            })
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('event_item_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'event_item_map_activate - MAPPING:',parseInt(0));
                window_complete_script('event_item_map_activate - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* -------------------------------------------------------- */
        map.getView().setZoom(12);
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */
export function feed_map_activate($LAT,$LON,$FRAME,$i){
    CONSOLE_MANAGER('feed_map_activate','ACTIVATED for: '+$FRAME.attr('id'));
	windowload_script('feed_map_activate');
	/* -------------------------------------------------------- */
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    //var vectorDatepins = new VectorSource({});
    //var vectorPathpins = new VectorSource({});
    //var $vectorpins = new VectorSource({});
    //var $DATES_obj_array=[];
    
    /* -------------------------------------------------------- */
    $("#"+$FRAME.attr('id')).css({display: "none"});
    var x = document.getElementById($FRAME.attr('id'));
    if (x.style.display === "none") {
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        /* --------------------------------------------------------*/
        /* --------------------------------------------------------*/
        var $LONGITUDE = parseFloat(0.0);
        var $LATITUDE = parseFloat(0.0);
        if($LAT.val()){
            CONSOLE_MANAGER('feed_map_activate','$LAT: ' + $LAT.val());
            $LATITUDE = $LAT.val();
        }
        if($LON.val()){
            CONSOLE_MANAGER('feed_map_activate','$LON: ' + $LON.val());
            $LONGITUDE = $LON.val();
        }
        
        var map = new Map({
            layers: [rasterLayer],
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById($FRAME.attr('id')), //$FRAME,//$FRAME.attr('id')
            view: new View({
                //minZoom: 9,
                //maxZoom: 14,
                //center:[0,0],
                center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),//transform([long, lat], 'EPSG:4326', 'EPSG:3857')
                zoom: 15,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([

            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('feed_map_activate','$INITAL_RENDER_CONTROL = FALSE');
                CenterMap($LONGITUDE,$LATITUDE,15);
                AddFeature($LONGITUDE,$LATITUDE);
                window_complete_script('feed_map_activate - LOADING CLOSE'+$FRAME.attr('id'));
                $INITAL_RENDER_CONTROL = true;
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
        /* --------------------------------------------------------  */
        map.on('singleclick', function(evt){
            //$('#event_menu01_options1').unbind().click();
        });
    } else {
        x.style.display = "none";
    }
    /* -------------------------------------------------------- */
    function AddFeature(long, lat) {
        var iconFeature = new Feature({      
            geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
            //name: 'NULL'
        });
        iconFeature.setStyle(new Style({
            image: new CircleStyle({
                radius: 8,
                stroke: new Stroke({
                    color: 'rgba(239,45,239, 0.7)',
                    width: 3,
                    //lineCap: 'round' /* square */,
                    lineJoin: 'bevel' /* round */,
                }),
                fill: new Fill({
                    color: 'rgba(47,45,239, 0.7)'
                })
            })
        }));
        var tempSource = new VectorSource({
            features: [iconFeature]
        });
        var tempLayer = new VectorLayer({
             source: tempSource
         });
        map.addLayer(tempLayer);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* --------------------------------------------------------  */
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function map_identity_event_control(){
	CONSOLE_MANAGER('map_identity_event_control','ACTIVATED');
	windowload_script('map_identity_event_control ');
	
	/* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById('map');
    if (x.style.display === "none") {
        
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        $( "#map_key_search_parameters" ).focusin(function() {
            var $autoObj = MGMT_google_autocomplete('map_key_search_parameters');
            $( ".pac-container" ).css('z-index', 3000);
        });
        if(document.getElementById("map_key_search_parameters")){
            $( "#map_key_search_parameters" ).keyup(function(e) {
                $( ".pac-container" ).css('z-index', 3000);
                /* --------------------------------------------------------  */
                //var codes = [9,13,32];
                var codes = [32];
                document.getElementById("map_key_search_parameters").addEventListener('keydown', function(e) {
                if(!contains(codes, e.keyCode)) return;
                    var path = e.path || e.composedPath()
                    if(!path[0]) return;
                    e.preventDefault();
                    path[0].value += String.fromCharCode(e.keyCode);//
                    var $STRING=$('#map_key_search_parameters').val();
                    $('#map_key_search_parameters').val($STRING.replace(/\s{2,}/g, ' '));
                })
                function contains(arr, item) {
                    for(var i=0;i<arr.length;i++)
                    if(arr[i]==item)return true;
                    return false;
                }
                /* --------------------------------------------------------  */
                var $autoObj = MGMT_google_autocomplete('map_key_search_parameters');
                /* --------------------------------------------------------  */
            });
        }
        /* ------------------------------------------------- */	
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        /* ------------------------------------------------- */
        var raster = new TileLayer({
            source: new OSM()
        });
        /**/
        var style = new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.6)'
          }),
          stroke: new Stroke({
            color: '#319FD3',
            width: 1
          }),
          text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
              color: '#000'
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 3
            })
          })
        });

        var highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(255,255,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        var selected_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgb(0,191,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        const rollover_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(0,255,0, 0.3)',
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3,
            }),
        });
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        if($('#event_locations_menu_formCurrentIndex').val()){
            var $key = $('#event_locations_menu_formCurrentIndex').val();
            if($key.includes('COUNTRY')){
               var location_vector = new VectorLayer({//COUNTRY
                    source: new VectorSource({
                        url: 'https://glbl-resources-public.s3.amazonaws.com/OL/countries.geojson',
                        //url: 'https://glbl-resources-public.s3.amazonaws.com/OL/US_OUTLINE.json',
                        //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/countries.geojson',
                        format: new GeoJSON()
                    })
                });
            }
            else if($key.includes('STATE')){
                var location_vector = new VectorLayer({//STATE
                    source: new VectorSource({
                        url: 'https://glbl-resources-public.s3.amazonaws.com/OL/states.json',
                        //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/states.json',
                        format: new GeoJSON()
                    })
                });
            }
            else if($key.includes('CITY')){
                var location_vector = new VectorLayer({//CITY
                    source: new VectorSource({
                        url: 'https://glbl-resources-public.s3.amazonaws.com/OL/GLOBAL_CITIES.json',
                        //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/states.json',
                        format: new GeoJSON()
                    })
                });
            }
            else if($key.includes('COUNTY')){
                var location_vector = new VectorLayer({//COUNTY
                    source: new VectorSource({
                        url: 'https://glbl-resources-public.s3.amazonaws.com/OL/US_COUNTIES.json',
                        //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/states.json',
                        format: new GeoJSON()
                    })
                });
            }
            else if($key.includes('CONGRESSIONAL')){
                var location_vector = new VectorLayer({//CONGRESSIONAL
                    source: new VectorSource({
                        url: 'https://glbl-resources-public.s3.amazonaws.com/OL/US_CONGRESSIONAL.json',
                        //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/states.json',
                        format: new GeoJSON()
                    })
                });
            }
        }
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatComplianceControlEventPins(rasterLayer),			
        });
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates, location_vector],
            name: 'vector Group'
        });
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */	
        var view = new View({
            center: [0, 0],
            zoom: 2,
            extent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34],
            multiWorld: true
        });
        var map = new Map({
            layers: [rasterLayer, vectorGroup_multif],//	raster	rasterLayer
            keyboardEventTarget: document,
            target: 'map',
            view: view,
            controls: defaultControls({
                //zoom: false,
                attribution: false,
                rotate: false,
            }),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('map_identity_event_control','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(Initiate_coord, 1000);
                setTimeout(SET_GEOLOCATION(), 1000)
                map_key_activate(map,'map_identity_event_control - MAPPING:item_map',parseInt(1000));
                Temp_CenterMap(Number($('#event_locations_menu_lon').val()), Number($('#event_locations_menu_lat').val()), parseInt($('#event_locations_menu_formZoom').val()), map, false);
                window_complete_script('map_identity_event_control - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var selected = [];
        var status = document.getElementById('status');
        map.on('singleclick', function(e) {
            map.forEachFeatureAtPixel(e.pixel, function(f) {
                var selIndex = selected.indexOf(f);
                if (selIndex < 0) {
                    selected.push(f);
                    f.setStyle(selected_highlightStyle);//highlightStyle
                    REGISTER_CLICK('ACTIVE',e,e.pixel);
                } else {
                    selected.splice(selIndex, 1);
                    f.setStyle(undefined);// TexthighlightStyle
                    REGISTER_CLICK('DISABLE',e,e.pixel);
                }
            });
        });
        /* -------------------------------------------------------- */
        let roll_selected = null;
        map.on('pointermove', function (e) {
            /* -------------------------------------------------------- */
            if (e.dragging) {
                return;
            }
            var pixel = map.getEventPixel(e.originalEvent);
            displayFeatureInfo(pixel);
            /* -------------------------------------------------------- */
            if (roll_selected !== null) {
                /* -------------------------------------------------------- */
                if(document.getElementById("event_locations_menu_identityCountryCoords")){
                    if($('#event_locations_menu_identityCountryCoords').val()){
                        var jsonValidate  = isJSON($('#event_locations_menu_identityCountryCoords').val());
                        if(jsonValidate==true){
                            var arr = [];
                            var $DATES_obj_array = JSON.parse($('#event_locations_menu_identityCountryCoords').val());
                            if(Object.keys($DATES_obj_array).length >0){
                                var $COUNTRY, $STATE, $CITY, $COUNTY = [];
                                $COUNTRY=$DATES_obj_array.COUNTRY;
                                $STATE=$DATES_obj_array.STATE;
                                $CITY=$DATES_obj_array.CITY;
                                $COUNTY=$DATES_obj_array.COUNTY;
                                var $KEY = JSON.stringify($DATES_obj_array);
                                /* -------------------------------------------------------- */
                                if(roll_selected.getId()){
                                    if($KEY.includes(roll_selected.getId())){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }
                                }else if(roll_selected.get('GEO_ID')){
                                    if($KEY.includes(roll_selected.get('GEO_ID'))){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }
                                }else if(roll_selected.get('ISO_A3')){
                                    if($KEY.includes(roll_selected.get('ISO_A3'))){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }	 
                                }else if(roll_selected.get('NAME')){
                                    if($KEY.includes(roll_selected.get('NAME'))){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }	
                                }
                                else if(roll_selected.get('name')){
                                    if($KEY.includes(roll_selected.get('name'))){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }	
                                }
                            }
                        }else{
                            CONSOLE_MANAGER('map_identity_event_control','ROLL OVER/ON POINTER MOVE - event_locations_menu_identityCountryCoords JSON ERROR');
                            roll_selected.setStyle(undefined);
                        }
                    }else{
                        roll_selected.setStyle(undefined);
                    }
                }
                roll_selected = null;
            }

            map.forEachFeatureAtPixel(e.pixel, function (featu) {
                roll_selected = featu;
                featu.setStyle(rollover_highlightStyle);
                return true;
            });
        });
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /*	GEO SEARCHING AND LABEL */
        /*	----------------------- */
        /*	----------------------- */
        var highlight;
        var displayFeatureInfo = function(pixel) {
            var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
                return feature;
            });
            var info = document.getElementById('event_locations_menu_messageboxinput');
            var store = document.getElementById('standard_entity_modify_formEntity');
            if (feature) {
                /* -------------------------------------------------------- *//* -------------------------------------------------------- */
                var $GEO = feature.get('geometry');
                var $NAME =null;
                var $ID =null;
                /* -------------------------------------------------------- */
                $NAME = (feature.get('name')) ?  feature.get('name'): feature.get('NAME');
                var res = $NAME.toLowerCase();
                var arr = res.split(" ");
                for (var i = 0; i < arr.length; i++) {
                    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
                }
                $NAME = arr.join(" ");
                info.innerHTML = $NAME;
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                $ID = (feature.getId()) ?  feature.getId(): feature.get('GEO_ID');
                if(($ID==null)||$ID=='undefined'){
                   $ID = feature.get('ISO_A3');
                    if(($ID==null)||$ID=='undefined'){
                       $ID = feature.get('ISO_A3');
                        if(($ID==null)||$ID=='undefined'){
                            $ID = (feature.get('name')) ?  feature.get('name'): feature.get('NAME');
                        }
                    }
                }
                /* -------------------------------------------------------- */
                $('#event_locations_menu_messageboxinput').val($NAME);
                store.innerHTML = $ID;
                $('#standard_entity_modify_formEntity').val($ID+'|'+$NAME);
            } else {
                info.innerHTML = '&nbsp;';
            }
        }
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
	var $INITAL_RENDER_CONTROL = false;
	var pre_run = false;
	function Initiate_coord(){
			map.on('click', function(e) {
				if(pre_run==false){
					location_vector.getSource().forEachFeature(function(f) {
						if(e.id==f.getId()){
							selected.push(f);
							f.setStyle(selected_highlightStyle);//highlightStyle
						}
						else if(e.id==f.get('GEO_ID')){
							selected.push(f);
							f.setStyle(selected_highlightStyle);//highlightStyle
						}
						else if(e.id==f.get('ISO_A3')){
							selected.push(f);
							f.setStyle(selected_highlightStyle);//highlightStyle
						}
						else if(e.id==f.get('name')){
							selected.push(f);
							f.setStyle(selected_highlightStyle);//highlightStyle
						}
						else if(e.id==f.get('NAME')){
							selected.push(f);
							f.setStyle(selected_highlightStyle);//highlightStyle
						}
					});
				}else{
                    CONSOLE_MANAGER('map_identity_event_control','PRE-LOADED ID - SYSTEMATIC MAP loop/pre_run: '+pre_run);
				}
			});
		
		/* --------------------------------------------------------  */
		if(document.getElementById("event_locations_menu_identityCountryCoords")){
			if($('#event_locations_menu_identityCountryCoords').val()){
				var jsonValidate  = isJSON($('#event_locations_menu_identityCountryCoords').val());
				if(jsonValidate==true){
					var arr = [];
					var $DATES_obj_array = JSON.parse($('#event_locations_menu_identityCountryCoords').val());
					if(Object.keys($DATES_obj_array).length >0){
						var $COUNTRY, $STATE, $CITY, $COUNTY = [];
						$COUNTRY=$DATES_obj_array.COUNTRY;
						$STATE=$DATES_obj_array.STATE;
						$CITY=$DATES_obj_array.CITY;
						$COUNTY=$DATES_obj_array.COUNTY;
						/* -------------------------------------------------------- */
                        if($COUNTRY.length >0){
                            for (var $r=0; $r<$COUNTRY.length; $r++){
								if($COUNTRY[$r]!=null){
                                    var $DIRECT_OBJ = $COUNTRY[$r];
                                    const $DIRECT_VALUE = $DIRECT_OBJ.split('|');
									var evt = {};
									evt.type = 'click';
									evt.id = [$DIRECT_VALUE[0]];
									//evt.GEO_ID = [$DIRECT_VALUE[0]];
									//evt.ISO_A3 = [$DIRECT_VALUE[0]];
									map.dispatchEvent(evt);
                                }
                            }
                        }
                        /* -------------------------------------------------------- */
                        if($STATE.length >0){
                            for (var $r=0; $r<$STATE.length; $r++){
								if($STATE[$r]!=null){	
                                    var $DIRECT_OBJ = $STATE[$r];
                                    const $DIRECT_VALUE = $DIRECT_OBJ.split('|');
									var evt = {};
									evt.type = 'click';
									evt.id = [$DIRECT_VALUE[0]];
									//evt.GEO_ID = [$DIRECT_VALUE[0]];
									//evt.ISO_A3 = [$DIRECT_VALUE[0]];
									map.dispatchEvent(evt);
                                }
                            }
                        }
                        /* -------------------------------------------------------- */
                        if($CITY.length >0){
                            for (var $r=0; $r<$CITY.length; $r++){
								if($CITY[$r]!=null){
                                    var $DIRECT_OBJ = $CITY[$r];
                                    const $DIRECT_VALUE = $DIRECT_OBJ.split('|');
									var evt = {};
									evt.type = 'click';
									evt.id = [$DIRECT_VALUE[0]];
									//evt.GEO_ID = [$DIRECT_VALUE[0]];
									//evt.ISO_A3 = [$DIRECT_VALUE[0]];
									//evt.NAME = [$DIRECT_VALUE[0]];
									//evt.name = [$DIRECT_VALUE[0]];
									map.dispatchEvent(evt);
                                }
                            }
                        }
                        /* -------------------------------------------------------- */
                        if($COUNTY.length >0){
                            for (var $r=0; $r<$COUNTY.length; $r++){
								if($COUNTY[$r]!=null){
                                    var $DIRECT_OBJ = $COUNTY[$r];
                                    const $DIRECT_VALUE = $DIRECT_OBJ.split('|');
									var evt = {};
									evt.type = 'click';
									evt.id = [$DIRECT_VALUE[0]];
									//evt.GEO_ID = [$DIRECT_VALUE[0]];
									//evt.ISO_A3 = [$DIRECT_VALUE[0]];
									map.dispatchEvent(evt);
                                }
                            }
                        }
					}
				}else{
                    CONSOLE_MANAGER('map_identity_event_control','JSON ERROR');
				}
				pre_run = true;
			}
		}else{
			pre_run = true;
		}
	}
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
    function find_update_key($VALUE){
        var elements = document.querySelectorAll(".identity_result_country_selected");
        for (var i = 0; i < elements.length; i++) {
            var image = elements[i];
            if(image.getAttribute('value') == $VALUE){
                image.remove();
            }
        }
    }
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* --------------------------------------------------------  */
    function REGISTER_CLICK($STATUS,event,coords){
		windowload_script('map_identity_event_control Initiate_coord');
		$('#standard_entity_modify_uniqEventId').val($('#event_locations_menu_uniqEventId').val());
        $('#standard_entity_modify_registerCode').val($('#event_locations_menu_registerCode').val());
		$('#standard_entity_modify_formCoords').val(coords);
        $('#standard_entity_modify_control').val($('#event_locations_menu_control').val());
		$('#standard_entity_modify_formCurrentIndex').val($('#event_locations_menu_formCurrentIndex').val());
		$('#standard_entity_modify_index1').val(coords);
        $('#standard_entity_modify_formFilter1').val($STATUS);
        settings_request_function(event);
		if($STATUS=='DISABLE'){
			find_update_key($('#event_locations_menu_messagedisplayinput').val());
		}
    }
	/* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    var AJAX_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    CONSOLE_MANAGER('map_identity_event_control','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                    var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                    if($("#standard_results_ajax_control").val()){
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': attributes');
                            $RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                            if($('#search_request_control').length){
                               $SEARCH_CNTRL = $('#search_request_control').val();
                            }
                            if(($('#standard_results_ajax_control').val())){
                                /* -------------------------------------------------------- */
                                if($('#standard_results_ajax_control').val()==$('#event_locations_menu_control').val()){
                                    $('#event_locations_menu_identityCountryCoords').val($('#standard_results_ajax_entitylistMgmt').val());
                                    var arr = [];
                                    var $DATES_obj_array = JSON.parse($('#standard_results_ajax_entitytypeMgmt').val());
                                    if(Object.keys($DATES_obj_array).length >0){
                                        var $COUNTRY_COUNT, $STATE_COUNT, $CITY_COUNT, $COUNTY_COUNT = parseInt(0);
                                        var $COUNTRY, $STATE, $CITY, $COUNTY = [];
                                        $COUNTRY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTRY_COUNT);
                                        $STATE_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_STATE_COUNT);
                                        $CITY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_CITY_COUNT);
                                        $COUNTY_COUNT=parseInt($DATES_obj_array.COMPLIANCE_RANGE_COUNTY_COUNT);

                                        $COUNTRY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTRY;
                                        $STATE=$DATES_obj_array.COMPLIANCE_RANGE_STATE;
                                        $CITY=$DATES_obj_array.COMPLIANCE_RANGE_CITY;
                                        $COUNTY=$DATES_obj_array.COMPLIANCE_RANGE_COUNTY;
                                        $('#event_locations_menu_options5active').html($COUNTRY_COUNT);
                                        $('#event_locations_menu_options6').html(null);
                                        $('#event_locations_menu_options6').html($COUNTRY);

                                        $('#event_locations_menu_options7active').html($STATE_COUNT);
                                        $('#event_locations_menu_options8').html(null);
                                        $('#event_locations_menu_options8').html($STATE);

                                        $('#event_locations_menu_options9active').html($CITY_COUNT);
                                        $('#event_locations_menu_options10').html(null);
                                        $('#event_locations_menu_options10').html($CITY);

                                        $('#event_locations_menu_options11active').html($COUNTY_COUNT);
                                        $('#event_locations_menu_options12').html(null);
                                        $('#event_locations_menu_options12').html($COUNTY);
                                    }
                                }
                            }
                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('map_identity_event_control',mutation.type+': childList');
                            /* --------------------------------------------------------  */
                        } 
                        translate_ajax_clear(true);
                    }
                    $RESULTS_CNTRL,$SEARCH_CNTRL = null;
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();
    })();/*END FUNCTION */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	function  CenterMap(long, lat, $zoom) {
		map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
		if($zoom == null){
			$zoom =8;
		}
		map.getView().setZoom($zoom);
	}/*END FUNCTION */
}
/* -------------------------------------------------------- */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function map_identity_country(){
	CONSOLE_MANAGER('map_identity_country','ACTIVATED');
	windowload_script('map_identity_country');
	
	/* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById('map');
    if (x.style.display === "none") {
        /* -------------------------------------------------------- */
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        /* ------------------------------------------------- */
        var raster = new TileLayer({
            source: new OSM()
        });
        /**/
        var style = new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.6)'
          }),
          stroke: new Stroke({
            color: '#319FD3',
            width: 1
          }),
          text: new Text({
            font: '12px Calibri,sans-serif',
            fill: new Fill({
              color: '#000'
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 3
            })
          })
        });

        var highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(255,255,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        var selected_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgb(0,191,255,0.4)'
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3
            })
        });

        const rollover_highlightStyle = new Style({
            fill: new Fill({
                color: 'rgba(0,255,0, 0.3)',
            }),
            stroke: new Stroke({
                color: '#3399CC',
                width: 3,
            }),
        });
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */
        /* ------------------------------------------------- */	
        /* ------------------------------------------------- */	
        var country_vector = new VectorLayer({
            source: new VectorSource({
                url: 'https://glbl-resources-public.s3.amazonaws.com/OL/countries.geojson',
                //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/countries.geojson',
                format: new GeoJSON()
            })
        });
        var state_vector = new VectorLayer({
            source: new VectorSource({
                url: 'https://glbl-resources-public.s3.amazonaws.com/OL/states.json',
                //url: 'https://s3.amazonaws.com/glbl-025498wzxxs-0knl-0001/OL/states.json',
                format: new GeoJSON()
            })
        });
        var view = new View({
            center: [0, 0],
            zoom: 2,
            extent: [-20037508.34, -20037508.34, 20037508.34, 20037508.34],
            multiWorld: true
        });
        /* -------------------------------------------------------- */
        var map = new Map({
            layers: [rasterLayer, country_vector],//,state_vector	raster	rasterLayer
            keyboardEventTarget: document,
            target: 'map',
            view: view,
            controls: defaultControls({
                zoom: false,
                attribution: false,
                rotate: false,
            }),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('map_identity_country','$INITAL_RENDER_CONTROL = FALSE');
                setTimeout(Initiate_coord, 1000);
                setTimeout(SET_GEOLOCATION(), 1000);
                map_key_activate(map,'items_settings_Page - MAPPING:item_map',parseInt(0));
                Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
                window_complete_script('items_settings_Page - LOADING CLOSE');
                $INITAL_RENDER_CONTROL = true;
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var selected = [];
        var status = document.getElementById('status');
        //var $status = false;
        map.on('singleclick', function(e) {
            map.forEachFeatureAtPixel(e.pixel, function(f) {
                var selIndex = selected.indexOf(f);
                if (selIndex < 0) {
                    selected.push(f);
                    f.setStyle(selected_highlightStyle);
                    REGISTER_CLICK('ACTIVE',e,e.pixel);
                } else {
                    selected.splice(selIndex, 1);
                    f.setStyle(undefined);// TexthighlightStyle
                    REGISTER_CLICK('DISABLE',e,e.pixel);
                }
            });
        });

        var highlight;
        var displayFeatureInfo = function(pixel) {
            var feature = map.forEachFeatureAtPixel(pixel, function(feature) {
                return feature;
            });
            var info = document.getElementById('identity_select_messagedisplayinput');
            var store = document.getElementById('standard_entity_modify_formEntity');
            if (feature) {
                var $GEO = feature.get('geometry');
                info.innerHTML = feature.get('name');
                $('#identity_select_messagedisplayinput').val(feature.get('name'));
                store.innerHTML = feature.getId();
                $('#standard_entity_modify_formEntity').val(feature.getId()+'|'+feature.get('name'));
                //$('#standard_entity_modify_formCoords').val(feature.getId()+'|'+feature.get('name'));
            } else {
                info.innerHTML = '&nbsp;';
            }
        }
        map.on('pointermove', function(evt) {
            if (evt.dragging) {
                return;
            }
            var pixel = map.getEventPixel(evt.originalEvent);
            displayFeatureInfo(pixel);
        });
        /* -------------------------------------------------------- */
        let roll_selected = null;
        map.on('pointermove', function (e) {
            if (roll_selected !== null) {
                /* -------------------------------------------------------- */
                if(document.getElementById("identity_select_identityCountryCode")){
                    if($('#identity_select_identityCountryCode').val()){
                        var jsonValidate  = isJSON($('#identity_select_identityCountryCode').val());
                        if(jsonValidate==true){
                            var arr = [];
                            //var selIndex2 = roll_selected.indexOf();
                            var $DATES_obj_array = JSON.parse($('#identity_select_identityCountryCode').val());
                            if(Object.keys($DATES_obj_array).length >0){
                                for (var $r=0; $r<Object.keys($DATES_obj_array).length; $r++){
                                    var res = $DATES_obj_array[$r];
                                    if(jQuery.inArray(roll_selected.getId(), $DATES_obj_array) !== -1){
                                        roll_selected.setStyle(selected_highlightStyle);
                                    }else{
                                        roll_selected.setStyle(undefined);
                                    }
                                }
                            }
                        }else{
                            CONSOLE_MANAGER('map_identity_country','forEachFeatureAtPixel: JSON ERROR');
                            roll_selected.setStyle(undefined);
                        }
                    }else{
                        roll_selected.setStyle(undefined);
                    }
                }
                roll_selected = null;
            }

            map.forEachFeatureAtPixel(e.pixel, function (featu) {
                roll_selected = featu;
                featu.setStyle(rollover_highlightStyle);
                return true;
            });
        });
    } else {
        x.style.display = "none";
    }
	/* --------------------------------------------------------  */
	var $INITAL_RENDER_CONTROL = false;
	var pre_run = false;
	function Initiate_coord(){
        map.on('click', function(e) {
            if(pre_run==false){
                country_vector.getSource().forEachFeature(function(f) {
                    if(e.id==f.getId()){
                        selected.push(f);
                        f.setStyle(selected_highlightStyle);
                    }
                });
            }else{
                CONSOLE_MANAGER('map_identity_country','Initiate_coord: JSON pre_run: '+pre_run);
            }	
        });
		/* --------------------------------------------------------  */
		if(document.getElementById("identity_select_identityCountryCode")){
			if($('#identity_select_identityCountryCode').val()){
				var jsonValidate  = isJSON($('#identity_select_identityCountryCode').val());
				if(jsonValidate==true){
					var arr = [];
					var $DATES_obj_array = JSON.parse($('#identity_select_identityCountryCode').val());
					if(Object.keys($DATES_obj_array).length >0){
						/* -------------------------------------------------------- */
                        if(Object.keys($DATES_obj_array).length >0){
							for (var $r=0; $r<Object.keys($DATES_obj_array).length; $r++){
								if($r==Object.keys($DATES_obj_array).length){
								   	pre_run = true;
									$INITAL_RENDER_CONTROL = true;
									break;
								}else{
									var res = $DATES_obj_array[$r];
									var evt = {};
									evt.type = 'click';
									evt.id = [res];
									map.dispatchEvent(evt);
								}
							}
						}
					}
				}else{
                    CONSOLE_MANAGER('map_identity_country','identity_select_identityCountryCode: JSON ERROR');
				}
				pre_run = true;
			}
		}else{
			pre_run = true;
		}
	}
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
    function find_update_key($VALUE){
        var elements = document.querySelectorAll(".identity_result_country_selected");
        for (var i = 0; i < elements.length; i++) {
            var image = elements[i];
            if(image.getAttribute('value') == $VALUE){
                image.remove();
            }
        }
    }
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
    function REGISTER_CLICK($STATUS,event,coords){
    	windowload_script('IDENTITY_basics (MAPPING)');
		$('#standard_entity_modify_formCoords').val(coords);
        $('#standard_entity_modify_control').val($('#identity_select_control').val());
        $('#standard_entity_modify_formFilter1').val($STATUS);
        settings_request_function(event);
		if($STATUS=='DISABLE'){
			find_update_key($('#identity_select_messagedisplayinput').val());
		}
    }
}
/* -------------------------------------------------------- */
/* --------------------------------------------------------  */
export function EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED(){
	CONSOLE_MANAGER('EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED','ACTIVATED');
	/* ------------------------------------------------- */	
	function calculateClusterInfo(resolution) {
	  maxFeatureCount = 0;
	  //var features = vector.getSource().getFeatures();
	var features = ajax_vector.getSource().getFeatures();
	  var feature, radius;
	  for (var i = features.length - 1; i >= 0; --i) {
		feature = features[i];
		
		var originalFeatures = feature.get('features');
		var extent = olExtent.createEmpty();
		var j, jj;
		var tmagnitude = [];
		for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
		  olExtent.extend(extent, originalFeatures[j].getGeometry().getExtent());
			
			//$tempFeature = feature.get('features')[0];
			tname = originalFeatures[j].get('name');
			tmagnitude.push(parseFloat(tname.substr(2)));
		}
		maxFeatureCount = Math.max(maxFeatureCount, jj);
		radius = 0.25 * (olExtent.getWidth(extent) + olExtent.getHeight(extent)) /
			resolution;
		feature.set('radius', radius);
		//feature.set('count_mag', jj);
		  
		  var total=0;
		  var e;
		  for(e = 0; e<tmagnitude.length;e++) { total += tmagnitude[e]; }
		  feature.set('count_mag', e);
			feature.set('count_magnitude', total);
	  	}
		
	}
	/* ------------------------------------------------- */	
	function calculatelikeajaxClusterInfo(resolution) {
	  maxFeatureCount = 0;
	 
	//var features = like_vector.getSource().getFeatures();
	var features = ajax_like_vector.getSource().getFeatures();
	  var feature, radius;
	  for (var i = features.length - 1; i >= 0; --i) {
		feature = features[i];
		
		var originalFeatures = feature.get('features');
		
		var extent = olExtent.createEmpty();
		var j, jj;
		var tmagnitude = [];
		for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
		  olExtent.extend(extent, originalFeatures[j].getGeometry().getExtent());
			
			
			tname = originalFeatures[j].get('name');
			
			tmagnitude.push(parseFloat(tname.substr(2)));
		}
		maxFeatureCount = Math.max(maxFeatureCount, jj);
		radius = 0.25 * (olExtent.getWidth(extent) + olExtent.getHeight(extent)) /
			resolution;
		feature.set('radius', radius);
		//feature.set('count_mag', jj);
		  
		  var total=0;
		  var e;
		  for(e = 0; e<tmagnitude.length;e++) { total += tmagnitude[e]; }
		  feature.set('count_mag', e);
			feature.set('count_magnitude', total);
	  	}
		
	}
	/* ------------------------------------------------- */	
	function calculatedislikeajaxClusterInfo(resolution) {
	  maxFeatureCount = 0;
	 
	//var features = like_vector.getSource().getFeatures();
	var features = ajax_dislike_vector.getSource().getFeatures();
	  var feature, radius;
	  for (var i = features.length - 1; i >= 0; --i) {
		feature = features[i];
		
		var originalFeatures = feature.get('features');
		
		var extent = olExtent.createEmpty();
		var j, jj;
		var tmagnitude = [];
		for (j = 0, jj = originalFeatures.length; j < jj; ++j) {
		  olExtent.extend(extent, originalFeatures[j].getGeometry().getExtent());
			
			
			tname = originalFeatures[j].get('name');
			
			tmagnitude.push(parseFloat(tname.substr(2)));
		}
		maxFeatureCount = Math.max(maxFeatureCount, jj);
		radius = 0.25 * (olExtent.getWidth(extent) + olExtent.getHeight(extent)) /
			resolution;
		feature.set('radius', radius);
		//feature.set('count_mag', jj);
		  
		  var total=0;
		  var e;
		  for(e = 0; e<tmagnitude.length;e++) { total += tmagnitude[e]; }
		  feature.set('count_mag', e);
			feature.set('count_magnitude', total);
	  	}
		
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */	
	function ajax_styleFunction(feature, resolution) {
	
		calculateClusterInfo(resolution);
		currentResolution = resolution;
	
	  var style;
	  var size = feature.get('features').length;
		
		$tempmag_count_text = 1;
		$temp_mag_count = feature.get('count_mag');
		$temp_magnitude_count = feature.get('count_magnitude');
		
		
		if($temp_magnitude_count){
			$tempmag_count_text = (feature.get('radius') * parseInt(5.1));
			/// $temp_magnitude_count / 
		}
		
	  if (size > 1) {
		
		style = new Style({
		  image: new CircleStyle({
			radius: feature.get('radius'),
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], Math.min(0.8, 0.4 + (size / maxFeatureCount))]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  } else {
		//var originalFeature = feature.get('features')[0];
		//style = createEarthquakeStyle(originalFeature);
		  
		  style = new Style({
		  image: new CircleStyle({
			radius: 5,
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], 0.7]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  }
	  return style;
	}
	/* ------------------------------------------------- */	
	function like_ajax_styleFunction(feature, resolution) {
	
		calculatelikeajaxClusterInfo(resolution);
		currentResolution = resolution;
	
	  var style;
	  var size = feature.get('features').length;
		$magFil = [100, 149, 237];
		
		$tempmag_count_text = 1;
		$temp_mag_count = feature.get('count_mag');
		$temp_magnitude_count = feature.get('count_magnitude');
		
		
		if($temp_magnitude_count){
			$tempmag_count_text = (feature.get('radius') * parseInt(5.1));
			/// $temp_magnitude_count / 
		}
		
		
	  if (size > 1) {
		style = new Style({
		  image: new CircleStyle({
			radius: feature.get('radius'),
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], Math.min(0.8, 0.4 + (size / maxFeatureCount))]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  } else {
		//var originalFeature = feature.get('features')[0];
		//style = createEarthquakeStyle(originalFeature);
		  
		  style = new Style({
		  image: new CircleStyle({
			radius: 5,
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], 0.7]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  }
	  return style;
	}
	/* ------------------------------------------------- */	
	function dislike_ajax_styleFunction(feature, resolution) {
		
		calculatedislikeajaxClusterInfo(resolution);
		currentResolution = resolution;
	
	  var style;
	  var size = feature.get('features').length;
		$magFil = [255, 41, 41];
		
		$tempmag_count_text = 1;
		$name_count = feature.get('name');
		
		
	  if (size > 1) {
		style = new Style({
		  image: new CircleStyle({
			radius: feature.get('radius'),
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], Math.min(0.8, 0.4 + (size / maxFeatureCount))]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  } else {
		//var originalFeature = feature.get('features')[0];
		//style = createEarthquakeStyle(originalFeature);
		  
		  style = new Style({
		  image: new CircleStyle({
			radius: 5,
			fill: new Fill({
			  color: [$magFil[0],$magFil[1],$magFil[2], 0.7]
			})
		  }),
		  text: new Text({
			text: size.toString(),
			//text: $tempmag_count_text,
			fill: textFill,
			stroke: textStroke
		  })
		});
	  }
	
	  return style;
	}
	function DisLikeProgress (oEvent) {
	  if (oEvent.lengthComputable) {
		var percentComplete = (oEvent.loaded / oEvent.total)*100;
		$('#progress_loader_text').html(Math.round(percentComplete)+'%');
		$('#progress_loader_bar').css({width:Math.round(percentComplete)+'%'});
	  } else {
	  }
	}

	function LikeProgress (oEvent) {
	  if (oEvent.lengthComputable) {
		var percentComplete = (oEvent.loaded / oEvent.total)*100;
		$('#progress_loader_text').html(Math.round(percentComplete)+'%');
		$('#progress_loader_bar').css({width:Math.round(percentComplete)+'%'});
	  } else {
	  }
	}
	/* ------------------------------------------------- */	
	//$DATA_URL = '/system/media/stat_ajax/'+$('#media_view_menu_uniqEventId').val()+'/'+$('#media_view_menu_registerCode').val()+'/'+$('#media_view_menu_name').val()+'/'+$('#media_view_menu_formCurrentIndex').val()+'/'+$('#media_pagination_options_limit').val()+'/'+$('#media_pagination_options_offset').val()+'/LIKE_STAT'
	//+'/'++'/'++
	//3097
	//$RNCOUNT = 0;
	pastResolution = 0; 
	
	var media_ajax_source = new VectorSource({
	  loader: function(extent, resolution, projection) {//
		var extent = map.getView().calculateExtent(map.getSize());
		extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
		map_match(map);
		/* ------------------------------------------------- */	
		//Transform function. Called with [minX, minY, maxX, maxY] extent coordinates.
/*
		  $DATA_URL = '/system/media/stat_ajax/'+$('#media_view_menu_uniqEventId').val()+'/'+$('#media_view_menu_registerCode').val()+'/'+$('#media_view_menu_name').val()+'/'+$('#media_view_menu_formCurrentIndex').val()+'/'+$('#media_pagination_options_limit').val()+'/'+$('#media_pagination_options_offset').val()+'/'+extent+'/'+map.getView().getZoom()+'/NULL'
*/
          $DATA_URL = '/system/media/stat_ajax/'+$UNIQUE_EVENT_ID+'/'+$UNIQUE_REG_ID+'/'+$UNIQUE_NAME+'/'+$UNIQUE_INDEX+'/'+$UNIQUE_LIMIT+'/'+$UNIQUE_OFFSET+'/'+extent+'/'+map.getView().getZoom()+'/NULL'
		  
		//  /system/media/stat_ajax/{MGMT1}/{MGMT2}/{MGMT3}/{MGMT4}/{MGMT5}//
		var url = $DATA_URL;
		XHR_Req_LIKE.addEventListener("progress", LikeProgress);
		XHR_Req_LIKE.addEventListener("load", transferComplete);
		XHR_Req_LIKE.addEventListener("error", transferFailed);
		XHR_Req_LIKE.addEventListener("abort", transferCanceled);
		XHR_Req_LIKE.onreadystatechange = function()
		{
			if (XHR_Req_LIKE.readyState == 4 && XHR_Req_LIKE.status == 200)
			{
				var format = new KML({
					extractStyles: false 
				});
				var features = format.readFeatures(XHR_Req_LIKE.responseText,
					{featureProjection: projection});
				
				//media_ajax_source.clear();
				 media_ajax_source.addFeatures(features);
				
				jQuery.extend({
					percentage: function(a, b) {
						return Math.round((a / b) * 100);
					}
				});
				//var tempfeatureCount = ajax_vector.getSource().getFeatures().length;
				var tempfeatureCount =0;
				var temptotalCount =0;
				var temppercentCount ='0%';
				
				if(features.length>0){
					tempfeatureCount = parseInt(features.length);
				}
				if(parseInt($('#media_pagination_options_formCount').val()) > 0){
					temptotalCount = parseInt($('#media_pagination_options_formCount').val());
				}
				if((temptotalCount > 0)&&(tempfeatureCount >0 )){
					temppercentCount = $.percentage(tempfeatureCount,temptotalCount)+'%';
				}
				if(features.length<1){
					//reset_media_count();
				}
				$('#media_pagination_options_dataCount').val(temppercentCount);
			}
		}
	
		
		XHR_Req_LIKE.open("POST", url, true);
		XHR_Req_LIKE.send();	
		//}  
		pastResolution = resolution;  
		//  $RNCOUNT = $RNCOUNT+1;
	  },
	 // strategy: bbox
	  //strategy: ol.loadingstrategy.all,
	});
	ajax_vector = new VectorLayer({
	 source: new Cluster({
		distance: 40,
		source:media_ajax_source ,
    }),
	 style: ajax_styleFunction
	});
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var media_like_ajax_source = new VectorSource({
	  loader: function(extent, resolution, projection) {//
		var extent = map.getView().calculateExtent(map.getSize());
		extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
		/* ------------------------------------------------- */	
		//Transform function. Called with [minX, minY, maxX, maxY] extent coordinates.
/*		$DATA_URL = '/system/media/stat_ajax/'+$('#media_view_menu_uniqEventId').val()+'/'+$('#media_view_menu_registerCode').val()+'/'+$('#media_view_menu_name').val()+'/'+$('#media_view_menu_formCurrentIndex').val()+'/'+$('#media_pagination_options_limit').val()+'/'+$('#media_pagination_options_offset').val()+'/'+extent+'/'+map.getView().getZoom()+'/LIKE_DATA'; 
		//  /system/media/stat_ajax/{MGMT1}/{MGMT2}/{MGMT3}/{MGMT4}/{MGMT5}//
*/		
		$DATA_URL = '/system/media/stat_ajax/'+$UNIQUE_EVENT_ID+'/'+$UNIQUE_REG_ID+'/'+$UNIQUE_NAME+'/'+$UNIQUE_INDEX+'/'+$UNIQUE_LIMIT+'/'+$UNIQUE_OFFSET+'/'+extent+'/'+map.getView().getZoom()+'/LIKE_DATA'
		  
		var url = $DATA_URL;
		
		window_start_load();
		XHR_Req_LIKE.addEventListener("progress", LikeProgress);
		XHR_Req_LIKE.addEventListener("load", transferComplete);
		XHR_Req_LIKE.addEventListener("error", transferFailed);
		XHR_Req_LIKE.addEventListener("abort", transferCanceled);
		XHR_Req_LIKE.onreadystatechange = function()
		{
			if (XHR_Req_LIKE.readyState == 4 && XHR_Req_LIKE.status == 200)
			{
				var format = new KML({
					extractStyles: false 
				});
				var features = format.readFeatures(XHR_Req_LIKE.responseText,
					{featureProjection: projection});
				
				 media_like_ajax_source.addFeatures(features);
				jQuery.extend({
					percentage: function(a, b) {
						return Math.round((a / b) * 100);
					}
				});
				//var tempfeatureCount = ajax_vector.getSource().getFeatures().length;
				var tempfeatureCount =0;
				var temptotalCount =0;
				var temppercentCount ='0%';
				
				if(features.length>0){
					tempfeatureCount = parseInt(features.length);
				}
				if(parseInt($('#media_pagination_options_formCount').val()) > 0){
					temptotalCount = parseInt($('#media_pagination_options_formCount').val());
				}
				if((temptotalCount > 0)&&(tempfeatureCount >0 )){
					temppercentCount = $.percentage(tempfeatureCount,temptotalCount)+'%';
				}
				if(features.length<1){
					//reset_media_count();
				}
				$('#media_pagination_options_dataCount').val(temppercentCount);
			}
		}
	
		XHR_Req_LIKE.open("POST", url, true);
		XHR_Req_LIKE.send();	
		//}  
		pastResolution = resolution;  
		//  $RNCOUNT = $RNCOUNT+1;
	  },
	  //strategy: bbox
	  //strategy: ol.loadingstrategy.all,
	});
	
	ajax_like_vector = new VectorLayer({
		source: new Cluster({
			distance: 40,
			source:media_like_ajax_source ,
    	}),
	 	style: like_ajax_styleFunction
	});
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var media_dislike_ajax_source = new VectorSource({
	  loader: function(extent, resolution, projection) {
		var extent = map.getView().calculateExtent(map.getSize());
		extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
		/* ------------------------------------------------- */	
		//Transform function. Called with [minX, minY, maxX, maxY] extent coordinates.
/*		$DATA_URL = '/system/media/stat_ajax/'+$('#media_view_menu_uniqEventId').val()+'/'+$('#media_view_menu_registerCode').val()+'/'+$('#media_view_menu_name').val()+'/'+$('#media_view_menu_formCurrentIndex').val()+'/'+$('#media_pagination_options_limit').val()+'/'+$('#media_pagination_options_offset').val()+'/'+extent+'/'+map.getView().getZoom()+'/DISLIKE_DATA'; 
*/
		//  /system/media/stat_ajax/{MGMT1}/{MGMT2}/{MGMT3}/{MGMT4}/{MGMT5}//
		
		$DATA_URL = '/system/media/stat_ajax/'+$UNIQUE_EVENT_ID+'/'+$UNIQUE_REG_ID+'/'+$UNIQUE_NAME+'/'+$UNIQUE_INDEX+'/'+$UNIQUE_LIMIT+'/'+$UNIQUE_OFFSET+'/'+extent+'/'+map.getView().getZoom()+'/DISLIKE_DATA'
		
		var url = $DATA_URL;
		
		//window_start_load();
		XHR_Req_DISLIKE.addEventListener("progress", DisLikeProgress);
		XHR_Req_DISLIKE.addEventListener("load", transferComplete);
		XHR_Req_DISLIKE.addEventListener("error", transferFailed);
		XHR_Req_DISLIKE.addEventListener("abort", transferCanceled);
		XHR_Req_DISLIKE.onreadystatechange = function()
		{
		
			if (XHR_Req_DISLIKE.readyState == 4 && XHR_Req_DISLIKE.status == 200)
			{
				var format = new KML({
					extractStyles: false 
				});
				var features = format.readFeatures(XHR_Req_DISLIKE.responseText,
					{featureProjection: projection});
				
				 media_dislike_ajax_source.addFeatures(features);
				
				jQuery.extend({
					percentage: function(a, b) {
						return Math.round((a / b) * 100);
					}
				});
				//var tempfeatureCount = ajax_vector.getSource().getFeatures().length;
				var tempfeatureCount =0;
				var temptotalCount =0;
				var temppercentCount ='0%';
				
				if(features.length>0){
					tempfeatureCount = parseInt(features.length);
				}
				if(parseInt($('#media_pagination_options_formCount').val()) > 0){
					temptotalCount = parseInt($('#media_pagination_options_formCount').val());
				}
				if((temptotalCount > 0)&&(tempfeatureCount >0 )){
					temppercentCount = $.percentage(tempfeatureCount,temptotalCount)+'%';
				}
				if(features.length<1){
					//reset_media_count();
				}
				$('#media_pagination_options_dataCount').val(temppercentCount);
			}
		}
	
		XHR_Req_DISLIKE.open("POST", url, true);
		XHR_Req_DISLIKE.send();	
		//}  
		pastResolution = resolution;  
		//  $RNCOUNT = $RNCOUNT+1;
		
	  },
	});
	ajax_dislike_vector = new VectorLayer({
	 source: new Cluster({
		distance: 40,
		source:media_dislike_ajax_source ,
    }),
	 style: dislike_ajax_styleFunction
	});
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var vectorGroup = new LayerGroup({
		layers: [ajax_like_vector,ajax_dislike_vector],//ajax_vector
		name: 'vector Group'
	});
	/* --------------------------------------------------------  
    var rasterLayer = new TileLayer({
        source: new Stamen({
            layer: 'toner-lite'
        })
    });
    */
    var rasterLayer = new TileLayer({
        source: new StadiaMaps({
            layer: 'stamen_toner_lite',
            retina: true,
        })
    });
    /* --------------------------------------------------------*/
	/* -------------------------------------------------------- */
	vectorGroup.once("change", function(evt) {
		var size =0;
		var extent = olExtent.createEmpty();
		evt.preventDefault();
		vectorGroup.getLayers().forEach(function(layer) {
			features = layer.getSource().getFeatures()
			var size = features.length
			if(size>0){
				olExtent.extend(extent, layer.getSource().getExtent());
				var layerlistenerKey = layer.getSource().on("change", function(evt) {
					var source = evt.target;
					if (source.getState() === 'undefined') {
                        CONSOLE_MANAGER('EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED','layer undefined');
                    }
                    else if (source.getState() === 'loading') {
                        CONSOLE_MANAGER('EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED','layer loading');
                    }
                    else if (source.getState() === 'ready') {
                        Observable.unByKey(layerlistenerKey);
                    }
                    else if (source.getState() === 'error') {
                        CONSOLE_MANAGER('EXTRA_MAP_FUNCTIONALTIY_THAT_HAS_BEEN_MOVED','layer error');
                    }
				});
			}
		});
		if(size>0){
		}
	});
	/* ------------------------------------------------- */	
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- *//* -------------------------------------------------------- */
	/* -------------------------------------------------------- *//* -------------------------------------------------------- */
	var vectorSource = new VectorSource({});
	/* -------------------------------------------------------- *//* -------------------------------------------------------- */
	/* -------------------------------------------------------- *//* -------------------------------------------------------- 
	var format = new KML({
		extractStyles: false 
	});
	
	var features = format.readFeatures($('#media_pagination_options_dislikeCoords').val(),{featureProjection: projection});
	
	var source = new VectorSource({
	  	features: features
	});

	var clusterSource = new Cluster({
	  	distance: 40,
	  	source: source
	});
	
	var media_like_source = new VectorLayer({
	 	source: clusterSource,
		strategy: bbox,
	  	style: dislike_styleFunction
	});
	*/
	/////////////////////
/*
	var format = new KML({
		extractStyles: false 
	});
	
	var features1 = format.readFeatures($('#media_pagination_options_likeCoords').val(),{featureProjection: projection});
	
	var source1 = new VectorSource({
	  	features: features1
	});

	var clusterSource1 = new Cluster({
	  	distance: 40,
	  	source: source1
	});
	
	var media_like_source = new VectorLayer({
	 	source: clusterSource1,
		strategy: bbox,
	  	style: like_styleFunction
	});
	/* -------------------------------------------------------- *//* -------------------------------------------------------- */
	/* -------------------------------------------------------- *//* -------------------------------------------------------- 
	var format = new KML({
		extractStyles: false 
	});
	
	var features = format.readFeatures($('#media_pagination_options_dislikeCoords').val(),{featureProjection: projection});
	
	var source = new VectorSource({
	  	features: features
	});

	var clusterSource = new Cluster({
	  	distance: 40,
	  	source: source
	});
	
	var media_dislike_source = new VectorLayer({
	 	source: clusterSource,
		strategy: bbox,
	  	style: dislike_styleFunction
	});
*/
	
	/* -------------------------------------------------------- *//* -------------------------------------------------------- */
	/*
	function MINI_MAP_UPDATE(){
		//media_view_menu_options5
		$('#media_view_menu_options4').unbind().click(function(event) {
			source.clear();		
		});
		$('#media_view_menu_options5').unbind().click(function(event) {
			source.clear();		
		});
	}
	//MINI_MAP_UPDATE();
	*/
	/* -------------------------------------------------------- *//* -------------------------------------------------------- 
	var vectorGroup_interaction = new LayerGroup({
		layers: [media_like_source,media_dislike_source],//dislike_vector
		name: 'Interaction Group'
	});
	
	var over_view_map = new Map({
		//target: 'over_view_window',
		target: $STATIC_NAME,
		layers: [rasterLayer,vectorGroup_interaction],
		controls: defaultControls({
		  	zoom: false,
		  	attribution: false,
		  	rotate: false,
		}),
		view: new View({
			center:[0,0],
			//center: transform([-73.983611111111,40.720833333333], 'EPSG:4326', 'EPSG:3857'),
			zoom: 3,
			//resolution: $RESOLUTION,
			minZoom: 2,
			maxZoom: 3
	  	})
	});

	var gridview = new ol.Graticule({
	  strokeStyle: new Stroke({
		color: [0, 0, 0, 1],
		width: .5
	  }),
	  targetSize: 50
	});
	//gridview.setMap(over_view_map);
	//////////////
	
	/////////////
	over_view_map.on('postrender', function() {
		var view = over_view_map.getView();
		var zoom = view.getZoom();
		var worldExtent = view.getProjection().getExtent();
		var extent = view.calculateExtent(over_view_map.getSize());
		
		if (!olExtent.containsExtent(worldExtent, extent)) {
			var newExtent = olExtent.getIntersection(extent, restrictedExtent);
			view.fit(newExtent, over_view_map.getSize());
		}
	});
*/
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	function map_match(main_map){
		//over_view_map.setLayerGroup(new LayerGroup());
		$location = main_map.getView().getCenter();
		var pan = ol.animation.pan({
          source: main_map.getView().getCenter()
        });
        main_map.beforeRender(pan);
        main_map.getView().setCenter($location);
		
		var extent = main_map.getView().calculateExtent(main_map.getSize());
		extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
		/*
		var style = {
		   strokeColor: "#00FF00",
		   strokeOpacity: 1,
		   strokeWidth: 3,
		   fillColor: "#00FF00",
		   fillOpacity: 0.8
		}; 
		
		var styles = {
			'square': new Style({
			  image: new ol.style.RegularShape({
				fill: fill,
				stroke: stroke,
				points: 4,
				radius: 10,
				angle: Math.PI / 4
			  })
			})
		};
		*/
		
		//var vectorLayer = new VectorLayer({});
		//var vectorSource = new VectorSource({});
		
		var polyFeature = new Feature({
			geometry: new Polygon([
				[
					[extent[2], extent[3]],
					[extent[2], extent[1]],
					[extent[0], extent[1]],
					[extent[0], extent[3]],
					[extent[2], extent[3]]
				]
			])
		});
		polyFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857');
		style = new Style({
            stroke: new Stroke({ 
				color: 'rgba(0,0,255, 0.8)',
				width: 2,
			}),
			fill: new Fill({
				color: 'rgba(255,255,255, 0.2)',
			}),
            text: new Text({
               // text: feature.get('name'),
               // font: '12px Calibri,sans-serif',
                fill: new Fill({ color: 'rgba(255,255,255, 0.3)' }),
                //stroke: new Stroke({
                //    color: '#fff', width: 2
                //})
            })
        });
        polyFeature.setStyle(style);
		
		vectorSource.clear();
		vectorSource.addFeature(polyFeature);
		
		var vectorLayer = new VectorLayer({
        	source: vectorSource
      	});
		main_map.addLayer(vectorLayer);
		
		main_map.on('click', function(evt) {
			/* // you want to detect feature click
			var feature = theMap.forEachFeatureAtPixel(evt.pixel,
				function(feature, layer) {
				  return feature;
				});
			if (feature) {
			  var fname = feature.getProperties().name;
			  return;
			}
			*/
			//main_map.getView().setCenter(evt.coordinate);
			$location = evt.coordinate;
			var pan = ol.animation.pan({
			  source: main_map.getView().getCenter()
			});
			main_map.beforeRender(pan);
			main_map.getView().setCenter($location);
		  });
	}
	/* -------------------------------------------------------- */
	/* --------------------------------------------------------  
	if(form_entity.includes('profile_image_windowfunction_MGMT')){
		$UNIQUE_EVENT_ID = Math.floor((Math.random()*100) + 1);
		$UNIQUE_REG_ID = Math.floor((Math.random()*100) + 1);
		$UNIQUE_NAME = $('#media_view_menu_username').val();
		$UNIQUE_INDEX = $('#media_view_menu_name').val();
		//$UNIQUE_INDEX = $('#media_view_menu_formCurrentIndex').val();
		$UNIQUE_LIMIT = 1;
		$UNIQUE_OFFSET = 0;
		$STATIC_NAME = 'map_no_swipe';
		//target: document.getElementById('map_no_swipe'),
	}else{
		$UNIQUE_EVENT_ID = $('#media_view_menu_uniqEventId').val();
		$UNIQUE_REG_ID = $('#media_view_menu_registerCode').val();
		$UNIQUE_NAME = $('#media_view_menu_name').val();
		$UNIQUE_INDEX = $('#media_view_menu_formCurrentIndex').val();
		$UNIQUE_LIMIT = $('#media_pagination_options_limit').val();
		$UNIQUE_OFFSET = $('#media_pagination_options_offset').val();
		$STATIC_NAME = '';
	}*/	
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	//map.addLayer(vectorGroup);
	//var extent = map.getView().calculateExtent(map.getSize());
	//extent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
	/* ------------------------------------------------- */	
	var vectorSource = new VectorSource({
		//url: 'https://developers.google.com/kml/documentation/KML_Samples.kml',
		//url: 'https://openlayers.org/en/latest/examples/data/kml/2012-02-10.kml',
		url: '../images/2012-02-10.kml',
		format: new KML({
			extractStyles: false
		}),
		
	});
	maxFeatureCount = 20000;
	var styleCache = {};
	var vectorLayer = new VectorLayer({
		source: vectorSource,
		distance: parseInt(10, 10),
		style: function(feature) {
		//var size = feature.get('features').length;
		var size = parseInt(10 * Math.floor(Math.random() * 11));
		var style = styleCache[size];
		if (!style) {
			style = new Style({
			  	image: new CircleStyle({
				//radius: feature.get('radius'),
				radius: 2 * Math.floor(Math.random() * 11),
				fill: new Fill({
				  	//color: [25,24,145, Math.min(0.8, 0.4 + (size / maxFeatureCount))]
					color: [25,24,145, 0.6]
				})
			  }),
			  text: new Text({
				//text: size.toString(),
				//text: $tempmag_count_text,
				fill: textFill,
				stroke: textStroke
			  })
			});
		  styleCache[size] = style;
		}
		return style;
	  }
	});
	/* --------------------------------------------------------  */
	var vectorGroup = new VectorLayer({
		source: vectorSource,//clusterSource
		style: function(feature) {
		//var size = feature.get('features').length;
			var size = parseInt(10 * Math.floor(Math.random() * 11));
			var style = styleCache[size];
			if (!style) {
				style = new Style({
					image: new CircleStyle({
					//radius: feature.get('radius'),
					radius: 2 * Math.floor(Math.random() * 11),
					fill: new Fill({
						//color: [25,24,145, Math.min(0.8, 0.4 + (size / maxFeatureCount))]
						color: [25,24,145, 0.6]
					})
				  }),
				  text: new Text({
					//text: size.toString(),
					//text: $tempmag_count_text,
					fill: textFill,
					stroke: textStroke
				  })
				});
			  styleCache[size] = style;
			}
			return style;
		}
	});
	//map.addLayer(vectorGroup);
	/* --------------------------------------------------------  */
	function getLikeAttributes($SIZE){
		var Count = parseInt(10);
		var Color = Color = [46, 148, 189, 0.39];
		
		if($SIZE>1000){
			Count = 30;
			Color = [37, 119, 152, 0.39]
		}
		else if($SIZE>150){
			Count = 20;
			Color = [37, 119, 152, 0.39]
		}
		else if($SIZE>140){
			Count = 7;
			Color = [37, 119, 152, 0.39]
		}
		else if($SIZE>110){
			Count = 7;
			Color = [37, 119, 152, 0.39]
		}
		else if($SIZE>80){
			Count = 7;
			Color = [37, 119, 152, 0.39]
		}
		else if($SIZE>50){
			Count = 6;
			Color = [78, 134, 212, 0.34]
		}
		else if($SIZE>20){
			Count = 6;
			Color = [78, 134, 212, 0.34]
		}
		else if($SIZE>10){
			Count = 6;
			Color = [78, 134, 212, 0.34]
		}
		else if($SIZE>0){
			if($SIZE>7){
				Count = 5;
				Color = [46, 148, 189, 0.39]
			}
			else if($SIZE>5){
				Count =5;
				Color = [46, 148, 189, 0.39]
			}
			else if($SIZE>1){
				Count = 5;
				Color = [46, 148, 189, 0.39]
			}
			else if($SIZE>0){
				Count = 5;
				Color = [46, 148, 189, 0.39]
			}
		}
		return [Count, Color];
	}
	/* --------------------------------------------------------  */
	function getdisLikeAttributes($SIZE){
		var Count = parseInt(10);
		var Color = Color = [255, 41, 41, 0.34];
		
		if($SIZE>1000){
			Count = 30;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>150){
			Count = 20;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>140){
			Count = 7;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>110){
			Count = 7;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>80){
			Count = 7;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>50){
			Count = 6;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>20){
			Count = 6;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>10){
			Count = 6;
			Color = [255, 41, 41, 0.34]
		}
		else if($SIZE>0){
			if($SIZE>7){
				Count = 5;
				Color = [255, 41, 41, 0.34]
			}
			else if($SIZE>5){
				Count = 5;
				Color = [255, 41, 41, 0.34]
			}
			else if($SIZE>1){
				Count = 5;
				Color = [255, 41, 41, 0.34]
			}
			else if($SIZE>0){
				Count = 5;
				Color = [255, 41, 41, 0.34]
			}
		}
		return [Count, Color];
	}
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	var vectorSource = new VectorSource({
		//url: 'https://developers.google.com/kml/documentation/KML_Samples.kml',
		//url: 'https://openlayers.org/en/latest/examples/data/kml/2012-02-10.kml',
		url: '../images/2012-02-10.kml',
		format: new KML({
			extractStyles: false
		}),
		
	});
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var earthquakeFill = new Fill({
	  color: 'rgba(255, 153, 0, 0.8)'
	});
	var earthquakeStroke = new Stroke({
	  color: 'rgba(255, 204, 0, 0.2)',
	  width: 1
	});
	function createEarthquakeStyle(feature) {
	  // 2012_Earthquakes_Mag5.kml stores the magnitude of each earthquake in a
	  // standards-violating <magnitude> tag in each Placemark.  We extract it
	  // from the Placemark's name instead.
	  var name = feature.get('name');
	  var magnitude = parseFloat(name.substr(2));
	  var radius = 5 + 20 * (magnitude - 5);

	  return new Style({
		geometry: feature.getGeometry(),
		image: new ol.style.RegularShape({
		  radius1: radius,
		  radius2: 3,
		  points: 5,
		  angle: Math.PI,
		  fill: earthquakeFill,
		  stroke: earthquakeStroke
		})
	  });
	}

}
/* ------------------------------------------------- */	
/* ------------------------------------------------- */
export function business_recommendations_map(){
	CONSOLE_MANAGER('business_recommendations_map','ACTIVATED');
	if($('#map').length){
		windowload_script('business_recommendations_map');
		
        $("#map").css({display: "none"});
        var x = document.getElementById('map');
        if (x.style.display === "none") {
            var vectorSource = new VectorSource({});
            /* ------------------------------------------------- */	
            /* ------------------------------------------------- */	
            var vectorGroup = new LayerGroup({
                source: vectorSource,
                name: 'vector Group'
            }); 
            var vectorLayerRegisterDates = new VectorLayer({
                source: initiatRecommendationPins(),			
            });
            /* ------------------------------------------------- */	
            /* --------------------------------------------------------  
            var rasterLayer = new TileLayer({
                source: new Stamen({
                    layer: 'toner-lite'
                })
            });
            */
            var rasterLayer = new TileLayer({
                source: new StadiaMaps({
                    layer: 'stamen_toner_lite',
                    retina: true,
                })
            });
            /* --------------------------------------------------------*/
            rasterLayer.on(["precompose", "prerender"], function(event) {

            });
            rasterLayer.on(["postcompose", "postrender"], function(event) {

            });
            /* -------------------------------------------------------- */
            vectorGroup.once("change", function(evt) {
                var size =0;
                var extent = olExtent.createEmpty();
                evt.preventDefault();
                vectorGroup.getLayers().forEach(function(layer) {
                    features = layer.getSource().getFeatures()
                    var size = features.length;
                    if(size>0){
                        olExtent.extend(extent, layer.getSource().getExtent());
                        var layerlistenerKey = layer.getSource().on("change", function(evt) {
                            var source = evt.target;
                            if (source.getState() === 'undefined') {
                                CONSOLE_MANAGER('business_recommendations_map','layer undefined');
                            }
                            else if (source.getState() === 'loading') {
                                CONSOLE_MANAGER('business_recommendations_map','layer loading');
                            }
                            else if (source.getState() === 'ready') {
                                Observable.unByKey(layerlistenerKey);
                            }
                            else if (source.getState() === 'error') {
                                CONSOLE_MANAGER('business_recommendations_map','layer error');
                            }
                        });
                    }
                });
                /* --------------------------------------------------------  */
                /**	ZOOM AND PAN ACTION	***/	//duration: 1500,duration: 1500,
                //pan = ol.animation.pan({duration: 100, source: map.getView().getCenter()});
                //zoom = ol.animation.zoom({duration: 100, resolution: map.getView().getResolution()});
                //map.beforeRender(pan, zoom);
                if(size>0){
                    //map.beforeRender(pan, zoom);
                    ///map.beforeRender(zoom);//pan, 
                    //map.getView().fit(extent, map.getSize());
                }
            });
            /* ------------------------------------------------- */
            var map = new Map({
                layers: [
                    rasterLayer,
                    new LayerGroup({
                      layers: [
                        vectorGroup,
                        vectorLayerRegisterDates
                      ]
                    })
                ],//vectorLayerRegisterDates	vectorGroup_multif
                controls: defaultControls({
                    zoom: false,
                    attribution: false,
                    rotate: false,
                }),
                target: 'map',
                view: new View({
                    center:[0,0],
                    zoom: 3,
                    //resolution: $RESOLUTION,
                    minZoom: 3,
                    maxZoom: 18
                })
            });
            /* -------------------------------------------------------- */
            x.style.display = "block";
            map.updateSize();
        } else {
            x.style.display = "none";
        }
		/* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
		var $INITAL_RENDER_CONTROL = false;
		/* -------------------------------------------------------- *//* -------------------------------------------------------- */
		/* -------------------------------------------------------- *//* -------------------------------------------------------- */
		map.on('rendercomplete', e => {
			if(($INITAL_RENDER_CONTROL==false)){
                CONSOLE_MANAGER('business_recommendations_map','$INITAL_RENDER_CONTROL = FALSE');
				setTimeout(SET_GEOLOCATION(), 1000);
				map_key_activate(map,'business_recommendations_map - MAPPING:item_map',parseInt(0));
				Temp_CenterMap(Number($('#map_key_lon').val()), Number($('#map_key_lat').val()), parseInt(2), map, false);
				window_complete_script('business_recommendations_map - LOADING CLOSE');
				$INITAL_RENDER_CONTROL = true;
			}
		})
		/* -------------------------------------------------------- *//* -------------------------------------------------------- */
		/* -------------------------------------------------------- */
		var restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
		map.on('postrender', function() {
			var view = map.getView();
			var zoom = view.getZoom();
			var worldExtent = view.getProjection().getExtent();
			var extent = view.calculateExtent(map.getSize());
			//////////********************
			if (!olExtent.containsExtent(worldExtent, extent)) {
				var newExtent = olExtent.getIntersection(extent, restrictedExtent);
				view.fit(newExtent, map.getSize());
			}
		});

		var projection = getProjection('EPSG:3857');
		var world_extent = projection.getWorldExtent();
		/* --------------------------------------------------------*/
		/* ------------------------------------------------- */
        function CenterMap(long, lat, $zoom) {
            map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
            if($zoom == null){
                $zoom =3;
            }
            map.getView().setZoom($zoom);
        }/*END FUNCTION */
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
		function AddFeature(long, lat) {
			var iconFeature = new Feature({      
                geometry: new Point(transform([long,lat], 'EPSG:4326', 'EPSG:3857')),
                //name: 'NULL'
            });
			iconFeature.setStyle(new Style({
                image: new CircleStyle({
                    radius: 8,
                    stroke: new Stroke({
                        color: 'rgba(239,45,239, 0.7)',
                        width: 3,
                        //lineCap: 'round' /* square */,
                        lineJoin: 'bevel' /* round */,
                    }),
                    fill: new Fill({
                        color: 'rgba(47,45,239, 0.7)'
                    })
                })
            }));
			
			//tempSource.addFeature(iconFeature);
			var tempSource = new VectorSource({
				features: [iconFeature]
			});
            var tempLayer = new VectorLayer({
				 source: tempSource
			 });
			
            map.addLayer(tempLayer);
			
			$( "#location_search_request_search_parameters").on('change', function(){
				if(tempLayer.getSource()){
					tempLayer.getSource().removeFeature(iconFeature)
				}
			});
        }/*END FUNCTION */
        /* --------------------------------------------------------  */
		if(document.getElementById("location_search_request_lat")){
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('location_search_request_lat');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							if($("#location_search_request_lat").val()){
                                CONSOLE_MANAGER('business_recommendations_map','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									CONSOLE_MANAGER('business_recommendations_map',mutation.type+': attributes');
									CenterMap($("#location_search_request_lon").val(),$("#location_search_request_lat").val(),12);
									AddFeature($("#location_search_request_lon").val(),$("#location_search_request_lat").val());
									/* -------------------------------------------------------- */
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('business_recommendations_map',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		}
		/* ------------------------------------------------- */
		/* ------------------------------------------------- */
	}
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   					                            ########################## */			
/* --------------------------------------------------------  */
export function matrix_map_activate(){
	CONSOLE_MANAGER('matrix_map_activate','ACTIVATED');
	windowload_script('matrix_map_activate');
	
    /* -------------------------------------------------------- */	
    //terrain watercolor toner	toner-lite
    /* -------------------------------------------------------- */
    $("#map").css({display: "none"});
    var x = document.getElementById('map');
    if (x.style.display === "none") {
        /* -------------------------------------------------------- */
        var vectorDatepins = new VectorSource({});
        var vectorPathpins = new VectorSource({});
        var $vectorpins = new VectorSource({});
        var $DATES_obj_array=[];
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */
        var ZOOM_DISTANCE_00 = parseInt(12);
        var ZOOM_DISTANCE_01 = parseInt(9.5);
        var ZOOM_DISTANCE_02 = parseInt(6.5);
        var ZOOM_DISTANCE_03 = parseInt(4);
        var ZOOM_DISTANCE_04 = parseInt(3);
        var ZOOM_DISTANCE_05 = parseInt(2);
        var ZOOM_DISTANCE_06 = parseInt(1);

        var RADIUS_DISTANCE_00 = parseInt(100);
        var RADIUS_DISTANCE_01 = parseInt(150);
        var RADIUS_DISTANCE_02 = parseInt(190);
        var RADIUS_DISTANCE_03 = parseInt(201);
        var RADIUS_DISTANCE_04 = parseInt(250);
        var RADIUS_DISTANCE_05 = parseInt(350);
        var RADIUS_DISTANCE_06 = parseInt(500);
        /* --------------------------------------------------------  
        var rasterLayer = new TileLayer({
            source: new Stamen({
                layer: 'toner-lite'
            })
        });
        */
        var rasterLayer = new TileLayer({
            source: new StadiaMaps({
                layer: 'stamen_toner_lite',
                retina: true,
            })
        });
        /* --------------------------------------------------------*/
        rasterLayer.on(["precompose", "prerender"], function(event) {

        });
        rasterLayer.on(["postcompose", "postrender"], function(event) {

        });
        /* --------------------------------------------------------*/
        var vectorLayerRegisterDates = new VectorLayer({
            source: initiatMicroPins(rasterLayer),			
        });
        /* --------------------------------------------------------*/
        var vectorGroup_multif = new LayerGroup({//vector_draw,	,vectorLayerMapPath
            layers: [vectorLayerRegisterDates],
            name: 'vector Group'
        });
        /* -------------------------------------------------------- */
        var map = new Map({
            layers: [rasterLayer],//,vectorGroup_multif
            controls: defaultControls({
              zoom: false,
              attribution: false,
              rotate: false,
            }),
            //maxResolution: 'auto',
            target: document.getElementById('map'),
            view: new View({
                //minZoom: 9,
                //maxZoom: 14,
                center:[0,0],
                //center: transform([$LONGITUDE,$LATITUDE], 'EPSG:4326', 'EPSG:3857'),
                zoom: 8,
            }),
            interactions: defaultInteractions({
            doubleClickZoom: false,
            dragAndDrop: false,
            dragPan: false,
            keyboardPan: false,
            keyboardZoom: false,
            mouseWheelZoom: false,
            pointer: true,
            select: true
          }).extend([
            new MouseWheelZoom({
              condition: platformModifierKeyOnly,
            }) ]),
        });
        /* -------------------------------------------------------- */
        x.style.display = "block";
        map.updateSize();
        /* -------------------------------------------------------- */
        if($('#map')){
           $('#map').data('map', map);
        }
        /* -------------------------------------------------------- */
        SET_STADIA_ATTRIBUTION($('#map').attr('id'));
        /* -------------------------------------------------------- */
        var $INITAL_RENDER_CONTROL = false;
        /* --------------------------------------------------------  */

        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        map.on('rendercomplete', e => {
            if(($INITAL_RENDER_CONTROL==true)){
                window_complete_script('matrix_map_activate - LOADING CLOSE');
            }
            if(($INITAL_RENDER_CONTROL==false)){
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), parseInt(12), map, false);
                /* -------------------------------------------------------- */
                //setTimeout(SET_GEOLOCATION(), 1000);
                $INITAL_RENDER_CONTROL = true;
                /* --------------------------------------------------------  */
                var vectorRADIUSSource = new VectorSource();
                //var vectorRADIUSSource = new ol.source.Vector({});
                $("#matrix_form_formFilter2").on('change', function(){
                    //vectorRADIUSSource.clear();
                    //if(vectorUser.getSource()){
                    //    vectorUser.getSource().removeFeature(iconUser_Feature)
                    //}
                });
                /* --------------------------------------------------------  */
                if($('#matrix_form_formFilter2').val()){
                    var jsonValidate  = isJSON($('#matrix_form_formFilter2').val());
                    if(jsonValidate==true){
                        var $DATES_obj_array = JSON.parse($('#matrix_form_formFilter2').val());
                        var $i=0;
                        for($i=parseInt(6); $i<12;$i++){
                            if($('#matrix_form_options'+$i).is(':visible')){
                                modify_active_sub1($('#matrix_form_options'+$i),$i,vectorRADIUSSource);
                                $.each($DATES_obj_array, function (index, value) {
                                    if($i==index){
                                        if(parseInt(value)){
                                            CONSOLE_MANAGER('matrix_map_activate','VALUE = '+value);
                                            $('#matrix_form_options'+parseInt($i)).addClass('form_filter_menu_option_2_active');
                                            /* --------------------------------------------------------  */
                                            if(value==12){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_06, map, false);
                                                set_radius(RADIUS_DISTANCE_06,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else if(value==11){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_05, map, false);
                                                set_radius(RADIUS_DISTANCE_05,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else if(value==10){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_04, map, false);
                                                set_radius(RADIUS_DISTANCE_04,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else if(value==9){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_03, map, false);
                                                set_radius(RADIUS_DISTANCE_03,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else if(value==8){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_02, map, false);
                                                set_radius(RADIUS_DISTANCE_02,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else if(value==7){
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_01, map, false);
                                                set_radius(RADIUS_DISTANCE_01,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                            /* --------------------------------------------------------  */
                                            else{
                                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_00, map, false);
                                                set_radius(RADIUS_DISTANCE_00,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                                            }
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
                function modify_active_sub1($Button,$Button_var,vectorRADIUSSource){
                    $Button.unbind().click(function(event) {
                        event.stopPropagation;
                        if($Button.is(':visible') && $Button.hasClass('form_filter_menu_option_2_active')){
                            $Button.removeClass('form_filter_menu_option_2_active');
                            update_matrix_array($('#matrix_form_formFilter2'),$('#matrix_form_index2'),$Button_var,false);
                            Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_00, map, false);
                            set_radius(RADIUS_DISTANCE_00,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                        }else{
                            $Button.addClass('form_filter_menu_option_2_active');
                            update_matrix_array($('#matrix_form_formFilter2'),$('#matrix_form_index2'),$Button_var,true);
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options6'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()),ZOOM_DISTANCE_01, map, false);
                                set_radius(RADIUS_DISTANCE_01,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options7'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()),ZOOM_DISTANCE_02, map, false);
                                set_radius(RADIUS_DISTANCE_02,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options8'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()),ZOOM_DISTANCE_03, map, false);
                                set_radius(RADIUS_DISTANCE_03,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options9'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()),ZOOM_DISTANCE_04, map, false);
                                set_radius(RADIUS_DISTANCE_04,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options10'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()),ZOOM_DISTANCE_05, map, false);
                                set_radius(RADIUS_DISTANCE_05,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                            /* --------------------------------------------------------  */
                            if($Button.attr('id')=='matrix_form_options11'){
                                Temp_CenterMap(Number($('#matrix_form_lon').val()), Number($('#matrix_form_lat').val()), ZOOM_DISTANCE_06, map, false);
                                set_radius(RADIUS_DISTANCE_06,$('#matrix_form_lat').val(),$('#matrix_form_lon').val(),map,vectorRADIUSSource);
                            }
                        }
                    });
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
            }
        })
        /* -------------------------------------------------------- *//* -------------------------------------------------------- */
        var $tempextent = olExtent.createEmpty();
        var $tempcount = 0;
        var $tempsum = 0;
        var $tempfeatures = [];
        vectorGroup_multif.getLayers().forEach(function(layer) {
            olExtent.extend($tempextent, layer.getSource().getExtent());
            var features = layer.getSource().getFeatures();
            $tempfeatures[$tempcount]  = parseInt(features.length);
            $tempcount++;
        });
        $.each($tempfeatures,function(){$tempsum+=parseFloat(this) || 0;});
        if($tempsum > 0){
            map.getView().fit($tempextent, map.getSize());
        }
        /* -------------------------------------------------------- */	
        var  restrictedExtent = [-20037508.34, -20037508.34, 20037508.34, 20037508.34];
        map.on('postrender', function() {
            var view = map.getView();
            var zoom = view.getZoom();
            var worldExtent = view.getProjection().getExtent();
            var extent_mgmt = view.calculateExtent(map.getSize());
            //////////********************
            if (!olExtent.containsExtent(worldExtent, extent_mgmt)) {
                var newExtent = olExtent.getIntersection(extent_mgmt, restrictedExtent);
                view.fit(newExtent, map.getSize());
            }
            /* --------------------------------------------------------  */
        });
    } else {
        x.style.display = "none";
    }
    /* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
    function CenterMap(long, lat, $zoom) {
        map.getView().setCenter(transform([long, lat], 'EPSG:4326', 'EPSG:3857'));
        if($zoom == null){
            $zoom =8;
        }
        map.getView().setZoom($zoom);
    }/*END FUNCTION */
    /* ------------------------------------------------- */
   
}
/* --------------------------------------------------------  */
export function set_radius($RADIUS,$LAT,$LONG,$MAP,$VECTOR){
    CONSOLE_MANAGER('set_radius','$RADIUS = '+$RADIUS);
    setTimeout(function(){
        var iconUser_Feature = new Feature({
            geometry: new Point(transform([Number($LONG),Number($LAT)], 'EPSG:4326', 'EPSG:3857'))
        });
        iconUser_Feature.setStyle(new Style({
            image: new CircleStyle({
                radius: $RADIUS,
                stroke: new Stroke({
                    color: 'rgba(204, 204, 204, 0.1)',
                    width: 3,
                    //lineCap: 'round' /* square */,
                    lineJoin: 'bevel' /* round */,
                }),
                fill: new Fill({
                    color: 'rgba(4, 144, 242, 0.1)',
                })
            })
        }));
        $VECTOR.clear();
        $VECTOR.addFeature(iconUser_Feature);
        var vectorRADIUS = new VectorLayer({
            source: $VECTOR,   
        });
        $MAP.addLayer(vectorRADIUS);
    }, 0)
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
