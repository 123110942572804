/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  
/*  photobucket_image_select
/*	media_image_select
/*  event_header_image_select0
/*  event_header_image_select1
/*  event_header_image_select2
/*  event_header_image_select3
/*  event_header_image_select4
/*  event_header_image_select5
/*  event_header_image_select6
/*  event_header_image_select7
/*  event_header_image_select8
/*  event_header_image_select9
/*  event_header_image_select10
/*  crop_preview
/*  profile_image_select
/*  event_image_brandmark_select
/*  business_image_select
/*  business_image_brandmark_select
/*  business_image_lettermark_select
/*  business_header_image_select1
/*  business_header_image_select2
/*  business_header_image_select3
/*  business_header_image_select4
/*  business_header_image_select5
/*  business_header_image_select6
/*  business_header_image_select7
/*  business_header_image_select8
/*  business_header_image_select9
/*  
/*  event_header_image_select7
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';
/********************/
/********************/
import Cropper from 'cropperjs';
//import React from 'react';
//import ReactDom from 'react-dom';
/*
import "jquery-ui/ui/widgets/datepicker";
import "jquery-datetimepicker";
import "jquery-mousewheel";
import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
const showSecond = true;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm';
import 'rc-time-picker/assets/index.css';
import Dropzone from './dropzone.js';
*/
var momenttz = require('moment-timezone');
import * as moment from 'moment';
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { feed_translate_ajax_data as feed_translate_ajax_data } from "./mgmt_control.js";

import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { starttime_window_open as starttime_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { initiate_starttime_creator as initiate_starttime_creator } from "./mgmt_control.js";
import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { activate_Hammer_swipe_MGMT as activate_Hammer_swipe_MGMT } from "./mgmt_control.js";
import { option_page_men as option_page_men } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";
import { event_item_map_activate as event_item_map_activate} from "./mgmt_mapping.js";
import { map_identity_event_control as map_identity_event_control} from "./mgmt_mapping.js";

import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { duration as duration } from "./mgmt_control.js";
import { micro_duration as micro_duration } from "./mgmt_control.js";

import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_control.js";
import { text_to_field as text_to_field } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";

import { ACTIVATE_UPLOAD_FORM_LISTED as ACTIVATE_UPLOAD_FORM_LISTED } from "./mgmt_control.js";
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function photobucket_image_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 4 / 3, //(RATIO: )
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('photobucket_image_select',+"\n"+'     aspectRatio: 4 / 3'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function media_image_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
         //aspectRatio: 16 / 9,
		aspectRatio: 1.91 / 1, //(RATIO: )
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('media_image_select',+"\n"+'     aspectRatio: 1.91 / 1'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select0(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / .817, //width - divided by - height = 2.4482
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select0',+"\n"+'     aspectRatio: 2 / .817'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select1(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 16 / 5.8, //(RATIO: )
        aspectRatio: 2 / .817, //width - divided by - height = 2.4482
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select1',+"\n"+'     aspectRatio: 2 / .817'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select2(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 4 / 1.748,
        aspectRatio: 2 / 1.77, //width - divided by - height = 1.1368
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select2',+"\n"+'     aspectRatio: 2 / 1.77'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select3(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
       	//aspectRatio: 2 / 3.053, 
        aspectRatio: 2 / .883, //width - divided by - height = 2.26688
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select3',+"\n"+'     aspectRatio: 2 / .883'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select4(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 2 / 3.053, 
        aspectRatio: 2 / .883, //width - divided by - height = 2.26688
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select4',+"\n"+'     aspectRatio: 2 / .883'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select5(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 3 / .88, //width - divided by - height = 3.4057
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select5',+"\n"+'     aspectRatio: 3 / .88'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select6(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 3 / .88, //width - divided by - height = 3.4057
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select6',+"\n"+'     aspectRatio: 3 / .88'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select7(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 3 / .88, //width - divided by - height = 3.4057
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select7',+"\n"+'     aspectRatio: 3 / .88'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select8(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 1.29, //width - divided by - height = 1.551
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select8',+"\n"+'     aspectRatio: 2 / 1.29'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select9(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 2.275, //width - divided by - height = .8793
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select9',+"\n"+'     aspectRatio: 2 / 2.275'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_header_image_select10(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 3.053,
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_header_image_select10',+"\n"+'     aspectRatio: 2 / 3.053'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function crop_preview($EVENT) {
    $('#image_editor_main_x1').val(parseInt($EVENT.detail.x));
    $('#image_editor_main_y1').val(parseInt($EVENT.detail.y));
    $('#image_editor_main_x2').val(parseInt($EVENT.detail.scaleX));
    $('#image_editor_main_y2').val(parseInt($EVENT.detail.scaleY));
    $('#image_editor_main_width').val(parseInt($EVENT.detail.width));	
    $('#image_editor_main_height').val(parseInt($EVENT.detail.height));
    CONSOLE_MANAGER('crop_preview','ACTIVATED'+"\n"+'     $EVENT.detail.x: '+$EVENT.detail.x+"\n"+'     $EVENT.detail.y: '+$EVENT.detail.y+"\n"+'     $EVENT.detail.width: '+$EVENT.detail.width+"\n"+'     $EVENT.detail.height: '+$EVENT.detail.height+"\n"+'     $EVENT.detail.rotate: '+$EVENT.detail.rotate+"\n"+'     $EVENT.detail.scaleX: '+$EVENT.detail.scaleX+"\n"+'     $EVENT.detail.scaleY: '+$EVENT.detail.scaleY);
} 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function profile_image_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 16 / 9,
		aspectRatio: 4 / 3, //(RATIO: )
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('profile_image_select',+"\n"+'     aspectRatio: 4 / 3'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function event_image_brandmark_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 16 / 5.55, //(RATIO: )
		aspectRatio: 4 / 4,
		//aspectRatio: 3 / 2,
		//aspectRatio: 9 / 16,
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('event_image_brandmark_select',+"\n"+'     aspectRatio: 4 / 4'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_image_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 16 / 5.55, //(RATIO: )
		//aspectRatio: 4 / 3,
		//aspectRatio: 3 / 2,
		//aspectRatio: 9 / 16,
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_image_select',+"\n"+'     aspectRatio: 16 / 5.55'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_image_brandmark_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        //aspectRatio: 16 / 5.55, //(RATIO: )
		aspectRatio: 2 / 2,
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_image_brandmark_select',+"\n"+'     aspectRatio: 2 / 2'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_image_lettermark_select(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		//aspectRatio: 4 / 1.5,
        aspectRatio: 2 / 0.8695652,
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_image_lettermark_select',+"\n"+'     aspectRatio: 2 / 0.8695652'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select1(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		aspectRatio: 2 / 0.638297, //(2/3.133333)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select1',+"\n"+'     aspectRatio: 2 / .53191'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select2(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		aspectRatio: 2 / 1.31276, //(2/1.5235)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select2',+"\n"+'     aspectRatio: 2 / 1.31276'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select3(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		aspectRatio: 2 / 1.18518, //(1.6875)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select3',+"\n"+'     aspectRatio: 2 / 1.18518'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select4(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		aspectRatio: 2 / .53191, //(3.76)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select4',+"\n"+'     aspectRatio: 2 / .53191'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select5(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 0.34042, //(5.875)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select5',+"\n"+'     aspectRatio: 2 / 0.34042'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select6(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 0.446808, //(4.47619)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select6',+"\n"+'     aspectRatio: 2 / 0.446808'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select7(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 4.25, //(.4708)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select7',+"\n"+'     aspectRatio: 1 / 2.07'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select8(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
        aspectRatio: 2 / 4.215, //(.4741)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select8',+"\n"+'     aspectRatio: 3 / 5.209'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function business_header_image_select9(){
	SET_BUTTON_CLICK_ACTION("image_editor_main_options1");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options2");
	SET_BUTTON_CLICK_ACTION("image_editor_main_options3");
	/* --------------------------------------------------------  */
	var url = document.getElementById("image_editor_main_image_var_load").value;
	$("#crop_image").attr("src",url);
    const image = document.getElementById('crop_image');
    const cropper = new Cropper(image, {
		aspectRatio: 3 / 5.17, //(.5809)
		viewMode: 2,
        crop(event) {
            CONSOLE_MANAGER('business_header_image_select9',+"\n"+'     aspectRatio: 3 / 5.201'+"\n"+'     getCropBoxData: '+cropper.getCropBoxData()+"\n"+'     getImageData: '+cropper.getImageData());
            crop_preview(event);
        },
    });
}/*END FUNCTION*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/