/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 


/*
userlog_active_menu_options1
 BUSINESS_reporting_basics 
 */
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* ###############   	FUNCTION_REGISTRATION            		########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';
import "jquery-slidePanel";

var momenttz = require('moment-timezone');
import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 

import { GeocodingApi, Configuration } from '@stadiamaps/api';
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { windowUnload_script as windowUnload_script } from "./mgmt_control.js";
import { window_complete_load as window_complete_load } from "./mgmt_control.js";
import { window_start_load as window_start_load } from "./mgmt_control.js";
import { transferComplete as transferComplete } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";

import { ERROR_basics as ERROR_basics } from "./mgmt_control.js";

import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { HOME_ACTIVITY_basics as HOME_ACTIVITY_basics } from "./mgmt_control.js";
import { SYSTEM_basics as SYSTEM_basics } from "./mgmt_control.js";
import { OPEN_basics as OPEN_basics } from "./mgmt_control.js";
import { FEEDBACK_basics as FEEDBACK_basics } from "./mgmt_control.js";
import { DASHBOARD_CONTROL_basics as DASHBOARD_CONTROL_basics } from "./mgmt_control.js";
import { CALENDAR_basics as CALENDAR_basics } from "./mgmt_control.js";
import { CALENDAR_index as CALENDAR_index } from "./mgmt_control.js";
import { CALENDAR_special as CALENDAR_special } from "./mgmt_control.js";
import { holiday_event_manager_MGMT as holiday_event_manager_MGMT } from "./mgmt_control.js";
import { MAILBOX_MAIL_Scrolliew_basics as MAILBOX_MAIL_Scrolliew_basics } from "./mgmt_control.js";
import { SOCIAL_MEDIA_CONTROL_basics as SOCIAL_MEDIA_CONTROL_basics } from "./mgmt_control.js";

import { Media_upload_function as Media_upload_function } from "./mgmt_control.js";
import { BUSINESS_PHOTO_basics as BUSINESS_PHOTO_basics } from "./mgmt_control.js";
import { PROFILE_PHOTO_basics as PROFILE_PHOTO_basics } from "./mgmt_control.js";
import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";
import { MEDIA_PHOTO_basics as MEDIA_PHOTO_basics } from "./mgmt_control.js";
import { PHOTOBUCKET_PHOTO_basics as PHOTOBUCKET_PHOTO_basics } from "./mgmt_control.js";
import { PROFILE_SOCIAL_MEDIA_basics as PROFILE_SOCIAL_MEDIA_basics } from "./mgmt_control.js";
import { BEFORE_YOU_GO_basics as BEFORE_YOU_GO_basics } from "./mgmt_control.js";
import { CONFIGURATION_SOCIAL_MEDIA_basics as CONFIGURATION_SOCIAL_MEDIA_basics } from "./mgmt_control.js";
import { METRICS_SOCIAL_MEDIA_basics as METRICS_SOCIAL_MEDIA_basics } from "./mgmt_control.js";
import { CATALOG_SOCIAL_SHARE_basics as CATALOG_SOCIAL_SHARE_basics } from "./mgmt_control.js";
import { PAGES_SOCIAL_SHARE_basics as PAGES_SOCIAL_SHARE_basics } from "./mgmt_control.js";
import { METRICS_reporting_basics as METRICS_reporting_basics } from "./mgmt_control.js";
import { CONFIGURATION_SEARCH_ENGINE_basics as CONFIGURATION_SEARCH_ENGINE_basics } from "./mgmt_control.js";

import { CONFIGURATION_SOCIAL_MEDIA_POP_basics as CONFIGURATION_SOCIAL_MEDIA_POP_basics } from "./mgmt_control.js";
import { MEMBERSHIP_Account_basics as MEMBERSHIP_Account_basics } from "./mgmt_control.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { ADMIN_LISTVIEW_basics as ADMIN_LISTVIEW_basics } from "./mgmt_admin.js";

/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { PROFILE_basics as PROFILE_basics } from "./mgmt_profile.js";
import { PROFILE_listview_basics as PROFILE_listview_basics } from "./mgmt_profile.js";
import { PROFILE_imagelistview_basics as PROFILE_imagelistview_basics } from "./mgmt_profile.js";
import { MEMBER_basics as MEMBER_basics } from "./mgmt_profile.js";
import { MEMBER_listview_basics as MEMBER_listview_basics } from "./mgmt_profile.js";
import { MEMBER_imagelistview_basics as MEMBER_imagelistview_basics } from "./mgmt_profile.js";
import { GENERAL_Settings_basics as GENERAL_Settings_basics } from "./mgmt_profile.js";
import { SECURITY_listview_basics as SECURITY_listview_basics } from "./mgmt_profile.js";
import { PROFILE_PIC_basics as PROFILE_PIC_basics } from "./mgmt_profile.js";
import { GENERAL_Account_basics as GENERAL_Account_basics } from "./mgmt_profile.js";
import { SELECTION_Account_basics as SELECTION_Account_basics } from "./mgmt_profile.js";
import { HISTORY_Account_basics as HISTORY_Account_basics } from "./mgmt_profile.js";
//import { form_profileimageview_ajax_MGMT as form_profileimageview_ajax_MGMT } from "./mgmt_profile.js";
import { RECOMMENDATIONS_SELECT_basics as RECOMMENDATIONS_SELECT_basics } from "./mgmt_profile.js";
import { IDENTITY_SELECT_basics as IDENTITY_SELECT_basics } from "./mgmt_profile.js";
import { IDENTITY_basics as IDENTITY_basics } from "./mgmt_profile.js";
import { MEMBER_Media as MEMBER_Media } from "./mgmt_profile.js";
import { PROFILE_EVENT_Media as PROFILE_EVENT_Media } from "./mgmt_profile.js";
import { profile_instance_function as profile_instance_function } from "./mgmt_profile.js";
import { MAILBOX_listview_basics as MAILBOX_listview_basics } from "./mgmt_profile.js";
import { MAILBOX_SELECT_listview_basics as MAILBOX_SELECT_listview_basics } from "./mgmt_profile.js";
import { MAILBOX_MAIL_listview_basics as MAILBOX_MAIL_listview_basics } from "./mgmt_profile.js";

/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { REGISTRATION_basics as REGISTRATION_basics } from "./mgmt_access.js";
import { LOGIN_basics as LOGIN_basics } from "./mgmt_access.js";
import { RESET_basics as RESET_basics } from "./mgmt_access.js";
import { ACTIVATION_basics as ACTIVATION_basics } from "./mgmt_access.js";
import { RESET_ACTIVATION_basics as RESET_ACTIVATION_basics } from "./mgmt_access.js";
import { REGISTRATION_SOCIAL_basics as REGISTRATION_SOCIAL_basics } from "./mgmt_access.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { Activity_scroll_function_MGMT as Activity_scroll_function_MGMT } from "./mgmt_feed.js";
import { ACTIVITY_MATRIX_basics as ACTIVITY_MATRIX_basics } from "./mgmt_feed.js";
import { Activity_feed_function_MGMT as Activity_feed_function_MGMT } from "./mgmt_feed.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_metrics.js";
import { WINDOW_PROFILE_METRICS_CONTROL_basics as WINDOW_PROFILE_METRICS_CONTROL_basics } from "./mgmt_metrics.js";
/*  
/*  
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { photobucket_image_select as photobucket_image_select } from "./mgmt_image.js";
import { media_image_select as media_image_select } from "./mgmt_image.js";

import { event_header_image_select0 as event_header_image_select0 } from "./mgmt_image.js";
import { event_header_image_select1 as event_header_image_select1 } from "./mgmt_image.js";
import { event_header_image_select2 as event_header_image_select2 } from "./mgmt_image.js";
import { event_header_image_select3 as event_header_image_select3 } from "./mgmt_image.js";
import { event_header_image_select4 as event_header_image_select4 } from "./mgmt_image.js";
import { event_header_image_select5 as event_header_image_select5 } from "./mgmt_image.js";
import { event_header_image_select6 as event_header_image_select6 } from "./mgmt_image.js";
import { event_header_image_select7 as event_header_image_select7 } from "./mgmt_image.js";

import { crop_preview as crop_preview } from "./mgmt_image.js";
import { profile_image_select as profile_image_select } from "./mgmt_image.js";
import { event_image_brandmark_select as event_image_brandmark_select } from "./mgmt_image.js";
import { business_image_select as business_image_select } from "./mgmt_image.js";
import { business_image_brandmark_select as business_image_brandmark_select } from "./mgmt_image.js";

import { business_image_lettermark_select as business_image_lettermark_select } from "./mgmt_image.js";
import { business_image_block_select as business_image_block_select } from "./mgmt_image.js";
import { business_image_large_block_select as business_image_large_block_select } from "./mgmt_image.js";
import { business_image_small_block_select as business_image_small_block_select } from "./mgmt_image.js";

import { business_image_slice_select as business_image_slice_select } from "./mgmt_image.js";
import { business_image_slice6_select as business_image_slice6_select } from "./mgmt_image.js";
import { business_image_slice7_select as business_image_slice7_select } from "./mgmt_image.js";
import { business_image_slice8_select as business_image_slice8_select } from "./mgmt_image.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { form_event_maincurrence_MGMT as form_event_maincurrence_MGMT } from "./mgmt_event.js";
import { form_event_manager_MGMT as form_event_manager_MGMT } from "./mgmt_event.js";
import { form_event_register_MGMT as form_event_register_MGMT } from "./mgmt_event.js";
import { EVENT_RECOMMENDATIONS_SELECT_basics as EVENT_RECOMMENDATIONS_SELECT_basics } from "./mgmt_event.js";
import { EVENT_ITEM_RECOMMENDATIONS_SELECT_basics as EVENT_ITEM_RECOMMENDATIONS_SELECT_basics } from "./mgmt_event.js";
import { EVENT_LISTVIEW_basics as EVENT_LISTVIEW_basics } from "./mgmt_event.js";
import { EVENT_TYPE_SELECT_basics as EVENT_TYPE_SELECT_basics } from "./mgmt_event.js";
//import { EVENT_COMPATIBILITY_basics as EVENT_COMPATIBILITY_basics } from "./mgmt_event.js";
import { EVENT_COHOST_SELECT_basics as EVENT_COHOST_SELECT_basics } from "./mgmt_event.js";
import { form_event_creator_MGMT as form_event_creator_MGMT } from "./mgmt_event.js";
import { event_instance_function as event_instance_function } from "./mgmt_event.js";
import { EVENT_MAP_basics as EVENT_MAP_basics } from "./mgmt_event.js";
import { EVENT_GUIDELINES_SELECT_basics as EVENT_GUIDELINES_SELECT_basics } from "./mgmt_event.js";
import { EVENT_Catalog as EVENT_Catalog } from "./mgmt_event.js";
import { EVENT_Media as EVENT_Media } from "./mgmt_event.js";
import { EVENT_INVITATIONS_SELECT_basics as EVENT_INVITATIONS_SELECT_basics } from "./mgmt_event.js";
import { EVENT_Upload_Media as EVENT_Upload_Media } from "./mgmt_event.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { ITEM_SETTINGS_basics_MGMT as ITEM_SETTINGS_basics_MGMT } from "./mgmt_items.js";
import { EVENT_ITEM_CHECKIN_basics as EVENT_ITEM_CHECKIN_basics } from "./mgmt_items.js";
import { EVENT_ITEM_LOCATION_basics as EVENT_ITEM_LOCATION_basics } from "./mgmt_items.js";
import { ITEM_ITINERARY_basics_MGMT as ITEM_ITINERARY_basics_MGMT } from "./mgmt_items.js";
import { ITEM_ITINERARY_create_MGMT as ITEM_ITINERARY_create_MGMT } from "./mgmt_items.js";
import { event_item_instance_function as event_item_instance_function } from "./mgmt_items.js";
import { ITEM_SEATING_basics_MGMT as ITEM_SEATING_basics_MGMT } from "./mgmt_items.js";

import { HOLIDAY_ITEM_SETTINGS_basics_MGMT as HOLIDAY_ITEM_SETTINGS_basics_MGMT } from "./mgmt_items.js";
import { HOLIDAY_ITEM_LOCATION_basics as HOLIDAY_ITEM_LOCATION_basics } from "./mgmt_items.js";
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { BUSINESS_TYPE_SELECT_basics as BUSINESS_TYPE_SELECT_basics } from "./mgmt_business.js";
import { BUSINESS_registration_basics as BUSINESS_registration_basics } from "./mgmt_business.js";
import { BUSINESS_basics as BUSINESS_basics } from "./mgmt_business.js";
import { BUSINESS_recommendation_select_basics as BUSINESS_recommendation_select_basics } from "./mgmt_business.js";
import { BUSINESS_recommendation_management_basics as BUSINESS_recommendation_management_basics } from "./mgmt_business.js";
import { BUSINESS_reporting_basics as BUSINESS_reporting_basics } from "./mgmt_business.js";
import { BUSINESS_settings_basics as BUSINESS_settings_basics } from "./mgmt_business.js";
import { BUSINESS_account_basics as BUSINESS_account_basics } from "./mgmt_business.js";
import { BUSINESS_COHOST_SELECT_basics as BUSINESS_COHOST_SELECT_basics } from "./mgmt_business.js";
import { BUSINESS_Catalog as BUSINESS_Catalog } from "./mgmt_business.js";
import { business_instance_function as business_instance_function } from "./mgmt_business.js";

/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { BUSINESS_COHOST_INDEX_basics as BUSINESS_COHOST_INDEX_basics } from "./mgmt_business_cohost.js";

/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
import { activate_Stat_view_MGMT as activate_Stat_view_MGMT } from "./mgmt_mapping.js";
import { image_meta_map_activate as image_meta_map_activate } from "./mgmt_mapping.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { address_update as address_update } from "./mgmt_mapping.js";
import { metrics_meta_map_activate as metrics_meta_map_activate } from "./mgmt_mapping.js";
import { SET_GEOLOCATION as SET_GEOLOCATION } from "./mgmt_mapping.js";
import { profile_geocode as profile_geocode } from "./mgmt_mapping.js";
import { initiatPhotobucketPins as initiatPhotobucketPins } from "./mgmt_mapping.js";

/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001 mutat */
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
var LoadingMsg = '../images/bkgrounds/loader.gif';
var CDN_MAIN_MGMT = '../';
var CORS_MGMT = 'http://ec2-52-4-253-139.compute-1.amazonaws.com:80/';
var dyn_www = window.location.protocol + "//" + window.location.host;
var dyn_page = window.location.pathname;
//var dyn_sub_folder = "/";
if(dyn_page.indexOf('app') > -1){
	var dyn_sub_folder = "/app.php/";
    CONSOLE_MANAGER('ACTIVE dyn_sub_folder',dyn_page);
}
if(dyn_page.indexOf('app') <= 0){
	var dyn_sub_folder = "/";
    CONSOLE_MANAGER('NON-ACTIVE dyn_sub_folder',dyn_page);
}
var dyn_address = dyn_www+dyn_sub_folder;
FUNC_CONTROL(dyn_www,dyn_page,dyn_sub_folder);	
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* ###############   		    COOKIE MANAGER          		########################## */
$(document).ready(function(){
    if(getCookie("HideCookie") != "true") {
        $("#cookie-consent").css('display','block');
    }
    /*
    document.addEventListener('contextmenu', event => event.preventDefault());
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode == 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }
    */
});
/* --------------------------------------------------------  */
if($('#cookie')){
	$('#cookie').unbind().click(function(event) {
		SetCookieAndHideDiv(event);
	});
}
export function SetCookieAndHideDiv(e){
    setCookie('HideCookie','true',1);
    $("#cookie-consent").css('display','none');
}
export function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');

    for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
    }
    return "";
}
export function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function changeMetaContent(metaName, newMetaContent) {
    CONSOLE_MANAGER('changeMetaContent','ACTIVATED: '+metaName+' - '+newMetaContent);
    $("meta").each(function() {

        if($(this).attr("name") == metaName) {
            $(this).attr("content" , newMetaContent);
            CONSOLE_MANAGER('changeMetaContent',metaName+' : '+newMetaContent);
        }
        else if($(this).attr("property") == metaName) {
            $(this).attr("content" , newMetaContent);
            CONSOLE_MANAGER('changeMetaContent',metaName+' : '+newMetaContent);
        };
    });
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* ###############   	center_overlay  	            		########################## */
export function Center_window_overlay() {
	var winW = window.innerWidth;
	var winH = window.innerHeight;
	/* -------------------------------------------------------- */		
	if($("#window_overlay").is(":visible") == true){
		var window_overlay = document.getElementById('window_overlay');
	}
	/* -------------------------------------------------------- */
	if($("#photo_overlay").is(":visible") == true){
		var window_overlay = document.getElementById('photo_overlay');
	}
	/* -------------------------------------------------------- */		
	if($("#window_view").is(":visible") == true){
		window_overlay.style.display = "block";
		window_overlay.style.height = winH+"px";
		window_overlay.style.width = winW+"px";
		var dialogbox = document.getElementById('window_view');
		dialogbox.style.left = (winW/2) - (955 * .5)+"px";
		dialogbox.style.display = "block";
	}		
	/* -------------------------------------------------------- */
};
var resizeTimer;
$(window).resize(function() {
	/* --------------------------------------------------------  */
	if($("#window_overlay").is(":visible") == true){	
		clearTimeout(resizeTimer);	
		resizeTimer = setTimeout(Center_window_overlay, 50);
        CONSOLE_MANAGER('$(window_overlay).resize()','ACTIVATED');
	}
	/* --------------------------------------------------------  */
	if($("#photo_overlay").is(":visible") == true){	
		clearTimeout(resizeTimer);	
		resizeTimer = setTimeout(Center_window_overlay, 50);
        CONSOLE_MANAGER('$(photo_overlay).resize()','ACTIVATED');
	}
	/* --------------------------------------------------------  */
});	FUNC_CONTROL
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	    addLoadEvent                ########################## */
export function addLoadEvent(func) {
  var oldonload = window.onload;
  if (typeof window.onload != 'function') {
    window.onload = func;
  } else {
    window.onload = function() {
      if (oldonload) {
        oldonload();
      }
      func();
    }
  }
}
/* -----  */
/* -----  */
export function FUNC_CONTROL(dyn_www_VAR,dyn_page_VAR,dyn_sub_folder){

}
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
export function mobileverify(){
	var dmgmt=false;
    var isMobile = false; 
    if($("#MOBILE_MANAGER")){
       dmgmt=$("#MOBILE_MANAGER").val();
    }
	// device detection
	/* --------------------------------------------------------  */
	if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
		|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
		isMobile = true;
	}
	/* --------------------------------------------------------  */
	CONSOLE_MANAGER('MOBILE VERIFY','MOBILE Verify - isMobile: '+isMobile+' dmgmt: '+dmgmt);
	return isMobile;
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	    FUNCTION_REGISTRATION .                 ########################## */
var FUNCTION_REGISTRATION = (function () {
 	$(document).ready(function(){
		CONSOLE_MANAGER('FUNCTION_REGISTRATION','CONTACT');
		if ($('#page_load_mgmt__index1').length){
            CONSOLE_MANAGER('FUNCTION_REGISTRATION',$('#page_load_mgmt__index1').val()+' - '+jQuery.type($('#page_load_mgmt__index1').val()));
			var fnstring = $('#page_load_mgmt__index1').val();
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            
            if(fnstring=='ADMIN_LISTVIEW_basics'){
			   ADMIN_LISTVIEW_basics();
			}
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* mgmt_control	-------------------------------------------  */
			if(fnstring=='HOME_ACTIVITY_basics'){
			   HOME_ACTIVITY_basics();
			}
            if(fnstring=='ERROR_basics'){
			   ERROR_basics();
			}
			if(fnstring=='SYSTEM_basics'){
			   SYSTEM_basics();
			}
            if(fnstring=='OPEN_basics'){
			   OPEN_basics();
			}
            if(fnstring=='BEFORE_YOU_GO_basics'){
			   BEFORE_YOU_GO_basics();
			}
			if(fnstring=='FEEDBACK_basics'){
			   FEEDBACK_basics();
			}
            if(fnstring=='MEMBERSHIP_Account_basics'){
			   MEMBERSHIP_Account_basics();
			}
			if(fnstring=='Feedback_option_selection_MGMT'){
			   Feedback_option_selection_MGMT();
			}
			if(fnstring=='CALENDAR_basics'){
			   CALENDAR_basics();
			}
			if(fnstring=='CALENDAR_index'){
			   CALENDAR_index();
			}
			if(fnstring=='CALENDAR_special'){
			   CALENDAR_special();
			}
			if(fnstring=='holiday_event_manager_MGMT'){
			   holiday_event_manager_MGMT();
			}
			if(fnstring=='Activity_feed_function_MGMT'){
			   Activity_feed_function_MGMT();
			}
			if(fnstring=='Activity_scroll_function_MGMT'){
			   Activity_scroll_function_MGMT();
			}
			if(fnstring=='GENERALevent_settings_function_MGMT'){
			   GENERALevent_settings_function_MGMT();
			}
			if(fnstring=='Media_upload_function'){
			   Media_upload_function();
			}
			if(fnstring=='ACTIVITY_MATRIX_basics'){
			   ACTIVITY_MATRIX_basics();
			}
            if(fnstring=='METRICS_reporting_basics'){
			   METRICS_reporting_basics();
			}
            if(fnstring=='CONFIGURATION_SEARCH_ENGINE_basics'){
			   CONFIGURATION_SEARCH_ENGINE_basics();
			}
			/* --------------------------------------------------------  */
			/* mgmt_access	-------------------------------------------  */
			if(fnstring=='REGISTRATION_basics'){
			   REGISTRATION_basics();
			}
			if(fnstring=='LOGIN_basics'){
				CONSOLE_MANAGER('FUNCTION_REGISTRATION','READY');
			   LOGIN_basics();
			}
			if(fnstring=='RESET_basics'){
			   RESET_basics();
			}
            if(fnstring=='ACTIVATION_basics'){
			   ACTIVATION_basics();
			}
            if(fnstring=='RESET_ACTIVATION_basics'){
			   RESET_ACTIVATION_basics();
			}
            if(fnstring=='REGISTRATION_SOCIAL_basics'){
			   REGISTRATION_SOCIAL_basics();
			}
			/* --------------------------------------------------------  */
			/* mgmt_profile	-------------------------------------------  */
			if(fnstring=='PROFILE_basics'){
			   PROFILE_basics();
			}
			if(fnstring=='PROFILE_listview_basics'){
			   PROFILE_listview_basics();
			}
			if(fnstring=='MAILBOX_listview_basics'){
			   MAILBOX_listview_basics();
			}
			if(fnstring=='MAILBOX_SELECT_listview_basics'){
			   MAILBOX_SELECT_listview_basics();
			}
			if(fnstring=='MAILBOX_MAIL_listview_basics'){
			   MAILBOX_MAIL_listview_basics();
			}
			if(fnstring=='MAILBOX_MAIL_Scrolliew_basics'){
			   MAILBOX_MAIL_Scrolliew_basics();
			}
			if(fnstring=='PROFILE_imagelistview_basics'){
			   PROFILE_imagelistview_basics();
			}
			if(fnstring=='MEMBER_basics'){
			   MEMBER_basics();
			}
			if(fnstring=='MEMBER_Media'){
			   MEMBER_Media();
			}
			if(fnstring=='MEMBER_listview_basics'){
			   MEMBER_listview_basics();
			}
			if(fnstring=='MEMBER_imagelistview_basics'){
			   MEMBER_imagelistview_basics();
			}
			if(fnstring=='GENERAL_Settings_basics'){
			   GENERAL_Settings_basics();
			}
			if(fnstring=='SECURITY_listview_basics'){
			   SECURITY_listview_basics();
			}
			if(fnstring=='PROFILE_PIC_basics'){
			   PROFILE_PIC_basics();
			}
			if(fnstring=='GENERAL_Account_basics'){
			   GENERAL_Account_basics();
			}
			if(fnstring=='SELECTION_Account_basics'){
			   SELECTION_Account_basics();
			}
			if(fnstring=='HISTORY_Account_basics'){
			   HISTORY_Account_basics();
			}
			if(fnstring=='RECOMMENDATIONS_SELECT_basics'){
			   RECOMMENDATIONS_SELECT_basics();
			}
			if(fnstring=='IDENTITY_SELECT_basics'){
			   IDENTITY_SELECT_basics();
			}
			if(fnstring=='profile_image_windowfunction_MGMT'){
			   profile_image_windowfunction_MGMT();
			}
			if(fnstring=='IDENTITY_basics'){
			   IDENTITY_basics();
			}
			if(fnstring=='DASHBOARD_CONTROL_basics'){
			   DASHBOARD_CONTROL_basics();
			}
			if(fnstring=='SOCIAL_MEDIA_CONTROL_basics'){
			   SOCIAL_MEDIA_CONTROL_basics();
			}
			if(fnstring=='WINDOW_PROFILE_METRICS_CONTROL_basics'){
			   WINDOW_PROFILE_METRICS_CONTROL_basics();
			}
			if(fnstring=='PROFILE_EVENT_Media'){
			   PROFILE_EVENT_Media();
			}
			/* --------------------------------------------------------  */
			/* mgmt_event	-------------------------------------------  */
			if(fnstring=='form_event_creator_MGMT'){
			   form_event_creator_MGMT();
			}
			if(fnstring=='form_event_maincurrence_MGMT'){
			   form_event_maincurrence_MGMT();
			}
			if(fnstring=='form_event_manager_MGMT'){
			   form_event_manager_MGMT();
			}
			if(fnstring=='form_event_register_MGMT'){
			   form_event_register_MGMT();
			}
			if(fnstring=='EVENT_RECOMMENDATIONS_SELECT_basics'){
			   EVENT_RECOMMENDATIONS_SELECT_basics();
			}
			if(fnstring=='EVENT_ITEM_RECOMMENDATIONS_SELECT_basics'){
			   EVENT_ITEM_RECOMMENDATIONS_SELECT_basics();
			}
			if(fnstring=='EVENT_LISTVIEW_basics'){
			   EVENT_LISTVIEW_basics();
			}
			if(fnstring=='EVENT_TYPE_SELECT_basics'){
			   EVENT_TYPE_SELECT_basics();
			}
			if(fnstring=='EVENT_PHOTO_basics'){
			   EVENT_PHOTO_basics();
			}
			if(fnstring=='EVENT_MAP_basics'){
			   EVENT_MAP_basics();
			}
			if(fnstring=='EVENT_GUIDELINES_SELECT_basics'){
			   EVENT_GUIDELINES_SELECT_basics();
			}
			if(fnstring=='EVENT_Catalog'){
			   EVENT_Catalog();
			}
			if(fnstring=='EVENT_Media'){
			   EVENT_Media();
			}
            if(fnstring=='EVENT_Upload_Media'){
			   EVENT_Upload_Media();
			}
			if(fnstring=='EVENT_INVITATIONS_SELECT_basics'){
			   EVENT_INVITATIONS_SELECT_basics();
			}
			if(fnstring=='ITEM_ITINERARY_basics_MGMT'){
			   ITEM_ITINERARY_basics_MGMT();
			}
			if(fnstring=='ITEM_ITINERARY_create_MGMT'){
			   ITEM_ITINERARY_create_MGMT();
			}
            /* --------------------------------------------------------  */
            if(fnstring=='HOLIDAY_ITEM_SETTINGS_basics_MGMT'){
			   HOLIDAY_ITEM_SETTINGS_basics_MGMT();
			}
            if(fnstring=='HOLIDAY_ITEM_LOCATION_basics'){
			   HOLIDAY_ITEM_LOCATION_basics();
			}//NOTIFICATIONSYSTEM_LOAD_MGMT  event_settings_menu_options8
			/* --------------------------------------------------------  */
			/* mgmt_media	-------------------------------------------  */
			if(fnstring=='MEDIA_PHOTO_basics'){
			   MEDIA_PHOTO_basics();
			}
			if(fnstring=='PHOTOBUCKET_PHOTO_basics'){
			   PHOTOBUCKET_PHOTO_basics();
			}
            if(fnstring=='PROFILE_SOCIAL_MEDIA_basics'){
			   PROFILE_SOCIAL_MEDIA_basics();
			}
            if(fnstring=='CONFIGURATION_SOCIAL_MEDIA_basics'){
			   CONFIGURATION_SOCIAL_MEDIA_basics();
			}
            if(fnstring=='CONFIGURATION_SOCIAL_MEDIA_POP_basics'){
			   CONFIGURATION_SOCIAL_MEDIA_POP_basics();
			}
            if(fnstring=='METRICS_SOCIAL_MEDIA_basics'){
			   METRICS_SOCIAL_MEDIA_basics();
			}
            if(fnstring=='CATALOG_SOCIAL_SHARE_basics'){
			   CATALOG_SOCIAL_SHARE_basics();
			}
            if(fnstring=='PAGES_SOCIAL_SHARE_basics'){
			   PAGES_SOCIAL_SHARE_basics();
			}
			/* --------------------------------------------------------  */
			/* mgmt_items	-------------------------------------------  */
			if(fnstring=='ITEM_SETTINGS_basics_MGMT'){
			   ITEM_SETTINGS_basics_MGMT();
			}
			if(fnstring=='EVENT_COHOST_SELECT_basics'){
			   EVENT_COHOST_SELECT_basics();
			}
			if(fnstring=='EVENT_ITEM_CHECKIN_basics'){
			   EVENT_ITEM_CHECKIN_basics();
			}
			if(fnstring=='EVENT_ITEM_LOCATION_basics'){
			   EVENT_ITEM_LOCATION_basics();
			}
			if(fnstring=='ITEM_SEATING_basics_MGMT'){
			   ITEM_SEATING_basics_MGMT();
			}
			/* --------------------------------------------------------  */
			/* mgmt_business-------------------------------------------  */
			if(fnstring=='BUSINESS_TYPE_SELECT_basics'){
			   BUSINESS_TYPE_SELECT_basics();
			}
			if(fnstring=='BUSINESS_registration_basics'){
			   BUSINESS_registration_basics();
			}
			if(fnstring=='BUSINESS_basics'){
			   BUSINESS_basics();
			}
			if(fnstring=='BUSINESS_Catalog'){
			   BUSINESS_Catalog();
			}
			if(fnstring=='BUSINESS_recommendation_select_basics'){
			   BUSINESS_recommendation_select_basics();
			}
			if(fnstring=='BUSINESS_recommendation_management_basics'){
			   BUSINESS_recommendation_management_basics();
			}
			if(fnstring=='BUSINESS_reporting_basics'){
			   BUSINESS_reporting_basics();
			}
			if(fnstring=='BUSINESS_settings_basics'){
			   BUSINESS_settings_basics();
			}
			if(fnstring=='BUSINESS_account_basics'){
			   BUSINESS_account_basics();
			}
			if(fnstring=='BUSINESS_COHOST_SELECT_basics'){
			   BUSINESS_COHOST_SELECT_basics();
			}
			if(fnstring=='BUSINESS_PHOTO_basics'){
			   BUSINESS_PHOTO_basics();
			}
            if(fnstring=='BUSINESS_COHOST_INDEX_basics'){
			   BUSINESS_COHOST_INDEX_basics();
			}
			/* --------------------------------------------------------  */
			/* mgmt_profile	-------------------------------------------  */
			if(fnstring=='PROFILE_PHOTO_basics'){
			   PROFILE_PHOTO_basics();
			}			
			/* --------------------------------------------------------  */
			/* mgmt_profile	-------------------------------------------  */
            
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}
	});
})();
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function SYSTEM_CRON_LISTEN_MANAGER(){
    CONSOLE_MANAGER('SYSTEM_CRON_LISTEN_MANAGER','ACTIVATED');
	AUTOBAHNJS_VERSION();
	//$('#standard_entity_modify_entitylistMgmt').val(null);
	/* -------------------------------------------------------- */
    /* -------------------------------------------------------- 
    
    
    CONSOLE_MANAGER('SYSTEM_CRON_LISTEN_MANAGER',data.registerCode+' this items class is: '+ data.itemviewCLASS+' - SET_STATUS: '+element.SET_STATUS+' - previous class: '+className);
    
    var conn = new ab.Session('ws://127.0.0.1:8080',
        function($EVENT) {
            //$EVENT.stopPropagation;
            var $DATES_obj_array=[];	
            conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                    if($DATES_obj_array != null){
                       
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.GET_UNIQ_ID != "undefined"){
                                        if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                            var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                            //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                            //activityCLASS	scheduleCLASS	itemviewCLASS
                                            $(element.SET_STATUS).removeClass(className);
                                            $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            });
        },
        function() {
            console.warn('WebSocket connection closed');
        },			   
        {'skipSubprotocolCheck': true}		   
    );*/
	/* -------------------------------------------------------- */
    /* --------------------------------------------------------
    var conn4 = new ab.Session('ws://127.0.0.1:8000',
        function($EVENT) {
            //$EVENT.stopPropagation;
            var $DATES_obj_array=[];	
            conn4.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                    if($DATES_obj_array != null){
                      
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.GET_UNIQ_ID != "undefined"){
                                        if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                            var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                            //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                            //activityCLASS	scheduleCLASS	itemviewCLASS
                                            $(element.SET_STATUS).removeClass(className);
                                            $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            });
        },
        function() {
            console.warn('WebSocket connection closed');
        },			   
        {'skipSubprotocolCheck': true}		   
    ); */
	/* -------------------------------------------------------- */
    /* -------------------------------------------------------- 
    var conn3 = new ab.Session('ws://localhost:8080',
        function($EVENT) {
            //$EVENT.stopPropagation;
            var $DATES_obj_array=[];	
            conn3.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                    if($DATES_obj_array != null){
                      
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.GET_UNIQ_ID != "undefined"){
                                        if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                            var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                            //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                            //activityCLASS	scheduleCLASS	itemviewCLASS
                                            $(element.SET_STATUS).removeClass(className);
                                            $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            });
        },
        function() {
            console.warn('WebSocket connection closed');
        },			   
        {'skipSubprotocolCheck': true}				   
    );*/	/*END FUNCTION */
	/* -------------------------------------------------------- */
    /* -------------------------------------------------------- 
	var conn2 = new ab.Session('ws://0.0.0.0:8080',
        function($EVENT) {
            //$EVENT.stopPropagation;
            var $DATES_obj_array=[];	
            conn2.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                    if($DATES_obj_array != null){
                       
                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    if(typeof element.GET_UNIQ_ID != "undefined"){
                                        if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                            var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                            //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                            //activityCLASS	scheduleCLASS	itemviewCLASS
                                            $(element.SET_STATUS).removeClass(className);
                                            $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                        }
                                    }
                                }
                            });
                        }
                    }
                }
            });
        },
        function() {
            console.warn('WebSocket connection closed');
        },			   
        {'skipSubprotocolCheck': true}	
    );
	*/
	/* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
}
SYSTEM_CRON_LISTEN_MANAGER()
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* ###############   	sys_main_options	            		########################## */
/* -------------------------------------------------------- */
/* -------------------------------------------------------- */
export function SET_BUTTON_CLICK_ACTION_CLASS(button_var){
	if(document.getElementById(button_var)){
		var submit_button = document.getElementsByClassName(button_var);	
		BUTTON_NO_DRAG_CLASS(button_var);
		
		submit_button.addEventListener("click", function(event){
			windowload_script('SET_BUTTON_CLICK_ACTION_CLASS: '+button_var);
		}, false);	
	}
}
export function BUTTON_NO_DRAG_CLASS(element_id){
	$("."+element_id).bind('dragstart', function(){return false; });	
}

export function HIDE_OVERLAY(){
    $('#header_menu_options2').slidePanel('hide');//slidePanel	Slidepanel
	$('#window_overlay').hide();
	$('#window_overlay').empty();
	$('#window_overlay').css("display", "none");
	$('#window_view').hide();
	$('#window_view').empty();
	$('#window_view').css("display", "none");
	$('#window_view').html(' ');
}
/* -------------------------------------------------------- */
/* -------------------------------------------------------- */
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* ###############   	ACTIVATE_MENU_IMAGES	            	########################## */
export function ACTIVATE_MENU_IMAGES(){
	$('document').ready(function(){
        CONSOLE_MANAGER('ACTIVATE_MENU_IMAGES','ACTIVATED');
		setTimeout(ai,800);
		function ai(){
			/*****************/
			var mobilecheck = mobileverify();
            if(mobilecheck){
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
                CONSOLE_MANAGER('ACTIVATE_MENU_MOBILE','ACTIVATED');
				/* -------------------------------------------------------- */
				BUTTON_NO_DRAG_CLASS('menu_menu_options20');
				$('#menu_menu_options20').unbind().click(function($EVENT) {
					HIDE_OVERLAY();
				});
				/* -------------------------------------------------------- */
				if(document.getElementById("menu_menu_profile_pic_path")){
					var pic_path_var = document.getElementById("menu_menu_profile_pic_path").value+'?'+Math.random(); 
					var pic_var = document.getElementById("menu_menu_options2"); 
					//pic_var.style.backgroundImage = Math.random();
					pic_var.style.backgroundImage = "url("+pic_path_var+")";
					pic_var.style.backgroundSize = "97% 100%";
					pic_var.style.backgroundRepeat = "no-repeat";
					pic_var.style.backgroundPosition = "center center";
				}else {
					setTimeout(ai, 1000);
				}
				
				SET_BUTTON_CLICK_ACTION("menu_menu_options9");
				//SET_BUTTON_CLICK_ACTION("menu_menu_options10");
				//SET_BUTTON_CLICK_ACTION("menu_menu_options11");
				SET_BUTTON_CLICK_ACTION("menu_menu_options8");
				SET_BUTTON_CLICK_ACTION("menu_menu_options7");
				SET_BUTTON_CLICK_ACTION("menu_menu_options6");
				SET_BUTTON_CLICK_ACTION("menu_menu_options5");
				SET_BUTTON_CLICK_ACTION("menu_menu_options4");
				SET_BUTTON_CLICK_ACTION("menu_menu_options3");
				SET_BUTTON_CLICK_ACTION("menu_menu_options2");
				SET_BUTTON_CLICK_ACTION("menu_menu_options1");
				if($('#menu_menu_options2').is(':visible')){
				   window_complete_script('function: ACTIVATE_MENU_MOBILE');
				}
				/* -------------------------------------------------------- */
				
            }else{
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				CONSOLE_MANAGER('ACTIVATE_MENU_DEFAULT','ACTIVATED');
				/* -------------------------------------------------------- */
				BUTTON_NO_DRAG_CLASS('menu_menu_options20');
				$('#menu_menu_options20').unbind().click(function($EVENT) {
					HIDE_OVERLAY();
				});
				/* -------------------------------------------------------- */
				if(document.getElementById("menu_menu_profile_pic_path")){
					var pic_path_var = document.getElementById("menu_menu_profile_pic_path").value+'?'+Math.random(); 
					var pic_var = document.getElementById("menu_menu_options2"); 
					//pic_var.style.backgroundImage = Math.random();
					pic_var.style.backgroundImage = "url("+pic_path_var+")";
					pic_var.style.backgroundSize = "97% 100%";
					pic_var.style.backgroundRepeat = "no-repeat";
					pic_var.style.backgroundPosition = "center center";
				}else {
					setTimeout(ai, 1000);
				}
				if($('#menu_menu_profile_pin_path')){
					var path_var = $('#menu_menu_profile_pin_path').val();
					$('#menu_menu_pin').css('background-image','url('+path_var+')');
				}
				/*****************/
				if(document.getElementById("menu_menu_profile_pin_path")){
					//var pin_path_var = document.getElementById("menu_menu_profile_pin_path").value+'?'+Math.random(); 
					//var pin_var = document.getElementById("menu_menu_options11"); 
					//pin_var.style.backgroundImage = "url("+pin_path_var+")";
					//pin_var.style.backgroundSize = "97% 100%";
					//pin_var.style.backgroundRepeat = "no-repeat";
					//pin_var.style.backgroundPosition = "center center";
				}
				/*****************/
				SET_BUTTON_CLICK_ACTION("menu_menu_title");
				SET_BUTTON_CLICK_ACTION("menu_menu_options9");
				//SET_BUTTON_CLICK_ACTION("menu_menu_options10");
				//SET_BUTTON_CLICK_ACTION("menu_menu_options11");
				SET_BUTTON_CLICK_ACTION("menu_menu_options8");
				SET_BUTTON_CLICK_ACTION("menu_menu_options7");
				SET_BUTTON_CLICK_ACTION("menu_menu_options6");
				SET_BUTTON_CLICK_ACTION("menu_menu_options5");
				SET_BUTTON_CLICK_ACTION("menu_menu_options4");
				SET_BUTTON_CLICK_ACTION("menu_menu_options3");
				SET_BUTTON_CLICK_ACTION("menu_menu_options2");
				SET_BUTTON_CLICK_ACTION("menu_menu_options1");
				if($('#menu_menu_options2').is(':visible')){
				   window_complete_script('function: ACTIVATE_MENU_DEFAULT');
				}
				/* -------------------------------------------------------- */
			}
		}
	});
}
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
CONSOLE_MANAGER('menu_panel_load','BEFORE');
var menu_panel_load = (function () {
    CONSOLE_MANAGER('menu_panel_load','ACTIVATED');
	if($('#header_menu_options2').is(':visible') && !($('.ie_functional_table').is(':visible'))){
		if(document.getElementById("header_menu_options2")){	//listener
            CONSOLE_MANAGER('menu_panel_load','LISTENING');
			var profile_header_menu_button_var = document.getElementById("header_menu_options2");
			//profile_header_menu_button_var.setAttribute("href", "/menu");
			$('#header_menu_options2').attr("href", "/menu");
			jQuery(function($) {
				$('#header_menu_options2').slidePanel({ //slidePanel	Slidepanel
					direction: 'left',
					mode: 'overlay',
					mouseDrag: false,
					touchDrag: false,
					pointerDrag: false,
					beforeLoad: function(coming, previous) {
                        CONSOLE_MANAGER('menu_panel_load','beforeLoad');
						windowload_script('function: menu_panel_load');
					}, 
					beforeShow: function(coming, previous) {
						CONSOLE_MANAGER('menu_panel_load','beforeShow');
					}, 
					afterShow: function(coming, previous) {
						CONSOLE_MANAGER('menu_panel_load','afterShow');
						$('.close', this.$panel).unbind().click(function(e) {
							e.preventDefault();
							HIDE_OVERLAY();
						});
						 $('#window_overlay').unbind().click(function() {
							HIDE_OVERLAY();
						});
						 $('#menu_panel_close_button', this.$panel).unbind().click(function() {
							HIDE_OVERLAY();
						});
						ACTIVATE_MENU_IMAGES();
					},
					afterHide: function(coming, previous) {
						CONSOLE_MANAGER('menu_panel_load','afterHide');
					},
					onChange: function(coming, previous) {
						CONSOLE_MANAGER('menu_panel_load','onChange');
					},
				}); 
			});
			profile_header_menu_button_var.addEventListener("click", function(){
				$('#window_view').hide();
				$('#window_overlay').show();
				$('#window_overlay').css("display", "inline");
				var winW = window.innerWidth;
				var winH = window.innerHeight;
				var window_overlay_pre = document.getElementById('window_overlay');

				window_overlay_pre.style.display = "block";
				window_overlay_pre.style.height = winH+"px";
				window_overlay_pre.style.width = winW+"px"; 
				/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
			}, false);
		}
	}
})();
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
var SYSTEM_OPTIONS_LOAD = (function () {
	windowUnload_script('SYSTEM_OPTIONS_LOAD: ACTIVATED')
	/* --------------------------------------------------------  */
	if(document.getElementById("language_validation_op1")){	
		SET_BUTTON_CLICK_ACTION("language_validation_op1");
		$('#language_validation_op1').unbind().click(function() {
			//Language_validation_MGMT();
		});
	}
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	var $YEAR = moment().utc().local().format('YYYY');
	var $MONTH = moment().utc().local().format('MMMM');
	var $DAY = moment().utc().local().format('D');
	var $TIMEZONE = momenttz.tz.guess(true);
	$('#userlog_active_menu_index1').val($YEAR);
	$('#userlog_active_menu_index2').val($MONTH.toUpperCase());
	$('#userlog_active_menu_index3').val($DAY);
	$('#userlog_active_menu_formCurrentIndex').val($TIMEZONE);
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	/* ###############   	sys_loging_options	            		########################## */
	if(document.getElementById("userlog_default_menu_options1")){
		SET_BUTTON_CLICK_ACTION("userlog_default_menu_options1");
	}
	if(document.getElementById("userlog_default_menu_options2")){
		SET_BUTTON_CLICK_ACTION("userlog_default_menu_options2");	
	}
	if(document.getElementById("userlog_active_menu_options1")){	 //PROFILE
		var CUL_button_1 = document.getElementById("userlog_active_menu_options1");	
		SET_BUTTON_CLICK_ACTION("userlog_active_menu_options1");

		if(document.getElementById("userlog_active_menu_profile_pic_path")){
			var pic_path_var = document.getElementById("userlog_active_menu_profile_pic_path").value+'?'+Math.random();
			CUL_button_1.style.backgroundImage = "url("+pic_path_var+")";
			CUL_button_1.style.backgroundSize = "85% 85%";
			CUL_button_1.style.backgroundRepeat = "no-repeat";
			CUL_button_1.style.backgroundPosition = "center center";
		}
	}
	/* -----  */
	if(document.getElementById("userlog_active_menu_options2")){	//ACTIVITY
		SET_BUTTON_CLICK_ACTION("userlog_active_menu_options2");			
	}
	/* -----  */
	if(document.getElementById("userlog_active_menu_options3")){	//CALENDAR
		SET_BUTTON_CLICK_ACTION("userlog_active_menu_options3");		
	}
	/* -----  */
	if(document.getElementById("userlog_active_menu_options4")){	//DASHBOARD
		SET_BUTTON_CLICK_ACTION("userlog_active_menu_options4");
	}
	if(document.getElementById("dashboard_textarea")){	
		SET_BUTTON_CLICK_ACTION("dashboard_textarea");
		$('#dashboard_textarea').unbind().click(function() {
			$('#userlog_active_menu_options4').unbind().click();
		});
	}
	if(document.getElementById("notification_submit")){
		SET_BUTTON_CLICK_ACTION("notification_submit");
	}
    /* -----  */
    /* -----  */
    /************************************//************************************/
    /************************************//************************************/
    var $LOAD_CONTROL = false;
    if($('#userlog_active_menu_loadLog')){
        $LOAD_CONTROL = $('#userlog_active_menu_loadLog').val();
    }
    else if($('#userlog_default_menu_loadLog')){
        $LOAD_CONTROL = $('#userlog_default_menu_loadLog').val();
    }
    CONSOLE_MANAGER('SYSTEM_OPTIONS_LOAD','LOAD MANAGER: '+$LOAD_CONTROL);
    if($LOAD_CONTROL=="TRUE"){
        /************************************/
        $('.full_MGMT_query_metrics').css("display", "block");
        $('.full_MGMT_query_metrics').show();
    }else{
        /************************************/
        $('.full_MGMT_query_metrics').css("display", "none");
        $('.full_MGMT_query_metrics').hide();
        $('.full_MGMT_query_metrics').empty();
        $('.full_MGMT_query_metrics').html(' ');
    }  
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	if(document.getElementById("admin_mgmt_options1")){
		SET_BUTTON_CLICK_ACTION("admin_mgmt_options1");
	}
	if(document.getElementById("admin_mgmt_options2")){
		SET_BUTTON_CLICK_ACTION("admin_mgmt_options2");
	}
	if(document.getElementById("admin_mgmt_options3")){
		SET_BUTTON_CLICK_ACTION("admin_mgmt_options3");
	}
	if(document.getElementById("admin_mgmt_options4")){
		SET_BUTTON_CLICK_ACTION("admin_mgmt_options4");
	}
	if(document.getElementById("admin_mgmt_options5")){
		SET_BUTTON_CLICK_ACTION("admin_mgmt_options5");
	}
    /* --------------------------------------------------------  */
    if(document.getElementById("footer_menu_options1")){
        SET_BUTTON_CLICK_ACTION("footer_menu_options1");
    }
    if(document.getElementById("report_concerns_notification_options1")){
        SET_BUTTON_CLICK_ACTION("report_concerns_notification_options1");
    }
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	if(document.getElementById("mobile_menu_options1")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options1");
	}
	if(document.getElementById("mobile_menu_options2")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options2");
	}
	if(document.getElementById("mobile_menu_options3")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options3");
	}
	if(document.getElementById("mobile_menu_options4")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options4");
	}
	if(document.getElementById("mobile_menu_options5")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options5");
	}
	if(document.getElementById("mobile_menu_options6")){
		SET_BUTTON_CLICK_ACTION("mobile_menu_options6");
	}
    /* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
	/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
	if($("#site_open_footer_options1").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options1");
	}
	if($("#site_open_footer_options2").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options2");
	}
	if($("#site_open_footer_options3").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options3");
	}
	if($("#site_open_footer_options4").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options4");
	}
	if($("#site_open_footer_options5").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options5");
	}
    if($("#site_open_footer_options6").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options6");
	}
    if($("#site_open_footer_options7").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options7");
	}
    if($("#site_open_footer_options8").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_options8");
	}
    if($("#site_open_footer_link1").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link1");
	}
    if($("#site_open_footer_link2").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link2");
	}
    if($("#site_open_footer_link3").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link3");
	}
    if($("#site_open_footer_link4").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link4");
	}
    if($("#site_open_footer_link5").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link5");
	}
    if($("#site_open_footer_link6").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link6");
	}
    if($("#site_open_footer_link7").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link7");
	}
    if($("#site_open_footer_link8").is(":visible")){	
		SET_BUTTON_CLICK_ACTION("site_open_footer_link8");
	}
})();
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/


/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/



/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/


/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101101001*/
/* 0101010101010101010101010101010101010101010101010101010101010101001011001011010101011001*/



