/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  
/*  
	form_event_creator_MGMT
	form_event_register_MGMT
	form_event_manager_MGMT
	form_event_maincurrence_MGMT
	
	event_instance_function
	
	EVENT_Catalog
	EVENT_Media
    EVENT_Upload_Media
	
	EVENT_MAP_basics
	EVENT_GUIDELINES_SELECT_basics
	
	EVENT_RECOMMENDATIONS_SELECT_basics
	EVENT_ITEM_RECOMMENDATIONS_SELECT_basics
	EVENT_LISTVIEW_basics	
	EVENT_COMPATIBILITY_basics	
/*  EVENT_TYPE_SELECT_basics
	EVENT_COHOST_SELECT_basics
	EVENT_INVITATIONS_SELECT_basics
/*  
/*  				title_body ACTIVATE_UPLOAD_FORM_INIT
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';
/********************/
import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import * as spectrum from 'spectrum-colorpicker2';
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT as PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_CONTRIBUTION_UPDATE as PHOTOBUCKET_CONTRIBUTION_UPDATE } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";

import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { item_time_selector as item_time_selector } from "./mgmt_control.js";
import { initiate_holiday_creator as initiate_holiday_creator } from "./mgmt_control.js";
import { initiate_holiday_daily_creator as initiate_holiday_daily_creator } from "./mgmt_control.js";
import { initiate_holiday_weekly_creator as initiate_holiday_weekly_creator } from "./mgmt_control.js";
import { initiate_holiday_monthly_creator as initiate_holiday_monthly_creator } from "./mgmt_control.js";
import { initiate_holiday_yearly_creator as initiate_holiday_yearly_creator } from "./mgmt_control.js";

import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";

import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_LISTED as ACTIVATE_UPLOAD_FORM_LISTED } from "./mgmt_control.js";

import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { event_create_pop_map_activate as event_create_pop_map_activate } from "./mgmt_mapping.js";
import { event_create_pop_manager_map_activate as event_create_pop_manager_map_activate } from "./mgmt_mapping.js";
import { event_profile_pop_manager_map_activate as event_profile_pop_manager_map_activate } from "./mgmt_mapping.js";

import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";

import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_control.js";

import { EVENT_METRICS_CONTROL_basics as EVENT_METRICS_CONTROL_basics } from "./mgmt_metrics.js";
//EVENT_COMPATIBILITY_basics	$CONFIRM_CNTRL		settings_activity_type		change

import { media_image_activate as media_image_activate } from "./mgmt_control.js";
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function event_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('event_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('event_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('event_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('event_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
	/* ------------------------------------------------- */
    if(form_entity.includes('event_activity_interaction_options')){
        var str = $('#event_creator_categorySequence').val();
        if (str.indexOf("Holiday") >= 0){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/holiday/event/account/update';
        }else{
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/event/account/update';
        }
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_item_settings_options')){
        var str = $('#event_creator_categorySequence').val();
        if (str.indexOf("Holiday") >= 0){
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/holiday/event/description/modify';
        }else{
            $('#standard_entity_modify_control').val(select_val);
            $('#standard_entity_modify_formReport').val(select_button);
            var $DATA = $('.Interaction_Activity_UI_class');
            var $URL = '/system/event/description/modify';
        }
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index1').val($('#image_creator_name').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());
        //$('#standard_entity_modify_index5').val($('#image_creator_formFilter2').val());
		/*-------------------------------------------------------- */
		if(document.getElementById("image_creator_formFilter2")){
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_TYPE'||$('#image_creator_formFilter2').val()=='HOLIDAY_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/upload';
			}
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_TYPE_PHOTOSYSTEM_TYPE'||$('#image_creator_formFilter2').val()=='HOLIDAY_TYPE_PHOTOSYSTEM_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/photosystem/upload';
			}
		}else{
			var $DATA = $('.Interaction_Activity_UI_class');
        	var $URL = '/entity/file/upload';
		}
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){

    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	  						                    ########################## */
export function EVENT_SETTINGS_STREAMING_OPTIONS(){
	$(document).ready(function() {
        CONSOLE_MANAGER('EVENT_SETTINGS_STREAMING_OPTIONS','ACTIVATED');
		/* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        if(document.getElementById("menu_objects_count")){
            var Object_count = parseInt(document.getElementById("menu_objects_count").value + 1);
            var $code = $("#menu_object").val();
            for(var $i=1; $i<Object_count; $i++){

                if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                    /* ------------------------------------------------- */
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                    //var $TITLE = '#form_'+$code+'_'+$i+'_title';
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                    var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                    //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    /* ------------------------------------------------- */
                    REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
                   	REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
					EVENT_SETTINGS_STREAMING_OPTIONS_ACTIVITY($($OPTIONS01),$($INDEX01),false)
                }	
            } 
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03){
				$('#standard_entity_modify_index1').val($INDEX01.val());
                $('#standard_entity_modify_index2').val($INDEX02.val());
                $('#standard_entity_modify_index3').val($INDEX03.val());
				$button_obj.unbind().click(function(event) {
					
					EVENT_SETTINGS_STREAMING_OPTIONS_ACTIVITY($button_obj,$INDEX01,true);
					$('#event_creator_streamOption01').val($INDEX01.val());
					$('#STREAMING_CONTROL').val($INDEX01.val());
					
                    $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                    $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
					event_instance_function('event_item_settings_options', $button_obj.val(),$INDEX01.val(),event);//
                });
            }
			
        }
		/* -------------------------------------------------------- */
        //https://www.youtube.com/watch?v=gon1dFU_ozY&feature=youtu.be
        ////https://vimeo.com/472446803/de88b00da4
		//https://www.twitch.tv/gernaderjake
        //$('#inputFullUrl').on('change',  function($EVENT) {
        $("#inputFullUrl").unbind().keyup(function($EVENT){  
            var $temp = $('#inputFullUrl').val();
            if(($temp.length>0)){
                /* -------------------------------------------------------- */
                var $temp_key = YouTubeGetUrl($temp);
                //$temp_key = $temp_key.location.host;
                $('#inputUrl').val($temp_key);
				/*
					https://developers.google.com/youtube/iframe_api_reference#Examples
					https://developer.vimeo.com/api/oembed/videos
					https://dev.twitch.tv/docs/embed/video-and-clips
				*/
                /* -------------------------------------------------------- */
                var $STREAMING_CONTROL = $('#event_creator_streamOption01').val();
                if($STREAMING_CONTROL.toLowerCase().indexOf("youtube") >= 0){
                    var $temp_key = YouTubeGetID($temp);
                    $('#inputKey').val($temp_key);
					$(function() {
						//$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://example.com" frameborder="0"></iframe>');
						$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://www.youtube.com/embed/'+$temp_key+'?enablejsapi=1&origin=http://example.com" frameborder="0" ></iframe>');
					});
					modify_verification_window();
                }
                if($STREAMING_CONTROL.toLowerCase().indexOf("vimeo") >= 0){
                    var $temp_key = VimeoGetID($temp);
                    $('#inputKey').val($temp_key);
					$(function() {
						//$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://example.com" frameborder="0"></iframe>');
						$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://player.vimeo.com/video/'+$temp_key+'" frameborder="0" ></iframe>');
					});
					modify_verification_window();
                }
				if($STREAMING_CONTROL.toLowerCase().indexOf("twitch") >= 0){
                    var $temp_key = TwitchGetID($temp);
                    $('#inputKey').val($temp_key);
					$(function() {
						//$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://www.youtube.com/embed/M7lc1UVf-VE?enablejsapi=1&origin=http://example.com" frameborder="0"></iframe>');
						$('#player').replaceWith('<iframe id="player" class="stream_container_menu_td_video_media_div" type="text/html" src="https://player.twitch.tv/?channel='+$temp_key+'&parent=127.0.0.1&autoplay=false" frameborder="0" scrolling="no" allowfullscreen ="false"></iframe>');
					});
					modify_verification_window();
                }
                /* -------------------------------------------------------- */
            }
        });/*END ON CHANGE */
		/* -------------------------------------------------------- */
		modify_verification_window();
        /* -------------------------------------------------------- */
		$('#STREAMING_CONTROL').val($('#event_creator_streamOption01').val())
		/* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
		function modify_verification_window(){// https://youtu.be/fgisqTEqI_Y
			var $STREAMING_CONTROL = $('#event_creator_streamOption01').val();
			if($STREAMING_CONTROL.length>4){
				/* -------------------------------------------------------- */
			   	//$('#inputFullUrl').val();
				//$('#inputUrl').val();
				//$('#inputKey').val();
				//https://www.youtube.com/watch?v=gon1dFU_ozY&feature=youtu.be
				//https://vimeo.com/472446803/de88b00da4
				/* -------------------------------------------------------- */
				$('#verify_options1').unbind().click(function(event) {
					var $FullURL = $('#inputFullUrl').val();
					var $inputURL = $('#inputUrl').val();
					var $inputKey = $('#inputKey').val();
					/* -------------------------------------------------------- */
					if(($FullURL.length >5)&&($inputURL.length >5)&&($inputKey.length >3)){
						$('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
						$('#standard_entity_modify_formCurrentIndex').val($FullURL);
						$('#standard_entity_modify_index1').val($FullURL);
						$('#standard_entity_modify_index2').val($inputURL);
						$('#standard_entity_modify_index3').val($inputKey);
						event_instance_function('event_item_settings_options', $('#verify_options1').attr('id'),$('#inputFullUrl').val(),event);
					}
				});
				/* -------------------------------------------------------- */
			}
		}
		/* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
		/*
            event_creator_streamOption01
            event_creator_streamToken01
            event_creator_streamKey01
            event_creator_streamDataUrl01
        */
        if($('#event_creator_streamToken01').val()){
            $('#inputUrl').val($('#event_creator_streamToken01').val());
        }
        if($('#event_creator_streamKey01').val()){
            $('#inputKey').val($('#event_creator_streamKey01').val());
        }
        if($('#event_creator_streamDataUrl01').val()){
            $('#inputFullUrl').val($('#event_creator_streamDataUrl01').val());
            $('#inputFullUrl').keyup();
        }
		/* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
		function YouTubeGetUrl(url){
			var a  = document.createElement('a');
			a.href = url;
			return a.hostname;
		}
		function YouTubeGetID(url){
			//https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
			//https://www.youtube.com/watch?v=gon1dFU_ozY&feature=youtu.be
   			url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
            CONSOLE_MANAGER('YouTubeGetID',"YouTubeGetID: 0: "+url[0]+' - 1: '+url[1] +' - 2: '+url[2]);
   			return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
		}
		function VimeoGetID(url){
			//https://vimeo.com/472446803/de88b00da4
   			return /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[3];
		}
		function TwitchGetID(url){
			//https://www.twitch.tv/gernaderjake
			//https://www.twitch.tv/insomniac
			url = url.split(/(vi\/|v=|\/v\/|twitch\.tv\/|\/embed\/)/);
            CONSOLE_MANAGER('TwitchGetID',"TwitchGetID: 0: "+url[0]+' - 1: '+url[1] +' - 2: '+url[2]);
   			//return /(twitch?\.tv)\/(?:[^\d]+)?(\d+)\??(.*)?$/.exec(url)[2];
			return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
		}
	}); 
	/* -------------------------------------------------------- */
}
export function EVENT_SETTINGS_STREAMING_OPTIONS_ACTIVITY($OBJECT,$FORM_VAL,$CONTROL){
	var Object_count = parseInt(document.getElementById("menu_objects_count").value + 1);
    var $code = $("#menu_object").val();
    for(var $i=1; $i<Object_count; $i++){
        if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
			/* ------------------------------------------------- */
            var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
			var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
            $($OPTIONS01).removeClass('event_stream_option_ACTIVE_class');
			if($CONTROL==false){
				if($($INDEX01).val() == $('#event_creator_streamOption01').val()){
					$($OPTIONS01).addClass('event_stream_option_ACTIVE_class');
					
				}
			}
        }
    }
	if($CONTROL==true){
		if($OBJECT.hasClass('event_stream_option_ACTIVE_class')){
		  $OBJECT.removeClass('event_stream_option_ACTIVE_class');
        }
        else{
        	$OBJECT.addClass('event_stream_option_ACTIVE_class');
			$('#event_creator_streamOption01').val($FORM_VAL.val());
        }
    }
    if($CONTROL==false){
        if($FORM_VAL.val() == $('#event_creator_streamOption01').val()){
            $OBJECT.addClass('event_stream_option_ACTIVE_class');
        }
    }
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
export function EVENT_HOLIDAY_OCCURENCE_OPTIONS_ACTIVITY(){
	$(document).ready(function() {
		 var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
        /* --------------------------------------------------------  */
        /* AJAX LISTENERS  (MutationObserver) ---------------------  verify_options1 */
        var MODIFY_VIEW_LISTENER = (function (){
            var target = document.getElementById('window_view');
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    CONSOLE_MANAGER('EVENT_HOLIDAY_OCCURENCE_OPTIONS_ACTIVITY','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                    var $MODIFY_CNTRL = $('#event_occurence_occurrence').val();
                        /* -------------------------------------------------------- */
                        if(mutation.type=='attributes'){
							CONSOLE_MANAGER('EVENT_HOLIDAY_OCCURENCE_OPTIONS_ACTIVITY',mutation.type+': attributes');
                            if($('#event_occurence_options1').is(':visible')){
                                ClearActiveClass($('#event_occurence_options1'),true)
                                /* -------------------------------------------------------- */
                                $('#event_occurence_options1').unbind().click(function() {
                                    $('#event_occurence_occurrence').val($('#event_occurence_formFilter1').val());
                                    $('#event_creator_occurrence').val($('#event_occurence_formFilter1').val());
                                    $('#event_creator_options18active').html($('#event_occurence_formFilter1').val());
                                    //$('#event_occurence_categorySequence').val($('#event_occurence_formFilter1').val())
                                    ClearActiveClass($('#event_occurence_options1'),false)
                                    initiate_holiday_creator($('#event_creator_startDate'));
                                });
                                $('#event_occurence_options2').unbind().click(function() {
                                    $('#event_occurence_occurrence').val($('#event_occurence_formFilter2').val());
                                    $('#event_creator_occurrence').val($('#event_occurence_formFilter2').val());
                                    $('#event_creator_options18active').html($('#event_occurence_formFilter2').val());
                                    //$('#event_occurence_categorySequence').val($('#event_occurence_formFilter2').val())
                                    ClearActiveClass($('#event_occurence_options2'),false)
                                    initiate_holiday_daily_creator($('#event_creator_startDate'));
                                });
                                $('#event_occurence_options3').unbind().click(function() {
                                    $('#event_occurence_occurrence').val($('#event_occurence_formFilter3').val());
                                    $('#event_creator_occurrence').val($('#event_occurence_formFilter3').val());
                                    $('#event_creator_options18active').html($('#event_occurence_formFilter3').val());
                                    //$('#event_occurence_categorySequence').val($('#event_occurence_formFilter3').val())
                                    ClearActiveClass($('#event_occurence_options3'),false)
                                    initiate_holiday_weekly_creator($('#event_creator_startDate'));
                                });
                                $('#event_occurence_options4').unbind().click(function() {
                                    $('#event_occurence_occurrence').val($('#event_occurence_formFilter4').val());
                                    $('#event_creator_occurrence').val($('#event_occurence_formFilter4').val());
                                    $('#event_creator_options18active').html($('#event_occurence_formFilter4').val());
                                    //$('#event_occurence_categorySequence').val($('#event_occurence_formFilter4').val())
                                    ClearActiveClass($('#event_occurence_options4'),false);
                                    initiate_holiday_monthly_creator($('#event_creator_startDate'));
                                });
                                $('#event_occurence_options5').unbind().click(function() {
                                    $('#event_occurence_occurrence').val($('#event_occurence_formFilter5').val());
                                    $('#event_creator_occurrence').val($('#event_occurence_formFilter5').val());
                                    $('#event_creator_options18active').html($('#event_occurence_formFilter5').val());
                                    //$('#event_occurence_categorySequence').val($('#event_occurence_formFilter5').val())
                                    ClearActiveClass($('#event_occurence_options5'),false);
                                    initiate_holiday_yearly_creator($('#event_creator_startDate'));
                                });
                                /* -------------------------------------------------------- */
                            }
                            else if($('#event_occurence_options1').is(':hidden')){

                            }
                        }
                        /* -------------------------------------------------------- */
                        else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('EVENT_HOLIDAY_OCCURENCE_OPTIONS_ACTIVITY',mutation.type+': childList');
                        }
                   // });
                });    
            });//END new MutationObserver
            var config = { attributes: true, childList: true, characterData: true };
            observer.observe(target, config);
            //observer.disconnect();
        })();/*END FUNCTION */
    }); 
	/* -------------------------------------------------------- */
	/* -------------------------------------------------------- */
	function ClearActiveClass($button,$check){
        /* -------------------------------------------------------- */
        /* -------------------------------------------------------- */
        if($( "#event_creator_formFilter1" ).val()=='PARENT'){
            /* -------------------------------------------------------- */
            var $DATES_obj_array = [];
            $('#event_creator_occurrenceRange').val(JSON.stringify($DATES_obj_array));
            /* -------------------------------------------------------- */
            $('#event_creator_occurrenceScale').val(null);
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
        }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){

        }
        /* -------------------------------------------------------- */
		if($check==false){
			if($('#event_occurence_options1').hasClass('occurence_button_active')){
				$('#event_occurence_options1').removeClass('occurence_button_active');
				$('#event_occurence_options1').addClass('occurence_button_default');
			}
			if($('#event_occurence_options2').hasClass('occurence_button_active')){
				$('#event_occurence_options2').removeClass('occurence_button_active');
				$('#event_occurence_options2').addClass('occurence_button_default');
			}
			if($('#event_occurence_options3').hasClass('occurence_button_active')){
				$('#event_occurence_options3').removeClass('occurence_button_active');
				$('#event_occurence_options3').addClass('occurence_button_default');
			}
			if($('#event_occurence_options4').hasClass('occurence_button_active')){
				$('#event_occurence_options4').removeClass('occurence_button_active');
				$('#event_occurence_options4').addClass('occurence_button_default');
			}
			if($('#event_occurence_options5').hasClass('occurence_button_active')){
				$('#event_occurence_options5').removeClass('occurence_button_active');
				$('#event_occurence_options5').addClass('occurence_button_default');
			}
			/* -------------------------------------------------------- */
            $("#holidaystartDate").val(null);
            $("#holidaystartTime").val(null);
            $("#holidayendDate").val(null);
            $("#holidayendTime").val(null);
			/* -------------------------------------------------------- */
            $("#holidaystartDate_daily").val(null);
            $("#holidayendDate_daily").val(null);
            $("#holidaystartTime_daily").val(null);
            $("#holidayendTime_daily").val(null);
			/* -------------------------------------------------------- */
            $("#holidaystartDate_weekly").val(null);
            $("#holidayendDate_weekly").val(null);
			/* -------------------------------------------------------- */
			$('#startDate').val(null);
			$("#startTime").val(null);
			$('#endDate').val(null);
			$("#endTime").val(null);
			
			$('#event_creator_start').val(null);
			$('#event_creator_startDate').val(null);
            $("#event_creator_startTime").val(null);
            
			$('#event_creator_end').val(null);
			$('#event_creator_endDate').val(null);
            $("#event_creator_endTime").val(null);
            
            $('#event_creator_occurrenceScale').val('FALSE');
            /* -------------------------------------------------------- */
            $('#holidaytimelineStatus').val(null);
			$('#holidaydurationStatus').val(null);
            $("#holidaycompletionStatus").val(null);
            
            parseTime();
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			$button.addClass("occurence_button_active");
			$button.removeClass('occurence_button_default');
		}else{
			/* -------------------------------------------------------- */
			if( $('#event_creator_occurrence').val()){

			}else{
			   $('#event_creator_occurrence').val($('#event_occurence_occurrence').val());
			}
			/* -------------------------------------------------------- */
			if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter1').val()){
			   	$('#event_occurence_options1').removeClass('occurence_button_default');
				$('#event_occurence_options1').addClass('occurence_button_active');
				initiate_holiday_creator($('#event_creator_startDate'));
			}
			else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter2').val()){
			   	$('#event_occurence_options2').removeClass('occurence_button_default');
				$('#event_occurence_options2').addClass('occurence_button_active');
				initiate_holiday_daily_creator($('#event_creator_startDate'));
			}
			else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter3').val()){
			   	$('#event_occurence_options3').removeClass('occurence_button_default');
				$('#event_occurence_options3').addClass('occurence_button_active');
				initiate_holiday_weekly_creator($('#event_creator_startDate'));
			}
			else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter4').val()){
			   	$('#event_occurence_options4').removeClass('occurence_button_default');
				$('#event_occurence_options4').addClass('occurence_button_active');
				initiate_holiday_monthly_creator($('#event_creator_startDate'));
			}
			else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter5').val()){
			   	$('#event_occurence_options5').removeClass('occurence_button_default');
				$('#event_occurence_options5').addClass('occurence_button_active');
				initiate_holiday_yearly_creator($('#event_creator_startDate'));
			}else{
                $('#event_occurence_options1').click();
                if($('#event_occurence_options1').hasClass('occurence_button_default')){
                    $('#event_occurence_options1').removeClass('occurence_button_default');
                    $('#event_occurence_options1').addClass('occurence_button_active');
                }
            }
		}
		/* -------------------------------------------------------- */
		if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter1').val()){
            $('#occurrence_div_01').show();
            $('#occurrence_div_02').hide();
            $('#occurrence_div_03').hide();
            $('#occurrence_div_04').hide();
            $('#occurrence_div_05').hide();
            //$('#occurrence_div_06').hide();
        }
        else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter2').val()){
            $('#occurrence_div_01').hide();
            $('#occurrence_div_02').show();
            $('#occurrence_div_03').hide();
            $('#occurrence_div_04').hide();
            $('#occurrence_div_05').hide();
            //$('#occurrence_div_06').hide();
        }
        else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter3').val()){
            $('#occurrence_div_01').hide();
            $('#occurrence_div_02').hide();
            $('#occurrence_div_03').show();
            $('#occurrence_div_04').hide();
            $('#occurrence_div_05').hide();
            //$('#occurrence_div_06').hide();
        }
        else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter4').val()){
            $('#occurrence_div_01').hide();
            $('#occurrence_div_02').hide();
            $('#occurrence_div_03').hide();
            $('#occurrence_div_04').show();
            $('#occurrence_div_05').hide();
            //$('#occurrence_div_06').hide();
        }
        else if($('#event_creator_occurrence').val()==$('#event_occurence_formFilter5').val()){
            $('#occurrence_div_01').hide();
            $('#occurrence_div_02').hide();
            $('#occurrence_div_03').hide();
            $('#occurrence_div_04').hide();
            $('#occurrence_div_05').show();
            //$('#occurrence_div_06').hide();
        }
    }	
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	form_event_creator_MGMT                     ########################## */
export function form_event_creator_MGMT(){
		$(document).ready(function() {
			CONSOLE_MANAGER('form_event_creator_MGMT','ACTIVATED');
			AUTOBAHNJS_VERSION();
			event_create_map_activate(); //initiatEventItemPins	
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- 
                event_creator_options7
                event_creator_options4
                event_creator_options5
            
				item_date_view 
				date_ajax_calendar
				activate_datetime_picker
				event_creator_endDate
				schedule_window_open();
				
				
			*/
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_home_options1");
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("event_creator_options1");
            SET_BUTTON_CLICK_ACTION("event_creator_options2");
            //SET_BUTTON_CLICK_ACTION("event_creator_options3");
            SET_BUTTON_CLICK_ACTION("event_creator_options6");
            
			SET_BUTTON_CLICK_ACTION("event_creator_options8");
			SET_BUTTON_CLICK_ACTION("event_creator_options9");
            SET_BUTTON_CLICK_ACTION("event_creator_options9active");
            SET_BUTTON_CLICK_ACTION("event_creator_options10");
            SET_BUTTON_CLICK_ACTION("event_creator_options10active");
			SET_BUTTON_CLICK_ACTION("event_creator_options11");
            SET_BUTTON_CLICK_ACTION("event_creator_options11active");
			SET_BUTTON_CLICK_ACTION("event_creator_options12");
			SET_BUTTON_CLICK_ACTION("event_creator_options13");
			SET_BUTTON_CLICK_ACTION("event_creator_options14");
			
			SET_BUTTON_CLICK_ACTION("event_creator_options17");
            SET_BUTTON_CLICK_ACTION("event_creator_options17active");
			
            if($('#event_creator_options18').is(':visible')){
                //event_instance_function('event_activity_interaction_options', $('#event_creator_start').attr('id'),$('#event_creator_start').val(),event);
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options18'),null,null,null,null);
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options18active'),null,null,null,null);
                EVENT_HOLIDAY_OCCURENCE_OPTIONS_ACTIVITY();
                SET_BUTTON_CLICK_ACTION("event_creator_options19");
            }
			
            SET_BUTTON_CLICK_ACTION("settings_event_cohost_menu_options1");
            SET_BUTTON_CLICK_ACTION("settings_event_cohost_menu_options2");
            SET_BUTTON_CLICK_ACTION("event_creator_file_upload_options_options1");
			SET_BUTTON_CLICK_ACTION("settings_mediabank_options1");
            SET_BUTTON_CLICK_ACTION("settings_mediabank_options2");
			SET_BUTTON_CLICK_ACTION("settings_mediabank_options3");
			SET_BUTTON_CLICK_ACTION("settings_streambank_options3");
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("settings_event_photobank_options1");
            SET_BUTTON_CLICK_ACTION("settings_event_photobank_options2");
            SET_BUTTON_CLICK_ACTION("settings_event_photobank_index1");
            $('#settings_event_photobank_index1').unbind().click(function() {
                CONSOLE_MANAGER('form_event_creator_MGMT','settings_event_photobank_index1 CLICK');
                $('#settings_event_photobank_options2').click();
			});
            /* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("settings_mediabank_index1");
            SET_BUTTON_CLICK_ACTION("settings_event_photobank_options3");
			$('#settings_mediabank_index1').unbind().click(function() {
                CONSOLE_MANAGER('form_event_creator_MGMT','settings_mediabank_index1 CLICK');
                $('#settings_mediabank_options2').click();
			});
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_seating_options3");
            SET_BUTTON_CLICK_ACTION("event_seating_options3active");
            SET_BUTTON_CLICK_ACTION("event_seating_options4");
            SET_BUTTON_CLICK_ACTION("event_seating_options4active");
            SET_BUTTON_CLICK_ACTION("event_seating_options5");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_itinerary_options1");
			SET_BUTTON_CLICK_ACTION("event_itinerary_options2");
			/* --------------------------------------------------------  */
			$('#settings_streambank_options1').unbind().click(function($EVENT) {
				event.preventDefault();
				$('#standard_entity_modify_control').val($('#settings_streambank_options1').attr('id'));
				var $DATA = $('#stream_menu');
				var $DATA_RETURN = control_pop_window_no_ajax(
					$DATA
				);
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
                setTimeout(is_review_complete, 500);
			});
			/* --------------------------------------------------------  */
			$('#settings_streambank_options2').unbind().click(function($EVENT) {
				event.preventDefault();
				$('#standard_entity_modify_control').val($('#settings_streambank_options2').attr('id'));
				var $DATA = $('#stream_menu');
				var $DATA_RETURN = control_pop_window_no_ajax(
					$DATA
				);
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
                setTimeout(is_review_complete, 500);
			});
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			
			if($('#STREAMING_CONTROL').val()=='YOUTUBE'){
				var player;
				function onYouTubeIframeAPIReady() {
				  player = new YT.Player('streaming-iframe', {
					  events: {
						'onReady': onPlayerReady,
						'onStateChange': onPlayerStateChange
					  }
				  });
				}
				function onPlayerReady(event) {
				  document.getElementById('streaming-iframe').style.borderColor = '#FF6D00';
				}
				function consoleStateChange(playerStatus) {
				  var color;
				  if (playerStatus == -1) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO unstarted');
				  } else if (playerStatus == 0) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO ended');
				  } else if (playerStatus == 1) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO playing');
				  } else if (playerStatus == 2) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO paused');
				  } else if (playerStatus == 3) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO buffering');
				  } else if (playerStatus == 5) {
                      CONSOLE_MANAGER('form_event_creator_MGMT','---VIDEO video cued');
				  }
				}
				function onPlayerStateChange(event) {
				  consoleStateChange(event.data);
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#STREAMING_CONTROL').val()=='VIMEO'){
				var videoPlayer = new Vimeo.Player('player');
                videoPlayer.on('play', function(event) {
                    CONSOLE_MANAGER('form_event_creator_MGMT','----Played the video '+event.data);
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#event_color_colorCode').is(':visible')){
				$("#event_color_colorCode").spectrum({
					flat: true,
					showInput: true,
					preferredFormat: "hex",
					showInitial: true,
					showButtons: false,
					showPalette: false,
					color: $('#event_color_colorCode').val(),
					change: function($EVENT) {
					},
				});
				$('#event_color_colorCode').on('change',  function($EVENT) {
                    if(($('#event_color_colorCode').val().length>0)){
						var $temp = $('#standard_entity_modify_formCurrentIndex').val();
						if($temp != $('#event_color_colorCode').val()){
							$('#standard_entity_modify_control').val($('#event_color_colorCode').attr('id'));
							$('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
							$('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
							$('#standard_entity_modify_formCurrentIndex').val($('#event_color_colorCode').val());
							event_instance_function('event_activity_interaction_options', $('#event_color_colorCode').attr('id'),$('#event_color_colorCode').val(),$EVENT);
							//windowload_script('form_event_creator_MGMT - $REG_UNAME');
							/* -------------------------------------------------------- */
							if(document.getElementById("total_objects_count_item")){
								var Object_count = parseInt(document.getElementById("total_objects_count_item").value + 1);
								var $code = $("#index_object_item").val();
								for(var $i=1; $i<Object_count; $i++){
									if($('#form_'+$code+'_'+$i+'_options9').is(':visible')){
										var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
										var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
										var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
										var $COLORSEQUENCE = $('#event_color_colorCode').val();
										if($('#event_creator_registerCode').val()==$($INDEX03).val()){
											/* ------------------------------------------------- */
											/* ------------------------------------------------- */
											$($OPTIONS04).css("background-color",$COLORSEQUENCE);
										}
									}
								}
							}
						}
                    }
                    $( ".sp-container" ).css('z-index', 1000);
                });/*END ON CHANGE */
                $( ".sp-container" ).css('z-index', 1000);
                setTimeout(is_review_complete, 500);
			}
			//$(".event_container_data9_1").hide();
			//$("#event_color_colorCode").spectrum("hide");
            /* --------------------------------------------------------*/
            if($('#event_creator_file_upload_options_file_path').val()){
                header_background_activate($('#event_creator_file_upload_options_file_path'),$('#BRAND_File_Upload_Label1'));
            } 
            $('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
            if(document.getElementById('Entity_upload_Activity_UI_control')){	
                if($('#BRAND_File_Upload_Label1').is(':visible')) {
                    ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                }
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			$('#event_seating_options1').unbind().click(function($EVENT) {
				$('#standard_entity_modify_control').val($('#event_seating_options1').attr('id'));
                $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
				event_instance_function('event_activity_interaction_options', $('#event_seating_options1').attr('id'),$('#event_seating_options1').val(),$EVENT);
				var $temp = $("#event_seating_seatLimit").val();
				$temp = $temp.replace(/[^0-9]/g, '');
				$temp = parseInt($temp) +1;
				$temp = $temp.toString();
				$temp = $temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
				$('#event_seating_seatLimit').val($temp);
                setTimeout(is_review_complete, 500);
            });
            $('#event_seating_options2').unbind().click(function($EVENT) {
				var $temp = $("#event_seating_seatLimit").val();
				$temp = $temp.replace(/[^0-9]/g, '');
				$temp = parseInt($temp);
				if($temp>0){
					$('#standard_entity_modify_control').val($('#event_seating_options1').attr('id'));
					$('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
					$('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
					event_instance_function('event_activity_interaction_options', $('#event_seating_options2').attr('id'),$('#event_seating_options2').val(),$EVENT);
				
					$temp = $temp-1;
					$temp = $temp.toString();
					$temp = $temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
					$('#event_seating_seatLimit').val($temp);
				}
                setTimeout(is_review_complete, 500);
            });
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(document.getElementById("event_seating_seatLimit")){
				$('#event_seating_seatLimit').attr('maxlength',10);
                var seat_var = document.getElementById("event_seating_seatLimit");
				if($('#event_seating_seatLimit').val()){
					var $temp = $('#event_seating_seatLimit').val();
				   	$temp = $temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
					$('#event_seating_seatLimit').val($temp);
				}
                /* --------------------------------------------------------  */
                seat_var.onfocus = function() {
                    $('#event_seating_seatLimit').keyup(function(event) {
                        var key = String.fromCharCode(event.which);
						/* --------------------------------------------------------  */
						var codes = [32];
						document.getElementById("event_seating_seatLimit").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#event_seating_seatLimit').val();
							$('#event_seating_seatLimit').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
                        if((!key.match(/^[^0-9+#\.\-]+$/)) || 
                           (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
                           (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
                        /*
                        (key == 13 /ENTER/)
                        (key == 46 /DELETE/)
                        (key == 8 /BACKSPACE/)
                        */ 
                        }
                        var $temp = null;
						$('#event_seating_seatLimit').unbind().on('keyup', function(e) {
							$temp = $('#event_seating_seatLimit').val();
							$temp = $temp.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
							$('#event_seating_seatLimit').val($temp);
						});
                        
                        //var string = document.getElementById("event_seating_seatLimit").value;
                        seat_var.onblur = function($EVENT) {
                            if($temp.length>=1){
                                if(($('#event_seating_seatLimit').val())){
                                    $('#standard_entity_modify_control').val($('#event_seating_seatLimit').attr('id'));
                                    $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                    $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
									$('#standard_entity_modify_index2').val($('#event_creator_uniqEventId').val());
                            		$('#standard_entity_modify_index3').val($('#event_creator_registerCode').val());
                                    //windowload_script('form_event_creator_MGMT - $temp');
                                    event_instance_function('event_activity_interaction_options', $('#event_seating_seatLimit').attr('id'),$('#event_seating_seatLimit').val(),$EVENT);
                                }
                            }
                        }
                    });
                }
                setTimeout(is_review_complete, 500);
            }
            /* --------------------------------------------------------  */
			function seatLimitActivity($VAL){
				if($VAL==true){
					$('#event_seating_options1').prop('disabled', false);
				 	$('#event_seating_options2').prop('disabled', false);
            		$('#event_seating_seatLimit').prop('disabled', false);
				}
				if($VAL==false){
				   	$('#event_seating_options1').prop('disabled', true);
					$('#event_seating_options2').prop('disabled', true);
            		$('#event_seating_seatLimit').prop('disabled', true);
				}
			}
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("event_creator_title")){
                var reg_titlename_var = document.getElementById("event_creator_title");
                /* --------------------------------------------------------  */
                reg_titlename_var.onfocus = function() {
                    $('#event_creator_title').keyup(function(event) {
                        var key = String.fromCharCode(event.which);
                        //if(event.keyCode == 32){
                        //    $('#event_creator_title').val($('#event_creator_title').val()+' ');
                        //}
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById("event_creator_title").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#event_creator_title').val();
							$('#event_creator_title').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
                        if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                           (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
                           (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
                        /*
                        (key == 13 /ENTER/)
                        (key == 46 /DELETE/)
                        (key == 8 /BACKSPACE/)
                        */ 
                        }
                        var $REG_UNAME = $('#event_creator_title').val();
                        var string = document.getElementById("event_creator_title").value;
                        reg_titlename_var.onblur = function($EVENT) {
                            if($REG_UNAME.length>1){
                            //ENTRYdelay(function(){
                                //if(($('#event_creator_title').val().length>0)){
                                    $('#standard_entity_modify_control').val($('#event_creator_title').attr('id'));
                                    $('#standard_entity_modify_title').val($('#event_creator_title').val());
                                    $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                    $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
									$('#standard_entity_modify_index2').val($('#event_creator_uniqEventId').val());
                            		$('#standard_entity_modify_index3').val($('#event_creator_registerCode').val());
									$('#standard_entity_modify_formCurrentIndex').val($('#event_creator_title').val());
                                    //windowload_script('form_event_creator_MGMT - $REG_UNAME');
                                    //event_title_validation_MGMT(event);
									event_instance_function('event_activity_interaction_options', $('#event_creator_title').attr('id'),$('#event_creator_title').val(),$EVENT);
                                //}
                                //if(!($('#event_creator_title').val())){
                                    //UPDATE_BTITLE_CLASS($("#output_username"), 'output_title_default', ' ');
                                //}
                            //}, 1000 );
                            }
                        }
                        setTimeout(is_review_complete, 500);
                    });
                }
            }
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_count_activate(){//event_register_div_manager
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_title")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_title");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_title").value;

                        $('#settings_event_description_edit_title').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_title').val($('#settings_event_description_edit_title').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_title').val();
                                $('#settings_event_description_edit_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                        });
                    }
                    /* --------------------------------------------------------  */
                    var ttextarea = null;
                    var tresult = null;
                    var tNEW_VAL = null;

                    //var MAXVAL= $("#formCount4").val();
                    var tMAXVAL= $("#settings_event_description_edit_title").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter4").value=tMAXVAL;
                    if(document.getElementById("settings_event_description_edit_title")){
                        ttextarea = document.getElementById("settings_event_description_edit_title");
                        if(document.getElementById("settings_event_description_edit_formFilter4")){
                            tresult   = document.getElementById("settings_event_description_edit_formFilter4");
                            //result.value = tMAXVAL;
                            ttextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                tNEW_VAL = tMAXVAL - v.characters;
                                tresult.value = tNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText1")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_descriptionText1");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText1").value;

                        $('#settings_event_description_edit_descriptionText1').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText1').val($('#settings_event_description_edit_descriptionText1').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText1").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText1').val();
                                $('#settings_event_description_edit_descriptionText1').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText1').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText1').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var htextarea = null;
                    var hresult = null;
                    var hNEW_VAL = null;

                    //var hMAXVAL= $("#formCount1").val();
                    var hMAXVAL= $("#settings_event_description_edit_descriptionText1").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter1").value=hMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText1")){
                        htextarea = document.getElementById("settings_event_description_edit_descriptionText1");
                        if(document.getElementById("settings_event_description_edit_formFilter1")){
                            hresult   = document.getElementById("settings_event_description_edit_formFilter1");
                            //result.value = hMAXVAL;
                            htextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                hNEW_VAL = hMAXVAL - v.characters;
                                hresult.value = hNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText2")){
                    var reg_desciption_var2 = document.getElementById("settings_event_description_edit_descriptionText2");


                    reg_desciption_var2.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText2").value;

                        $('#settings_event_description_edit_descriptionText2').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText2').val($('#settings_event_description_edit_descriptionText2').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText2").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText2').val();
                                $('#settings_event_description_edit_descriptionText2').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText2').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText2').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var btextarea = null;
                    var bresult = null;
                    var bNEW_VAL = null;

                    //var bMAXVAL= $("#formCount2").val();
                    var bMAXVAL= $("#settings_event_description_edit_descriptionText2").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter2").value=bMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText2")){
                        btextarea = document.getElementById("settings_event_description_edit_descriptionText2");
                        if(document.getElementById("settings_event_description_edit_formFilter2")){
                            bresult   = document.getElementById("settings_event_description_edit_formFilter2");
                            //result.value = bMAXVAL;
                            btextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                bNEW_VAL = bMAXVAL - v.characters;
                                bresult.value = bNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText3")){
                    var reg_desciption_var3 = document.getElementById("settings_event_description_edit_descriptionText3");


                    reg_desciption_var3.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText3").value;

                        $('#settings_event_description_edit_descriptionText3').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText3').val($('#settings_event_description_edit_descriptionText3').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText3").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText3').val();
                                $('#settings_event_description_edit_descriptionText3').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText3').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText3').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var ftextarea = null;
                    var fresult = null;
                    var fNEW_VAL = null;

                    //var fMAXVAL= $("#formCount3").val();
                    var fMAXVAL= $("#settings_event_description_edit_descriptionText3").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter3").value=fMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText3")){
                        ftextarea = document.getElementById("settings_event_description_edit_descriptionText3");
                        if(document.getElementById("settings_event_description_edit_formFilter3")){
                            fresult   = document.getElementById("settings_event_description_edit_formFilter3");
                            //result.value = fMAXVAL;
                            ftextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                fNEW_VAL = fMAXVAL - v.characters;
                                fresult.value = fNEW_VAL;
                            }, false);
                        }
                    }
                }
                setTimeout(is_review_complete, 500);
            }/*END OF FUNCTION*/
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_activate(){
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                /*
                    'STANDARD'
                    'TITLE_BODY'
                    'BODY'
                    
                    'HIDDEN'=>'any_visuallyhidden',
                    'STANDARD1'=>'event_edit_description_field1_1',
                    'STANDARD2'=>'event_edit_description_field1_2',
                    'STANDARD3'=>'event_edit_description_field1_3',
                    'TITLE_BODY1'=>'event_edit_description_field2_1',
                    'TITLE_BODY2'=>'event_edit_description_field2_3',
                    'BODY1'=>'event_edit_description_field3_1',
                    
                    'MENU_STANDARD_DEFAULT'=>'event_edit_description_button_default1',
                    'MENU_STANDARD_ACTIVE'=>'event_edit_description_button_active1',
                    'MENU_TITLE_DEFAULT'=>'event_edit_description_button_default2',
                    'MENU_TITLE_ACTIVE'=>'event_edit_description_button_active2',
                    'MENU_BODY_DEFAULT'=>'event_edit_description_button_default3',
                    'MENU_BODY_ACTIVE'=>'event_edit_description_button_active3',
                    
                    'TD_STANDARD1'=>'event_edit_pop_td2_1_ST',
                    'TD_STANDARD2'=>'event_edit_pop_td2_2_ST',
                    'TD_STANDARD3'=>'event_edit_pop_td2_3_ST',
                    
                    'TD_TITLE_BODY1'=>'event_edit_pop_td2_1_TB',
                    'TD_TITLE_BODY2'=>'event_edit_pop_td2_2_TB',
                    
                    'TD_BODY1'=>'event_edit_pop_td2_1_B',
                */
                /* -------------------------------------------------------- */
                $('#notificationsystem_menu_action').hide();
                //$('#notificationsystem_menu_action').show();
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options1");
                var $HIDDEN, $PARENT, $TITLE1, $TITLE2, $STANDARD1,$STANDARD2,$STANDARD3,$TITLE_BODY1,$TITLE_BODY2,$BODY1,$MENU_STANDARD_DEFAULT,$MENU_STANDARD_ACTIVE,$MENU_TITLE_DEFAULT,$MENU_TITLE_ACTIVE,$MENU_BODY_DEFAULT,$MENU_BODY_ACTIVE,$TD_STANDARD1,$TD_STANDARD2,$TD_STANDARD3,$TD_TITLE_BODY1,$TD_TITLE_BODY2,$TD_BODY1,$COUNT_STANDARD1,$COUNT_STANDARD2,$COUNT_STANDARD3,$COUNT_TITLE_BODY1,$COUNT_TITLE_BODY2,$COUNT_BODY1 = null;
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_description_edit_index4').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_description_edit_index4').val());
                    if($DATES_obj_array != null){

                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    /* -------------------------------------------------------- */
                                    CONSOLE_MANAGER('event_description_activate','DATA FOR CLASS: '+element.HIDDEN+' -|COUNT|- '+element.STANDARD1+' -|STATUS|- '+element.STANDARD2+' -- '+element.STANDARD3+' -- '+element.TITLE_BODY1+' -|LIST|- '+element.TITLE_BODY2+' -- '+element.BODY1+' -|VIEW|- '+element.MENU_STANDARD_DEFAULT+' -- '+element.MENU_STANDARD_ACTIVE+' -|SCROLL|- '+element.MENU_TITLE_DEFAULT+' -- '+element.MENU_TITLE_ACTIVE);
                                    /* -------------------------------------------------------- */
                                    $PARENT = element.PARENT;
                                    $HIDDEN = element.HIDDEN;
                                    $TITLE1 = element.TITLE1;
                                    $TITLE2 = element.TITLE2;
                                    $STANDARD1 = element.STANDARD1;
                                    $STANDARD2 = element.STANDARD2;
                                    $STANDARD3 = element.STANDARD3;
                                    $TITLE_BODY1 = element.TITLE_BODY1;
                                    $TITLE_BODY2 = element.TITLE_BODY2;
                                    $BODY1 = element.BODY1;
                                    
                                    $TD_STANDARD1 = element.TD_STANDARD1;
                                    $TD_STANDARD2 = element.TD_STANDARD2;
                                    $TD_STANDARD3 = element.TD_STANDARD3;
                                    $TD_TITLE_BODY1 = element.TD_TITLE_BODY1;
                                    $TD_TITLE_BODY2 = element.TD_TITLE_BODY2;
                                    $TD_BODY1 = element.TD_BODY1;
                                    
                                    
                                    $MENU_STANDARD_DEFAULT = element.MENU_STANDARD_DEFAULT;
                                    $MENU_STANDARD_ACTIVE = element.MENU_STANDARD_ACTIVE;
                                    $MENU_TITLE_DEFAULT = element.MENU_TITLE_DEFAULT;
                                    $MENU_TITLE_ACTIVE = element.MENU_TITLE_ACTIVE;
                                    $MENU_BODY_DEFAULT = element.MENU_BODY_DEFAULT;
                                    $MENU_BODY_ACTIVE = element.MENU_BODY_ACTIVE;
                                    
                                    $COUNT_STANDARD1 = element.COUNT_STANDARD1;
                                    $COUNT_STANDARD2 = element.COUNT_STANDARD2;
                                    $COUNT_STANDARD3 = element.COUNT_STANDARD3;
                                    $COUNT_TITLE_BODY1 = element.COUNT_TITLE_BODY1;
                                    $COUNT_TITLE_BODY2 = element.COUNT_TITLE_BODY2;
                                    $COUNT_BODY1 = element.COUNT_BODY1;
                                }
                            });
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options2");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options3");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options4");
                            /* --------------------------------------------------------  */
                            if($PARENT==true){
                                $('#settings_event_description_edit_title').addClass($TITLE1);
                                $('#settings_event_description_edit_title').removeClass($TITLE2);
                            }else{
                                $('#settings_event_description_edit_title').addClass($TITLE2);
                                $('#settings_event_description_edit_title').removeClass($TITLE1);
                            }
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options2').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options2 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                if($('#settings_event_description_edit_options2').hasClass($MENU_STANDARD_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options3').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options3 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_edit_options3').hasClass($MENU_TITLE_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_TITLE_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                                        $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options4').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options4 CLICK - '+$MENU_BODY_ACTIVE+' - '+$MENU_BODY_DEFAULT);
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_edit_options4').hasClass($MENU_BODY_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').hide();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').hide();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').hide();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_BODY1));
                                        $('#formCount2').val(parseInt(0));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($HIDDEN);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                        }
                    }
                }else{
                    CONSOLE_MANAGER('event_description_activate','INVALID JSON');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                if($('#settings_event_description_edit_descriptionType').val()=='STANDARD' || !$('#settings_event_description_edit_descriptionType').val()){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE STANDARD');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_STANDARD1));
                    $('#formCount2').val(parseInt($COUNT_STANDARD2));
                    $('#formCount3').val(parseInt($COUNT_STANDARD3));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='TITLE_BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE TITLE_BODY');
                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_TITLE_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                    $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE BODY');
                    $('#settings_event_description_edit_descriptionType').val('BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').hide();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').hide();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').hide();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_BODY1));
                    $('#formCount2').val(parseInt(0));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($BODY1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($HIDDEN);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                event_description_count_activate();
            }/*END OF FUNCTION*/
			/* --------------------------------------------------------map_key_search_parameters  */
            /* --------------------------------------------------------  */
			if(document.getElementById("settings_event_photobank_index1")){
				var reg_catelog_var = document.getElementById("settings_event_photobank_index1");
                /* -------------------------------------------------------- */
				reg_catelog_var.onfocus = function() {
					var string = document.getElementById("settings_event_photobank_index1").value;

					$('#settings_event_photobank_index1').keyup(function(event) {
						var key = String.fromCharCode(event.which);
                        //if(event.keyCode == 32){
                        //    $('#settings_event_photobank_index1').val($('#settings_event_photobank_index1').val()+' ');
                        //}
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById("settings_event_photobank_index1").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#settings_event_photobank_index1').val();
							$('#settings_event_photobank_index1').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
                        if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                           (event.keyCode == $.ui.keyCode.DELETE )|| 
                           (event.keyCode == $.ui.keyCode.BACKSPACE )){
                        }
						var $REG_LOG = $('#settings_event_photobank_index1').val();
                        reg_catelog_var.onblur = function($EVENT) {
                            if(string!=$REG_LOG){
								if($( "#event_creator_formFilter1" ).val()=='PARENT'){
									
								}else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                    $('#standard_entity_modify_control').val($('#settings_event_photobank_index1').attr('id'));
                                    $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                    $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                    $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_photobank_index1').val());
                                    event_instance_function('event_activity_interaction_options', $('#settings_event_photobank_index1').attr('id'),$('#settings_event_photobank_index1').val(),$EVENT);
								}
							}
						}
                        setTimeout(is_review_complete, 500);
					}); 
				}
            }
            /* --------------------------------------------------------  */
            
            /* --------------------------------------------------------  */
            $('#event_creator_startDate').unbind().click(function(event) {
                initiate_datetime_creator($('#event_creator_startDate'));//initiate_datetime_picker
                schedule_window_open();
                item_time_selector();
                $('#event_register_div_blocker').hide();
            });
            $('#event_creator_startTime').unbind().click(function(event) {
                initiate_datetime_creator($('#event_creator_startTime'));//initiate_datetime_picker
                schedule_window_open();
                item_time_selector();
                $('#event_register_div_blocker').hide();
            });
            $('#event_creator_endDate').unbind().click(function(event) {
                initiate_datetime_creator($('#event_creator_endDate'));//initiate_datetime_picker
                schedule_window_open();
                item_time_selector();
                $('#event_register_div_blocker').hide();
            });
            $('#event_creator_endTime').unbind().click(function(event) {
                initiate_datetime_creator($('#event_creator_endTime'));//initiate_datetime_picker
                schedule_window_open();
                item_time_selector();
                $('#event_register_div_blocker').hide();
            });
			/* --------------------------------------------------------  */
            if($('#event_creator_startDate').is(':visible') && $('#event_creator_startDate').val()){
                var $ST_DATE = $('#event_creator_startDate').val();
                if($ST_DATE.length && $ST_DATE!='FALSE'){
				    $('#event_creator_startDate').val(moment($ST_DATE).format('MMMM DD YYYY'));
                }else{
                    $('#event_creator_startDate').val(null);
                }
            }
			/* --------------------------------------------------------  */
			if($('#event_creator_startTime').is(':visible') && $('#event_creator_startTime').val()){
                var $ST_DATE = $('#event_creator_startTime').val();
                if($ST_DATE.length){
                    $('#event_creator_startTime').val(moment($ST_DATE).format('h:mm A'));
                }
            }
            /* --------------------------------------------------------  */
            if($('#event_creator_endDate').is(':visible') && $('#event_creator_endDate').val()){
                var $ST_DATE = $('#event_creator_endDate').val();
                if($ST_DATE.length && $ST_DATE!='FALSE'){
                    $('#event_creator_endDate').val(moment($ST_DATE).format('MMMM DD YYYY'));//LLLL
                }else{
                    $('#event_creator_endDate').val(null);
                }
            }			
           	/* --------------------------------------------------------  */
			if($('#event_creator_endTime').is(':visible') && $('#event_creator_endTime').val()){
                var $ST_DATE = $('#event_creator_endTime').val();
                if($ST_DATE.length){
                    $('#event_creator_endTime').val(moment($ST_DATE).format('h:mm A'));
                }
            }
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* -------------------------------------------------------- 
            $('#event_creator_options7').unbind().click(function(event) {
                var $TIME = moment.utc([]).add(2, 'minutes').local();
                var $FUTURE = moment.utc($TIME).add(1, 'hours').local();
                $("#startTime").val(moment($TIME).format('LT'));
                $("#event_creator_startTime").val(moment($TIME).format('LT'));
                $("#endTime").val(moment($FUTURE).format('LT'));
                $("#event_creator_endTime").val(moment($FUTURE).format('LT'));

                $('#startDate').val(moment($TIME).format('MMMM DD YYYY'));
				$('#event_creator_start').val(moment($TIME).format());
                $('#event_creator_startDate').val(moment($TIME).format('MMMM DD YYYY'));
                $('#endDate').val(moment($FUTURE).format('MMMM DD YYYY'));
                $('#event_creator_endDate').val(moment($FUTURE).format('MMMM DD YYYY'));
				$('#event_creator_end').val(moment($FUTURE).format());
                parseTime();
				if($( "#event_creator_formFilter1" ).val()=='PARENT'){
								   
									
				}else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
					$('#standard_entity_modify_control').val($('#event_creator_start').attr('id'));
					$('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
					$('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
					$('#standard_entity_modify_index2').val($('#event_creator_uniqEventId').val());
					$('#standard_entity_modify_index3').val($('#event_creator_registerCode').val());
					$('#standard_entity_modify_startDate').val($('#event_creator_start').val());
					$('#standard_entity_modify_endDate').val($('#event_creator_end').val());
                    
                    $('#standard_entity_modify_occurrence').val($('#event_creator_occurrence').val());
					$('#standard_entity_modify_occurrenceScale').val($('#event_creator_occurrenceScale').val());
					$('#standard_entity_modify_occurrenceSequence').val($('#event_creator_occurrenceSequence').val());
                    $('#standard_entity_modify_occurrenceRange').val($('#event_creator_occurrenceRange').val());
                    
					//windowload_script('form_event_creator_MGMT - $temp');
					event_instance_function('event_activity_interaction_options', $('#event_creator_start').attr('id'),$('#event_creator_start').val(),event);
				}
            });
             */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* AJAX LISTENERS  (MutationObserver) ---------------------  verify_options1 */
            var MODIFY_VIEW_LISTENER = (function (){
                var target = document.getElementById('window_view');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                var observer = new MutationObserver(function(mutations) {
                    mutations.forEach(function(mutation) {
                        $(document).ready(function() {
                            CONSOLE_MANAGER('form_event_creator_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							var $MODIFY_CNTRL = $('#standard_entity_modify_control').val();
                            /* -------------------------------------------------------- 
                            $(window).on('resize', function(){
                                if($('.ol-viewport').is(':visible')){
                                    $('#event_register_div_blocker').hide();
                                }
                            });
                            if($('.ol-viewport').is(':visible')){
                               $('#event_register_div_blocker').hide();
                            }*/
                            /* -------------------------------------------------------- */
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('form_event_creator_MGMT',mutation.type+': attributes');
								if($('#window_view').is(':visible')){
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
									if($MODIFY_CNTRL==$('#settings_streambank_options1').attr('id')||$MODIFY_CNTRL==$('#settings_streambank_options2').attr('id')){
										$('.sp-container').hide();
										EVENT_SETTINGS_STREAMING_OPTIONS();
                                        $('#event_register_div_blocker').hide();
										$('#standard_entity_modify_control').val(null);
									}
                                    if($MODIFY_CNTRL=='EVENT_MAP'){
										event_create_pop_map_activate();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
									}
                                    if($MODIFY_CNTRL=='EVENT_MAP_GLOBAL'){//event_register_div_manager
										event_create_pop_manager_map_activate();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
									}
                                    if($MODIFY_CNTRL=='EVENT_NOW'){
										event_create_pop_map_activate();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
									}
                                    if($MODIFY_CNTRL=='EVENT_CATEGORY_EDIT'){
                                        category_menu();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
									}
                                    $('#event_register_div_blocker').hide();
								}
								else if($('#window_view').is(':hidden')){
									if($MODIFY_CNTRL==$('#settings_streambank_options1').attr('id')||$MODIFY_CNTRL==$('#settings_streambank_options2').attr('id')){
										REFRESH_CLEAR_MANAGER();
										$('.sp-container').show();
										$('#standard_entity_modify_control').val(null);
									}
                                    setTimeout(is_review_complete, 500);
								}
                                /* -------------------------------------------------------- */
                            }
                            /* -------------------------------------------------------- */
                            else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('form_event_creator_MGMT',mutation.type+': childList');
                                /* -------------------------------------------------------- */
                                /* -------------------------------------------------------- */
                                if($('#window_view').is(':visible')){
                                    /* -------------------------------------------------------- */
                                    /*  EVENT REGISTER MANAGER MENU */
                                    if(($('#event_creator_address').val()==' ')&&($('#event_creator_startDate').val()==false && (!$('#event_creator_startTime').val()) && $('#event_creator_endDate').val()==false && (!$('#event_creator_endTime').val()))){
                                        $('#event_register_div_manager').show();
                                        $('#event_register_div_blocker').hide();
                                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options4'),null,null,null,null);
                                        $('#event_creator_options5').unbind().click(function(event) {
                                            initiate_datetime_creator($('#event_creator_startDate'));//initiate_datetime_picker
                                            schedule_window_open();
                                            item_time_selector();
                                            $('#event_register_div_blocker').hide();
                                        });
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    else{
                                        $('#event_register_div_manager').hide();
                                        $('#event_register_div_blocker').show();
                                        NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options15'),null,null,null,null);
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    /* -------------------------------------------------------- */
                                    if($MODIFY_CNTRL=='EVENT_DESCRIPTION'){
                                        event_description_activate();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
                                    }
                                    /* -------------------------------------------------------- */
                                    if($MODIFY_CNTRL=='EVENT_CATEGORY_EDIT'){
                                        category_menu();
                                        $('#event_register_div_blocker').hide();
                                        $('#standard_entity_modify_control').val(null);
									}
                                    $('#event_register_div_blocker').hide();
                                }
								else if($('#window_view').is(':hidden')){
                                    /* -------------------------------------------------------- */
                                    setTimeout(is_review_complete, 500);
                                    /* -------------------------------------------------------- */
                                }
                            }
                            /* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
                            modify_description();
                            /* -------------------------------------------------------- */
                            $(window).on('resize', function(){
                                if($('.ol-viewport').is(':visible')){
                                    $('#event_register_div_blocker').hide();
                                }
                            });
                            if($('.ol-viewport').is(':visible')){
                               $('#event_register_div_blocker').hide();
                            }
                            /* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
                        });
                    });    
                });//END new MutationObserver
                var config = { attributes: true, childList: true, characterData: true };
                observer.observe(target, config);
                //observer.disconnect();

            })();/*END FUNCTION */
			/* --------------------------------------------------------	*/
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  
			//var address_var = document.getElementById("event_creator_address");
            $( "#event_creator_address" ).focusin(function() {
                var $autoObj = MGMT_google_autocomplete('event_creator_address');
                $( ".pac-container" ).css('z-index', 3000);
            });
            if(document.getElementById("event_creator_address")){
                $( "#event_creator_address" ).keyup(function(e) {
                    $( ".pac-container" ).css('z-index', 3000);
					
                    //var codes = [9,13,32];
                    var codes = [32];
                    document.getElementById("event_creator_address").addEventListener('keydown', function(e) {
                    if(!contains(codes, e.keyCode)) return;
						var path = e.path || e.composedPath()
                        if(!path[0]) return;

                        e.preventDefault();
                        path[0].value += String.fromCharCode(e.keyCode);//
                        var $STRING=$('#event_creator_address').val();
                        $('#event_creator_address').val($STRING.replace(/\s{2,}/g, ' '));
                    })
                    function contains(arr, item) {
                        for(var i=0;i<arr.length;i++)
                        if(arr[i]==item)return true;
                        return false;
                    }
                    
                    var $autoObj = MGMT_google_autocomplete('event_creator_address');
                    
                });
            }*/
            /* -------------------------------------------------------- */
            var $hlength = 0;
            if($('#host_objects_count').val()>0){
                var $hlength = parseInt($('#host_objects_count').val())+1;
                var $code = $("#host_object").val();
                for(var $i=1; $i< $hlength; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                    var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                    var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                    var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                    var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                    var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                    var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
                    var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                    var $TITLE = '#form_'+$code+'_'+$i+'_title';
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                    SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                    SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options3');
                    SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options7');
                    SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_title');
                    if($($OPTIONS01)){
                        if($($PATHSEQUENCE).val()){
                            $($OPTIONS01).css('background-image','url('+$($PATHSEQUENCE).val()+')');
                        }
                        /* ------------------------------------------------- */
                        host_standard_entity_modify_register($($OPTIONS01),$($TITLE),$($INDEX01))
                        host_standard_entity_modify_register($($OPTIONS03),$($TITLE),$($INDEX01))
                        host_standard_entity_modify_register($($OPTIONS07),$($TITLE),$($INDEX01))
                        host_standard_entity_modify_register($($OPTIONS08),$($TITLE),$($INDEX01))
                    }
                    /* ------------------------------------------------- */
                    function host_standard_entity_modify_register($button_obj,$TITLE,$INDEX01){
                        $button_obj.unbind().click(function(event) {
                            $('#standard_entity_modify_control').val($INDEX01.val());
                            $('#standard_entity_modify_title').val($TITLE.val());
                            $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                            standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event);
                        });
                    }
                }
			}
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            var $ENTITYLISTMgmt = [];
            var $ENTITYLISTCOORDMgmt = [];
            //var $code = $("#index_object").val();
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            if(document.getElementById("total_objects_count_item")){
                var Object_count = parseInt(document.getElementById("total_objects_count_item").value + 1);
                var $code = $("#index_object_item").val();
                for(var $i=1; $i<Object_count; $i++){

                    if($('#form_'+$code+'_'+$i+'_options9').is(':visible')){
                        /* ------------------------------------------------- */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                        var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                        var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                        var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                        var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                        var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
                        var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
                        var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                        var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                        var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                        var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                        var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                        var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
                        var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						var $CURRENTINDEX = '#form_'+$code+'_'+$i+'_formCurrentIndex';

                        /* ------------------------------------------------- */
                        if($('#event_creator_registerCode').val() == $($INDEX03).val()){
                           $($CURRENTINDEX).val($($INDEX03).val());
                        }
                        /* ------------------------------------------------- */
                        $($OPTIONS04).css("background-color",$($COLORSEQUENCE).val());
                        /* ------------------------------------------------- */
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                        //if($($PATHSEQUENCE).val()){
                        //	$($OPTIONS03).css('background-image','url('+$($PATHSEQUENCE).val()+')');
                        //}
                        /* ------------------------------------------------- */	
                        ITEM_DURATION($($OPTIONS10),$($OPTIONS05),$($OPTIONS07));
                        /* ------------------------------------------------- */	
                        var $SUBMgmt = {GET_UNIQ_ID:$($INDEX02).val(),GET_ITEM_ID:$($INDEX03).val(),SET_STATUS:$($INDEX04).val()};
                        $ENTITYLISTMgmt[$i] =$SUBMgmt;
                        /* ------------------------------------------------- */
                        CONSOLE_MANAGER('form_event_creator_MGMT - index_object_item','child SELECTED INDEX: '+$($CURRENTINDEX).val());
                        CONSOLE_MANAGER('form_event_creator_MGMT - index_object_item','child ITEM: '+$($INDEX03).val());
                        CONSOLE_MANAGER('form_event_creator_MGMT - index_object_item','child COLOR: '+$($COLORSEQUENCE).val());
                        /* ------------------------------------------------- */
                        //header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                        REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        //REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS09),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
                        var $SETCOLOR =null;
                        var $SETADDRESS =  null;
                        var $SETCITY =  null;
                        var $SETSTATE = null;
                        var $SETCOUNTRY = null;
                        var $SETLAT = null;
                        var $SETLONG = null;
                        var $SETACTIVE = null;
                        if($($SELECTEDSEQUENCE).val()){
                            $SETCOLOR = '#form_'+$code+'_'+$i+'_colorCode';
                            $SETADDRESS = '#form_'+$code+'_'+$i+'_address';
                            $SETCITY = '#form_'+$code+'_'+$i+'_city';
                            $SETSTATE = '#form_'+$code+'_'+$i+'_state';
                            $SETCOUNTRY = '#form_'+$code+'_'+$i+'_country';
                            $SETLAT = '#form_'+$code+'_'+$i+'_lat';
                            $SETLONG = '#form_'+$code+'_'+$i+'_lon';

                            if(($($SETLAT).val()==0)||($($SETLAT).val()==0.0) && ($($SETLONG).val()==0)||($($SETLONG).val()==0.0)){
                                $($SETLAT).val($('#map_key_lat').val());
                                $($SETLONG).val($('#map_key_lon').val());
                            }
                            else{
                                $SETACTIVE=true;
                            }

                            $($OPTIONS03).css({"border-color": 'green',"border-width":"6px",  "border-style":"solid"});
                            initiate_datetime_picker($SELECTEDSEQUENCE,$INDEX02,$INDEX03,$COLORSEQUENCE);
                        }else{
                            if($i==1){
                                initiate_datetime_picker($SELECTEDSEQUENCE,$INDEX02,$INDEX03,$COLORSEQUENCE);
                            }
                        }
                    }	
                }
                $('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));   
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- 
                var conn = new ab.Session('ws://127.0.0.1:8080',
                    function($EVENT) {
                        $EVENT.stopPropagation;
                        var $DATES_obj_array=[];	
                        conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                            var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                            if(jsonValidate==true){
                                $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                                if($DATES_obj_array != null){

                                    if($DATES_obj_array.length > 0){
                                        $DATES_obj_array.forEach((element, index, array) => {
                                            if(element){
                                                if(typeof element.GET_UNIQ_ID != "undefined"){
                                                    if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                                        var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                                        //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                                        //activityCLASS	scheduleCLASS	itemviewCLASS
                                                        $(element.SET_STATUS).removeClass(className);
                                                        $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    },
                    function() {
                        console.warn('WebSocket connection closed');
                    },			   
                    {'skipSubprotocolCheck': true}		   
                );*//*END FUNCTION */
               	/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				if(document.getElementById("data_count")){
					var Object_count = parseInt(document.getElementById("data_count").value + 1);
					var $code = $("#data_code").val();
					for(var $i=1; $i<Object_count; $i++){

						if($('#form_'+$code+'_'+$i+'_options20').is(':visible')){
							var $OPTIONS20 = '#form_'+$code+'_'+$i+'_options20';
							var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
							var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
							var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
							var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
							var $TITLE = '#form_'+$code+'_'+$i+'_title';
							var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
							var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
							var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
							var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
							var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
							var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
							var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
							var $CURRENTINDEX = '#form_'+$code+'_'+$i+'_formCurrentIndex';
							/* ------------------------------------------------- */
							/* ------------------------------------------------- 
							//var $form_entity = $($INDEX01).val();
							if($($PATHSEQUENCE).val()){
								header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
							}
							*/
							/* ------------------------------------------------- */
							REGISTER_CLICK($($OPTIONS20),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
							//REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07),$($CURRENTINDEX));
							//REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06),$($INDEX07),$($CURRENTINDEX));
							//REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07),$($CURRENTINDEX));
							//REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07),$($CURRENTINDEX));
							//REGISTER_CLICK($($OPTIONS01ACTIVE),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						}	
					}
				}
				/* --------------------------------------------------------  */
                /* -------------------------------------------------------- */
                /* --------------------------------------------------------  */
                function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06,$INDEX07, $CURRENTINDEX){
					$button_obj.unbind().click(function(event) {
						event.stopPropagation;
                            $('#standard_entity_modify_title').val($TITLE.val());
                            $('#standard_entity_modify_index1').val($INDEX01.val());
                            $('#standard_entity_modify_index2').val($INDEX02.val());
                            $('#standard_entity_modify_index3').val($INDEX03.val());
                            $('#standard_entity_modify_index4').val($INDEX04.val());
                            $('#standard_entity_modify_index5').val($INDEX05.val());
                            $('#standard_entity_modify_index6').val($INDEX06.val());
                            $('#standard_entity_modify_index7').val($button_obj.attr('id'));
							$('#standard_entity_modify_formCurrentIndex').val($CURRENTINDEX.val());
                            $('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                            standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
                }
            }
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            function category_menu(){
                CONSOLE_MANAGER('category_menu','ACTIVE');
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#event_creator_category').val());
                if(document.getElementById("total_category_objects_count")){
					var Object_count = parseInt(document.getElementById("total_category_objects_count").value + 1);
					var $code = $("#index_category_object").val();
                    CONSOLE_MANAGER('category_menu $code ------- ',$code);
					for(var $i=1; $i<Object_count; $i++){
						if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                            CONSOLE_MANAGER('category_menu','ACTIVE');
							var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
							var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
							var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
							var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
							//var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
							var $TITLE = '#form_'+$code+'_'+$i+'_title';
							var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
							var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
							var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
							var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
							var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
							var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
							var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
							var $CURRENTINDEX = '#form_'+$code+'_'+$i+'_formCurrentIndex';
							/* ------------------------------------------------- */
							/* ------------------------------------------------- */
                            if(jsonValidate==true){
                                $DATES_obj_array = JSON.parse($('#event_creator_category').val());
                                if($DATES_obj_array != null){
                                    /* -------------------------------------------------------- */
                                    if($DATES_obj_array.length > 0){
                                        $DATES_obj_array.forEach((element, index, array) => {
                                            if(element){
                                                CONSOLE_MANAGER('category_menu element ------- ',element);
                                                if(element == $($INDEX01).val()){
                                                    $($OPTIONS01).addClass('holiday_select_active'); 
                                                }
                                            }
                                        });
                                    }/** END IF **/
                                }/** END IF **/
                            }
							/* ------------------------------------------------- */
                            REGISTER_CLICK_SELECT($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS01));
							REGISTER_CLICK_SELECT($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS01));
                            
						}	
					}
                    /* -------------------------------------------------------- */
                    function REGISTER_CLICK_SELECT($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS01){
                        $button_obj.unbind().click(function($EVENT) {
                            event.stopPropagation;
                            CONSOLE_MANAGER('category_menu REGISTER_CLICK_SELECT ------- ',$OPTIONS01.attr('id'));
                            /* --------------------------------------------------------  */
                            if($OPTIONS01.hasClass('holiday_select_active')){
                                $OPTIONS01.removeClass('holiday_select_active');

                            }else{
                                //$OPTIONS01.removeClass('holiday_select_active');
                                $OPTIONS01.addClass('holiday_select_active'); 
                            }
                            $('#standard_entity_modify_formCurrentIndex').val($INDEX01.val());
                            $('#standard_entity_modify_index1').val($INDEX01.val());
                            $('#standard_entity_modify_index2').val($INDEX02.val());
                            $('#standard_entity_modify_index3').val($INDEX03.val());
                            $('#standard_entity_modify_index4').val($INDEX04.val());
                            $('#standard_entity_modify_uniqEventId').val($INDEX02.val());
                            $('#standard_entity_modify_registerCode').val($INDEX03.val());
                            $('#standard_entity_modify_control').val('CATEGORIES');//$OPTIONS01.attr('value')
                            $('#standard_entity_modify_formFilter1').val($OPTIONS01.attr('id'));
                            $('#standard_entity_modify_formFilter2').val($OPTIONS01.attr('id'));
                            $('#standard_entity_modify_formFilter3').val($OPTIONS01.attr('id'));
                            $('#standard_entity_modify_formFilter8').val($OPTIONS01.attr('id'));
                            /* --------------------------------------------------------  */
                            var $DATA = $('.Interaction_Activity_UI_class');
                            var $URL = '/system/holiday/event/category/modify';
                            /* ------------------------------------------------- */	
                            var $DATA_RETURN = control_update_noview_ajax(
                                $DATA,
                                $URL,
                                $EVENT
                            );
                            if($DATA_RETURN){
                            }
                            /* END DATA RETURN */
                        });   
                    }
				}
            }
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            //SET_BUTTON_CLICK_ACTION("event_creator_options4");
            //SET_BUTTON_CLICK_ACTION("event_creator_options5");
            //SET_BUTTON_CLICK_ACTION("event_creator_options7");
            //window_view_close_button
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#map'),'form_event_creator_MGMT',null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#item_form_map_option'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options7'),null,null,null,null);
            SET_BUTTON_CLICK_ACTION("event_creator_options16active");
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#description_option'),null,null,null,null);
            //NOTIFICATIONSYSTEM_LOAD_MGMT($('#sei_option'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options16'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options15'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_container_data_holiday_category_button'),null,null,null,null);
            /* -------------------------------------------------------- */
            $('#event_container_activity_address_div_option').unbind().click(function(event) {
                CONSOLE_MANAGER('event_container_activity_address_div_option','RESET CLICK');
                //$('#event_creator_options15').html(null);
                //$('#event_creator_options15').value(null);
                //$('#event_creator_address').value('unregistered');
                //$('#event_creator_city').value('unregistered');
                //$('#event_creator_state').value('unregistered');
                //$('#event_creator_country').value('unregistered');
                //$('#event_creator_postal').value('unregistered');
            });
            /* -------------------------------------------------------- */
            /*  EVENT REGISTER MANAGER MENU */
            CONSOLE_MANAGER('form_event_creator_MGMT','event_creator_address value: '+ $('#event_creator_address').val());
            CONSOLE_MANAGER('form_event_creator_MGMT','event_creator_startDate value: '+ $('#event_creator_startDate').val());
            CONSOLE_MANAGER('form_event_creator_MGMT','event_creator_startTime value: '+ $('#event_creator_startTime').val());
            CONSOLE_MANAGER('form_event_creator_MGMT','event_creator_endDate value: '+ $('#event_creator_endDate').val());
            CONSOLE_MANAGER('form_event_creator_MGMT','event_creator_endTime value: '+ $('#event_creator_endTime').val());
            /* -------------------------------------------------------- */
            function modify_description(){//event_register_div_blocker
                if(($('#event_creator_address').val()==' ')&&($('#event_creator_startDate').val()==false && (!$('#event_creator_startTime').val()) && $('#event_creator_endDate').val()==false && (!$('#event_creator_endTime').val()))){
                    $('#event_register_div_manager').show();
                    $('#event_register_div_blocker').hide();
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options4'),null,null,null,null);
                    //NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options7'),null,null,null,null);
                    $('#event_creator_options5').unbind().click(function(event) {
                        initiate_datetime_creator($('#event_creator_startDate'));//initiate_datetime_picker
                        schedule_window_open();
                        item_time_selector();
                    });
                }
                else{
                    $('#event_register_div_manager').hide();
                    $('#event_register_div_blocker').show();
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options4'),null,null,null,null);
                }
            }
            modify_description()
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#type_objects_count').val()>0){
                var $vlength = parseInt($('#type_objects_count').val())+1;
                var $code = $("#type_object").val();
                for(var $i=1; $i< $vlength; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                    SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                    REGISTER_TYPE($($OPTIONS01),$($INDEX01),$($INDEX02),$($INDEX03));
					REGISTER_TYPE($($OPTIONS02),$($INDEX01),$($INDEX02),$($INDEX03));
                }
				function REGISTER_TYPE($button_obj,$INDEX01,$INDEX02,$INDEX03){
					$button_obj.unbind().click(function(event) {
						event.stopPropagation;
                            $('#standard_entity_modify_index1').val($INDEX01.val());
                            $('#standard_entity_modify_index2').val($INDEX02.val());
							$('#standard_entity_modify_index3').val($INDEX03.val());
                            $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                            $('#standard_entity_modify_control').val($('#event_creator_formCurrentIndex').attr('id'));
                            standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
                }
			}
			/* -------------------------------------------------------- */
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            function is_review_complete(){
                //CONSOLE_MANAGER('is_review_complete','--------------    ACTIVE     ----------');
                CONSOLE_MANAGER('form_event_creator_MGMT',' is_review_complete --------------    ACTIVE     ----------');
                /* --------------------------------------------------------  */ 
                CONSOLE_MANAGER('form_event_creator_MGMT',' is_review_complete --------------    ACTIVE     ----------');
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */ 
                /* --------------------------------------------------------  */ 
                if($("#event_creator_startDate").val()&&$("#event_creator_startDate").val()!='FALSE' && $("#event_creator_startDate").val()!=false){
                    $("#event_creator_formFilter2").val(true);
                }else{
                    $("#event_creator_formFilter2").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_startTime").val()&&$("#event_creator_startTime").val()!='FALSE' && $("#event_creator_startTime").val()!=false){
                    $("#event_creator_formFilter3").val(true);
                }else{
                    $("#event_creator_formFilter3").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_endDate").val()&&$("#event_creator_endDate").val()!='FALSE' && $("#event_creator_endDate").val()!=false){
                    $("#event_creator_formFilter4").val(true);
                }else{
                    $("#event_creator_formFilter4").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_endTime").val()&&$("#event_creator_endTime").val()!='FALSE' && $("#event_creator_endTime").val()!=false){
                    $("#event_creator_formFilter5").val(true);
                }else{
                    $("#event_creator_formFilter5").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_address").val()&&$("#event_creator_address").val()!='FALSE'&&$("#event_creator_address").val()!=' '){
                    $("#event_creator_formFilter6").val(true);
                }else{
                    $("#event_creator_formFilter6").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_city").val()&&$("#event_creator_city").val()!='FALSE'&&$("#event_creator_city").val()!=' '){
                    $("#event_creator_formFilter7").val(true);
                }else{
                    $("#event_creator_formFilter7").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_state").val()&&$("#event_creator_state").val()!='FALSE'&&$("#event_creator_state").val()!=' '){
                    $("#event_creator_formFilter8").val(true);
                }else{
                    $("#event_creator_formFilter8").val(null);
                }
                /* --------------------------------------------------------  */ 
                if($("#event_creator_country").val()&&$("#event_creator_country").val()!='FALSE'&&$("#event_creator_country").val()!=' '){
                    $("#event_creator_formFilter9").val(true);
                }else{
                    $("#event_creator_formFilter9").val(null);
                }
                /* --------------------------------------------------------  */ 
                CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-1 (START DATE): '+$("#event_creator_formFilter2").val()+' - 2 (START TIME): '+$("#event_creator_formFilter3").val()+' - 3 (END DATE): '+$("#event_creator_formFilter4").val()); 
                CONSOLE_MANAGER('is_review_complete -PRE RESULTS - ','-4 (END TIME): '+$("#event_creator_formFilter5").val()+' -5 (ADDRESS): '+$("#event_creator_formFilter6").val()+' -6 (CITY): '+$("#event_creator_formFilter7").val()+' -7 (STATE): '+$("#event_creator_formFilter8").val()+' -8 (COUNTRY): '+$("#event_creator_formFilter9").val());
                /* --------------------------------------------------------  */ 
                if(($("#event_creator_formFilter2").val())&&
                   ($("#event_creator_formFilter3").val())&&($("#event_creator_formFilter4").val())&&
                   ($("#event_creator_formFilter5").val())&&($("#event_creator_formFilter6").val())&&
                   ($("#event_creator_formFilter7").val())&&($("#event_creator_formFilter8").val())&&
                   ($("#event_creator_formFilter9").val())
                  ){
                    CONSOLE_MANAGER('is_review_complete','--------------    COMPLETE     ----------');
                    /* --------------------------------------------------------  */
                    if($("#event_creator_formFilter2").val()&&
                       ($("#event_creator_formFilter3").val())
                      ){
                        $('#event_container_short_div1').addClass('event_container_short_div1');
                        $('#event_container_short_div1').removeClass('event_container_short_div1_error');
                    }
                    /* --------------------------------------------------------  */
                    if($("#event_creator_formFilter4").val()&&
                       $("#event_creator_formFilter5").val()
                      ){
                        $('#event_container_short_div2').addClass('event_container_short_div2');
                        $('#event_container_short_div2').removeClass('event_container_short_div2_error');                        
                    }
                    /* --------------------------------------------------------  */
                    if($("#event_creator_formFilter6").val()&&
                       $("#event_creator_formFilter7").val()&&$("#event_creator_formFilter8").val()&&
                       $("#event_creator_formFilter9").val()
                      ){
                        $('#event_container_activity_address_div').addClass('event_container_activity_address_div');
                        $('#event_container_activity_address_div').removeClass('event_container_activity_address_div_error');
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    $('#event_creator_options3').attr('disabled',false);
                    $('#event_creator_options3').removeClass('event_container_add_button');
                    $('#event_creator_options3').addClass('event_container_add_button_active');
                    //SET_BUTTON_CLICK_ACTION("registration_options1");
                    NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_creator_options3'),null,null,null,null);
                    //$('#standard_entity_modify_control').val($('#registration_options1').attr('id'));
                }else{
                    CONSOLE_MANAGER('is_review_complete','--------------    INCOMPLETE     ----------');
                    /* --------------------------------------------------------  */
                    if(!$("#event_creator_formFilter2").val()&&
                       !($("#event_creator_formFilter3").val())
                      ){
                        $('#event_container_short_div1').addClass('event_container_short_div1_error');
                        $('#event_container_short_div1').removeClass('event_container_short_div1');
                    }
                    /* --------------------------------------------------------  */
                    if(!$("#event_creator_formFilter4").val()&&
                       !$("#event_creator_formFilter5").val()
                      ){
                        $('#event_container_short_div2').addClass('event_container_short_div2_error');
                        $('#event_container_short_div2').removeClass('event_container_short_div2');
                    }
                    /* --------------------------------------------------------  */
                    if(!$("#event_creator_formFilter6").val()&&
                       !$("#event_creator_formFilter7").val()&&!$("#event_creator_formFilter8").val()&&
                       !$("#event_creator_formFilter9").val()
                      ){
                        $('#event_container_activity_address_div').addClass('event_container_activity_address_div_error');
                        $('#event_container_activity_address_div').removeClass('event_container_activity_address_div');
                    }
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */
                    /* --------------------------------------------------------  */ 
                    $('#event_creator_options3').attr('disabled',true); 
                    $('#event_creator_options3').removeClass('event_container_add_button_active');
                    $('#event_creator_options3').addClass('event_container_add_button');
                }
            }/**	END OF $(document).ready(function()*/
             setTimeout(is_review_complete, 500);
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('form_event_creator_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_MAP_basics                    ########################## */
export function EVENT_MAP_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('EVENT_MAP_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			event_map_activate();
			/* -------------------------------------------------------- */
            if($('#social_media_share_options1').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("social_media_share_options1");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MULTI_REGISTER_DATE_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('EVENT_MAP_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							if(mutation.type=='attributes'){
								if($("#window_view").is(':visible')){
									if($("#standard_results_ajax_control").val()){
										var $RESULTS_CNTRL = $SEARCH_CNTRL = null;
										$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
										if($('#search_request_control').length){
										   $SEARCH_CNTRL = $('#search_request_control').val();
										}
										/* -------------------------------------------------------- */
										if(mutation.type=='attributes'){
											if(($('#standard_results_ajax_control').val())){
												CONSOLE_MANAGER('EVENT_MAP_basics',mutation.type+': attributes');
												if($RESULTS_CNTRL.includes('Filter_FormType')){
													Filter_FormType_init(null);
												}
											}
										}else if(mutation.type=='childList'){
											CONSOLE_MANAGER('EVENT_MAP_basics',mutation.type+': childList');
											/* -------------------------------------------------------- */

										}
									}
									/* -------------------------------------------------------- */
								}
								if($( "#window_view" ).is(':hidden')){
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
								}
							}else if(mutation.type=='childList'){
								/* --------------------------------------------------------  */

							} 
							translate_ajax_clear(true);
							$RESULTS_CNTRL = $SEARCH_CNTRL = null;
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_MAP_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_Catalog                    ########################## */
export function EVENT_Catalog(){
		$(document).ready(function() {
            CONSOLE_MANAGER('EVENT_Catalog','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("social_metrics_options1");
			SET_BUTTON_CLICK_ACTION("social_metrics_options2");
			SET_BUTTON_CLICK_ACTION("social_metrics_options3");
			SET_BUTTON_CLICK_ACTION("social_metrics_options4");
			SET_BUTTON_CLICK_ACTION("social_metrics_options5");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            if($('#social_media_share_options1').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("social_media_share_options1");
			}
            $('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                    var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
					var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
					var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
					
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                   	header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
					
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options4');
					if($($OPTIONS04).hasClass('photolist_upload') || $($OPTIONS04).hasClass('photolist_upload_image')){
                        CONSOLE_MANAGER('EVENT_Catalog','$($OPTIONS04): '+$($OPTIONS04).hasClass('photolist_upload'));
						REGISTER_UPLOAD($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_UPLOAD($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        /* -------------------------------------------------------- */
                        //REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_OBJECT_CLICK($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_OBJECT_CLICK($($OPTIONS05),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        REGISTER_OBJECT_CLICK($($OPTIONS06),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        /* -------------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
						REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
						REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        /* -------------------------------------------------------- */
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					 }else{
						/* -------------------------------------------------------- */
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						/* -------------------------------------------------------- */
						REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						//REGISTER_VIEW($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
						/* -------------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
						REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
						REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
						/* -------------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
						/* -------------------------------------------------------- */
					 }
                }
            }
            //$('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
            /*
			//$('.Listview_options_Activity_UI_class').attr("id", 'Listview_options_Activity_UI_class');
            $('#Event_File_Upload_Label').unbind().click(function(event) {
                $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label').attr('id'));

                ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
            });
            */
            
			function REGISTER_UPLOAD($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				$button_obj.unbind().click(function(event) {
                    CONSOLE_MANAGER('EVENT_Catalog','REGISTER_UPLOAD: '+$INDEX04.val());
					$('#Event_File_Upload_Label').unbind().click();
					$('#entity_file_upload_options_index1').val($INDEX04.val());
					$('#entity_file_upload_options_index4').val($INDEX04.val());
					ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
				});
			}
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				NOTIFICATIONSYSTEM_LOAD_MGMT($button_obj,$code,$INDEX02,$INDEX03,$INDEX04);
            }
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			}
            /* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_OBJECT_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				$button_obj.unbind().click(function(event) {
					$('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
            /* -------------------------------------------------------- */
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_like_active')){
					    $button_obj.removeClass('photobucket_action_like_active');
                       	$button_obj.addClass('photobucket_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_action_like_disabled')){
						$button_obj.removeClass('photobucket_action_like_disabled');
                       	$button_obj.addClass('photobucket_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_action_dislike_active');
							$OPTIONS11.addClass('photobucket_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter3').val($OPTIONS11.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/photosystem/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){
                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_dislike_active')){
					    $button_obj.removeClass('photobucket_action_dislike_active');
                       	$button_obj.addClass('photobucket_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_action_like_active')){
							$OPTIONS10.removeClass('photobucket_action_like_active');
							$OPTIONS10.addClass('photobucket_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/photosystem/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){
                    }/* END DATA RETURN */
                });   
            }
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){  
                                CONSOLE_MANAGER('EVENT_Catalog','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
												var $length = parseInt($('#population_index_count').val())+1;
												var $code = $("#population_index").val();
												for(var $i=0; $i< $length; $i++){
													var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
													//var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
													var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
													var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
													var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
													var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
													var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
													header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
												}
											//}
											/* -------------------------------------------------------- */
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqEventId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('EVENT_Catalog','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('EVENT_Catalog',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('EVENT_Catalog','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('EVENT_Catalog',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('EVENT_Catalog',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									CONSOLE_MANAGER('EVENT_Catalog','----------------------- visible');
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									CONSOLE_MANAGER('EVENT_Catalog','-----------------------not visible');
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_Catalog');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_Media                    ########################## */
export function EVENT_Media(){
		$(document).ready(function() {
            CONSOLE_MANAGER('EVENT_Media','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            media_image_activate();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
					
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                   	header_background_activate($($IMAGESEQUENCE),$($OPTIONS01));
					//header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
					//header_background_activate($($EVENTEQUENCE),$($OPTIONS01));
					/* -------------------------------------------------------- */
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
					SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
					REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
					REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
					/* -------------------------------------------------------- */
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
					//SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
					REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
					REGISTER_VIEW($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                }
            }
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_like_active')){
					    $button_obj.removeClass('photobucket_action_like_active');
                       	$button_obj.addClass('photobucket_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_action_like_disabled')){
						$button_obj.removeClass('photobucket_action_like_disabled');
                       	$button_obj.addClass('photobucket_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_action_dislike_active');
							$OPTIONS11.addClass('photobucket_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter3').val($OPTIONS11.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_action_dislike_active')){
					    $button_obj.removeClass('photobucket_action_dislike_active');
                       	$button_obj.addClass('photobucket_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_action_like_active')){
							$OPTIONS10.removeClass('photobucket_action_like_active');
							$OPTIONS10.addClass('photobucket_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_control').val($button_obj.attr('value'));
					$('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
				//PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				PHOTOBUCKET_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('EVENT_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
												var $length = parseInt($('#population_index_count').val())+1;
												var $code = $("#population_index").val();
												for(var $i=0; $i< $length; $i++){
													var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
													//var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
													
													var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
													var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
													var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
													var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
													var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
													
													header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
													
												}
											//}
											/* -------------------------------------------------------- */
											
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqEventId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('EVENT_Media','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('EVENT_Media',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('EVENT_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('EVENT_Media',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('EVENT_Media',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    CONSOLE_MANAGER('EVENT_Media','---------------------- visible');
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    CONSOLE_MANAGER('EVENT_Media','----------------------not visible');
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_Media');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_Upload_Media                    ########################## */
export function EVENT_Upload_Media(){
		$(document).ready(function() {
            CONSOLE_MANAGER('EVENT_Upload_Media','ACTIVATED');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
			//SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			//SET_BUTTON_CLICK_ACTION("settings_event_view_select_options1");
			SET_BUTTON_CLICK_ACTION("filter_form_options1");
            $('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
            if($('#total_objects_count').val()>0){
                var $length = parseInt($('#total_objects_count').val())+1;
                var $code = $("#index_object").val();
                for(var $i=0; $i< $length; $i++){
                    var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                    var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
					var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
					var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
					
                    var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
                    var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
					var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                    var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
					var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
					var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
					var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
					
                    var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                    var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
					var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
					var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
					var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
					var $IMAGESEQUENCE = '#form_'+$code+'_'+$i+'_file_path1';
					var $EVENTEQUENCE = '#form_'+$code+'_'+$i+'_file_path2';
                    if($($IMAGESEQUENCE).val()){
                        header_background_activate($($IMAGESEQUENCE),$($OPTIONS01));
                        //header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                        //header_background_activate($($EVENTEQUENCE),$($OPTIONS01));
                        /* -------------------------------------------------------- */
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
                        REGISTER_CLICK_LIKE($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS11),$($OPTIONS13),$($OPTIONS14));
                        REGISTER_CLICK_DISLIKE($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        
                        REGISTER_CLICK_PRIVATE($($OPTIONS08),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        REGISTER_CLICK_SENSITIVITY($($OPTIONS09),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        REGISTER_CLICK_DELETE($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($OPTIONS10),$($OPTIONS13),$($OPTIONS14));
                        /* -------------------------------------------------------- */
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
                        //SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
                        REGISTER_VIEW($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        //REGISTER_VIEW($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        //REGISTER_VIEW($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                        //REGISTER_VIEW($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                    }else{
                        REGISTER_UPLOAD($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04));
                    }
                }
            }
            /*
                image_viewer_menu_activity_frame    image_viewer_activity_frame
                image_viewer_menu_count_frame
                
                photobucket_contribution_image_viewer_menu_count
                photobucket_contribution_image_viewer_menu_activity
                
                
            
            */
            /* --------------------------------------------------------  */
			function REGISTER_CLICK_LIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS11,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_contribution_action_like_active')){
					    $button_obj.removeClass('photobucket_contribution_action_like_active');
                       	$button_obj.addClass('photobucket_contribution_action_like_disabled');
					}else if($button_obj.hasClass('photobucket_contribution_action_like_disabled')){
						$button_obj.removeClass('photobucket_contribution_action_like_disabled');
                       	$button_obj.addClass('photobucket_contribution_action_like_active'); 
						if($OPTIONS11.hasClass('photobucket_contribution_action_dislike_active')){
							$OPTIONS11.removeClass('photobucket_contribution_action_dislike_active');
							$OPTIONS11.addClass('photobucket_contribution_action_dislike_disabled');
						}
					}
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    $('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/contribution/interaction';
                    /* ------------------------------------------------- viewer_dislike_frame*/	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
            /* --------------------------------------------------------  */
			function REGISTER_CLICK_DISLIKE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_contribution_action_dislike_active')){
					    $button_obj.removeClass('photobucket_contribution_action_dislike_active');
                       	$button_obj.addClass('photobucket_contribution_action_dislike_disabled');
					}else if($button_obj.hasClass('photobucket_contribution_action_dislike_disabled')){
						$button_obj.removeClass('photobucket_contribution_action_dislike_disabled');
                       	$button_obj.addClass('photobucket_contribution_action_dislike_active');
						if($OPTIONS10.hasClass('photobucket_contribution_action_like_active')){
							$OPTIONS10.removeClass('photobucket_contribution_action_like_active');
							$OPTIONS10.addClass('photobucket_contribution_action_like_disabled');
						}
					}
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    $('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/contribution/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
            /* --------------------------------------------------------  */
            function REGISTER_CLICK_PRIVATE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_contribution_menu_privacy_active')){
                        $button_obj.removeClass('photobucket_contribution_menu_privacy_active');
                        $button_obj.addClass('photobucket_contribution_menu_privacy_default');
                    }else if($button_obj.hasClass('photobucket_contribution_menu_privacy_default')){
                        $button_obj.removeClass('photobucket_contribution_menu_privacy_default');
                        $button_obj.addClass('photobucket_contribution_menu_privacy_active');
                    }
					/* --------------------------------------------------------  */
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    $('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/contribution/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
            /* --------------------------------------------------------  */
            function REGISTER_CLICK_SENSITIVITY($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
					/* --------------------------------------------------------  */
					if($button_obj.hasClass('photobucket_contribution_menu_sensitivity_active')){
                        $button_obj.removeClass('photobucket_contribution_menu_sensitivity_active');
                        $button_obj.addClass('photobucket_contribution_menu_sensitivity_default');
                    }else if($button_obj.hasClass('photobucket_contribution_menu_sensitivity_default')){
                        $button_obj.removeClass('photobucket_contribution_menu_sensitivity_default');
                        $button_obj.addClass('photobucket_contribution_menu_sensitivity_active');
                    }
					/* --------------------------------------------------------  */
                   $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    $('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    /* --------------------------------------------------------  */
                    var $DATA = $('.Interaction_Activity_UI_class');
                    var $URL = '/entity/image/contribution/interaction';
                    /* ------------------------------------------------- */	
                    var $DATA_RETURN = control_update_noview_ajax(
                        $DATA,
                        $URL,
                        $EVENT
                    );
                    if($DATA_RETURN){

                    }/* END DATA RETURN */
                });   
            }
            /* --------------------------------------------------------  */
            function REGISTER_CLICK_DELETE($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$OPTIONS10,$OPTIONS13,$OPTIONS14,){
				$button_obj.unbind().click(function($EVENT) {
                    event.stopPropagation;
                    $('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($INDEX04.val());
                    $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                    $('#standard_entity_modify_control').val($button_obj.attr('id'));
                    $('#standard_entity_modify_formFilter1').val($OPTIONS14.attr('id'));
					$('#standard_entity_modify_formFilter2').val($OPTIONS10.attr('id'));
					$('#standard_entity_modify_formFilter3').val($button_obj.attr('id'));
					$('#standard_entity_modify_formFilter8').val($OPTIONS13.attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
            
             });   
            }
            /* --------------------------------------------------------  */
			function REGISTER_VIEW($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
                CONSOLE_MANAGER('REGISTER_VIEW','CLICK');
				//PHOTOSYSTEM_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				//PHOTOBUCKET_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
                PHOTOBUCKET_CONTRIBUTION_LOAD_MGMT($button_obj,$INDEX01,$INDEX02,$INDEX03,$INDEX04);
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
            /* --------------------------------------------------------  */
            function REGISTER_UPLOAD($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04){
                $button_obj.unbind().click(function(event) {
                    $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label').attr('id'));
					$('#Event_File_Upload_Label1').unbind().click();
					$('#entity_file_upload_options_index1').val($INDEX01.val());
					$('#entity_file_upload_options_index4').val($INDEX04.val());
					ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
				});
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('EVENT_Upload_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
												var $length = parseInt($('#population_index_count').val())+1;
												var $code = $("#population_index").val();
												for(var $i=0; $i< $length; $i++){
													var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
													//var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
													
													var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
													var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
													var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
													var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
													var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
													
													header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
													
												}
											//}
											/* -------------------------------------------------------- */
											
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
                                        //element.CONTRIBUTION_LIKE_CLASS
										//element.CONTRIBUTION_DISLIKE_CLASS
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										///if($('#image_viewer_menu_activity_frame').is(':visible')){
										//	var temp_class_like = $('#image_viewer_activity_frame').attr('class');
										//	$('#image_viewer_activity_frame').removeClass(temp_class_like);
										//	$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										//}
										//if($('#image_viewer_menu_count_frame').is(':visible')){
										//	$('#image_viewer_count_frame').val($count);
										//	$('#image_viewer_count_frame').html($count);
										//}
                                        /* -------------------------------------------------------- */
                                        if($('#image_viewer_menu_private_frame').is(':visible')){
                                            var temp_class_like = $('#image_viewer_menu_private').attr('class');
                                            $('#image_viewer_menu_private').removeClass(temp_class_like);
                                            $('#image_viewer_menu_private').addClass(element.VIEW_CONTRIBUTION_PRIVACY_STATUS);
                                        }
                                        /* -------------------------------------------------------- */
                                        if($('#image_viewer_menu_sensitivity_frame').is(':visible')){
                                            var temp_class_like = $('#image_viewer_menu_sensitivity').attr('class');
                                            $('#image_viewer_menu_sensitivity').removeClass(temp_class_like);
                                            $('#image_viewer_menu_sensitivity').addClass(element.VIEW_CONTRIBUTION_SENSITIVITY_STATUS);
                                        }
                                        /* -------------------------------------------------------- */
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqEventId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('EVENT_Upload_Media','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('EVENT_Upload_Media',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('EVENT_Upload_Media','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('EVENT_Upload_Media',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('EVENT_Upload_Media',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    CONSOLE_MANAGER('EVENT_Upload_Media','---------------------- visible');
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
                                    CONSOLE_MANAGER('EVENT_Upload_Media','----------------------not visible');
									$RESULTS_CNTRL =1;
									PHOTOBUCKET_CONTRIBUTION_UPDATE();
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_Upload_Media');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	form_event_maincurrence_MGMT                ########################## */
export function form_event_maincurrence_MGMT(){
		$(document).ready(function() {
            CONSOLE_MANAGER('form_event_maincurrence_MGMT','ACTIVATED');
            /* --------------------------------------------------------  */
            event_header_background_activate(null);
			//event_text_reader_activate(null);
			/* --------------------------------------------------------  */
			if($('#image_editor_main_image_var_load').length){
				EVENT_PHOTO_basics();
			}
			/* --------------------------------------------------------  */
			if($('#map').is(':visible')) {
				micro_map_activate();
			}
			/* -------------------------------------------------------- */
			SET_BUTTON_CLICK_ACTION("settings_event_general_options6");
			/* -------------------------------------------------------- */
			if($('#settings_event_general_options11').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("settings_event_general_options11");
            }
			/* --------------------------------------------------------  */
            SET_BUTTON_CLICK_ACTION("settings_event_activity_options1");
            if($('#settings_event_activity_options1').is(':visible')) {
                var $SCORE = parseInt($('#settings_event_activity_formValue').val());
                EVENT_METRICS_CONTROL_basics($('#settings_event_activity_options1'),$SCORE);
            }
            SET_BUTTON_CLICK_ACTION("settings_event_swipe_activity_options1");
            if($('#settings_event_swipe_activity_options1').is(':visible')) {
                var $SCORE = parseInt($('#settings_event_swipe_activity_formValue').val());
                EVENT_METRICS_CONTROL_basics($('#settings_event_swipe_activity_options1'),$SCORE);
            }
            /* -------------------------------------------------------- */
            if($('#event_activity_action_options1').is(':visible')) {
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_activity_action_options1'),null,null,null,null);
			}
            /* -------------------------------------------------------- */
            if($('#event_activity_action_options2').is(':visible')) {
				NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_activity_action_options2'),null,null,null,null);
			}
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION('event_member_follow_action_options1');
			SET_BUTTON_CLICK_ACTION('event_member_follow_action_options2');
			SET_BUTTON_CLICK_ACTION('event_member_follow_action_options3');
			SET_BUTTON_CLICK_ACTION('event_member_follow_action_options4');
			SET_BUTTON_CLICK_ACTION('event_member_follow_header_action_options1');
			SET_BUTTON_CLICK_ACTION('event_member_follow_header_action_options2');
			SET_BUTTON_CLICK_ACTION('event_member_follow_header_action_options3');
			SET_BUTTON_CLICK_ACTION('event_member_follow_header_action_options4');
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            //item_form_map_option
            //description_option
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#map'),'form_event_maincurrence_MGMT',null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_event_description_formCurrentIndex'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_event_description_options3'),null,null,null,null);
            NOTIFICATIONSYSTEM_LOAD_MGMT($('#settings_event_general_options5'),null,null,null,null);
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            if($('#event_checkin_options3').is(':visible')) {
                var $ST_DATE = $('#event_checkin_options3').html();
                if($ST_DATE.length && $ST_DATE!='FALSE'){
                    $('#event_checkin_options3').val(moment($ST_DATE).format('dddd, MMM Do @ LT'));//
                    $('#event_checkin_options3').html(moment($ST_DATE).format('dddd, MMM Do @ LT'));//
                }else{
                    $('#event_checkin_options3').val(null);
                    $('#event_checkin_options3').html(' ');
                }
            }
            if($('#event_checkin_options4').is(':visible')) {
                var $ST_DATE = $('#event_checkin_options4').html();
                if($ST_DATE.length && $ST_DATE!='FALSE'){
                    $('#event_checkin_options4').val(moment($ST_DATE).format('dddd, MMM Do @ LT'));//
                    $('#event_checkin_options4').html(moment($ST_DATE).format('dddd, MMM Do @ LT'));//
                }else{
                    $('#event_checkin_options4').val(null);
                    $('#event_checkin_options4').html(' ');
                }
            }
            /* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_menu01_options1");
			//SET_BUTTON_CLICK_ACTION("settings_event_general_options2");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_swipe_attendees_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_swipe_followers_options1");
			SET_BUTTON_CLICK_ACTION("settings_event_swipe_recommendations_options1");
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_attendees_formValue");
			$('#settings_event_attendees_formValue').unbind().click(function(event) {
				$('#settings_event_attendees_options1').unbind().click();
			});
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_followers_formValue");
			$('#settings_event_followers_formValue').unbind().click(function(event) {
				$('#settings_event_followers_options1').unbind().click();
			});
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("settings_event_recommendations_formValue");
			$('#settings_event_recommendations_formValue').unbind().click(function(event) {
				$('#settings_event_recommendations_options1').unbind().click();
			});
            /* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
            $('.Register_Activity_UI_class').attr("id", 'Register_Activity_UI_control');
			if($("#event_register_index3").val()=='PARENT'){
                CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED');
                /*
                    IF PARENT REGISTERED STATUS IS 0 (FALSE) - ALLOW FOR REGISTERING ON THE PARENT.
                    IF PARENT REGISTERED STATUS IS 1 (TRUE) - ALLOW TO DE-REGISTER ON THE PARENT.
                
                */
				if(parseInt($("#event_register_formFilter7").val())==parseInt(1)){
					if($('#event_register_options1').is(':visible')){//EVENT_INVITATIONS_SELECT_basics
                        CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED OPTIONS 1 VISIBLE');
						SET_BUTTON_CLICK_ACTION('event_register_options1');
                        //SET_BUTTON_CLICK_ACTION('event_register_options2');
                        SET_BUTTON_CLICK_ACTION('event_register_options3');
						//e.preventDefault();
						if($("#event_register_index7").val()){
                            CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED OPTION 1 INDEX 7 VALUE');
							window_complete_script('form_event_maincurrence_MGMT');
						}else{
							NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_register_options1'),null,null,null,null);
						}
                       $('#event_register_options2').prop("disabled",true); 
					}
					if($('#event_register_options2').is(':visible')){//EVENT_INVITATIONS_SELECT_basics
                        CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED OPTIONS 2 VISIBLE');
						//SET_BUTTON_CLICK_ACTION('event_register_options1');
                        SET_BUTTON_CLICK_ACTION('event_register_options2');
                        SET_BUTTON_CLICK_ACTION('event_register_options3');
						//e.preventDefault();
						if($("#event_register_index7").val()){
                            CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED OPTION 2 INDEX 7 VALUE');
							window_complete_script('form_event_maincurrence_MGMT');
						}else{
							NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_register_options2'),null,null,null,null);
						}
                       $('#event_register_options1').prop("disabled",true); 
					}
				}else{	
                    CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ PARENT ACTIVATED OPTIONS DISABLED');
					$('#event_register_options1').prop("disabled",true);
					$('#event_register_options2').prop("disabled",true);
				}
			}else{
                CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED');
                /*
                    IF CHECKIN FINILIZED STATUS IS 0 (FALSE) - ALLOW FOR CHECKINS.
                    IF CHECKIN FINALIZED STATUS IS 1 (TRUE) - CHECKINS ARE DISABLED.
                
                */
				if(parseInt($("#event_register_formFilter6").val())<=parseInt(0)){
					//if($("#event_register_index7").val()==null){
					//	SET_BUTTON_CLICK_ACTION('event_register_options1');//EVENT_INVITATIONS_SELECT_basics
                     //   SET_BUTTON_CLICK_ACTION('event_register_options3');
						//if($("#event_register_index1").val()){
					//		NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_register_options1'),null,null,null,null);
						//}
					//}
                    if($('#event_register_options1').is(':visible')){//EVENT_INVITATIONS_SELECT_basics
                        CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED OPTIONS 1 VISIBLE');
						SET_BUTTON_CLICK_ACTION('event_register_options1');
                        //SET_BUTTON_CLICK_ACTION('event_register_options2');
                        SET_BUTTON_CLICK_ACTION('event_register_options3');
						//e.preventDefault();
						if($("#event_register_index7").val()){
                            CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED OPTION 1 INDEX 7 VALUE');
							window_complete_script('form_event_maincurrence_MGMT');
						}else{
							NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_register_options1'),null,null,null,null);
						}
                       $('#event_register_options2').prop("disabled",true); 
					}
					if($('#event_register_options2').is(':visible')){//EVENT_INVITATIONS_SELECT_basics
                        CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED OPTIONS 1 VISIBLE');
						//SET_BUTTON_CLICK_ACTION('event_register_options1');
                        SET_BUTTON_CLICK_ACTION('event_register_options2');
                        SET_BUTTON_CLICK_ACTION('event_register_options3');
						//e.preventDefault();
						if($("#event_register_index7").val()){
                            CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED OPTION 2 INDEX 7 VALUE');
							window_complete_script('form_event_maincurrence_MGMT');
						}else{
							NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_register_options2'),null,null,null,null);
						}
                       $('#event_register_options1').prop("disabled",true); 
					}
				}else{
                    CONSOLE_MANAGER('form_event_maincurrence_MGMT','------ CHILD ACTIVATED OPTIONS DISABLED');
					$('#event_register_options1').prop("disabled",true);
					$('#event_register_options2').prop("disabled",true);
				}
			}
			if(parseInt($("#event_checkin_formFilter6").val())==parseInt(1)){
				$('#event_checkin_options1').prop("disabled",true);
				$('#event_checkin_options2').prop("disabled",true);
			}else if((parseInt($("#event_checkin_formFilter6").val())==parseInt(1))&&(($("#event_checkin_formFilter6").val()=='ACTIVE'))){
				if($('#event_checkin_options1').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_checkin_options1');
				}
				if($('#event_checkin_options1active').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_checkin_options1active');
				}
				$('#event_checkin_options2').prop("disabled",true);
			}else{
				if($('#event_checkin_options1').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_checkin_options1');
				}
				if($('#event_checkin_options1active').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_checkin_options1active');
				}
			}
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if($('#social_media_options1').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options1");
                $('#social_media_options1').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options1').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options1'),null,null,null,null);
            }
            if($('#social_media_options2').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options2");
                $('#social_media_options2').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options2').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options2'),null,null,null,null);
            }
            if($('#social_media_options3').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options3");
                $('#social_media_options3').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options3').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options3'),null,null,null,null);
            }
            if($('#social_media_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options4");
                $('#social_media_options4').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options4').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options4'),null,null,null,null);
            }
            if($('#social_media_options5').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("social_media_options5");
                $('#social_media_options5').unbind().click(function(event) {
                    $('#social_media_control').val($('#social_media_options5').attr('id')); 
                });
                //NOTIFICATIONSYSTEM_LOAD_MGMT($('#social_media_options5'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
			for(var $i=1;$i<parseInt(18);$i++ ){
				if($('#event_recommendation_options'+$i+'active').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_recommendation_options'+$i+'active');
				}
				if($('#event_recommendation_index'+$i).val()){
					SET_BUTTON_CLICK_ACTION('event_recommendation_options'+$i);
					header_background_activate($('#event_recommendation_formFilter'+$i), $('#event_recommendation_options'+$i))
					//REGISTER_CLICK($('#event_recommendation_options'+$i),$i);
				}else{
					 $('#event_recommendation_options'+$i).prop('disabled', true);
				}
			}
			/* --------------------------------------------------------  */
			for(var $i=1;$i<parseInt(18);$i++ ){
				if($('#event_item_recommendation_options'+$i+'active').is(':visible')){
					SET_BUTTON_CLICK_ACTION('event_item_recommendation_options'+$i+'active');
				}
				if($('#event_item_recommendation_index'+$i).val()){
					SET_BUTTON_CLICK_ACTION('event_item_recommendation_options'+$i);
					header_background_activate($('#event_item_recommendation_formFilter'+$i), $('#event_item_recommendation_options'+$i))
					//REGISTER_CLICK($('#event_item_recommendation_options'+$i),$i);
				}else{
					 $('#event_item_recommendation_options'+$i).prop('disabled', true);
				}
			}
			/* -------------------------------------------------------- */
			if($('#event_media_view_options2').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("event_media_view_options2");
            }
			if($('#event_media_view_index5').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("event_media_view_index5");
            }
			/* -------------------------------------------------------- */
			if($('#event_media_view_options1').is(':visible')) {
				//PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#event_media_view_options1'),null,null,null,null);
				if( $('#image_object_photobucket').val()!='[]'){
					PHOTOBUCKET_LOAD_MGMT($('#event_media_view_options1'),null,null,null,null);
				}
            }
			if($('#event_media_view_index3').is(':visible')) {
				//PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#event_media_view_index3'),null,null,null,null);
				if( $('#image_object_photobucket').val()!='[]'){
					PHOTOBUCKET_LOAD_MGMT($('#event_media_view_index3'),null,null,null,null);
				}
            }
			/* --------------------------------------------------------settings_event_followers_formValue */
			/* -------------------------------------------------------- */
			if($('#media_menu_options1').is(':visible')) {
				//PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#media_menu_options1'),null,null,null,null);
				if( $('#image_object').val()!='[]'){
					PHOTOSYSTEM_LOAD_MGMT($('#media_menu_options1'),null,null,null,null);
				}
            }
			if($('#media_menu_index3').is(':visible')) {
				//PHOTOSYSTEM_PROFILE_LOAD_MGMT($('#media_menu_index3'),null,null,null,null);
				if( $('#image_object').val()!='[]'){
					PHOTOSYSTEM_LOAD_MGMT($('#media_menu_index3'),null,null,null,null);
				}
            }
			SET_BUTTON_CLICK_ACTION("media_menu_options2");
			if($('#media_menu_index5').is(':visible')) {
				SET_BUTTON_CLICK_ACTION("media_menu_index5");
                $('#media_menu_index5').unbind().click(function(event) {
					$('#media_menu_options2').unbind().click();
                });
            }
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('standard_results_ajax_control');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($("#standard_results_ajax_control").val()){
                                CONSOLE_MANAGER('form_event_maincurrence_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
								if(mutation.type=='attributes'){
									if($RESULTS_CNTRL=='POPULATION'){
										/* -------------------------------------------------------- */
										if(($('#standard_results_ajax_formFilter1').val()==$('#standard_entity_modify_index2').val())&&($('#standard_results_ajax_formFilter2').val()==$('#standard_entity_modify_index3').val())){
											var $TEMP_HTML = $('#standard_results_ajax_responseData').val();
											$('#image_population').html($TEMP_HTML);
											header_background_activate($('#image_population_view_file_path'),$('#image_population_view_options1'));
											//$('#standard_results_ajax_responseData').val(' ');
											
											/* -------------------------------------------------------- */
											//if($('#population_index_count').val()>0){
												var $length = parseInt($('#population_index_count').val())+1;
												var $code = $("#population_index").val();
												for(var $i=0; $i< $length; $i++){
													var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
													//var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
													
													var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
													var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
													var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
													var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
													var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
													
													header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
													
												}
											//}
											/* -------------------------------------------------------- */
											
										}
									}else{
										/* -------------------------------------------------------- */
										var $temp_count_obj = $('#standard_entity_modify_formFilter1').val();
										var $temp_OBJ_LIKE = $('#standard_entity_modify_formFilter2').val();
										var $temp_OBJ_DISLIKE = $('#standard_entity_modify_formFilter3').val();
										var temp_setclass_VIEW_LIKE = $('#standard_results_ajax_formFilter4').val();
										var temp_setclass_VIEW_DISLIKE = $('#standard_results_ajax_formFilter5').val();
										var temp_setclass_SCROLL_LIKE = $('#standard_results_ajax_formFilter6').val();
										var temp_setclass_SCROLL_DISLIKE = $('#standard_results_ajax_formFilter7').val();
										var temp_setclass_VIEW_ACTIVITY = $('#standard_results_ajax_formFilter9').val();
										var temp_setclass_SCROLL_ACTIVITY = $('#standard_results_ajax_formFilter10').val();
										var $temp_stat_obj = $('#standard_entity_modify_formFilter8').val();
										var $count = $('#standard_results_ajax_formFilter1').val();
										/* -------------------------------------------------------- */
										$('#'+$temp_count_obj).html($count);
										$('#'+$temp_count_obj).val($count);
										/* -------------------------------------------------------- */
										var temp_classOBL = $('#'+$temp_OBJ_LIKE).attr('class');
										$('#'+$temp_OBJ_LIKE).removeClass(temp_classOBL);
										var temp_setclass_LIST_LIKE = $('#standard_results_ajax_formFilter2').val();
										$('#'+$temp_OBJ_LIKE).addClass(temp_setclass_LIST_LIKE);
										/* -------------------------------------------------------- */
										var temp_classOBDL = $('#'+$temp_OBJ_DISLIKE).attr('class');
										$('#'+$temp_OBJ_DISLIKE).removeClass(temp_classOBDL);
										var temp_setclass_LIST_DISLIKE = $('#standard_results_ajax_formFilter3').val();
										$('#'+$temp_OBJ_DISLIKE).addClass(temp_setclass_LIST_DISLIKE);
										/* -------------------------------------------------------- */
										var temp_class = $('#'+$temp_stat_obj).attr('class');
										$('#'+$temp_stat_obj).removeClass(temp_class);
										var temp_setclass_LIST_ACTIVITY = $('#standard_results_ajax_formFilter8').val();
										$('#'+$temp_stat_obj).addClass(temp_setclass_LIST_ACTIVITY);
										/* -------------------------------------------------------- */
										if($('#image_viewer_menu_activity_frame').is(':visible')){
											var temp_class_like = $('#image_viewer_activity_frame').attr('class');
											$('#image_viewer_activity_frame').removeClass(temp_class_like);
											$('#image_viewer_activity_frame').addClass(temp_setclass_VIEW_ACTIVITY);
										}
										if($('#image_viewer_menu_count_frame').is(':visible')){
											$('#image_viewer_count_frame').val($count);
											$('#image_viewer_count_frame').html($count);
										}
										if($('#image_viewer_like_frame').is(':visible')){
											var temp_class_like = $('#viewer_like_frame').attr('class');
											$('#viewer_like_frame').removeClass(temp_class_like);
											$('#viewer_like_frame').addClass(temp_setclass_VIEW_LIKE);
										}
										 if($('#image_viewer_dislike_frame').is(':visible')){
											var temp_class_disslike = $('#viewer_dislike_frame').attr('class');
											$('#viewer_dislike_frame').removeClass(temp_class_disslike);
											$('#viewer_dislike_frame').addClass(temp_setclass_VIEW_DISLIKE);
										}
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var $DATES_obj_array=[];
										var jsonValidate  = isJSON($('#image_object').val());
										if(jsonValidate==true){
											$DATES_obj_array = JSON.parse($('#image_object').val());
											if($DATES_obj_array != null){
												/* -------------------------------------------------------- */
												if($DATES_obj_array.length > 0){
													$DATES_obj_array.forEach((element, index, array) => {
														if(element){
															if(typeof element.getUniqEventId != "undefined"){
																if(element.getName == $('#standard_entity_modify_index4').val()){
																	/* -------------------------------------------------------- */
																	element.ACTIVITY_COUNT = $count;
																	element.LIST_ACTIVITY_STATUS = temp_setclass_LIST_ACTIVITY;
																	element.VIEW_ACTIVITY_STATUS = temp_setclass_VIEW_ACTIVITY;
																	element.SCROLL_ACTIVITY_STATUS = temp_setclass_SCROLL_ACTIVITY;
																	element.LIST_LIKE_CLASS = temp_setclass_LIST_LIKE;
																	element.LIST_DISLIKE_CLASS = temp_setclass_LIST_DISLIKE;
																	element.VIEW_LIKE_CLASS = temp_setclass_VIEW_LIKE;
																	element.VIEW_DISLIKE_CLASS = temp_setclass_VIEW_DISLIKE;
																	element.SCROLL_LIKE_CLASS = temp_setclass_SCROLL_LIKE;
																	element.SCROLL_DISLIKE_CLASS = temp_setclass_SCROLL_DISLIKE;
																	/* -------------------------------------------------------- */
                                                                    CONSOLE_MANAGER('form_event_maincurrence_MGMT','DATA FOR FILE: '+element.getName+' -|COUNT|- '+element.ACTIVITY_COUNT+' -|STATUS|- '+element.LIST_ACTIVITY_STATUS+' -- '+element.VIEW_ACTIVITY_STATUS+' -- '+element.SCROLL_ACTIVITY_STATUS+' -|LIST|- '+element.LIST_LIKE_CLASS+' -- '+element.LIST_DISLIKE_CLASS+' -|VIEW|- '+element.VIEW_LIKE_CLASS+' -- '+element.VIEW_DISLIKE_CLASS+' -|SCROLL|- '+element.SCROLL_LIKE_CLASS+' -- '+element.SCROLL_DISLIKE_CLASS);
																}
															}
														}
													});
												}/** END IF **/
											}/** END IF **/
											$('#image_object').val(JSON.stringify($DATES_obj_array))
										}/** END IF **/
									}
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									//translate_ajax_clear(true);
									$('#standard_entity_modify_control').val(' ');
								}else if(mutation.type=='childList'){
									CONSOLE_MANAGER('form_event_maincurrence_MGMT',mutation.type+': childList');
									/* -------------------------------------------------------- */
								}
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  
			var Social_HTML = ' ';
			if(document.getElementById('parent_event_social_div')){
				Social_HTML = $('#parent_event_social_div').html();
				$('#parent_event_social_div').html(' ');
			}
			$('#set_event_social_div').html(Social_HTML);*/
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $EVENT_VAL = $('#event_media_view_control').val();
			/* --------------------------------------------------------  *//* --------------------------------------------------------  */
			/* --------------------------------------------------------  *//* --------------------------------------------------------  */
			if(document.getElementById('event_media_view_control')){
				if($EVENT_VAL.indexOf("STREAMING_STATUS") >= 0){
					SET_BUTTON_CLICK_ACTION("event_media_view_options1");
					$('#event_media_view_options1').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
					/* --------------------------------------------------------  */
					SET_BUTTON_CLICK_ACTION("event_media_view_options2");
					$('#event_media_view_options2').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
						if($('#event_media_view_options3').is(':visible')) {
							//SET_BUTTON_CLICK_ACTION("event_media_view_options3");
							PHOTOSYSTEM_LOAD_MGMT($('#event_media_view_options3'),null,null,null,null);
						}
						/* --------------------------------------------------------  */
						if($('#event_media_view_options4').is(':visible')) {
							SET_BUTTON_CLICK_ACTION("event_media_view_options4");
							$('#event_media_view_options4').unbind().click(function(event) {
								$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_title').val(null);
								$('#standard_entity_modify_index1').val(null);
								$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_index4').val(null);
								$('#standard_entity_modify_index5').val(null);
								$('#standard_entity_modify_index6').val(null);
								$('#standard_entity_modify_index7').val($(this).attr('id'));
								$('#standard_entity_modify_control').val($EVENT_VAL);
								standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
							});
						}
				}
				/* --------------------------------------------------------  *//* --------------------------------------------------------  */
				/* --------------------------------------------------------  *//* --------------------------------------------------------  */
				if($EVENT_VAL.indexOf("CATALOG_STATUS") >= 0){
					SET_BUTTON_CLICK_ACTION("event_media_view_options1");
					$('#event_media_view_options1').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						//standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
						PHOTOSYSTEM_LOAD_MGMT($('#event_media_view_options1'),null,null,null,null);
					});
					/* --------------------------------------------------------  */
					SET_BUTTON_CLICK_ACTION("event_media_view_options2");
					$('#event_media_view_options2').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						//standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
						PHOTOSYSTEM_LOAD_MGMT($('#event_media_view_options2'),null,null,null,null);
					});
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
						if($('#event_media_view_options3').is(':visible')) {
							SET_BUTTON_CLICK_ACTION("event_media_view_options3");
							$('#event_media_view_options3').unbind().click(function(event) {
								$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_title').val(null);
								$('#standard_entity_modify_index1').val(null);
								$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_index4').val(null);
								$('#standard_entity_modify_index5').val(null);
								$('#standard_entity_modify_index6').val(null);
								$('#standard_entity_modify_index7').val($(this).attr('id'));
								$('#standard_entity_modify_control').val($EVENT_VAL);
								standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
							});
						}
						/* --------------------------------------------------------  */
						if($('#event_media_view_options4').is(':visible')) {
							SET_BUTTON_CLICK_ACTION("event_media_view_options4");
							$('#event_media_view_options4').unbind().click(function(event) {
								$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_title').val(null);
								$('#standard_entity_modify_index1').val(null);
								$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_index4').val(null);
								$('#standard_entity_modify_index5').val(null);
								$('#standard_entity_modify_index6').val(null);
								$('#standard_entity_modify_index7').val($(this).attr('id'));
								$('#standard_entity_modify_control').val($EVENT_VAL);
								standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
							});
						}
				}
				/* --------------------------------------------------------  *//* --------------------------------------------------------  */
				/* --------------------------------------------------------  *//* --------------------------------------------------------  */
				if($EVENT_VAL.indexOf("MEDIA_STATUS") >= 0){
					SET_BUTTON_CLICK_ACTION("event_media_view_options1");
					$('#event_media_view_options1').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
					/* --------------------------------------------------------  */
					SET_BUTTON_CLICK_ACTION("event_media_view_options2");
					$('#event_media_view_options2').unbind().click(function(event) {
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_title').val(null);
						$('#standard_entity_modify_index1').val(null);
						$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
						$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
						$('#standard_entity_modify_index4').val(null);
						$('#standard_entity_modify_index5').val(null);
						$('#standard_entity_modify_index6').val(null);
						$('#standard_entity_modify_index7').val($(this).attr('id'));
						$('#standard_entity_modify_control').val($EVENT_VAL);
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
						if($('#event_media_view_options3').is(':visible')) {
							SET_BUTTON_CLICK_ACTION("event_media_view_options3");
							$('#event_media_view_options3').unbind().click(function(event) {
								$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_title').val(null);
								$('#standard_entity_modify_index1').val(null);
								$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_index4').val(null);
								$('#standard_entity_modify_index5').val(null);
								$('#standard_entity_modify_index6').val(null);
								$('#standard_entity_modify_index7').val($(this).attr('id'));
								$('#standard_entity_modify_control').val($EVENT_VAL);
								//standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
								PHOTOSYSTEM_LOAD_MGMT($('#event_media_view_options3'),null,null,null,null);
							});
						}
						/* --------------------------------------------------------  */
						if($('#event_media_view_options4').is(':visible')) {
							SET_BUTTON_CLICK_ACTION("event_media_view_options4");
							$('#event_media_view_options4').unbind().click(function(event) {
								$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_registerCode').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_title').val(null);
								$('#standard_entity_modify_index1').val(null);
								$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
								$('#standard_entity_modify_index3').val($('#settings_event_general_registerCode').val());
								$('#standard_entity_modify_index4').val(null);
								$('#standard_entity_modify_index5').val(null);
								$('#standard_entity_modify_index6').val(null);
								$('#standard_entity_modify_index7').val($(this).attr('id'));
								$('#standard_entity_modify_control').val($EVENT_VAL);
								//standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
							});
						}
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
			$('.Event_Media_Activity_UI_class').attr("id", 'Event_upload_Media_upload_Activity_UI_control');
            if(document.getElementById('Event_upload_Media_upload_Activity_UI_control')){
				/* ------------------------------------------------- */	
                if($('#Event_Contribution_File_Upload_Label').is(':visible')) {
                    $('#Event_Contribution_File_Upload_Label').unbind().click(function(event) {
                        $('#event_media_view_control').val($('#Event_Contribution_File_Upload_Label').attr('id'));
                        CONSOLE_MANAGER('Event_Media_Activity_UI_class','ACTIVATED');
                        ACTIVATE_UPLOAD_FORM_INIT($('#Event_upload_Media_upload_Activity_UI_control'));
                    });
                }
            }
            /* --------------------------------------------------------  */
			$('.Event_Media_Streaming_Activity_UI_class').attr("id", 'Event_upload_Media_Streaming_upload_Activity_UI_control');
            if(document.getElementById('Event_upload_Media_Streaming_upload_Activity_UI_control')){
				/* ------------------------------------------------- */	
                if($('#Event_Contribution_File_Upload_Label').is(':visible')) {
                    $('#Event_Contribution_File_Upload_Label').unbind().click(function(event) {
                        $('#event_media_view_control').val($('#Event_Contribution_File_Upload_Label').attr('id'));
                        CONSOLE_MANAGER('Event_Media_Streaming_Activity_UI_class','ACTIVATED');
                        ACTIVATE_UPLOAD_FORM_INIT($('#Event_upload_Media_Streaming_upload_Activity_UI_control'));
                    });
                }
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('form_event_maincurrence_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							var $MODIFY_CNTRL = $('#standard_entity_modify_control').val();
                            /* -------------------------------------------------------- */
                            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
                            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
                            if(!$('#standard_results_ajax_control').val()){
                                if(mutation.type=='attributes'){
                                    CONSOLE_MANAGER('form_event_maincurrence_MGMT',mutation.type+': attributes');
                                    if($('#window_view').is(':visible')){
                                        /*
                                        if($MODIFY_CNTRL=='EVENT_MAP'){
                                            event_create_pop_map_activate();
                                            $('#event_register_div_blocker').hide();
                                            $('#standard_entity_modify_control').val(null);
                                        }
                                        */
                                        if($MODIFY_CNTRL=='EVENT_MAP_GLOBAL'){
                                            event_profile_pop_manager_map_activate();
                                            //$('#event_register_div_blocker').hide();
                                            $('#standard_entity_modify_control').val(null);
                                        }

                                    }
                                    else if($('#window_view').is(':hidden')){

                                    }
                                    /* -------------------------------------------------------- */
                                }
                                /* -------------------------------------------------------- */
                                else if(mutation.type=='childList'){
                                    CONSOLE_MANAGER('form_event_maincurrence_MGMT',mutation.type+': childList');
                                    /* -------------------------------------------------------- */
                                    /* -------------------------------------------------------- */
                                    if($('#window_view').is(':visible')){
                                        /* -------------------------------------------------------- */
                                        if($MODIFY_CNTRL=='EVENT_DESCRIPTION_VIEW'){
                                            event_description_activate2();
                                            $('#standard_entity_modify_control').val(null);
                                        }
                                        //$('#event_register_div_blocker').hide();  
                                        /* -------------------------------------------------------- */
                                        if($MODIFY_CNTRL=='EVENT_DESCRIPTION_EDIT'){
                                            event_description_activate();
                                            $('#standard_entity_modify_control').val(null);
                                        }
                                    }
                                    else if($('#window_view').is(':hidden')){
                                        /* -------------------------------------------------------- */
                                        /* -------------------------------------------------------- */
                                    }
                                }
                            }
                            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
                            /* -------------------------------------------------------- *//* -------------------------------------------------------- */
							if($('#standard_results_ajax_control').val()){
								$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
								if($('#search_request_control').length){
								   $SEARCH_CNTRL = $('#search_request_control').val();
								}

								if(mutation.type=='attributes'){
									if($("#window_view").is(':visible')){
										/* --------------------------------------------------------  */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
                                        /*
                                        if($MODIFY_CNTRL=='EVENT_MAP'){
                                            event_create_pop_map_activate();
                                            $('#event_register_div_blocker').hide();
                                            $('#standard_entity_modify_control').val(null);
                                        }
                                        */
                                        if($MODIFY_CNTRL=='EVENT_MAP_GLOBAL'){
                                            event_create_pop_manager_map_activate();
                                            //$('#event_register_div_blocker').hide();
                                            $('#standard_entity_modify_control').val(null);
                                        }
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										var MODIFY_VIEW_LISTENER = (function (){
											var target = document.getElementById('basic_status');
											/* --------------------------------------------------------  */
											/* --------------------------------------------------------  */	
											var observer = new MutationObserver(function(mutations) {
												mutations.forEach(function(mutation) {
													$(document).ready(function() {
														if(mutation.type=='attributes'){
                                                            CONSOLE_MANAGER('form_event_maincurrence_MGMT',mutation.attributeName + ' attribute');
															/* --------------------------------------------------------  */
                                                            
															/* --------------------------------------------------------  */
														}else if(mutation.type=='childList'){
                                                            CONSOLE_MANAGER('form_event_maincurrence_MGMT',mutation.attributeName + ' childList');
															/* --------------------------------------------------------  */
															if($("#window_view").is(':visible')){
																if($('#search_request_control').length){
																	if($SEARCH_CNTRL.includes('Settings_Event_EditType')){
																		event_active_pagination('Settings_Event_EditType');
																	}
																}
															}
															/* --------------------------------------------------------  */
														} 
													});
												});    
											});//END new MutationObserver
											var config = { attributes: true, childList: true, characterData: true };
											observer.observe(target, config);
											//observer.disconnect();

										})();/*END FUNCTION */		
										/* -------------------------------------------------------- */
										/**********		CONFIRM_MGMT	**********/
										if($RESULTS_CNTRL.includes('Entity_upload_Activity_UI_class')){
											var $CONFIRM_CNTRL = $('#settings_sys_confirm_index1').val();
											if($CONFIRM_CNTRL.includes('options1')){
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {
													$('#'+ $CONFIRM_CNTRL).unbind().click();
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											}
											if($CONFIRM_CNTRL.includes('options2')){
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {
													$('#'+ $CONFIRM_CNTRL).unbind().click();
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											}
										}
										/* -------------------------------------------------------- */
									}
									if($( "#window_view" ).is(':hidden')){
										/* --------------------------------------------------------  */
										//if($("#event_select_menu_options2").is(':visible')){

										//}
										/* --------------------------------------------------------  */

										/* --------------------------------------------------------  */

									}

									/* --------------------------------------------------------  */

										/* --------------------------------------------------------  */
								}else if(mutation.type=='childList'){
									/* --------------------------------------------------------  */
								
									/* -------------------------------------------------------- */
									if($("#window_view").is(':visible')){
										if($('#search_request_control').length){
											if($SEARCH_CNTRL.includes('Settings_Event_EditType')){
												event_active_pagination('Settings_Event_EditType');
											}
										}
									} 
									/* --------------------------------------------------------  */
									if($("#standard_results_ajax_control").val() == 'Settings_Event_DescriptionType'){

										SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options1");
										SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options2");
										$('#settings_event_description_edit_options2').unbind().click(function() {
											$('#settings_event_description_edit_formCurrentIndex').val('');

										});

										text_editor_word_count(
											$('#settings_event_description_edit_formCurrentIndex'),
											$('#settings_event_description_edit_formRecList')
										);

										$('#settings_event_description_edit_formCurrentIndex').keyup(function(event) {
											var key = String.fromCharCode(event.which);
											//if(event.keyCode == 32){
											//	$('#settings_event_description_edit_formCurrentIndex').val($('#settings_event_description_edit_formCurrentIndex').val()+' ');
											//}
											/* --------------------------------------------------------  */
											//var codes = [9,13,32];
											var codes = [32];
											document.getElementById("settings_event_description_edit_formCurrentIndex").addEventListener('keydown', function(e) {
											if(!contains(codes, e.keyCode)) return;
												var path = e.path || e.composedPath()
												if(!path[0]) return;

												e.preventDefault();
												path[0].value += String.fromCharCode(e.keyCode);//
												var $STRING=$('#settings_event_description_edit_formCurrentIndex').val();
												$('#settings_event_description_edit_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
											})
											function contains(arr, item) {
												for(var i=0;i<arr.length;i++)
												if(arr[i]==item)return true;
												return false;
											}
											/* --------------------------------------------------------  */
											$('#settings_event_description_edit_options1').unbind().click(function(event){
											});

											if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
											   (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
											   (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
											/*
											(key == 13 /ENTER/)
											(key == 46 /DELETE/)
											(key == 8 /BACKSPACE/)
											*/
												text_editor_word_count(
													$('#settings_event_description_edit_formCurrentIndex'),
													$('#settings_event_description_edit_formRecList')
												);
											}
										});
										$('#settings_event_description_edit_options1').unbind().click(function(event) {
										});
									}
									/* --------------------------------------------------------  Settings_Event_GeneralType*/
									//if($("#search_request_search_parameters").is(':visible') && 

									if($('#search_request_control').length){
										if($SEARCH_CNTRL.includes('Settings_Event_Description_selectionType')){
											/* -------------------------------------------------------- */
											/* -------------------------------------------------------- */
											if($("#window_view").is(':visible')){
												SET_BUTTON_CLICK_ACTION('search_request_options1');
												event_data_update_function('Settings_Event_Description_selectionType',null,null,null);
												$('#search_request_options1').unbind().click(function(event) {
													event_data_update_function('Settings_Event_Description_selectionType',null,null,event);
												});
												/* -------------------------------------------------------- */
												$(".Pop_search_Activity_UI_class").submit(function(event){
													event.preventDefault();
													event_data_update_function('Settings_Event_Description_selectionType',null,null,event);
												});
											}
										}
									}
									/* --------------------------------------------------------  */
									if($("#window_view").is(':visible')){
										if($('#search_request_control').length){
											if($SEARCH_CNTRL.includes('Settings_Event_Guidelines')){
												event_active_pagination('Settings_Event_Guidelines');
											}
										}
									}
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
									//translate_ajax_clear(true);
								} 

							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(parseInt($('#settings_event_general_cohostStatus').val()) >= 1){
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                SET_BUTTON_CLICK_ACTION("event_file_segment1_upload_options1");
                SET_BUTTON_CLICK_ACTION("settings_event_general_options1");
                SET_BUTTON_CLICK_ACTION("event_file_upload_options2");
                SET_BUTTON_CLICK_ACTION("event_file_segment1_upload_options2");
                SET_BUTTON_CLICK_ACTION("event_file_segment2_upload_options2");
                SET_BUTTON_CLICK_ACTION("event_file_segment2_upload_options3");
                SET_BUTTON_CLICK_ACTION("event_file_segment3_upload_options2");
                SET_BUTTON_CLICK_ACTION("event_file_segment3_upload_options3");
                SET_BUTTON_CLICK_ACTION("event_file_segment4_upload_options2");
                SET_BUTTON_CLICK_ACTION("event_file_segment4_upload_options3");
                /* --------------------------------------------------------  */
                for(var $i=1;$i<parseInt(8);$i++ ){
                    if($('#event_recommendation_options'+$i+'active').is(':visible')){
                        SET_BUTTON_CLICK_ACTION('event_recommendation_options'+$i+'active');
                    }
                    if($('#event_recommendation_index'+$i).val()){
                        SET_BUTTON_CLICK_ACTION('event_recommendation_options'+$i);
                        header_background_activate($('#event_recommendation_formFilter'+$i), $('#event_recommendation_options'+$i))
                        //REGISTER_CLICK($('#profile_menu_recommendation_options'+$i),$i);
                    }else{
                         $('#event_recommendation_options'+$i).prop('disabled', true);
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                SET_BUTTON_CLICK_ACTION("event_file_upload_options1");
                $('#event_file_upload_options1').unbind().click(function(event) {
                    event_editor_function('event_file_upload_options1',event);
                });
                /* --------------------------------------------------------  */
                SET_BUTTON_CLICK_ACTION("entity_file_upload_options_options11");
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_standard0_action_frame_div" ).on( "mouseover", function() {
                    CONSOLE_MANAGER('event_standard0_action_frame_div','MOUSE OVER');
                    $('#event_file_segment0_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment1_upload_index1').val()==1){
                        $('#entity_file_upload_options_options1').prop('disabled', false);
                        $('#entity_file_upload_options_options1').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment0_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options1').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_standard0_action_frame_div','MOUSE OUT');
                    $('#event_file_segment0_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment1_upload_index1').val()==1){
                        $('#entity_file_upload_options_options1').prop('disabled', false);
                        $('#entity_file_upload_options_options1').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment0_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options1').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_standard1_action_frame_div" ).on( "mouseover", function() {
                    CONSOLE_MANAGER('event_standard1_action_frame_div','MOUSE OVER');
                    $('#event_file_segment1_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment1_upload_index1').val()==1){
                        $('#entity_file_upload_options_options1').prop('disabled', false);
                        $('#entity_file_upload_options_options1').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment1_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options1').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_standard1_action_frame_div','MOUSE OUT');
                    $('#event_file_segment1_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment1_upload_index1').val()==1){
                        $('#entity_file_upload_options_options1').prop('disabled', false);
                        $('#entity_file_upload_options_options1').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment1_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options1').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display1_action_frame_div" ).on( "mouseover", function() {
                    CONSOLE_MANAGER('event_display1_action_frame_div','MOUSE OVER');
                    $('#event_file_segment2_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment2_upload_index1').val()==1){
                        $('#entity_file_upload_options_options2').prop('disabled', false);
                        $('#entity_file_upload_options_options2').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment2_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options2').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display1_action_frame_div','MOUSE OUT');
                    $('#event_file_segment2_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment2_upload_index1').val()==1){
                        $('#entity_file_upload_options_options2').prop('disabled', false);
                        $('#entity_file_upload_options_options2').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment2_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options2').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display3_action_frame_div" ).on( "mouseover", function() {
                    CONSOLE_MANAGER('event_display3_action_frame_div','MOUSE OVER');
                    $('#event_file_segment3_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment3_upload_index1').val()==1){
                        $('#entity_file_upload_options_options3').prop('disabled', false);
                        $('#entity_file_upload_options_options3').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment3_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options3').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display3_action_frame_div','MOUSE OUT');
                    $('#event_file_segment3_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment3_upload_index1').val()==1){
                        $('#entity_file_upload_options_options3').prop('disabled', false);
                        $('#entity_file_upload_options_options3').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment3_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options3').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display4_action_frame_div" ).on( "mouseover", function() {
                    CONSOLE_MANAGER('event_display4_action_frame_div','MOUSE OVER');
                    $('#event_file_segment4_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment4_upload_index1').val()==1){
                        $('#entity_file_upload_options_options4').prop('disabled', false);
                        $('#entity_file_upload_options_options4').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment4_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options4').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display4_action_frame_div','MOUSE OUT');
                    $('#event_file_segment4_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment4_upload_index1').val()==1){
                        $('#entity_file_upload_options_options4').prop('disabled', false);
                        $('#entity_file_upload_options_options4').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment4_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options4').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display5_action_frame_div" ).on( "mouseover", function() {
                   CONSOLE_MANAGER('event_display5_action_frame_div','MOUSE OVER');
                    $('#event_file_segment5_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment5_upload_index1').val()==1){
                        $('#entity_file_upload_options_options5').prop('disabled', false);
                        $('#entity_file_upload_options_options5').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment5_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options5').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display5_action_frame_div','MOUSE OUT');
                    $('#event_file_segment5_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment5_upload_index1').val()==1){
                        $('#entity_file_upload_options_options5').prop('disabled', false);
                        $('#entity_file_upload_options_options5').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment5_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options5').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display6_action_frame_div" ).on( "mouseover", function() {
                   CONSOLE_MANAGER('event_display6_action_frame_div','MOUSE OVER');
                    $('#event_file_segment6_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment6_upload_index1').val()==1){
                        $('#entity_file_upload_options_options6').prop('disabled', false);
                        $('#entity_file_upload_options_options6').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment6_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options6').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display6_action_frame_div','MOUSE OUT');
                    $('#event_file_segment6_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment6_upload_index1').val()==1){
                        $('#entity_file_upload_options_options6').prop('disabled', false);
                        $('#entity_file_upload_options_options6').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment6_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options6').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_display7_action_frame_div" ).on( "mouseover", function() {
                   CONSOLE_MANAGER('event_display7_action_frame_div','MOUSE OVER');
                    $('#event_file_segment7_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment7_upload_index1').val()==1){
                        $('#entity_file_upload_options_options7').prop('disabled', false);
                        $('#entity_file_upload_options_options7').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment7_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options7').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_display7_action_frame_div','MOUSE OUT');
                    $('#event_file_segment7_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment7_upload_index1').val()==1){
                        $('#entity_file_upload_options_options7').prop('disabled', false);
                        $('#entity_file_upload_options_options7').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment7_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options7').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_standard2_action_frame_div" ).on( "mouseover", function() {
                   CONSOLE_MANAGER('event_standard2_action_frame_div','MOUSE OVER');
                    $('#event_file_segment8_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment8_upload_index1').val()==1){
                        $('#entity_file_upload_options_options8').prop('disabled', false);
                        $('#entity_file_upload_options_options8').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment8_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options8').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_standard2_action_frame_div','MOUSE OUT');
                    $('#event_file_segment8_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment8_upload_index1').val()==1){
                        $('#entity_file_upload_options_options8').prop('disabled', false);
                        $('#entity_file_upload_options_options8').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment8_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options8').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                $(".event_standard3_action_frame_div" ).on( "mouseover", function() {
                   CONSOLE_MANAGER('event_standard3_action_frame_div','MOUSE OVER');
                    $('#event_file_segment9_upload_theme_1').addClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').hide();
                    //$('#event_guidelines_display').hide();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment9_upload_index1').val()==1){
                        $('#entity_file_upload_options_options9').prop('disabled', false);
                        $('#entity_file_upload_options_options9').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment9_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options9').prop('disabled', true);
                    }
                 }).on( "mouseout", function() {
                    CONSOLE_MANAGER('event_standard3_action_frame_div','MOUSE OUT');
                    $('#event_file_segment9_upload_theme_1').removeClass('event_theme_display_selected');
                    //$('#event_mgmt_content_background_DIV').show();
                    //$('#event_guidelines_display').show();
                    /* --------------------------------------------------------  */
                    if($('#event_file_segment9_upload_index1').val()==1){
                        $('#entity_file_upload_options_options9').prop('disabled', false);
                        $('#entity_file_upload_options_options9').unbind().click(function(event) {
                            //DELETE
                            $('#event_file_segment9_upload_options2').unbind().click();
                        });
                    }else{
                        $('#entity_file_upload_options_options9').prop('disabled', true);
                    }
                });
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */

                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                /* -------------------------------------------------EVENT_PHOTO_basics  */	
                $('.Entity_upload_Activity_UI_class').attr("id", 'Entity_upload_Activity_UI_control');
                if(document.getElementById('Entity_upload_Activity_UI_control')){
                    /* ------------------------------------------------- */	
                    //WORKS
                    if($('#Event_File_Upload_Label').is(':visible')) {
                        $('#Event_File_Upload_Label').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label1').is(':visible')) {
                        $('#Event_File_Upload_Label1').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label1').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file1'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label2').is(':visible')) {
                        $('#Event_File_Upload_Label2').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label2').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file2'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label3').is(':visible')) {
                        $('#Event_File_Upload_Label3').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label3').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file3'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label4').is(':visible')) {
                        $('#Event_File_Upload_Label4').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label4').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file4'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label5').is(':visible')) {
                        $('#Event_File_Upload_Label5').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label5').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file5'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */	
                    //WORKS
                    if($('#Event_File_Upload_Label6').is(':visible')) {
                        $('#Event_File_Upload_Label6').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label6').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file6'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */
                    //WORKS
                    if($('#Event_File_Upload_Label7').is(':visible')) {
                        $('#Event_File_Upload_Label7').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label7').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file7'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */	
                    if($('#Event_File_Upload_Label8').is(':visible')) {
                        $('#Event_File_Upload_Label8').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label8').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file8'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */	
                    if($('#Event_File_Upload_Label9').is(':visible')) {
                        $('#Event_File_Upload_Label9').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label9').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file9'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                    /* ------------------------------------------------- */	
                    if($('#Event_File_Upload_Label10').is(':visible')) {
                        $('#Event_File_Upload_Label10').unbind().click(function(event) {
                            $('#entity_file_upload_options_control').val($('#Event_File_Upload_Label10').attr('id'));
                            ACTIVATE_UPLOAD_FORM_LISTED($('#entity_file_upload_options_file10'),document.getElementById("Entity_upload_Activity_UI_control"))
                            //ACTIVATE_UPLOAD_FORM_INIT($('.Entity_upload_Activity_UI_class'));
                        });
                    }
                }
                /* --------------------------------------------------------  */
				if(document.getElementById("settings_event_general_title")){
					var reg_titlename_var = document.getElementById("settings_event_general_title");
					$('#settings_event_general_title').attr('readonly', false);
					/* --------------------------------------------------------  */
					reg_titlename_var.onfocus = function() {
						$('#settings_event_general_title').keyup(function(event) {
							var key = String.fromCharCode(event.which);
							/* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_general_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
								var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_general_title').val();
                                $('#settings_event_general_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
							if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
							   (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
							   (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
							/*
							(key == 13 /ENTER/)
							(key == 46 /DELETE/)
							(key == 8 /BACKSPACE/)
							*/ 
							}
							var $REG_UNAME = $('#settings_event_general_title').val();
							var string = document.getElementById("settings_event_general_title").value;
							reg_titlename_var.onblur = function($EVENT) {
								if($REG_UNAME.length>1){
								//ENTRYdelay(function(){
									if(($('#settings_event_general_title').val().length>0)){
										$('#standard_entity_modify_control').val($('#settings_event_general_title').attr('id'));
										$('#standard_entity_modify_title').val($('#settings_event_general_title').val());
										$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
										$('#standard_entity_modify_registerCode').val($('#settings_event_general_formCurrentIndex').val());
										$('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
										$('#standard_entity_modify_index3').val($('#settings_event_general_formCurrentIndex').val());
										$('#standard_entity_modify_formCurrentIndex').val($('#settings_event_general_title').val());
										//windowload_script('form_event_creator_MGMT - $REG_UNAME');
										event_instance_function('event_activity_interaction_options', $('#settings_event_general_title').attr('id'),$('#settings_event_general_title').val(),$EVENT);
										//event_title_validation_MGMT(event);
									}
								}
							}
						});
					}
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if(document.getElementById("settings_event_description_formCurrentIndex")){
					var reg_desciption_var = document.getElementById("settings_event_description_formCurrentIndex");
					$('#settings_event_description_formCurrentIndex').attr('readonly', false);
					/* -------------------------------------------------------- */
					reg_desciption_var.onfocus = function() {
						var string = document.getElementById("settings_event_description_formCurrentIndex").value;

						$('#settings_event_description_formCurrentIndex').keyup(function(event) {
							var key = String.fromCharCode(event.which);
							/* --------------------------------------------------------  */
                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_formCurrentIndex").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
								var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_formCurrentIndex').val();
                                $('#settings_event_description_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }
                            /* --------------------------------------------------------  */
							if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
							   (event.keyCode == $.ui.keyCode.DELETE )|| 
							   (event.keyCode == $.ui.keyCode.BACKSPACE )){

							}
							var $REG_DESC = $('#settings_event_description_formCurrentIndex').val();

							reg_desciption_var.onblur = function($EVENT) {
								if($REG_DESC.length>1){
                                    $('#standard_entity_modify_control').val($('#settings_event_description_formCurrentIndex').attr('id'));
                                    $('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
                                    $('#standard_entity_modify_registerCode').val($('#settings_event_general_formCurrentIndex').val());
                                    $('#standard_entity_modify_index2').val($('#settings_event_general_uniqEventId').val());
                                    $('#standard_entity_modify_index3').val($('#settings_event_general_formCurrentIndex').val());
                                    $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_formCurrentIndex').val());
                                    event_instance_function('event_activity_interaction_options', $('#settings_event_description_formCurrentIndex').attr('id'),$('#settings_event_description_formCurrentIndex').val(),$EVENT);	
								}
							}
						}); 
					}
				}
				/* ------------------------------------------------- */	
				for(var i=1; i<2; i++){
					if($('#settings_event_guidelines_profile_options'+i)){
						SET_BUTTON_CLICK_ACTION('settings_event_guidelines_profile_options2');
					}
				}
				/* ------------------------------------------------- */	
				for(var i=1; i<2; i++){
					if($('#settings_event_guidelines_contribution_options'+i)){
						SET_BUTTON_CLICK_ACTION('settings_event_guidelines_contribution_options2');
					}
				}
				/* ------------------------------------------------- */	
				for(var i=1; i<2; i++){
					if($('#settings_event_guidelines_sharing_options'+i)){
						SET_BUTTON_CLICK_ACTION('settings_event_guidelines_sharing_options2');
					}
				}
				/* ------------------------------------------------- */	
				for(var i=1; i<2; i++){
					if($('#settings_event_guidelines_social1_options'+i)){
						SET_BUTTON_CLICK_ACTION('settings_event_guidelines_social1_options2');
					}
				}
				/* ------------------------------------------------- */	
				for(var i=1; i<2; i++){
					if($('#settings_event_guidelines_social3_options'+i)){
						SET_BUTTON_CLICK_ACTION('settings_event_guidelines_social3_options2');
					}
				}	
            /* --------------------------------------------------------  */
            }//END COHOST
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  
			if($('#settings_event_dates_options1').length){
				$('#settings_event_dates_options1').single_double_click(function(event){
						
						event.stopPropagation;
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options1_singleclick');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					}, function(event) {
						
						event.stopPropagation;
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options1_doubleclick');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					}, function(event) {
						
						event.stopPropagation;	
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options1_press');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					},function(event) {
						
						event.stopPropagation;	
					}													  
				);
			}
			if($('#settings_event_dates_options2').length){
				$('#settings_event_dates_options2').single_double_click(function(event){
						
						event.stopPropagation;
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options2_singleclick');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					}, function(event) {
						
						event.stopPropagation;
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options2_doubleclick');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					}, function(event) {
						
						event.stopPropagation;	
						$('#standard_entity_modify_uniqEventId').val($('#settings_event_general_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_control').val('form_event_maincurrence_MGMT_options2_press');//+$val_code+'_control'
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					},function(event) {
						
						event.stopPropagation;
					}													  
				);
			}
            */
			var $ENTITYLISTMgmt = [];
            var $ENTITYLISTCOORDMgmt = [];
            //var $code = $("#index_object").val();
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            if(document.getElementById("total_objects_count_item")){
                var Object_count = parseInt(document.getElementById("total_objects_count_item").value + 1);
                var $code = $("#index_object_item").val();
                for(var $i=1; $i<Object_count; $i++){

                    if($('#form_'+$code+'_'+$i+'_options9').is(':visible')){
                        /* ------------------------------------------------- */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                        var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                        var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                        var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                        var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                        var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
                        var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
                        var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
                        var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                        var $COLORSEQUENCE = '#form_'+$code+'_'+$i+'_colorCode';
                        var $SELECTEDSEQUENCE = '#form_'+$code+'_'+$i+'_formCurrentIndex';
                        var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                        var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
                        var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
                        /* ------------------------------------------------- */
                        $($OPTIONS04).css("background-color",$($COLORSEQUENCE).val());
						//$($OPTIONS11).css("background-color",$($COLORSEQUENCE).val());
                        /* ------------------------------------------------- */
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                        //if($($PATHSEQUENCE).val()){
                        //	$($OPTIONS03).css('background-image','url('+$($PATHSEQUENCE).val()+')');
                        //}
                        /*
                        event_standard_entity_modify_register(
                            $($OPTIONS01),
                            $($INDEX03)
                        )
                        event_standard_entity_modify_register(
                            $($OPTIONS02),
                            $($INDEX03)
                        )
                        */
                        /* ------------------------------------------------- */	
                        ITEM_DURATION($($OPTIONS10),$($OPTIONS05),$($OPTIONS07));
                        /* ------------------------------------------------- */	
                        var $SUBMgmt = {GET_UNIQ_ID:$($INDEX02).val(),GET_ITEM_ID:$($INDEX03).val(),SET_STATUS:$($INDEX04).val()};
                        $ENTITYLISTMgmt[$i] =$SUBMgmt;
                        /* ------------------------------------------------- */
                        /* ------------------------------------------------- */
                        REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS09),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                        REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
                    }	
                }
                $('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));   
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- */
                /* -------------------------------------------------------- 
                var conn = new ab.Session('ws://127.0.0.1:8080',
                    function($EVENT) {
                        $EVENT.stopPropagation;
                        var $DATES_obj_array=[];	
                        conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
                            var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
                            if(jsonValidate==true){
                                $DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
                                if($DATES_obj_array != null){

                                    if($DATES_obj_array.length > 0){
                                        $DATES_obj_array.forEach((element, index, array) => {
                                            if(element){
                                                if(typeof element.GET_UNIQ_ID != "undefined"){
                                                    if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
                                                        var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
                                                        //item_event_activity_active	item_event_activity_pending	item_event_activity_complete
                                                        //activityCLASS	scheduleCLASS	itemviewCLASS
                                                        $(element.SET_STATUS).removeClass(className);
                                                        $(element.SET_STATUS).addClass(data.itemviewCLASS);
                                                    }
                                                }
                                            }
                                        });
                                    }
                                }
                            }
                        });
                    },
                    function() {
                        console.warn('WebSocket connection closed');
                    },			   
                    {'skipSubprotocolCheck': true}		   
                );*//*END FUNCTION */
				/* -------------------------------------------------------- */
                /* --------------------------------------------------------  */
                function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
					$button_obj.unbind().click(function(event) {
						 event.stopPropagation;
                            $('#standard_entity_modify_title').val($TITLE.val());
                            $('#standard_entity_modify_index1').val($INDEX01.val());
                            $('#standard_entity_modify_index2').val($INDEX02.val());
                            $('#standard_entity_modify_index3').val($INDEX03.val());
                            $('#standard_entity_modify_index4').val($INDEX04.val());
                            $('#standard_entity_modify_index5').val($INDEX05.val());
                            $('#standard_entity_modify_index6').val($INDEX06.val());
                            $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                            $('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
                            standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

					});
                }
			}
			/* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            /* -------------------------------------------------------- */
            if(document.getElementById("total_objects_count")){
                var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
                var $code = $("#index_object").val();
                for(var $i=1; $i<Object_count; $i++){

                    if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                        /* ------------------------------------------------- */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                        var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                        var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        /* ------------------------------------------------- */
                        /* ------------------------------------------------- */
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                        /* ------------------------------------------------- */
                        /* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
                        REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                        REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03));
                    }	
                }   
				/* -------------------------------------------------------- */
                /* --------------------------------------------------------  */
                function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03){
					$button_obj.unbind().click(function(event) {
						 event.stopPropagation;
                            $('#standard_entity_modify_title').val($TITLE.val());
                            $('#standard_entity_modify_index1').val($INDEX01.val());
                            $('#standard_entity_modify_index2').val($INDEX02.val());
                            $('#standard_entity_modify_index3').val($INDEX03.val());
                            $('#standard_entity_modify_index7').val($button_obj.attr('id'));
                            $('#standard_entity_modify_control').val('host_eventAction');
                            standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
					});
                }
			}
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_count_activate(){//event_register_div_manager settings_event_description_edit_title"
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_title")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_title");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_title").value;

                        $('#settings_event_description_edit_title').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_title').val($('#settings_event_description_edit_title').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_title").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_title').val();
                                $('#settings_event_description_edit_title').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                        });
                    }
                    /* --------------------------------------------------------  */
                    var ttextarea = null;
                    var tresult = null;
                    var tNEW_VAL = null;

                    //var MAXVAL= $("#formCount4").val();
                    var tMAXVAL= $("#settings_event_description_edit_title").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter4").value=tMAXVAL;
                    if(document.getElementById("settings_event_description_edit_title")){
                        ttextarea = document.getElementById("settings_event_description_edit_title");
                        if(document.getElementById("settings_event_description_edit_formFilter4")){
                            tresult   = document.getElementById("settings_event_description_edit_formFilter4");
                            //result.value = tMAXVAL;
                            ttextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                tNEW_VAL = tMAXVAL - v.characters;
                                tresult.value = tNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText1")){
                    var reg_desciption_var1 = document.getElementById("settings_event_description_edit_descriptionText1");


                    reg_desciption_var1.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText1").value;

                        $('#settings_event_description_edit_descriptionText1').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText1').val($('#settings_event_description_edit_descriptionText1').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText1").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText1').val();
                                $('#settings_event_description_edit_descriptionText1').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText1').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText1').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText1').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText1').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText1').attr('id'),$('#settings_event_description_edit_descriptionText1').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var htextarea = null;
                    var hresult = null;
                    var hNEW_VAL = null;

                    //var hMAXVAL= $("#formCount1").val();
                    var hMAXVAL= $("#settings_event_description_edit_descriptionText1").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter1").value=hMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText1")){
                        htextarea = document.getElementById("settings_event_description_edit_descriptionText1");
                        if(document.getElementById("settings_event_description_edit_formFilter1")){
                            hresult   = document.getElementById("settings_event_description_edit_formFilter1");
                            //result.value = hMAXVAL;
                            htextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                hNEW_VAL = hMAXVAL - v.characters;
                                hresult.value = hNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText2")){
                    var reg_desciption_var2 = document.getElementById("settings_event_description_edit_descriptionText2");


                    reg_desciption_var2.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText2").value;

                        $('#settings_event_description_edit_descriptionText2').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText2').val($('#settings_event_description_edit_descriptionText2').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText2").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText2').val();
                                $('#settings_event_description_edit_descriptionText2').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText2').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText2').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText2').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText2').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText2').attr('id'),$('#settings_event_description_edit_descriptionText2').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var btextarea = null;
                    var bresult = null;
                    var bNEW_VAL = null;

                    //var bMAXVAL= $("#formCount2").val();
                    var bMAXVAL= $("#settings_event_description_edit_descriptionText2").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter2").value=bMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText2")){
                        btextarea = document.getElementById("settings_event_description_edit_descriptionText2");
                        if(document.getElementById("settings_event_description_edit_formFilter2")){
                            bresult   = document.getElementById("settings_event_description_edit_formFilter2");
                            //result.value = bMAXVAL;
                            btextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                bNEW_VAL = bMAXVAL - v.characters;
                                bresult.value = bNEW_VAL;
                            }, false);
                        }
                    }
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  */
                if(document.getElementById("settings_event_description_edit_descriptionText3")){
                    var reg_desciption_var3 = document.getElementById("settings_event_description_edit_descriptionText3");


                    reg_desciption_var3.onfocus = function() {
                        var string = document.getElementById("settings_event_description_edit_descriptionText3").value;

                        $('#settings_event_description_edit_descriptionText3').keyup(function(event) {
                            var key = String.fromCharCode(event.which);
                            //if(event.keyCode == 32){
                            //    $('#settings_event_description_edit_descriptionText3').val($('#settings_event_description_edit_descriptionText3').val()+' ');
                            //}

                            //var codes = [9,13,32];
                            var codes = [32];
                            document.getElementById("settings_event_description_edit_descriptionText3").addEventListener('keydown', function(e) {
                            if(!contains(codes, e.keyCode)) return;
                                var path = e.path || e.composedPath()
                                if(!path[0]) return;

                                e.preventDefault();
                                path[0].value += String.fromCharCode(e.keyCode);//
                                var $STRING=$('#settings_event_description_edit_descriptionText3').val();
                                $('#settings_event_description_edit_descriptionText3').val($STRING.replace(/\s{2,}/g, ' '));
                            })
                            function contains(arr, item) {
                                for(var i=0;i<arr.length;i++)
                                if(arr[i]==item)return true;
                                return false;
                            }

                            if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                               (event.keyCode == $.ui.keyCode.DELETE )|| 
                               (event.keyCode == $.ui.keyCode.BACKSPACE )){

                            }
                            /*
                            var $REG_DESC = $('#settings_event_description_edit_descriptionText3').val();
                            reg_desciption_var.onblur = function($EVENT) {
                                if(string!=$REG_DESC){
                                    if($( "#event_creator_formFilter1" ).val()=='PARENT'){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                    }else if($( "#event_creator_formFilter1" ).val()=='CHILD'){
                                        //var $description = $('#standard_entity_modify_formCurrentIndex').val();
                                        //if($temp != $('#settings_event_description_edit_descriptionText3').val()){
                                        $('#standard_entity_modify_control').val($('#settings_event_description_edit_descriptionText3').attr('id'));
                                        $('#standard_entity_modify_uniqEventId').val($('#event_creator_uniqEventId').val());
                                        $('#standard_entity_modify_registerCode').val($('#event_creator_registerCode').val());
                                        $('#standard_entity_modify_formCurrentIndex').val($('#settings_event_description_edit_descriptionText3').val());
                                        event_instance_function('event_activity_interaction_options', $('#settings_event_description_edit_descriptionText3').attr('id'),$('#settings_event_description_edit_descriptionText3').val(),$EVENT);
                                        //}
                                    }
                                }
                            }
                            */
                        }); 
                    }
                    /* --------------------------------------------------------  */
                    var ftextarea = null;
                    var fresult = null;
                    var fNEW_VAL = null;

                    //var fMAXVAL= $("#formCount3").val();
                    var fMAXVAL= $("#settings_event_description_edit_descriptionText3").attr("maxlength");
                    document.getElementById("settings_event_description_edit_formFilter3").value=fMAXVAL;
                    if(document.getElementById("settings_event_description_edit_descriptionText3")){
                        ftextarea = document.getElementById("settings_event_description_edit_descriptionText3");
                        if(document.getElementById("settings_event_description_edit_formFilter3")){
                            fresult   = document.getElementById("settings_event_description_edit_formFilter3");
                            //result.value = fMAXVAL;
                            ftextarea.addEventListener("input", function(){
                                var v = wordCount( this.value );
                                fNEW_VAL = fMAXVAL - v.characters;
                                fresult.value = fNEW_VAL;
                            }, false);
                        }
                    }
                }
                //setTimeout(is_review_complete, 500);
            }/*END OF FUNCTION*/
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_activate(){
                CONSOLE_MANAGER('event_description_activate','ACTIVE');
                /* --------------------------------------------------------  */
                /*
                    'STANDARD'
                    'TITLE_BODY'
                    'BODY'
                    
                    'HIDDEN'=>'any_visuallyhidden',
                    'STANDARD1'=>'event_edit_description_field1_1',
                    'STANDARD2'=>'event_edit_description_field1_2',
                    'STANDARD3'=>'event_edit_description_field1_3',
                    'TITLE_BODY1'=>'event_edit_description_field2_1',
                    'TITLE_BODY2'=>'event_edit_description_field2_3',
                    'BODY1'=>'event_edit_description_field3_1',
                    
                    'MENU_STANDARD_DEFAULT'=>'event_edit_description_button_default1',
                    'MENU_STANDARD_ACTIVE'=>'event_edit_description_button_active1',
                    'MENU_TITLE_DEFAULT'=>'event_edit_description_button_default2',
                    'MENU_TITLE_ACTIVE'=>'event_edit_description_button_active2',
                    'MENU_BODY_DEFAULT'=>'event_edit_description_button_default3',
                    'MENU_BODY_ACTIVE'=>'event_edit_description_button_active3',
                    
                    'TD_STANDARD1'=>'event_edit_pop_td2_1_ST',
                    'TD_STANDARD2'=>'event_edit_pop_td2_2_ST',
                    'TD_STANDARD3'=>'event_edit_pop_td2_3_ST',
                    
                    'TD_TITLE_BODY1'=>'event_edit_pop_td2_1_TB',
                    'TD_TITLE_BODY2'=>'event_edit_pop_td2_2_TB',
                    
                    'TD_BODY1'=>'event_edit_pop_td2_1_B',
                */
                /* -------------------------------------------------------- */
                $('#notificationsystem_menu_action').hide();
                //$('#notificationsystem_menu_action').show();
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options1");
                var $HIDDEN, $PARENT, $TITLE1, $TITLE2, $STANDARD1,$STANDARD2,$STANDARD3,$TITLE_BODY1,$TITLE_BODY2,$BODY1,$MENU_STANDARD_DEFAULT,$MENU_STANDARD_ACTIVE,$MENU_TITLE_DEFAULT,$MENU_TITLE_ACTIVE,$MENU_BODY_DEFAULT,$MENU_BODY_ACTIVE,$TD_STANDARD1,$TD_STANDARD2,$TD_STANDARD3,$TD_TITLE_BODY1,$TD_TITLE_BODY2,$TD_BODY1,$COUNT_STANDARD1,$COUNT_STANDARD2,$COUNT_STANDARD3,$COUNT_TITLE_BODY1,$COUNT_TITLE_BODY2,$COUNT_BODY1 = null;
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_description_edit_index4').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_description_edit_index4').val());
                    if($DATES_obj_array != null){

                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    /* -------------------------------------------------------- */
                                    CONSOLE_MANAGER('event_description_activate','DATA FOR CLASS: '+element.HIDDEN+' -|COUNT|- '+element.STANDARD1+' -|STATUS|- '+element.STANDARD2+' -- '+element.STANDARD3+' -- '+element.TITLE_BODY1+' -|LIST|- '+element.TITLE_BODY2+' -- '+element.BODY1+' -|VIEW|- '+element.MENU_STANDARD_DEFAULT+' -- '+element.MENU_STANDARD_ACTIVE+' -|SCROLL|- '+element.MENU_TITLE_DEFAULT+' -- '+element.MENU_TITLE_ACTIVE);
                                    /* -------------------------------------------------------- */
                                    $PARENT = element.PARENT;
                                    $HIDDEN = element.HIDDEN;
                                    $TITLE1 = element.TITLE1;
                                    $TITLE2 = element.TITLE2;
                                    $STANDARD1 = element.STANDARD1;
                                    $STANDARD2 = element.STANDARD2;
                                    $STANDARD3 = element.STANDARD3;
                                    $TITLE_BODY1 = element.TITLE_BODY1;
                                    $TITLE_BODY2 = element.TITLE_BODY2;
                                    $BODY1 = element.BODY1;
                                    
                                    $TD_STANDARD1 = element.TD_STANDARD1;
                                    $TD_STANDARD2 = element.TD_STANDARD2;
                                    $TD_STANDARD3 = element.TD_STANDARD3;
                                    $TD_TITLE_BODY1 = element.TD_TITLE_BODY1;
                                    $TD_TITLE_BODY2 = element.TD_TITLE_BODY2;
                                    $TD_BODY1 = element.TD_BODY1;
                                    
                                    
                                    $MENU_STANDARD_DEFAULT = element.MENU_STANDARD_DEFAULT;
                                    $MENU_STANDARD_ACTIVE = element.MENU_STANDARD_ACTIVE;
                                    $MENU_TITLE_DEFAULT = element.MENU_TITLE_DEFAULT;
                                    $MENU_TITLE_ACTIVE = element.MENU_TITLE_ACTIVE;
                                    $MENU_BODY_DEFAULT = element.MENU_BODY_DEFAULT;
                                    $MENU_BODY_ACTIVE = element.MENU_BODY_ACTIVE;
                                    
                                    $COUNT_STANDARD1 = element.COUNT_STANDARD1;
                                    $COUNT_STANDARD2 = element.COUNT_STANDARD2;
                                    $COUNT_STANDARD3 = element.COUNT_STANDARD3;
                                    $COUNT_TITLE_BODY1 = element.COUNT_TITLE_BODY1;
                                    $COUNT_TITLE_BODY2 = element.COUNT_TITLE_BODY2;
                                    $COUNT_BODY1 = element.COUNT_BODY1;
                                }
                            });
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options2");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options3");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options4");
                            /* --------------------------------------------------------  */
                            if($PARENT==true){
                                $('#settings_event_description_edit_title').addClass($TITLE1);
                                $('#settings_event_description_edit_title').removeClass($TITLE2);
                            }else{
                                $('#settings_event_description_edit_title').addClass($TITLE2);
                                $('#settings_event_description_edit_title').removeClass($TITLE1);
                            }
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options2').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options2 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                if($('#settings_event_description_edit_options2').hasClass($MENU_STANDARD_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options3').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options3 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_edit_options3').hasClass($MENU_TITLE_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_TITLE_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                                        $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_edit_options4').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_edit_options4 CLICK - '+$MENU_BODY_ACTIVE+' - '+$MENU_BODY_DEFAULT);
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_edit_options2').attr('id'));
                                var $DATA = $('#settings_event_description_edit_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_edit_options4').hasClass($MENU_BODY_DEFAULT)){
                                    $('#settings_event_description_edit_descriptionType').val('BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').hide();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').hide();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').hide();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_BODY1));
                                        $('#formCount2').val(parseInt(0));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($HIDDEN);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_edit_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                                        $('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                                        $('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                                }
                                event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_creator_MGMT - event_description_activate');
                            });
                        }
                    }
                }else{
                    CONSOLE_MANAGER('event_description_activate','INVALID JSON');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                if($('#settings_event_description_edit_descriptionType').val()=='STANDARD' || !$('#settings_event_description_edit_descriptionType').val()){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE STANDARD');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_STANDARD1));
                    $('#formCount2').val(parseInt($COUNT_STANDARD2));
                    $('#formCount3').val(parseInt($COUNT_STANDARD3));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($STANDARD1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($STANDARD2);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($STANDARD3);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='TITLE_BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE TITLE_BODY');
                    $('#settings_event_description_edit_descriptionType').val('TITLE_BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_TITLE_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                    $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index5').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($TITLE_BODY1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($TITLE_BODY2);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_edit_descriptionType').val()=='BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE BODY');
                    $('#settings_event_description_edit_descriptionType').val('BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_edit_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_edit_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_edit_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_edit_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_edit_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_edit_options4').addClass($MENU_BODY_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').hide();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').hide();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').hide();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_BODY1));
                    $('#formCount2').val(parseInt(0));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_edit_descriptionText1').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText2').attr('placeholder',$('#settings_event_description_edit_index6').val());
                    //$('#settings_event_description_edit_descriptionText3').attr('placeholder',$('#settings_event_description_edit_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_edit_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_edit_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_edit_descriptionText1').addClass($BODY1);

                    $('#settings_event_description_edit_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_edit_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_edit_descriptionText2').addClass($HIDDEN);

                    $('#settings_event_description_edit_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_edit_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_edit_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                event_description_count_activate();
            }/*END OF FUNCTION*/
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            /* --------------------------------------------------------  *//* --------------------------------------------------------  */
            function event_description_activate2(){
                CONSOLE_MANAGER('event_description_activate2','ACTIVE');
                /* --------------------------------------------------------  */
                /*
                    'STANDARD'
                    'TITLE_BODY'
                    'BODY'
                    
                    'HIDDEN'=>'any_visuallyhidden',
                    'STANDARD1'=>'event_edit_description_field1_1',
                    'STANDARD2'=>'event_edit_description_field1_2',
                    'STANDARD3'=>'event_edit_description_field1_3',
                    'TITLE_BODY1'=>'event_edit_description_field2_1',
                    'TITLE_BODY2'=>'event_edit_description_field2_3',
                    'BODY1'=>'event_edit_description_field3_1',
                    
                    'MENU_STANDARD_DEFAULT'=>'event_edit_description_button_default1',
                    'MENU_STANDARD_ACTIVE'=>'event_edit_description_button_active1',
                    'MENU_TITLE_DEFAULT'=>'event_edit_description_button_default2',
                    'MENU_TITLE_ACTIVE'=>'event_edit_description_button_active2',
                    'MENU_BODY_DEFAULT'=>'event_edit_description_button_default3',
                    'MENU_BODY_ACTIVE'=>'event_edit_description_button_active3',
                    
                    'TD_STANDARD1'=>'event_edit_pop_td2_1_ST',
                    'TD_STANDARD2'=>'event_edit_pop_td2_2_ST',
                    'TD_STANDARD3'=>'event_edit_pop_td2_3_ST',
                    
                    'TD_TITLE_BODY1'=>'event_edit_pop_td2_1_TB',
                    'TD_TITLE_BODY2'=>'event_edit_pop_td2_2_TB',
                    
                    'TD_BODY1'=>'event_edit_pop_td2_1_B',
                */
                /* -------------------------------------------------------- */
                $('#notificationsystem_menu_action').hide();
                //$('#notificationsystem_menu_action').show();
                /* -------------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION("settings_event_description_view_options1");
                var $HIDDEN, $PARENT, $TITLE1, $TITLE2, $STANDARD1,$STANDARD2,$STANDARD3,$TITLE_BODY1,$TITLE_BODY2,$BODY1,$MENU_STANDARD_DEFAULT,$MENU_STANDARD_ACTIVE,$MENU_TITLE_DEFAULT,$MENU_TITLE_ACTIVE,$MENU_BODY_DEFAULT,$MENU_BODY_ACTIVE,$TD_STANDARD1,$TD_STANDARD2,$TD_STANDARD3,$TD_TITLE_BODY1,$TD_TITLE_BODY2,$TD_BODY1,$COUNT_STANDARD1,$COUNT_STANDARD2,$COUNT_STANDARD3,$COUNT_TITLE_BODY1,$COUNT_TITLE_BODY2,$COUNT_BODY1 = null;
                var $DATES_obj_array=[];
                var jsonValidate  = isJSON($('#settings_event_description_view_index4').val());
                if(jsonValidate==true){
                    $DATES_obj_array = JSON.parse($('#settings_event_description_view_index4').val());
                    if($DATES_obj_array != null){

                        if($DATES_obj_array.length > 0){
                            $DATES_obj_array.forEach((element, index, array) => {
                                if(element){
                                    /* -------------------------------------------------------- */
                                    CONSOLE_MANAGER('event_description_activate','DATA FOR CLASS: '+element.HIDDEN+' -|COUNT|- '+element.STANDARD1+' -|STATUS|- '+element.STANDARD2+' -- '+element.STANDARD3+' -- '+element.TITLE_BODY1+' -|LIST|- '+element.TITLE_BODY2+' -- '+element.BODY1+' -|VIEW|- '+element.MENU_STANDARD_DEFAULT+' -- '+element.MENU_STANDARD_ACTIVE+' -|SCROLL|- '+element.MENU_TITLE_DEFAULT+' -- '+element.MENU_TITLE_ACTIVE);
                                    /* -------------------------------------------------------- */
                                    $PARENT = element.PARENT;
                                    $HIDDEN = element.HIDDEN;
                                    $TITLE1 = element.TITLE1;
                                    $TITLE2 = element.TITLE2;
                                    $STANDARD1 = element.STANDARD1;
                                    $STANDARD2 = element.STANDARD2;
                                    $STANDARD3 = element.STANDARD3;
                                    $TITLE_BODY1 = element.TITLE_BODY1;
                                    $TITLE_BODY2 = element.TITLE_BODY2;
                                    $BODY1 = element.BODY1;
                                    
                                    $TD_STANDARD1 = element.TD_STANDARD1;
                                    $TD_STANDARD2 = element.TD_STANDARD2;
                                    $TD_STANDARD3 = element.TD_STANDARD3;
                                    $TD_TITLE_BODY1 = element.TD_TITLE_BODY1;
                                    $TD_TITLE_BODY2 = element.TD_TITLE_BODY2;
                                    $TD_BODY1 = element.TD_BODY1;
                                    
                                    
                                    $MENU_STANDARD_DEFAULT = element.MENU_STANDARD_DEFAULT;
                                    $MENU_STANDARD_ACTIVE = element.MENU_STANDARD_ACTIVE;
                                    $MENU_TITLE_DEFAULT = element.MENU_TITLE_DEFAULT;
                                    $MENU_TITLE_ACTIVE = element.MENU_TITLE_ACTIVE;
                                    $MENU_BODY_DEFAULT = element.MENU_BODY_DEFAULT;
                                    $MENU_BODY_ACTIVE = element.MENU_BODY_ACTIVE;
                                    
                                    $COUNT_STANDARD1 = element.COUNT_STANDARD1;
                                    $COUNT_STANDARD2 = element.COUNT_STANDARD2;
                                    $COUNT_STANDARD3 = element.COUNT_STANDARD3;
                                    $COUNT_TITLE_BODY1 = element.COUNT_TITLE_BODY1;
                                    $COUNT_TITLE_BODY2 = element.COUNT_TITLE_BODY2;
                                    $COUNT_BODY1 = element.COUNT_BODY1;
                                }
                            });
                            SET_BUTTON_CLICK_ACTION("settings_event_description_view_options2");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_view_options3");
                            SET_BUTTON_CLICK_ACTION("settings_event_description_view_options4");
                            /* --------------------------------------------------------  */
                            if($PARENT==true){
                                $('#settings_event_description_view_title').addClass($TITLE1);
                                $('#settings_event_description_view_title').removeClass($TITLE2);
                            }else{
                                $('#settings_event_description_view_title').addClass($TITLE2);
                                $('#settings_event_description_view_title').removeClass($TITLE1);
                            }
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_view_options2').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_view_options2 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_view_options2').attr('id'));
                                var $DATA = $('#settings_event_description_view_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                if($('#settings_event_description_view_options2').hasClass($MENU_STANDARD_DEFAULT)){
                                    $('#settings_event_description_view_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                                        $('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        $('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                                }else{
                                    $('#settings_event_description_view_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                                        $('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        $('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                                }
                                //event_description_count_activate();
                                window_complete_script('form_event_maincurrence_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_view_options3').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_view_options3 CLICK');
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_view_options2').attr('id'));
                                var $DATA = $('#settings_event_description_view_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_view_options3').hasClass($MENU_TITLE_DEFAULT)){
                                    $('#settings_event_description_view_descriptionType').val('TITLE_BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_ACTIVE);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_TITLE_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                                        $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                                        $('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_view_descriptionType').val('STANDARD');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                                        $('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        $('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($STANDARD1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($STANDARD2);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                                }
                                //event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_maincurrence_MGMT - event_description_activate');
                            });
                            /* --------------------------------------------------------  */
                            $('#settings_event_description_view_options4').unbind().click(function($EVENT) {
                                CONSOLE_MANAGER('event_description_activate','settings_event_description_view_options4 CLICK - '+$MENU_BODY_ACTIVE+' - '+$MENU_BODY_DEFAULT);
                                /*
                                event.preventDefault();
                                $('#standard_entity_modify_control').val($('#settings_event_description_view_options2').attr('id'));
                                var $DATA = $('#settings_event_description_view_descriptionType');
                                var $DATA_RETURN = control_pop_window_no_ajax(
                                    $DATA
                                );
                                */
                                /* --------------------------------------------------------  */
                                if($('#settings_event_description_view_options4').hasClass($MENU_BODY_DEFAULT)){
                                    $('#settings_event_description_view_descriptionType').val('BODY');
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_ACTIVE);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').hide();
                                        $('#TEXT3_ROW').hide();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').hide();
                                        $('#TEXT3_TD').hide();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').hide();
                                        $('#FILTER3_ROW').hide();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_BODY1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        //$('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_BODY1));
                                        $('#formCount2').val(parseInt(0));
                                        $('#formCount3').val(parseInt(0));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        //$('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($BODY1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($HIDDEN);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($HIDDEN);
                                }else{
                                    $('#settings_event_description_view_descriptionType').val(null);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                                    /* --------------------------------------------------------  */
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                                    /* --------------------------------------------------------  */
                                        $('#TEXT1_ROW').show();
                                        $('#TEXT2_ROW').show();
                                        $('#TEXT3_ROW').show();
                                        $('#TEXT1_TD').show();
                                        $('#TEXT2_TD').show();
                                        $('#TEXT3_TD').show();
                                        $('#FILTER1_ROW').show();
                                        $('#FILTER2_ROW').show();
                                        $('#FILTER3_ROW').show();
                                        /* --------------------------------------------------------  */
                                        $('#TEXT1_TD').removeClass($TD_STANDARD1);
                                        $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                                        $('#TEXT1_TD').removeClass($TD_BODY1);
                                        $('#TEXT1_TD').addClass($TD_STANDARD1);
                                    
                                        $('#TEXT2_TD').removeClass($TD_STANDARD2);
                                        $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                                        $('#TEXT2_TD').addClass($TD_STANDARD2);
                                        
                                        $('#TEXT3_TD').removeClass($TD_STANDARD3);
                                        $('#TEXT3_TD').addClass($TD_STANDARD3);
                                        /* --------------------------------------------------------  */
                                        $('#formCount1').val(parseInt($COUNT_STANDARD1));
                                        $('#formCount2').val(parseInt($COUNT_STANDARD2));
                                        $('#formCount3').val(parseInt($COUNT_STANDARD3));
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                                        $('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                                        $('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                                        /* --------------------------------------------------------  */
                                        $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                                        $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                                        $('#settings_event_description_view_descriptionText1').addClass($TITLE_BODY1);
                                        
                                        $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                                        $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                                        $('#settings_event_description_view_descriptionText2').addClass($TITLE_BODY2);
                                        
                                        $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                                        $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                                        $('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                                }
                                //event_description_count_activate();
                                /* --------------------------------------------------------  */
                                window_complete_script('form_event_maincurrence_MGMT - event_description_activate');
                            });
                        }
                    }
                }else{
                    CONSOLE_MANAGER('event_description_activate','INVALID JSON');
                }
                /* --------------------------------------------------------  */
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                if($('#settings_event_description_view_descriptionType').val()=='STANDARD' || !$('#settings_event_description_view_descriptionType').val()){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE STANDARD');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').show();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').show();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').show();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_STANDARD1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_STANDARD2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    $('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_STANDARD1));
                    $('#formCount2').val(parseInt($COUNT_STANDARD2));
                    $('#formCount3').val(parseInt($COUNT_STANDARD3));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                    //$('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                    //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_view_descriptionText1').addClass($STANDARD1);

                    $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_view_descriptionText2').addClass($STANDARD2);

                    $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_view_descriptionText3').addClass($STANDARD3);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_view_descriptionType').val()=='TITLE_BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE TITLE_BODY');
                    $('#settings_event_description_view_descriptionType').val('TITLE_BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_view_options4').addClass($MENU_BODY_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').show();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').show();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').show();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_TITLE_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    $('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_TITLE_BODY1));
                    $('#formCount2').val(parseInt($COUNT_TITLE_BODY2));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index5').val());
                    //$('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                    //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_view_descriptionText1').addClass($TITLE_BODY1);

                    $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_view_descriptionText2').addClass($TITLE_BODY2);

                    $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_view_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                /* --------------------------------------------------------  *//* --------------------------------------------------------  */
                else if($('#settings_event_description_view_descriptionType').val()=='BODY'){
                    CONSOLE_MANAGER('event_description_activate','ACTIVE BODY');
                    $('#settings_event_description_view_descriptionType').val('BODY');
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_ACTIVE);
                    $('#settings_event_description_view_options2').removeClass($MENU_STANDARD_DEFAULT);
                    $('#settings_event_description_view_options2').addClass($MENU_STANDARD_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_DEFAULT );
                    $('#settings_event_description_view_options3').removeClass($MENU_TITLE_ACTIVE );
                    $('#settings_event_description_view_options3').addClass($MENU_TITLE_DEFAULT);
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_ACTIVE);
                    $('#settings_event_description_view_options4').removeClass($MENU_BODY_DEFAULT);
                    $('#settings_event_description_view_options4').addClass($MENU_BODY_ACTIVE);
                    /* --------------------------------------------------------  */
                    $('#TEXT1_ROW').show();
                    $('#TEXT2_ROW').hide();
                    $('#TEXT3_ROW').hide();
                    $('#TEXT1_TD').show();
                    $('#TEXT2_TD').hide();
                    $('#TEXT3_TD').hide();
                    $('#FILTER1_ROW').show();
                    $('#FILTER2_ROW').hide();
                    $('#FILTER3_ROW').hide();
                    /* --------------------------------------------------------  */
                    $('#TEXT1_TD').removeClass($TD_STANDARD1);
                    $('#TEXT1_TD').removeClass($TD_TITLE_BODY1);
                    $('#TEXT1_TD').removeClass($TD_BODY1);
                    $('#TEXT1_TD').addClass($TD_BODY1);

                    $('#TEXT2_TD').removeClass($TD_STANDARD2);
                    $('#TEXT2_TD').removeClass($TD_TITLE_BODY2);
                    //$('#TEXT2_TD').addClass($TD_TITLE_BODY2);

                    $('#TEXT3_TD').removeClass($TD_STANDARD3);
                    //$('#TEXT3_TD').addClass($TD_STANDARD3);
                    /* --------------------------------------------------------  */
                    $('#formCount1').val(parseInt($COUNT_BODY1));
                    $('#formCount2').val(parseInt(0));
                    $('#formCount3').val(parseInt(0));
                    /* --------------------------------------------------------  */
                    //$('#settings_event_description_view_descriptionText1').attr('placeholder',$('#settings_event_description_view_index6').val());
                    //$('#settings_event_description_view_descriptionText2').attr('placeholder',$('#settings_event_description_view_index6').val());
                    //$('#settings_event_description_view_descriptionText3').attr('placeholder',$('#settings_event_description_view_index7').val());
                    /* --------------------------------------------------------  */
                    $('#settings_event_description_view_descriptionText1').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText1').removeClass($STANDARD1);
                    $('#settings_event_description_view_descriptionText1').removeClass($TITLE_BODY1);
                    $('#settings_event_description_view_descriptionText1').removeClass($BODY1);
                    $('#settings_event_description_view_descriptionText1').addClass($BODY1);

                    $('#settings_event_description_view_descriptionText2').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText2').removeClass($STANDARD2);
                    $('#settings_event_description_view_descriptionText2').removeClass($TITLE_BODY2);
                    $('#settings_event_description_view_descriptionText2').addClass($HIDDEN);

                    $('#settings_event_description_view_descriptionText3').removeClass($HIDDEN);
                    $('#settings_event_description_view_descriptionText3').removeClass($STANDARD3);
                    $('#settings_event_description_view_descriptionText3').addClass($HIDDEN);
                    /* --------------------------------------------------------  */
                }
                //event_description_count_activate();
            }/*END OF FUNCTION*/
		});/**	END OF $(document).ready(function()*/
		/* ------------------------------------------------- */	
		/* --------------------------------------------------------  */
		window_complete_script('form_event_maincurrence_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	form_event_manager_MGMT                ########################## */
export function form_event_manager_MGMT(){
		$(document).ready(function() {
			CONSOLE_MANAGER('form_event_manager_MGMT','ACTIVATED');
			AUTOBAHNJS_VERSION();
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("event_select_menu_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			event_header_background_activate(null);
			/* --------------------------------------------------------  */
			search_bar_function('form_event_manager_MGMT');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS01ACTIVE = '#form_'+$code+'_'+$i+'_options1active';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS02));
						/* ------------------------------------------------- */
						//$($SEQUENCE).css("background-color",$($COLORSEQUENCE).val());
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						var $SUBMgmt = {GET_UNIQ_ID:$($INDEX02).val(),GET_ITEM_ID:$($INDEX03).val(),SET_STATUS:$($INDEX04).val()};
						$ENTITYLISTMgmt[$i] =$SUBMgmt;
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
						REGISTER_CLICK($($OPTIONS01ACTIVE),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				var conn = new ab.Session('ws://127.0.0.1:8080',
					function($EVENT) {
						$EVENT.stopPropagation;
						var $DATES_obj_array=[];	
						conn.subscribe('SYS_EVENT_MINUTE_STATUS_WEBSOCKET_CRON', function(topic, data) {
							var jsonValidate  = isJSON($('#standard_entity_modify_entitylistMgmt').val());
							if(jsonValidate==true){
								$DATES_obj_array = JSON.parse($('#standard_entity_modify_entitylistMgmt').val());
								if($DATES_obj_array != null){
									/* -------------------------------------------------------- */
									if($DATES_obj_array.length > 0){
										$DATES_obj_array.forEach((element, index, array) => {
											if(element){
												if(typeof element.GET_UNIQ_ID != "undefined"){
													if( element.GET_UNIQ_ID == data.uniqEventId && element.GET_ITEM_ID == data.registerCode){
														var className = $(element.SET_STATUS).attr('class');//	itemdisplayCLASS	itemviewCLASS	activityCLASS	scheduleCLASS
														//item_event_activity_active	item_event_activity_pending	item_event_activity_complete
														//activityCLASS	scheduleCLASS	itemviewCLASS
														$(element.SET_STATUS).removeClass(className);
														$(element.SET_STATUS).addClass(data.itemviewCLASS);
													}
												}
											}
										});
									}/** END IF **/
								}/** END IF **/
							}/** END IF **/
						});
					},
					function() {
						console.warn('WebSocket connection closed');
					},			   
					{'skipSubprotocolCheck': true}		   
				);/*END FUNCTION */
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03){
				$button_obj.unbind().click(function(event) {
                     event.stopPropagation;
						//$('#standard_entity_modify_uniqEventId').val($('#form_'+$code+'_'+$val_code+'_uniqEventId').val());
						//$('#standard_entity_modify_registerCode').val($('#form_'+$code+'_'+$val_code+'_registerCode').val());
						$('#standard_entity_modify_index1').val($button_obj.attr('id'));
						//$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}		
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('form_event_manager_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	form_event_register_MGMT                    ########################## */
export function form_event_register_MGMT(){
	$(document).ready(function(){
        CONSOLE_MANAGER('form_event_register_MGMT','ACTIVATED');
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		if($('#image_editor_main_image_var_load').length){
			EVENT_PHOTO_basics();
			//header_image_select();
		}
		/* ------------------------------------------------- */	
		if($('#settings_event_general_registerDates')){
			VALIDATE_Reg_dates_Submit(null);
		}	
		/* --------------------------------------------------------  */
		event_header_background_activate(null);
		/* --------------------------------------------------------  */
		//Event_File_Upload_Label	event_file_upload_options_file
		SET_BUTTON_CLICK_ACTION("event_file_upload_options1");
		$('#event_file_upload_options1').unbind().click(function(event) {
			event_editor_function('event_file_upload_options1',event);
		});
		/* --------------------------------------------------------  */
		//SET_BUTTON_CLICK_ACTION("settings_event_general_termsSubmit_active");
		//SET_BUTTON_CLICK_ACTION("settings_event_general_termsSubmit_default");
		SET_BUTTON_CLICK_ACTION("settings_event_general_options1");
		SET_BUTTON_CLICK_ACTION("settings_event_general_options2");
		SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
		/* --------------------------------------------------------  */
		if($('#settings_event_general_title').hasClass('event_title_field_error') ) {
			$('#settings_event_general_title').unbind().click( function() {
				$('#settings_event_general_title').removeClass('event_title_field_error');
				$('#settings_event_general_title').addClass('event_title_field');
				//$('#error_log_errorlog4').val('');	settings_event_title_edit_formCurrentIndex
			});
		}
		/* --------------------------------------------------------  */
		SET_BUTTON_CLICK_ACTION("settings_event_dates_options1");
		SET_BUTTON_CLICK_ACTION("settings_event_dates_formValue");
		if($('#settings_event_dates_options1').hasClass('event_set_date_button_temp_error') ) {
			//settings_event_dates_formValue	settings_event_dates_options1	
			$('#settings_event_dates_formValue').unbind().click( function() {
				//$('#settings_event_occurrence_date_control').val($('.Options_create_Activity_UI_class').attr('class'));
				$('#settings_event_dates_options1').removeClass('event_set_date_button_temp_error');
				$('#settings_event_dates_options1').addClass('event_set_date_button_default');
				//$('#error_log_errorlog3').val('');
				//event_set_date_button_active
			});
		}
		
		
		/* --------------------------------------------------------  */
		CONFIRM_MGMT('settings_event_general_options1','.Options_create_Activity_UI_class');
		CONFIRM_MGMT('settings_event_general_options2','.Options_create_Activity_UI_class');
		/* --------------------------------------------------------  */
		$('#settings_event_dates_formValue').unbind().click(function(event) {
			
		});
		$('#settings_event_dates_options1').unbind().click(function(event) {
			$('#standard_entity_modify_control').val('form_event_register_MGMT');
			$('#standard_entity_modify_formCurrentIndex').val(0);
			standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
		
		});
		$('#settings_event_dates_options2').unbind().click(function(event) {
		
		});
		/* ------------------------------------------------- */
		if($('#settings_event_shares_options1')){//settings_event_shares_submit
			SET_BUTTON_CLICK_ACTION("settings_event_shares_options1");
		}
		if($('#settings_event_invites_options1')){//settings_event_invites_submit
			SET_BUTTON_CLICK_ACTION("settings_event_invites_options1");
		}
		if($('#settings_event_followers_options1')){//settings_event_followers_submit
			SET_BUTTON_CLICK_ACTION("settings_event_followers_options1");
		}
		if($('#settings_event_compatibility_options1')){//settings_event_compatibility_submit
			SET_BUTTON_CLICK_ACTION("settings_event_compatibility_options1");
		}
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		var MODIFY_VIEW_LISTENER = (function (){
			var target = document.getElementById('window_view');
			/* --------------------------------------------------------  */	
			var observer = new MutationObserver(function(mutations) {
				mutations.forEach(function(mutation) {
					$(document).ready(function() {
                        CONSOLE_MANAGER('form_event_register_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
						var $RESULTS_CNTRL = null;
						var $SEARCH_CNTRL = null;
						if($('#standard_results_ajax_control').val()){
							$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
							if($('#search_request_control').length){
							   $SEARCH_CNTRL = $('#search_request_control').val();
							}
							if(mutation.type=='attributes'){
								if($("#window_view").is(':visible')){
									/* --------------------------------------------------------  */
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */

									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									/* -------------------------------------------------------- */
									var MODIFY_VIEW_LISTENER = (function (){
										var target = document.getElementById('basic_status');
										/* --------------------------------------------------------  */
										/* --------------------------------------------------------  */	
										var observer = new MutationObserver(function(mutations) {

										  mutations.forEach(function(mutation) {

											if(mutation.type=='attributes'){
												/* --------------------------------------------------------  */

												/* --------------------------------------------------------  */
											}else if(mutation.type=='childList'){
												/* --------------------------------------------------------  */
												if($("#window_view").is(':visible')){
													if($('#search_request_control').length){
														if($SEARCH_CNTRL.includes('Settings_Event_EditType')){
															event_active_pagination('Settings_Event_EditType');
														}
													}
												}
												/* --------------------------------------------------------  */
											} 

										  });    
										});//END new MutationObserver
										var config = { attributes: true, childList: true, characterData: true };
										observer.observe(target, config);
										//observer.disconnect();

									})();/*END FUNCTION */		
									/* -------------------------------------------------------- */
									/**********		CONFIRM_MGMT	**********/
									///settings_event_general_options1
									if($RESULTS_CNTRL.includes('Options_create_Activity_UI_class')){
										var $CONFIRM_CNTRL = $('#settings_sys_confirm_index1').val();
										if($CONFIRM_CNTRL.includes('options1')){
											SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
											$('#settings_sys_confirm_options2').unbind().click(function() {
												$('#'+ $CONFIRM_CNTRL).unbind().click();
											});
											$("#settings_sys_confirm_options3").unbind().click(function(){
												basic_window_close(close_div_var='basic_message_window_stucture');
											});
										}
										if($CONFIRM_CNTRL.includes('options2')){
											SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
											$('#settings_sys_confirm_options2').unbind().click(function() {
												$('#'+ $CONFIRM_CNTRL).unbind().click();
											});
											$("#settings_sys_confirm_options3").unbind().click(function(){
												basic_window_close(close_div_var='basic_message_window_stucture');
											});
										}
									}
									/* -------------------------------------------------------- */
								}
								if($( "#window_view" ).is(':hidden')){
									/* --------------------------------------------------------  */
									//if($("#event_select_menu_options2").is(':visible')){

									//}
									/* --------------------------------------------------------  */

									/* --------------------------------------------------------  */

								}

								/* --------------------------------------------------------  */

									/* --------------------------------------------------------  */
							}else if(mutation.type=='childList'){
								/* --------------------------------------------------------  */
								if($("#window_view").is(':visible')){
									if($('#search_request_control').length){
										if($SEARCH_CNTRL.includes('Settings_Event_EditType')){
											event_active_pagination('Settings_Event_EditType');
										}
									}
								} 
								/* --------------------------------------------------------  */
								//if($("#settings_event_title_edit_formRecList").is(':visible') && 
									//$("#standard_results_ajax_control").val()== 'Settings_Event_TitleEditType'){
								if($("#standard_results_ajax_control").val()== 'Settings_Event_TitleEditType'){
									SET_BUTTON_CLICK_ACTION('search_request_options1');
									/* -------------------------------------------------------- */
									SET_BUTTON_CLICK_ACTION("settings_event_title_edit_options1");
									SET_BUTTON_CLICK_ACTION("settings_event_title_edit_options2");
									$('#settings_event_title_edit_options2').unbind().click(function() {
										$('#settings_event_title_edit_formCurrentIndex').val('');

									});

									text_editor_word_count(
										$('#settings_event_title_edit_formCurrentIndex'),
										$('#settings_event_title_edit_formRecList')
									);

									$('#settings_event_title_edit_formCurrentIndex').keyup(function(event) {
										//if(event.keyCode == 32){
										//	$('#settings_event_title_edit_formCurrentIndex').val($('#settings_event_title_edit_formCurrentIndex').val()+' ');
										//}
										/* --------------------------------------------------------  */
										//var codes = [9,13,32];
										var codes = [32];
										document.getElementById("settings_event_title_edit_formCurrentIndex").addEventListener('keydown', function(e) {
										if(!contains(codes, e.keyCode)) return;
											var path = e.path || e.composedPath()
											if(!path[0]) return;

											e.preventDefault();
											path[0].value += String.fromCharCode(e.keyCode);//
											var $STRING=$('#settings_event_title_edit_formCurrentIndex').val();
											$('#settings_event_title_edit_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
										})
										function contains(arr, item) {
											for(var i=0;i<arr.length;i++)
											if(arr[i]==item)return true;
											return false;
										}
										/* --------------------------------------------------------  */
										var key = String.fromCharCode(event.which);
										if((event.keyCode == $.ui.keyCode.ENTER /*ENTER*/)){
											//return false;
											event.stopPropagation();
											$('#settings_event_title_edit_options1').unbind().click(function(event) {
											});

										}
										if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
										   (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
										   (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
										/*
										(key == 13 /ENTER/)
										(key == 46 /DELETE/)
										(key == 8 /BACKSPACE/)
										*/
											text_editor_word_count(
												$('#settings_event_title_edit_formCurrentIndex'),
												$('#settings_event_title_edit_formRecList')
											);
										}
									});
									$('#settings_event_title_edit_options1').unbind().click(function(event) {
									});  
								}
								/* --------------------------------------------------------  */
								//if($("#settings_event_description_edit_formRecList").is(':visible') && 

								if($("#standard_results_ajax_control").val() == 'Settings_Event_DescriptionType'){

									SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options1");
									SET_BUTTON_CLICK_ACTION("settings_event_description_edit_options2");
									$('#settings_event_description_edit_options2').unbind().click(function() {
										$('#settings_event_description_edit_formCurrentIndex').val('');

									});

									text_editor_word_count(
										$('#settings_event_description_edit_formCurrentIndex'),
										$('#settings_event_description_edit_formRecList')
									);

									$('#settings_event_description_edit_formCurrentIndex').keyup(function(event) {
										var key = String.fromCharCode(event.which);
										//if(event.keyCode == 32){
										//	$('#settings_event_description_edit_formCurrentIndex').val($('#settings_event_description_edit_formCurrentIndex').val()+' ');
										//}
										/* --------------------------------------------------------  */
										//var codes = [9,13,32];
										var codes = [32];
										document.getElementById("settings_event_description_edit_formCurrentIndex").addEventListener('keydown', function(e) {
										if(!contains(codes, e.keyCode)) return;
											var path = e.path || e.composedPath()
											if(!path[0]) return;

											e.preventDefault();
											path[0].value += String.fromCharCode(e.keyCode);//
											var $STRING=$('#settings_event_description_edit_formCurrentIndex').val();
											$('#settings_event_description_edit_formCurrentIndex').val($STRING.replace(/\s{2,}/g, ' '));
										})
										function contains(arr, item) {
											for(var i=0;i<arr.length;i++)
											if(arr[i]==item)return true;
											return false;
										}
										/* --------------------------------------------------------  */
										$('#settings_event_description_edit_options1').unbind().click(function(event){
										});

										if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
										   (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
										   (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
										/*
										(key == 13 /ENTER/)
										(key == 46 /DELETE/)
										(key == 8 /BACKSPACE/)
										*/
											text_editor_word_count(
												$('#settings_event_description_edit_formCurrentIndex'),
												$('#settings_event_description_edit_formRecList')
											);
										}
									});
									$('#settings_event_description_edit_options1').unbind().click(function(event) {
									});
								}
								/* --------------------------------------------------------  Settings_Event_GeneralType*/
								//if($("#search_request_search_parameters").is(':visible') && 

								if($('#search_request_control').length){
									if($SEARCH_CNTRL.includes('Settings_Event_Description_selectionType')){
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										if($("#window_view").is(':visible')){
											SET_BUTTON_CLICK_ACTION('search_request_options1');
											event_data_update_function('Settings_Event_Description_selectionType',null,null,null);
											$('#search_request_options1').unbind().click(function(event) {
												event_data_update_function('Settings_Event_Description_selectionType',null,null,event);
											});
											/* -------------------------------------------------------- */
											$(".Pop_search_Activity_UI_class").submit(function(event){
												event.preventDefault();
												event_data_update_function('Settings_Event_Description_selectionType',null,null,event);
											});
										}
									}
								}
								/* --------------------------------------------------------  */
								if($("#window_view").is(':visible')){
									if($('#search_request_control').length){
										if($SEARCH_CNTRL.includes('Settings_Event_Guidelines')){
											event_active_pagination('Settings_Event_Guidelines');
										}
									}
								}
								/* --------------------------------------------------------  */

								/* --------------------------------------------------------  */

								/* --------------------------------------------------------  */
								//translate_ajax_clear(true);
							} 

						}
					});
				});    
			});//END new MutationObserver
			var config = { attributes: true, childList: true, characterData: true };
			observer.observe(target, config);
			//observer.disconnect();
		})();/*END FUNCTION */
		/* --------------------------------------------------------  */
		/* -------  TYPE EDIT	--------  */
		if($('#settings_event_general_options3')){
			SET_BUTTON_CLICK_ACTION("settings_event_general_options3");
			$('#settings_event_general_options3').unbind().click(function(event) {
				event_entity_type_ajax_function('event_select_menu_event_main',event)
			});
			SET_BUTTON_CLICK_ACTION("settings_event_general_options4");
			$('#settings_event_general_options4').unbind().click(function(event) {
				event_entity_type_ajax_function('event_select_menu_event_main',event)
			});
		}
		/* -------  TITLE EDIT	--------  */
		//SET_BUTTON_CLICK_ACTION("settings_event_general_title");
		//$('#settings_event_general_title').unbind().click(function(event) {
			//event_title_text_editor_function('settings_event_general_title',event)
		//});
		//$('#settings_event_general_options5').unbind().click(function(event) {
			//event_title_text_editor_function('settings_event_general_title',event)
		//});
		/* -------  DESCRIPTION TEXT EDIT	--------  */
		//settings_event_description_formCurrentIndex	
		SET_BUTTON_CLICK_ACTION("settings_event_description_formCurrentIndex");
		$('#settings_event_description_formCurrentIndex').unbind().click(function(event) {
			event_description_text_editor_function('settings_event_description_formCurrentIndex',event)
		});
		/* -------  DESCRIPTION FRAME EDIT	--------  */
		SET_BUTTON_CLICK_ACTION("settings_event_description_options2");
		$('#settings_event_description_options2').unbind().click(function(event) {
			event_description_frame_update_function('settings_event_description_submit',event)
		});
		/* ------------------------------------------------- */	
		for(var i=1; i<3; i++){
			if($('#settings_event_guidelines_profile_options'+i)){
				event_guide_activate('#settings_event_guidelines_profile_options'+i,'#settings_event_guidelines_profile_options2')
			}
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_profile_options'+i);
		}
		/* ------------------------------------------------- */	
		for(var i=1; i<3; i++){
			if($('#settings_event_guidelines_contribution_options'+i)){
				event_guide_activate('#settings_event_guidelines_contribution_options'+i,'#settings_event_guidelines_contribution_options2')
			}
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_contribution_options'+i);
		}
		/* ------------------------------------------------- */	
		for(var i=1; i<3; i++){
			if($('#settings_event_guidelines_sharing_options'+i)){
				event_guide_activate('#settings_event_guidelines_sharing_options'+i,'#settings_event_guidelines_sharing_options2')
			}
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_sharing_options'+i);
		}
		/* ------------------------------------------------- */	
		for(var i=1; i<3; i++){
			if($('#settings_event_guidelines_social1_options'+i)){
				event_guide_activate('#settings_event_guidelines_social1_options'+i,'#settings_event_guidelines_social1_options2')
			}
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_social1_options'+i);
		}
		/* ------------------------------------------------- */	
		for(var i=1; i<3; i++){
			if($('#settings_event_guidelines_social3_options'+i)){
				event_guide_activate('#settings_event_guidelines_social3_options'+i,'#settings_event_guidelines_social3_options2')
			}
			SET_BUTTON_CLICK_ACTION('settings_event_guidelines_social3_options'+i);
		}
		/* --------------------------------------------------------  */
	 });
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_RECOMMENDATIONS_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_RECOMMENDATIONS_SELECT_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();	BUSINESS_recommendation_select_basics
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_RECOMMENDATIONS_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options4').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
				$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
                $('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options4').attr('id'));
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options4'),null,null,null,null);
            }
			/* --------------------------------------------------------  */
            if($('#select_menu_item_options12').is(':visible')) {
                SET_BUTTON_CLICK_ACTION("select_menu_item_options12");
				$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
                $('#standard_entity_modify_index2').val('BUSINESS_TYPE');
                $('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
                $('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
                $('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
                $('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
                if($('#select_menu_item_index3')){
                    $('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
                }else{
                    $('#standard_entity_modify_formEntity').val(null); 
                }
                $('#standard_entity_modify_index10').val($('#select_menu_item_verificationCode').val());
                $('#standard_entity_modify_index7').val( $('#select_menu_item_options12').attr('id'));
                $('#standard_entity_modify_control').val($('#select_menu_item_control').val());
                NOTIFICATIONSYSTEM_LOAD_MGMT($('#select_menu_item_options12'),null,null,null,null);
            }
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if( $('#select_menu_item_verificationCode').val()){
				SET_BUTTON_CLICK_ACTION("select_menu_item_options9");
				$('#select_menu_item_options9').unbind().click(function(event) {
					$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
					$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
					$('#standard_entity_modify_index2').val($('#select_menu_item_uniqEventId').val());
					$('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
					$('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
					$('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
					$('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
					if($('#select_menu_item_index3')){
						$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
					}else{
						$('#standard_entity_modify_formEntity').val(null); 
					}
					$('#standard_entity_modify_index7').val($('#select_menu_item_verificationCode').val());
					$('#standard_entity_modify_index10').val( $('#select_menu_item_options9').attr('id'));
					$('#standard_entity_modify_control').val($('#select_menu_item_control').val());
					//standard_entity_modify_dollarValue
					settings_request_function(event);
				});
				DURATION($('#select_menu_item_formFilter3'),$('#select_menu_item_options6'),$('#select_menu_item_title').val(),$('#select_menu_item_activityStatus').val(),$('#select_menu_item_duration').val());
			}	
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
				$button_obj.unbind().click(function(event) {
                     event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($INDEX04.val());
						$('#standard_entity_modify_index5').val($INDEX05.val());
						$('#standard_entity_modify_index6').val($INDEX06.val());
						if($('#select_menu_item_index3')){
							$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
						}else{
							$('#standard_entity_modify_formEntity').val(null); 
						}
						$('#standard_entity_modify_index7').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#select_menu_item_duration').val()==7){
				$('#select_menu_item_options20').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==14){
				$('#select_menu_item_options21').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==21){
				$('#select_menu_item_options22').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==30){
				$('#select_menu_item_options23').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==90){
				$('#select_menu_item_options24').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==180){
				$('#select_menu_item_options25').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==270){
				$('#select_menu_item_options26').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==360){
				$('#select_menu_item_options27').addClass('recommendation_active');
			}
			if(($('#select_menu_item_duration').val()=='UNLIMITED')||($('#select_menu_item_duration').val()=='FREE')||($('#select_menu_item_duration').val()==0)){
				$('#select_menu_item_options28').addClass('recommendation_active');
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('EVENT_RECOMMENDATIONS_SELECT_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if($('#standard_results_ajax_control').val()){
								$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
								if($('#search_request_control').length){
								   $SEARCH_CNTRL = $('#search_request_control').val();
								}

								if(mutation.type=='attributes'){
									if($("#window_view").is(':visible')){
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/**********		CONFIRM_MGMT	**********/
										if($RESULTS_CNTRL.includes('Interaction_Activity_UI_class')){
											//if($CONFIRM_CNTRL.includes('options2')){
											var close_div_var = null;
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {	
													$('#select_menu_item_options4').unbind().click();
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											//}
										}
										/* -------------------------------------------------------- */
									}
								}else if(mutation.type=='childList'){
									/* --------------------------------------------------------  */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('function: EVENT_RECOMMENDATIONS_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_ITEM_RECOMMENDATIONS_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_ITEM_RECOMMENDATIONS_SELECT_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();	
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_ITEM_RECOMMENDATIONS_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			CONFIRM_MGMT('select_menu_item_options4','Interaction_Activity_UI_class');
			CONFIRM_MGMT('select_menu_item_options12','Interaction_Activity_UI_class');
			/* -------------------------------------------------------- */
			if( $('#select_menu_item_verificationCode').val()){
				SET_BUTTON_CLICK_ACTION("select_menu_item_options9");
				$('#select_menu_item_options9').unbind().click(function(event) {
					$('#standard_entity_modify_title').val($('#select_menu_item_title').val());
					$('#standard_entity_modify_index1').val($('#select_menu_item_profileType').val());
					$('#standard_entity_modify_index2').val($('#select_menu_item_uniqEventId').val());
					$('#standard_entity_modify_index3').val($('#select_menu_item_registerCode').val());
					$('#standard_entity_modify_index4').val($('#select_menu_item_formCurrentIndex').val());
					$('#standard_entity_modify_index5').val($('#select_menu_item_duration').val());
					$('#standard_entity_modify_index6').val($('#select_menu_item_actionDate').val());
					if($('#select_menu_item_index3')){
						$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
					}else{
						$('#standard_entity_modify_formEntity').val(null); 
					}
					$('#standard_entity_modify_index7').val($('#select_menu_item_verificationCode').val());
					$('#standard_entity_modify_index10').val( $('#select_menu_item_options9').attr('id'));
					$('#standard_entity_modify_control').val($('#select_menu_item_control').val());
					settings_request_function(event);
				});
				DURATION($('#select_menu_item_formFilter3'),$('#select_menu_item_options6'),$('#select_menu_item_title').val(),$('#select_menu_item_activityStatus').val(),$('#select_menu_item_duration').val());
			}	
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
				$button_obj.unbind().click(function(event) {
                     event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($INDEX04.val());
						$('#standard_entity_modify_index5').val($INDEX05.val());
						$('#standard_entity_modify_index6').val($INDEX06.val());
						if($('#select_menu_item_index3')){
							$('#standard_entity_modify_formEntity').val($('#select_menu_item_index3').val()); 
						}else{
							$('#standard_entity_modify_formEntity').val(null); 
						}
						$('#standard_entity_modify_index7').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)

                });
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#select_menu_item_duration').val()==7){
				$('#select_menu_item_options20').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==14){
				$('#select_menu_item_options21').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==21){
				$('#select_menu_item_options22').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==30){
				$('#select_menu_item_options23').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==90){
				$('#select_menu_item_options24').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==180){
				$('#select_menu_item_options25').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==270){
				$('#select_menu_item_options26').addClass('recommendation_active');
			}
			if($('#select_menu_item_duration').val()==360){
				$('#select_menu_item_options27').addClass('recommendation_active');
			}
			if(($('#select_menu_item_duration').val()=='UNLIMITED')||($('#select_menu_item_duration').val()=='FREE')||($('#select_menu_item_duration').val()==0)){
				$('#select_menu_item_options28').addClass('recommendation_active');
			}
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MODIFY_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('EVENT_ITEM_RECOMMENDATIONS_SELECT_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if($('#standard_results_ajax_control').val()){
								$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
								if($('#search_request_control').length){
								   $SEARCH_CNTRL = $('#search_request_control').val();
								}

								if(mutation.type=='attributes'){
									if($("#window_view").is(':visible')){
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/* -------------------------------------------------------- */
										/**********		CONFIRM_MGMT	**********/
										if($RESULTS_CNTRL.includes('Interaction_Activity_UI_class')){
											//if($CONFIRM_CNTRL.includes('options2')){
											var close_div_var = null;
												SET_BUTTON_CLICK_ACTION("settings_sys_confirm_options2");
												$('#settings_sys_confirm_options2').unbind().click(function() {	
													$('#select_menu_item_options4').unbind().click();
												});
												$("#settings_sys_confirm_options3").unbind().click(function(){
													basic_window_close(close_div_var='basic_message_window_stucture');
												});
											//}
										}
										/* -------------------------------------------------------- */
									}
								}else if(mutation.type=='childList'){
									/* --------------------------------------------------------  */
								} 
							}
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('function: EVENT_ITEM_RECOMMENDATIONS_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_GUIDELINES_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_GUIDELINES_SELECT_basics','ACTIVATED');
			//AUTOBAHNJS_VERSION();	
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("select_menu_item_options4");
			header_background_activate($('#select_menu_item_file_path'),$('#select_menu_item_options1'));
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_ITEM_RECOMMENDATIONS_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
						var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS07),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX05),$($INDEX06));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX05,$INDEX06){
				$button_obj.unbind().click(function(event) {
                     event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($INDEX04.val());
						$('#standard_entity_modify_index5').val($INDEX05.val());
						$('#standard_entity_modify_index6').val($INDEX06.val());
						$('#standard_entity_modify_index7').val($button_obj.attr('id'));
						$('#standard_entity_modify_control').val($('#page_load_mgmt__control').val());
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_GUIDELINES_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	            EVENT_SHARES_basics                   ########################## */
export function EVENT_LISTVIEW_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_LISTVIEW_basics','ACTIVATED');
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('EVENT_SHARES_basics');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($("#total_objects_count").length){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						var $TITLE = '#form_'+$code+'_'+$i+'_title';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						//var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						header_background_activate($($PATHSEQUENCE),$($OPTIONS01));
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($TITLE),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}
				$('#standard_entity_modify_entitylistMgmt').val(JSON.stringify($ENTITYLISTMgmt));
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
				/* -------------------------------------------------------- */
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
                    $('#standard_entity_modify_title').val($TITLE.val());
                    $('#standard_entity_modify_index1').val($INDEX01.val());
                    $('#standard_entity_modify_index2').val($INDEX02.val());
                    $('#standard_entity_modify_index3').val($INDEX03.val());
                    $('#standard_entity_modify_index4').val($button_obj.val());
					$('#standard_entity_modify_index7').val($INDEX07.val());
                    $('#standard_entity_modify_control').val($INDEX01.val());
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_LISTVIEW_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_COMPATIBILITY_basics(){
		$(document).ready(function() {
            CONSOLE_MANAGER('EVENT_COMPATIBILITY_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			if(document.getElementById("event_manager_menu_index1")){
				for(var $i=1; $i<5; $i++){
					if($('#event_manager_menu_options'+$i).is(':visible')){
						SET_BUTTON_CLICK_ACTION('event_manager_menu_options'+$i);
					}
					if($('#event_manager_menu_options'+$i+'active').is(':visible')){
						SET_BUTTON_CLICK_ACTION('event_manager_menu_options'+$i+'active');
					}	
				}   
			}
			if($('#sys_admin_menu_options1').is(':visible')){
			   SET_BUTTON_CLICK_ACTION('sys_admin_menu_options1');
			}
			if($('#sys_admin_menu_options1active').is(':visible')){
				SET_BUTTON_CLICK_ACTION('sys_admin_menu_options1active');
			}	
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		/* -------------------------------------------------------- */
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_COMPATIBILITY_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_TYPE_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_TYPE_SELECT_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			SETTINGS_PAGINATION_CONTROL();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			//profile_select_options1	profile_select_options2	
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($('#profile_select_options1').is(':visible')){
				for(var $i=1; $i<3; $i++){
					var $SEQUENCE = '#profile_select_options'+$i;
					SELECT_PROFILE_CLICK($($SEQUENCE),$i,$code);
				}
				var $PATHSEQUENCE = '#profile_select_profile_pic_path';
				header_background_activate($($PATHSEQUENCE),$('#profile_select_options1'));
			}
			/* --------------------------------------------------------  */
			event_header_background_activate(null);
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_TYPE_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			var $control_index;
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if($('#control_object').val()==1){	
				$control_index = 'select_profile';
			}
			if($('#control_object').val()==0){	
				$control_index = 'createAction';
			}
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $1SEQUENCE = '#form_'+$code+'_'+$i+'_options1';
						var $2SEQUENCE = '#form_'+$code+'_'+$i+'_options2';
						var $3SEQUENCE = '#form_'+$code+'_'+$i+'_options3';
						var $4SEQUENCE = '#form_'+$code+'_'+$i+'_options4';
						var $5SEQUENCE = '#form_'+$code+'_'+$i+'_options5';
						var $PSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
						if($($PSEQUENCE).val()){
							$($1SEQUENCE).css('background-image','url('+$($PSEQUENCE).val()+')');
						}
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						REGISTER_CLICK($($1SEQUENCE),$i,$code);
						REGISTER_CLICK($($2SEQUENCE),$i,$code);
						REGISTER_CLICK($($3SEQUENCE),$i,$code);
						REGISTER_CLICK($($4SEQUENCE),$i,$code);
						REGISTER_CLICK($($5SEQUENCE),$i,$code);
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
						$('#standard_entity_modify_index1').val($('#form_'+$code+'_'+$val_code+'_index1').val());
						$('#standard_entity_modify_index2').val($('#form_'+$code+'_'+$val_code+'_index2').val());
						$('#standard_entity_modify_control').val($control_index);
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
			}
		});/**	END OF $(document).ready(function()*/
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_TYPE_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_COHOST_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_COHOST_SELECT_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			SETTINGS_PAGINATION_CONTROL();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			event_header_background_activate(null);
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_COHOST_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                        var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                        var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                        var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                        //var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                        var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                        var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                        var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        //var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                        //var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
                        //var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						if($($PATHSEQUENCE).val()){
							$($OPTIONS01).css('background-image','url('+$($PATHSEQUENCE).val()+')');
						}
						/* ------------------------------------------------- */
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($button_obj.val());
						$('#standard_entity_modify_index7').val($INDEX07.val());
						$('#standard_entity_modify_control').val($button_obj.attr('id'));
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function() */
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_COHOST_SELECT_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                               ########################## */
export function EVENT_INVITATIONS_SELECT_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_INVITATIONS_SELECT_basics','ACTIVATED');
			/* --------------------------------------------------------  */
			SETTINGS_PAGINATION_CONTROL();
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* --------------------------------------------------------  */
			event_header_background_activate(null);
			/* --------------------------------------------------------  */
			//search_bar_function('EVENT_INVITATIONS_SELECT_basics');
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){
					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						/* ------------------------------------------------- */
                        var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                        var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
                        var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
                        var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
                        var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
                        var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
                        //var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
                        var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
                        var $PATHSEQUENCE = '#form_'+$code+'_'+$i+'_profile_pic_path';
                        var $TITLE = '#form_'+$code+'_'+$i+'_title';
                        var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                        var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                        var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                        //var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
                        //var $INDEX05 = '#form_'+$code+'_'+$i+'_index5';
                        //var $INDEX06 = '#form_'+$code+'_'+$i+'_index6';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						if($($PATHSEQUENCE).val()){
							$($OPTIONS01).css('background-image','url('+$($PATHSEQUENCE).val()+')');
						}
						/* ------------------------------------------------- */
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
						REGISTER_CLICK($($OPTIONS10),$i,$code,$($TITLE),$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX07));
					}	
				}   
			}
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			function REGISTER_CLICK($button_obj,$val_code,$code,$TITLE,$INDEX01,$INDEX02,$INDEX03,$INDEX07){
				$button_obj.unbind().click(function(event) {
					event.stopPropagation;
						$('#standard_entity_modify_title').val($TITLE.val());
						$('#standard_entity_modify_index1').val($INDEX01.val());
						$('#standard_entity_modify_index2').val($INDEX02.val());
						$('#standard_entity_modify_index3').val($INDEX03.val());
						$('#standard_entity_modify_index4').val($button_obj.val());
						$('#standard_entity_modify_index7').val($INDEX07.val());
						$('#standard_entity_modify_control').val($button_obj.attr('id'));
						standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
				});
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});/**	END OF $(document).ready(function() */
		/* --------------------------------------------------------  */
		window_complete_script('EVENT_INVITATIONS_SELECT_basics');
}
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */
export function event_header_background_activate($EVENT){
    CONSOLE_MANAGER('event_header_background_activate','ACTIVATED');
	if($('#event_file_upload_file_path').val()){
		var path_var = document.getElementById('event_file_upload_file_path').value;
		$('#event_file_upload_theme_1').css('background-image','url('+path_var+')');
	}
    if($('#event_file_segment0_upload_file_path').val()){
		var path_var = document.getElementById('event_file_segment0_upload_file_path').value;
        $('#event_file_segment0_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment1_upload_file_path1').val()){
		var path_var = document.getElementById('event_file_segment1_upload_file_path1').value;
		$('#event_file_segment1_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment2_upload_file_path2').val()){
		var path_var = document.getElementById('event_file_segment2_upload_file_path2').value;
		$('#event_file_segment2_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment3_upload_file_path3').val()){
		var path_var = document.getElementById('event_file_segment3_upload_file_path3').value;
		$('#event_file_segment3_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment4_upload_file_path4').val()){
		var path_var = document.getElementById('event_file_segment4_upload_file_path4').value;
		$('#event_file_segment4_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment5_upload_file_path5').val()){
		var path_var = document.getElementById('event_file_segment5_upload_file_path5').value;
		$('#event_file_segment5_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment6_upload_file_path6').val()){
		var path_var = document.getElementById('event_file_segment6_upload_file_path6').value;
		$('#event_file_segment6_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_file_segment7_upload_file_path7').val()){
		var path_var = document.getElementById('event_file_segment7_upload_file_path7').value;
		$('#event_file_segment7_upload_theme_1').css('background-image','url('+path_var+')');
	}
    if($('#event_file_segment8_upload_file_path8').val()){
		var path_var = document.getElementById('event_file_segment8_upload_file_path8').value;
		$('#event_file_segment8_upload_theme_1').css('background-image','url('+path_var+')');
	}
    if($('#event_file_segment9_upload_file_path9').val()){
		var path_var = document.getElementById('event_file_segment9_upload_file_path9').value;
		$('#event_file_segment9_upload_theme_1').css('background-image','url('+path_var+')');
	}
    if($('#event_file_segment10_upload_file_path10').val()){
		var path_var = document.getElementById('event_file_segment10_upload_file_path10').value;
		$('#event_file_segment10_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#event_swipe_upload_file_path').val()){
		var path_var = document.getElementById('event_swipe_upload_file_path').value+'?'+Math.random();
		$('#event_swipe_upload_theme_1').css('background-image','url('+path_var+')');
	}
    /* --------------------------------------------------------  */
	if($('#event_item_file_upload_file_path').val()){
		var path_var = document.getElementById('event_item_file_upload_file_path').value+'?'+Math.random();
		$('#event_item_file_upload_theme_1').css('background-image','url('+path_var+')');
	}
	if($('#profile_select_profile_pic_path').val()){
		var path_var = document.getElementById('profile_select_profile_pic_path').value+'?'+Math.random();
		$('#profile_select_options1').css('background-image','url('+path_var+')');
	}
    /* --------------------------------------------------------  */
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function event_text_reader_activate($EVENT){
	CONSOLE_MANAGER('event_text_reader_activate','ACTIVATED');
	if($('#settings_event_description_options2').is(':visible')){

	}else{
		SET_BUTTON_CLICK_ACTION("settings_event_description_formCurrentIndex");
		if($('#settings_event_description_formCurrentIndex').length){
			$('#settings_event_description_formCurrentIndex').unbind().click(function($event) {
				$DATA = $('.Description_MGMT_selection_Activity_UI_class');
				$URL = '/system/event/description/modify';
				$DATA_RETURN = control_pop_window_ajax(
					$DATA,
					$URL,
					$event
				);
			});	
		}
	}
}/*END FUNCTION */
/* -------------------------------------------------------- */
export function event_active_pagination(control_var){
	/* ------------------------------------------------- */	
	if($('#total_results_objects_count').val()>0){
		var $length = parseInt($('#total_results_objects_count').val())+1;
	}
	else if($('#total_menu_count').val()>0){
		var $length = parseInt($('#total_menu_count').val())+1;
	}
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	var $code = $("#standard_results_ajax_entityMgmt").val();
	for(var $i=1; $i< $length; $i++){
		var $SEQUENCE = '#form_'+$code+'_'+$i+'_button';
		var $FSEQUENCE = '#form_'+$code+'_'+$i+'_buttonFlare';
		var $LSEQUENCE = '#form_'+$code+'_'+$i+'_buttonLabel';
		var $VSEQUENCE = '#form_'+$code+'_'+$i+'_formValue';

		if($($SEQUENCE).is(':visible')){
			SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_button');
			SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_buttonFlare');
			SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_buttonLabel');
			if($(".Pagination_Shift_UI_class_main").length){
				event_standard_entity_modify_register(
					$($SEQUENCE),
					$($VSEQUENCE)
				);
				event_standard_entity_modify_register(
					$($FSEQUENCE),
					$($VSEQUENCE)
				)
				event_standard_entity_modify_register(
					$($LSEQUENCE),
					$($VSEQUENCE)
				)
			}
		}
		if($("#search_request_control").val() == 'Settings_Event_Description_selectionType'){
			if($(".Pagination_Shift_UI_class_main").length <1){
			search_button_register(
				$($SEQUENCE),
				$($VSEQUENCE)
			);

			search_button_register(
				$($FSEQUENCE),
				$($VSEQUENCE)
			)
			search_button_register(
				$($LSEQUENCE),
				$($VSEQUENCE)
			)
			}
		}
		function search_button_register($button_var,$item_value){
			$button_var.unbind().click(function() {
				$('#search_request_search_parameters').val($item_value.val());
				$( "#search_request_options1" ).unbind().click();
			});
		}
	}
	/* ------------------------------------------------- */
	function event_standard_entity_modify_register($button_var,$item_value){
		$button_var.unbind().click(function(event) {
			$('#standard_entity_modify_control').val($('#search_request_control').val());
			$('#standard_results_ajax_formFilter1').val(' ');
			$('#standard_results_ajax_formFilter2').val(' ');
			$('#standard_results_ajax_formFilter3').val(' ');
			$('#standard_results_ajax_formFilter4').val(' ');

			$('#standard_entity_modify_formReport').val(' ');
			$('#standard_entity_modify_formEntity').val(' ');
			$('#standard_entity_modify_formCurrentIndex').val(' ');
			$('#standard_entity_modify_entityMgmt').val(' ');
			$('#standard_entity_modify_index1').val($item_value.val());
			$('#standard_entity_modify_index2').val(' ');
			$('#standard_entity_modify_index3').val(' ');
			$('#standard_entity_modify_index4').val(' ');
			standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event);
		});
	}
	/* ------------------------------------------------- */		
	for(var i=1; i<6; i++){
		//var option_val = null;
		if(i<6){
			if($('#pagination_ajax_options'+i).is(':visible')){
				option_val = document.getElementById('pagination_ajax_options'+i).value;
			}
			if($('#pagination_ajax_options'+i+'active').is(':visible')){
				//event_pagination_ajax_submit(control_var,'pagination_ajax_options'+i+'active', option_val);
				event_pagination_ajax_submit('pagination_ajax_options'+i+'active');
			}
			if($('#pagination_ajax_options'+i).is(':visible')){
				//event_pagination_ajax_submit(control_var,'pagination_ajax_options'+i, option_val);
				event_pagination_ajax_submit('pagination_ajax_options'+i);
			}
		}
		/* ------------------------------------------------- */		
		//if(i == 1){
			if($('#pagination_ajax_options7active').is(':visible')){
				//event_pagination_ajax_submit(control_var,"pagination_ajax_options7active", +1);	
				event_pagination_ajax_submit("pagination_ajax_options7active");
			}
			if($('#pagination_ajax_options6active').is(':visible')){	
				//event_pagination_ajax_submit(control_var,"pagination_ajax_options6active", -1);	
				event_pagination_ajax_submit("pagination_ajax_options6active");	
			}
		//}
	}
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
	//$('#standard_results_ajax_control').val(null);
	//translate_ajax_clear(true);
}/*END FUNCTION */
/* ------------------------------------------------- */	
export function event_pagination_ajax_submit(button_var){
	CONSOLE_MANAGER('event_text_reader_activate','ACTIVATED'+button_var);
	SET_BUTTON_CLICK_ACTION(button_var);
	$('#'+button_var).off().click(function(event) {
		if(document.getElementById("search_request_formRecList")){
			document.getElementById("search_request_formRecList").value=button_var;
		}
		/* ------------------------------------------------- */
		if($('#search_request_control').val() == 'Settings_Event_EditType'){
			//type_update_function(control_var,option_var,current_var,event);
			//event_data_update_function(control_var,option_var,current_var,event)
			event_data_update_function(event);
		}
		/* ------------------------------------------------- */
		if($('#search_request_control').val() == 'Settings_Event_Description_selectionType'){
			//event_data_update_function(control_var,option_var,current_var,event)
			event_data_update_function(event);
		}
		/* ------------------------------------------------- */
		if($('#search_request_control').val().includes('Settings_Event_Guidelines')){
			event_guide_update_function(event)
		}
	});	
}/*END FUNCTION */
/* -------------------------------------------------------- */
export function event_data_update_function($EVENT){
	var $DATA;
	var $URL;
	form_view = $('#search_request_control').val();
    CONSOLE_MANAGER('event_data_update_function','ACTIVATED: '+form_view);
	/* ------------------------------------------------- */	
	if(form_view.includes('Settings_Event_Description_selectionType')){
		$DATA = $('.Pop_search_Activity_UI_class')
		$URL = '/system/event/description/modify';
	}
	else if(form_view.includes('Settings_Event_EditType')){
		$DATA = $('.Pop_search_Activity_UI_class');
		$URL = '/system/event/configure';
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_search_window_ajax(
		$DATA,
		$URL,
		$EVENT,
		form_view
	);
	if($DATA_RETURN){
	}
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* AJAX LISTENERS  (MutationObserver) ---------------------  */
	var SEARCH_RESULTS_VIEW_LISTENER = (function (){
		var target = document.getElementById('search_results');
		var observer = new MutationObserver(function(mutations) {
			mutations.forEach(function(mutation) {
				$(document).ready(function() {
                    CONSOLE_MANAGER('event_data_update_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
					var $RESULTS_CNTRL = null;
					var $SEARCH_CNTRL = null;
					if($('#standard_results_ajax_control').val()){
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
						if($('#search_request_control').length){
						   $SEARCH_CNTRL = $('#search_request_control').val();
						}

						if(mutation.type=='attributes'){

						}else if(mutation.type=='childList'){

							if($SEARCH_CNTRL.includes('Settings_Event_Description_selectionType')){
								event_active_pagination('Settings_Event_Description_selectionType');	//Settings_Event_GeneralType
								/* ------------------------------------------------- */	
								$('#search_request_search_parameters')
								  .mouseover(function() {
									if($('#search_request_search_parameters').val() != ''){
									//ls_clear_submit_button
									 $('#search_request_clear').removeClass('any_visuallyhidden');
									$('#search_request_clear').addClass('ls_clear_submit_button');
									}
								  })
								  .mouseout(function() {
									 $('#search_request_clear').removeClass('ls_clear_submit_button');
									$('#search_request_clear').addClass('any_visuallyhidden');
								  });


								$('#search_request_clear')

								  .mouseover(function() {
									  if($('#search_request_search_parameters').val() != ''){
									//ls_clear_submit_button
									 $('#search_request_clear').removeClass('any_visuallyhidden');
									$('#search_request_clear').addClass('ls_clear_submit_button');
									  }
								  })
								  .mouseout(function() {
									 $('#search_request_clear').removeClass('ls_clear_submit_button');
									$('#search_request_clear').addClass('any_visuallyhidden');
								  });

								$('#search_request_clear').unbind().click(function() {
									$('#search_request_search_parameters').val('');
									$( "#search_request_options1" ).unbind().click();
								});
								/* -------------------------------------------------------- */ 
							}
							/* -------------------------------------------------------- */
						} 
					}
					//translate_ajax_clear(true);
				});
		  	});    
		});//END new MutationObserver
		var config = { attributes: true, childList: true, characterData: true };
		observer.observe(target, config);
		//observer.disconnect();

	})();/*END FUNCTION */
}/*END FUNCTION */
/* -------------------------------------------------------- */
export function event_guide_update_function($EVENT){
	var $DATA;
	var $URL;
	CONSOLE_MANAGER('event_guide_update_function','ACTIVATED');
	form_view = $('#search_request_control').val();
	if(form_view.includes('Settings_Event_Guidelines')){
		$DATA = $('.Pop_search_Activity_UI_class');
		$URL = '/system/event/guideline';
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_search_window_ajax(
		$DATA,
		$URL,
		$EVENT,
		form_view
	);
	if($DATA_RETURN){
	}	
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* --------------------------------------------------------  */
	/* AJAX LISTENERS  (MutationObserver) ---------------------  */
	var SEARCH_RESULTS_VIEW_LISTENER = (function (){
		var target = document.getElementById('search_results');
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */	
		var observer = new MutationObserver(function(mutations) {
			mutations.forEach(function(mutation) {
				$(document).ready(function() {
					var $RESULTS_CNTRL = null;
					var $SEARCH_CNTRL = null;
                    CONSOLE_MANAGER('event_guide_update_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
					var $RESULTS_CNTRL = $SEARCH_CNTRL = null;
					if($('#standard_results_ajax_control').val()){
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
						if($('#search_request_control').length){
						   $SEARCH_CNTRL = $('#search_request_control').val();
						}
						if(mutation.type=='attributes'){
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */	
						}else if(mutation.type=='childList'){
							/* --------------------------------------------------------  */
							if($("#window_view").is(':visible')){
								if($SEARCH_CNTRL.includes('Settings_Event_Guidelines')){
									event_active_pagination('Settings_Event_Guidelines');
								}
							}
						} 
					}
					//$('#standard_results_ajax_control').val(null);
					//translate_ajax_clear(true);
				});
		  	});    
		});//END new MutationObserver
		var config = { attributes: true, childList: true, characterData: true };
		observer.observe(target, config);
		//observer.disconnect();

	})();/*END FUNCTION */
}/*END FUNCTION */	
/* --------------------------------------------------------  */
export function event_title_text_editor_function(form_entity,$EVENT){
    CONSOLE_MANAGER('event_title_text_editor_function','ACTIVATED');
	var $DATA;
	var $URL;
	if(form_entity.includes('settings_event_general_title')){
		$DATA = $('.Description_MGMT_selection_Activity_UI_class');
		$URL = '/system/event/title/text/editor';
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){

	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function event_description_text_editor_function(form_entity,$EVENT){
    CONSOLE_MANAGER('event_description_text_editor_function','ACTIVATED');
	var $DATA;
	var $URL;
	if(form_entity.includes('settings_event_description_formCurrentIndex')){
		$DATA = $('.Description_MGMT_selection_Activity_UI_class')
		$URL = '/system/event/description/text/editor';
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){

	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function event_description_frame_update_function(form_entity,$EVENT){
    CONSOLE_MANAGER('event_description_frame_update_function','ACTIVATED');
	var $DATA;
	var $URL;
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_description_submit')){
		$DATA = $('.Options_create_Activity_UI_class')
		$URL = '/system/event/description/modify'
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){

	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function event_guide_activate(button_var,edit_var){
	if($(button_var).is(":visible")){
		SET_BUTTON_CLICK_ACTION(button_var);
		$(button_var).unbind().click(function(event) {
			event.stopPropagation();
			event_entity_guideline_ajax_function(button_var,event)
		});
		SET_BUTTON_CLICK_ACTION(edit_var);
		$(edit_var).unbind().click(function(event) {
			event.stopPropagation();
			event_entity_guideline_ajax_function(button_var,event)
		});
	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
/* ------------------------------------------------- */	
export function event_entity_guideline_ajax_function(form_entity,$EVENT){
	CONSOLE_MANAGER('event_entity_guideline_ajax_function','ACTIVATED');
	var $DATA;
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_guidelines_profile_options')){
		$DATA = $('.Guideline_profile_selection_Activity_UI_class');
	}
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_guidelines_contribution_options')){
		$DATA = $('.Guideline_contribution_selection_Activity_UI_class');
	}
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_guidelines_sharing_options')){
		$DATA = $('.Guideline_sharing_selection_Activity_UI_class');
	}
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_guidelines_social1_options')){
		$DATA = $('.Guideline_opt1_selection_Activity_UI_class');
	}
	/* ------------------------------------------------- */	
	if(form_entity.includes('settings_event_guidelines_social3_options')){
		$DATA = $('.Guideline_opt3_selection_Activity_UI_class');
	}
	/* ------------------------------------------------- */
	var $URL = '/system/event/guideline';
	var $DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){

	}
	/* --------------------------------------------------------  */
}/*END FUNCTION */
/* ------------------------------------------------- */	
export function event_entity_type_ajax_function(form_entity,$EVENT){
    CONSOLE_MANAGER('event_entity_type_ajax_function','ACTIVATED');
	var $DATA;
	var $URL;
	if(form_entity=='event_select_menu_event_main'){
		$DATA = $('.Options_create_Activity_UI_class');
		$URL = '/system/event/configure';
	}
	/* ------------------------------------------------- */	
	$DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){

	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
export function event_editor_function(form_entity,$EVENT){
    CONSOLE_MANAGER('event_editor_function','ACTIVATED');
	var $DATA;
	var $URL;
	/* ------------------------------------------------- */	
	if($('#settings_event_general_activityStatus').val()==1){
		$DATA = $('.Image_selection_Activity_UI_class');
		$URL = '/system/event/options';
	}
	/* ------------------------------------------------- */	
	else if($('#settings_event_multi_activityStatus').val()==1){
		$DATA = $('.Options_create_Activity_UI_class');
		$URL = '/system/event/options';
	}
	/* ------------------------------------------------- */
	else if($('#settings_event_general_activityStatus').val()!=1){
		$DATA = $('.Image_selection_Activity_UI_class');
		$URL = '/system/event/update';
	}
	/* ------------------------------------------------- */	
	var $DATA_RETURN = control_pop_window_ajax(
		$DATA,
		$URL,
		$EVENT
	);
	if($DATA_RETURN){
		var STAT_ARRAY_MGMT = new Array();
		var STAT_PATH_MGMT = new Array();
	}
}/*END FUNCTION */
/* --------------------------------------------------------  */
/* --------------------------------------------------------  */