/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/ 
/*  event_item_instance_function
/*  EVENT_ITEM_CHECKIN_basics
/*  
/*  ITEM_SETTINGS_basics_MGMT
/*	EVENT_ITEM_LOCATION_basics
/*  
/*  ITEM_ITINERARY_create_MGMT
/*  ITEM_ITINERARY_basics_MGMT
/*
/*  holiday_item_instance_function
/*  HOLIDAY_ITEM_SETTINGS_basics_MGMT
/*  HOLIDAY_ITEM_LOCATION_basics
/* 
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	                    ########################## */
import * as $ from 'jquery';
import * as jQuery from 'jquery';

/********************/
import * as spectrum from 'spectrum-colorpicker2';
/********************/
import Cropper from 'cropperjs';

import { Moment } from 'moment'
let moment = require('moment');
const m = moment(); 
/********************/
import { GeocodingApi, Configuration } from '@stadiamaps/api';
/********************/
import { CONSOLE_MANAGER as CONSOLE_MANAGER } from "./mgmt_control.js";
import { REFRESH_CLEAR_MANAGER as REFRESH_CLEAR_MANAGER } from "./mgmt_control.js";
import { SET_BUTTON_CLICK_ACTION as SET_BUTTON_CLICK_ACTION } from "./mgmt_control.js";
import { window_complete_script as window_complete_script } from "./mgmt_control.js";
import { CONFIRM_MGMT as CONFIRM_MGMT } from "./mgmt_control.js";
import { PHOTOSYSTEM_LOAD_MGMT as PHOTOSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { PHOTOBUCKET_LOAD_MGMT as PHOTOBUCKET_LOAD_MGMT } from "./mgmt_control.js";

import { control_pop_window_no_ajax as control_pop_window_no_ajax } from "./mgmt_control.js";
import { basic_window_open as basic_window_open } from "./mgmt_control.js";
import { basic_window_close as basic_window_close } from "./mgmt_control.js";
import { schedule_window_open as schedule_window_open } from "./mgmt_control.js";
import { starttime_window_open as starttime_window_open } from "./mgmt_control.js";
import { schedule_window_close as schedule_window_close } from "./mgmt_control.js";
import { windowload_script as windowload_script } from "./mgmt_control.js";
import { AUTOBAHNJS_VERSION as AUTOBAHNJS_VERSION } from "./mgmt_control.js";
import { search_bar_function as search_bar_function } from "./mgmt_control.js";
import { initiate_datetime_picker as initiate_datetime_picker } from "./mgmt_control.js";
import { initiate_datetime_creator as initiate_datetime_creator } from "./mgmt_control.js";
import { initiate_starttime_creator as initiate_starttime_creator } from "./mgmt_control.js";
import { parseTime as parseTime } from "./mgmt_control.js";
import { event_title_validation_MGMT as event_title_validation_MGMT } from "./mgmt_control.js";
import { SETTINGS_PAGINATION_CONTROL as SETTINGS_PAGINATION_CONTROL } from "./mgmt_control.js";
import { PHOTOBUCKET_UPDATE as PHOTOBUCKET_UPDATE } from "./mgmt_control.js";
import { FLIP_SWITCH_SETVAL_STATUS as FLIP_SWITCH_SETVAL_STATUS } from "./mgmt_control.js";
import { FLIP_SWITCH_OPTION_STATUS as FLIP_SWITCH_OPTION_STATUS } from "./mgmt_control.js";

import { header_background_activate as header_background_activate } from "./mgmt_control.js";
import { multifunction_map_activate as multifunction_map_activate } from "./mgmt_mapping.js";
import { control_pop_window_ajax as control_pop_window_ajax } from "./mgmt_control.js";
import { MULTI_SELECT_OPTION_FORM as MULTI_SELECT_OPTION_FORM } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM as ACTIVATE_UPLOAD_FORM } from "./mgmt_control.js";
import { polling_ajax_upload_size as polling_ajax_upload_size } from "./mgmt_control.js";
import { text_editor_word_count as text_editor_word_count } from "./mgmt_control.js";
import { standard_entity_modify_submit_MGMT as standard_entity_modify_submit_MGMT } from "./mgmt_control.js";
import { translate_ajax_clear as translate_ajax_clear } from "./mgmt_control.js";
import { SELECT_PROFILE_CLICK as SELECT_PROFILE_CLICK } from "./mgmt_control.js";
import { ACTIVATE_UPLOAD_FORM_INIT as ACTIVATE_UPLOAD_FORM_INIT } from "./mgmt_control.js";
import { isJSON as isJSON } from "./mgmt_control.js";
import { MGMT_google_autocomplete as MGMT_google_autocomplete } from "./mgmt_mapping.js";
import { event_create_map_activate as event_create_map_activate } from "./mgmt_mapping.js";
import { geocode as geocode } from "./mgmt_mapping.js";
import { event_map_activate as event_map_activate } from "./mgmt_mapping.js";
import { micro_map_activate as micro_map_activate } from "./mgmt_mapping.js";
import { event_item_map_activate as event_item_map_activate} from "./mgmt_mapping.js";
import { map_identity_event_control as map_identity_event_control} from "./mgmt_mapping.js";

import { EVENT_PHOTO_basics as EVENT_PHOTO_basics } from "./mgmt_control.js";

import { control_update_noview_ajax as control_update_noview_ajax } from "./mgmt_control.js";
import { sys_email_match_check as sys_email_match_check } from "./mgmt_control.js";
import { DURATION as DURATION } from "./mgmt_control.js";
import { duration as duration } from "./mgmt_control.js";
import { micro_duration as micro_duration } from "./mgmt_control.js";

import { ITEM_DURATION as ITEM_DURATION } from "./mgmt_control.js";
import { settings_request_function as settings_request_function } from "./mgmt_control.js";
import { NOTIFICATIONSYSTEM_LOAD_MGMT as NOTIFICATIONSYSTEM_LOAD_MGMT } from "./mgmt_control.js";
import { SETTINGS_METRICS_CONTROL as SETTINGS_METRICS_CONTROL } from "./mgmt_control.js";
import { text_to_field as text_to_field } from "./mgmt_control.js";
import { wordCount as wordCount } from "./mgmt_control.js";


//map		location_search_request_search_parameters	$CONFIRM_CNTRL
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function event_item_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('event_item_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('event_item_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('event_item_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('event_item_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
	/* ------------------------------------------------- */
    if(form_entity.includes('event_activity_interaction_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/account/update';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_item_settings_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/description/modify';
    }
    /* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('holiday_event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/description/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/description/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_itinerary_creator_control')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/event/schedule/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index1').val($('#image_creator_name').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());
		/*-------------------------------------------------------- */
		if(document.getElementById("image_creator_formFilter2")){
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/upload';
			}
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_PHOTOSYSTEM_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/photosystem/upload';
			}
		}else{
			var $DATA = $('.Interaction_Activity_UI_class');
        	var $URL = '/entity/file/upload';
		}
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){
    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* --------------------------------------------------------  */
export function holiday_item_instance_function(form_entity,select_val,select_button,$EVENT){
    CONSOLE_MANAGER('holiday_item_instance_function',select_button);
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* --------------------------------------------------------  */
    /* AJAX LISTENERS  (MutationObserver) ---------------------  */
    var MODIFY_VIEW_LISTENER = (function (){
        var target = document.getElementById('standard_results_ajax_control');
        /* --------------------------------------------------------  */
        /* --------------------------------------------------------  */	
        var observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                $(document).ready(function() {
                    if($("#standard_results_ajax_control").val()){  
						var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
						$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
                        CONSOLE_MANAGER('holiday_item_instance_function','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                        if(mutation.type=='attributes'){
                            CONSOLE_MANAGER('holiday_item_instance_function',mutation.type+': attributes');
                            /* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
							/* -------------------------------------------------------- */
                            /* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_imageUploadActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_catelogActive').attr('id')){
							   
							}
							/* -------------------------------------------------------- */
							if($RESULTS_CNTRL == $('#event_creator_streamActive').attr('id')){
							   
							}
                            /* -------------------------------------------------------- */
                            $("#standard_results_ajax_control").val('');

                        }else if(mutation.type=='childList'){
                            CONSOLE_MANAGER('holiday_item_instance_function',mutation.type+': childList');
                            /* -------------------------------------------------------- */

                        }
                    }
                });
            });    
        });//END new MutationObserver
        var config = { attributes: true, childList: true, characterData: true };
        observer.observe(target, config);
        //observer.disconnect();

    })();/*END FUNCTION */
    /* --------------------------------------------------------  */
    $('#standard_entity_modify_formCurrentIndex').val(select_button);
	/* ------------------------------------------------- */
    if(form_entity.includes('event_activity_interaction_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/account/update';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_item_settings_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/description/modify';
    }
    /* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('holiday_event_settings_menu_options')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/description/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if(form_entity.includes('event_itinerary_creator_control')){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        var $DATA = $('.Interaction_Activity_UI_class');
        var $URL = '/system/holiday/event/schedule/modify';
    }
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */
    if((form_entity.includes('image_activity_upload_options'))||(form_entity.includes('image_activity_interaction_options'))){
    	$('#standard_entity_modify_control').val(select_val);
    	$('#standard_entity_modify_formReport').val(select_button);
        $('#standard_entity_modify_uniqEventId').val($('#image_creator_uniqEventId').val());
		$('#standard_entity_modify_registerCode').val($('#image_creator_registerCode').val());
		$('#standard_entity_modify_index1').val($('#image_creator_name').val());
		$('#standard_entity_modify_index4').val($('#image_creator_control').val());
		/*-------------------------------------------------------- */
		if(document.getElementById("image_creator_formFilter2")){
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/upload';
			}
			/*-------------------------------------------------------- */
			if($('#image_creator_formFilter2').val()=='EVENT_PHOTOSYSTEM_TYPE'){
				var $DATA = $('.Interaction_Activity_UI_class');
				var $URL = '/entity/file/photosystem/upload';
			}
		}else{
			var $DATA = $('.Interaction_Activity_UI_class');
        	var $URL = '/entity/file/upload';
		}
    }
	/* ------------------------------------------------- */
    /* ------------------------------------------------- */	
    var $DATA_RETURN = control_update_noview_ajax(
        $DATA,
        $URL,
        $EVENT
    );
    if($DATA_RETURN){

    }/* END DATA RETURN */
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_ITEM_CHECKIN_basics                   ########################## */
export function EVENT_ITEM_CHECKIN_basics(){
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_ITEM_CHECKIN_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			event_item_map_activate();
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			search_bar_function('EVENT_ITEM_CHECKIN_basics');
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(parseInt($("#event_checkin_menu_formFilter3").val())==parseInt(1)){
				$('#event_checkin_menu_options2').prop("disabled",true);
                $('#event_checkin_menu_options3').prop("disabled",true);
                $('#event_checkin_menu_options5').prop("disabled",true);
                $('#event_checkin_menu_options6').prop("disabled",true);
				$('#event_checkin_menu_options7').prop("disabled",true);
                $('#event_checkin_menu_options8').prop("disabled",true);
                $('#event_checkin_menu_options9').prop("disabled",true);
                $('#event_checkin_menu_options10').prop("disabled",true);
				$('#event_checkin_menu_options11').prop("disabled",true);
                $('#event_checkin_menu_options12').prop("disabled",true);
				$('#event_checkin_menu_options2active').prop("disabled",true);
                $('#event_checkin_menu_options3active').prop("disabled",true);
                $('#event_checkin_menu_options4active').prop("disabled",true);
                $('#event_checkin_menu_options5active').prop("disabled",true);
				$('#event_checkin_menu_options6active').prop("disabled",true);
                $('#event_checkin_menu_options7active').prop("disabled",true);
				$('#event_checkin_menu_options8active').prop("disabled",true);
                $('#event_checkin_menu_options20').prop("disabled",true);
				$('#event_checkin_menu_options20active').prop("disabled",true);
				$('#event_checkin_menu_options21').prop("disabled",true);
				$('#event_checkin_menu_options21active').prop("disabled",true);
				$('#event_checkin_menu_options22').prop("disabled",true);
				$('#event_checkin_menu_options22active').prop("disabled",true);
			}else{
				if(document.getElementById("event_checkin_menu_options2")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options2");
					//NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_checkin_menu_options2'),null,null,null,null);
				}
				if(document.getElementById("event_checkin_menu_options3")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options3");
					//NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_checkin_menu_options3'),null,null,null,null);
				}
				if(document.getElementById("event_checkin_menu_options5")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options5");
					//NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_checkin_menu_options5'),null,null,null,null);
				}
				if(document.getElementById("event_checkin_menu_options6")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options6");
				}
				if(document.getElementById("event_checkin_menu_options7")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options7");
				}
				if(document.getElementById("event_checkin_menu_options8")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options8");
				}
				if(document.getElementById("event_checkin_menu_options9")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options9");
				}
				if(document.getElementById("event_checkin_menu_options10")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options10");
				}
				if(document.getElementById("event_checkin_menu_options11")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options11");
				}
				if(document.getElementById("event_checkin_menu_options12")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options12");
				}
				/* --------------------------------------------------------  */
				if(document.getElementById("event_checkin_menu_options2active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options2active");
				}
				if(document.getElementById("event_checkin_menu_options3active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options3active");
				}
				if(document.getElementById("event_checkin_menu_options4active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options4active");
				}
				if(document.getElementById("event_checkin_menu_options5active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options5active");
				}
				if(document.getElementById("event_checkin_menu_options6active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options6active");
				}
				if(document.getElementById("event_checkin_menu_options7active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options7active");
				}
				if(document.getElementById("event_checkin_menu_options8active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options8active");
				}
				/* --------------------------------------------------------  */
				if(document.getElementById("event_checkin_menu_options20")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options20");
				}
				if(document.getElementById("event_checkin_menu_options21")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options21");
				}
				if(document.getElementById("event_checkin_menu_options22")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options22");
				}
				if(document.getElementById("event_checkin_menu_options20active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options20active");
				}
				if(document.getElementById("event_checkin_menu_options21active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options21active");
				}
				if(document.getElementById("event_checkin_menu_options22active")){
					SET_BUTTON_CLICK_ACTION("event_checkin_menu_options22active");
				}
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var MULTI_REGISTER_DATE_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('EVENT_ITEM_CHECKIN_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							if(mutation.type=='attributes'){
								if($("#window_view").is(':visible')){
									if($("#standard_results_ajax_control").val()){
										var $RESULTS_CNTRL = $SEARCH_CNTRL = null;
										$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
										if($('#search_request_control').length){
										   $SEARCH_CNTRL = $('#search_request_control').val();
										}
										/* -------------------------------------------------------- */
										if(mutation.type=='attributes'){
											if(($('#standard_results_ajax_control').val())){
												CONSOLE_MANAGER('EVENT_ITEM_CHECKIN_basics',mutation.type+': attributes');
												if($RESULTS_CNTRL.includes('Filter_FormType')){
													Filter_FormType_init(null);
												}
											}
										}else if(mutation.type=='childList'){
											CONSOLE_MANAGER('EVENT_ITEM_CHECKIN_basics',mutation.type+': childList');
											/* -------------------------------------------------------- */

										}
									}

									/* -------------------------------------------------------- */
								}
								if($( "#window_view" ).is(':hidden')){
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
								}
							}else if(mutation.type=='childList'){
								/* --------------------------------------------------------  */

							} 
							translate_ajax_clear(true);
							$RESULTS_CNTRL = $SEARCH_CNTRL = null;
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_ITEM_CHECKIN_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ITEM_SETTINGS_basics_MGMT                   ########################## */
export function ITEM_SETTINGS_basics_MGMT(){
		$(document).ready(function() {
			CONSOLE_MANAGER('ITEM_SETTINGS_basics_MGMT','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			$('.Listview_options_Activity_UI_class').attr("id", 'Event_Settings_Menu_UI_control');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			if(parseInt($("#event_settings_menu_formFilter11").val())==parseInt(1)){
				$('#event_settings_menu_options2').prop("disabled",true);
                $('#event_settings_menu_options3').prop("disabled",true);
                $('#event_settings_menu_options5').prop("disabled",true);
                $('#event_settings_menu_options6').prop("disabled",true);
				$('#event_settings_menu_options7').prop("disabled",true);
				$('#event_settings_menu_options7active').prop("disabled",true);
                //$('#event_settings_menu_options8').prop("disabled",true);
                //$('#event_settings_menu_options9').prop("disabled",true);
                $('#event_settings_menu_options10').prop("disabled",true);
				$('#event_settings_menu_options11').prop("disabled",true);
				$('#event_settings_menu_options11active').prop("disabled",true);
                //$('#event_settings_menu_options12').prop("disabled",true);
				$('#event_settings_menu_options2active').prop("disabled",true);
                $('#event_settings_menu_options3active').prop("disabled",true);
                $('#event_settings_menu_options4active').prop("disabled",true);
                $('#event_settings_menu_options5active').prop("disabled",true);
				//$('#event_settings_menu_options6active').prop("disabled",true);
                //$('#event_settings_menu_options7active').prop("disabled",true);
				//$('#event_settings_menu_options8active').prop("disabled",true);
				$('#event_settings_menu_options12').prop("disabled",true);
				$('#event_settings_menu_options12active').prop("disabled",true);
				$('#event_settings_menu_options13').prop("disabled",true);
				$('#event_settings_menu_options13active').prop("disabled",true);
				$('#event_settings_menu_options14').prop("disabled",true);
				$('#event_settings_menu_options14active').prop("disabled",true);
				$('#event_settings_menu_options15').prop("disabled",true);
				$('#event_settings_menu_options15active').prop("disabled",true);
			}else{
				if(document.getElementById("event_settings_menu_options2")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options2");
				}
				if(document.getElementById("event_settings_menu_options3")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options3");
				}
				if(document.getElementById("event_settings_menu_options4")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options4");
				}
				if(document.getElementById("event_settings_menu_options5")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options5");
				}
				if(document.getElementById("event_settings_menu_options6")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options6");
				}
				if(document.getElementById("event_settings_menu_options7")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options7");
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if($("#event_settings_menu_control").val()=='PARENT'){
					$('#event_settings_menu_options7active').prop("disabled",true);
					$('#event_settings_menu_options11active').prop("disabled",true);
				}else{
					/* --------------------------------------------------------  */
					if($("#event_settings_menu_formFilter6").val()==true){
						$('#event_settings_menu_options7').prop("disabled",true);
					}else{
						if(document.getElementById("event_settings_menu_options7active")){
							SET_BUTTON_CLICK_ACTION("event_settings_menu_options7active");
						}
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options8'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options8').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options8active'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options8').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options9'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options9').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options9active'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options9').attr('id'));
					}
					/* --------------------------------------------------------  */
					if($("#event_settings_menu_formFilter7").val()==true){
						$('#event_settings_menu_options11').prop("disabled",true);
					}else{
						if(document.getElementById("event_settings_menu_options11active")){
							SET_BUTTON_CLICK_ACTION("event_settings_menu_options11active");
						}
					}
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if(document.getElementById("event_settings_menu_options10")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options10");
				}
				if(document.getElementById("event_settings_menu_options11")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options11");
				}
				if(document.getElementById("event_settings_menu_options12")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options12");
				}
				if(document.getElementById("event_settings_menu_options12active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options12active");
				}
				if(document.getElementById("event_settings_menu_options13")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options13");
				}
				if(document.getElementById("event_settings_menu_options13active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options13active");
				}
				if(document.getElementById("event_settings_menu_options14")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options14");
				}
				if(document.getElementById("event_settings_menu_options14active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options14active");
				}
				if(document.getElementById("event_settings_menu_options15")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options15");
				}
				if(document.getElementById("event_settings_menu_options15active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options15active");
				}
				/* --------------------------------------------------------  */
				if(document.getElementById("event_settings_menu_options2active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options2active");
				}
				if(document.getElementById("event_settings_menu_options3active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options3active");
				}
				if(document.getElementById("event_settings_menu_options4active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options4active");
				}
				if(document.getElementById("event_settings_menu_options5active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options5active");
				}
				/* --------------------------------------------------------  */
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('ITEM_SETTINGS_basics_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('ITEM_SETTINGS_basics_MGMT',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('ITEM_SETTINGS_basics_MGMT',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									remote_data_load_submit($('#Event_Settings_Menu_UI_control'));
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						//var $2SEQUENCE = '#form_'+$code+'_'+$i+'_options2';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						//var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						//var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01));
						if($($INDEX01).val()){
							if($('#event_settings_menu_index16').val()){
								var jsonValidate  = isJSON($('#event_settings_menu_index16').val());
								if(jsonValidate==true){
									var $DATES_obj_array = JSON.parse($('#event_settings_menu_index16').val());
									if ($.inArray($($INDEX01).val(), $DATES_obj_array)>=0){
										update_class($($OPTIONS01))
									}else{
    
									}
								}
							}
						}
					}	
				}   
			}
			/* -------------------------------------------------------- */
			function update_class($button_obj){
				var $ACTIVE = 'DISABLE';
				if($button_obj.hasClass("event_identity_result_label_active")){
					$button_obj.addClass('event_identity_result_label').removeClass('event_identity_result_label_active');
				}else{
					$button_obj.addClass('event_identity_result_label_active').removeClass('event_identity_result_label');
					$ACTIVE = 'ACTIVE';
				}
				return $ACTIVE;
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01){
				$button_obj.unbind().click(function(event) {
					var $STATUS = update_class($button_obj);
					$('#standard_entity_modify_formFilter1').val($STATUS);
					$('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_formCurrentIndex').val($INDEX01.val());
                    $('#standard_entity_modify_uniqEventId').val($('#event_settings_menu_uniqEventId').val());
                    $('#standard_entity_modify_registerCode').val($('#event_settings_menu_registerCode').val());
					event_item_instance_function('event_settings_menu_options', $('#event_settings_menu_options1').attr('id'),$INDEX01.val(),event);//event_instance_function
                });
			}		
			/* --------------------------------------------------------  */
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('ITEM_SETTINGS_basics_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ITEM_ITINERARY_basics_MGMT                  ########################## */
export function ITEM_ITINERARY_basics_MGMT(){
		/* -------------------------------------------------------- */
		$(document).ready(function() {
			CONSOLE_MANAGER('ITEM_ITINERARY_basics_MGMT','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			$('.Listview_options_Activity_UI_class').attr("id", 'Event_Itinerary_Menu_UI_control');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			header_background_activate($('#event_itinerary_menu_index3'),$('#event_itinerary_menu_options3'));
			/* --------------------------------------------------------  */
			if(parseInt($("#event_itinerary_menu_formFilter1").val())==parseInt(1)){
				$('#event_itinerary_menu_options1').prop("disabled",true);
				$('#event_itinerary_menu_options2').prop("disabled",true);
			}else{
				if(($("#event_itinerary_menu_formFilter2").val())){
					if(document.getElementById("event_itinerary_menu_options1")){
						SET_BUTTON_CLICK_ACTION("event_itinerary_menu_options1");
					}
					if(document.getElementById("event_itinerary_menu_options2")){
						SET_BUTTON_CLICK_ACTION("event_itinerary_menu_options2");
					}
				}else{
					$('#event_itinerary_menu_options1').prop("disabled",true);
					$('#event_itinerary_menu_options2').prop("disabled",true);
				}
			}
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("event_itinerary_creator_options26")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options26");
			}
			if(document.getElementById("event_itinerary_creator_options27")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options27");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('ITEM_ITINERARY_basics_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('ITEM_ITINERARY_basics_MGMT',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('ITEM_ITINERARY_basics_MGMT',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									remote_data_load_submit($('#Event_Settings_Menu_UI_control'));
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						var $OPTIONS02 = '#form_'+$code+'_'+$i+'_options2';
						var $OPTIONS03 = '#form_'+$code+'_'+$i+'_options3';
						var $OPTIONS04 = '#form_'+$code+'_'+$i+'_options4';
						var $OPTIONS05 = '#form_'+$code+'_'+$i+'_options5';
						var $OPTIONS06 = '#form_'+$code+'_'+$i+'_options6';
						
						var $OPTIONS07 = '#form_'+$code+'_'+$i+'_options7';
						var $OPTIONS08 = '#form_'+$code+'_'+$i+'_options8';
						var $OPTIONS09 = '#form_'+$code+'_'+$i+'_options9';
						var $OPTIONS10 = '#form_'+$code+'_'+$i+'_options10';
						var $OPTIONS11 = '#form_'+$code+'_'+$i+'_options11';
						var $OPTIONS12 = '#form_'+$code+'_'+$i+'_options12';
						
						var $OPTIONS13 = '#form_'+$code+'_'+$i+'_options13';
						var $OPTIONS14 = '#form_'+$code+'_'+$i+'_options14';
						var $OPTIONS15ACTIVE = '#form_'+$code+'_'+$i+'_options15active';
						var $OPTIONS15 = '#form_'+$code+'_'+$i+'_options15';
						var $OPTIONS16ACTIVE = '#form_'+$code+'_'+$i+'_options16active';
						var $OPTIONS16 = '#form_'+$code+'_'+$i+'_options16';
						var $OPTIONS17 = '#form_'+$code+'_'+$i+'_options17';
						var $OPTIONS18 = '#form_'+$code+'_'+$i+'_options18';
						
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						var $INDEX04 = '#form_'+$code+'_'+$i+'_index4';
						var $INDEX07 = '#form_'+$code+'_'+$i+'_index7';
						/* ------------------------------------------------- */
						/* --------------------------------------------------------  */
						if($($OPTIONS03).val()){
							
							var tempDate = ($($OPTIONS03).val()) ? moment($($OPTIONS03).val(),'YYYY-MM-DDTHH:mm:ssZZ').utc().local().format() :  moment([]).utc().local().format();
							var tempTime = ($($OPTIONS04).val()) ? moment($($OPTIONS04).val(), 'YYYY-MM-DDTHH:mm:ssZZ').utc().local().format() :  moment([]).utc().local().format();
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */	
							/* --------------------------------------------------------  */
							if((moment(tempDate).isValid())&&(moment(tempTime).isValid())){
								var saveDate = moment(tempDate).format('YYYY-MM-DD');
								var saveTime = moment(tempTime).format('HH:mm:ssZZ');
								var temp = saveDate+'T'+saveTime;
								var temp = moment(temp).utc().local().format();
								$($OPTIONS03).html(moment(tempTime).format('MMMM DD YYYY'));
								$($OPTIONS04).html(moment(tempTime).format('LT'));
							}else if((moment(tempDate).isValid())&&(moment(tempTime).isValid()==false)){
								var saveDate = moment(tempDate).format('YYYY-MM-DD');
								var saveTime = moment(tempDate).format('HH:mm:ssZZ');
								var temp = saveDate+'T'+saveTime;
								var temp = moment(temp).utc().local().format();
								$($OPTIONS03).html(moment(tempTime).format('MMMM DD YYYY'));
								$($OPTIONS04).html(moment(tempTime).format('LT'));
							}else if((moment(tempDate).isValid()==false)&&(moment(tempTime).isValid())){
								var saveDate = moment(tempTime).format('YYYY-MM-DD');
								var saveTime = moment(tempTime).format('HH:mm:ssZZ');
								var temp = saveDate+'T'+saveTime;
								var temp = moment(temp).utc().local().format();
								$($OPTIONS03).html(moment(tempTime).format('MMMM DD YYYY'));
								$($OPTIONS04).html(moment(tempTime).format('LT'));
							}
							micro_duration($($OPTIONS03),$($OPTIONS05));
							/* --------------------------------------------------------  */
							/* --------------------------------------------------------  */
						}
						/* ------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options2');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options3');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options4');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options5');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options6');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options7');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options8');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options9');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options10');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options11');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options12');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options13');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options14');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options15active');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options15');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options16active');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options16');
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options17');
                        SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options18');
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS02),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS03),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS04),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS05),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS06),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));

						REGISTER_CLICK($($OPTIONS07),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS08),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS09),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS10),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS11),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS12),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));

						REGISTER_CLICK($($OPTIONS13),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS14),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS15ACTIVE),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS15),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS16ACTIVE),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS16),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS17),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
						REGISTER_CLICK($($OPTIONS18),$i,$code,$($INDEX01),$($INDEX02),$($INDEX03),$($INDEX04),$($INDEX07));
					}	
				}   
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01,$INDEX02,$INDEX03,$INDEX04,$INDEX07){
				$button_obj.unbind().click(function(event) {
					$('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_index2').val($INDEX02.val());
					$('#standard_entity_modify_index3').val($INDEX03.val());
					$('#standard_entity_modify_index4').val($INDEX04.val());
					$('#standard_entity_modify_index7').val($INDEX07.val());
					$('#standard_entity_modify_control').val($button_obj.attr('id'));
                    standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event)
                });
			}		
			/* --------------------------------------------------------  */
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('ITEM_ITINERARY_basics_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ITEM_ITINERARY_create_MGMT                   ########################## */
export function ITEM_ITINERARY_create_MGMT(){
		/* -------------------------------------------------------- */
		$(document).ready(function() {
			CONSOLE_MANAGER('ITEM_ITINERARY_create_MGMT','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			$('.Listview_options_Activity_UI_class').attr("id", 'Event_Settings_Menu_UI_control');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			if(document.getElementById("event_itinerary_creator_options1")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options1");
			}
			if(document.getElementById("event_itinerary_creator_options2")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options2");
			}
            /* --------------------------------------------------------  */
            if(document.getElementById("event_itinerary_creator_options26")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options26");
			}
			if(document.getElementById("event_itinerary_creator_options27")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options27");
			}
			/* --------------------------------------------------------  */
            /* -------------------------------------------------------- blur */
            $('#event_itinerary_creator_options3').unbind().click(function(event) {
                initiate_starttime_creator($('#event_itinerary_creator_options3'));
                starttime_window_open();//schedule_window_open
            });
			$('#event_itinerary_creator_options4').unbind().click(function(event) {
                initiate_starttime_creator($('#event_itinerary_creator_options4'));
                starttime_window_open();//schedule_window_open
            });
			$('#event_itinerary_creator_options5').unbind().click(function(event) {
                initiate_starttime_creator($('#event_itinerary_creator_options3'));
                starttime_window_open();//schedule_window_open
            });
			/* --------------------------------------------------------  */
            if($('#event_itinerary_creator_startDate').val()){
				/* --------------------------------------------------------  */
                var $ST_DATE = $('#event_itinerary_creator_startDate').val();
				//$('#event_itinerary_creator_startDate').val(moment($ST_DATE).format('MMMM DD YYYY'));
				$('#event_itinerary_creator_options3').html(moment($ST_DATE).format('MMMM DD YYYY'));
				/* --------------------------------------------------------  */
				var $ST_TIME = $('#event_itinerary_creator_startTime').val();
				$('#event_itinerary_creator_options4').html(moment($ST_TIME).format('LT'));//format('HH:mm:ssZZ')
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				var tempDate = ($('#event_itinerary_creator_startDate').val()) ? moment($('#event_itinerary_creator_startDate').val(),'YYYY-MM-DDTHH:mm:ssZZ').utc().local().format() :  moment([]).utc().local().format();
				var tempTime = ($('#event_itinerary_creator_startTime').val()) ? moment($('#event_itinerary_creator_startTime').val(), 'YYYY-MM-DDTHH:mm:ssZZ').utc().local().format() :  moment([]).utc().local().format();
				/* --------------------------------------------------------  */
				if((moment(tempDate).isValid())&&(moment(tempTime).isValid())){
					var saveDate = moment(tempDate).format('YYYY-MM-DD');
					var saveTime = moment(tempTime).format('HH:mm:ssZZ');
					var temp = saveDate+'T'+saveTime;
					var temp = moment(temp).utc().local().format();
					$('#event_itinerary_creator_start').val(temp);
				}else if((moment(tempDate).isValid())&&(moment(tempTime).isValid()==false)){
					var saveDate = moment(tempDate).format('YYYY-MM-DD');
					var saveTime = moment(tempDate).format('HH:mm:ssZZ');
					var temp = saveDate+'T'+saveTime;
					var temp = moment(temp).utc().local().format();
					$('#event_itinerary_creator_start').val(temp);
				}else if((moment(tempDate).isValid()==false)&&(moment(tempTime).isValid())){
					var saveDate = moment(tempTime).format('YYYY-MM-DD');
					var saveTime = moment(tempTime).format('HH:mm:ssZZ');
					var temp = saveDate+'T'+saveTime;
					var temp = moment(temp).utc().local().format();
					$('#event_itinerary_creator_start').val(temp);
				}
				duration();
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
            }
			/* --------------------------------------------------------  */
			if(document.getElementById("event_itinerary_creator_options6")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options6");
			}
			if(document.getElementById("event_itinerary_creator_options7")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options7");
			}
			if(document.getElementById("event_itinerary_creator_options8")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options8");
			}
			if(document.getElementById("event_itinerary_creator_options9")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options9");
			}
			if(document.getElementById("event_itinerary_creator_options21")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options21");
			}
			if(document.getElementById("event_itinerary_creator_options22")){
				SET_BUTTON_CLICK_ACTION("event_itinerary_creator_options22");
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("event_itinerary_creator_options6active")){
				 $('#event_itinerary_creator_options6active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options6active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options7active")){
				 $('#event_itinerary_creator_options7active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options7active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options8active")){
				 $('#event_itinerary_creator_options8active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options8active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options9active")){
				 $('#event_itinerary_creator_options9active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options9active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if($('#event_itinerary_creator_options10active')){
				 $('#event_itinerary_creator_options10active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options10active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options11active")){
				 $('#event_itinerary_creator_options11active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options11active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options12active")){
				 $('#event_itinerary_creator_options12active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options12active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			if(document.getElementById("event_itinerary_creator_options13active")){
				 $('#event_itinerary_creator_options13active').unbind().click(function(event) {
					text_to_field($('#event_itinerary_creator_options13active'),$('#event_itinerary_creator_messageboxinput'));
					/* --------------------------------------------------------  */
					text_editor_word_count(
						$('#event_itinerary_creator_messageboxinput'),
						$('#event_itinerary_creator_wordCount')
					);
				});
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var textarea = null;
			var result = null;
			var NEW_VAL = null;
			$('#event_itinerary_creator_messageboxinput').attr("maxlength", $('#event_itinerary_creator_formFilter4').val());
			/* --------------------------------------------------------  */
            text_editor_word_count(
                $('#event_itinerary_creator_messageboxinput'),
                $('#event_itinerary_creator_wordCount')
            );
			/* --------------------------------------------------------  */
            /* --------------------------------------------------------  */
            if(document.getElementById("event_itinerary_creator_messageboxinput")){
                var obj_var = document.getElementById("event_itinerary_creator_messageboxinput");
                /* --------------------------------------------------------  */
                obj_var.onfocus = function() {
                    $('#event_itinerary_creator_messageboxinput').keyup(function(event) {
                        var key = String.fromCharCode(event.which);
                        //if(event.keyCode == 32){
                        //    $('#event_itinerary_creator_messageboxinput').val($('#event_itinerary_creator_messageboxinput').val()+' ');
                        //}
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById("event_itinerary_creator_messageboxinput").addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$('#event_itinerary_creator_messageboxinput').val();
							$('#event_itinerary_creator_messageboxinput').val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
                        if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                           (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
                           (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
                        /*
                        (key == 13 /ENTER/)
                        (key == 46 /DELETE/)
                        (key == 8 /BACKSPACE/)
                        */ 
                        }
						/* --------------------------------------------------------  */
						text_editor_word_count(
							$('#event_itinerary_creator_messageboxinput'),
							$('#event_itinerary_creator_wordCount')
						);
						/* --------------------------------------------------------  */
                        var $REG = $('#event_itinerary_creator_messageboxinput').val();
                        var string = document.getElementById("event_itinerary_creator_messageboxinput").value;
						/**/
                        obj_var.onblur = function($EVENT) {
                            if($REG.length>1){
                                    $('#standard_entity_modify_control').val($('#event_itinerary_creator_messageboxinput').attr('id'));
                                    $('#standard_entity_modify_title').val($('#event_itinerary_creator_messageboxinput').val());
                                    $('#standard_entity_modify_uniqEventId').val($('#event_itinerary_creator_uniqEventId').val());
                                    $('#standard_entity_modify_registerCode').val($('#event_itinerary_creator_registerCode').val());
									$('#standard_entity_modify_index2').val($('#event_creator_uniqEventId').val());
                            		$('#standard_entity_modify_index3').val($('#event_creator_registerCode').val());
									$('#standard_entity_modify_index7').val($('#event_itinerary_creator_formCurrentIndex').val());
									$('#standard_entity_modify_formCurrentIndex').val($('#event_itinerary_creator_messageboxinput').val());
									event_item_instance_function('event_itinerary_creator_control', $('#event_itinerary_creator_messageboxinput').attr('id'),$('#event_itinerary_creator_messageboxinput').val(),$EVENT);
                            }
                        }
						
                    });
                }
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options14')){
				text_ajax($('#event_itinerary_creator_options14'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options15')){
				text_ajax($('#event_itinerary_creator_options15'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options16')){
				text_ajax($('#event_itinerary_creator_options16'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options17')){
				text_ajax($('#event_itinerary_creator_options17'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options18')){
				text_ajax($('#event_itinerary_creator_options18'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options19')){
				text_ajax($('#event_itinerary_creator_options19'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options20')){
				text_ajax($('#event_itinerary_creator_options20'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options21')){
				text_ajax($('#event_itinerary_creator_options21'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options22')){
				text_ajax($('#event_itinerary_creator_options22'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options23')){
				text_ajax($('#event_itinerary_creator_options23'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options24')){
				text_ajax($('#event_itinerary_creator_options24'));
			}
			/* --------------------------------------------------------  */
			if($('#event_itinerary_creator_options25')){
				text_ajax($('#event_itinerary_creator_options25'));
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function text_ajax($but_obj){
				var obj_var = document.getElementById($but_obj.attr('id'));
                /* --------------------------------------------------------  */
                obj_var.onfocus = function() {
                   $but_obj.keyup(function(event) {
                        var key = String.fromCharCode(event.which);
                        //if(event.keyCode == 32){
                        //   $but_obj.val($but_obj.val()+' ');
                        //}
						/* --------------------------------------------------------  */
						//var codes = [9,13,32];
						var codes = [32];
						document.getElementById($but_obj.attr('id')).addEventListener('keydown', function(e) {
						if(!contains(codes, e.keyCode)) return;
							var path = e.path || e.composedPath()
							if(!path[0]) return;

							e.preventDefault();
							path[0].value += String.fromCharCode(e.keyCode);//
							var $STRING=$but_obj.val();
							$but_obj.val($STRING.replace(/\s{2,}/g, ' '));
						})
						function contains(arr, item) {
							for(var i=0;i<arr.length;i++)
							if(arr[i]==item)return true;
							return false;
						}
						/* --------------------------------------------------------  */
                        if((!key.match(/^[^A-Za-z0-9+#\.\-]+$/)) || 
                           (event.keyCode == $.ui.keyCode.DELETE /*DELETE*/)|| 
                           (event.keyCode == $.ui.keyCode.BACKSPACE /*BACKSPACE*/)){
                        /*
                        (key == 13 /ENTER/)
                        (key == 46 /DELETE/)
                        (key == 8 /BACKSPACE/)
                        */ 
                        }
						/* --------------------------------------------------------  */
						/* --------------------------------------------------------  */
                        var $REG = $but_obj.val();
                        obj_var.onblur = function($EVENT) {
                            if($REG.length>1){
                                    $('#standard_entity_modify_control').val($but_obj.attr('id'));
									$('#standard_entity_modify_title').val($but_obj.val());
									$('#standard_entity_modify_uniqEventId').val($('#event_itinerary_creator_uniqEventId').val());
									$('#standard_entity_modify_registerCode').val($('#event_itinerary_creator_registerCode').val());
									$('#standard_entity_modify_index2').val($('#event_creator_uniqEventId').val());
									$('#standard_entity_modify_index3').val($('#event_creator_registerCode').val());
									$('#standard_entity_modify_index7').val($('#event_itinerary_creator_formCurrentIndex').val());
									$('#standard_entity_modify_formCurrentIndex').val($but_obj.val());
									event_item_instance_function('event_itinerary_creator_control', $but_obj.attr('id'),$but_obj.val(),$EVENT);
                            }
                        }
                    });
                }
			}
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('ITEM_ITINERARY_create_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('ITEM_ITINERARY_create_MGMT',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('ITEM_ITINERARY_create_MGMT',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									remote_data_load_submit($('#Event_Settings_Menu_UI_control'));
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('ITEM_ITINERARY_create_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	ITEM_SEATING_basics_MGMT                   ########################## */
export function ITEM_SEATING_basics_MGMT(){
		/* -------------------------------------------------------- */
		$(document).ready(function() {
			CONSOLE_MANAGER('ITEM_SEATING_basics_MGMT','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			$('.Listview_options_Activity_UI_class').attr("id", 'Event_Seating_Menu_UI_control');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			if(parseInt($("#event_seating_menu_formFilter11").val())==parseInt(1)){
				$('#event_seating_menu_options2').prop("disabled",true);
                $('#event_seating_menu_options3').prop("disabled",true);
                $('#event_seating_menu_options5').prop("disabled",true);
                $('#event_seating_menu_options6').prop("disabled",true);
				$('#event_seating_menu_options2active').prop("disabled",true);
                $('#event_seating_menu_options5active').prop("disabled",true);
			}else{
				if(document.getElementById("event_seating_menu_options2")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options2");
				}
				if(document.getElementById("event_seating_menu_options2active")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options2active");
				}
				if(document.getElementById("event_seating_menu_options3")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options3");
				}
				if(document.getElementById("event_seating_menu_options5")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options5");
				}
				if(document.getElementById("event_seating_menu_options5active")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options5active");
				}
				if(document.getElementById("event_seating_menu_options6")){
					SET_BUTTON_CLICK_ACTION("event_seating_menu_options6");
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if($("#event_seating_menu_control").val()=='PARENT'){
					
					
				}else{
					/* --------------------------------------------------------  */
					
				}
				/* --------------------------------------------------------  */
			}	
			/* --------------------------------------------------------  */
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('ITEM_SEATING_basics_MGMT');
}
/* -------------------------------------------------------- */
/* -------------------------------------------------------- */
export function remote_data_load_submit(control_var){
	/* ------------------------------------------------- */
	/* ------------------------------------------------- */	
	/* ------------------------------------------------- */	
	CONSOLE_MANAGER('remote_data_load_submit','ACTIVATED');
	/* --------------------------------------------------------  */
    var $ENTITYLISTMgmt = [];
    var $ENTITYLISTCOORDMgmt = [];
    var $code = $("#index_object_pop").val();
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    /* -------------------------------------------------------- */
    if(document.getElementById("total_objects_count_pop")){
        var Object_count = parseInt(document.getElementById("total_objects_count_pop").value + 1);
        var $code = $("#index_object_pop").val();
        for(var $i=1; $i<Object_count; $i++){

            if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
                var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
                var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
                var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
                var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                /* ------------------------------------------------- */
                SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
				update_class($($OPTIONS01),$($INDEX01),$($INDEX02),$($INDEX03))
                remote_data_load_submit_register(control_var,$($OPTIONS01),$i,$code,$($INDEX01));
            }	
        }   
    }
    /* -------------------------------------------------------- */
    function update_class($button_obj){
		window_complete_script('remote_data_load_submit');
    }
	/* ------------------------------------------------- */
	function remote_data_load_submit_register($control_var,$button_var,$item_value,$code,$INDEX01){
		$button_var.unbind().click(function(event) {
			$('#standard_entity_modify_control').val($control_var.attr('id'));
			$('#standard_entity_modify_formCurrentIndex').val($('#confirmation_notification_index1').val());
			$('#standard_entity_modify_index1').val($INDEX01.val());
			standard_entity_modify_submit_MGMT($('#standard_entity_modify_options1'),event);
		});
	}
}/*END FUNCTION */
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	EVENT_ITEM_LOCATION_basics                   ########################## */
export function EVENT_ITEM_LOCATION_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('EVENT_ITEM_LOCATION_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			
			map_identity_event_control();
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(document.getElementById("event_locations_menu_options1")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options1");
            }
			if(document.getElementById("event_locations_menu_options2")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options2");
            }
			if(document.getElementById("event_locations_menu_options3")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options3");
            }
			if(document.getElementById("event_locations_menu_options4")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options4");
            }
			if(document.getElementById("event_locations_menu_options1active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options1active");
            }
			if(document.getElementById("event_locations_menu_options2active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options2active");
            }
            if(document.getElementById("event_locations_menu_options3active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options3active");
            }
            if(document.getElementById("event_locations_menu_options4active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options4active");
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('EVENT_ITEM_LOCATION_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if(mutation.type=='attributes'){
								if($("#window_view").is(':visible')){
									if($("#standard_results_ajax_control").val()){
										$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
										if($('#search_request_control').length){
										   $SEARCH_CNTRL = $('#search_request_control').val();
										}
										/* -------------------------------------------------------- */
										if(mutation.type=='attributes'){
                                            CONSOLE_MANAGER('EVENT_ITEM_LOCATION_basics',mutation.type+': attributes');
											if(($('#standard_results_ajax_control').val())){
												
											}
										}else if(mutation.type=='childList'){
											CONSOLE_MANAGER('EVENT_ITEM_LOCATION_basics',mutation.type+': childList');
											/* -------------------------------------------------------- */

										}
									}
									/* -------------------------------------------------------- */
								}
								if($( "#window_view" ).is(':hidden')){
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
								}
							}else if(mutation.type=='childList'){
								/* --------------------------------------------------------  */

							} 
							translate_ajax_clear(true);
							$RESULTS_CNTRL = $SEARCH_CNTRL = null;
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('EVENT_ITEM_LOCATION_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	HOLIDAY_ITEM_SETTINGS_basics_MGMT                   ########################## */
export function HOLIDAY_ITEM_SETTINGS_basics_MGMT(){
		/* -------------------------------------------------------- */
		$(document).ready(function() {
			CONSOLE_MANAGER('HOLIDAY_ITEM_SETTINGS_basics_MGMT','ACTIVATED');
			//AUTOBAHNJS_VERSION();
			$('.Listview_options_Activity_UI_class').attr("id", 'Event_Settings_Menu_UI_control');
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			if(document.getElementById("sys_return_options1")){
				SET_BUTTON_CLICK_ACTION("sys_return_options1");
			}
			/* --------------------------------------------------------  */
			if(parseInt($("#event_settings_menu_formFilter11").val())==parseInt(1)){
				$('#event_settings_menu_options2').prop("disabled",true);
                $('#event_settings_menu_options3').prop("disabled",true);
                $('#event_settings_menu_options5').prop("disabled",true);
                $('#event_settings_menu_options6').prop("disabled",true);
				$('#event_settings_menu_options7').prop("disabled",true);
				$('#event_settings_menu_options7active').prop("disabled",true);
                //$('#event_settings_menu_options8').prop("disabled",true);
                //$('#event_settings_menu_options9').prop("disabled",true);
                $('#event_settings_menu_options10').prop("disabled",true);
				$('#event_settings_menu_options11').prop("disabled",true);
				$('#event_settings_menu_options11active').prop("disabled",true);
                //$('#event_settings_menu_options12').prop("disabled",true);
				$('#event_settings_menu_options2active').prop("disabled",true);
                $('#event_settings_menu_options3active').prop("disabled",true);
                $('#event_settings_menu_options4active').prop("disabled",true);
                $('#event_settings_menu_options5active').prop("disabled",true);
				//$('#event_settings_menu_options6active').prop("disabled",true);
                //$('#event_settings_menu_options7active').prop("disabled",true);
				//$('#event_settings_menu_options8active').prop("disabled",true);
				$('#event_settings_menu_options12').prop("disabled",true);
				$('#event_settings_menu_options12active').prop("disabled",true);
				$('#event_settings_menu_options13').prop("disabled",true);
				$('#event_settings_menu_options13active').prop("disabled",true);
				$('#event_settings_menu_options14').prop("disabled",true);
				$('#event_settings_menu_options14active').prop("disabled",true);
				$('#event_settings_menu_options15').prop("disabled",true);
				$('#event_settings_menu_options15active').prop("disabled",true);
			}else{
				if(document.getElementById("event_settings_menu_options2")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options2");
				}
				if(document.getElementById("event_settings_menu_options3")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options3");
				}
				if(document.getElementById("event_settings_menu_options4")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options4");
				}
				if(document.getElementById("event_settings_menu_options5")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options5");
				}
				if(document.getElementById("event_settings_menu_options6")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options6");
				}
				if(document.getElementById("event_settings_menu_options7")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options7");
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if($("#event_settings_menu_control").val()=='PARENT'){
					$('#event_settings_menu_options7active').prop("disabled",true);
					$('#event_settings_menu_options11active').prop("disabled",true);
				}else{
					/* --------------------------------------------------------  */
					if($("#event_settings_menu_formFilter6").val()==true){
						$('#event_settings_menu_options7').prop("disabled",true);
					}else{
						if(document.getElementById("event_settings_menu_options7active")){
							SET_BUTTON_CLICK_ACTION("event_settings_menu_options7active");
						}
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options8'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options8').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options8active'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options8').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options9'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options9').attr('id'));
						NOTIFICATIONSYSTEM_LOAD_MGMT($('#event_settings_menu_options9active'),null,null,null,null);
						$('#standard_entity_modify_control').val($('#event_settings_menu_options9').attr('id'));
					}
					/* --------------------------------------------------------  */
					if($("#event_settings_menu_formFilter7").val()==true){
						$('#event_settings_menu_options11').prop("disabled",true);
					}else{
						if(document.getElementById("event_settings_menu_options11active")){
							SET_BUTTON_CLICK_ACTION("event_settings_menu_options11active");
						}
					}
				}
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */
				if(document.getElementById("event_settings_menu_options10")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options10");
				}
				if(document.getElementById("event_settings_menu_options11")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options11");
				}
				if(document.getElementById("event_settings_menu_options12")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options12");
				}
				if(document.getElementById("event_settings_menu_options12active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options12active");
				}
				if(document.getElementById("event_settings_menu_options13")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options13");
				}
				if(document.getElementById("event_settings_menu_options13active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options13active");
				}
				if(document.getElementById("event_settings_menu_options14")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options14");
				}
				if(document.getElementById("event_settings_menu_options14active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options14active");
				}
				if(document.getElementById("event_settings_menu_options15")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options15");
				}
				if(document.getElementById("event_settings_menu_options15active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options15active");
				}
				/* --------------------------------------------------------  */
				if(document.getElementById("event_settings_menu_options2active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options2active");
				}
				if(document.getElementById("event_settings_menu_options3active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options3active");
				}
				if(document.getElementById("event_settings_menu_options4active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options4active");
				}
				if(document.getElementById("event_settings_menu_options5active")){
					SET_BUTTON_CLICK_ACTION("event_settings_menu_options5active");
				}
				/* --------------------------------------------------------  */
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* AJAX LISTENERS  (MutationObserver) ---------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
							var $RESULTS_CNTRL, $SEARCH_CNTRL = null;
                            CONSOLE_MANAGER('HOLIDAY_ITEM_SETTINGS_basics_MGMT','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
                            if(mutation.type=='attributes'){
								CONSOLE_MANAGER('HOLIDAY_ITEM_SETTINGS_basics_MGMT',mutation.type+': attributes');
                            }else if(mutation.type=='childList'){
                                CONSOLE_MANAGER('HOLIDAY_ITEM_SETTINGS_basics_MGMT',mutation.type+': childList');
								/* -------------------------------------------------------- */
								if(($('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									remote_data_load_submit($('#Event_Settings_Menu_UI_control'));
								}
                                /* -------------------------------------------------------- */
								if((!$('#window_view').is(':visible'))&&($RESULTS_CNTRL==null)){
									$RESULTS_CNTRL =1;
								}
								/* -------------------------------------------------------- */
                            }
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();	
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var $ENTITYLISTMgmt = [];
			var $ENTITYLISTCOORDMgmt = [];
			var $code = $("#index_object").val();
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			/* -------------------------------------------------------- */
			if(document.getElementById("total_objects_count")){
				var Object_count = parseInt(document.getElementById("total_objects_count").value + 1);
				var $code = $("#index_object").val();
				for(var $i=1; $i<Object_count; $i++){

					if($('#form_'+$code+'_'+$i+'_options1').is(':visible')){
						var $OPTIONS01 = '#form_'+$code+'_'+$i+'_options1';
						//var $2SEQUENCE = '#form_'+$code+'_'+$i+'_options2';
						var $INDEX01 = '#form_'+$code+'_'+$i+'_index1';
						//var $INDEX02 = '#form_'+$code+'_'+$i+'_index2';
						//var $INDEX03 = '#form_'+$code+'_'+$i+'_index3';
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */	
						/* ------------------------------------------------- */
						/* ------------------------------------------------- */
						SET_BUTTON_CLICK_ACTION('form_'+$code+'_'+$i+'_options1');
						REGISTER_CLICK($($OPTIONS01),$i,$code,$($INDEX01));
						if($($INDEX01).val()){
							if($('#event_settings_menu_index16').val()){
								var jsonValidate  = isJSON($('#event_settings_menu_index16').val());
								if(jsonValidate==true){
									var $DATES_obj_array = JSON.parse($('#event_settings_menu_index16').val());
									if ($.inArray($($INDEX01).val(), $DATES_obj_array)>=0){
										update_class($($OPTIONS01))
									}else{
                                        
									}
								}
							}
						}
					}	
				}   
			}
			/* -------------------------------------------------------- */
			function update_class($button_obj){
				var $ACTIVE = 'DISABLE';
				if($button_obj.hasClass("event_identity_result_label_active")){
					$button_obj.addClass('event_identity_result_label').removeClass('event_identity_result_label_active');
				}else{
					$button_obj.addClass('event_identity_result_label_active').removeClass('event_identity_result_label');
					$ACTIVE = 'ACTIVE';
				}
				return $ACTIVE;
			}
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			function REGISTER_CLICK($button_obj,$val_code,$code,$INDEX01){
				$button_obj.unbind().click(function(event) {
					var $STATUS = update_class($button_obj);
					$('#standard_entity_modify_formFilter1').val($STATUS);
					$('#standard_entity_modify_index1').val($INDEX01.val());
					$('#standard_entity_modify_formCurrentIndex').val($INDEX01.val());
                    $('#standard_entity_modify_uniqEventId').val($('#event_settings_menu_uniqEventId').val());
                    $('#standard_entity_modify_registerCode').val($('#event_settings_menu_registerCode').val());
					holiday_item_instance_function('holiday_event_settings_menu_options', $('#event_settings_menu_options1').attr('id'),$INDEX01.val(),event);//event_instance_function
                });
			}		
			/* --------------------------------------------------------  */
		});
		/* --------------------------------------------------------  */
		/* --------------------------------------------------------  */
		window_complete_script('HOLIDAY_ITEM_SETTINGS_basics_MGMT');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* ###############   	HOLIDAY_ITEM_LOCATION_basics                   ########################## */
export function HOLIDAY_ITEM_LOCATION_basics(){
		$(document).ready(function() {
			CONSOLE_MANAGER('HOLIDAY_ITEM_LOCATION_basics','ACTIVATED');
			AUTOBAHNJS_VERSION();
			
			map_identity_event_control();
			SET_BUTTON_CLICK_ACTION("sys_return_options1");
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
            if(document.getElementById("event_locations_menu_options1")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options1");
            }
			if(document.getElementById("event_locations_menu_options2")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options2");
            }
			if(document.getElementById("event_locations_menu_options3")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options3");
            }
			if(document.getElementById("event_locations_menu_options4")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options4");
            }
			if(document.getElementById("event_locations_menu_options1active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options1active");
            }
			if(document.getElementById("event_locations_menu_options2active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options2active");
            }
            if(document.getElementById("event_locations_menu_options3active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options3active");
            }
            if(document.getElementById("event_locations_menu_options4active")){
                SET_BUTTON_CLICK_ACTION("event_locations_menu_options4active");
            }
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			var WINDOW_VIEW_LISTENER = (function (){
				var target = document.getElementById('window_view');
				/* --------------------------------------------------------  */	
				var observer = new MutationObserver(function(mutations) {
					mutations.forEach(function(mutation) {
						$(document).ready(function() {
                            CONSOLE_MANAGER('HOLIDAY_ITEM_LOCATION_basics','mutation: '+mutation.type+' - '+mutation.addedNodes+' - '+mutation.removedNodes);
							var $RESULTS_CNTRL,$SEARCH_CNTRL = null;
							if(mutation.type=='attributes'){
								if($("#window_view").is(':visible')){
									if($("#standard_results_ajax_control").val()){
										$RESULTS_CNTRL = $('#standard_results_ajax_control').val();
										if($('#search_request_control').length){
										   $SEARCH_CNTRL = $('#search_request_control').val();
										}
										/* -------------------------------------------------------- */
										if(mutation.type=='attributes'){
                                            CONSOLE_MANAGER('HOLIDAY_ITEM_LOCATION_basics',mutation.type+': attributes');
											if(($('#standard_results_ajax_control').val())){
												
											}
										}else if(mutation.type=='childList'){
											CONSOLE_MANAGER('HOLIDAY_ITEM_LOCATION_basics',mutation.type+': childList');
											/* -------------------------------------------------------- */

										}
									}
									/* -------------------------------------------------------- */
								}
								if($( "#window_view" ).is(':hidden')){
									/* --------------------------------------------------------  */
									/* --------------------------------------------------------  */
								}
							}else if(mutation.type=='childList'){
								/* --------------------------------------------------------  */

							} 
							translate_ajax_clear(true);
							$RESULTS_CNTRL = $SEARCH_CNTRL = null;
						});
					});    
				});//END new MutationObserver
				var config = { attributes: true, childList: true, characterData: true };
				observer.observe(target, config);
				//observer.disconnect();
			})();/*END FUNCTION */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* --------------------------------------------------------  */
			/* -------------------------------------------------------- */
		}); 
		/* -------------------------------------------------------- */
		window_complete_script('HOLIDAY_ITEM_LOCATION_basics');
}
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/
/* 01010101010101010101010101010101010101010101010101010101010101010010110010110101010110101001*/